import React, { useCallback } from 'react';
import { useApolloClient } from '@apollo/client';
import { Box } from '@mantine/core';
import startsWith from 'lodash/startsWith';
import moment from 'moment';
import { ViewSERPButton } from 'Components/AccuTable/CellRenderer/actions';
import RankCell from 'Components/AccuTable/CellRenderer/rank';
import SerpCell, { SerpCellProps } from 'Components/AccuTable/CellRenderer/serp';
import { createCombinedNumberCell, createNumberCell, createPercentageCell } from 'Components/Cell';
import {
  CellRendererProps,
  ColumnType,
  TableFetchDataCallBack,
  TableOrder,
  WithRowContext,
  withRowContext,
} from 'Components/DataTable';
import URLCell from 'Components/Table/TableRow/URLCell';
import { DEFAULT_DATE_TIME_FORMAT } from 'Constants';
import { KeywordHistoryTableDocument, Maybe } from 'Ghql';
import { KeywordHistoryTableQuery, TableKeywordHistoryNode } from 'Ghql/customTypes';
import { useStandardFilters } from 'Hooks';
import { useModal } from 'Hooks/base/useModal';
import { useDomainId } from 'Hooks/data/domain/useQueryDomainInfo';
import { useHasEnabledNewFeatures } from 'Hooks/data/organization/useOrganizationInfo';
import { useMetaData } from 'Hooks/data/user/useMetaData';
import { KeywordColumnID } from 'Pages/Keywords/Table/support/constants';
import { WithKeywordTableExpandChildren } from 'Pages/Keywords/Table/support/types';
import { t } from 'Utilities/i18n';
import { formatInternalLink } from 'Utilities/underdash';

type CellProps = CellRendererProps<TableKeywordHistoryNode>;

export const KeywordTableColumnIDs = {
  SEARCH_DATE: 'search_date',
  RANK: 'rank',
  SEARCH_VOLUME: 'share_volume',
  CPC: 'cpc',
  VISITS: 'visits',
  CTR: 'ctr',
  SHARE_OF_VOICE: 'share_of_voice',
  SHARE_OF_VOICE_CPC: 'share_of_voice_cpc',
  URL: 'highest_ranking_page',
  SERP: 'serp',
  SERP_PAGE: 'serp_page',
  AI_SEARCH_VOLUME: 'ai_search_volume',
};

const formatKeyword = <T extends { extraRanks?: Maybe<Array<Maybe<Array<Maybe<string>>>>> }>(
  keywordItem: T,
): T & WithKeywordTableExpandChildren => {
  return {
    ...keywordItem,
    expandChildren: keywordItem?.extraRanks?.map((el) => ({
      highestRankingPage: el?.[1],
      rankValue: el?.[0],
      isSubRow: true,
    })),
  };
};

// copy SERP features that values are not null or false
const getSerpFeatures = (record?: object) =>
  Object.keys(record || {}).filter((key) => startsWith(key, 'serp') && record?.[key]);

export const useFetchSingleKeywordHistory = (keywordId: string): TableFetchDataCallBack => {
  const filters = useStandardFilters();
  const client = useApolloClient();
  return useCallback(
    async ({ pagination }: any) => {
      return client
        .query<KeywordHistoryTableQuery>({
          query: KeywordHistoryTableDocument,
          variables: {
            filters,
            keywordId,
            ordering: { order: TableOrder.DESC, orderBy: 'search_date' },
            pagination,
          },
        })
        .then((e) => ({
          data:
            e?.data?.keywordsTableHistory?.history
              ?.filter(Boolean)
              ?.map((el) => formatKeyword<Partial<TableKeywordHistoryNode>>(el!)) ?? [],
          length: e?.data?.keywordsTableHistory?.pagination?.numResults ?? 0,
        }));
    },
    [filters, client, keywordId],
  );
};

export const useKeywordHistoryColumns = (): ColumnType<
  TableKeywordHistoryNode,
  WithRowContext & SerpCellProps
>[] => {
  const metaData = useMetaData();
  const hasEnabledNewFeatures = useHasEnabledNewFeatures();
  return [
    {
      id: KeywordTableColumnIDs.SEARCH_DATE,
      title: t('Search date'),
      width: 150,
      dataToCopy: (record) => {
        if (record?.searchDate) {
          return moment(record?.searchDate).format(DEFAULT_DATE_TIME_FORMAT);
        }
      },
      cellRenderer: ({ record }: CellProps) => {
        if (!record?.searchDate) return '';
        return moment(record.searchDate).format(DEFAULT_DATE_TIME_FORMAT);
      },
      onHeaderCell: () => ({
        tooltip: t('Date and time of the search in your local timezone'),
      }),
    },
    {
      id: KeywordColumnID.RANK,
      title: t('Rank'),
      dataToCopy: (record) => record?.rankValue,
      width: 90,
      cellRenderer: withRowContext(
        RankCell as React.ComponentType<React.PropsWithChildren<WithRowContext>>,
      ),
    },
    {
      id: KeywordTableColumnIDs.URL,
      title: t('URL'),
      dataToCopy: (record) => record?.highestRankingPage ?? '',
      width: 150,
      flex: true,
      cellRenderer: ({ record }: CellProps) => {
        return (
          <URLCell
            url={record?.highestRankingPage ?? ''}
            title={record?.title}
            description={record?.description}
          />
        );
      },
    },
    {
      id: KeywordTableColumnIDs.SEARCH_VOLUME,
      title: t('Search Volume'),
      dataToCopy: (record) => record.searchVolumeValue,
      onHeaderCell: () => ({
        reverseDirection: true,
        tooltip: t('Average monthly search volume'),
      }),
      width: 100,
      cellRenderer: createNumberCell<TableKeywordHistoryNode>('searchVolumeValue'),
    },
    ...(hasEnabledNewFeatures
      ? [
          {
            id: KeywordTableColumnIDs.AI_SEARCH_VOLUME,
            title: t('AI Search Volume'),
            dataToCopy: (record) => record.aiSearchVolume,
            onHeaderCell: () => ({
              reverseDirection: true,
              tooltip: t('AI Search Volume'),
            }),
            width: 105,
            cellRenderer: createNumberCell<TableKeywordHistoryNode>('aiSearchVolume'),
          },
        ]
      : []),
    {
      id: KeywordTableColumnIDs.CPC,
      title: t('CPC'),
      dataToCopy: (record) => record.avgCostPerClick,
      onHeaderCell: () => ({
        reverseDirection: true,
        tooltip: t('Average cost per click (Google Ads)'),
      }),
      width: 75,
      cellRenderer: createNumberCell<TableKeywordHistoryNode>('avgCostPerClick', true, 2, 2),
    },

    {
      id: KeywordTableColumnIDs.SHARE_OF_VOICE,
      title: t('SoV'),
      dataToCopy: (record) => record?.shareOfVoiceValue,
      onHeaderCell: () => ({
        reverseDirection: true,
        tooltip: t('Share of voice'),
      }),
      width: 75,
      cellRenderer: createCombinedNumberCell<TableKeywordHistoryNode>({
        percentageKey: 'shareOfVoicePercentageValue',
        valueKey: 'shareOfVoiceValue',
      }),
    },
    {
      id: KeywordTableColumnIDs.CTR,
      title: t('CTR'),
      dataToCopy: (record) => record?.dynamicCtrValue,
      onHeaderCell: () => ({
        reverseDirection: true,
        tooltip: t('Click-through rate'),
      }),
      width: 100,
      cellRenderer: createPercentageCell<TableKeywordHistoryNode>({
        percentageKey: 'dynamicCtrValue',
        tooltip: t('No value for this date'),
        emptyValue: '?',
        multiplyByHundred: true,
        precision: 1,
      }),
    },
    {
      id: KeywordTableColumnIDs.VISITS,
      title: t('AI SoV'),
      dataToCopy: (record) => record?.organicTraffic,
      onHeaderCell: () => ({
        tooltip: t('AI Share of Voice'),
        reverseDirection: true,
      }),
      width: 100,
      cellRenderer: createNumberCell<TableKeywordHistoryNode>('organicTraffic'),
    },
    {
      id: KeywordTableColumnIDs.SERP,
      title: t('SERP Features'),
      dataToCopy: getSerpFeatures,
      onHeaderCell: () => ({
        tooltip: t('Features on the SERP'),
      }),
      width: 144,
      cellRenderer: SerpCell,
      cellRendererParams: {
        serpFeatures: metaData?.serpFeatures,
      },
    },
    {
      id: KeywordTableColumnIDs.SERP_PAGE,
      width: 44,
      dataToCopy: (record) =>
        (record?.historyUrl ? formatInternalLink(record?.historyUrl || '') : '-'),
      cellRenderer: ({ record }) => {
        //we only store SERP results in a limited amount of days on our servers

        return (
          <Box mr="sm" className="serp-action">
            <ViewSERPButton historyUrl={record?.historyUrl} />
          </Box>
        );
      },
    },
  ];
};

export const useShowHistoryReportModal = (keywordId: string) => {
  const { showModal } = useModal();
  const domainId = useDomainId();
  return () =>
    showModal({
      modalType: 'KeywordHistoryReport',
      modalTheme: 'light',
      modalProps: {
        domainId,
        keywordId,
      },
      nested: true,
    });
};
