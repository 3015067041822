/* eslint-disable react/no-did-update-set-state */
import { LazyExoticComponent, Suspense, lazy, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RedocRawOptions, RedocStandaloneProps } from 'redoc';
import { finishLoading, startLoading } from 'Actions/LoadingAction';
import BasePublicPage from 'Pages/Layout/BasePublicPage';
import { WithRouter } from 'Utilities/Router';
import { formatInternalLink } from 'Utilities/underdash';
import './api-page.scss';

interface ExtendedRedocRawOptions extends RedocRawOptions {
  noAutoAuth?: boolean;
}

interface ExtendedRedocStandaloneProps extends RedocStandaloneProps {
  options?: ExtendedRedocRawOptions;
}

const RedocStandalone: LazyExoticComponent<(props: ExtendedRedocStandaloneProps) => JSX.Element> =
  lazy(() => import('./redoc-export'));

type ApiPageProps = WithRouter & {
  startLoading: (context: { loadingText: string; noBackdrop: boolean }) => void;
  finishLoading: () => void;
};

type State = {
  spec?: Record<string, any>;
  firstLoad: boolean;
};

const ApiPage = (props: ApiPageProps) => {
  const [state, setState] = useState<State>({
    spec: undefined,
    firstLoad: true,
  });

  // where to get the JSON
  const ReadURL = '/api/v4/docs/?format=openapi';
  const WriteURL = '/api/v4/openapi.json';

  const loadSpec = async () => {
    const isV4Write = window.location.href.includes('/api/v4/write/docs');

    const url = isV4Write ? WriteURL : ReadURL;

    await fetch(formatInternalLink(url))
      .then((response) => response.json())
      .then((resonseSpec: State['spec']) => {
        setState((previousState) => ({
          ...previousState,
          spec: resonseSpec,
        }));
      });
    props.finishLoading();
    setState((previousState) => ({
      ...previousState,
      firstLoad: false,
    }));
  };

  useEffect(() => {
    props.startLoading({
      loadingText: '',
      noBackdrop: true,
    });
    loadSpec();
  }, []);

  return (
    <BasePublicPage className="api-page" showSideNavbar={false} showFooter={false}>
      <Suspense fallback={<div />}>
        {state.spec && (
          <RedocStandalone
            spec={state.spec}
            options={{
              hideLoading: true,
              nativeScrollbars: true,
              noAutoAuth: true,
              hideDownloadButton: true,
              scrollYOffset: 68,
              theme: {
                colors: {
                  primary: {
                    main: '#f89537',
                  },
                },
                typography: {
                  fontFamily: '"Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif',
                  headings: {
                    fontFamily: '"Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif',
                  },
                },
                rightPanel: {
                  backgroundColor: '#313445',
                },
              },
            }}
          />
        )}
      </Suspense>
    </BasePublicPage>
  );
};

export default connect(null, {
  startLoading,
  finishLoading,
})(ApiPage);
