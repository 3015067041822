import { useCallback } from 'react';
import { useApolloClient } from '@apollo/client';
import moment from 'moment';
import { ColumnType, TableOrder } from 'Components/DataTable';
import Ellipsis from 'Components/Ellipsis';
import ReportActionCell from 'Components/Table/TableRow/ReportActionCell';
import {
  GeneratedReportFragment,
  GeneratedReportsDocument,
  GeneratedReportsQuery,
  GeneratedReportsQueryVariables,
} from 'Ghql';
import { SORTING_TYPE } from 'Pages/Keywords/Table/hooks/keyword/constants';
import { getReportTypeIcon } from 'Pages/ReportsOverview/ScheduledReports/helpers/hooks';
import styles from 'Pages/ReportsOverview/ScheduledReports/helpers/scheduledReportTable.module.scss';
import { t } from 'Utilities/i18n';
import { notEmpty } from 'Utilities/underdash';
import { ColumnIDs } from './ColumnIDs';

export const useFetchGeneratedReports = ({
  searchTerm,
  handleLoading,
}: {
  searchTerm: string;
  handleLoading: (isLoading: boolean) => void;
}) => {
  const client = useApolloClient();

  return useCallback(async () => {
    try {
      handleLoading(true);
      const response = await client.query<GeneratedReportsQuery, GeneratedReportsQueryVariables>({
        query: GeneratedReportsDocument,
      });
      const generatedReports = response?.data?.generatedReports?.filter(notEmpty) || [];

      const search = searchTerm.toLowerCase();

      const filteredReports = search
        ? generatedReports
            .filter((report) => !!report.scheduledReportNew)
            .filter((report) => {
              const { scheduledReportNew } = report;
              return scheduledReportNew?.isGroupReport
                ? scheduledReportNew.group?.name?.toLowerCase()?.includes(search)
                : scheduledReportNew?.domain?.domain?.toLowerCase()?.includes(search);
            })
        : generatedReports.filter((report) => !!report?.scheduledReportNew);
      return { data: filteredReports, length: filteredReports.length };
    } catch (error: unknown) {
      if (error instanceof Error) {
        console.error('Error fetching generated reports:', error.message);
      }
      return { data: [], length: 0 };
    } finally {
      handleLoading(false);
    }
  }, [client, searchTerm, handleLoading]);
};

export const useGeneratedReportsTableColumns = ({
  handleDelete,
}: {
  handleUpdateTable: () => void;
  handleDelete: (report: GeneratedReportFragment) => void;
}) => {
  // eslint-disable-next-line @typescript-eslint/ban-types
  const columns: ColumnType<GeneratedReportFragment, {}>[] = [
    {
      id: ColumnIDs.TYPE,
      title: t('Type'),
      width: 60,
      cellRenderer: (props) => {
        if (props.record.scheduledReportNew) {
          const reportTypeIcon = getReportTypeIcon(props.record.scheduledReportNew.reportType);
          return <div className={styles.centerWrapper}>{reportTypeIcon}</div>;
        }
      },
      onHeaderCell: () => ({
        ordering: {
          defaultOrder: TableOrder.ASC,
          orderBy: 'scheduledReportNew.reportType',
          sortingKey: SORTING_TYPE.alphabetical,
        },
      }),
      className: 'cell-center table-icon',
    },
    {
      id: ColumnIDs.REPORT_FOR,
      title: t('Report For'),
      flex: true,
      width: 200,
      cellRenderer: (props) => {
        const report = props.record?.scheduledReportNew;

        if (report) {
          const groupOrDomainName = report.isGroupReport
            ? t('All domains is %s', report.group?.name)
            : `${report.domain?.domain}${
                report.domain?.displayName ? ` (${report.domain?.displayName})` : ''
              }${report.withHistory ? ' - Keyword rank history' : ''}`;
          return <Ellipsis>{groupOrDomainName}</Ellipsis>;
        }

        return 'unknown';
      },
      onHeaderCell: () => ({
        ordering: {
          defaultOrder: TableOrder.ASC,
          orderBy: 'scheduledReportNew.domain.displayName',
          sortingKey: SORTING_TYPE.alphabetical,
        },
      }),
    },
    {
      id: ColumnIDs.DATE,
      title: t('Date'),

      width: 200,
      cellRenderer: (props) => <Ellipsis>{moment(props.record.createdAt).format('lll')}</Ellipsis>,
      onHeaderCell: () => ({
        ordering: {
          defaultOrder: TableOrder.ASC,
          orderBy: 'createdAt',
          sortingKey: SORTING_TYPE.recurency,
        },
      }),
    },
    {
      id: ColumnIDs.ACTIONS,
      title: t('Actions'),
      width: 74,
      cellRenderer: (props) => (
        <ReportActionCell report={props.record} handleDelete={handleDelete} />
      ),
    },
  ];
  return columns;
};
