import {
  CHANGE_NUMBER_OF_ROWS,
  CHANGE_PAGE,
  CHANGE_SORTING,
  RESET_TABLE,
  SET_TABLE_VIEW_MODE,
} from 'Actions/TableAction';
import type { Action } from 'Actions/TableAction';
import type { ViewMode } from 'Types/Table';
import { toNestedReducer } from 'Utilities/reducers';

type State = {
  viewMode?: ViewMode;
  readonly numberOfRows: number;
  readonly page: number;
  readonly sortOrder: 'asc' | 'desc';
  readonly sortField: string;
};

const getInitialState = (): State | null => {
  const savedTableData = localStorage.getItem('table');

  if (savedTableData) {
    try {
      return JSON.parse(savedTableData);
    } catch (e) {
      console.error('failed to parse savedTableData', savedTableData);
    }
  }

  return null;
};

const initialState: State = getInitialState() || {
  viewMode: 'default',
  numberOfRows: 25,
  page: 1,
  sortOrder: 'asc',
  sortField: '',
};

const toggleSortOrder = (sortOrder: 'asc' | 'desc') => (sortOrder === 'asc' ? 'desc' : 'asc');

const particularTableReducer = (state: State = initialState, { payload, type }: Action): State => {
  switch (type) {
    case CHANGE_NUMBER_OF_ROWS:
      return { ...state, numberOfRows: payload.numberOfRows };

    case CHANGE_PAGE:
      return { ...state, page: payload.page };

    case CHANGE_SORTING: {
      const defaultOrder = payload.descDefault ? 'desc' : 'asc';
      const newSortOrder =
        payload.sortField === state.sortField ? toggleSortOrder(state.sortOrder) : defaultOrder;
      return { ...state, sortField: payload.sortField, sortOrder: newSortOrder, page: 1 };
    }

    case RESET_TABLE: {
      const defaultOrder = payload.descDefault ? 'desc' : 'asc';
      return {
        ...state,
        ...initialState,
        sortField: payload.sortField,
        sortOrder: defaultOrder,
        numberOfRows: payload.numberOfRows,
        // TODO FixTSignore
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        startIndex: payload.startIndex as any,
        stopIndex: payload.stopIndex,
        page: 1,
      };
    }

    case SET_TABLE_VIEW_MODE: {
      return { ...state, viewMode: payload.viewMode };
    }

    default:
      return state;
  }
};

export default (state: State = initialState, action: Action): State =>
  toNestedReducer(particularTableReducer, (act) =>
    (act && act.payload && act.payload.tableName ? [act.payload.tableName] : []),
  )(state, action);
