import { useEffect } from 'react';

export const useAutoFocus = (ref, autoFocus?: boolean, timeout?: number) => {
  // Used to defer autofocus until the component is mounted. (As opposed to use autoFocus prop on input element)
  // fixing page jump issue on open popover. For instance, if you have scrolled in the keyword table
  // and then open filters from table header
  useEffect(() => {
    const focus = () => {
      ref.current?.focus();
    };
    if (autoFocus) {
      // timeout used to bypass useFocusTrap.
      if (timeout !== undefined) {
        setTimeout(focus, timeout);
      } else {
        requestAnimationFrame(focus);
      }
    }
  }, [autoFocus, ref, timeout]);
};
