import { ForwardedRef, MouseEventHandler, forwardRef } from 'react';
import { ActionIconProps, ActionIcon as MantineActionIcon } from '@mantine/core';
import cn from 'classnames';
import styles from './acc-action-icon.module.scss';

type variantType = 'default' | 'filled' | 'outlined' | 'subtle' | 'navigation';
type sizeType = 'default' | 'xs' | 'sm' | 'md' | 'lg' | 'xl';
type properties = { [key: string]: string | number };

const variantProps: Record<variantType, properties> = {
  default: {
    color: 'gray.7',
    variant: 'transparent',
  },
  filled: {
    variant: 'filled',
  },
  outlined: {},
  subtle: {
    color: 'gray.8',
    variant: 'subtle',
  },
  navigation: {
    variant: 'transparent',
    color: 'blue',
    className: styles.navigationIcon,
  },
};

// Overwrite Mantine button sizes with ACC sizes.
const sizeProps: Record<sizeType, properties> = {
  default: {
    size: 28,
  },
  xs: {
    size: 16,
  },
  sm: {
    size: 20,
  },
  md: {
    size: 24,
  },
  lg: {
    size: 28,
  },
  xl: {
    size: 32,
  },
};

// eslint-disable-next-line import/no-unused-modules
export type Props = Omit<ActionIconProps, 'variant'> & {
  variant?: variantType;
  size?: sizeType | number;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  href?: string;
  target?: string;
  disabled?: boolean;
  inline?: boolean;
  tabIndex?: number;
};

const AccActionIcon = forwardRef((props: Props, ref: ForwardedRef<any>) => {
  const {
    variant = 'default',
    size = 'default',
    children,
    onClick,
    href,
    target,
    inline,
    className,
    tabIndex,
    ...rest
  } = props;

  return (
    <MantineActionIcon
      ref={ref}
      onClick={href ? () => window.open(href, target ?? '_blank', 'noopener,noreferrer') : onClick}
      radius="sm"
      className={cn({ [styles.inline]: inline }, styles.aspectRatio, className)}
      disabled={props.disabled}
      tabIndex={tabIndex}
      {...variantProps[variant]}
      {...(sizeProps[size] ? sizeProps[size] : { size })}
      {...rest}
    >
      {children}
    </MantineActionIcon>
  );
});
AccActionIcon.displayName = 'AccActionIcon';

export default AccActionIcon;
