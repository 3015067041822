import { DataTable, TableOrder, TableSize } from 'Components/DataTable';
import { TableStyleMode } from 'Components/DataTable/types';
import { useIsGroupDomains } from 'Hooks/data/domain/useQueryDomainInfo';
import {
  useFetchWinnerLosersTable,
  useOverviewKeywordTableInfo,
} from 'Pages/Keywords/Overview/components/WinnersLosers/support/hooks';
import { KeywordColumnID } from 'Pages/Keywords/Table/support/constants';
import { TableIDs } from 'Types/Table';

export const WinnerLosersTable = ({ winnersSelected, useAi }) => {
  const tableName = TableIDs.KEYWORDS_OVERVIEW;
  const isGroupDomains = useIsGroupDomains();
  const { columns } = useOverviewKeywordTableInfo(isGroupDomains, useAi);
  const fetchKeywords = useFetchWinnerLosersTable(winnersSelected);

  const defaultOrdering = {
    order: winnersSelected ? TableOrder.DESC : TableOrder.ASC,
    orderBy: useAi ? KeywordColumnID.ORGANIC_TRAFFIC_CHANGE : KeywordColumnID.SHARE_OF_VOICE_CHANGE,
  };

  return (
    <DataTable
      tableId={tableName}
      fetchData={fetchKeywords}
      styleMode={TableStyleMode.ROUNDED}
      columns={columns}
      defaultOrdering={defaultOrdering}
      pageSize={10}
      skip={winnersSelected === undefined}
      viewMode={TableSize.DEFAULT}
      tableMinHeight={200}
      dataKey={winnersSelected?.toString()}
      skipSaveOrdering
      syncFilters={{ applyDefaultOrder: true }}
      syncOrdering
      skipClearStoreOnUnmount
    />
  );
};
