import { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withApollo } from '@apollo/client/react/hoc';
import { Box, Flex } from '@mantine/core';
import * as Sentry from '@sentry/react';
import { IconChevronDown, IconChevronUp, IconPlus } from '@tabler/icons-react';
import cn from 'classnames';
import fetchJsonp from 'fetch-jsonp';
import compose from 'lodash/flowRight';
import type { Dispatch } from 'redux';
import { Field, FieldArray, arrayPush, change, formValueSelector, reduxForm } from 'redux-form';
import { showModal } from 'Actions/ModalAction';
import AccButton from 'Components/AccButton/AccButton';
import LocaleDropdownField from 'Components/Controls/Dropdowns/LocaleSelect';
import { linkToImportGSCWithDomains } from 'Components/Filters/LinkToDomain';
import { TagsField } from 'Components/Forms/Fields';
import FormErrors from 'Components/Forms/FormErrors';
import SearchEngineField from 'Components/Forms/SearchEngine';
import { AddKeywordsModalType, AddKeywordsMode } from 'Components/Modal/Content/AddKeywords';
import { ModalFooter } from 'Components/Modal/Layout/ModalFooter';
import AccText from 'Components/Text/AccText';
import {
  useAddKeywordsFormAccountUsageUserQuery,
  useAddKeywordsFormKeywordOveruseEnabledQuery,
  useAddKeywordsFormSimpleLogMutation,
} from 'Ghql';
import toast from 'Hooks/useToast';
import { appStorage } from 'Utilities/AppStorage';
import { t, tct, tn } from 'Utilities/i18n';
import Validation from 'Utilities/validation';
import KeywordSettings from './KeywordSettings';
import { mapStateToProps as keywordsCountMapStateToProps } from './KeywordsCount';
import KeywordsField from './KeywordsField';
import {
  AddKeywordCountInfo,
  formatAddKeywordCountDescription,
  getAddKeywordsCountInfo,
} from './support/helpers';
import type { SearchEngine } from './types';
import styles from './add-keywords-form-styles.module.scss';
import './add-keywords-form.scss';

type Locale = {
  id: string;
  region: string;
  countryCode: string;
  localeShort: string;
  locale: string;
  searchEngines: SearchEngine[];
};
type Props = {
  handleSubmit: (...args: Array<any>) => any;
  onCancel: (...args: Array<any>) => any;
  locales: Locale[];
  localeId: string;
  keywords: string[];
  engines: any[];
  change: (...args: Array<any>) => any;
  resetAdvancedSetting: (...args: Array<any>) => any;
  initialValues: Record<string, any>;
  hasGSC: boolean;
  openHelperOverlay?: (...args: Array<any>) => any;
  showModal: (...args: Array<any>) => any;
  domain: string;
  addEngine: (...args: Array<any>) => any;
  refresh?: () => void;
  submitting: boolean;
  country: string;
  youTubeDomain: boolean;
  maxKeywords: number;
  keywordsCounter: number;
  keydisKeywordsCounter?: number;
  selectedCountry?: any;
  mode?: AddKeywordsModalType;
  keywordCountInfo: AddKeywordCountInfo[];
  totalKeywords?: number;
};

const AddKeywordsForm = (props: Props): JSX.Element => {
  const [showSuggestions, setShowSuggestions] = useState<boolean>(false);
  const [showEnginesDropdown, setShowEnginesDropdown] = useState<boolean>(false);
  const [suggestions, setSuggestions] = useState([]);
  const [suggestionSearchText, setSuggestionSearchText] = useState<string>('');
  const [keywordsOveruseLogged, setKeywordsOveruseLogged] = useState<boolean>(false);

  const inputRef = useRef<HTMLInputElement>(null);

  const { data, refetch: numberOfKeywordsRefetch } = useAddKeywordsFormAccountUsageUserQuery();
  const [numOfKeywords, setNumOfKeywords] = useState<number>(
    data?.user?.organization?.numberOfKeywords ?? 0,
  );

  const [addKeywordsFormSimpleLogMutation] = useAddKeywordsFormSimpleLogMutation({
    variables: {
      input: {
        action: 'NO_MORE_KEYWORDS_ALLOWED_ON_PLAN',
        value: 1,
      },
    },
  });

  const { data: kwOveruseEnabledData, refetch: keywordOveruseRefetch } =
    useAddKeywordsFormKeywordOveruseEnabledQuery();
  const [isKeywordOveruseEnabled, setIsKeywordOveruseEnabled] = useState<boolean>(
    kwOveruseEnabledData?.user?.organization?.keywordOveruseEnabled ?? false,
  );

  /** Adds a simpleLog entry the first time an organization goes into overuse */
  useEffect(() => {
    // const numOfKw = data?.user?.organization?.numberOfKeywords ?? 0;
    if (
      props.maxKeywords < props.keywordsCounter + numOfKeywords &&
      !keywordsOveruseLogged &&
      !isKeywordOveruseEnabled
    ) {
      setKeywordsOveruseLogged(true);
      addKeywordsFormSimpleLogMutation().catch((err) => {
        Sentry.captureException(err);
      });
    }
  }, [
    props.maxKeywords,
    props.keywordsCounter,
    numOfKeywords,
    keywordsOveruseLogged,
    isKeywordOveruseEnabled,
  ]);

  useEffect(() => {
    const domainId = props.domain;
    if (props.mode !== AddKeywordsMode.IMPORT) {
      appStorage.setKeywordModalContent(props.keywords, domainId);
    }
  }, [props.keywords]);

  const handleConnectToGSC = () => {
    const domainId = props.domain;
    props.showModal({
      modalType: 'ConnectToGSC',
      modalTheme: 'light',
      modalProps: {
        domainId,
      },
    });
  };

  const renderImportGCS = () => {
    const { hasGSC, domain: domainId } = props;

    if (hasGSC) {
      return (
        <AccButton
          variant="secondary"
          fullWidth
          disabled={showSuggestions}
          onClick={props.onCancel}
          link={linkToImportGSCWithDomains(domainId)}
        >
          {t('Import from Google Search Console')}
        </AccButton>
      );
    }

    return (
      <AccButton
        variant="secondary"
        fullWidth
        disabled={showSuggestions}
        onClick={handleConnectToGSC}
      >
        {t('Connect to Google Search Console')}
      </AccButton>
    );
  };

  const fetchKeywordSuggestions = (text: string) => {
    const localeId =
      props?.localeId ||
      props?.initialValues?.localeId ||
      props?.initialValues?.engines?.[0]?.countrylocale;
    const locale = props.locales.find((localObj) => localObj.id === localeId);
    fetchJsonp(
      `https://suggestqueries.google.com/complete/search?q=${text}&hl=${
        !locale ? 'en' : locale.localeShort
      }&client=youtube&_=1531379428615&gl=${!locale ? 'us' : locale.countryCode.toLowerCase()}`,
      {
        mode: 'no-cors',
      } as any,
    )
      .then((response) => response.json())
      .then((responseData) => {
        setSuggestions(responseData[1].map((el) => el[0]));
      });
  };

  const handleSuggestionSearchText = (text: string) => {
    setSuggestionSearchText(text);
    fetchKeywordSuggestions(text);
  };

  const handleChangeLocale = (localeId) => {
    const { locales, engines, youTubeDomain } = props;
    const locale = locales.find((localeObj) => localeObj.id === localeId);

    // you cannot add the same locale more than once
    if (engines && locale) {
      const alreadyExists = engines.filter((e) => e.countrylocale === locale.id).length > 0;
      if (alreadyExists) {
        toast.error(t('You cannot add the same locale multiple times.'));
        return;
      }
    }

    props.showModal({
      modalType: 'AddSearchEngine',
      modalTheme: 'light',
      modalProps: {
        locale,
        youTubeDomain,
        initialValues: {
          searchEngines: locale?.searchEngines,
          settings: {
            ignoreLocalResults: false,
            ignoreFeaturedSnippet: false,
            enableAutocorrect: false,
          },
          locations: [''],
        },
        onSubmit: (v) => props.addEngine(v),
      },
      nested: true,
    });
    setShowEnginesDropdown(false);
  };

  // Re-fetch loop
  // Updates isKeywordOveruseEnabled and numOfKeywords every 30 seconds
  useEffect(() => {
    const delay = 30 * 1000; // 30 seconds
    const interval = setInterval(() => {
      keywordOveruseRefetch()
        .then((dat) => {
          setIsKeywordOveruseEnabled(dat.data.user?.organization?.keywordOveruseEnabled ?? false);
        })
        .catch((err) => {
          Sentry.captureException(err);
        });
      numberOfKeywordsRefetch()
        .then((dat) => {
          setNumOfKeywords(dat.data.user?.organization?.numberOfKeywords ?? 0);
        })
        .catch((err) => {
          Sentry.captureException(err);
        });
    }, delay);
    // clear interval on unMount
    return () => clearInterval(interval);
  }, []);

  const { locales, submitting, keywords, keywordsCounter, maxKeywords, youTubeDomain } = props;

  const countDescription = formatAddKeywordCountDescription(
    props.keywordsCounter,
    props.totalKeywords,
    props.keywordCountInfo,
  );

  return (
    <form className="add-keywords-form">
      {maxKeywords < keywordsCounter + numOfKeywords && !isKeywordOveruseEnabled && (
        <>
          <div className={'too-many-keywords-alert'}>
            <span>
              gl
              {tct(
                'You are adding more keywords than your current [maxKeywords] keywords plan allow. You are exceeding your limit by [exceeding] keywords.',
                {
                  maxKeywords,
                  exceeding: -(maxKeywords - (keywordsCounter + numOfKeywords)),
                },
              )}
              <br />
              {tct('You can upgrade your plan [link:here].', {
                link: <Link to={'/account/billing'} />,
              })}
            </span>
          </div>
          <div
            style={{
              paddingTop: '75px',
            }}
          />
        </>
      )}
      <FormErrors />
      <div className="columns-container">
        <div className="keywords-column">
          <div className="form-label">{t('Keywords')}</div>
          <div
            className={cn({
              [styles.import_mode]: props.mode === AddKeywordsMode.IMPORT,
            })}
          >
            <Field
              name="keywords"
              component={KeywordsField}
              validate={Validation.array}
              placeholder={t('Enter your keywords (one per line)')}
              mode={props.mode}
              disabled={props.mode === AddKeywordsMode.IMPORT}
            />
          </div>
          {props.mode !== AddKeywordsMode.IMPORT && (
            <AccButton
              onClick={() => {
                setShowSuggestions(!showSuggestions);
                inputRef?.current?.focus();
              }}
              variant="tertiary"
              fullWidth
              mb="md"
              leftSection={
                showSuggestions ? <IconChevronUp size={18} /> : <IconChevronDown size={18} />
              }
            >
              {showSuggestions ? t('Hide keyword suggestions') : t('Show keyword suggestions')}
            </AccButton>
          )}

          <div
            className={cn({
              'add-keywords-suggestion-list': showSuggestions,
            })}
          >
            <input
              ref={inputRef}
              type="text"
              className={cn('add-keywords-suggestion-input', {
                hide: !showSuggestions,
              })}
              value={suggestionSearchText}
              onChange={(e) => handleSuggestionSearchText(e.target.value)}
              placeholder={t('Start typing for keyword suggestions…')}
            />

            {showSuggestions && suggestions.length ? (
              <>
                <div className="add-keywords-suggestion-disclaimer">
                  {t('Click on a keyword to add/remove it in the list above')}
                </div>
                {suggestions.map((el, i) => {
                  const exists = keywords.includes(el);
                  return (
                    <div
                      key={i}
                      className={cn('add-keywords-suggestion-tag', {
                        disabled: exists,
                      })}
                      onClick={() =>
                        props.change(
                          'keywords',
                          !exists ? [...keywords, el] : keywords.filter((kw) => kw !== el),
                        )
                      }
                    >
                      {el}
                    </div>
                  );
                })}
              </>
            ) : null}
          </div>

          {props.mode !== AddKeywordsMode.IMPORT && (
            <Flex direction="column" rowGap="sm" className={cn({ disabledLink: showSuggestions })}>
              <div>{renderImportGCS()}</div>
              <AccButton
                link={'/import'}
                onClick={props.openHelperOverlay}
                disabled={showSuggestions}
                variant="secondary"
                fullWidth
              >
                {t('Import from CSV')}
              </AccButton>
            </Flex>
          )}
        </div>
        <div className="settings-column">
          <div className="form-label">{t('Search Engine, Locale and Location')}</div>

          <FieldArray
            name="engines"
            countryLocales={locales}
            showModal={props.showModal}
            component={SearchEngineField}
            youTubeDomain={youTubeDomain}
            validate={Validation.array}
            selectedCountry={props.selectedCountry}
          />

          {showEnginesDropdown ? (
            <LocaleDropdownField
              placeholder={t('Please add at least one locale')}
              locales={locales}
              onChange={handleChangeLocale}
              autoFocus={showEnginesDropdown}
            />
          ) : (
            <AccButton
              mt="xxs"
              variant="secondary"
              leftSection={<IconPlus size={18} />}
              onClick={() => {
                setShowEnginesDropdown(true);
              }}
            >
              {t('Add Search Engine')}
            </AccButton>
          )}

          <Box mt="xs" className="form-label">
            {t('Tags')}
          </Box>
          <Field
            placeholder={t('Enter tags here')}
            excludeDynamic={true}
            component={TagsField}
            name="tags"
            creatable
          />
          <div className="form-label">{t('Advanced Settings')}</div>
          <Field component={KeywordSettings} name="keywordSettings" />
        </div>
      </div>
      <ModalFooter
        primaryButtonSlot={
          <AccButton
            variant="primary"
            disabled={
              submitting ||
              !keywordsCounter ||
              (maxKeywords < keywordsCounter + numOfKeywords && !isKeywordOveruseEnabled)
            }
            loading={submitting}
            onClick={props.handleSubmit}
          >
            <span className={styles.importBtnText}>
              {tn('Add keyword', 'Add keywords', keywordsCounter)}
            </span>
          </AccButton>
        }
        secondaryButtonSlot={
          <AccButton variant="tertiary" onClick={props.onCancel}>
            {t('Cancel')}
          </AccButton>
        }
        textSlot={
          <AccText variant="label">
            <div className="keywords-count">
              {t('Keywords to be added:')}
              <b>&nbsp;{keywordsCounter}&nbsp;</b>
              {countDescription ? <small>{`= ${countDescription}`}</small> : null}
            </div>
          </AccText>
        }
      />
    </form>
  );
};

const ReduxAddKeywordsForm = reduxForm({
  form: 'AddKeywordsForm',
})(AddKeywordsForm);
const formSelector = formValueSelector('AddKeywordsForm');

const mapStateToProps = (state, ownProps) => {
  const keywords = formSelector(state, 'keywords');
  const engines = formSelector(state, 'engines');
  let totalKeywords;

  if (ownProps.mode === AddKeywordsMode.IMPORT) {
    totalKeywords = ownProps.keydisKeywordsCounter;
  } else {
    totalKeywords = keywords ? keywords.length : 0;
  }

  return {
    ...keywordsCountMapStateToProps(state),
    ...getAddKeywordsCountInfo(engines, totalKeywords, ownProps.locales),
    totalKeywords,
    localeId: formSelector(state, 'localeId'),
    keywords,
    engines,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  change: (field: string, value: any) =>
    dispatch(change('AddKeywordsForm', field, value, true, false)),
  addEngine: (value: any) => dispatch(arrayPush('AddKeywordsForm', 'engines', value)),
  showModal: (modalProps) => dispatch(showModal(modalProps)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  withApollo(ReduxAddKeywordsForm),
);
