import * as React from 'react';
import {
  AlignType,
  CellEllipsisType,
  DataIndex,
  FixedType,
  Key,
} from 'Components/DataTable/table-core/interface';
import { SORTING_TYPE } from 'Pages/Keywords/Table/hooks/keyword/constants';
import { FilterBase } from 'Types/Filter';
import { SelectRowType, TableOrder, TableSize } from './constants';
import { TableStoreType } from './store/TableStore';

export interface ColumnSetting<T = string> {
  id: string;
  queryWithClauses: T[];
  tableSetting: {
    getLabel?: () => string;
    requiresAnalytics?: boolean;
    requiresAdvancedMetrics?: boolean;
    required?: boolean;
    defaultHide?: boolean;
    grouping?: string;
    orderBy?: string;
    groupingOrder?: 'after' | 'before';
    disabled?: boolean;
  };
}

export type CellRendererProps<RecordType = any> = {
  record: RecordType;
  // use record instead
  value: { data: RecordType };
  updateRowData: (patch: Partial<RecordType>) => void;
};

export type CellRenderer<RecordType = any, OtherProps = any> = (
  props: CellRendererProps<RecordType> & OtherProps,
) => React.ReactNode | null;

interface OnHeaderCellProps {
  ordering?: {
    defaultOrder: TableOrder;
    orderBy: string;
    /** Sort by description used for the menu.
     *
     * Defaults to `Ascending` and `Descending`
     */
    sortingKey?: SORTING_TYPE;
  };
  tooltip?: string;
  filter?: {
    filterAttributes: string[];
    filterTooltip: string;
    filterContent?: JSX.Element;
    filterClassName?: string;
    hasLargeFilter?: boolean;
  };
  type?: 'checkbox';
  /** Add a custom menu title. Defaults to title */
  menuTitle?: string;
  /** Reverse text and icon order*/
  reverseDirection?: boolean;
}

// eslint-disable-next-line @typescript-eslint/ban-types, @typescript-eslint/no-explicit-any
export interface ColumnType<RecordType = { [key: string]: any }, OtherProps = any> {
  title?: React.ReactNode;
  key?: Key;
  id?: string;
  dataToCopy?: (record: RecordType) => unknown;
  className?: string;
  fixed?: FixedType;
  flex?: boolean;
  ellipsis?: CellEllipsisType;
  align?: AlignType;
  colSpan?: number;
  dataIndex?: DataIndex;
  cellRenderer?: CellRenderer<RecordType, OtherProps>;
  cellRendererParams?: object;
  combineRow?: string;
  sortField?: string;
  // shouldCellUpdate?: (record: RecordType, prevRecord: RecordType) => boolean;
  rowSpan?: number;
  width?: number | string;
  onCell?: (record: RecordType, rowId: number) => void;
  /**Pass additional parameters for rendering the header row*/
  onHeaderCell?: () => OnHeaderCellProps;
}

export type BodyCellRender = ColumnType['cellRenderer'];

export interface ColumnFilter {
  filterAttributes: FilterBase['attribute'][];
  hasLargeFilter?: boolean;
  hasMediumFilter?: boolean;
  filterTooltip?: string;
  filterLabel?: string;
  containerClassName?: string;
  filterId?: string;
  filterContent?: React.ReactNode;
  filterClassName?: string;
}

export interface ColumnOrdering {
  defaultOrder: 'DESC' | 'ASC';
  orderBy: string;
  sortingKey?: SORTING_TYPE;
}

export interface ColumnOrderingState {
  order: TableOrder;
  orderBy: string;
}

export interface PaginationState {
  startIndex: number;
  stopIndex: number;
  page: number;
  results: number;
}

export type CellContext = {
  viewMode: TableSize;
  toggleExpandRow: (id: string) => void;
  expandedRows: (string | number)[];
  expandedExtraRows: (string | number)[];
  toggleExpandRowConfig: (id: string) => void;
};

export interface TablePagination {
  page: number;
  results: number;
  startIndex: number;
  stopIndex: number;
}

export interface TableFetchDataCallbackParams {
  ordering: ColumnOrderingState;
  pagination: TablePagination;
  force?: boolean;
  tableStore: TableStoreType;
}

export type TableFetchDataCallBackReturn<T> = {
  data: T[];
  length?: number | null;
  // if true - means that request was skipped
  skip?: boolean;
};

export type TableFetchDataCallBack<T = any> = (
  params: TableFetchDataCallbackParams,
) => Promise<TableFetchDataCallBackReturn<T>>;

export interface TableLabels {
  cellLoadingTooltip?: string;
  paginationLoading?: string;
  paginationLoadingWithDetails?: (a: { from: string; to: string; total: number }) => string;
  paginationDetails?: (a: { from: string; to: string; total: number | JSX.Element }) => string;
  itemsUpdate?: (added?: number, removed?: number) => string;
}

export interface OptimizationConfig {
  primaryColumns: string[];
}

export type SelectConfig = {
  // strategies of handling selecting rows in the table, default: ONE_PAGE_SELECT
  selectRowType?: SelectRowType;
  // limit number of select all items, if empty no limit on select
  maxSelectCount?: number;
};

export enum TableStyleMode {
  DEFAULT = 'default',
  ROUNDED = 'rounded',
}
