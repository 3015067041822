import { useMemo } from 'react';
import ModalBorder from 'Components/Modal/Layout/ModalBorder';
import { extractErrorFromGhql } from 'Hooks/Graphql/helpers';
import { useMutate } from 'Hooks/Graphql/useMutate';
import { useModal } from 'Hooks/base/useModal';
import { useDomainId } from 'Hooks/data/domain/useQueryDomainInfo';
import toast from 'Hooks/useToast';
import { DynamicTagsBuilder } from 'Pages/DomainSettings/components/DynamicTagsBuilder/DynamicTagsBuilder';
import style from 'Pages/DomainSettings/components/DynamicTagsBuilder/dynamic-tags.module.scss';
import {
  MUTATION_ADD_DYNAMIC_TAG_RULESET,
  MUTATION_UPDATE_DYNAMIC_TAG_RULESET,
} from 'Pages/DomainSettings/components/DynamicTagsBuilder/support/dynamig-tags.query';
import {
  getDefaultTagsFormValues,
  validateRulesForm,
} from 'Pages/DomainSettings/components/DynamicTagsBuilder/support/helpers';
import { convertDynamicTagsToInitialValues } from 'Pages/DomainSettings/support/helpers';
import { DynamicTagFormValues, DynamicTagItem } from 'Pages/DomainSettings/support/types';
import { useGroupingStore } from 'Pages/Keywords/Groupings/support/groupingsStore';
import { useInvalidateKeywordCache } from 'Utilities/Graphql/invalidateCache';
import { t } from 'Utilities/i18n';

type Props = {
  initialValues?: DynamicTagItem;
  isEdit?: boolean;
  updatedAt?: string;
  createdBy?: string;
};

const CreateOrEditDynamicTagModal = (props: Props) => {
  const { initialValues: initialValuesProps, createdBy, updatedAt } = props;

  const { hideModal } = useModal();
  const domainId = useDomainId();

  const [addTagRuleset] = useMutate(MUTATION_ADD_DYNAMIC_TAG_RULESET, { isForm: true });
  const [updateTagRuleset] = useMutate(MUTATION_UPDATE_DYNAMIC_TAG_RULESET, { isForm: true });

  const groupingStore = useGroupingStore();
  const invalidateKeywordCache = useInvalidateKeywordCache();

  const isEdit = props.isEdit;
  const initialValues = useMemo(() => {
    return initialValuesProps
      ? convertDynamicTagsToInitialValues(initialValuesProps)
      : getDefaultTagsFormValues();
  }, [initialValuesProps]);

  const handleSave = async (form: DynamicTagFormValues) => {
    const localErrors = validateRulesForm(form);

    if (localErrors) {
      return localErrors;
    }
    const result: any = {
      tag: form.tag,
      rawRuleset: JSON.stringify(form.rawRuleset),
      folderPath: form.folder,
    };

    if (form?.id) {
      result.id = form.id;
    }
    result.domainId = parseInt(domainId as string);

    const res = form?.id ? await updateTagRuleset(result) : await addTagRuleset(result);
    const errors = extractErrorFromGhql(res, true);
    if (errors) {
      return errors;
    }

    invalidateKeywordCache();
    await groupingStore.refetch(false);
    hideModal();
    toast.success(isEdit ? t('Dynamic tag was updated!') : t('New Dynamic tag was added!'));
  };

  return (
    <ModalBorder
      className={style.modal}
      title={isEdit ? t('Edit Dynamic Tag') : t('Create Dynamic Tag')}
      onClose={hideModal}
    >
      <DynamicTagsBuilder
        onClose={hideModal}
        onSubmit={handleSave}
        initialValues={initialValues as DynamicTagFormValues}
        isEdit={isEdit}
        createdBy={createdBy}
        updatedAt={updatedAt}
      />
    </ModalBorder>
  );
};

export default CreateOrEditDynamicTagModal;
