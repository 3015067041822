import { ApolloClient, ApolloLink, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { WebSocketLink } from '@apollo/client/link/ws';
import { composeWithDevToolsDevelopmentOnly } from '@redux-devtools/extension';
import {
  Debug as DebugIntegration,
  ExtraErrorData as ExtraErrorDataIntegration,
} from '@sentry/integrations';
import * as Sentry from '@sentry/react';
import { Breadcrumb } from '@sentry/react';
import SentryRRWeb from '@sentry/rrweb';
import { SentryLink } from 'apollo-link-sentry';
import { getMainDefinition } from 'apollo-utilities';
import config from 'config';
import isEmpty from 'lodash/isEmpty';
import { Store, applyMiddleware, combineReducers, createStore } from 'redux';
import {
  middleware as asyncMiddleware,
  innerReducer,
  outerReducer,
} from 'redux-async-initial-state';
import { reducer as formReducer } from 'redux-form';
import thunk from 'redux-thunk';
import { SubscriptionClient } from 'subscriptions-transport-ws';
// import * as ws from 'ws';
import { initFilters, updateFilterGroups } from 'Actions/FilterAction';
import { showMethodNotAllowedModal } from 'Actions/MethodNotAllowedAction';
import { CLEAR_EVERYTHING, SET_EVERYTHING } from 'Actions/ResetAction';
import { showPermissionDeniedModal } from 'Actions/ShowPermissionDenied';
import { GenericGetUserQuery, StoreDomainListDocument, StoreDomainListQuery } from 'Ghql';
import type { TableKeywordNode } from 'Ghql/customTypes';
import localeMiddleware from 'Middlewares/localeMiddleware';
import reduxFormMiddleware from 'Middlewares/reduxFormMiddleware';
import User from 'Queries/user';
import DomainsReducer from 'Reducers/DomainsReducer';
import GoogleAccountsReducer from 'Reducers/GoogleAccountsReducer';
import KeywordsTableReducer from 'Reducers/KeywordsTableReducer';
import OverviewPageReducer from 'Reducers/OverviewPageReducer';
import ServiceMessageReducer from 'Reducers/ServiceMessageReducer';
import TableReducer from 'Reducers/TableReducer';
import {
  DomainsFilter,
  FilterAttribute,
  FilterComparison,
  FilterGroup,
  FilterValueType,
  parseValue,
} from 'Types/Filter';
import { StoreType } from 'Types/Store';
import { getTableKeywordNodeCacheId } from 'Utilities/Graphql/keywords';
import { graphqlEndpoint, graphqlWebSocketEndpoint } from 'Utilities/runtimeConfig';
import { notEmpty, redirectToExternalUrl } from 'Utilities/underdash';
import FilterReducer from './Reducers/FilterReducer';
import LoadingReducer from './Reducers/LoadingReducer';
import MetaDataReducer from './Reducers/MetaDataReducer';
import ModalReducer from './Reducers/ModalReducer';
import OrderPlanReducer from './Reducers/OrderPlanReducer';
import ReportTemplateReducer from './Reducers/ReportTemplateReducer';
import ScrollReducer from './Reducers/ScrollReducer';
import UserReducer from './Reducers/UserReducer';
import Storage from './Utilities/storage';

const isProd = process.env.NODE_ENV !== 'development';
const isTest = process.env.NODE_ENV === 'test';

const sentryIntegrations: any[] = [new SentryRRWeb(), new ExtraErrorDataIntegration()];
if (!isProd) {
  // Only add the Debug integration on non production builds
  sentryIntegrations.push(new DebugIntegration());
}

// removing sentry.init in test env since it breaks apollo mocks
if (!isTest) {
  const denyUrls = [
    /o2\.mouseflow\.com/i,
    /stats\.g\.doubleclick\.net/i,
    /www\.google-analytics\.com/i,
    /maps\.googleapis\.com/i,
  ];

  Sentry.init({
    dsn: config.services.sentry,
    defaultIntegrations: false,
    maxBreadcrumbs: 50,
    // TODO FixTSignore
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    release: COMMITHASH,
    // eslint-disable-line
    environment: config.services.sentryEnvironment,
    attachStacktrace: true,
    integrations: sentryIntegrations,
    ignoreErrors: [
      // Resize observer loops can be safely ignored according to random people on the internet
      'ResizeObserver loop limit exceeded',
      'ResizeObserver loop completed with undelivered notifications.',
      // Probably because the user is running adblock
      'Google Tag Manager could not be loaded.',
      // Ignore client side network errors
      'Network error: Failed to fetch',
      'Failed to fetch',
      'NetworkError when attempting to fetch resource.',
      'AbortError: The user aborted a request.',
    ],
    denyUrls,
    beforeBreadcrumb(breadcrumb: Breadcrumb): Breadcrumb | null {
      // Any URL added to denyUrls will also not be included in the breadcrumbs (we filter them here)
      if (breadcrumb.category === 'xhr') {
        const url = breadcrumb.data?.url ?? null;
        return denyUrls.some((pattern) => pattern.test(url)) ? null : breadcrumb;
      }
      return breadcrumb;
    },
    normalizeDepth: 10,
  });
}

const sentryReduxEnhancer = Sentry.createReduxEnhancer();

// const HttpLinkClass = process.env.NODE_ENV === 'fakeapi' ? HttpLink : BatchHttpLink;
// const HttpLinkClass = HttpLink;
// TODO The batching link does not add cookies
// https://github.com/apollographql/apollo-link/issues/44
const networkInterfaceOptions = {
  fetch,
  uri: graphqlEndpoint(),
  credentials: config.credentials || 'same-origin',
};

// TODO FixTSignore
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
if (process.env.NODE_ENV !== 'fakeapi') networkInterfaceOptions.batchInterval = 10;

const appReducer = combineReducers({
  form: formReducer,
  orderPlan: OrderPlanReducer,
  user: UserReducer,
  metaData: MetaDataReducer,
  asyncInitialState: innerReducer,
  loadingOverlay: LoadingReducer,
  modal: ModalReducer,
  filter: FilterReducer,
  reportTemplate: ReportTemplateReducer,
  table: TableReducer as any,
  scrollToElement: ScrollReducer,
  googleAccounts: GoogleAccountsReducer,
  overviewPage: OverviewPageReducer as any,
  keywordsTable: KeywordsTableReducer,
  domains: DomainsReducer,
  serviceMessage: ServiceMessageReducer,
});

const rootReducer = (state, action) => {
  if (action.type === CLEAR_EVERYTHING) {
    state = undefined;
  } else if (action.type === SET_EVERYTHING) {
    state = action.payload;
  }

  return appReducer(state, action);
};

export const reducers = outerReducer(rootReducer);

let middlewareLink: ApolloLink;

if (process.env.NODE_ENV === 'fakeapi' || process.env.NODE_ENV === 'test') {
  middlewareLink = setContext(() => {
    const token = Storage.getFromAll('authToken');

    if (token) {
      return {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
    }

    return {
      headers: {},
    };
  });
} else {
  middlewareLink = setContext(() => {
    const token = Storage.getFromAll('authToken');

    const headers: any = {
      // TODO FixTSignore
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      'X-Frontend-Version': COMMITHASH, // eslint-disable-line
    };

    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }

    return {
      headers,
    };
  });
  middlewareLink = middlewareLink.concat(
    // Log graphql queries in breadcrumbs
    new SentryLink({
      attachBreadcrumbs: {
        includeQuery: false,
        includeVariables: true,
        includeError: true,
      },
    }),
  );
  middlewareLink = middlewareLink.concat(
    onError(({ graphQLErrors, networkError }) => {
      if (!isEmpty(graphQLErrors) || networkError) {
        if (networkError) {
          // 500
          console.error('[Network error]', networkError); // redirectToExternalUrl('/app/error/500');
        } else {
          const errorMessages = graphQLErrors?.reduce((messages, error) => {
            messages.push(error.message);
            return messages;
          }, [] as string[]);

          if (graphQLErrors?.filter((e) => e.message.includes('500'))?.length) {
            graphQLErrors.forEach(({ message, locations, path }) => {
              const errorMessage = `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`;
              Sentry.captureException(errorMessage);
            }); // 500
            // redirectToExternalUrl(`/app/error/500/${sentryEventId}?errors=${encodeURI(errorMessages)}`);
          } else if (graphQLErrors?.filter((e) => e.message.includes('404'))?.length) {
            // 404
            redirectToExternalUrl(
              `/app/error/404?errors=${encodeURI(errorMessages?.join('') ?? '')}`,
            );
          } else if (graphQLErrors?.filter((e) => e.message.includes('401'))?.length) {
            // 401
            redirectToExternalUrl(`/app/login/?next=${window.location.pathname}`);
          } else if (graphQLErrors?.filter((e) => e.message.includes('403'))?.length) {
            // 403
            // show modal that user dont have permission to trigger this action
            showPermissionDeniedModal();
          } else if (graphQLErrors?.filter((e) => e.message.includes('405'))?.length) {
            // 405
            // show modal that user dont have access to this account or data is not valid any more
            showMethodNotAllowedModal();
          } else {
            graphQLErrors?.forEach(({ message, locations, path }) => {
              const errorMessage = `[Unhandled GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`;
              Sentry.captureException(errorMessage);
            });
          }
        }
      }
    }),
  );
}

const createWsLink = () => {
  return new WebSocketLink(
    new SubscriptionClient(graphqlWebSocketEndpoint(), {
      reconnect: true,
      lazy: true,
    }),
  );
};
const combinedLink = ApolloLink.split(
  ({ query }) => {
    // Only send subscriptions through the websocket
    // TODO FixTSignore
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { kind, operation } = getMainDefinition(query);
    return kind === 'OperationDefinition' && operation === 'subscription';
  },
  createWsLink(),
  // TODO FixTSignore
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  middlewareLink.concat(createHttpLink(networkInterfaceOptions)),
);

export const apolloClient = new ApolloClient({
  link: combinedLink,
  cache: new InMemoryCache({
    addTypename: true,
    /**
     * Required for proper keywords query cache working
     * @issue: https://accuranker.myjetbrains.com/youtrack/issue/ARR-1982
     * Without composite id - if we cache some `TableKeywordNode` with specific id (on query),
     * when we will change range, even though attributes differ - we still got same cached entity
     * to avoid it we need to define what should be proper unique identifier
     */
    dataIdFromObject: (obj) => {
      if (obj.__typename === 'TableKeywordNode') {
        return getTableKeywordNodeCacheId(obj as TableKeywordNode);
      }
      if (obj.__typename === 'OverviewCompetitorStatsChartNodeNode') {
        return `OverviewCompetitorStatsChartNodeNode:${obj.domain}`;
      }
      // if (obj.__typename === 'GraphsNode') {
      //   return `GraphsNode:${Object.keys(obj)[1]}`; // eg. GraphsNode:competitorHistory
      // }
      // if (obj.__typename === 'ChartsNode') {
      //   return `ChartsNode:${Object.keys(obj)[1]}`; // eg. GraphsNode:competitorHistory
      // }
    },
    typePolicies: {
      ImportNode: {
        keyFields: ['id'], // needed for Apollo writeQuery
      },
    },
  }),
  queryDeduplication: true,
});
// const combinedLinkV3 = ApolloLinkV3.split(
//   ({ query }) => {
//     // Only send subscriptions through the websocket
//     const { kind, operation } = getMainDefinition(query);
//     return kind === 'OperationDefinition' && operation === 'subscription';
//   },
//   new WebSocketLink(
//     new SubscriptionClient(graphqlWebSocketEndpoint(), {
//       reconnect: true,
//       lazy: true,
//     }),
//   ),
//   middlewareLink.concat(createHttpLinkV3(networkInterfaceOptions)),
// );
// export const apolloClientV3 = new ApolloClientV3({
//   link: combinedLinkV3,
//   cache: new InMemoryCacheV3({ addTypename: true }),
//   // cache: new InMemoryCache({ addTypename: true }),
//   //queryDeduplication: true,
// });
// TODO we should not use redux store for storing graphql data
// as it's not updating when graphql cache is updating
export const loadStore = (getCurrentState: () => StoreType) => {
  return apolloClient
    .query<GenericGetUserQuery>({
      query: User.queries.getUser,
      fetchPolicy: 'network-only',
    })
    .then((userResponse) => {
      if (userResponse?.data?.user?.isAuthenticated) {
        return apolloClient
          .query<StoreDomainListQuery>({
            query: StoreDomainListDocument,
            fetchPolicy: 'network-only',
          })
          .then((domainResponse) => {
            const domainIds =
              domainResponse?.data?.domainsList?.map((domain) => domain?.id).filter(notEmpty) || [];
            const domainsFilter: DomainsFilter = {
              attribute: FilterAttribute.DOMAINS,
              type: FilterValueType.LIST,
              comparison: FilterComparison.CONTAINS,
              value: domainIds,
            };
            const savedFilters = userResponse?.data?.user?.savedFilters
              ?.filter(notEmpty)
              .map((userSavedFilter) => ({
                ...userSavedFilter,
                filters: JSON.parse(userSavedFilter?.filters).map((filter) => ({
                  ...filter,
                  value: parseValue(filter),
                })) as FilterGroup[],
              }));
            const filterStore = {
              ...FilterReducer(
                FilterReducer(getCurrentState().filter, initFilters(domainsFilter)),
                updateFilterGroups((savedFilters as unknown as FilterGroup[]) ?? []),
              ),
            };
            return {
              ...getCurrentState(),
              metaData: domainResponse?.data?.metaData,
              user: { ...userResponse.data.user, debug: false },
              filter: {
                ...filterStore,
                pristine: true,
                defaultCompareTo: userResponse?.data?.user?.defaultCompareTo,
              },
            };
          });
      }

      return { ...getCurrentState(), user: { ...userResponse.data.user, debug: false } };
    });
};

const composeEnhancers = composeWithDevToolsDevelopmentOnly({
  trace: true,
});

const store: Store<StoreType> = createStore(
  reducers,
  composeEnhancers(
    applyMiddleware(
      thunk,
      asyncMiddleware(loadStore),
      //TODO FixTSignore
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      localeMiddleware,
      reduxFormMiddleware,
    ),
    sentryReduxEnhancer,
  ),
) as Store<StoreType>;
export { store };
