import React, { Suspense } from 'react';
import { AddKeywordsMode } from 'Components/Modal/Content/AddKeywords';
import Skeleton from 'Components/Skeleton';
import './keywordsfield.scss';

const ReactAce = React.lazy(
  () =>
    // eslint-disable-next-line no-async-promise-executor
    new Promise(async (resolve) => {
      const module = await import('react-ace');
      const module2 = await import('ace-builds/src-noconflict/theme-chrome');
      const module3 = await import('ace-builds/src-noconflict/mode-text');
      resolve({ ...module, ...module2, ...module3, default: module.default });
    }),
);

export const withAceEditor = <T extends { mode: string }>(Component: React.ElementType) => {
  const AceEditor = (props: T): JSX.Element => {
    const style = { height: props.mode === AddKeywordsMode.IMPORT ? '338px' : '200px' };
    return (
      <Suspense
        fallback={
          <div style={style}>
            <Skeleton
              linesConfig={[{ type: 'text', options: { width: '40%' } }, { type: 'input' }]}
            />
          </div>
        }
      >
        <Component {...props} aceComponent={ReactAce} />
      </Suspense>
    );
  };
  AceEditor.displayName = 'AceEditor';

  return AceEditor;
};
