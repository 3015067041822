/* eslint-disable import/no-unused-modules */
import React, { FC, useEffect, useMemo, useRef } from 'react';
import { useApolloClient } from '@apollo/client';
import cn from 'classnames';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import { TableOfflineFilter, useOfflineFilter } from 'Components/DataTable';
import { WithOptionalFilterSync } from 'Components/DataTable/components/data/WithOptionalFilterSync';
import { useFetchWithOfflineFilter } from 'Components/DataTable/hooks/offline/useFetchWithOfflineFilter';
import Table from 'Components/DataTable/table-core';
import withProps from 'Components/HOC/withProps';
import { TableID } from 'Types/Table';
import { invalidateCache } from 'Utilities/Graphql/invalidateCache';
import { BodyCell } from './components/body/BodyCell/BodyCell';
import { SearchVolumeDetailsRow } from './components/body/BodyRow/SearchVolumeDetailsRow';
import { DynamicItemsIndicator } from './components/common/TableInfo/DynamicItemsIndicator';
import { DynamicSelectionInfo } from './components/common/TableInfo/DynamicSelectionInfo';
import { TablePlaceholder } from './components/common/TablePlaceholder';
import TableFooterContainer from './components/footer/TableFooterContainer/TableFooterContainer';
import { HeaderCell } from './components/header/HeaderCell/HeaderCell';
import { ENABLE_OPTIMIZATION_MIN_COLS, MIN_TABLE_HEIGHT, TableSize } from './constants';
import { getDefaultTableHeight, tableClassName } from './helpers';
import { useColumns } from './hooks/columns/useColumns';
import { useOptimized } from './hooks/store/useOptimized';
import { useEffectOnce } from './hooks/useEffectOnce';
import { OptimizationStore } from './store/OptimizationStore';
import { TableStoreType, deleteTableStore, getTableStore } from './store/TableStore';
import {
  OptimizationContextType,
  OptimizationStoreContext,
} from './store/components/OptimizationContext';
import { TableStoreContext } from './store/components/TableStoreContext';
import {
  ColumnOrderingState,
  ColumnType,
  OptimizationConfig,
  SelectConfig,
  TableFetchDataCallBack,
  TableLabels,
  TableStyleMode,
} from './types';
import './table.scss';

export interface TableSyncFiltersConfig {
  /**
   * set default order on filters change
   */
  applyDefaultOrder?: boolean;
}
export interface DataTableProps<T = any> {
  styleMode?: TableStyleMode;
  /**
   * Id of the table, required since will be used for mobx store ialization,
   * after that we can get it by `const tableStore = useTableStore(tableId)`
   */
  tableId: TableID;
  /**
   * Used to fetch data, will be triggered when pagination/ordering will be changed.
   */
  fetchData: TableFetchDataCallBack;
  /**
   * Default viewMode, can be controlled from tableStore
   */
  viewMode?: TableSize;
  /**
   * used to define columns: [Definition](/?path=/story/datatable-api--page#columntype)
   */
  columns: ColumnType<T>[];
  /**
   * default ordering, for example:
   * `{ order: Order, orderBy: string }`
   */
  defaultOrdering?: ColumnOrderingState;

  /**
   * used to provide custom labels, pagination won't work without provided labels
   */
  labels?: TableLabels;

  // @deprecated
  optimizationConfig?: OptimizationConfig | false;

  /**
   * used to disable pagination
   */
  pagination?: boolean;

  /**
   * used to force table to re-fetch table, usually done to notify table to fetch data
   * in case table don't have subscription to it, but it used in fetchData
   */
  dataKey?: string;

  /**
   * skip fetching data while true
   */
  skip?: boolean;

  /**
   * Customization for empty data placeholder
   */
  emptyOptions?: {
    title?: string;
    subTitle?: string | JSX.Element;
    // if true, won't show clear filter btn
    noFilters?: boolean;
    noDataWithFiltersTitle?: string;
    noDataWithFiltersSubTitle?: string;
  };
  /**
   * Will delete store on unmount, in future will be true by default
   */
  unmountStore?: boolean;
  /**
   * Default selected
   */
  defaultSelected?: string[];
  onSelectToggle?: (value: string, checked: boolean) => void;

  customExpandComponent?: FC<React.PropsWithChildren<{ id: string } & any>>;

  /** columns that should be visible for expanded rows */
  expandRowColumnIds?: string[];

  /**
   * @deprecated
   * - initially was introduced for keydis table in order to specify item selected by default
   * - removed in scope of https://accuranker.myjetbrains.com/youtrack/issue/ARR-2438
   */
  initialRowSelect?: (data: any[]) => string[];

  /**
   * skip showing selected count banner
   * https://accuranker.myjetbrains.com/youtrack/issue/ARR-1892
   */
  skipSelectedCount?: boolean;

  /** customize select row functionality
   *via checkboxes and top panel with select all
   */
  selectConfig?: SelectConfig;

  /** Min height of the table */
  tableMinHeight?: number;

  /** Number of items shown per page, also sent to backend */
  pageSize?: number;

  /** If true scroll to the first row on page change */
  scrollOnPageChange?: boolean;

  /** Skip saving ordering to local storage */
  skipSaveOrdering?: boolean;

  /**  if true we unselect item that is not part of data
   * for example for tags cloud page - on filter change we remove old selection
   * https://accuranker.myjetbrains.com/youtrack/issue/ARR-2698/TagsCloud-select-logic-issues
   */
  syncSelected?: boolean;

  /**
   * used to make filtering on client side, meaning that we receive from backend all data and no extra requests will be made.
   * side effect of providing offline filters is that result from fetchData will be sorted/filtered/paginated before passing into table
   * @example
   * ```
   * {
   *   skip: ['domains'],
   *   mappings: { [NotesTableIDs.DATE_CREATED]: 'createdAt },
   *   sortTypes: { createdAt: Sort.DATE },
   *   transformData: { keywords: (kws) => kws.map(({ id }) => id)},
   *   tableName: TableIDs.NOTES,
   * }
   * ```
   * Check [TableOfflineFilters](`Components/DataTable/hooks/offline/useOfflineFilter.ts`) for more details
   */
  offlineFilter?: TableOfflineFilter;

  /**
   * If true or object with configuration - refresh page on filter change
   */
  syncFilters?: TableSyncFiltersConfig | boolean;

  /**
   * change ordering if defaultOrdering changed
   */
  syncOrdering?: boolean;
  forceRenderSkeleton?: boolean;
  /**
   * Control visibility of top panel with select info and table updates info,
   * currently used by keywords and keydis tables, we should update labels before exposing it to other tables
   */
  showCountInfo?: boolean;

  /**
   * Remove border on row level
   */
  borderless?: boolean;

  /**
   * Optional class passed to the table
   */
  className?: string;

  /**
   * Skip clearing store on unmount, should be cleared manually in such case
   */
  skipClearStoreOnUnmount?: boolean;
}

const MemoizedTable = React.memo(Table, isEqual);

const DataTableComponent: React.FC<React.PropsWithChildren<DataTableProps>> = (props) => {
  const container = useRef<any>();
  const client = useApolloClient();

  const prepareData = useOfflineFilter(props.offlineFilter);
  const fetchData: TableFetchDataCallBack = useFetchWithOfflineFilter(props.fetchData, prepareData);

  const tableStore: TableStoreType = useMemo(
    () =>
      getTableStore(props.tableId, {
        fetchData,
        defaultOrdering: props.defaultOrdering,
        defaultSelected: props.defaultSelected,
        onSelectToggle: props.onSelectToggle,
        initialRowSelect: props.initialRowSelect,
        selectRowType: props.selectConfig?.selectRowType,
        maxSelectCount: props.selectConfig?.maxSelectCount,
        pageSize: props.pageSize,
        scrollOnPageChange: props.scrollOnPageChange,
        viewMode: props.viewMode,
        skipSaveOrdering: props.skipSaveOrdering,
        syncSelected: props.syncSelected,
      })!,
    [],
  );

  useEffect(() => {
    tableStore.setFetchData(fetchData);
  }, [tableStore, fetchData]);

  const data = toJS(tableStore?.data) ?? [];
  const optimizationEnabled = useMemo(() => {
    return (
      props.optimizationConfig &&
      props.columns?.filter(
        (e) =>
          !(props.optimizationConfig as OptimizationConfig)?.primaryColumns?.includes(e.id ?? ''),
      )?.length > ENABLE_OPTIMIZATION_MIN_COLS
    );
  }, [props.columns]);

  const optimizationStore = useMemo(() => new OptimizationStore(), []);

  const optimizationContext: OptimizationContextType = useMemo(
    () => ({
      enabled: !!optimizationEnabled,
      config: props.optimizationConfig,
      store: optimizationStore,
    }),
    [optimizationStore, optimizationEnabled],
  );
  useOptimized(data, optimizationContext.enabled ? optimizationContext.store : undefined);
  const formattedColumns = useColumns(props.columns, tableStore, props.labels);
  const dataKey = `${props.columns?.map((e) => e.id).join('_') ?? ''}`.concat(props.dataKey ?? '');

  useEffectOnce(() => () => {
    if (props.skipClearStoreOnUnmount) return;
    if (props.unmountStore) {
      deleteTableStore(props.tableId);
    } else {
      tableStore.resetState();
    }
  });

  useEffect(() => {
    return () => {
      if (tableStore?.requireInvalidateCache) {
        invalidateCache(client.cache);
      }
    };
  }, [tableStore]);

  useEffect(() => {
    if (props.syncOrdering && props.defaultOrdering) {
      tableStore?.setOrderingSilently(props.defaultOrdering);
    }
  }, [tableStore, props.defaultOrdering, props.syncOrdering]);

  useEffect(() => {
    if (!props.skip) {
      tableStore?.getData();
    }
  }, [tableStore, dataKey, props.skip]);

  const tableConfig = useMemo(
    () => ({
      // should be 100% instead of max-content since with last one it will make
      // columns grow in firefox
      // https://accuranker.myjetbrains.com/youtrack/issue/ARR-1321
      scroll: { x: '100%' },
      sticky: { getContainer: () => container.current },
      components: {
        header: { cell: HeaderCell },
        body: { cell: BodyCell },
      },
      getDefaultHeight: isNil(props.tableMinHeight)
        ? getDefaultTableHeight
        : () => props.tableMinHeight || MIN_TABLE_HEIGHT,
      expandRowConfig: {
        columnIds: props.expandRowColumnIds ?? ['rank', 'url'],
        customExpandComponent: props.customExpandComponent ?? (SearchVolumeDetailsRow as any),
      },
      emptyText: (
        <TablePlaceholder
          title={props.emptyOptions?.title}
          subTitle={props.emptyOptions?.subTitle}
          noFilters={props.emptyOptions?.noFilters}
          noDataWithFiltersTitle={props.emptyOptions?.noDataWithFiltersTitle}
          noDataWithFiltersSubTitle={props.emptyOptions?.noDataWithFiltersSubTitle}
        />
      ),
      footer: (props.pagination
        ? withProps({ tableLabels: props.labels })(TableFooterContainer)
        : false) as any,
      rowKey: 'id',
    }),
    [],
  );

  const viewMode = tableStore?.viewMode;

  const RowInfo = useMemo(() => {
    const result = () =>
      (props.showCountInfo ? (
        <>
          <DynamicItemsIndicator itemsUpdateLabel={props.labels?.itemsUpdate} />
          <DynamicSelectionInfo skipSelectedCount={props.skipSelectedCount} />
        </>
      ) : null);
    result.displayName = 'RowInfo';
    return result;
  }, [props.showCountInfo]);
  const cssPageSize = { '--table-pagesize': props.pageSize || 10 } as React.CSSProperties;
  return useMemo(
    () => (
      <WithOptionalFilterSync
        tableStore={tableStore}
        syncFilters={!!props.syncFilters}
        applyDefaultOrder={
          (props.syncFilters as TableSyncFiltersConfig)?.applyDefaultOrder ?? false
        }
      >
        <TableStoreContext.Provider value={tableStore}>
          <OptimizationStoreContext.Provider value={optimizationContext}>
            <div
              ref={container}
              style={{ position: 'relative', ...cssPageSize }}
              className={cn('data-table', `data-table--${props.tableId}`, {
                'table-loading': !!tableStore?.loading || props.forceRenderSkeleton,
              })}
            >
              <MemoizedTable
                rowInfo={RowInfo}
                tableLayout="fixed"
                columns={formattedColumns}
                data={data}
                className={cn(
                  tableClassName(`size--${viewMode}`),
                  props.borderless ? tableClassName('-borderless') : '',
                  !props.pagination ? 'no-footer' : '',
                  props.className,
                )}
                styleMode={props.styleMode}
                {...tableConfig}
              />
            </div>
          </OptimizationStoreContext.Provider>
        </TableStoreContext.Provider>
      </WithOptionalFilterSync>
    ),
    [tableStore, formattedColumns, data, viewMode, data, tableConfig],
  );
};

export const DataTable: FC<React.PropsWithChildren<DataTableProps>> = React.memo(
  observer(DataTableComponent),
  isEqual,
);
DataTable.displayName = 'DataTable';

DataTable.defaultProps = {
  pagination: true,
  syncFilters: true,
  showCountInfo: false,
};

export default DataTable;
