import { Flex } from '@mantine/core';
import AccButton from 'Components/AccButton/AccButton';
import Breadcrumbs from 'Components/Breadcrumbs/Breadcrumbs';
import { Form } from 'Components/Fields';
import { t } from 'Utilities/i18n';
import TransferMultiAccountDomainForm from './TransferMultiAccountDomainForm/TransferMultiAccountDomainForm';
import { useTransferDomain } from './TransferMultiAccountDomainForm/utils/useTransferDomain';
import styles from './TransferMultiAccountDomainForm/transfer-multi-account-domain-form.module.scss';

const TransferMultiAccountDomain = () => {
  const { submit } = useTransferDomain();

  const handleTransferDomain = (values: any, copy?: boolean) => {
    submit(values, copy);
  };

  return (
    <>
      <Breadcrumbs />
      <div className={styles.container}>
        <Form onSubmit={(values) => handleTransferDomain(values)} formClassName={styles.form}>
          {(formRenderProps) => {
            const {
              errors,
              form: { getState },
            } = formRenderProps;
            return (
              <>
                <TransferMultiAccountDomainForm />
                <Flex justify="end" gap="sm">
                  <AccButton
                    ml="auto"
                    variant="secondary"
                    onClick={() => handleTransferDomain(getState().values, true)}
                    disabled={errors && !!Object.entries(errors)?.length}
                  >
                    {t('Copy')}
                  </AccButton>
                  <AccButton
                    variant="primary"
                    type="submit"
                    disabled={errors && !!Object.entries(errors)?.length}
                  >
                    {t('Transfer')}
                  </AccButton>
                </Flex>
              </>
            );
          }}
        </Form>
      </div>
    </>
  );
};

export default TransferMultiAccountDomain;
