import { DataTable, TableOrder, TableSize } from 'Components/DataTable';
import { useFilteredTableColumns } from 'Components/Modal/Content/TableSettingsModal/useFilteredColumns';
import { TableIDs } from 'Types/Table';
import { t } from 'Utilities/i18n';
import { DomainColumnIDs } from './support/types';
import { useDomainsTableInfo } from './support/useDomainsTableInfo';
import { useFetchDomainsData } from './support/useFetchDomainsData';
import styles from './domains-table.module.scss';

const tableName = TableIDs.DOMAINS;

const DomainsTable = () => {
  const fetchDomains = useFetchDomainsData();

  const defaultOrdering = {
    order: TableOrder.ASC,
    orderBy: DomainColumnIDs.DOMAIN_DISPLAY_NAME,
  };

  const { columns, tableLabels, dataKey } = useDomainsTableInfo();
  const resultComns = useFilteredTableColumns(columns, TableIDs.DOMAINS);

  return (
    <DataTable
      className={styles.domainsTable}
      dataKey={dataKey.toString()}
      fetchData={fetchDomains}
      columns={resultComns}
      tableId={tableName}
      pagination={true}
      pageSize={100}
      viewMode={TableSize.DEFAULT}
      defaultOrdering={defaultOrdering}
      labels={tableLabels}
      emptyOptions={{
        title: t('No domains'),
        subTitle: t('There is currently no domains added to your account.'),
      }}
    />
  );
};

export default DomainsTable;
