import { useSelector } from 'react-redux';
import { useAddGroupMutation } from 'Ghql';
import { useModal } from 'Hooks/base/useModal';
import toast from 'Hooks/useToast';
import { selectOrganizationId } from 'Selectors/OrganizationSelector';
import { t } from 'Utilities/i18n';
import Validator, { Errors } from 'Utilities/validation';
import GroupModalView, { GroupFormValues } from '../GroupModalView';

type Props = {
  refetch: (...args: Array<any>) => any;
};

const AddGroup = (props: Props) => {
  const { refetch } = props;
  const organizationId = useSelector(selectOrganizationId);
  const { hideModal } = useModal();
  const [addGroup] = useAddGroupMutation();

  const handleSubmit = (values: GroupFormValues) => {
    const input = {
      organization: organizationId as string,
      name: values.groupName,
    };
    if (!organizationId) {
      toast.error(t('Request failed'));
      return;
    }
    return addGroup({
      variables: {
        input,
      },
    })
      .then((resp) => {
        const errs = resp.data?.addClient?.errors ?? [];
        const client = resp.data?.addClient?.client;
        if (!client) {
          if (errs.length > 0) {
            toast.error(errs[0]?.messages[0]);
            Validator.setResponseErrors(Validator.throwSubmissionError, errs as Errors);
          }
        } else {
          toast.success(t('Added'));
          refetch();
        }
      })
      .catch(() => {
        toast.error(t('Request failed'));
      })
      .finally(hideModal);
  };

  return (
    <GroupModalView title={t('Add Group')} hideModal={hideModal} handleSubmit={handleSubmit} />
  );
};

export default AddGroup;
