import { memo } from 'react';
import { IconDeviceDesktop, IconDeviceMobile } from '@tabler/icons-react';
import AccSegmentedControl from 'Components/AccSegmentedControl/AccSegmentedControl';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import withFiltersEditor from 'Components/HOC/withFiltersEditor';
import type { FiltersEditorProps } from 'Components/HOC/withFiltersEditor';
import {
  DESKTOP,
  FilterAttribute,
  FilterBase,
  FilterComparison,
  FilterValueType,
  MOBILE,
  TOTAL,
} from 'Types/Filter';
import type { SearchType } from 'Types/Filter';
import { t } from 'Utilities/i18n';
import styles from './search-type-switch.module.scss';

const Component = (props: FiltersEditorProps) => {
  const { filtersEditor, filterGroup } = props;

  const defaultFilter = filterGroup.filters.find(
    (x) => x.attribute === FilterAttribute.SEARCH_TYPE,
  );
  const defaultSearchType = defaultFilter ? defaultFilter.value : TOTAL;

  const handleChange = (value: string) => {
    // Inside a set timeout such that segmented control feels responsive
    // and then filters are set triggering full rerender
    setTimeout(() => {
      const searchType = parseInt(value);

      if (searchType === TOTAL) {
        filtersEditor.removeFilter(FilterAttribute.SEARCH_TYPE);
      } else {
        const newFilter = {
          attribute: FilterAttribute.SEARCH_TYPE,
          type: FilterValueType.NUMBER,
          comparison: FilterComparison.EQ,
          value: searchType as SearchType,
        };
        const filter = filterGroup.filters.find(
          (x: FilterBase) => x.attribute === FilterAttribute.SEARCH_TYPE,
        );

        if (filter) {
          filtersEditor.updateFilters(newFilter, filter);
        } else {
          filtersEditor.addFilter(newFilter);
        }
      }
    }, 50);
  };

  return (
    <AccSegmentedControl
      defaultValue={defaultSearchType.toString()}
      onChange={(value) => handleChange(value)}
      size="sm"
      ml="auto"
      data={[
        {
          label: (
            <AccTooltip tooltip={t('Filter by Desktop')}>
              <div>
                <IconDeviceDesktop size={18} />
              </div>
            </AccTooltip>
          ),
          value: DESKTOP.toString(),
        },
        {
          label: (
            <AccTooltip tooltip={t('Filter by Mobile')}>
              <div>
                <IconDeviceMobile size={18} />
              </div>
            </AccTooltip>
          ),
          value: MOBILE.toString(),
        },
        { label: t('All'), value: TOTAL.toString() },
      ]}
      classNames={{
        label: styles.searchTypeSwitchLabel,
      }}
    />
  );
};
Component.displayName = 'SearchTypeSwitch';

const SearchTypeSwitch = withFiltersEditor(memo(Component));

export default SearchTypeSwitch;
