import React, { useCallback, useMemo } from 'react';
import Loader from 'Components/Loader';
import { useModal } from 'Hooks/base/useModal';
import useUserPermission from 'Hooks/useUserPermission';
import { useFoldersTableConfig } from 'Pages/Keywords/Groupings/ViewMode/utils/useFoldersTableConfig';
import { t } from 'Utilities/i18n';
import { TreeTable } from '../../../../Components/Table/TreeTable';
import { STICKY_FILTER_HEIGHT } from '../../../../Constants/sticky';
import { useClearSelectedOnUnmount, useSelectedNodes } from '../EditMode/support/state';
import { FolderType } from '../groupingTypes';
import { GroupingViewModeActions } from './components/GroupingViewModeActions';
import { GroupingsEmptyContent } from './components/GroupingsEmptyContent';
import reusableStyles from '../../../../css/reusable-styles.module.scss';
import styles from './viewMode.module.scss';

type Props = {
  data: FolderType[];
  loading: boolean;
  setEditMode: (editMode: boolean) => void;
  saveExpandedNodes: (nodeIds: string[]) => void;
  initialExpanded: string[];
  orderBy: string;
  order?: string;
  setOrderBy: (orderBy: string) => void;
};

const TableViewMode = (props: Props) => {
  const classes = {
    favicon: styles.favicon,
    noWrap: styles.noWrap,
    rightTextAlign: styles.rightTextAlign,
  };
  const { data, loading, setEditMode, saveExpandedNodes, initialExpanded, setOrderBy } = props;
  const { getSelectedNodes, setSelectedNodes } = useSelectedNodes();
  const { userHasWriteAccess } = useUserPermission();
  useClearSelectedOnUnmount();
  const { showModal } = useModal();

  // TODO: use event argument later, when we need keyboard modifiers to multi-select
  const handleSetSelectedNodes = useCallback(
    (node: FolderType, _?: React.MouseEvent | React.KeyboardEvent | React.ChangeEvent) => {
      const selectedNodes = getSelectedNodes();
      const selectedIds = selectedNodes.map((sNode) => sNode.path);

      if (selectedIds.includes(node.path)) {
        setSelectedNodes((prev) => [...prev].filter((sNode) => sNode.path !== node.path));
      } else {
        setSelectedNodes((prev) => [...prev, node]);
      }
    },
    [getSelectedNodes],
  );

  const { Header, RowTemplate, gridTableRows } = useFoldersTableConfig();

  const headerProps = useMemo(
    () => ({
      orderBy: props.orderBy,
      order: props.order,
      setOrderBy,
    }),
    [props.orderBy, props.order, setOrderBy],
  );

  const loadingElement = useMemo(
    () => (
      <Loader
        style={{
          height: '400px',
        }}
        loadingText={t('Loading…')}
      />
    ),
    [],
  );

  const enterEditMode = useCallback((event: React.DragEvent<HTMLDivElement>, text?) => {
    event.preventDefault();
    userHasWriteAccess &&
      showModal({
        modalType: 'Confirmation',
        modalTheme: 'light',
        modalProps: {
          action: () => setEditMode(true),
          confirmLabel: t('Yes'),
          cancelLabel: t('Cancel'),
          title: t('Enter Edit Mode?'),
          lockDuration: 0,
          description: text,
          showExclamationInTitle: false,
          enableOutsideClick: true,
        },
      });
  }, []);

  return (
    <>
      <GroupingViewModeActions loading={loading} setEditMode={setEditMode} />
      <div className={reusableStyles.paper}>
        <TreeTable
          emptyContentComponent={GroupingsEmptyContent}
          disableRowSelect
          data={data as any}
          saveExpandedNodes={saveExpandedNodes}
          headerProps={headerProps}
          expandedNodes={initialExpanded}
          expandDataKey="path"
          onNodeSelect={handleSetSelectedNodes}
          loading={loading}
          gridTableRows={gridTableRows}
          rowComponent={RowTemplate as any}
          headerComponent={Header as any}
          loadingElement={loadingElement}
          onDragEnd={enterEditMode}
          cellProps={{ classes }}
          key={`treetable-${gridTableRows}`}
          topOffset={`${STICKY_FILTER_HEIGHT}px`}
        />
      </div>
    </>
  );
};

export default TableViewMode;
