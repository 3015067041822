import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import * as Sentry from '@sentry/react';
import { createSelector } from 'reselect';
import {
  useGroupsAndDomainsDeleteDomainMutation,
  useGroupsAndDomainsUpdateClientMutation,
  useQuickNavigationDataQuery,
} from 'Ghql';
import { useModal } from 'Hooks/base/useModal';
import toast from 'Hooks/useToast';
import { pathToLabelMap } from 'Pages/Layout/DashboardTemplate/SideNavbar/support/useKeywordNavItems';
import SpecificFilterSelector from 'Selectors/SpecificFilterSelector';
import { FilterAttribute } from 'Types/Filter';
import { EventName, trackEventMixpanel } from 'Utilities/Analytics/mixpanel';
import { t } from 'Utilities/i18n';
import { notEmpty, redirectToExternalUrl } from 'Utilities/underdash';
import { DOMAIN, GROUP, useSubscribeToTopic } from 'Utilities/websocket';
import {
  useSmartRouterSubscription,
  useStableNavigate,
} from '../../../../../../Utilities/Router/components/LocationPathnameContext';
import { buildGroups, getGroupLink } from './helpers';

const domainsFilterSelector = createSelector(
  SpecificFilterSelector(FilterAttribute.DOMAINS),
  (item) => item?.value.join(',') ?? '',
);

export const useGroupsMenuData = () => {
  const { data, refetch, loading } = useQuickNavigationDataQuery();
  const domainIdsString = useSelector(domainsFilterSelector);
  const location = useLocation();

  const domainIds = (domainIdsString || '')?.split?.(',') ?? [];

  const groups = buildGroups(data?.user?.quickNavigationData);
  //Fallback for empty groups where we cannot filter on domainId's
  const groupItemFromRouterState = groups.find(
    (group) => group.id === (location.state?.groupId as string | undefined),
  );
  const selectedGroupItem =
    groups.find((client) => client.domainsIds?.includes(domainIds[0])) || groupItemFromRouterState;
  const domains = selectedGroupItem ? selectedGroupItem.domains : [];
  const selectedDomainItem =
    domainIds.length === 1
      ? domains?.filter(notEmpty).find((domain) => domain.id === domainIds[0])
      : null;

  useSubscribeToTopic([
    {
      action: DOMAIN,
      cb: () => refetch(),
    },
    {
      action: GROUP,
      cb: () => refetch(),
    },
  ]);

  return {
    groups,
    loading,
    refetch,
    selectedGroupItem,
    selectedDomainItem,
    domainIds,
  };
};

export const useGroupsNavigationActions = (domainIds: string[], refetch: Function) => {
  const { showModal } = useModal();
  const navigate = useStableNavigate();

  const [deleteClientMutation] = useGroupsAndDomainsUpdateClientMutation();
  const [deleteDomainMutation] = useGroupsAndDomainsDeleteDomainMutation();

  const onGroupSelect = (group: { domainsIds: string[]; id: string }) => {
    navigate(getGroupLink(group), { state: { groupId: group.id } });
  };

  const onAddGroup = () => {
    showModal({
      modalType: 'AddGroup',
      modalTheme: 'light',
      modalProps: {
        refetch,
      },
    });
  };

  const onEditGroup = (event: any, groupObj: any) => {
    event.stopPropagation();
    showModal({
      modalType: 'EditGroup',
      modalTheme: 'light',
      modalProps: {
        groupId: groupObj.id,
        refetch,
        initialValues: {
          groupName: groupObj.name,
        },
      },
    });
  };

  const showDeleteConfirmation = (type, action, groupObj) => {
    const { name, displayName, domain } = groupObj;
    showModal({
      modalType: 'Confirmation',
      modalProps: {
        title: type === 'group' ? t('Delete Group?') : t('Delete Domain?'),
        description:
          type === 'group'
            ? t('The group %s and all the domains within it will be permanently deleted.', name)
            : t(
                'The domain %s and all its keywords will be permanently deleted.',
                displayName || domain,
              ),
        cancelLabel: t('Cancel'),
        confirmLabel: type === 'group' ? t('Delete group') : t('Delete domain'),
        action: () => action(groupObj),
      },
    });
  };

  const onDeleteGroup = (event, groupObj) => {
    event?.stopPropagation?.();
    const { id, name, organization, domainsIds } = groupObj;
    const onDeleteGroupMutation = () =>
      deleteClientMutation({
        variables: {
          input: {
            id,
            name,
            organization: organization.id,
            delete: true,
          },
        },
      }).then((res) => {
        const errors = res.data?.updateClient?.errors;
        if (errors && errors.length) {
          toast.error(t('Could not delete group'));
          return;
        }

        toast.success(t('Group deleted'));
        refetch();

        if (domainsIds.includes(domainIds.at(-1))) {
          redirectToExternalUrl('/app/groups');
        }
      });

    showDeleteConfirmation(onDeleteGroupMutation, 'group', groupObj);
  };

  const onDomainsDelete = (event, domainObj) => {
    event?.stopPropagation?.();

    const { id } = domainObj;
    const deleteDomain = () => {
      deleteDomainMutation({
        variables: {
          input: {
            id,
          },
        },
      }).then((res) => {
        const errors = res?.data?.deleteDomain?.errors;
        if (errors && errors.length) {
          toast.error(t('Could not delete domain'));
          trackEventMixpanel(EventName.DeleteDomainFailed, '', {
            'Deleted Domain ID': id,
            Source: 'GroupsAndDomains',
            Error: `${errors?.[0]?.field}: ${errors?.[0]?.messages?.join('')}`,
          });
          return;
        }

        trackEventMixpanel(EventName.DeleteDomainSuccess, '', {
          'Deleted Domain ID': id,
          Source: 'GroupsAndDomains',
        });

        toast.success(t('Domain deleted'));
        refetch();

        if (!Array.isArray(domainIds)) {
          Sentry.captureException(`Domain ids is not an array: ${domainIds}`);
        }

        const firstDomain = Array.isArray(domainIds) ? domainIds.at(0) : undefined;
        if (firstDomain === id) {
          redirectToExternalUrl('/domains');
        }
      });
    };

    return showDeleteConfirmation('domain', deleteDomain, domainObj);
  };

  const onDomainsAdd = (groupId?: string) => () => {
    showModal({
      modalType: 'BuildDomain',
      modalTheme: 'light',
      modalProps: {
        groupId,
        onClose: refetch,
      },
    });
  };

  const onDomainsEdit = (event, domainObj) => {
    event.stopPropagation();
    showModal({
      modalType: 'BuildDomain',
      modalTheme: 'light',
      modalProps: {
        domainId: domainObj.id,
        onClose: refetch,
      },
    });
  };

  return {
    onGroupSelect,
    onEditGroup,
    onAddGroup,
    onDeleteGroup,
    onDomainsAdd,
    onDomainsEdit,
    onDomainsDelete,
  };
};

export const useKeywordsPageHeader = () => {
  const labelMap = pathToLabelMap();

  const getPathnameMatch = (pathname: string, map: Record<string, string>) => {
    for (const substring in map) {
      if (pathname.includes(substring)) {
        return map[substring];
      }
    }
    return '';
  };

  return useSmartRouterSubscription((pathname) => {
    return getPathnameMatch(pathname, labelMap);
  });
};
