import sumBy from 'lodash/sumBy';
import { AddKeywordFormType } from 'Components/Modal/Content/AddKeywords/AddKeywordsForm/types';
import { CountryLocaleNode } from 'Ghql';
import { t, tn } from 'Utilities/i18n';

export interface AddKeywordCountInfo {
  localeInfo: string;
  locationsCount: number;
  searchEnginesCount: number;
}

export const getAddKeywordsCountInfo = (
  engines: AddKeywordFormType['engines'] | null,
  totalKeywords: number,
  countryLocales: Partial<CountryLocaleNode>[] | null,
): {
  keywordsCounter: number;
  keywordCountInfo: AddKeywordCountInfo[];
} => {
  let keywordsCounter = 0;

  const keywordCountInfo: AddKeywordCountInfo[] =
    engines
      ?.map((e) => {
        const country = countryLocales?.find((el) => el.id === e.countrylocale);
        const localeShort = country?.localeShort?.toUpperCase();
        return {
          localeInfo: country
            ? `${country.countryCode}${localeShort ? `-${localeShort}` : ''}`
            : null,
          locationsCount: e?.locations?.length > 0 ? e?.locations?.length : 1,
          searchEnginesCount: sumBy(e?.searchEngines, 'searchTypes.length') || 1,
        };
      })
      .filter(Boolean)
      .map((e) => e as AddKeywordCountInfo) ?? [];

  if (engines && totalKeywords > 0) {
    keywordsCounter =
      (keywordCountInfo?.reduce((acc, e) => acc + e.searchEnginesCount * e.locationsCount, 0) ??
        0) * totalKeywords;
  }

  return {
    keywordsCounter,
    keywordCountInfo,
  };
};

const getCountLocationDescription = (
  countInfo: AddKeywordCountInfo,
  includeLocaleInfo?: boolean,
) => {
  const searchEnginesCountStr =
    includeLocaleInfo || countInfo.searchEnginesCount !== 1 ? countInfo.searchEnginesCount : '';
  const localeInfo = includeLocaleInfo && countInfo.localeInfo ? ` ${countInfo.localeInfo}` : '';

  return (
    countInfo.searchEnginesCount
      ? countInfo.searchEnginesCount === 1
        ? t('%s%s search engine', searchEnginesCountStr, localeInfo)
        : t('%s%s search engines', searchEnginesCountStr, localeInfo)
      : ''
  )
    .trim()
    .concat(
      countInfo.searchEnginesCount && countInfo.locationsCount > 1
        ? ' × '.concat(t('%s locations', countInfo.locationsCount))
        : '',
    );
};

/**
 * @docs https://accuranker.myjetbrains.com/youtrack/issue/ARR-1998
 * Return description for keywords count, if no description required - return null.
 * Example:
 * - 35 "= 5 Keywords x (1 US-EN search engine + 2 AF-FA search engines x 3 locations)"
 * - 30 "= 5 Keywords x ( 2 search engines x 3 locations)"
 * - 10 "= 5 Keywords x ( search engine x 2 locations)"
 * - 5 {<- If we have only one of each (one locale, one search engine, and one location)}
 */
export const formatAddKeywordCountDescription = (
  keywordsCounter: number,
  totalKeywords?: number,
  keywordCountInfo?: AddKeywordCountInfo[],
) => {
  const firstKeywordCountInfo = keywordCountInfo?.[0];

  if (
    !totalKeywords ||
    !keywordCountInfo ||
    !firstKeywordCountInfo ||
    keywordsCounter === totalKeywords
  ) {
    return null;
  }
  const keywordsText = tn('%s keyword', '%s keywords', totalKeywords);

  const includeLocaleInfo = keywordCountInfo.length > 1;
  const extraItems = keywordCountInfo
    .map((e) => getCountLocationDescription(e, includeLocaleInfo))
    .join(' + ');
  const hasBrackets = keywordCountInfo.length > 1 || firstKeywordCountInfo.locationsCount > 1;

  return keywordsText
    .concat(' × ')
    .concat(hasBrackets ? '(' : '')
    .concat(extraItems)
    .concat(hasBrackets ? ')' : '');
};
