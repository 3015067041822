import { useLayoutEffect, useState } from 'react';
import {
  SegmentedControl,
  SegmentedControlProps,
  SegmentedControlStylesNames,
} from '@mantine/core';
import cn from 'classnames';
import { EventName, TrackingKey, useMixpanel } from 'Utilities/Analytics/mixpanel';
import styles from './accSegmentedControl.module.scss';

export type AccSegmentedControlProps = Omit<SegmentedControlProps, 'classNames'> & {
  classNames?: Partial<Record<SegmentedControlStylesNames, string>>;
  trackingKey?: TrackingKey;
  /** Animated state transitions on the component. Defaults to `false` */
  animated?: boolean;
};

const AccSegmentedControl = (props: AccSegmentedControlProps) => {
  const { children, classNames, onChange, trackingKey, animated = false, ...rest } = props;

  const trackEvent = useMixpanel();

  // For some reason, when the SegmentedControl is rendered inside a modal, it is fucked up on first render.
  // thus, we force a rerender.
  const [isFirstRender, setIsFirstRender] = useState(true);

  useLayoutEffect(() => {
    setTimeout(() => {
      setIsFirstRender(false);
    });
  }, []);

  if (isFirstRender) {
    return null;
  }

  const trackChange = (v) => {
    const text = v;
    const extraProps = {
      ...(text ? { Text: text } : {}),
      ...(trackingKey ? { 'Tracking Key': trackingKey } : {}),
    };
    trackEvent(EventName.ButtonClick, extraProps);
  };

  return (
    <SegmentedControl
      color="snorlax"
      radius={8}
      transitionDuration={animated ? 200 : 0}
      onChange={(value) => {
        trackChange(value);
        onChange?.(value);
      }}
      {...rest}
      classNames={{
        ...classNames,
        root: cn(styles.root, classNames?.root),
        label: cn(styles.label, classNames?.label),
        indicator: cn(styles.indicator, classNames?.indicator),
        control: cn(styles.control, classNames?.control),
        input: cn(styles.input, classNames?.input),
      }}
    >
      {children}
    </SegmentedControl>
  );
};
export default AccSegmentedControl;
