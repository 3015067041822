import { Center } from '@mantine/core';
import {
  IconBaselineDensityLarge,
  IconBaselineDensityMedium,
  IconBaselineDensitySmall,
} from '@tabler/icons-react';
import { setTableViewMode } from 'Actions/TableAction';
import AccSegmentedControl from 'Components/AccSegmentedControl/AccSegmentedControl';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import { TableSize, useTableStore } from 'Components/DataTable';
import { useActions } from 'Hooks/redux/useActions';
import { t } from 'Utilities/i18n';
import { TableIDs } from '../../Types/Table';

const segmentedControlDataKeyword = [
  {
    label: (
      <AccTooltip tooltip={t('Set table view density to default')}>
        <Center>
          <IconBaselineDensityLarge size={14} />
        </Center>
      </AccTooltip>
    ),
    value: TableSize.DEFAULT,
  },
  {
    label: (
      <AccTooltip tooltip={t('Set table view density to medium')}>
        <Center>
          <IconBaselineDensityMedium size={14} />
        </Center>
      </AccTooltip>
    ),
    value: TableSize.COMFORTABLE,
  },
  {
    label: (
      <AccTooltip tooltip={t('Set table view density to compact')}>
        <Center>
          <IconBaselineDensitySmall size={14} />
        </Center>
      </AccTooltip>
    ),
    value: TableSize.COMPACT,
  },
];

const segmentedControlDataDomains = [
  {
    label: (
      <AccTooltip tooltip={t('Set table view density to default')}>
        <Center>
          <IconBaselineDensityLarge size={14} />
        </Center>
      </AccTooltip>
    ),
    value: TableSize.DEFAULT,
  },
  {
    label: (
      <AccTooltip tooltip={t('Set table view density to compact')}>
        <Center>
          <IconBaselineDensitySmall size={14} />
        </Center>
      </AccTooltip>
    ),
    value: TableSize.COMPACT,
  },
];

export const ViewMode = ({ tableName }) => {
  const tableStore = useTableStore(tableName);

  const segmentedControlData =
    tableName === TableIDs.KEYWORDS ? segmentedControlDataKeyword : segmentedControlDataDomains;

  const viewMode = tableStore?.viewMode ?? TableSize.DEFAULT;

  const { setTableViewMode: updateTableViewMode } = useActions({
    setTableViewMode,
  });

  const onSave = (v) => {
    updateTableViewMode(tableName, v);
    tableStore?.setTableViewMode(v);
  };

  return (
    <AccSegmentedControl
      color={'snorlax'}
      data={segmentedControlData}
      onChange={(value) => onSave(value)}
      defaultValue={viewMode}
    />
  );
};
