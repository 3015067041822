import { Box, Center, Group, Paper, Stack } from '@mantine/core';
import { IconHourglass, IconInfoTriangle } from '@tabler/icons-react';
import AccessControl from 'Components/AccessControl';
import Icon from 'Components/Icon/new';
import {
  AddAction,
  ConnectToAnalyticsAction,
  ConnectToGSCAction,
  RefreshAction,
} from 'Pages/Layout/ActionsMenu/Actions';
import { t, tct } from 'Utilities/i18n';
import SearchIcon from 'icons/connecting.svg?inline';
import ExclamationIcon from 'icons/exclamation.svg?inline';
import KeywordOverview from 'icons/keyword-overview.svg?inline';
import CompetitorsIcon from 'icons/menu/competitors.svg?inline';
import TagIcon from 'icons/soft-tag.svg?inline';
import styles from './styles.module.scss';

type MissingDataOverlayProps =
  | {
      type: 'organicTraffic';
      handleConnectToGSC: () => void;
      handleConnectToAnalytics: () => void;
      isEmptyGroup: boolean;
      isGroupView?: boolean;
      extraBlurry?: boolean;
      openModalAction?: boolean;
      disable?: boolean;
      disableTooltip?: string;
    }
  | {
      type: 'searchTrend';
      isEmptyGroup: boolean;
      isGroupView?: boolean;
      extraBlurry?: boolean;
      openModalAction?: boolean;
      disable?: boolean;
      disableTooltip?: string;
    }
  | {
      type:
        | 'competitors'
        | 'tags'
        | 'awaitAnalyticsData'
        | 'missingKeywords'
        | 'featureAdvancedMetrics'
        | 'organicTrafficGroup';
      isEmptyGroup: boolean;
      isGroupView?: boolean;
      onClick?: () => void;
      extraBlurry?: boolean;
      openModalAction?: boolean;
    };

export const MissingDataOverlay = (props: MissingDataOverlayProps) => {
  const { isEmptyGroup, isGroupView = false } = props;
  let actionLabel: string;

  if (isEmptyGroup) {
    return (
      <Center data-extra-blurry={props.extraBlurry || null} className={styles.missingDataOverlay}>
        <Paper radius={'md'} mb="lg" p="xxl" className={styles.missingDataPaper}>
          <Center>
            <Stack align={'center'}>
              <Icon width={48} style={{ color: 'grey' }}>
                <IconInfoTriangle stroke={1.5} fillOpacity={0} />
              </Icon>
              <Box className={styles.paperBox} mt={-10}>
                {t('Add domain(s) to the selected\ngroup to see this chart')}
              </Box>
            </Stack>
          </Center>
        </Paper>
      </Center>
    );
  }
  switch (props.type) {
    case 'competitors':
      actionLabel = t('Add Competitor');
      break;
    case 'missingKeywords':
      actionLabel = t('Add Keywords');
      break;
    case 'featureAdvancedMetrics':
      actionLabel = t('Upgrade plan');
      break;
    default:
      actionLabel = t('Add Tag');
  }

  const { openModalAction = true } = props;

  return (
    <Center data-extra-blurry={props.extraBlurry || null} className={styles.missingDataOverlay}>
      {openModalAction && (
        <Paper radius={'md'} className={styles.missingDataPaper} mb="lg" p="xxl">
          <Center>
            <Stack align={'center'}>
              <Icon width={48} style={{ color: 'grey' }}>
                {props.type === 'competitors' && <CompetitorsIcon />}
                {props.type === 'tags' && <TagIcon />}
                {(props.type === 'organicTraffic' || props.type === 'organicTrafficGroup') && (
                  <SearchIcon />
                )}
                {props.type === 'awaitAnalyticsData' && (
                  <IconHourglass size={26} stroke={1.5} fillOpacity={0} />
                )}
                {props.type === 'featureAdvancedMetrics' && <ExclamationIcon />}
                {props.type === 'missingKeywords' && <KeywordOverview />}
                {props.type === 'searchTrend' && (
                  <IconHourglass size={26} stroke={1.5} fillOpacity={0} />
                )}
              </Icon>
              <Box className={styles.paperBox} mt={-10}>
                {props.type === 'competitors' && t('Add competitor(s) to see this chart')}
                {props.type === 'tags' && t('Add some tags to see this chart')}
                {props.type === 'awaitAnalyticsData' &&
                  tct('Fetching data...[br]This might take a few minutes', {
                    br: <br />,
                  })}
                {props.type === 'organicTrafficGroup' &&
                  tct(
                    'No domains in this group are connected [br] to Google Search Console or Google Analytics',
                    { br: <br /> },
                  )}
                {props.type === 'organicTraffic' &&
                  tct(
                    'Connect to Google Search Console or [br]  Google Analytics to see this chart',
                    { br: <br /> },
                  )}
                {props.type === 'missingKeywords' && t('Add keywords to see this chart')}
                {props.type === 'featureAdvancedMetrics' &&
                  t('Upgrade your plan to enable this chart')}
                {props.type === 'searchTrend' &&
                  tct(
                    'We\'re collecting data for this chart.[br]Please check back later for updates.',
                    { br: <br /> },
                  )}
              </Box>
              <AccessControl>
                <Group gap={'md'}>
                  {((!isGroupView && props.type === 'tags') ||
                    (!isGroupView && props.type === 'competitors') ||
                    (!isGroupView && props.type === 'missingKeywords')) && (
                    <AddAction onClick={props.onClick} label={actionLabel} />
                  )}
                  {props.type === 'awaitAnalyticsData' && <RefreshAction onClick={props.onClick} />}
                  {props.type === 'organicTraffic' && (
                    <>
                      <ConnectToGSCAction
                        key="addGSC"
                        onClick={props.handleConnectToGSC}
                        disabled={props.disable}
                        tooltip={props.disableTooltip}
                      />
                      <ConnectToAnalyticsAction
                        key="addGa"
                        onClick={props.handleConnectToAnalytics}
                        disabled={props.disable}
                        tooltip={props.disableTooltip}
                      />
                    </>
                  )}
                  {props.type === 'featureAdvancedMetrics' && (
                    <AddAction label="Upgrade Plan" link="/billing/package/select" />
                  )}
                </Group>
              </AccessControl>
            </Stack>
          </Center>
        </Paper>
      )}
    </Center>
  );
};
