import { Component } from 'react';
import { findDOMNode } from 'react-dom';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { gql } from '@apollo/client';
import { graphql, withApollo } from '@apollo/client/react/hoc';
import compose from 'lodash/flowRight';
import { Field, reduxForm } from 'redux-form';
import { showModal } from 'Actions/ModalAction';
import AccButton from 'Components/AccButton/AccButton';
import { MultiSelectInputField, Select } from 'Components/Forms/Fields';
import FormErrors from 'Components/Forms/FormErrors';
import toFormField from 'Components/Forms/toFormField';
import { ModalFooter } from 'Components/Modal/Layout/ModalFooter';
import { AppLanguage } from 'Constants';
import toast from 'Hooks/useToast';
import EmailsInput from 'Pages/ScheduledReportBuilder/ScheduledReportBuilderForm/EmailsInput';
import {
  getDefaultEmailBody,
  getDefaultEmailSubject,
  getLanguageOptions,
} from 'Pages/ScheduledReportBuilder/data';
import queryDomainInfo from 'Pages/queryDomainInfo';
import { DOMAINS, stringifyFilters } from 'Types/Filter';
import { throwNetworkError } from 'Utilities/errors';
import { t } from 'Utilities/i18n';
import { downloadFile, graphqlError, graphqlLoading } from 'Utilities/underdash';
import Validator from 'Utilities/validation';
import WS, { subscribeToGeneratedReport } from 'Utilities/websocket';
import CloseIcon from 'icons/close-2.svg?inline';
import './competitors-ranks-report-form.scss';

const EmailsField = toFormField(EmailsInput);
type Props = {
  competitorsData: Record<string, any>;
  domainId: string;
  domainInfo: Record<string, any>;
  domainData: any;
  invalid: boolean;
  submitting: boolean;
  createReport: (...args: Array<any>) => any;
  showModal: (...args: Array<any>) => any;
  handleSubmit: (...args: Array<any>) => any;
  hasDriveAccount: boolean;
  endDate: string;
  onSubmit: () => void;
  submitOnInit: boolean;
  initialState: Record<string, any>;
  filters?: any;
  onClose: (...args: Array<any>) => any;
};
type State = {
  scheduledReportId: number | null;
  submittedOnInit: boolean;
  isLoading: boolean;
  link: string | null;
};

const getReportTypeOptions = () => [
  {
    value: 2,
    label: t('Excel'),
  },
  {
    value: 3,
    label: t('CSV'),
  },
  {
    value: 5,
    label: t('Google Sheets'),
  },
];

class CompetitorsRanksReportForm extends Component<Props, State> {
  _submit: any;
  generatedReportSub: any;
  state: State = {
    isLoading: false,
    scheduledReportId: null,
    link: null,
    // to show Download button when report is created successfully
    submittedOnInit: false,
  };
  languageOptions = getLanguageOptions();
  reportTypeOptions = getReportTypeOptions();

  downloadReport = () => {
    if (this.state.link) {
      window.open(this.state.link ?? '', '_blank');
    }
    this.props.onSubmit();
  };

  componentDidMount() {
    this.generatedReportSub = subscribeToGeneratedReport(this.handleDownload as any);
  }

  componentWillUnmount() {
    this.generatedReportSub.unsubscribe();
  }

  handleDownload = ({
    data: {
      action,
      other: { scheduled_report, url, report_type },
    },
  }) => {
    // // ws message example
    // const message = {
    //   "data": {
    //     "id": "530365",
    //     "obj": "GENERATED_REPORT",
    //     "action": "UPDATED",
    //     "other": {
    //       "scheduled_report": 58224,
    //       "url": "/media/generated_reports/AccuRanker_accurankercom-8_2019-01-07_ab541b17b7ad4a04b6f7b12126aa2cf3_3lTBZlb.pdf",
    //       "report_type": 1
    //     }
    //   }
    // };
    if (scheduled_report === this.state.scheduledReportId && action === WS.UPDATED) {
      if (url) {
        toast.success(t('Report created'));
        this.setState({
          link: url,
          isLoading: false,
        });
      }

      if (report_type !== 5 && url) {
        downloadFile(url);
      }
    }
  };

  componentDidUpdate() {
    if (
      this._submit &&
      this.props.submitOnInit &&
      !this.state.submittedOnInit &&
      this.props.hasDriveAccount &&
      !(graphqlLoading(this.props) || graphqlError(this.props))
    ) {
      // eslint-disable-next-line react/no-will-update-set-state
      this.setState({
        submittedOnInit: true,
      });
      // Probably not the best way to submit programmatically,
      // so if there is a better way that runs handleSubmit and set submitting, fix me
      // eslint-disable-next-line react/no-find-dom-node
      // TODO FixTSignore
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line react/no-find-dom-node
      findDOMNode(this._submit)?.click();
    }
  }

  getCompetitorsOptions = () => {
    const competitorsData = this.props?.domainData?.domain?.competitors;

    if (!competitorsData) {
      return [];
    }

    return competitorsData.map(({ id, domain, youtubeChannelName }) => ({
      label: youtubeChannelName || domain,
      value: id,
    }));
  };
  handleSubmit = (data: any) => {
    const { domainId, hasDriveAccount } = this.props;
    const { competitors, reportType, language, emails } = data;
    const competitorsArr = Array.isArray(competitors) ? competitors : [competitors];

    // pop the google auth flow if we dont have an account
    if (reportType.value === 5 && !hasDriveAccount) {
      this.handleMissingDriveAccount(data);
      return;
    }

    this.setState({
      isLoading: true,
    });
    const noDomainsFilters = this.props.filters.filter((filter) => filter.attribute !== DOMAINS);
    return this.props
      .createReport({
        variables: {
          input: {
            domainId,
            competitors: competitorsArr,
            reportType: reportType.value,
            emailSubject: getDefaultEmailSubject(),
            emailBody: getDefaultEmailBody(),
            language: language.value,
            recipients: emails ? emails : [],
            filters: stringifyFilters(noDomainsFilters),
          },
        },
      })
      .then(
        ({
          data: {
            scheduleCompetitorRanksReport: { errors, scheduledReport },
          },
        }) => {
          if (errors && errors.length) {
            this.setState({
              isLoading: false,
            });
            toast.error(errors[0].messages[0]);
            Validator.throwSubmissionError(errors);
            return;
          }

          this.setState({
            scheduledReportId: Number(scheduledReport.id),
          });
        },
        throwNetworkError,
      )
      .catch(() => {
        this.setState({
          isLoading: false,
        });
        toast.error(t('Unable to create report'));
      });
  };
  setSubmitRef = (ref) => {
    this._submit = ref;
  };
  valueComponent = ({ value, children, onClick, onRemove }) => (
    <span className="value-item" onClick={onClick}>
      <span>
        {children}
        <CloseIcon onClick={() => onRemove(value)} />
      </span>
    </span>
  );
  handleMissingDriveAccount = (data) => {
    const { domainId } = this.props;
    this.props.showModal({
      modalType: 'ConnectToDrive',
      modalTheme: 'light',
      modalProps: {
        message: t(
          'You do not have a Google Drive connection setup with AccuRanker. Please connect to your Google account to allow AccuRanker to create spreadsheet reports. AccuRanker will only have access to the files it creates, and cannot read other files.',
        ),
        nextModalType: 'CompetitorsRanksReport',
        lastState: data,
        domainId,
      },
    });
  };

  renderLinkToReport() {
    return (
      <div className="alert alert-info">
        {this.state.link?.startsWith('https://docs.google.com')
          ? t(
              'Your report is ready. To view it, please click the \'Open Google Sheets\' button below.',
            )
          : t(
              'Your report is ready. The download should have started automatically. If it hasn\'t, please click the download button below.',
            )}
      </div>
    );
  }

  renderGeneratingReport() {
    return (
      <div>
        <p>{t('Your report is being generated. Please wait…')}</p>
        <p>{t('Please note that reports are queued up and can take a few minutes to complete.')}</p>
        <p>
          {t(
            'If nothing happens, your browser may be blocking the download. You can probably find your report in the ',
          )}
          <Link to="/reports/generated">{t('generated reports tab.')}</Link>
        </p>
      </div>
    );
  }

  renderForm() {
    return (
      <div>
        <FormErrors />
        <div className="form-label required">{t('Competitors')}</div>
        <Field
          name="competitors"
          placeholder={t('Competitors')}
          component={MultiSelectInputField}
          defaultBehaviour
          validate={Validator.nonEmptyArrayOrObj}
          options={this.getCompetitorsOptions()}
          creatable={false}
          autoFocus={true}
          // valueComponent={this.valueComponent}
        />
        <div className="form-label required">{t('Report Type')}</div>
        <Field
          name="reportType"
          defaultBehaviour
          component={Select}
          options={this.reportTypeOptions}
          validate={Validator.required}
          searchable={false}
        />
        <div key="language-label" className="form-label required">
          {t('Language')}
        </div>
        <Field
          key="language"
          name="language"
          defaultBehaviour
          placeholder={t('Language')}
          component={Select}
          options={this.languageOptions}
          validate={Validator.required}
          searchable={false}
        />
        <div key="email-label" className="form-label">
          {t('Email to')}
        </div>
        <Field
          key="email"
          name="emails"
          //Do not include the word "email" in the placeholder - as this will trigger password managers to suggest email addresses
          placeholder={t('You can enter multiple addresses')}
          component={EmailsField}
        />
      </div>
    );
  }

  render() {
    const { handleSubmit, invalid, submitting } = this.props;
    const { isLoading, link } = this.state;
    return (
      <form className="competitors-ranks-report-form" onSubmit={handleSubmit(this.handleSubmit)}>
        {(link && this.renderLinkToReport()) || (
          <div>{submitting || isLoading ? this.renderGeneratingReport() : this.renderForm()}</div>
        )}
        <ModalFooter
          primaryButtonSlot={
            (link && (
              <AccButton onClick={this.downloadReport} variant="primary">
                {this.state.link?.startsWith('https://docs.google.com')
                  ? t('Open Google Sheets')
                  : t('Download')}
              </AccButton>
            )) || (
              <AccButton
                ref={this.setSubmitRef}
                disabled={invalid || submitting || isLoading}
                loading={submitting || isLoading}
                type="submit"
                variant="primary"
              >
                {submitting || isLoading ? t('Generating') : t('Download')}
              </AccButton>
            )
          }
          secondaryButtonSlot={
            <AccButton variant="tertiary" onClick={this.props.onClose}>
              {t('Cancel')}
            </AccButton>
          }
        />
      </form>
    );
  }
}

const createReportMutation = gql`
  mutation competitorsRanksReportForm_createReportMutation(
    $input: ScheduleCompetitorRanksReportInput!
  ) {
    scheduleCompetitorRanksReport(input: $input) {
      scheduledReport {
        id
      }
      errors {
        field
        messages
      }
    }
  }
`;

const ReduxForm = reduxForm({
  form: 'CompetitorsRanksReportForm',
  enableReinitialize: true,
})(CompetitorsRanksReportForm);

const mapStateToProps = (state, { initialState }) => {
  const languageOptions = getLanguageOptions();
  const userLanguage = state.user.language as AppLanguage;
  languageOptions.sort((a, _) => (a.value === userLanguage ? -1 : 1));
  const initialValues = initialState || {
    language: languageOptions[0],
    reportType: getReportTypeOptions()[1],
    emails: [],
  };
  return {
    initialValues,
    hasDriveAccount: state.user.googleConnections.length > 0,
    filters: state.filter.filterGroup.filters,
  };
};

export default compose(
  withApollo,
  connect(mapStateToProps, {
    showModal,
  }),
  queryDomainInfo(),
  graphql(createReportMutation, {
    name: 'createReport',
  }),
)(ReduxForm);
