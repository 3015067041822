import * as React from 'react';
import CheckboxGroup, { ValueLabel } from 'Components/Controls/CheckboxGroup';
import toFormField from 'Components/Forms/toFormField';
import { t } from 'Utilities/i18n';
import type { SearchEngineSettings } from '../../AddKeywords/AddKeywordsForm/types';

type Props = {
  value: SearchEngineSettings;
  onChange: (...args: Array<any>) => any;
  showError?: boolean;
  disabled?: boolean;
  showGoogleOptions: boolean;
};
const defaultSettings = {
  ignoreLocalResults: false,
  ignoreFeaturedSnippet: false,
  enableAutocorrect: false,
};

class SearchEngineSettingsField extends React.Component<Props> {
  static defaultProps = {
    disabled: false,
  };

  handleChange = (properties: string[]) => {
    const newValue = properties.reduce(
      (accumulator, property) => {
        accumulator[property] = true;
        return accumulator;
      },
      { ...defaultSettings },
    );
    this.props.onChange(newValue);
  };

  items: ValueLabel[] = [
    {
      value: 'ignoreLocalResults',
      label: t('Ignore local pack'),
      hint: t(
        'If checked, any local pack found on the SERP will not be included in the rank position.',
      ),
    },
    {
      value: 'ignoreFeaturedSnippet',
      label: t('Ignore featured snippet'),
      hint: t('Ignore the featured snippet that is often shown on how/what searches.'),
    },
    {
      value: 'enableAutocorrect',
      label: t('Enable autocorrect'),
      hint: t('Enable Google Autocorrect for this keyword.'),
    },
  ];

  render() {
    const { value, showGoogleOptions } = this.props;
    const selectedProperties = Object.keys(value).filter((field) => value[field]);

    return (
      <CheckboxGroup
        disabled={!showGoogleOptions}
        className="add-keywords-settings"
        value={selectedProperties}
        onChange={this.handleChange}
        items={this.items}
      />
    );
  }
}

export default toFormField(SearchEngineSettingsField);
