import { useCallback } from 'react';
import { useSelector, useStore } from 'react-redux';
import isNil from 'lodash/isNil';
import { LATEST } from 'Components/PeriodFilter/model';
import { selectCurrentFilters } from 'Selectors/FilterSelector';
import { RequiredFiltersSelector } from 'Selectors/FiltersSelector';
import SpecificFilterSelector from 'Selectors/SpecificFilterSelector';
import {
  COMPARE_TO,
  DOMAINS,
  FREE_TEXT_DOMAIN,
  FilterAttribute,
  FilterAttributetype,
  FilterBase,
  FilterComparison,
  FilterValueType,
  PERIOD,
} from 'Types/Filter';

export const useFilters = () => {
  return useSelector(selectCurrentFilters);
};

/** alternative to useFilters, used to avoid re-rendering when filters change */
export const useGetFilters = () => {
  const store = useStore();
  return useCallback(() => selectCurrentFilters(store.getState()), [store]);
};

export const useRequiredFilters = () => {
  return useSelector(RequiredFiltersSelector);
};

export const useCompareToIsLatest = () => {
  const periodFilter = useSelector(SpecificFilterSelector(FilterAttribute.PERIOD));
  let isLatest = false;

  if (periodFilter && periodFilter.value) {
    isLatest = JSON.parse(periodFilter.value)[1] === LATEST;
  }

  return isLatest;
};

/** Use only filter domains, period and compare_to, but remove other filters. **/
export const useStandardFilters = () => {
  return useFilters().filter((x) =>
    [DOMAINS, FREE_TEXT_DOMAIN, PERIOD, COMPARE_TO].includes(x.attribute),
  );
};

export const formatFiltersWithRankChange = (filters: FilterBase[], winners): FilterBase[] => {
  const existingRankChangeFilter = filters.find((f) => f.attribute === FilterAttribute.RANK_CHANGE);

  if (existingRankChangeFilter) {
    return filters;
  }

  return [
    ...filters,
    isNil(winners)
      ? null
      : {
          attribute: FilterAttribute.RANK_CHANGE,
          comparison: winners ? FilterComparison.LT : FilterComparison.GT,
          value: 0,
          type: FilterValueType.NUMBER,
        },
  ].filter(Boolean) as FilterBase[];
};

/**Hook to select a specific filter */
export const useSpecificFilter = <T extends FilterAttributetype>(
  //hacky way to avoid type widening to string
  attribute: T,
) =>
  // Make sure typeScript returns the type of the specific filter
  useSelector(SpecificFilterSelector(attribute) ?? null) as
    | Extract<FilterBase, { attribute: T }>
    | undefined;
