import { Component, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { gql } from '@apollo/client';
import { graphql } from '@apollo/client/react/hoc';
import { Center } from '@mantine/core';
import compose from 'lodash/flowRight';
import { bindActionCreators } from 'redux';
import { formValueSelector, submit } from 'redux-form';
import { finishLoading, startLoading } from 'Actions/LoadingAction';
import { showModal } from 'Actions/ModalAction';
import { setVatStatus } from 'Actions/OrderPlanAction';
import AccButton from 'Components/AccButton/AccButton';
import ErrorBoundary from 'Components/ErrorBoundary';
import Skeleton from 'Components/Skeleton';
import { useGa4Tracking } from 'Hooks/useConversionTracking';
import CompanyInfoWidget from 'Pages/Billing/CompanyInfoWidget';
import CheckoutInfoWidget from 'Pages/Billing/InfoWidget';
import OrderPlanWidget from 'Pages/Billing/OrderPlanWidget';
import PaymentWidget from 'Pages/Billing/PaymentWidget';
import SpecialOfferMessage from 'Pages/Billing/SpecialOfferMessage';
import SpecialOfferTimer from 'Pages/Billing/SpecialOfferTimer';
import TemplateNavTop from 'Pages/Layout/TemplateNavTop';
import LocaleSelector from 'Selectors/LocaleSelector';
import { EventName, TrackingKey, trackEventMixpanel } from 'Utilities/Analytics/mixpanel';
import { WithRouter, withRouter } from 'Utilities/Router';
import { t } from 'Utilities/i18n';
import { DealExpired, DealNotStartedYet } from '../DealExpired';
import {
  WithPricingPlanAndCalculatedPlanProps,
  getDiscountedPrice,
  getGa4Item,
  withPricingPlanAndCalculatedPlan,
} from '../utils';
import './checkout-form.scss';

type Props = {
  data: Record<string, any>;
  dispatch: (...args: Array<any>) => any;
  companyInfoError: any;
  countriesError: any;
  match: Record<string, any>;
  startLoading: (...args: Array<any>) => any;
  showModal: (...args: Array<any>) => any;
  performPayment: (...args: Array<any>) => any;
  finishLoading: (...args: Array<any>) => any;
  country?: any;
  paymentContact?: any;
} & Pick<WithRouter, 'history'> &
  WithPricingPlanAndCalculatedPlanProps;

type State = {
  showVatFields: boolean;
  braintreeUniqueId: any;
  formSubmitting: boolean;
  braintreeInstance: any;
  companyInfoWidgetValid: boolean;
  total?: number;
};
const NEW_PAYMENT = 'A_1';

class CheckoutForm extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      showVatFields: true,
      braintreeUniqueId: +new Date(),
      formSubmitting: false,
      braintreeInstance: false,
      companyInfoWidgetValid: true,
    };
  }
  errorModalShowed: boolean = false;

  componentDidUpdate() {
    if (this.props.data.error || this.props.companyInfoError || this.props.countriesError) {
      if (this.errorModalShowed) {
        return;
      }
      this.errorModalShowed = true;
      this.props.showModal({
        modalType: 'Confirmation',
        modalProps: {
          cancelLabel: t('Cancel'),
          confirmLabel: t('Select Another Plan'),
          lockDuration: 0,
          description: t(
            'Something went wrong with the plan you selected. Select another plan or contact us if you have any questions.',
          ),
          action: () => {
            this.props?.history?.push?.('/billing/package/select');
          },
        },
      });
    }
  }

  setCompanyInfoWidgetValidStatus = (valid) => {
    this.setState({
      companyInfoWidgetValid: valid,
    });
  };

  trackGa4Payment(payment: any) {
    const calculatedPlan = this.props.calculatedPlan;
    const pricingPlan = this.props.pricingPlan;

    const currency = calculatedPlan?.currency;
    const transaction_id = payment.id;
    const value = getDiscountedPrice(calculatedPlan);

    window.gtag?.('event', 'purchase', {
      currency,
      transaction_id,
      value,
      items: [getGa4Item(calculatedPlan, pricingPlan)],
    });
  }

  handleSubmit = ({
    companyName,
    street,
    zipcode,
    city,
    state,
    country: { countryCode },
    vatPrefix,
    vatNumber,
    emailInvoiceTo,
  }) => {
    this.props.startLoading({
      loadingText: t('Processing payment'),
    });
    this.state.braintreeInstance.requestPaymentMethod(
      {
        threeDSecure: {
          challengeRequested: true,
          amount: this.state.total,
        },
      },
      (braintreeError, payload) => {
        if (braintreeError) {
          this.props.finishLoading();
          this.setState({
            braintreeUniqueId: +new Date(),
            formSubmitting: false,
          });
          this.props.showModal({
            modalType: 'PaymentFailed',
            modalProps: {
              errorType: braintreeError.message,
            },
          });
          return;
        }

        const { id, cycle, coupon } = this.props.match.params;
        this.setState({
          formSubmitting: true,
        });
        const paymentDetails = {
          variables: {
            planId: id,
            billingCycleId: parseInt(cycle),
            paymentNonce: payload.nonce,
            companyName,
            street,
            zipcode,
            city,
            state,
            countryIso: countryCode,
            vatPrefix: vatPrefix ? vatPrefix.vatCode : null,
            vatNumber: vatNumber ? vatNumber.trim() : null,
            emailInvoiceTo,
            coupon,
          },
        };
        this.props.performPayment(paymentDetails).then((res) => {
          const {
            data: {
              setPaymentContact: { error, success, payment },
            },
          } = res;
          this.props.finishLoading();

          if (!success) {
            this.setState({
              braintreeUniqueId: +new Date(),
              formSubmitting: false,
            });
            this.props.showModal({
              modalType: 'PaymentFailed',
              modalProps: {
                errorType: error,
              },
            });
            return;
          }

          if (payment?.paymentType === NEW_PAYMENT) {
            trackEventMixpanel(EventName.PaymentSuccess, 'checkout', {
              ID: `payment-${payment.id}`,
              Name: payment.organizationPlan.category,
              SKU: payment.organizationPlan.maxKeywords,
              Price: payment.amountBeforeVat,
              Category: 'Sale',
              Revenue: payment.amountBeforeVat,
            });

            // Facebook
            // TODO FixTSignore
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if (typeof fbq !== 'undefined') {
              // TODO FixTSignore
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              fbq('track', 'Purchase', {
                value: payment.amountBeforeVat,
                currency: 'USD',
              });
            }
          }

          this.trackGa4Payment(payment);

          this.props.showModal({
            modalType: 'PaymentSuccess',
            modalProps: {
              paymentInfo: payment,
              modalBodyText: payment
                ? 'Thank you for your order! Your payment was successful.'
                : 'Thank you for your order! Your new subscription will be active from the start of your next billing period.',
            },
          });
        });
      },
    );
  };
  createBraintreeInstance = (instance) => {
    this.setState({
      braintreeInstance: instance,
    });
  };
  renderPaymentWidget = () => {
    // We need the value for 3d-secure. This should be the total value of a normal recurring transaction.
    const total = this.state.total;

    if (!total || this.props.country === undefined) {
      return (
        <div>
          <Skeleton
            linesConfig={[
              {
                type: 'chart',
              },
            ]}
          />
        </div>
      );
    }

    if (
      this.props.paymentContact.paymentContact !== undefined &&
      this.props.paymentContact.paymentContact.paymentMethod === 'A_1'
    ) {
      return (
        <div className={'order-plan-wrapper'}>
          <p>{t('You are paying via invoice. Please contact us to change your subscription.')}</p>
        </div>
      );
    }

    return (
      <PaymentWidget
        braintreeThreeDSecureAmount={total}
        onCreate={this.createBraintreeInstance}
        uniqueid={this.state.braintreeUniqueId}
        country={this.props.country.countryCode}
      />
    );
  };
  onTotal = (total) => {
    this.setState({
      total,
    });
  };

  render() {
    const {
      dispatch,
      match: { params },
    } = this.props;
    const dealStartDate = this.props?.data?.pricingPlanCalculated?.dealStartDate;
    const dealEndDate = this.props?.data?.pricingPlanCalculated?.dealEndDate;
    const showCountdown = this.props?.data?.pricingPlanCalculated?.showCountdown;
    const isDealExpired = dealEndDate && new Date(dealEndDate).getTime() <= Date.now();
    const isDealNotStartedYet = dealStartDate && new Date(dealStartDate).getTime() > Date.now();
    const billingCycle: number = parseInt(params.cycle);

    const { formSubmitting, braintreeInstance, companyInfoWidgetValid } = this.state;

    return (
      <TemplateNavTop>
        <main role="main" className="checkout">
          <h1>{t('Your Order Details')}</h1>
          <form>
            <div className="checkout__columns">
              <div className="checkout__order-details">
                <DealExpired condition={isDealExpired} />
                <DealNotStartedYet condition={isDealNotStartedYet && !isDealExpired} />
                <SpecialOfferTimer showCountdown={showCountdown} dealEndDate={dealEndDate} />
                <SpecialOfferMessage planId={params.id} billingCycleId={billingCycle} />

                <div className="hidden-md-down">
                  <CheckoutInfoWidget planId={params.id} billingCycleId={billingCycle} />
                </div>

                <strong className="form-title">{t('Company Details')}</strong>
                <ErrorBoundary>
                  <CompanyInfoWidget
                    onSubmit={this.handleSubmit}
                    setFormValidStatus={this.setCompanyInfoWidgetValidStatus}
                  />
                </ErrorBoundary>

                <strong className="form-title">{t('Payment Details')}</strong>
                <div>{this.renderPaymentWidget()}</div>
              </div>

              {/* hidden on medium and down */}
              <div className="checkout-sticky__plan-menu">
                <div className="checkout-sticky__top">
                  <OrderPlanWidget
                    basepath="checkout"
                    isSticky
                    planId={params.id}
                    billingCycleId={billingCycle}
                    coupon={params.coupon}
                  />
                </div>
              </div>
            </div>

            <strong className="form-title">{t('Place Order')}</strong>
            <CheckoutInfoWidget planId={params.id} billingCycleId={billingCycle} />
            <OrderPlanWidget
              planId={params.id}
              billingCycleId={billingCycle}
              coupon={params.coupon}
              onTotal={this.onTotal}
            />
            <Center className="confirmation-button-wrapper">
              <AccButton
                disabled={formSubmitting || !braintreeInstance || !companyInfoWidgetValid}
                variant="primary"
                onClick={() => dispatch(submit('CompanyInfoForm'))}
                px={100}
                style={{ height: 48 }}
                trackingKey={TrackingKey.Checkout}
              >
                {t('Confirm')}
              </AccButton>
            </Center>
          </form>
        </main>
      </TemplateNavTop>
    );
  }
}

const formValuesSelector = formValueSelector('CompanyInfoForm');

const mapStateToProps = (state) => ({
  fullLocale: LocaleSelector(state),
  country: formValuesSelector(state, 'country'),
});

const paymentMethodQuery = gql`
  query checkoutForm_getPaymentMethod {
    paymentContact {
      id
      paymentMethod
    }
  }
`;
const pricingPlanQuery = gql`
  query checkoutForm_getPricingPlan($id: ID!) {
    pricingPlan(id: $id) {
      id
      name
    }
  }
`;
const pricingPlanCalculatedQuery = gql`
  query checkoutForm_getPricingPlanCalculated($id: ID!, $billingCycleId: Int!) {
    pricingPlanCalculated(id: $id, billingCycle: $billingCycleId) {
      dealStartDate
      dealEndDate
      showCountdown
    }
  }
`;
const performPaymentMutation = gql`
  mutation checkoutForm_performPayment(
    $planId: String
    $billingCycleId: Int
    $companyName: String!
    $street: String!
    $city: String!
    $zipcode: String!
    $countryIso: String!
    $state: String
    $vatPrefix: String
    $vatNumber: String
    $paymentNonce: String
    $emailInvoiceTo: String
    $coupon: String
  ) {
    setPaymentContact(
      planId: $planId
      billingCycleId: $billingCycleId
      companyName: $companyName
      street: $street
      city: $city
      zipcode: $zipcode
      countryIso: $countryIso
      state: $state
      vatPrefix: $vatPrefix
      vatNumber: $vatNumber
      paymentNonce: $paymentNonce
      emailInvoiceTo: $emailInvoiceTo
      coupon: $coupon
    ) {
      success
      error
      payment {
        id
        billysbillingInvoiceId
        amount
        amountBeforeVat
        couponCode
        paymentType
        organizationPlan {
          id
          category
          maxKeywords
        }
      }
    }
  }
`;

const useGA4TrackBeginCheckout = ({ pricingPlan, calculatedPlan, loading }: Props) => {
  const [ga4BeginCheckoutSent, setGa4BeginCheckoutSent] = useState(false);

  const currency = calculatedPlan?.currency;
  const value = getDiscountedPrice(calculatedPlan);

  useEffect(() => {
    if (ga4BeginCheckoutSent) return;
    if (loading) return;

    window.gtag?.('event', 'begin_checkout', {
      currency,
      value,
      items: [getGa4Item(calculatedPlan, pricingPlan)],
    });
    setGa4BeginCheckoutSent(true);
  }, [calculatedPlan, currency, ga4BeginCheckoutSent, loading, pricingPlan, value]);
};

const withGa4Tracking = (WrappedComponent) => {
  const WithGa4TrackingWrapper = (props: Props) => {
    useGa4Tracking();
    useGA4TrackBeginCheckout(props);
    return <WrappedComponent {...props} />;
  };
  return WithGa4TrackingWrapper;
};

export default withRouter(
  compose(
    graphql(paymentMethodQuery, {
      name: 'paymentContact',
      options: () => ({
        fetchPolicy: 'network-only',
      }),
    }),
    graphql(performPaymentMutation, {
      name: 'performPayment',
    }),
    graphql(pricingPlanQuery, {
      options: ({ match }: any) => ({
        variables: {
          id: match.params.id,
        },
        fetchPolicy: 'network-only',
      }),
    }),
    graphql(pricingPlanCalculatedQuery, {
      options: ({ match }: any) => ({
        variables: {
          id: match.params.id,
          billingCycleId: parseInt(match.params.cycle),
        },
        fetchPolicy: 'network-only',
      }),
    }),

    connect(mapStateToProps, (dispatch) => ({
      ...bindActionCreators(
        {
          setVatStatus,
          startLoading,
          finishLoading,
          showModal,
        },
        dispatch,
      ),
      dispatch,
    })),
    withPricingPlanAndCalculatedPlan,
    withGa4Tracking,
  )(CheckoutForm),
);
