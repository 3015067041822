import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
import isArray from 'lodash/isArray';
import isNil from 'lodash/isNil';
import { useNotesSelect, useQueryNotes } from 'Components/Chart/Notes';
import { useEffectOnce } from 'Components/DataTable/hooks/useEffectOnce';
import { clearStore } from 'Components/DataTable/store/TableStore';
import { useDomainIds, useQueryDomainInfo } from 'Hooks/data/domain/useQueryDomainInfo';
import DndBoard from 'Pages/Keywords/Overview/NextChartsDashboard/DraggableChartListV2/DndBoard';
import { useChartControls, useDefaultCharts } from 'Pages/Keywords/Overview/chartSelectHelpers';
import { CHART_NAMES, ChartBox } from 'Pages/Keywords/Overview/components/ChartBox';
import { HISTORY_CHARTS_TO_BACKEND_NAME } from 'Pages/Keywords/Overview/components/HistoryCharts/constants';
import KpiBar from 'Pages/Keywords/Overview/components/KpiBar/index';
import { OverviewActionBar } from 'Pages/Keywords/Overview/components/OverviewActionBar';
import { adjustChartsAndKpisForNaver } from 'Pages/Keywords/Overview/removeNaverColumns';
import ActionbarContainer from 'Pages/Layout/ActionsMenu/components/ActionbarContainer';
import { StoreType } from 'Types/Store';
import { TableIDs } from 'Types/Table';
import styles from './styles.module.scss';

const CHARTS_BANNED_IN_GROUP_VIEW = [CHART_NAMES.COMPETITORS, CHART_NAMES.DYNAMIC_COMPETITORS_BY];

function useRemoveNonGroupChartsIfGroupView(
  isGroupView: boolean,
  allSelectedCharts: string[],
  selectedCharts: Record<string, string[]>,
  setSelectedCharts: (value: Record<string, string[]>) => void,
  loadingDomainInfo: boolean,
) {
  useEffect(() => {
    if (isGroupView && CHARTS_BANNED_IN_GROUP_VIEW.some((e) => allSelectedCharts.includes(e))) {
      const leftColumn = selectedCharts.leftColumn.filter(
        (e) => !CHARTS_BANNED_IN_GROUP_VIEW.includes(e),
      );
      const rightColumn = selectedCharts.rightColumn.filter(
        (e) => !CHARTS_BANNED_IN_GROUP_VIEW.includes(e),
      );

      setSelectedCharts({ leftColumn, rightColumn, kpis: selectedCharts.kpis });
    }
  }, [isGroupView, loadingDomainInfo]);
}

export const NextOverviewPage = () => {
  const { domainInfo, domainId, loadingDomainInfo, isNaverDomain } = useQueryDomainInfo();
  const defaultCharts = useDefaultCharts();
  const domainIds = useDomainIds();

  const isGroupView = !loadingDomainInfo && !domainInfo;
  const disableEdit = isGroupView || isNaverDomain;
  const isEmptyGroup = isGroupView && !domainIds?.length;

  const storedCharts = cloneDeep(
    useSelector((state: StoreType) => {
      return state.user?.defaultOverviewCharts || defaultCharts;
    }),
  ); // Clone to allow mutating just below here if there are no kpis on stored charts

  useEffectOnce(() => () => {
    clearStore(TableIDs.KEYWORDS_OVERVIEW);
  });
  if (isNil(storedCharts?.kpis)) {
    storedCharts.kpis = defaultCharts.kpis;
  }

  const [selectedCharts, setSelectedCharts] = useState<Record<string, string[]>>(storedCharts);

  const allSelectedCharts = selectedCharts.leftColumn.concat(selectedCharts.rightColumn);

  useRemoveNonGroupChartsIfGroupView(
    isGroupView,
    allSelectedCharts,
    selectedCharts,
    setSelectedCharts,
    loadingDomainInfo,
  );

  const { saveChartEdits, restoreDefaultCharts, cancelChartEdits, handleSetSelectedChart } =
    useChartControls(selectedCharts, setSelectedCharts, storedCharts);

  const [version, setVersion] = useState(0);
  const refresh = () => setVersion((v) => v + 1);

  const { notes, isLoadingNotes } = useQueryNotes();
  const showNote = useNotesSelect();

  const notesProps = {
    onNotesSelect: showNote,
    notesLoading: isLoadingNotes,
    notes,
  };

  // Make sure we only fetch the selected history charts
  const enabledHistoryCharts = useMemo(
    () =>
      selectedCharts.leftColumn
        .concat(selectedCharts.rightColumn)
        .filter((chart) => HISTORY_CHARTS_TO_BACKEND_NAME[chart])
        .map((chart) => HISTORY_CHARTS_TO_BACKEND_NAME[chart])
        .sort((a, b) => a.localeCompare(b)),
    [selectedCharts.leftColumn, selectedCharts.rightColumn],
  );

  const showPercentage = domainInfo?.shareOfVoicePercentage ?? false;
  const allProps = {
    ...notesProps,
    ...domainInfo,
    showPercentage,
    domainId,
    isGroupView,
    refresh,
    handleSetSelectedChart,
    selectedCharts,
    enabledHistoryCharts,
  };

  if (!selectedCharts) {
    return null;
  }

  let leftChartNames = [...selectedCharts.leftColumn];
  let rightChartNames = [...selectedCharts.rightColumn];
  let kpis = [...(selectedCharts?.kpis || [])];

  if (isNaverDomain) {
    const adjustedCharts = adjustChartsAndKpisForNaver(leftChartNames, rightChartNames, kpis);
    leftChartNames = adjustedCharts.leftChartNames;
    rightChartNames = adjustedCharts.rightChartNames;
    kpis = adjustedCharts.kpis;
  }

  const saveCharts = (fn) => {
    const nextChartState = fn(selectedCharts);
    setSelectedCharts(nextChartState);
    saveChartEdits(nextChartState);
  };

  const saveKpis = (kpiList) => {
    if (!isArray(kpiList)) {
      return;
    }
    setSelectedCharts((val) => ({ ...val, kpis: kpiList }));
    saveChartEdits({ ...selectedCharts, kpis: kpiList });
  };

  return (
    <React.Fragment key={version}>
      <ActionbarContainer mb="lg">
        <OverviewActionBar
          domainInfo={domainInfo}
          refresh={refresh}
          saveChartEdits={saveChartEdits}
          cancelChartEdits={cancelChartEdits}
          restoreDefaultCharts={restoreDefaultCharts}
          isEmptyGroup={isEmptyGroup}
        />
      </ActionbarContainer>
      <KpiBar activeKpis={kpis} saveKpis={saveKpis} disableEdit={disableEdit} />
      <DndBoard
        leftChartNames={leftChartNames}
        rightChartNames={rightChartNames}
        allProps={allProps}
        saveCharts={saveCharts}
        disableEdit={disableEdit}
      />
      <div className={styles.container}>
        <div className={styles.fullWidthColumn}>
          <ChartBox chartName={CHART_NAMES.NOTIFICATIONS} otherProps={allProps} />
        </div>
      </div>
    </React.Fragment>
  );
};
