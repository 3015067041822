import { useEffect, useState } from 'react';
import { Avatar } from '@mantine/core';
import cn from 'classnames';
import hash2md5 from 'md5';

const SATURATION = 50;
const LIGHTNESS = 60;

const getInitials = (str: string) => {
  return (
    str
      .match(/(^\S\S?|\s\S)?/g)
      ?.map((v) => v.trim())
      .join('')
      .match(/(^\S|\S$)?/g)
      ?.join('')
      .toLocaleUpperCase() || ''
  );
};

const stringToColor = (str: string, s = SATURATION, l = LIGHTNESS) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  return `hsl(${hash % 360}, ${s}%, ${l}%)`;
};

type Props = {
  email?: string | null;
  name: string;
  md5?: string;
  size: number;
  rating: string;
  default: string;
  className?: string;
  protocol?: string;
  domain?: string;
  style?: object;
  hideTooltip?: boolean;
};

const getHash = (md5?: string, email?: string) => {
  if (md5) {
    return md5;
  } else if (typeof email === 'string') {
    return hash2md5(email, { encoding: 'binary' });
  }
  return null;
};

const GravatarProfiles = {};

const AccAvatar = (props: Props) => {
  const {
    protocol,
    domain,
    email,
    md5,
    size,
    rating,
    name,
    default: def,
    className,
    style,
    hideTooltip,
  } = props;

  // Gravatar service currently trims and lowercases all registered emails
  const formattedEmail = email?.trim().toLowerCase() || '';
  const base = `${protocol}${domain}`;
  const hash = getHash(md5, formattedEmail);
  const query = new URLSearchParams({
    s: size.toString(),
    r: rating,
    d: def,
  }).toString();

  const gravId = `${hash}?${query}`;

  const [gravatarImage, setGravatarImage] = useState<string | null>(
    GravatarProfiles[gravId] || null,
  );

  // Preload image to avoid flickering of avatar. Save image in GravatarProfiles variable for later use.
  useEffect(() => {
    if (!(gravId in GravatarProfiles)) {
      const image = document.createElement('img');
      image.src = `${base}/avatar/${gravId}`;
      image.onload = () => {
        setGravatarImage(image.src);
        GravatarProfiles[gravId] = image.src;
      };
      image.onerror = () => {
        GravatarProfiles[gravId] = null;
      };
    }
  }, [base, gravId]);

  const value = (name || formattedEmail || '').trim();
  const initials = getInitials(value);
  const color = stringToColor(value);
  return (
    <Avatar
      src={gravatarImage}
      alt={hideTooltip ? undefined : value}
      variant="filled"
      className={cn('acc-avatar', className)}
      style={style}
      color={color}
      maw={size}
      mah={size}
      size={size}
    >
      {initials}
    </Avatar>
  );
};

AccAvatar.defaultProps = {
  size: 50,
  rating: 'g',
  default: '404',
  protocol: '//',
  domain: 'www.gravatar.com',
};

export default AccAvatar;
