import { useCallback, useEffect, useState } from 'react';
import { useForm, useFormState } from 'react-final-form';
import { Collapse, Flex } from '@mantine/core';
import { Field } from 'Components/Fields';
import SoMeFormFields from 'Components/SoMeFormFields/SoMeFormFields';
import AccText from 'Components/Text/AccText';
import AccTitle from 'Components/Title/AccTitle';
import { t } from 'Utilities/i18n/index';
import { SoMePlatform } from '../../../../../../SoMeFormFields/SocialMediaIcons';
import ConnectionSettings, { ConnectionSettingsProps } from './ConnectionSettings';
import styles from './advanced-settings.module.scss';

type Props = {
  isYoutubeMode: boolean;
  showAdvancedSettings: boolean;
  showPaused: boolean;
  connectionSettingsProps?: ConnectionSettingsProps;
};

export const AdvancedSettingsFields = (props: Props) => {
  const { showAdvancedSettings, isYoutubeMode, showPaused, connectionSettingsProps } = props;

  const [activeSocials, setActiveSocials] = useState<SoMePlatform[]>([]);

  const form = useForm();

  const { initialValues } = useFormState();

  // show social media fields if they have an initial value
  useEffect(() => {
    const initialActiveSocials: SoMePlatform[] = [];
    Object.entries(initialValues).forEach(([key, value]) => {
      if (Object.values<string>(SoMePlatform).includes(key) && value !== undefined) {
        initialActiveSocials.push(key as SoMePlatform);
      }
    });
    setActiveSocials(initialActiveSocials);
  }, [initialValues]);

  const handleSocialIconToggle = useCallback((platform: SoMePlatform) => {
    setActiveSocials((prevSocials) => {
      const updatedSocials = [...prevSocials];
      const index = prevSocials?.indexOf(platform);

      if (index === -1) {
        updatedSocials.push(platform);
      } else {
        form.change(platform, '');
        updatedSocials.splice(index, 1);
      }

      return updatedSocials;
    });
  }, []);

  return (
    <Collapse in={showAdvancedSettings}>
      <Flex direction="column" rowGap="xl" pt="md" className={styles.advancedSettingsContainer}>
        <div>
          <AccTitle type="h3">{t('General')}</AccTitle>
          <Flex direction="column" rowGap="xl">
            <Field.Checkbox
              name="shareOfVoicePercentage"
              helpTextPopover={t('Show domain share of voice as a percentage of maximum value.')}
              label={
                <AccText size="sm" fw={400}>
                  {t('Show share of voice as percentage')}
                </AccText>
              }
            />
            {!isYoutubeMode && (
              <Field.Checkbox
                name="includeSubdomains"
                helpTextPopover={t(
                  'Include results from *.domain.com. If not checked, we only include results for www.domain.com and domain.com.',
                )}
                label={
                  <AccText size="sm" fw={400}>
                    {t('Include subdomains')}
                  </AccText>
                }
              />
            )}
            {!isYoutubeMode && (
              <Field.Checkbox
                name="exactMatch"
                helpTextPopover={t(
                  'Only include results where the URL found is an exact match to what is entered in domain name',
                )}
                label={
                  <AccText size="sm" fw={400}>
                    {t('Exact match')}
                  </AccText>
                }
              />
            )}
            {showPaused && (
              <Field.Checkbox
                name="paused"
                helpTextPopover={t('When a domain is paused, it cannot be un-paused for 14 days.')}
                label={
                  <AccText size="sm" fw={400}>
                    {t('Pause domain')}
                  </AccText>
                }
              />
            )}
          </Flex>
        </div>
        {!isYoutubeMode && connectionSettingsProps && (
          <ConnectionSettings {...connectionSettingsProps} />
        )}
        {!isYoutubeMode && (
          <SoMeFormFields activeSocials={activeSocials} onIconToggle={handleSocialIconToggle} />
        )}
      </Flex>
    </Collapse>
  );
};
