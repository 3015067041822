import { SpotlightActionData, SpotlightActionGroupData } from '@mantine/spotlight';
import { IconSitemap, IconTopologyRing2 } from '@tabler/icons-react';
import { clearEverything } from 'Actions/ResetAction';
import linkWithFilters from 'Components/Filters/linkWithFilters';
import { LinkedOrganizationFragment } from 'Ghql';
import { useActions } from 'Hooks/redux/useActions';
import { getDomainLink } from 'Pages/Layout/DashboardTemplate/Menu/GroupsAndDomains/support/helpers';
import type { DomainsFilter } from 'Types/Filter';
import { FilterAttribute, FilterComparison, FilterValueType } from 'Types/Filter';
import { KEYWORDS_FILTER_SET } from 'Types/FilterSet';
import { useStableNavigate } from 'Utilities/Router/components/LocationPathnameContext';
import { getFaviconByDomain } from 'Utilities/favicon';
import { t, tct } from 'Utilities/i18n';
import { redirectToExternalUrl } from 'Utilities/underdash';
import { QuickNavigationClient } from '../QuickNavigation';
import { ACTION_ADD_DOMAIN, ACTION_ADD_GROUP, DOMAIN_ITEM, loadingOption } from './constants';

export const extractGroupAndDomains = (
  loading?: boolean,
  data?: {
    clients: any[];
  },
  error?: string,
) => {
  const options: any[] = [
    {
      displayName: 'Add domain',
      key: 'action-add-domain',
      label: '+ Add domain',
      value: 'action-add-domain',
      type: ACTION_ADD_DOMAIN,
    },
    {
      displayName: 'Add group',
      key: 'action-add-group',
      label: '+ Add group',
      value: 'action-add-group',
      type: ACTION_ADD_GROUP,
    },
  ];

  if (loading) {
    options.push(loadingOption);
  } else if (!error && data?.clients) {
    data?.clients?.forEach((client) => {
      options.push(
        {
          type: DOMAIN_ITEM,
          key: `group-${client.id}`,
          displayName: client.name,
          value: `group-${client.id}`,
          label: client.name,
          headerText: client.name,
          header: true,
          domainIds: client.domains.map((item) => item.id),
        },
        ...client.domains.map((item) => ({
          ...item,
          type: DOMAIN_ITEM,
          key: `domain-${item.id}`,
          value: `domain-${item.id}`,
          label: item.displayName,
        })),
      );
    });
  }

  return options;
};

export const filterNavigationOptions = (options: any, filterString?: string) => {
  const pattern = filterString?.toLowerCase() ?? '';
  return options
    .filter(
      // filter domains first
      (option) =>
        option.header ||
        (option.domain && option.domain.toLowerCase().includes(pattern)) ||
        (option.displayName && option.displayName.toLowerCase().includes(pattern)),
    )
    .filter(
      // then filter groups and hide groups with no domains and that's doesn't fit to filter pattern
      (option, idx, opts) =>
        !(
          option.header &&
          ((opts.length > idx + 1 && opts[idx + 1].header) || opts.length === idx + 1)
        ) ||
        (option.header && option.displayName && option.displayName.toLowerCase().includes(pattern)),
    )
    .concat(options.some((e) => e.loading) && loadingOption)
    .filter(Boolean);
};

export const keywordsPaths = [
  '/notes/:filter',
  '/keywords/import/gsc/:filter',
  '/keywords/overview/:filter',
  '/keywords/keyword_discovery/:filter',
  '/keywords/list/:filter',
  '/keywords/competitors/:filter',
  '/keywords/serp/:filter',
  '/keywords/tags/:filter',
  '/keywords/landing-pages/:filter',
  '/keywords/sitemapping/:filter',
];

export const getGroupOrDomainLink = (path: string) => {
  if (keywordsPaths.includes(path)) {
    return path.replace(/\/(:([\w\d]+)\??)(?:$|\/$)/, '') || '/';
  }

  return '/keywords/overview';
};

export const makeDomainsLink = (domainsIds: string[], path?: string) => {
  const domainsFilter: DomainsFilter = {
    attribute: FilterAttribute.DOMAINS,
    type: FilterValueType.LIST,
    comparison: FilterComparison.CONTAINS,
    value: domainsIds,
  };
  const urlPath =
    domainsIds.length > 1 || !path ? '/keywords/overview' : getGroupOrDomainLink(path);
  return linkWithFilters({
    to: urlPath,
    overwriteFilters: [domainsFilter],
    filterSet: KEYWORDS_FILTER_SET,
    resetFilters: true,
  });
};
export const getDomainGroupLink = (domainObj: any, path?: string) => {
  return makeDomainsLink(!domainObj.header ? [domainObj.id] : domainObj.domainIds || [], path);
};

export const useMapDataToActions = (
  loading?: boolean,
  clients?: QuickNavigationClient[],
  linkedOrganizations?: LinkedOrganizationFragment[],
  domainPageTo?: string,
  search?: string,
): SpotlightActionGroupData[] => {
  const navigate = useStableNavigate();

  const reduxActions = useActions({ clearEverything });

  if (loading) return [];

  const totalDomainCount = clients?.reduce((acc, client) => {
    const domainCount = client?.domains?.filter(
      (domain) =>
        domain.displayName?.toLowerCase().includes((search || '').toLowerCase()) ||
        domain.domain?.toLowerCase().includes((search || '').toLowerCase()),
    ).length;
    return acc + domainCount;
  }, 0);

  const totalGroups = clients?.filter((client) =>
    client?.clientName?.toLowerCase().includes(search || ''),
  ).length;
  const totalAccounts = linkedOrganizations?.filter((account) =>
    account.name.toLowerCase().includes(search || ''),
  ).length;

  const domainActions: SpotlightActionData[] =
    clients?.flatMap((client) =>
      client.domains.map((domain) => ({
        label: domain.displayName || domain.domain,
        id: `domain${domain.id}`,
        description: domain.domain,
        leftSection: (
          <img
            src={getFaviconByDomain(domain.domain, 16, true)}
            alt={tct('favicon for [domain]', { domain: domain.domain })}
          />
        ),
        onClick: () => navigate(getDomainLink(domain, domainPageTo)),
      })),
    ) || [];

  const groupsActions: SpotlightActionData[] =
    clients?.map((client) => ({
      label: client.clientName,
      id: `client${client.clientId}`,
      description: `${client?.domains?.length || 0} ${t('domains')}`,
      leftSection: <IconSitemap size={18} />,
      onClick: () =>
        navigate(makeDomainsLink(client.domains.map((domain) => domain.id.toString()))),
    })) || [];

  const subAccountActions: SpotlightActionData[] =
    linkedOrganizations?.map((account) => ({
      label: account.name,
      id: account.id,
      leftSection: <IconTopologyRing2 size={18} />,
      onClick: () => {
        if (account.multiAccountLink) {
          reduxActions.clearEverything();
          redirectToExternalUrl(`/org/multiaccount/change/${account?.multiAccountLink?.id}/`);
        }
      },
    })) || [];

  const actions = [
    { group: `${t('Domains')} - ${totalDomainCount}`, actions: domainActions },
    { group: `${t('Groups')} - ${totalGroups}`, actions: groupsActions },
    { group: `${t('Sub-Accounts')} - ${totalAccounts}`, actions: subAccountActions },
  ];

  return actions;
};
