import { Component } from 'react';
import { gql } from '@apollo/client';
import { graphql } from '@apollo/client/react/hoc';
import Skeleton from 'Components/Skeleton';
import { t } from 'Utilities/i18n/index';
import { graphqlOK } from 'Utilities/underdash';

type Props = {
  data: Record<string, any>;
  isOrgAdmin: boolean;
};

class PaymentContact extends Component<Props> {
  renderSkeleton() {
    return (
      <Skeleton
        linesConfig={
          [
            {
              type: 'chart',
              options: {
                flex: '1',
                height: '250px',
              },
            },
          ] as any
        }
      />
    );
  }

  render() {
    if (!graphqlOK(this.props)) {
      return this.renderSkeleton();
    }

    const {
      isOrgAdmin,
      data: {
        user: {
          organization: { paymentcontact: contact },
        },
      },
    } = this.props;
    let content;

    if (!isOrgAdmin) {
      content = <p>{t('You need to be organization admin to see billing information.')}</p>;
    } else if (!contact) {
      content = <p>{t('No billing information for this account')}</p>;
    } else {
      content = (
        <div>
          <p>
            <span>
              <strong>{contact.companyName}</strong>{' '}
            </span>
            <br />
            <span>{contact.street}</span>
            <br />
            <span>
              {contact.zipcode} {contact.city}
            </span>
            <br />
            <span>{contact.country.name}</span>
          </p>
          <p>
            <span>
              {t('P.O. number:')} {contact.poNumber || t('Not set')}
            </span>
          </p>
          <p>
            <span>
              {t('Email for invoices:')} {contact.emailInvoiceTo || t('Not set')}
            </span>
          </p>
        </div>
      );
    }

    return <div className="billing-box">{content}</div>;
  }
}

const dataQuery = gql`
  query paymentContact_paymentContact {
    user {
      id
      organization {
        id
        paymentcontact {
          id
          companyName
          street
          zipcode
          city
          state
          country {
            id
            name
          }
          emailInvoiceTo
          poNumber
        }
      }
    }
  }
`;
export default graphql<any>(dataQuery, {
  options: () => ({
    fetchPolicy: 'network-only',
  }),
})(PaymentContact);
