import moment from 'moment';
import { SelectItem } from 'Components/AccSelect/support/types';
import { KeywordNode, KeywordsInputQuery } from 'Ghql';

export const getNotesStaticFilters = (domainId: string | null) => {
  return [
    {
      attribute: 'period',
      type: 'datetime',
      comparison: 'between',
      value: `["${moment().format('YYYY-MM-DD')}", "${moment().format('YYYY-MM-DD')}"]`,
    },
    {
      attribute: 'compare_to',
      type: 'datetime',
      comparison: 'eq',
      value: `${moment().format('YYYY-MM-DD')}`,
    },
    {
      attribute: 'domains',
      type: 'list',
      comparison: 'contains',
      value: `[${domainId}]`,
    },
  ];
};

export const extractKeywordsOptionsFromNotes = (
  data?: KeywordsInputQuery,
): SelectItem<string>[] => {
  return (
    data?.keywords?.keywords
      ?.filter(Boolean)
      ?.map((e) => e as Partial<KeywordNode>)
      ?.map(({ id, keyword, ...rest }) => ({
        value: id!,
        label: keyword,
        ...rest,
      })) || []
  );
};
