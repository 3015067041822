import cn from 'classnames';
import AccText from 'Components/Text/AccText';
import { useSetOrOverwriteFilter } from 'Hooks/data/filter/setFilters';
import { ClientsFilter, FilterAttribute, FilterComparison, FilterValueType } from 'Types/Filter';
import { DashboardNode } from '../support/types';
import styles from '../domains-table.module.scss';

type Props = {
  domainNode: DashboardNode;
};

const GroupCell = (props: Props) => {
  const { client, clientId = '' } = props.domainNode;

  const setFilter = useSetOrOverwriteFilter();

  const handleSetGroupFilter = () => {
    const nextFilter: ClientsFilter = {
      attribute: FilterAttribute.CLIENTS,
      type: FilterValueType.LIST,
      comparison: FilterComparison.CONTAINS,
      value: [clientId],
    };
    setFilter(nextFilter, 'no tracking');
  };

  return (
    <div className={cn(styles.domainsCellContainer, styles.textCell)}>
      <AccText variant="link" onClick={handleSetGroupFilter}>
        {client}
      </AccText>
    </div>
  );
};

export default GroupCell;
