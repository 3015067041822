import AccTooltip from 'Components/AccTooltip/AccTooltip';
import { getFaviconByDomain } from 'Utilities/favicon';
import styles from './domainFavicon.module.scss';

export const DomainFavicon = ({
  domain,
  size = 'md',
  disabledTooltip = false,
}: {
  domain?: string | null;
  faviconPath?: string | null;
  size?: 'xs' | 'md';
  disabledTooltip?: boolean;
}) => {
  if (!domain) {
    return null;
  }
  const faviconUrl = getFaviconByDomain(domain);
  const favicon = (
    <img
      src={faviconUrl}
      alt={domain ?? ''}
      className={size === 'md' ? styles.default : styles.xs}
    />
  );
  if (disabledTooltip) return favicon;
  return (
    <AccTooltip tooltip={domain} disable={!domain}>
      {favicon}
    </AccTooltip>
  );
};
