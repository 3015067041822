import { useState } from 'react';
import { Kbd, TextInput } from '@mantine/core';
import cn from 'classnames';
import { t } from '../../../../../Utilities/i18n';
import styles from '../tree-table.module.scss';

export const TableSearchInput = ({ activeSearch, submitSearch }) => {
  const [search, setSearch] = useState('');
  const [focus, setFocused] = useState(false);

  const showSuggest = search && search !== activeSearch && focus;

  return (
    <TextInput
      p={0}
      ml={12}
      w={200}
      size="xs"
      data-active={activeSearch || null}
      placeholder={t('Search...')}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          submitSearch(e.currentTarget.value);
        }
      }}
      classNames={{ input: styles.textInput, root: styles.textRoot }}
      onChange={(e) => setSearch(e.currentTarget.value)}
      value={search}
      onFocus={() => setFocused(true)}
      onBlur={() => {
        setFocused(false);
        if (activeSearch !== search) {
          submitSearch(search);
        }
      }}
      rightSection={<Kbd className={cn(styles.searchKbd, { [styles.show]: showSuggest })}>↵</Kbd>}
    />
  );
};
