import { useEffect, useState } from 'react';
import { Navigate } from 'react-router';
import { Flex } from '@mantine/core';
import * as Sentry from '@sentry/react';
import isEmpty from 'lodash/isEmpty';
import { clearEverything } from 'Actions/ResetAction';
import AccButton from 'Components/AccButton/AccButton';
import { Form } from 'Components/Fields';
import {
  PricingPlanNode,
  UnconfirmedUserNode,
  useRegisterPageCountriesQuery,
  useRegisterPagePayNowPlanQuery,
  useRegisterPagePricingPlanQuery,
  useRegisterPageUnconfirmedUserQuery,
} from 'Ghql';
import { useUser } from 'Hooks/data/user/useUser';
import { useActions } from 'Hooks/redux/useActions';
import { useTrialConversionTracking } from 'Hooks/useConversionTracking';
import AuthPageContainer from 'Pages/Auth/Shared/AuthPageContainer/AuthPageContainer';
import PaymentWidget from 'Pages/Billing/PaymentWidget';
import { useRouteMatch } from 'Utilities/Router/hooks/useRouteMatch';
import { getCountryCodeFromTLD } from 'Utilities/getCountryCodeFromTld';
import { t } from 'Utilities/i18n';
import { redirectToExternalUrl } from 'Utilities/underdash';
import AddressInfo from './components/AddressInfo';
import CompanyInfo from './components/CompanyInfo';
import ContactInfo from './components/ContactInfo';
import PlanInfo from './components/PlanInfo';
import TermsAndConditions from './components/TermsAndConditions';
import { useCountryAndVATFields } from './utils/useCountryAndVATFields';
import { useRegistrationSubmit } from './utils/useRegistrationSubmit';

export type CountryOption = {
  label: string;
  value: string;
  vatCode: string;
  countryCode: string;
};

const TRIAL = 'A_1'; // standard trial (don't show payment)
export const TRIAL_CC_AND_PAY = 'A_2'; // abusive country (show payment)
export const PAY = 'A_3'; // may be unused

export enum FIELD_NAMES {
  FULL_NAME = 'fullName',
  JOB_TITLE = 'jobTitle',
  PHONE = 'phone',
  EMAIL = 'email',
  PASSWORD = 'password',
  ORG_NAME = 'organizationName',
  ORG_URL = 'organizationURL',
  ORG_COUNTRY = 'organizationCountry',
  URL_TO_TRACK = 'urlToTrack',
  STREET = 'street',
  CITY = 'city',
  STATE = 'state',
  ZIPCODE = 'zipcode',
  COUNTRY = 'country',
  VAT_PREFIX = 'vatPrefix',
  VAR_NUMBER = 'varNumber',
  TERMS = 'termsAccepted',
}

const hubspotTrackPageView = () => {
  try {
    if (!window?.location?.pathname?.startsWith('/app/register/')) return; // only track on the register page
    if (window?.location?.hostname !== 'app.accuranker.com') return; // only prod

    if (!(window as any)?._hsq) {
      (window as any)._hsq = [];
    }

    const _hsq = (window as any)._hsq;

    _hsq.push(['setPath', window.location.pathname]);
    _hsq.push(['trackPageView']);
  } catch (e) {
    // ignore error
  }
};

const RegisterPage = () => {
  useTrialConversionTracking();
  const user = useUser();

  useEffect(() => {
    (window as any)?.registerHotJar?.();
    hubspotTrackPageView();
    return () => {
      (window as any)?.unregisterHotJar?.();
      (window as any)._hsq = [];
    };
  }, []);

  const match = useRouteMatch();
  const unconfirmedUserId = match.params?.id;

  // Unconfirmed user
  const { data: uuData, error: uuError } = useRegisterPageUnconfirmedUserQuery({
    variables: { id: unconfirmedUserId ?? '' },
  });
  const signupType = uuData?.unconfirmedUser?.signupType;
  const mustPayNow = signupType === TRIAL_CC_AND_PAY || signupType === PAY;

  // Standard pricing plan
  const { data: ppData, error: ppError } = useRegisterPagePricingPlanQuery({
    variables: { id: unconfirmedUserId ?? '' },
    skip: signupType === PAY,
  });
  // Abusive country pricing plan
  const { data: pnpData, error: pnpError } = useRegisterPagePayNowPlanQuery({
    skip: signupType === TRIAL,
  });
  const pricingPlan: PricingPlanNode | undefined =
    (ppData?.pricingPlan as PricingPlanNode) || (pnpData?.payNowPlan as PricingPlanNode);

  // Countries
  const { data: cData, error: cError } = useRegisterPageCountriesQuery();
  const { countryOptions, vatOptions, currentCountryOption } = useCountryAndVATFields({
    countriesData: cData,
  });

  const [initialValues, setInitialValues] = useState<any>();
  const [braintreeInstance, setBraintreeInstance] = useState<any | false>(false);
  const [braintreeUniqueId, setBraintreeUniqueId] = useState<number>(+new Date());

  const actions = useActions({ clearEverything });

  const { handleSubmit } = useRegistrationSubmit({
    match,
    setBraintreeUniqueId,
    signupType,
    braintreeInstance,
    pricingPlan,
    unconfirmedUserData: uuData,
  });

  useEffect(() => {
    const emailParts = uuData?.unconfirmedUser?.email?.split('@');
    const orgUrlDefaultValue = emailParts?.length === 2 ? emailParts[1] : '';
    const orgCountryDefaultValue = orgUrlDefaultValue
      ? getCountryCodeFromTLD(countryOptions, orgUrlDefaultValue)
      : '';
    setInitialValues({
      [FIELD_NAMES.FULL_NAME]: uuData?.unconfirmedUser?.prefillFullName || '',
      [FIELD_NAMES.EMAIL]: uuData?.unconfirmedUser?.email || '',
      [FIELD_NAMES.PHONE]: uuData?.unconfirmedUser?.phoneNumber || '',
      [FIELD_NAMES.COUNTRY]: uuData?.unconfirmedUser?.createdByCountry || 'DK',
      [FIELD_NAMES.TERMS]: false,
      [FIELD_NAMES.ORG_URL]: orgUrlDefaultValue,
      [FIELD_NAMES.ORG_COUNTRY]: orgCountryDefaultValue,
      ...(currentCountryOption && { [FIELD_NAMES.COUNTRY]: currentCountryOption?.value }),
    });
  }, [countryOptions, uuData, currentCountryOption]);

  const error = !!uuError || !!ppError || !!pnpError || !!cError;
  if (error) {
    Sentry.captureException(error);
  }

  if (uuError) {
    return <Navigate replace to={'/error/404'} />;
  }

  const handleStopImpersonate = () => {
    actions.clearEverything();
    redirectToExternalUrl('/accuranker_admin/impersonate/stop/');
  };

  return (
    <AuthPageContainer
      heroText={t('The World\'s Fastest & Most Accurate Rank Tracker')}
      formHeader={
        <PlanInfo
          pricingPlan={pricingPlan}
          unconfirmedUser={uuData?.unconfirmedUser as UnconfirmedUserNode | undefined}
        />
      }
    >
      <Flex direction="column">
        <Form onSubmit={handleSubmit} initialValues={initialValues}>
          {({ submitting, errors }) => {
            return (
              <Flex direction="column" rowGap="md">
                <ContactInfo />

                <CompanyInfo
                  countryOptions={countryOptions ?? []}
                  isSocialSignup={!!uuData?.unconfirmedUser?.isSocialSignup}
                />

                {mustPayNow && (
                  <AddressInfo countryOptions={countryOptions ?? []} vatOptions={vatOptions} />
                )}

                {mustPayNow && (
                  <PaymentWidget
                    onCreate={(instance) => setBraintreeInstance(instance)}
                    uniqueid={braintreeUniqueId}
                    braintreeThreeDSecureAmount={pricingPlan?.priceMonthly ?? 1}
                    hideTitle
                  />
                )}

                <TermsAndConditions />

                <AccButton
                  my="xxs"
                  mx="auto"
                  type="submit"
                  variant="primary"
                  fullWidth
                  disabled={submitting || !isEmpty(errors)}
                >
                  {signupType === TRIAL_CC_AND_PAY || signupType === TRIAL
                    ? t('Activate trial')
                    : t('Pay')}
                </AccButton>
                {user?.isImpersonating && (
                  <AccButton
                    my="xxs"
                    mx="auto"
                    type="button"
                    variant="destructive"
                    onClick={handleStopImpersonate}
                  >
                    {t('Stop impersonating')}
                  </AccButton>
                )}
              </Flex>
            );
          }}
        </Form>
      </Flex>
    </AuthPageContainer>
  );
};

export default RegisterPage;
