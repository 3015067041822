import { useMemo } from 'react';
import UrlWithStatusCell from 'Components/AccuTable/CellRenderer/CellUrlWithStatus/CellUrlWithStatus';
import { withKeywordCellLoader } from 'Components/AccuTable/CellRenderer/HelperComponents/CellLoaders/withKeywordCellLoader';
import ActionsCell from 'Components/AccuTable/CellRenderer/actions';
import AiTrafficValueChangeCell from 'Components/AccuTable/CellRenderer/aiTrafficValueChange';
import CheckboxCell from 'Components/AccuTable/CellRenderer/checkBox';
import CompetitionCell from 'Components/AccuTable/CellRenderer/competition';
import CountryCell from 'Components/AccuTable/CellRenderer/countryCell';
import CPCCell from 'Components/AccuTable/CellRenderer/cpc';
import CTRCell from 'Components/AccuTable/CellRenderer/ctr';
import CtrChangeCell from 'Components/AccuTable/CellRenderer/ctrChange';
import MaxCTRCell from 'Components/AccuTable/CellRenderer/ctrMax';
import DateAddedCell from 'Components/AccuTable/CellRenderer/dateAdded';
import DescriptionCell from 'Components/AccuTable/CellRenderer/description';
import DomainCell from 'Components/AccuTable/CellRenderer/domain';
import KeywordCell from 'Components/AccuTable/CellRenderer/keyword';
import KeywordTimeAgoCell from 'Components/AccuTable/CellRenderer/keywordTimeAgo';
import LanguageCell from 'Components/AccuTable/CellRenderer/languageCell';
import LocationCell from 'Components/AccuTable/CellRenderer/locationCell';
import MaxAiTrafficValueCell from 'Components/AccuTable/CellRenderer/maxAiTrafficValueCell';
import PixelsFromTopCell from 'Components/AccuTable/CellRenderer/pixelsFromTop';
import PixelsFromTopChangeCell from 'Components/AccuTable/CellRenderer/pixelsFromTopChangeCell';
import RankCell, { getRankLoadingTooltip } from 'Components/AccuTable/CellRenderer/rank';
import RankChangeCell from 'Components/AccuTable/CellRenderer/rankChange';
import SearchIntentCell from 'Components/AccuTable/CellRenderer/searchIntent';
import SearchTypeEngineCell from 'Components/AccuTable/CellRenderer/searchTypeEngine';
import SearchVolumeCell from 'Components/AccuTable/CellRenderer/searchVolume';
import SerpCell, { SerpFeatureNode } from 'Components/AccuTable/CellRenderer/serp';
import ShareOfVoiceCell from 'Components/AccuTable/CellRenderer/shareOfVoice';
import ShareOfVoiceCPCCell from 'Components/AccuTable/CellRenderer/shareOfVoiceCPC';
import ShareOfVoiceCPCChangeCell from 'Components/AccuTable/CellRenderer/shareOfVoiceCPCChange';
import ShareOfVoiceChangeCell from 'Components/AccuTable/CellRenderer/shareOfVoiceChange';
import SimpleNumberCell from 'Components/AccuTable/CellRenderer/simpleNumberCell';
import TopDomainCell from 'Components/AccuTable/CellRenderer/topDomain';
import TrafficCell from 'Components/AccuTable/CellRenderer/traffic';
import TrafficChangeCell from 'Components/AccuTable/CellRenderer/trafficChange';
import {
  ColumnType,
  TableLabels,
  WithRowContext,
  tableClassName,
  withCellContext,
  withRowContext,
} from 'Components/DataTable';
import { DataTableProps } from 'Components/DataTable/DataTable';
import { FixedType } from 'Components/DataTable/table-core/interface';
import linkWithFilters from 'Components/Filters/linkWithFilters';
import { MenuItemIds } from 'Components/Modal/Content/KeywordInfo';
import { useModal } from 'Hooks/base/useModal';
import { useIsGroupDomains, useLanguage } from 'Hooks/data/domain/useQueryDomainInfo';
import { useHasEnabledNewFeatures } from 'Hooks/data/organization/useOrganizationInfo';
import { useMetaData } from 'Hooks/data/user/useMetaData';
import useKeyword from 'Hooks/useKeyword';
import useUserPermission from 'Hooks/useUserPermission';
import { DomainTypeChoices } from 'Query';
import { FilterAttribute, FilterComparison, FilterValueType } from 'Types/Filter';
import { KEYWORDS_FILTER_SET } from 'Types/FilterSet';
import { TableID } from 'Types/Table';
import { WithRouterHistory } from 'Utilities/Router';
import { t, tct } from 'Utilities/i18n';
import DevicesIcon from 'icons/devices.svg?inline';
import StarIcon from 'icons/star.svg?inline';
import { keywordOrderConfig } from './hooks/keyword/constants';
import { getKeydisCells } from './keydisCells';
import { IconContainer } from './support/IconContainer/IconContainer';
import { KeywordColumnID, keywordColumnOrdering } from './support/constants';

type UseKeywordTableInfoProps = Pick<
  DataTableProps,
  'columns' | 'labels' | 'defaultOrdering' | 'viewMode'
>;

type Props = {
  tableName: TableID;
  history: WithRouterHistory;
  displayCurrency: string | undefined;
  isKeyDis?: boolean;
  domainId?: string;
  isLatestFilter?: boolean;
  isDemoDomain?: boolean;
  domainType?: DomainTypeChoices;
};

export const NaverBlacklist: string[] = [
  KeywordColumnID.DYNAMIC_CTR,
  KeywordColumnID.DYNAMIC_CTR_CHANGE,
  KeywordColumnID.DYNAMIC_CTR_MAX,
  KeywordColumnID.ORGANIC_TRAFFIC,
  KeywordColumnID.ORGANIC_TRAFFIC_CHANGE,
  KeywordColumnID.MAX_AI_SOV,
  KeywordColumnID.CPC,
  KeywordColumnID.AD_COMPETITION,
  KeywordColumnID.SEARCH_INTENT,
  KeywordColumnID.SHARE_OF_VOICE_CPC,
  KeywordColumnID.SHARE_OF_VOICE_CPC_CHANGE,
  KeywordColumnID.MAX_TRAFFIC_VALUE,
  KeywordColumnID.PIXELS_FROM_TOP,
  KeywordColumnID.PIXELS_FROM_TOP_CHANGE,
  KeywordColumnID.AI_TRAFFIC_VALUE,
  KeywordColumnID.AI_TRAFFIC_VALUE_CHANGE,
  KeywordColumnID.MAX_AI_TRAFFIC_VALUE,
];

// some table columns are not supported for Naver domains. remove those from the array
const filterByDomainType = (
  keywordTableColumns: ColumnType[],
  domainType: DomainTypeChoices | undefined,
): ColumnType[] => {
  if (domainType === DomainTypeChoices.A_7) {
    return keywordTableColumns.filter((col) => !col.id || NaverBlacklist.indexOf(col.id) === -1);
  }
  return keywordTableColumns;
};

export const useKeywordTableInfo = ({
  history,
  isKeyDis,
  domainId,
  isLatestFilter,
  displayCurrency,
  isDemoDomain,
  domainType,
}: Props): UseKeywordTableInfoProps => {
  const { showModal } = useModal();
  const language = useLanguage();
  const metaData = useMetaData();
  const isGroupMode = useIsGroupDomains();
  const { userHasWriteAccess } = useUserPermission();
  const newFeaturesEnabled = useHasEnabledNewFeatures();

  const MAX_COLUMN_WIDTH = language === 'da' ? 60 : 55;

  const {
    updateKeywordsTags,
    updateKeywordsDescription,
    updateKeywordsStarred,
    updateKeywordsRefresh,
    showKeywordModal,
    showLandingPageModal,
  } = useKeyword({
    showModal,
  });

  const columns: ColumnType[] = useMemo(() => {
    // https://accuranker.myjetbrains.com/youtrack/issue/ARR-2300
    // we show loader only if latest keyword filter
    const loadingWrapper =
      isLatestFilter && !isKeyDis
        ? withKeywordCellLoader
        : (((WrappedComponent) => WrappedComponent) as typeof withKeywordCellLoader);

    if (isKeyDis) {
      return getKeydisCells({
        showKeywordModal,
        updateKeywordsTags,
        language,
        metaData,
        history,
        displayCurrency,
        userHasWriteAccess,
      });
    }

    const checkBoxColumns: ColumnType[] = [
      {
        id: KeywordColumnID.CHECKBOX,
        width: 40,
        fixed: 'left' as FixedType,
        combineRow: KeywordColumnID.CHECKBOX_STARRED,
        onHeaderCell: () => ({
          type: 'checkbox',
        }),
        onCell: (record: any) => ({
          type: 'checkbox',
          data: record,
        }),
      },
    ];
    const keywordTableColumns: ColumnType[] = [
      {
        id: KeywordColumnID.CHECKBOX_STARRED,
        onHeaderCell: () => ({
          tooltip: t('Starred keywords'),
          filter: {
            filterAttributes: [FilterAttribute.STARRED],
            filterContent: (
              <IconContainer size="sm">
                <StarIcon />
              </IconContainer>
            ),
            filterClassName: 'column-filter-btn--star',
            filterTooltip: t('Filter starred keywords'),
          },
          disabledMenu: true,
        }),
        width: 36,
        fixed: 'left' as FixedType,
        cellRenderer: CheckboxCell,
        cellRendererParams: {
          disabled: isDemoDomain,
          type: 'star',
          onChange: (id: any, checked: any) =>
            updateKeywordsStarred({
              keywords: [id],
              starred: checked,
            }),
        },
      },
      isGroupMode
        ? {
            id: KeywordColumnID.DOMAIN,
            title: t('Domain'),
            onHeaderCell: () => ({
              tooltip: t('Domain'),
              ordering: keywordOrderConfig.domain,
            }),
            width: 170,
            fixed: 'left' as FixedType,
            cellRenderer: DomainCell,
          }
        : {},
      {
        id: KeywordColumnID.KEYWORD,
        combineRow: KeywordColumnID.SEARCH_TYPE,
        title: t('Keyword'),
        onHeaderCell: () => ({
          tooltip: t('Keyword'),
          filter: {
            filterAttributes: [
              FilterAttribute.KEYWORD,
              FilterAttribute.MULTIPLE_KEYWORDS,
              FilterAttribute.TAGS,
              FilterAttribute.KEYWORD_OPTIONS,
            ],
            filterTooltip: t('Add filter for Keyword'),
            filterId: 'keyword-filter',
            hasLargeFilter: true,
          },
          ordering: keywordOrderConfig.keyword,
        }),
        width: 250,
        fixed: 'left' as FixedType,
        flex: true,
        cellRenderer: withCellContext(KeywordCell as any) as any,
        cellRendererParams: {
          serpFeatures: metaData?.serpFeatures,
          onKeywordClick: (id: any, rankId: any, _domainId: any) => {
            showKeywordModal({
              keywordId: id,
              domainId: _domainId,
              rankId,
              initialTab: MenuItemIds.TAB_RANKS_HISTORY,
            });
          },
          onTagRemoveClick: (id: number, tag: any) =>
            updateKeywordsTags({
              keywords: [id],
              tags: [tag],
              remove: true,
            }),
          onTagClick: (_domainId: string, tag: any) => {
            const tagFilter = {
              attribute: FilterAttribute.TAGS,
              type: FilterValueType.ARRAY,
              comparison: tag ? FilterComparison.ANY : FilterComparison.EMPTY,
              value: [tag],
            };

            history.push(
              linkWithFilters({
                to: '/keywords/list',
                newFilters: [tagFilter],
                filterSet: KEYWORDS_FILTER_SET,
              }),
            );
          },
          showOptions: true,
          isDemoDomain,
        },
      },
      {
        id: KeywordColumnID.SEARCH_TYPE,
        combineRow: KeywordColumnID.SEARCH_INTENT,
        title: (
          <IconContainer size="md">
            <DevicesIcon />
          </IconContainer>
        ),
        onHeaderCell: () => ({
          tooltip: t('Search Type'),
          filter: {
            filterAttributes: [FilterAttribute.SEARCH_ENGINE, FilterAttribute.SEARCH_TYPE],
            filterTooltip: t('Add filter for Search Type'),
          },
          menuTitle: t('Search Type'),
        }),
        width: 64,
        fixed: 'left' as FixedType,
        cellRenderer: SearchTypeEngineCell,
        cellRendererParams: {
          searchTypes: metaData?.searchTypes,
          searchEngines: metaData?.searchEngines,
          cellClassName: 'vertical-alignment-top',
        },
      },
      {
        id: KeywordColumnID.SEARCH_INTENT,
        title: 'SI',
        onHeaderCell: () => ({
          tooltip: t('Search Intent'),
          filter: {
            filterAttributes: [FilterAttribute.SEARCH_INTENT],
            filterTooltip: t('Add filter for Search Intent'),
          },
          ordering: keywordOrderConfig.searchIntent,
          menuTitle: t('Search Intent'),
        }),
        width: 72,
        cellRenderer: SearchIntentCell,
      },
      {
        id: KeywordColumnID.DESCRIPTION,
        title: t('Description'),
        onHeaderCell: () => ({
          tooltip: t('Description'),
          ordering: keywordOrderConfig.description,
        }),
        width: 200,
        className: tableClassName('description-cell'),
        cellRenderer: DescriptionCell,
        cellRendererParams: {
          onChange: (id: any, description: any) =>
            updateKeywordsDescription({
              keywords: [id],
              description,
            }),
        },
      },
      {
        id: KeywordColumnID.COUNTRY,
        title: t(' '),
        onHeaderCell: () => ({
          tooltip: t('Country'),
          filter: {
            filterAttributes: [FilterAttribute.COUNTRY_LOCALE],
            filterTooltip: t('Add filter for Country Locale'),
          },
          menuTitle: t('Country'),
        }),
        width: 38,
        cellRenderer: CountryCell,
        cellRendererParams: {
          countryLocales: metaData?.countrylocales,
        },
      },
      {
        id: KeywordColumnID.LOCATION,
        title: t('Location'),
        onHeaderCell: () => ({
          tooltip: t('Location'),
          filter: {
            filterAttributes: [FilterAttribute.LOCATION],
            filterTooltip: t('Add filter for Location'),
          },
          ordering: keywordOrderConfig.countryLocale,
        }),
        width: 120,
        cellRenderer: LocationCell,
        cellRendererParams: {
          countryLocales: metaData?.countrylocales,
        },
      },
      {
        id: KeywordColumnID.LOCALE,
        title: t('Language'),
        width: 80,
        cellRenderer: LanguageCell,
        cellRendererParams: {
          countryLocales: metaData?.countrylocales,
        },
      },
      {
        id: KeywordColumnID.RANK,
        combineRow: KeywordColumnID.RANK_CHANGE,
        title: t('Rank'),
        onHeaderCell: () => ({
          tooltip: t('Rank'),
          ordering: keywordOrderConfig.rank,
          filter: {
            filterAttributes: [FilterAttribute.RANK],
            filterTooltip: t('Add filter for Rank'),
            hasMediumFilter: true,
            filterLabel: t('Rank column filters'),
          },
          reverseDirection: true,
        }),
        width: 85,
        cellRenderer: loadingWrapper(
          withRowContext(RankCell as React.ComponentType<React.PropsWithChildren<WithRowContext>>),
          {
            overlayOnly: true,
            getTooltip: getRankLoadingTooltip,
          },
        ),
      },
      {
        id: KeywordColumnID.RANK_CHANGE,
        title: t('+/-'),
        onHeaderCell: () => ({
          tooltip: t('Change in rank between the two compared dates'),
          ordering: keywordOrderConfig.rankChange,
          reverseDirection: true,
          filter: {
            filterAttributes: [FilterAttribute.RANK_CHANGE],
            filterTooltip: t('Add filter for Rank'),
            hasMediumFilter: true,
            filterLabel: t('Rank column filters'),
          },
          menuTitle: t('Rank change'),
        }),
        width: 56,
        cellRenderer: loadingWrapper(RankChangeCell, {
          getTooltip: getRankLoadingTooltip,
        }),
      },
      {
        id: KeywordColumnID.BASE_RANK,
        combineRow: KeywordColumnID.BASE_RANK_CHANGE,
        title: t('Base Rank'),
        onHeaderCell: () => ({
          tooltip: t('Base Rank'),
          ordering: keywordOrderConfig.baseRank,
          filter: {
            filterAttributes: [FilterAttribute.BASE_RANK],
            filterTooltip: t('Add filter for Base Rank'),
            hasMediumFilter: true,
            filterLabel: t('Base rank column filters'),
          },
          reverseDirection: true,
        }),
        width: 85,
        cellRenderer: loadingWrapper(
          withRowContext(RankCell as React.ComponentType<React.PropsWithChildren<WithRowContext>>),
          {
            overlayOnly: true,
            getTooltip: getRankLoadingTooltip,
          },
        ),
        cellRendererParams: { isBaseRank: true },
      },
      {
        id: KeywordColumnID.BASE_RANK_CHANGE,
        combineRow: KeywordColumnID.MAX_RANK,
        title: t('+/-'),
        onHeaderCell: () => ({
          tooltip: t('Change in Base Rank between the two compared dates'),
          ordering: keywordOrderConfig.baseRankChange,
          reverseDirection: true,
          filter: {
            filterAttributes: [FilterAttribute.BASE_RANK_CHANGE],
            filterTooltip: t('Add filter for Base Rank'),
            hasMediumFilter: true,
            filterLabel: t('Base rank column filters'),
          },
          menuTitle: t('Base Rank change'),
        }),
        width: 56,
        cellRenderer: loadingWrapper(RankChangeCell, {
          getTooltip: getRankLoadingTooltip,
        }),
        cellRendererParams: { isBaseRank: true },
      },
      {
        id: KeywordColumnID.MAX_RANK,
        title: t('Max'),
        onHeaderCell: () => ({
          tooltip: t('Max Base Rank'),
          ordering: keywordOrderConfig.maxRank,
          reverseDirection: true,
          filter: {
            filterAttributes: [FilterAttribute.MAX_RANK],
            filterTooltip: t('Add filter for Base Rank'),
            hasMediumFilter: true,
            filterLabel: t('Base rank column filters'),
          },
          menuTitle: t('Max Base Rank'),
        }),
        width: MAX_COLUMN_WIDTH,
        cellRenderer: loadingWrapper(withRowContext(SimpleNumberCell), {
          overlayOnly: true,
          getTooltip: getRankLoadingTooltip,
        }),
        cellRendererParams: { colName: 'maxRank', nDecimals: 0 },
      },
      {
        id: KeywordColumnID.LOCAL_PACK_RANK,
        combineRow: KeywordColumnID.LOCAL_PACK_RANK_CHANGE,
        title: t('LPR'),
        onHeaderCell: () => ({
          tooltip: t('Local Pack Rank'),
          ordering: keywordOrderConfig.localPackRank,
          filter: {
            filterAttributes: [FilterAttribute.LOCAL_PACK_RANK],
            filterTooltip: t('Add filter for Local Pack Rank'),
            hasMediumFilter: true,
            filterLabel: t('Local pack rank column filters'),
          },
          menuTitle: t('Local Pack Rank'),
          reverseDirection: true,
        }),
        width: 62,
        cellRenderer: loadingWrapper(
          withRowContext(RankCell as React.ComponentType<React.PropsWithChildren<WithRowContext>>),
          {
            overlayOnly: true,
            getTooltip: getRankLoadingTooltip,
          },
        ),
        cellRendererParams: { isLocalPackRank: true },
      },
      {
        id: KeywordColumnID.LOCAL_PACK_RANK_CHANGE,
        title: t('+/-'),
        onHeaderCell: () => ({
          tooltip: t('Change in Local Pack Rank between the two compared dates'),
          ordering: keywordOrderConfig.localPackRankChange,
          reverseDirection: true,
          filter: {
            filterAttributes: [FilterAttribute.LOCAL_PACK_RANK_CHANGE],
            filterTooltip: t('Add filter for Local Pack Rank'),
            hasMediumFilter: true,
            filterLabel: t('Local pack rank column filters'),
          },
          menuTitle: t('LPR change'),
        }),
        width: 56,
        cellRenderer: loadingWrapper(RankChangeCell, {
          getTooltip: getRankLoadingTooltip,
        }),
        cellRendererParams: { isLocalPackRank: true },
      },
      {
        id: KeywordColumnID.AI_TRAFFIC_VALUE,
        combineRow: KeywordColumnID.AI_TRAFFIC_VALUE_CHANGE,
        title: `${t('AI Traffic Value')} (${displayCurrency})`,
        onHeaderCell: () => ({
          tooltip: t('AI Traffic Value'),
          ordering: keywordOrderConfig.aiTrafficValue,
          filter: {
            filterAttributes: [FilterAttribute.AI_TRAFFIC_VALUE],
            filterTooltip: t('Add filter for AI Traffic Value'),
            filterLabel: t('AI Traffic Value filters'),
          },
          reverseDirection: true,
        }),
        width: language === 'da' ? 160 : 136,

        cellRenderer: loadingWrapper(ShareOfVoiceCPCCell, {
          dependsOnSearchVolume: true,
        }),
        cellRendererParams: {
          useAi: true,
        },
      },
      {
        id: KeywordColumnID.AI_TRAFFIC_VALUE_CHANGE,
        combineRow: KeywordColumnID.MAX_AI_TRAFFIC_VALUE,
        title: t('+/-'),
        onHeaderCell: () => ({
          tooltip: t('Change in AI Traffic Value Change between the two compared dates'),
          ordering: keywordOrderConfig.aiTrafficValueChange,
          reverseDirection: true,
          filter: {
            filterAttributes: [FilterAttribute.AI_TRAFFIC_VALUE_CHANGE],
            filterTooltip: t('Add filter for AI Traffic Value'),
            filterLabel: t('AI Traffic Value filters'),
          },
          menuTitle: t('Change in AI Traffic Value'),
        }),
        width: 75,
        cellRenderer: loadingWrapper(AiTrafficValueChangeCell, {
          dependsOnSearchVolume: true,
          overlayOnly: true,
        }),
      },
      {
        id: KeywordColumnID.MAX_AI_TRAFFIC_VALUE,
        title: t('Max'),
        onHeaderCell: () => ({
          tooltip: t('Max AI Traffic Value'),
          ordering: keywordOrderConfig.maxAiTrafficValue,
          reverseDirection: true,
          filter: {
            filterAttributes: [FilterAttribute.MAX_AI_TRAFFIC_VALUE],
            filterTooltip: t('Add filter for AI Traffic Value'),
            filterLabel: t('AI Traffic Value filters'),
          },
          menuTitle: t('Max AI Traffic Value'),
        }),
        width: 65,
        cellRenderer: loadingWrapper(MaxAiTrafficValueCell, {
          dependsOnSearchVolume: true,
          overlayOnly: true,
        }),
        cellRendererParams: { colName: 'maxAiTrafficValue' },
      },

      {
        id: KeywordColumnID.TOP_DOMAIN,
        title: t('Top Competitor'),
        width: 150,
        cellRenderer: loadingWrapper(TopDomainCell, {
          dependsOnSearchVolume: false,
        }),
        onHeaderCell: () => ({
          tooltip: t('Top Competitor'),
          filter: {
            filterAttributes: [FilterAttribute.TOP_COMPETITOR],
            filterTooltip: t('Add filter for Top Competitor'),
            filterLabel: t('Top competitor filter'),
          },
        }),
      },
      {
        id: KeywordColumnID.PIXELS_FROM_TOP,
        combineRow: KeywordColumnID.PIXELS_FROM_TOP_CHANGE,
        title: `${t('Pixels from Top')}`,
        onHeaderCell: () => ({
          tooltip: t('Pixels from Top'),
          ordering: keywordOrderConfig.pixelsFromTop,
          filter: {
            filterAttributes: [FilterAttribute.PIXELS_FROM_TOP, FilterAttribute.ABOVE_THE_FOLD],
            filterTooltip: t('Add filter for Pixels from Top'),
          },
          reverseDirection: true,
        }),
        width: 104,
        cellRenderer: loadingWrapper(PixelsFromTopCell, {
          dependsOnSearchVolume: false,
        }),
        cellRendererParams: { nDecimals: 0 },
      },
      {
        id: KeywordColumnID.PIXELS_FROM_TOP_CHANGE,
        title: t('+/-'),
        onHeaderCell: () => ({
          tooltip: t('Change in pixel from top between the two compared dates'),
          ordering: keywordOrderConfig.pixelsFromTopChange,
          reverseDirection: true,
          filter: {
            filterAttributes: [FilterAttribute.PIXELS_FROM_TOP_CHANGE],
            filterTooltip: t('Add filter for Pixels from Top'),
          },
          menuTitle: t('Change in Pixels from Top'),
        }),
        width: 70,
        cellRenderer: loadingWrapper(PixelsFromTopChangeCell, {
          dependsOnSearchVolume: false,
          overlayOnly: true,
        }),
      },
      {
        id: KeywordColumnID.URL,
        flex: true,
        title: t('URL'),
        onHeaderCell: () => ({
          tooltip: t('URL'),
          filter: {
            filterAttributes: [
              FilterAttribute.HIGHEST_RANKING_PAGE,
              FilterAttribute.HIGHEST_RANKING_PAGE_MATCH,
            ],
            hasMediumFilter: true,
            filterTooltip: t('Add filter for URL'),
          },
          ordering: keywordOrderConfig.url,
        }),
        width: 240,
        cellRenderer: loadingWrapper(UrlWithStatusCell, {
          getTooltip: () => t('Refreshing rank'),
        }),
        cellRendererParams: {
          isDemoDomain,
          onClick: (
            id: any,
            _domainId: any,
            highestRankingPage: any,
            preferredLandingPageId: any,
          ) => {
            showLandingPageModal({
              keywords: [id],
              domainId: _domainId,
              isAllSelected: false,
              preferredLandingPageId,
              highestRankingPage,
            });
          },
        },
      },
      {
        id: KeywordColumnID.SEARCH_VOLUME,
        title: t('Search Volume'),
        onHeaderCell: () => ({
          tooltip: t('Monthly Search Volume'),
          filter: {
            filterAttributes: [FilterAttribute.SEARCH_VOLUME],
            filterTooltip: t('Filter searches'),
          },
          ordering: keywordOrderConfig.searchVolume,
          reverseDirection: true,
          menuTitle: t('Search Volume'),
        }),
        width: 96,
        cellRenderer: loadingWrapper(withRowContext(SearchVolumeCell), {
          dependsOnSearchVolume: true,
        }),
        cellRendererParams: { useAi: false },
      },
      ...(newFeaturesEnabled
        ? [
            {
              id: KeywordColumnID.AI_SEARCH_VOLUME,
              title: t('AI Search Volume'),
              onHeaderCell: () => ({
                tooltip: t('Monthly AI Search Volume'),
                filter: {
                  filterAttributes: [FilterAttribute.AI_SEARCH_VOLUME],
                  filterTooltip: t('Filter AI Search Volume'),
                },
                ordering: keywordOrderConfig.aiSearchVolume,
              }),
              width: language === 'da' ? 120 : 110,
              cellRenderer: loadingWrapper(withRowContext(SearchVolumeCell), {
                dependsOnSearchVolume: true,
              }),
              cellRendererParams: { useAi: true },
            },
          ]
        : []),
      {
        id: KeywordColumnID.DYNAMIC_CTR,
        combineRow: KeywordColumnID.DYNAMIC_CTR_CHANGE,
        title: t('CTR'),
        onHeaderCell: () => ({
          tooltip: t('Click-through Rate'),
          ordering: keywordOrderConfig.dynamicCtr,
          filter: {
            filterAttributes: [FilterAttribute.DYNAMIC_CTR],
            filterTooltip: t('Add filter for CTR'),
          },
          reverseDirection: true,
          menuTitle: t('Click-through Rate'),
        }),
        width: 60,
        cellRenderer: loadingWrapper(CTRCell, { overlayOnly: true }),
      },
      {
        id: KeywordColumnID.DYNAMIC_CTR_CHANGE,
        combineRow: KeywordColumnID.DYNAMIC_CTR_MAX,
        title: t('+/-'),
        onHeaderCell: () => ({
          tooltip: t('Change in ctr between the two compared dates'),
          ordering: keywordOrderConfig.dynamicCtrChange,
          reverseDirection: true,
          filter: {
            filterAttributes: [FilterAttribute.DYNAMIC_CTR_CHANGE],
            filterTooltip: t('Add filter for CTR'),
          },
          menuTitle: t('Change in CTR'),
        }),
        width: 60,
        cellRenderer: loadingWrapper(CtrChangeCell, { overlayOnly: true }),
      },
      {
        id: KeywordColumnID.DYNAMIC_CTR_MAX,
        combineRow: KeywordColumnID.DYNAMIC_CTR_POTENTIAL,
        title: t('Max'),
        onHeaderCell: () => ({
          tooltip: t('Max Click-through Rate'),
          ordering: keywordOrderConfig.dynamicCtrMax,
          reverseDirection: true,
          filter: {
            filterAttributes: [FilterAttribute.DYNAMIC_CTR_MAX],
            filterTooltip: t('Add filter for CTR'),
          },
          menuTitle: t('Max Click-through Rate'),
        }),
        width: MAX_COLUMN_WIDTH,
        cellRenderer: loadingWrapper(MaxCTRCell, {
          dependsOnSearchVolume: false,
          onlyGoogle: true,
        }),
      },

      {
        id: KeywordColumnID.ORGANIC_TRAFFIC,
        title: t('AI SoV'),
        combineRow: KeywordColumnID.ORGANIC_TRAFFIC_CHANGE,
        onHeaderCell: () => ({
          tooltip: t('AI Share of Voice'),
          ordering: keywordOrderConfig.organicTraffic,
          filter: {
            filterAttributes: [FilterAttribute.ORGANIC_TRAFFIC],
            filterTooltip: t('Add filter for AI SoV'),
          },
          reverseDirection: true,
          menuTitle: t('AI Share of Voice'),
        }),
        width: language === 'da' ? 96 : 64,
        cellRenderer: loadingWrapper(TrafficCell, {
          dependsOnSearchVolume: true,
        }),
      },
      {
        id: KeywordColumnID.ORGANIC_TRAFFIC_CHANGE,
        combineRow: KeywordColumnID.MAX_AI_SOV,
        title: t('+/-'),
        onHeaderCell: () => ({
          tooltip: t('Change in AI Share of Voice between the two compared dates'),
          ordering: keywordOrderConfig.organicTrafficChange,
          reverseDirection: true,
          filter: {
            filterAttributes: [FilterAttribute.ORGANIC_TRAFFIC_CHANGE],
            filterTooltip: t('Add filter for AI SoV'),
          },
          menuTitle: t('Change in AI Share of Voice'),
        }),
        width: 65,
        cellRenderer: loadingWrapper(TrafficChangeCell, {
          dependsOnSearchVolume: true,
          onlyGoogle: true,
          overlayOnly: true,
          emptyContent: true,
        }),
        cellRendererParams: {
          emptyLoader: true,
        },
      },
      {
        id: KeywordColumnID.MAX_AI_SOV,
        title: t('Max'),
        onHeaderCell: () => ({
          tooltip: t('Max AI Share of Voice'),
          ordering: keywordOrderConfig.maxAiSov,
          reverseDirection: true,
          filter: {
            filterAttributes: [FilterAttribute.MAX_AI_SOV],
            filterTooltip: t('Add filter for AI SoV'),
          },
          menuTitle: t('Max AI Share of Voice'),
        }),
        width: 65,
        cellRenderer: loadingWrapper(SimpleNumberCell, {
          dependsOnSearchVolume: true,
          overlayOnly: true,
        }),
        cellRendererParams: { colName: 'maxAiSov', nDecimals: 0 },
      },
      {
        id: KeywordColumnID.CPC,
        title: `${t('CPC')} (${displayCurrency})`,
        onHeaderCell: () => ({
          tooltip: t('Average Cost per Click'),
          ordering: keywordOrderConfig.cpc,
          filter: {
            filterAttributes: [FilterAttribute.CPC],
            filterTooltip: t('Add filter for average Cost per Click'),
          },
          reverseDirection: true,
          menuTitle: t('Average Cost per Click'),
        }),
        width: 96,
        cellRenderer: loadingWrapper(CPCCell, { dependsOnSearchVolume: true }),
      },
      {
        id: KeywordColumnID.AD_COMPETITION,
        title: t('Competition'),
        onHeaderCell: () => ({
          tooltip: t('Google Ads Competition'),
          ordering: keywordOrderConfig.adCompetition,
          filter: {
            filterAttributes: [FilterAttribute.AD_COMPETITION],
            filterTooltip: t('Filter Google Ads competition'),
          },
        }),
        width: language === 'da' ? 105 : 90,
        cellRenderer: loadingWrapper(CompetitionCell, { dependsOnSearchVolume: true }),
      },
      {
        id: KeywordColumnID.SHARE_OF_VOICE,
        title: t('SoV'),
        combineRow: KeywordColumnID.SHARE_OF_VOICE_CHANGE,
        onHeaderCell: () => ({
          tooltip: t('Share of Voice'),
          ordering: keywordOrderConfig.sov,
          filter: {
            filterAttributes: [FilterAttribute.SHARE_OF_VOICE],
            filterTooltip: t('Add filter for change in Share of Voice'),
          },
          reverseDirection: true,
        }),
        width: language === 'da' ? 105 : 70,
        cellRenderer: loadingWrapper(ShareOfVoiceCell, {
          dependsOnSearchVolume: true,
        }),
      },
      {
        id: KeywordColumnID.SHARE_OF_VOICE_CHANGE,
        combineRow: KeywordColumnID.MAX_SOV,
        title: t('+/-'),
        onHeaderCell: () => ({
          tooltip: t('Change in Share of Voice between the two compared dates'),
          ordering: keywordOrderConfig.sovChange,
          reverseDirection: true,
          filter: {
            filterAttributes: [FilterAttribute.SHARE_OF_VOICE_CHANGE],
            filterTooltip: t('Add filter for change in Share of Voice'),
          },
          menuTitle: t('Share of Voice change'),
        }),
        width: 70,
        cellRenderer: loadingWrapper(ShareOfVoiceChangeCell as any, {
          dependsOnSearchVolume: true,
          overlayOnly: true,
        }),
        cellRendererParams: {
          emptyLoader: true,
        },
      },
      {
        id: KeywordColumnID.MAX_SOV,
        title: t('Max'),
        onHeaderCell: () => ({
          tooltip: t('Max Share of Voice'),
          ordering: keywordOrderConfig.maxSov,
          reverseDirection: true,
          filter: {
            filterAttributes: [FilterAttribute.MAX_SOV],
            filterTooltip: t('Add filter for change in Share of Voice'),
          },
          menuTitle: t('Max Share of Voice'),
        }),
        width: MAX_COLUMN_WIDTH,
        cellRenderer: loadingWrapper(ShareOfVoiceCell, {
          dependsOnSearchVolume: true,
          overlayOnly: true,
        }),
        cellRendererParams: { isMaxSov: true },
      },
      {
        id: KeywordColumnID.SHARE_OF_VOICE_CPC,
        title: `${t('Traffic Value')} (${displayCurrency})`,
        width: language === 'da' ? 145 : 130,
        combineRow: KeywordColumnID.SHARE_OF_VOICE_CPC_CHANGE,
        onHeaderCell: () => ({
          tooltip: t('Traffic Value'),
          ordering: keywordOrderConfig.trafficValue,
          filter: {
            filterAttributes: [FilterAttribute.TRAFFIC_VALUE],
            filterTooltip: t('Add filter for Traffic Value'),
          },
          reverseDirection: true,
        }),
        cellRenderer: loadingWrapper(ShareOfVoiceCPCCell, {
          dependsOnSearchVolume: true,
        }),
      },
      {
        id: KeywordColumnID.SHARE_OF_VOICE_CPC_CHANGE,
        combineRow: KeywordColumnID.MAX_TRAFFIC_VALUE,
        title: t('+/-'),
        onHeaderCell: () => ({
          tooltip: t('Change in Traffic Value between the two compared dates'),
          ordering: keywordOrderConfig.trafficValueChange,
          reverseDirection: true,
          filter: {
            filterAttributes: [FilterAttribute.TRAFFIC_VALUE_CHANGE],
            filterTooltip: t('Add filter for Traffic Value'),
          },
          menuTitle: t('Traffic Value change'),
        }),
        width: 75,
        cellRenderer: loadingWrapper(ShareOfVoiceCPCChangeCell, {
          dependsOnSearchVolume: true,
          overlayOnly: true,
        }),
      },
      {
        id: KeywordColumnID.MAX_TRAFFIC_VALUE,
        title: t('Max'),
        onHeaderCell: () => ({
          tooltip: t('Max Traffic Value'),
          ordering: keywordOrderConfig.maxTrafficValue,
          reverseDirection: true,
          filter: {
            filterAttributes: [FilterAttribute.MAX_TRAFFIC_VALUE],
            filterTooltip: t('Add filter for Traffic Value'),
          },
          menuTitle: t('Max Traffic Value'),
        }),
        width: 65,
        cellRenderer: loadingWrapper(SimpleNumberCell, {
          dependsOnSearchVolume: true,
          overlayOnly: true,
        }),
        cellRendererParams: { colName: 'maxTrafficValue', nDecimals: 0 },
      },
      {
        id: KeywordColumnID.SERP,
        title: t('SERP Features'),
        onHeaderCell: () => ({
          tooltip: t('SERP Features'),
          filter: {
            filterAttributes: [
              FilterAttribute.PAGE_SERP_FEATURES,
              FilterAttribute.PAGE_SERP_FEATURES_OWNED,
              FilterAttribute.PAGE_SERP_FEATURES_RANK,
            ],
            hasLargeFilter: true,
            containerClassName: 'serp-features-modal',
            filterTooltip: t('Filter features on the SERP'),
          },
          ordering: keywordOrderConfig.serp,
        }),
        width: 144,
        cellRenderer: loadingWrapper(SerpCell),
        cellRendererParams: {
          serpFeatures: metaData?.serpFeatures,
          onClick: (
            id: string,
            _domainId: string,
            serp: string,
            serpFeaturesData?: SerpFeatureNode[],
            searchDate?: string,
          ) => {
            showKeywordModal({
              keywordId: id,
              domainId: _domainId,
              initialTab: MenuItemIds.TAB_SERP_FEATURES,
              selectedSerp: serp,
              serpFeaturesData,
              searchDate,
            });
          },
        },
      },
      {
        id: KeywordColumnID.DATE_ADDED,
        title: t('Added On'),
        onHeaderCell: () => ({
          tooltip: t('Added On'),
          filter: {
            filterAttributes: [FilterAttribute.DATE_ADDED],
            filterTooltip: t('Filter date when the keyword was added'),
          },
          ordering: keywordOrderConfig.dateAdded,
        }),
        width: language === 'da' ? 130 : 88,
        cellRenderer: DateAddedCell,
      },
      {
        id: KeywordColumnID.KEYWORD_TIME_AGO,
        title: t('Last Update'),
        onHeaderCell: () => ({
          tooltip: t('Last Update'),
          ordering: keywordOrderConfig.timeAgo,
        }),
        width: language === 'da' ? 120 : 104,
        cellRenderer: loadingWrapper(KeywordTimeAgoCell),
      },
      {
        id: KeywordColumnID.ACTIONS,
        title: '',
        width: userHasWriteAccess ? 76 : 42,
        fixed: 'right' as FixedType,
        cellRenderer: ActionsCell,
        cellRendererParams: {
          onRefreshKeyword: (id: any) =>
            updateKeywordsRefresh({
              keywords: [id],
              isDisabled: false, // todo
            }),
          isDemoDomain,
          hideRefresh: !userHasWriteAccess,
        },
      },
    ].sort(
      (a, b) =>
        keywordColumnOrdering.indexOf(a?.id as any) - keywordColumnOrdering.indexOf(b?.id as any),
    );
    if (domainId) {
      return [
        ...checkBoxColumns,
        ...filterByDomainType(keywordTableColumns, domainType),
      ] as ColumnType[];
    }
    return keywordTableColumns as ColumnType[];
  }, [isLatestFilter, displayCurrency, domainId]);

  const tableLabels: TableLabels = useMemo(
    () => ({
      itemsUpdate: isKeyDis
        ? () => t('Keywords have recently been imported so this list might be outdated.')
        : undefined,
      cellLoadingTooltip: t('Refreshing rank…'),
      paginationLoading: t('Loading keywords…'),
      paginationLoadingWithDetails: ({ from, to, total }) =>
        tct('Loading keyword [from] to [to] of [total] keywords', {
          total,
          from,
          to,
        }),
      paginationDetails: ({ from, to, total }) =>
        tct('Keyword [from] to [to] of [total] keywords', {
          total,
          from,
          to,
        }),
    }),
    [isKeyDis],
  );

  return useMemo(
    () => ({
      columns,
      labels: tableLabels,
    }),
    [columns],
  );
};
