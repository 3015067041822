import { useEffect, useMemo, useState } from 'react';
import { useDebouncedValue } from '@mantine/hooks';
import { FilterInput, useFilterCountQuery } from 'Ghql';
import { useFilters } from 'Hooks';
import { DOMAINS, FilterComparison } from 'Types/Filter';
import getIsKeyDis from 'Utilities/getKeyDis';
import { getRegexError } from 'Utilities/validation';

const DELAY = 150; //ms

const useFilterCount = (filter: FilterInput) => {
  const isKeydis = getIsKeyDis();
  const isGscImport = window.location.href.includes('/import/gsc');

  const filters = useFilters();
  const [count, setCount] = useState<number | null>(null);
  const { value, comparison, type } = filter;

  const debouncedVariables = useMemo(
    () => ({
      debouncedValue: value,
      debouncedComparison: comparison,
      debouncedType: type,
    }),
    [value, comparison, type],
  );

  // Debounce the filter values to avoid unresponsive UI - debounce together to avoid multiple requests
  const [{ debouncedValue, debouncedComparison, debouncedType }] = useDebouncedValue(
    debouncedVariables,
    DELAY,
  );

  let invalidRegex = false;
  if (
    debouncedComparison === FilterComparison.REGEX ||
    debouncedComparison === FilterComparison.NOT_REGEX
  ) {
    if (getRegexError(debouncedValue)) {
      invalidRegex = true;
    }
  }

  // On keyword research, the filter count does not work in general.. It is because "country" is not set as a filter
  // but is state instead on the keyword research page. We capture this, and more general filter issues by checking
  // if a domains filter is present (which it is not on keyword research in general).
  const doesNotHaveDomainsFilter = filters.filter((f) => f.attribute === DOMAINS).length === 0;

  const skip = isGscImport || doesNotHaveDomainsFilter || invalidRegex;

  // Store the initial state of the filter
  const [{ initialValue, initialComparison }] = useState({
    initialValue: filter.value,
    initialComparison: filter.comparison,
  });

  // Avoid both the stored filter and the filter being edited in redux-form being included in the filter count query (i.e. when editing in the filter bar)
  const filtersBesidesCurrent = filters.filter(
    (f) =>
      !(
        f.attribute === filter.attribute &&
        f.value === initialValue &&
        f.comparison === initialComparison
      ),
  );

  const { data } = useFilterCountQuery({
    variables: {
      filters:
        debouncedValue === ''
          ? filters
          : [
              ...filtersBesidesCurrent,
              {
                value: debouncedValue,
                comparison: debouncedComparison,
                attribute: filter.attribute,
                type: debouncedType,
              },
            ],
      isKeydis,
    },
    skip,
  });

  // The useEffect might seem redundant, but it is there such that
  // when data is fetching, count is not shown as undefined
  // but as previous state value.
  useEffect(() => {
    if (data) {
      setCount(data?.filterData?.filterCount ?? null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.filterData?.filterCount]);
  return invalidRegex ? 0 : count;
};

export default useFilterCount;
