import { Grid } from '@mantine/core';
import AccTitle from 'Components/Title/AccTitle';
import { useHasAccuApiAccess } from 'Hooks/data/user/useHasAccuApiAccess';
import { useUser } from 'Hooks/data/user/useUser';
import useUserPermission from 'Hooks/useUserPermission';
import { InvoicesTable } from 'Pages/Invoices/InvoicesTable';
import * as Actions from 'Pages/Layout/ActionsMenu/Actions';
import ActionbarContainer from 'Pages/Layout/ActionsMenu/components/ActionbarContainer';
import { t } from 'Utilities/i18n';
import PaymentContact from './PaymentContact';
import PaymentMethod from './PaymentMethod';
import Plan from './Plan';
import './billing-overview.scss';

const BillingOverview = () => {
  const user = useUser();
  const { isAdmin } = useUserPermission();
  const active = !!user?.organization?.active;

  const ActionButtonsPanel = () => {
    const hasAccuApiAccess = useHasAccuApiAccess();
    if (hasAccuApiAccess) return null;
    return (
      <Actions.AddAction
        link="/billing/package/select"
        label={active ? t('Change plan') : t('Reactivate')}
        disabled={!isAdmin}
        showTooltip={!isAdmin}
        tooltip={
          active
            ? t('Only admin users can change the plan')
            : t('Only admin users can reactivate the plan')
        }
      />
    );
  };

  return (
    <>
      <ActionbarContainer mt="md">
        <ActionButtonsPanel />
      </ActionbarContainer>
      <div className="billing-overview">
        <Grid gutter={50} className="billing-grid">
          <Grid.Col span={{ xs: 12, md: 4 }}>
            <AccTitle type="h4">{t('Plan Info')}</AccTitle>
            <Plan isActive={active} isOrgAdmin={isAdmin} />
          </Grid.Col>
          <Grid.Col span={{ xs: 12, md: 4 }}>
            <AccTitle type="h4">{t('Billing Info')}</AccTitle>
            <PaymentContact isOrgAdmin={isAdmin} />
          </Grid.Col>
          <Grid.Col span={{ xs: 12, md: 4 }}>
            <AccTitle type="h4">{t('Payment Method')}</AccTitle>
            <PaymentMethod isOrgAdmin={isAdmin} />
          </Grid.Col>
        </Grid>
      </div>
      <AccTitle type="h3" mt="lg" mb="xxs">
        {t('Invoices')}
      </AccTitle>
      <div className="billing-invoices-table">
        <InvoicesTable organizationId={user?.organization?.id} />
      </div>
    </>
  );
};

export default BillingOverview;
