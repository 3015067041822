import { useCallback } from 'react';
import linkWithFilters from 'Components/Filters/linkWithFilters';
import { useGroupsTableUpdateClientMutation } from 'Ghql';
import { useModal } from 'Hooks/base/useModal';
import toast from 'Hooks/useToast';
import { useOrganizationInfo } from 'Selectors/OrganizationSelector';
import {
  ClientsFilter,
  DomainsFilter,
  FilterAttribute,
  FilterComparison,
  FilterValueType,
} from 'Types/Filter';
import { DOMAINS_FILTER_SET, KEYWORDS_FILTER_SET } from 'Types/FilterSet';
import { t } from 'Utilities/i18n';

type Props = {
  updateTable: () => void;
};
export const useGroupHandlers = (props: Props) => {
  const { updateTable } = props;

  const { organization } = useOrganizationInfo();

  const { showModal } = useModal();

  const [deleteClient] = useGroupsTableUpdateClientMutation({
    onCompleted(result) {
      const errors = result.updateClient?.errors;
      if (errors && errors.length) {
        toast.error(t('Could not delete group'));
      } else {
        toast.success(t('Group deleted'));
        updateTable();
      }
    },
  });

  const makeDomainsLink = useCallback((clientId: string) => {
    const clientsFilter: ClientsFilter = {
      attribute: FilterAttribute.CLIENTS,
      type: FilterValueType.LIST,
      comparison: FilterComparison.CONTAINS,
      value: [clientId],
    };
    return linkWithFilters({
      to: '/domains',
      overwriteFilters: [clientsFilter],
      filterSet: DOMAINS_FILTER_SET,
    });
  }, []);

  const makeOverviewLink = useCallback((group: any) => {
    if (!group.domainIds || group.domainIds.length === 0 || group.numberOfDomains === 0) return '';
    const domainsFilter: DomainsFilter = {
      attribute: FilterAttribute.DOMAINS,
      type: FilterValueType.LIST,
      comparison: FilterComparison.CONTAINS,
      value: group.domainIds,
    };
    return linkWithFilters({
      to: '/keywords/overview',
      overwriteFilters: [domainsFilter],
      filterSet: KEYWORDS_FILTER_SET,
      resetFilters: true,
    });
  }, []);

  const handleEditGroup = useCallback(
    (group) => {
      showModal({
        modalType: 'EditGroup',
        modalTheme: 'light',
        modalProps: {
          groupId: group.id,
          initialValues: {
            groupName: group.name,
          },
          refetch: updateTable,
        },
      });
    },
    [showModal, updateTable],
  );

  const handleDelete = useCallback(
    (group) => {
      const { name, id } = group;
      showModal({
        modalType: 'Confirmation',
        modalProps: {
          title: t('Delete Group?'),
          description: t(
            'The group %s and all the domains within it will be permanently deleted.',
            name,
          ),
          confirmLabel: t('Delete group'),
          action: () => {
            deleteClient({
              variables: {
                input: {
                  id,
                  name,
                  organization: organization?.id || '',
                  delete: true,
                },
              },
            });
          },
        },
      });
    },
    [deleteClient, showModal],
  );

  return { makeDomainsLink, makeOverviewLink, handleEditGroup, handleDelete };
};
