import { ApolloCache, useApolloClient } from '@apollo/client';
import { KeywordsQueryName } from 'Pages/Keywords/Table/support/constants';

// Use queries from the Keywords Table as the default queries for cache cleaning.
const queriesAffectedByKeywordUpdate = Object.values(KeywordsQueryName);

export const invalidateCache = (
  cache: ApolloCache<unknown>,
  query: string | string[] = queriesAffectedByKeywordUpdate,
) => {
  const queryNames = Array.isArray(query) ? query : [query];

  queryNames.map((queryName) => {
    cache.evict({ id: 'ROOT_QUERY', fieldName: queryName });
  });

  cache.gc();
};

export const useInvalidateKeywordCache = () => {
  const client = useApolloClient();
  return () => invalidateCache(client.cache, queriesAffectedByKeywordUpdate);
};
