import { Flex } from '@mantine/core';
import AccButton from 'Components/AccButton/AccButton';
import ModalBorder from 'Components/Modal/Layout/ModalBorder';
import { ModalFooter } from 'Components/Modal/Layout/ModalFooter';
import AccText from 'Components/Text/AccText';
import { useModal } from 'Hooks/base/useModal';
import { t, tct } from 'Utilities/i18n';
import style from './select-tag-type.module.scss';

type Props = {
  folderId?: string;
};
const SelectTagTypeModal = (props: Props) => {
  const { folderId } = props;

  const { hideModal, showModal } = useModal();

  const handleCreateTag = (modalType: 'CreateOrEditDynamicTag' | 'CreateOrEditStaticTag') => {
    showModal({
      modalType,
      modalTheme: 'light',
      modalProps: {
        initialValues: { folder: folderId },
      },
    });
  };

  return (
    <ModalBorder className={style.modal} title={t('Create Tag')} onClose={hideModal}>
      <Flex direction="column">
        <AccText ta="center">
          {tct('What kind of tag would you like to create?', {
            break: <br />,
          })}
        </AccText>

        <Flex justify="center" align="center" my={12} gap={12}>
          <AccButton variant="primary" onClick={() => handleCreateTag('CreateOrEditDynamicTag')}>
            {t('Dynamic tag')}
          </AccButton>
          <AccButton variant="secondary" onClick={() => handleCreateTag('CreateOrEditStaticTag')}>
            {t('Static tag')}
          </AccButton>
        </Flex>
      </Flex>
      <ModalFooter
        primaryButtonSlot={<></>}
        textSlot={
          <AccText variant="label">
            {tct(
              'Learn how you can use [linkDynamicTag:dynamic tags] and [linkStaticTag:static tags].',
              {
                linkStaticTag: (
                  <a
                    className={style.link}
                    href="https://www.accuranker.com/help/keywords/tagging-keywords/"
                    target="_blank"
                    rel="noreferrer"
                  />
                ),
                linkDynamicTag: (
                  <a
                    className={style.link}
                    href="https://www.accuranker.com/help/keywords/dynamic-tagging-rules/"
                    target="_blank"
                    rel="noreferrer"
                  />
                ),
              },
            )}
          </AccText>
        }
      />
    </ModalBorder>
  );
};

export default SelectTagTypeModal;
