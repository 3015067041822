import React from 'react';
import { Divider, Flex } from '@mantine/core';
import compose from 'lodash/flowRight';
import isEqual from 'lodash/isEqual';
import { observer } from 'mobx-react';
import AccessControl from 'Components/AccessControl';
import { useTableStore } from 'Components/DataTable';
import HelpGuideButton from 'Components/HelpGuideButton';
import { getDisabledDemoText } from 'Constants/messages';
import { useDisplayCurrency } from 'Hooks/displayCurrency';
import { useFilterAndOrderByWithVariables } from 'Hooks/useFilterAndOrderByWithVariables';
import useKeyword from 'Hooks/useKeyword';
import useUserPermission from 'Hooks/useUserPermission';
import * as Actions from 'Pages/Layout/ActionsMenu/Actions';
import { DomainInfo, DomainTypeChoices } from 'Query';
import { FilterBase } from 'Types/Filter';
import { TableID } from 'Types/Table';
import { EventName, useMixpanel } from 'Utilities/Analytics/mixpanel';
import { t, tct } from 'Utilities/i18n';
import MoreActions from './MoreActions/new';
import MoreRemoveActions from './MoreRemoveActions/new';
import {
  WithKeywordActionMenuDataProps,
  withKeywordActionMenuData,
} from './withKeywordActionMenuData';
import styles from './new-actions.module.scss';

interface KeywordActionMenuProps {
  tableName: TableID;
  domainInfo?: DomainInfo;
  filters: FilterBase[];
}

const ActionMenuList = ({
  tableName,
  manualSelectedKeywords,
  isAllSelected,
  totalResults,
  domainId,
  filters,
  actions,
  totalSelected,
  showCompare,
  domainInfo,
}: KeywordActionMenuProps & WithKeywordActionMenuDataProps) => {
  const tableStore = useTableStore(tableName);
  const filterAndOrderByWithVariables = useFilterAndOrderByWithVariables(tableName);
  const {
    updateKeywordsStarred,
    updateKeywordsRefresh,
    showOneTimeReportModal,
    showAddKeywordsModal,
    showLandingPageModal,
    showRemoveTagsModal,
    showDeleteKeywordsModal,
    showAddNoteModal,
    showAddTagsModal,
    showEditKeywordsModal,
    showMoveKeywordsModal,
    showCopyToClipboardModal,
    showDuplicateModal,
    showCompareModal,
  } = useKeyword({ showModal: actions.showModal });
  const trackEvent = useMixpanel();
  const { displayCurrency } = useDisplayCurrency(true);

  const { isDemoDomain } = domainInfo || {};
  const { userHasWriteAccess } = useUserPermission();

  const isRefreshDisabled = domainInfo?.domainType === DomainTypeChoices.A_7; // Disabled for Naver
  const showActions =
    (manualSelectedKeywords.length > 0 || isAllSelected) && !isDemoDomain && userHasWriteAccess;

  const tableSettingAction = (
    <Actions.SettingsAction
      key="settings"
      className={styles.tableSettingsKeywords}
      onClick={() =>
        actions.showModal({
          modalType: 'TableSettingsModal',
          modalTheme: 'light',
          modalProps: {
            tableName: 'keywords',
          },
        })
      }
    />
  );

  return (
    <>
      {domainId ? (
        <>
          <AccessControl>
            <Actions.AddAction
              disabled={isDemoDomain}
              tooltip={isDemoDomain ? getDisabledDemoText() : undefined}
              key="add"
              label={t('Add keywords')}
              onClick={() => {
                showAddKeywordsModal({
                  domainId,
                });
              }}
            />
          </AccessControl>
          <Actions.ReportAction key="report" onClick={() => showOneTimeReportModal({ domainId })} />
          <HelpGuideButton
            helpguideLink={'https://www.accuranker.com/help/keywords/the-keyword-table/'}
          />
          <Flex columnGap="xs">
            {showActions || !!showCompare ? (
              <Divider h={26} m="auto" orientation="vertical" color="gray.2" />
            ) : null}
            {showActions ? (
              <>
                <MoreActions
                  totalSelected={totalSelected}
                  selectedKeywords={manualSelectedKeywords}
                  isAllSelected={isAllSelected}
                  domainId={domainId}
                  totalResults={totalResults}
                  updateKeywordsRefresh={
                    isRefreshDisabled
                      ? undefined
                      : () => {
                          tableStore?.updateSelectedItems((data) => {
                            data.updatingKeyword = true;
                          });
                          updateKeywordsRefresh({
                            keywords: manualSelectedKeywords,
                            isAllSelected,
                            isDisabled: false,
                            filters,
                          });
                          trackEvent(EventName.RefreshKeywordSingle, {
                            numberOfKeywords: manualSelectedKeywords?.length ?? 0,
                            source: 'MoreActions',
                          });
                        }
                  }
                  showAddNoteModal={() =>
                    showAddNoteModal({
                      keywords: manualSelectedKeywords,
                      isAllSelected,
                      domainId,
                      filters,
                    })
                  }
                  showAddTagsModal={() =>
                    showAddTagsModal({
                      keywords: manualSelectedKeywords,
                      isAllSelected,
                      filters,
                    })
                  }
                  updateKeywordsStarred={
                    (() => {
                      tableStore?.updateSelectedItems((data) => {
                        data.starred = true;
                      });
                      updateKeywordsStarred({
                        keywords: manualSelectedKeywords,
                        isAllSelected,
                        filters,
                        domainId,
                        starred: true,
                      } as any);
                    }) as any
                  }
                  showEditKeywordsModal={() =>
                    showEditKeywordsModal({
                      keywords: manualSelectedKeywords,
                      isAllSelected,
                      filters,
                      domainId,
                      numResults: totalResults,
                    } as any)
                  }
                  showLandingPageModal={() =>
                    showLandingPageModal({
                      keywords: manualSelectedKeywords,
                      isAllSelected,
                      filters,
                      domainId,
                    } as any)
                  }
                  showDuplicateModal={() =>
                    showDuplicateModal({
                      keywords: manualSelectedKeywords,
                      isAllSelected,
                      filters,
                      domainId,
                      numResults: totalResults,
                    })
                  }
                  showMoveKeywordsModal={() =>
                    showMoveKeywordsModal({
                      keywords: manualSelectedKeywords,
                      isAllSelected,
                      filters,
                      domainId,
                    })
                  }
                  showCopyToClipboardModal={() =>
                    showCopyToClipboardModal({
                      tableStore,
                      filterAndOrderByWithVariables,
                      filters,
                      displayCurrency,
                    })
                  }
                />
                <MoreRemoveActions
                  selectedKeywords={manualSelectedKeywords}
                  isAllSelected={isAllSelected}
                  totalResults={totalResults}
                  showRemoveTagsModal={() =>
                    showRemoveTagsModal({
                      keywords: manualSelectedKeywords,
                      isAllSelected,
                      filters,
                      domainId,
                    })
                  }
                  showDeleteKeywordsModal={() => {
                    showDeleteKeywordsModal({
                      keywords: manualSelectedKeywords,
                      isAllSelected,
                      filters,
                      onAction: () => {
                        tableStore?.deleteSelectedItems();
                      },
                    } as any);
                  }}
                  updateKeywordsStarred={
                    (() => {
                      tableStore?.updateSelectedItems((data) => {
                        data.starred = false;
                        // version used to update state of components that don't update store and post only to backend
                        data.version = (data.version || 0) + 1;
                      });
                      updateKeywordsStarred({
                        keywords: manualSelectedKeywords,
                        isAllSelected,
                        filters,
                        domainId,
                        starred: false,
                      } as any);
                    }) as any
                  }
                />
              </>
            ) : null}

            {showCompare ? (
              <Actions.CompareAction
                key="compare"
                label={tct('Compare [amount]', { amount: `(${totalSelected})` })}
                onClick={() =>
                  showCompareModal({
                    keywords: manualSelectedKeywords,
                  })
                }
              />
            ) : null}
          </Flex>
          {tableSettingAction}
        </>
      ) : (
        <>
          <Actions.ReportAction key="report" onClick={() => showOneTimeReportModal({ domainId })} />
          {tableSettingAction}
        </>
      )}
    </>
  );
};

export default compose(observer, withKeywordActionMenuData)(React.memo(ActionMenuList, isEqual));
