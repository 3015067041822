import React, { ReactNode, SetStateAction, useCallback, useEffect, useState } from 'react';
import {
  Checkbox,
  CloseButton,
  Combobox,
  ComboboxStore,
  Flex,
  Group,
  Loader,
  MantineStyleProps,
  MultiSelectProps,
  Pill,
  PillsInput,
  ScrollArea,
  useCombobox,
} from '@mantine/core';
import cn from 'classnames';
import debounce from 'lodash/debounce';
import isArray from 'lodash/isArray';
import { DelayedSpinner } from 'Components/AccSelect/components/DelayedSpinner';
import { t } from 'Utilities/i18n';
import styleVariables from 'css/base/variables.module.scss';
import { MAX_SELECT_DROPDOWN_HEIGHT } from './support/constants';
import { defaultSelectCreatePrompt } from './support/helpers';
import { useSelectNotFound } from './support/hooks';
import { SelectItem, SelectStylesVariations } from './support/types';
import styles from './select.module.scss';

type ValueComponentType = (
  props: Partial<SelectItem<string> & { onRemove: (value: string) => void }>,
) => JSX.Element;
type StackComponentType = (
  props: Partial<{ moreValue: number; moreLabel: ReactNode }>,
) => JSX.Element;

const ComboboxOptions = ({
  comboboxOptionsData,
  selectedOptions,
  optionComponent,
  hidePickedOptions,
}: {
  comboboxOptionsData: SelectItem<string>[];
  selectedOptions: SelectItem<string>[];
  optionComponent?: (props: SelectItem<string> & { isSelected?: boolean }) => React.ReactNode;
  hidePickedOptions?: boolean;
}) => {
  return (
    <>
      {comboboxOptionsData.map((dataOption) => {
        const isSelected = selectedOptions?.includes(dataOption);

        return (
          <Combobox.Option
            {...dataOption}
            key={dataOption.value}
            selected={isSelected}
            disabled={dataOption?.disabled}
          >
            {optionComponent ? (
              optionComponent({ ...dataOption, isSelected })
            ) : (
              <Group gap="sm" wrap="nowrap">
                {isSelected ? (
                  <Checkbox checked color={'snorlax.3'} readOnly />
                ) : hidePickedOptions ? null : (
                  <Checkbox checked={false} color={'snorlax'} readOnly />
                )}
                <span>{dataOption.label}</span>
              </Group>
            )}
          </Combobox.Option>
        );
      })}
    </>
  );
};

const PillValues = ({
  selectedValues,
  valueComponent: ValueComponent,
  stackComponent: StackComponent,
  handleValueRemove,
  maxDisplayedValues,
}: {
  selectedValues: SelectItem<string>[];
  valueComponent?: ValueComponentType;
  stackComponent?: StackComponentType;
  handleValueRemove: (val: string) => void;
  maxDisplayedValues: number;
}) => {
  const PillComp = ValueComponent ? ValueComponent : Pill;
  const PillStack = StackComponent ? StackComponent : PillComp;
  const moreItemsValue = selectedValues.length - (maxDisplayedValues - 1);
  const moreItemsLabel = t(`+${selectedValues.length - (maxDisplayedValues - 1)} more`);
  const values = selectedValues.map((option) => option.value);
  return (
    <>
      {selectedValues
        .slice(
          0,
          maxDisplayedValues === selectedValues.length
            ? maxDisplayedValues
            : maxDisplayedValues - 1,
        )
        .map((option) => (
          <PillComp
            size="sm"
            key={option.value}
            withRemoveButton
            onRemove={() => handleValueRemove(option.value)}
            classNames={
              !ValueComponent
                ? { root: styles.pillRoot, label: styles.pillOuterLabel, remove: styles.pillRemove }
                : undefined
            }
            {...option}
            values={values}
          >
            <span className={styles.pillLabel}>{option.label}</span>
          </PillComp>
        ))}
      {selectedValues.length > maxDisplayedValues && (
        <PillStack
          size="sm"
          classNames={
            !ValueComponent ? { root: styles.pillRoot, label: styles.pillLabel } : undefined
          }
          moreValue={moreItemsValue}
          moreLabel={moreItemsLabel}
        >
          {moreItemsLabel}
        </PillStack>
      )}
    </>
  );
};

const RightSectionElement = ({
  clearable,
  value,
  setValue,
  setData,
  onChange,
}: {
  clearable: boolean;
  value: string[];
  setValue: (value: React.SetStateAction<string[]>) => void;
  setData: (value: SetStateAction<SelectItem<string>[]>) => void;
  onChange: (value: string[]) => void;
}) => {
  if (clearable && value.length) {
    return (
      <CloseButton
        size="sm"
        onMouseDown={(event) => {
          //make sure the bubbling phase is stopped to not conflict with the PillsInput close dropdown event
          event.preventDefault();
          event.stopPropagation();
        }}
        onClick={() => {
          setValue(() => {
            onChange([]);
            return [];
          });
          setData((data) => data.filter((dataOption) => !dataOption.created));
        }}
        aria-label={t('Clear value')}
        classNames={{ root: styles.closeButtonRoot }}
      />
    );
  }
  return <Combobox.Chevron />;
};

type AccMultiSelectProps = Pick<
  MultiSelectProps,
  'placeholder' | 'autoFocus' | 'disabled' | 'error' | 'limit' | 'hidePickedOptions'
> &
  MantineStyleProps & {
    options?: SelectItem<string>[];
    value?: string[] | string | null;
    onChange: (value: string[]) => void;
    creatable?: boolean;
    isLoading?: boolean;
    /**
     * Callback that return options, being executed on search change (debounced)
     * Handle loading state by yourself
     * @example
     * ```tsx
     * const loadOptions = () => {
     *   apolloClient.query({ query: GET_SOME_OPTIONS, variables: { search } })
     *   .then(({data}) => data.users.map(user => ({ value: user.id, label: user.name })))
     * }
     * <AccSelect loadOptions={loadOptions} ... />
     * ```
     */
    loadOptions?: (search?: string) => Promise<SelectItem<string>[]>;

    /**
     * Placeholder text displayed during loading
     */
    loadingPlaceholderText?: string;
    /** render custom component for each selectItem in the dropdown */
    optionComponent?: (props: SelectItem<string> & { isSelected?: boolean }) => JSX.Element;
    /** Render custom component for each selected tag in the input field */
    valueComponent?: ValueComponentType;
    stackComponent?: StackComponentType;
    clearable?: boolean;
    noResultsText?: string;
    showError?: boolean;
    promptTextCreator?: (query: string) => string;
    /**
     * Fetch options even though search is empty
     */
    showResultsForEmptySearch?: boolean;
    searchable?: boolean;
    dropdownHeight?: number;
    inputMinWidth?: number;
    inputMaxWidth?: number;
    searchMinWidth?: number;
    /** Maximum number of selected tags to be displayed simultaneously.
     *
     * If more tags are selected, a `+x more` tag is displayed.*/
    maxDisplayedValues?: number;
    withinPortal?: boolean;
    dropdownWidth?: number;

    /**
     * Items can be removed by pressing backspace
     */
    removeOnBackspace?: boolean;
  };

const checkArrayOnlyValue = (val: string | string[] | null) => (isArray(val) ? val : []);

/**
 * Multi select component with support for async options loading and custom option rendering.
 * If you need single select support, please use `AccSelect` component.
 *
 * @documentation https://mantine.dev/core/multi-select/
 * @example
 * ```tsx
 * const [value, setValue] = useState<string[] | null>([]]);
 * return <AccSelect value={value} onChange={setValue} options={[{label: "Option 1", value: 1}, {label: "Option 2", value: 2},]} />;
 * ```
 */
const AccMultiSelect = ({
  clearable = true,
  creatable = true,
  value: externalValue = [],
  showError,
  isLoading: externalIsLoading,
  options = [],
  // Performance is not great for large lists, so we limit it to 200 and make it searchable
  limit = 200,
  bg,
  size = 'default',
  promptTextCreator,
  noResultsText,
  inputMaxWidth,
  inputMinWidth,
  searchMinWidth,
  showResultsForEmptySearch,
  borderRadius = 'sm',
  searchable = true,
  loadOptions,
  optionComponent,
  onChange,
  valueComponent,
  stackComponent,
  dropdownHeight = MAX_SELECT_DROPDOWN_HEIGHT,
  hidePickedOptions = true,
  error,
  disabled,
  autoFocus,
  placeholder,
  loadingPlaceholderText,
  maxDisplayedValues = 20,
  withinPortal = false,
  dropdownWidth,
  removeOnBackspace = true,
  ...props
}: AccMultiSelectProps & SelectStylesVariations) => {
  const [search, setSearch] = useState('');
  const [data, setData] = useState<SelectItem<string>[]>(loadOptions ? [] : options);
  const [firstLoad, setFirstLoad] = useState(true);
  //make sure we do not accidentally pass a single string or numbers as value
  const initialValue = checkArrayOnlyValue(externalValue).map((val) => val.toString());
  //array of selected values used for displaying pills
  const [value, setValue] = useState(initialValue);

  // Sync initial value - sometimes e.g. in forms this might be provided after first render of this component
  useEffect(() => {
    setValue(initialValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(initialValue)]);

  //Check if searchValue is identical to one of the elements
  const exactOptionMatch = data.some(
    (item) =>
      item.label?.toLowerCase() === search?.toLowerCase() ||
      item.value?.toLowerCase() === search?.toLowerCase(),
  );

  const [loading, setLoading] = useState(false);
  const isLoading = loading || externalIsLoading;
  const selectedOptions = data.filter((dataOption) => value?.includes(dataOption.value));
  //make sure we can display tags not included in the options array
  const selectedValues = value.map((valueString) => {
    const isIncludedInData = data.find((dataOption) => dataOption.value === valueString);
    return isIncludedInData ? isIncludedInData : { value: valueString, label: valueString };
  });

  const handleLoadOptions = (combobox: ComboboxStore, searchString: string) => {
    const isValidSearch = !!searchString || showResultsForEmptySearch;
    if ((loadOptions && !isLoading && isValidSearch) || (loadOptions && firstLoad)) {
      setFirstLoad(false);
      setLoading(true);

      loadOptions(searchString).then((response) => {
        //remove dublicates of selected values if they already exist in the response
        const newOptions = response.filter((responseOption) =>
          selectedOptions.every((selectedValue) => selectedValue.value !== responseOption.value),
        );
        setData([...selectedOptions, ...newOptions]);
        setLoading(false);
        combobox.resetSelectedOption();
      });
    }
  };

  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
    onDropdownOpen: () => {
      if (loadOptions) {
        if (data.length === 0) {
          handleLoadOptions(combobox, search);
        }
      } else {
        combobox.updateSelectedOptionIndex('active');
      }
    },
  });

  //sync data when options change from the outside
  useEffect(() => {
    //initial load of options
    firstLoad && showResultsForEmptySearch && handleLoadOptions(combobox, search);
    if (!options.length) return;
    const newOptions = options.filter((newOption) =>
      selectedOptions.every((selectedValue) => selectedValue.value !== newOption.value),
    );

    setData([...selectedOptions, ...newOptions]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(options)]);

  /**Remove option from Data array */
  const removeDataOption = (val: string) => {
    //if the item was previously created, remove it from the data array
    if (creatable && data.find((dataOption) => dataOption.value === val)?.created) {
      setData((current) => current.filter((dataOption) => dataOption.value !== val));
    }
    onChange && onChange(value.filter((v) => v !== val));
  };

  const shouldHidePickedOptions = maxDisplayedValues < value.length ? false : hidePickedOptions;

  /** select or deselect values */
  const handleOptionSubmit = (val: string) => {
    if (shouldHidePickedOptions) {
      combobox.dropdownOpened && combobox.closeDropdown();
    }
    if (val === '$create') {
      setData((current) => [...current, { value: search, label: search, created: true }]);
      setValue((current) => {
        const updatedValues = [...current, search];
        onChange && onChange(updatedValues);
        return updatedValues;
      });
      setSearch('');
      return;
    }
    let isRemovingValue = false;
    setValue((current) => {
      isRemovingValue = current.includes(val);
      const updatedValues = isRemovingValue ? current.filter((v) => v !== val) : [...current, val];
      onChange && onChange(updatedValues);
      return updatedValues;
    });
    setSearch('');
    if (isRemovingValue) {
      removeDataOption(val);
    }
  };

  const handleValueRemove = (val: string) => {
    setValue((current) => {
      const updatedValues = current.filter((v) => v !== val);
      onChange && onChange(updatedValues);
      return updatedValues;
    });
    //if the item was previously created, remove it from the data array
    removeDataOption(val);
  };
  // Always show the selected values in dropdown if there are more than the maxDisplayedValues

  //filter items if searchable
  const filterMethod = (item: SelectItem) => {
    const shouldBeVisible = shouldHidePickedOptions ? !value.includes(item.value.toString()) : true;
    if (searchable && search?.length) {
      return (
        (item.label?.toLowerCase().includes(search?.trim().toLowerCase()) && shouldBeVisible) ||
        (item.value?.toString().toLowerCase().includes(search?.trim().toLowerCase()) &&
          shouldBeVisible)
      );
    }

    return shouldBeVisible;
  };

  const comboboxOptionsData = data.filter(filterMethod).slice(0, limit);

  const nothingFound = useSelectNotFound({
    search,
    isLoading,
    nothingFoundText: noResultsText,
  });

  const ComboboxOptionsList = () => {
    if (isLoading) {
      return (
        <Flex justify="center" align="center" mih={35}>
          <DelayedSpinner />
        </Flex>
      );
    }
    const showEmptyState =
      (!comboboxOptionsData.length && !creatable) ||
      (creatable && !comboboxOptionsData.length && search.trim().length === 0) ||
      //show empty state if no results and the search value is identical to one of the tags
      (creatable && !comboboxOptionsData.length && exactOptionMatch && search.trim().length > 0);

    return (
      <>
        <ComboboxOptions
          comboboxOptionsData={comboboxOptionsData}
          optionComponent={optionComponent}
          selectedOptions={selectedOptions}
          hidePickedOptions={shouldHidePickedOptions}
        />
        {creatable && !exactOptionMatch && search.trim().length > 0 && (
          <Combobox.Option value="$create">
            {promptTextCreator
              ? promptTextCreator(search || '')
              : defaultSelectCreatePrompt(search)}
          </Combobox.Option>
        )}
        {showEmptyState && <Combobox.Empty>{nothingFound}</Combobox.Empty>}
      </>
    );
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedLoadOptions = useCallback(
    debounce((cmbbox: ComboboxStore, searchString: string) => {
      handleLoadOptions(cmbbox, searchString);
    }, 200),
    [handleLoadOptions],
  );

  return (
    <Combobox
      shadow={'md'}
      store={combobox}
      onOptionSubmit={handleOptionSubmit}
      withinPortal={withinPortal}
      width={dropdownWidth}
      classNames={{
        dropdown: styles.comboboxDropdown,
        option: styles.comboboxOption,
        options: styles.comboboxOptions,
        empty: styles.comboboxEmpty,
      }}
      data-background-gray={bg === 'gray' || undefined}
      data-background-snorlax={bg === 'snorlax' || undefined}
    >
      <Combobox.DropdownTarget>
        <PillsInput
          {...props}
          role="combobox"
          onClick={() => !combobox.dropdownOpened && combobox.openDropdown()}
          rightSection={
            isLoading ? (
              <Loader size={15} />
            ) : (
              <RightSectionElement
                clearable={clearable}
                value={value}
                setValue={setValue}
                onChange={onChange}
                setData={setData}
              />
            )
          }
          error={showError && error}
          withErrorStyles={showError}
          rightSectionPointerEvents={clearable && !isLoading ? 'auto' : 'none'}
          radius={borderRadius === 'none' ? 0 : borderRadius}
          multiline={false}
          disabled={disabled}
          style={{
            ...(inputMaxWidth ? { maxWidth: `${inputMaxWidth}px` } : {}),
            ...(inputMinWidth ? { minWidth: `${inputMinWidth}px` } : {}),
          }}
          classNames={{
            section: styles.inputSection,
            input: cn(styles.input, styles.inputMultiSelect),
            root: styles.inputRoot,
            error: styles.error,
            wrapper: size === 'default' ? styles.inputWrapperDefault : undefined,
          }}
          size={size === 'default' ? styleVariables.inputFieldHeight : size}
          wrapperProps={{
            'data-background-gray': bg === 'gray' || undefined,
            'data-background-snorlax': bg === 'snorlax' || undefined,
          }}
        >
          <Pill.Group className={styles.pillGroup}>
            <PillValues
              selectedValues={selectedValues}
              valueComponent={valueComponent}
              stackComponent={stackComponent}
              handleValueRemove={handleValueRemove}
              maxDisplayedValues={maxDisplayedValues}
            />
            <Combobox.EventsTarget>
              <PillsInput.Field
                autoFocus={autoFocus}
                data-autofocus={autoFocus || undefined}
                aria-label={placeholder}
                onFocus={() => !combobox.dropdownOpened && combobox.openDropdown()}
                onBlur={() => combobox.dropdownOpened && combobox.closeDropdown()}
                value={search}
                miw={searchMinWidth}
                placeholder={
                  isLoading
                    ? loadingPlaceholderText ?? t('Loading…')
                    : value.length
                    ? undefined
                    : placeholder
                }
                onChange={(event) => {
                  combobox.updateSelectedOptionIndex();
                  if (event.currentTarget.value !== search) {
                    setSearch(event.currentTarget.value);
                    !!loadOptions && debouncedLoadOptions(combobox, event.currentTarget.value);
                  }
                }}
                onKeyDown={(event) => {
                  if (
                    removeOnBackspace &&
                    event.key.toLowerCase() === 'backspace' &&
                    search?.length === 0
                  ) {
                    event.preventDefault();
                    handleValueRemove(value[value.length - 1]);
                    return;
                  }
                  if (event.key.toLowerCase() === 'enter') {
                    event.preventDefault();
                    event.stopPropagation();
                    if (
                      comboboxOptionsData.length === 1 &&
                      search.trim().toLowerCase() === comboboxOptionsData[0].label?.toLowerCase()
                    ) {
                      handleOptionSubmit(comboboxOptionsData[0].value);
                    } else if (
                      creatable &&
                      !comboboxOptionsData.length &&
                      search.trim().length > 0 &&
                      !exactOptionMatch
                    ) {
                      handleOptionSubmit('$create');
                    }
                  }
                  searchable && !combobox.dropdownOpened && combobox.openDropdown();
                }}
              />
            </Combobox.EventsTarget>
          </Pill.Group>
        </PillsInput>
      </Combobox.DropdownTarget>
      <Combobox.Dropdown>
        <Combobox.Options>
          <ScrollArea.Autosize
            mah={dropdownHeight}
            type="auto"
            classNames={{ viewport: styles.scrollAreaViewPort }}
          >
            <ComboboxOptionsList />
          </ScrollArea.Autosize>
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
};

export default AccMultiSelect;
