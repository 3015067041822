import { IconHelp } from '@tabler/icons-react';
import isEmpty from 'lodash/isEmpty';
import { useTransferMultiAccountDomainMutation } from 'Ghql';
import { useModal } from 'Hooks/base/useModal';
import toast from 'Hooks/useToast';
import { ErrorsFromServer, throwSubmitErrors } from 'Utilities/errors';
import { t, tct } from 'Utilities/i18n';
import { notEmpty } from 'Utilities/underdash';

export const useTransferDomain = () => {
  const { showModal, hideModal } = useModal();

  const [transferDomain] = useTransferMultiAccountDomainMutation();

  const showOveruseModal = (formValues, isCopy?: boolean) => {
    showModal({
      modalType: 'Confirmation',
      modalProps: {
        showExclamationInTitle: false,
        title: (
          <span>
            <span>{t('Keyword usage')}</span>
            <a
              className={'help-icon-overuse-modal'}
              href="https://www.accuranker.com/help/account/dynamic-keyword-usage"
              target="_blank"
              rel="noreferrer"
            >
              <IconHelp size={20} />
            </a>
          </span>
        ),
        description: tct(
          'By adding these keywords, your account will exceed its current keyword limit. [br]If you want to manage your keyword usage click [here:here].',
          {
            br: <br />,
            here: (
              <a
                href="https://app.accuranker.com/app/account/keyword-usage"
                target="_blank"
                rel="noreferrer"
              />
            ),
          },
        ),
        confirmLabel: t('Continue'),
        lockDuration: 0,
        action: async () => {
          const res = await transferDomain({
            variables: {
              input: {
                copy: !!isCopy,
                toClientId: formValues?.client_to?.value,
                domainId: formValues?.domain_from?.value,
              },
              forceTransfer: true,
            },
          });
          const errors = res?.data?.transferMultiAccountDomain?.errors;

          if (!isEmpty(errors)) {
            throwSubmitErrors(errors as ErrorsFromServer);
          } else {
            toast.success(isCopy ? t('Domain copy job started…') : t('Domain move job started…'));
          }

          hideModal();
        },
      },
      nested: true,
    });
  };

  const showNotEnoughKeywordsModal = () => {
    showModal({
      modalType: 'Confirmation',
      modalProps: {
        showExclamationInTitle: false,
        title: (
          <span className="label-with-help">
            <span>{t('Transfer failed')}</span>
            <a
              className={'help-icon-overuse-modal'}
              href="https://www.accuranker.com/help/account/dynamic-keyword-usage"
              target="_blank"
              rel="noreferrer"
            >
              <IconHelp size={20} />
            </a>
          </span>
        ),
        description: tct(
          'The organization you are trying to transfer the domain to does not have enough keywords on their current plan. [br]If you want to manage keyword usage on the plan click [here:here].',
          {
            br: <br />,
            here: (
              <a
                href="https://app.accuranker.com/app/account/keyword-usage"
                target="_blank"
                rel="noreferrer"
              />
            ),
          },
        ),
        confirmLabel: t('Close'),
        lockDuration: 0,
      },
      nested: true,
    });
  };

  const submit = async (formValues, isCopy?: boolean) => {
    showModal({
      modalType: 'Confirmation',
      modalProps: {
        cancelLabel: t('Cancel'),
        confirmLabel: t('OK'),
        lockDuration: 0,
        title: isCopy ? t('Copy domain?') : t('Move domain?'),
        description: tct(
          'Are you sure you want to [action] the domain [domain] to the group [group] on the [organization] account?',
          {
            action: isCopy ? t('copy') : t('transfer'),
            domain: <strong>{formValues?.domain_from?.label}</strong>,
            group: <strong>{formValues?.client_to?.label}</strong>,
            organization: <strong>{formValues?.account_to?.label}</strong>,
          },
        ),
        action: async () => {
          try {
            const res = await transferDomain({
              variables: {
                input: {
                  copy: !!isCopy,
                  toClientId: formValues?.client_to?.value,
                  domainId: formValues?.domain_from?.value,
                },
                forceTransfer: false,
              },
            });
            const errors = res?.data?.transferMultiAccountDomain?.errors?.filter(notEmpty);

            if (!isEmpty(errors)) {
              if (errors?.find((error) => error?.messages.includes('WARNING! Entering overuse'))) {
                showOveruseModal(formValues, isCopy);
              }
              if (
                errors?.find((error) =>
                  error?.messages.includes(
                    'Not enough keywords on the organization you are trying to transfer to.',
                  ),
                )
              ) {
                showNotEnoughKeywordsModal();
              } else if (errors?.length) {
                toast.error(errors[0]?.messages[0]);
              }
            } else {
              toast.success(isCopy ? t('Domain copy job started…') : t('Domain move job started…'));
            }
          } catch (error: any) {
            if (error.errors && error.errors.toClientId) {
              toast.error(error.errors.toClientId);
            } else {
              console.error(error);
              toast.error(t('Something went wrong'));
            }
            throw error;
          }
        },
      },
    });
  };

  return { submit };
};
