import isEqual from 'lodash/isEqual';
import {
  DELETE_FILTERS,
  DELETE_FILTER_GROUP,
  INIT_FILTERS,
  RENAME_FILTER_GROUP,
  SAVE_FILTER_GROUP,
  SELECT_FILTERSET,
  SET_DEFAULT_FILTERS,
  SET_FILTERS,
  TOGGLE_DEFAULT_FILTER_GROUP,
  UPDATE_DEFAULT_COMPARE_TO,
  UPDATE_FILTER_GROUPS,
  UPDATE_FILTER_GROUP_FILTERS,
} from 'Actions/FilterAction';
import type { Action } from 'Actions/FilterAction';
import { getButtonsConfigs } from 'Components/PeriodFilter/buttons';
import { clampRange, rangeToFilters } from 'Components/PeriodFilter/model';
import { getValidFilters } from 'Hooks/data/filter/getValidFilters';
import { FilterAttribute, FilterComparison, FilterValueType, subfilterDiff } from 'Types/Filter';
import type { FilterBase, FilterGroup } from 'Types/Filter';
import {
  AFFILIATE_FILTER_SET,
  DOMAINS_FILTER_SET,
  KEYDIS_FILTER_SET,
  KEYWORDS_FILTER_SET,
  SALES_FILTER_SET,
  SITE_MAPPING_FILTER_SET,
  TAG_CLOUD_FILTER_SET,
  getFilterSetType,
  isFilterInSet,
  isShownInFilterBar,
} from 'Types/FilterSet';
import type { FilterSet } from 'Types/FilterSet';

export type FilterStoreState = {
  readonly filters: Array<FilterBase>;
  readonly filterGroup: FilterGroup;
  readonly filterGroups: Array<FilterGroup>;
  readonly filterSet: FilterSet;
  readonly pristine: boolean;
  readonly defaultCompareTo: string;
};

/**
 * [getDateRangeFilter]
 *
 * Function relies on "compare to" filter buttons' config and will grab
 * the declared range for the respective `compareTo` value.
 *
 * `compareTo` is a user prop (`user.defaultCompareTo`) and will always
 * be set from the getButtonsConfigs(). For that reason it's matching
 * up against those values here as well, in order to only maintain
 * defined "compare to" ranges once; in 'Components/PeriodFilter/buttons'
 *
 * @param  {String} compareTo
 * @param  {boolean} includeCompareToFilter - check if we need only period filter but not compare to one
 * @return {Array<FilterBase>}
 */
const getCompareTo = (compareTo: string, includeCompareToFilter: boolean) => {
  const btns = getButtonsConfigs(true);
  const defaultCompareTo = btns.filter((el) => el.id === compareTo);
  return rangeToFilters(
    // TODO FixTSignore
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    !defaultCompareTo?.length
      ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        clampRange(btns[0]?.getRange?.(), new Date(0), new Date())
      : // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        clampRange(defaultCompareTo[0].getRange(), new Date(0), new Date()),
    includeCompareToFilter,
  );
};

const allDomainsFilter = {
  attribute: FilterAttribute.DOMAINS,
  type: FilterValueType.LIST,
  comparison: FilterComparison.CONTAINS,
  value: [],
};

const getDefaultFilterType = (group: string) => {
  return [
    KEYWORDS_FILTER_SET,
    DOMAINS_FILTER_SET,
    KEYDIS_FILTER_SET,
    SITE_MAPPING_FILTER_SET,
    TAG_CLOUD_FILTER_SET,
  ].includes(group)
    ? `A_${getFilterSetType(group as FilterSet)}`
    : '';
};

const getFilterGroup = ({ group, compareTo }) => {
  return {
    name: 'Default',
    type: getDefaultFilterType(group),
    id: '',
    filters:
      {
        // TODO FixTSignore
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        [KEYWORDS_FILTER_SET]: [...getCompareTo(compareTo), allDomainsFilter],
        // TODO FixTSignore
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        [DOMAINS_FILTER_SET]: getCompareTo(compareTo),
        // TODO FixTSignore
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        [KEYDIS_FILTER_SET]: getCompareTo(compareTo),
        [SITE_MAPPING_FILTER_SET]: getCompareTo(compareTo, true),
        [TAG_CLOUD_FILTER_SET]: getCompareTo(compareTo, true),
        [AFFILIATE_FILTER_SET]: getCompareTo(compareTo, false),
        [SALES_FILTER_SET]: getCompareTo(compareTo, false),
      }[group] || [],
  };
};

const getDefaultFilterGroup = (
  state: FilterStoreState,
  compareTo: string = state.defaultCompareTo,
) => {
  const { filterSet, filterGroups } = state;
  const filterSetType = getFilterSetType(filterSet);
  const userDefaultFilterGroup = filterGroups.find((filterGroup) => {
    const filterGroupType = parseInt(filterGroup.type.split('_')[1], 10);
    const currentTypeMatch = filterGroupType === filterSetType;
    if (!currentTypeMatch) return false;

    if (filterSet === DOMAINS_FILTER_SET) {
      return filterGroup.defaultForDomains;
    }

    return false;
  });
  const defaultFilterGroup = getFilterGroup({
    group: filterSet,
    compareTo,
  });

  if (userDefaultFilterGroup) {
    return {
      ...userDefaultFilterGroup,
      filters: [...defaultFilterGroup.filters, ...userDefaultFilterGroup.filters],
    };
  }

  return defaultFilterGroup;
};

const getInitialFilters = (compareTo: string) => {
  // TODO FixTSignore
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  let defaultFilters = [...getCompareTo(compareTo), allDomainsFilter];
  const savedFiltersData = sessionStorage.getItem('all_filters');

  if (savedFiltersData) {
    try {
      let savedFilters = JSON.parse(savedFiltersData).filters;
      savedFilters = savedFilters.filter(
        (filter) =>
          !defaultFilters.find((defaultFilter) => defaultFilter.attribute === filter.attribute),
      );
      defaultFilters = [...defaultFilters, ...savedFilters];
    } catch (e) {
      // Ignore error
    }
  }

  return defaultFilters;
};

const initialState: FilterStoreState = {
  // TODO FixTSignore
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  filters: getInitialFilters('yesterday'),
  // Current filter group
  filterGroup: getFilterGroup({
    group: 'keywords',
    compareTo: 'yesterday',
  }),
  // Filter groups received from the backend + default ones
  filterGroups: [
    {
      name: 'Default',
      type: getDefaultFilterType(KEYWORDS_FILTER_SET),
      id: '',
      filters: [],
    },
    {
      name: 'Default',
      type: getDefaultFilterType(DOMAINS_FILTER_SET),
      id: '',
      filters: [],
    },
    {
      name: 'Default',
      type: getDefaultFilterType(KEYDIS_FILTER_SET),
      id: '',
      filters: [],
    },
    {
      name: 'Default',
      type: getDefaultFilterType(SITE_MAPPING_FILTER_SET),
      id: '',
      filters: [],
    },
    {
      name: 'Default',
      type: getDefaultFilterType(TAG_CLOUD_FILTER_SET),
      id: '',
      filters: [],
    },
  ],
  // Current filter set id
  filterSet: KEYWORDS_FILTER_SET,
  // Are filters in filter bar up to date
  pristine: true,
  defaultCompareTo: 'yesterday', // This will be overwritten by the user specific default on init
};

function selectFilterGroup(state: FilterStoreState, segmentId: string): FilterStoreState {
  if (state.filterGroup.id !== segmentId) {
    const newFilterGroup = state.filterGroups.find((filterGroup) => filterGroup.id === segmentId);
    return {
      ...state,
      // TODO FixTSignore
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      filterGroup: newFilterGroup || getFilterGroup({}), // noFilterGroup,
    };
  }

  return state;
}

function updateSubfilters(
  state: FilterStoreState,
  newSubfilters: Array<FilterBase>,
): FilterStoreState {
  const isPristine = subfilterDiff(state.filterGroup.filters, newSubfilters).every(
    (filterAttribute: string) => !isShownInFilterBar(filterAttribute, state.filterSet),
  );
  const sameFilters = isEqual(newSubfilters, state.filterGroup?.filters);
  return {
    ...state,
    filterGroup: sameFilters ? state.filterGroup : { ...state.filterGroup, filters: newSubfilters },
    pristine: isPristine,
  };
}

function changeFilters(state: FilterStoreState, filters: FilterBase[]) {
  const withoutSetFilters: FilterBase[] = state.filters.filter(
    (filter) =>
      !isFilterInSet(filter.attribute, state.filterSet) &&
      !filters.find((newFilter) => newFilter.attribute === filter.attribute), // don't include same filters twice
  );
  const newFilters = getValidFilters([...withoutSetFilters, ...filters]);

  if (isEqual(newFilters, state.filters)) {
    return state;
  }
  return { ...state, filters: newFilters };
}

const getCurrentDomainId = (state: FilterStoreState) => {
  const filters = state.filterGroup.filters;
  // TODO FixTSignore
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const domainsFilter = filters.find((filter) => filter.attribute === FilterAttribute.DOMAINS);
  const domainsIds = domainsFilter?.value;
  return Array.isArray(domainsIds) && domainsIds?.length ? domainsIds?.[0] : undefined;
};

const defaultCompareToMap = {
  [AFFILIATE_FILTER_SET]: 'last-month',
  [SALES_FILTER_SET]: 'last-week',
};
export default function (state: FilterStoreState = initialState, action: Action): FilterStoreState {
  const filterGroup = state.filterGroup;

  switch (action.type) {
    case INIT_FILTERS: {
      // HACK: Update default filter value
      // TODO FixTSignore
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      allDomainsFilter.value = action.domainsFilter.value;
      return state;
    }

    case SELECT_FILTERSET: {
      const newFilterSet = action.filterSet;
      return {
        ...state,
        filterSet: newFilterSet,
        filterGroup: {
          ...state.filterGroup,
          filters: getValidFilters(
            state.filters.filter((filter) => isFilterInSet(filter.attribute, newFilterSet)),
          ),
        },
      };
    }

    case SET_FILTERS: {
      // We set filters for the current filter set only
      state = selectFilterGroup(state, action.segmentId);
      state = updateSubfilters(state, action.filters);
      return changeFilters(state, state.filterGroup.filters);
    }

    case DELETE_FILTERS: {
      return {
        ...state,
        filters: (state.filters ?? []).filter((e) => !action.payload?.includes(e?.attribute)),
        filterGroup: {
          ...state.filterGroup,
          filters: (state.filterGroup.filters ?? []).filter(
            (e) => !action.payload?.includes(e?.attribute),
          ),
        },
      };
    }

    case SET_DEFAULT_FILTERS: {
      state = {
        ...state,
        filterGroup: getDefaultFilterGroup(state, defaultCompareToMap[state.filterSet]),
      };
      return changeFilters(state, state.filterGroup.filters);
    }

    case UPDATE_FILTER_GROUPS: {
      return { ...state, filterGroups: [...state.filterGroups, ...action.filterGroups] };
    }

    case SAVE_FILTER_GROUP: {
      const newFilterGroup: FilterGroup = action.filterGroup;
      return {
        ...state,
        filterGroup: {
          ...filterGroup,
          name: newFilterGroup.name,
          id: newFilterGroup.id,
          type: newFilterGroup.type,
        },
        filterGroups: [...state.filterGroups, newFilterGroup],
        pristine: true,
      };
    }

    case DELETE_FILTER_GROUP: {
      const idToRemove = action.id;
      const newFilterGroups = state.filterGroups.filter((fGroup) => fGroup.id !== idToRemove);
      return { ...state, filterGroups: newFilterGroups };
    }

    case RENAME_FILTER_GROUP: {
      const idToRename = action.id;
      const newName = action.name;
      const newFilterGroups = state.filterGroups.map((fGroup) => {
        if (fGroup.id === idToRename) {
          return { ...fGroup, name: newName };
        }

        return fGroup;
      });
      return {
        ...state,
        filterGroup: {
          ...state.filterGroup,
          name: action.id === state.filterGroup.id ? newName : state.filterGroup.name,
        },
        filterGroups: newFilterGroups,
      };
    }

    case TOGGLE_DEFAULT_FILTER_GROUP: {
      const idToToggle = action.id;
      const newFilterGroups = state.filterGroups.map((fGroup) => {
        if (state.filterSet === DOMAINS_FILTER_SET) {
          const defaultForDomains = fGroup.id === idToToggle ? action.isDefault : false;
          return { ...fGroup, defaultForDomains };
        } else if (
          [
            KEYWORDS_FILTER_SET,
            KEYDIS_FILTER_SET,
            SITE_MAPPING_FILTER_SET,
            TAG_CLOUD_FILTER_SET,
          ].includes(state.filterSet)
        ) {
          const domainId = getCurrentDomainId(state);

          if (domainId) {
            let defaultForKeywords: any[] = fGroup.defaultForKeywords || [];
            defaultForKeywords = defaultForKeywords.filter(
              (defaultDomainId) => defaultDomainId !== domainId,
            );

            if (fGroup.id === idToToggle && action.isDefault) {
              defaultForKeywords.push(domainId);
            }

            return { ...fGroup, defaultForKeywords };
          }
        }

        return fGroup;
      });
      return { ...state, filterGroups: newFilterGroups as any };
    }

    case UPDATE_FILTER_GROUP_FILTERS: {
      const newFilters = action.filters;
      const idToReplaceFilters = action.id;
      const newFilterGroups = state.filterGroups.map((fGroup) => {
        if (fGroup.id === idToReplaceFilters) {
          return { ...fGroup, filters: newFilters };
        }

        return fGroup;
      });
      return { ...state, filterGroups: newFilterGroups, pristine: true };
    }

    case UPDATE_DEFAULT_COMPARE_TO: {
      return { ...state, defaultCompareTo: action.id };
    }

    default: {
      // apply get valid filters to state.filterGroup.filters
      const filterGroupFilters = getValidFilters(state.filterGroup.filters);
      const filterGroupWithValidFilters = { ...state.filterGroup, filters: filterGroupFilters };
      return {
        ...state,
        filterGroup: filterGroupWithValidFilters,
        filters: getValidFilters(state.filters),
      };
    }
  }
}
