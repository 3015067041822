import React, { createContext, useCallback, useContext, useState } from 'react';
import { Flex } from '@mantine/core';
import noop from 'lodash/noop';
import { STICKY_TREE_TABLE_HEADER_WITH_ADD } from 'Constants/sticky';
import { TOP_STICKY_OFFSET_HEADER } from '../../support/constants';
import { useSelectedNodes } from '../../support/state';
import { AutoScrollWrapper } from '../AutoScrollArea';
import { ClickToAddFolderButton } from './components/ClickToAddFolderButton';
import { MoveGroupModal } from './components/MoveGroupModal';
import { MoveSelectedNodesOptions } from './components/MoveSelectedNodesAction';
import { TableWrapperGroupEdit } from './components/TableWrapperGroupEdit';

type EditModeHeaderContextType = {
  onDrop: (...args: unknown[]) => void;
  onStartAdd: (target?: string | number | null) => void;
  isDirty?: boolean;
  isAddMode?: boolean;
  isEditingNode?: boolean;
  animateMoveSelected?: boolean;
  addTarget?: string | number | null;
};
export const EditModeHeaderContext = createContext<EditModeHeaderContextType>({
  onDrop: noop,
  onStartAdd: noop,
});

export const EditModeHeader = ({ activeSearch, submitSearch }: any) => {
  const headerContext = useContext(EditModeHeaderContext);
  const resetScroll = useCallback(
    () =>
      window.scrollTo({
        top: Math.min(window.scrollY, TOP_STICKY_OFFSET_HEADER),
        behavior: 'smooth',
      }),
    [],
  );
  const { setSelectedNodes } = useSelectedNodes();
  const [openMoveModal, setOpenMoveModal] = useState(false);
  const handleMoveItems = () => {
    setOpenMoveModal(true);
  };

  // we hide header only if adding on root level, to avoid jumping on folder level adding
  const headerHidden = headerContext.isAddMode && !headerContext.addTarget;
  return (
    <>
      <TableWrapperGroupEdit
        submitSearch={submitSearch}
        activeSearch={activeSearch}
        isDirty={headerContext.isDirty}
      />
      <AutoScrollWrapper up topStickyOffset={STICKY_TREE_TABLE_HEADER_WITH_ADD}>
        {!headerHidden && (
          <Flex
            justify={'apart'}
            pl={8}
            pt={8}
            pr={10}
            h={50}
            bg="white"
            style={{
              borderBottom: '1px solid #cecfd2',
            }}
          >
            {!headerContext.isAddMode ? (
              <ClickToAddFolderButton
                onStartAdd={headerContext.onStartAdd}
                resetScroll={resetScroll}
              />
            ) : null}
            <MoveSelectedNodesOptions
              setSelectedNodes={setSelectedNodes}
              handleMoveItems={handleMoveItems}
              isAddMode={headerContext.isAddMode}
              isEditingNode={headerContext.isEditingNode}
              animateMoveSelected={headerContext.animateMoveSelected}
            />
          </Flex>
        )}
      </AutoScrollWrapper>
      <MoveGroupModal
        opened={openMoveModal}
        setOpened={setOpenMoveModal}
        onDrop={headerContext.onDrop}
      />
    </>
  );
};
