import { linkToPageWithDomains } from 'Components/Filters/LinkToDomain';
import { KEYWORDS_FILTER_SET } from 'Types/FilterSet';
import { notEmpty } from 'Utilities/underdash';

const makeDomainsLink = (domainsIds: string[], page?: string) => {
  return linkToPageWithDomains(
    page || '/keywords/overview',
    domainsIds,
    KEYWORDS_FILTER_SET,
    [],
    !page,
  );
};

export const getDomainLink = (domainObj: any, page?: string) =>
  makeDomainsLink([domainObj.id], page);
export const getGroupLink = (group) => makeDomainsLink(group.domainsIds);

export const buildGroups = (clients: any = []) => {
  return clients.map((client) => {
    const domainsIds = client.domains.map((domain) => domain?.id).filter(notEmpty);

    return {
      id: client.clientId,
      displayName: client.clientName,
      domainsIds: domainsIds.map((x) => x.toString()),
      domains: client.domains.map((domain) => ({
        id: domain.id.toString(),
        domain: domain.domain,
        displayName: domain.displayName,
      })),
    };
  });
};
