import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Box, Flex, Group } from '@mantine/core';
import { IconTournament } from '@tabler/icons-react';
import isNil from 'lodash/isNil';
import AccActionIcon from 'Components/AccActionIcon/AccActionIcon';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import Cell from 'Components/AccuTable/CellRenderer/HelperComponents/AccuCell';
import {
  createChangeCell,
  createCombinedChangeCell,
  createCombinedNumberCell,
  createNumberCell,
  createPercentageChangeCell,
} from 'Components/Cell';
import { ColumnType, TableOrder } from 'Components/DataTable';
import linkWithFilters from 'Components/Filters/linkWithFilters';
import FormatNumber from 'Components/FormatNumber/new';
import { ReverseEllipsisLink } from 'Components/Link/ReverseEllipsisLink';
import { useQueryDomainInfo } from 'Hooks/data/domain/useQueryDomainInfo';
import { useDisplayCurrency } from 'Hooks/displayCurrency';
import { useFetchTableData } from 'Hooks/table';
import { SORTING_TYPE } from 'Pages/Keywords/Table/hooks/keyword/constants';
import { pathFromURL } from 'Pages/SiteMapping/support/helpers';
import { t } from 'Utilities/i18n';
import { getIsLandingPageRevenueEnabled } from '../../../support/helpers';
import { LandingCostValueCell } from '../components/LandingCostValueCell';
import { TableKeywordsCount } from '../components/TableKeywordsCount';
import { UntrackedKeywordsCell } from '../components/UntrackedKeywordsCell';
import { DEFAULT_LANDING_ORDERING, LANDING_TABLE_ID, LandingTableIDs } from './constants';
import { QUERY_LANDING_PAGES } from './landing-table.query';
import { LandingTableItem, LandingTableQuery, LandingTableQueryVariables } from './types';

export const LinkToSitemap = ({ path }) => {
  return (
    <Link
      to={linkWithFilters({ to: '/keywords/sitemapping' })}
      state={{ landingPage: pathFromURL(path) }}
    >
      <AccTooltip tooltip={t('Go to Site Explorer and view landing page')}>
        <AccActionIcon color="blue" size="md" variant="subtle">
          <IconTournament size={16} style={{ transform: 'rotate(180deg)' }} />
        </AccActionIcon>
      </AccTooltip>
    </Link>
  );
};

export const NaverBlacklist: string[] = [
  LandingTableIDs.COST_VALUE,
  LandingTableIDs.ORGANIC_TRAFFIC,
  LandingTableIDs.ORGANIC_TRAFFIC_CHANGE,
  LandingTableIDs.DYNAMIC_CTR,
  LandingTableIDs.DYNAMIC_CTR_CHANGE,
];

const byDomainTypeFilter = (col: ColumnType<LandingTableItem>, isNaverDomain: boolean): boolean => {
  // if (domainInfo?.domainType === DomainTypeChoices.A_7) {
  if (isNaverDomain) {
    return !col.id || !NaverBlacklist.includes(col.id);
  }
  return true;
};

export const useLandingTableInfo = () => {
  const { domainInfo, isNaverDomain } = useQueryDomainInfo();
  const enabledRevenue = getIsLandingPageRevenueEnabled(domainInfo);
  const { displayCurrency } = useDisplayCurrency(true);
  const NoValueForDate = () => {
    return (
      <AccTooltip tooltip={t('No value found')}>
        <span>N/A</span>
      </AccTooltip>
    );
  };

  const columns: ColumnType<LandingTableItem>[] = useMemo(
    () =>
      [
        {
          id: LandingTableIDs.CHECKBOX,
          width: 40,
          fixed: 'left',
          onCell: (record: any) => ({
            type: 'checkbox',
            data: record,
          }),
        },
        {
          id: LandingTableIDs.PATH,
          title: t('Path'),
          flex: true,
          width: 150,
          cellRenderer: ({ record }) => (
            <Group>
              <Flex justify="space-between" w="100%">
                <ReverseEllipsisLink url={record.path} />
                <Box ml={20}>
                  <LinkToSitemap path={record.path} />
                </Box>
              </Flex>
            </Group>
          ),

          onHeaderCell: () => ({
            ordering: {
              defaultOrder: TableOrder.ASC,
              orderBy: LandingTableIDs.PATH,
              sortingKey: SORTING_TYPE.alphabetical,
            },
          }),
        },
        {
          id: LandingTableIDs.KEYWORDS,
          title: t('Keywords'),
          width: 120,
          cellRenderer: TableKeywordsCount,
          onHeaderCell: () => ({
            ordering: {
              defaultOrder: TableOrder.ASC,
              orderBy: LandingTableIDs.KEYWORDS,
              sortingKey: SORTING_TYPE.numerical,
            },
            reverseDirection: true,
          }),
        },
        {
          id: LandingTableIDs.SEARCH_VOLUME,
          title: t('Search volume'),
          width: 124,
          cellRenderer: (props) => (
            <Cell rightAligned>
              <FormatNumber value={props.record?.searchVolumeValue} />
            </Cell>
          ),
          onHeaderCell: () => ({
            ordering: {
              defaultOrder: TableOrder.ASC,
              orderBy: LandingTableIDs.SEARCH_VOLUME,
              sortingKey: SORTING_TYPE.numerical,
            },
            reverseDirection: true,
          }),
        },
        {
          id: LandingTableIDs.UNTRACKED_KEYWORDS,
          title: t('Discovered Keywords'),
          width: 140,
          cellRenderer: UntrackedKeywordsCell,
          onHeaderCell: () => ({
            reverseDirection: true,
          }),
        },
        {
          id: LandingTableIDs.SHARE_OF_VOICE,
          combineRow: LandingTableIDs.SHARE_OF_VOICE_CHANGE,
          title: t('SoV'),
          width: 100,
          cellRenderer: createCombinedNumberCell<LandingTableItem>({
            percentageKey: 'shareOfVoicePercentageValue',
            valueKey: 'shareOfVoiceValue',
          }),
          onHeaderCell: () => ({
            ordering: {
              defaultOrder: TableOrder.ASC,
              orderBy: LandingTableIDs.SHARE_OF_VOICE,
              sortingKey: SORTING_TYPE.numerical,
            },
            reverseDirection: true,
          }),
        },
        {
          id: LandingTableIDs.SHARE_OF_VOICE_CHANGE,
          title: t('+/-'),
          width: 72,
          cellRenderer: createCombinedChangeCell<LandingTableItem>({
            percentageKey: 'shareOfVoicePercentageValue',
            percentageCompareKey: 'shareOfVoicePercentageValueCompare',
            valueKey: 'shareOfVoiceValue',
            compareKey: 'shareOfVoiceCompare',
          }),
          onHeaderCell: () => ({
            ordering: {
              defaultOrder: TableOrder.ASC,
              orderBy: LandingTableIDs.SHARE_OF_VOICE_CHANGE,
              sortingKey: SORTING_TYPE.numerical,
            },
            reverseDirection: true,
          }),
        },
        ...(enabledRevenue
          ? [
              {
                id: LandingTableIDs.AVG_REVENUE,
                combineRow: LandingTableIDs.AVG_REVENUE_COMPARE,
                title: t('Revenue'),
                width: 100,
                cellRenderer: createNumberCell<LandingTableItem>('avgRevenue', true),
                onHeaderCell: () => ({
                  ordering: {
                    defaultOrder: TableOrder.ASC,
                    orderBy: LandingTableIDs.AVG_REVENUE,
                    sortingKey: SORTING_TYPE.numerical,
                  },
                  reverseDirection: true,
                }),
              },
              {
                id: LandingTableIDs.AVG_REVENUE_COMPARE,
                title: t('+/-'),
                width: 72,
                cellRenderer: createChangeCell<LandingTableItem>({
                  valueKey: 'avgRevenue',
                  deltaKey: 'avgRevenueCompare',
                }),
                onHeaderCell: () => ({
                  ordering: {
                    defaultOrder: TableOrder.ASC,
                    orderBy: LandingTableIDs.AVG_REVENUE_COMPARE,
                    sortingKey: SORTING_TYPE.numerical,
                  },
                  reverseDirection: true,
                }),
              },
            ]
          : []),
        {
          id: LandingTableIDs.COST_VALUE,
          title: `${t('Traffic value')} (${displayCurrency})`,
          width: 135,
          cellRenderer: LandingCostValueCell,
          onHeaderCell: () => ({
            ordering: {
              defaultOrder: TableOrder.DESC,
              orderBy: LandingTableIDs.COST_VALUE,
              sortingKey: SORTING_TYPE.numerical,
            },
            reverseDirection: true,
          }),
        },

        {
          id: LandingTableIDs.ORGANIC_TRAFFIC,
          title: t('AI SoV'),
          combineRow: LandingTableIDs.ORGANIC_TRAFFIC_CHANGE,
          width: 100,
          cellRenderer: (props) => {
            if (isNil(props.record?.organicTraffic)) {
              return (
                <Cell rightAligned>
                  <NoValueForDate />
                </Cell>
              );
            }
            return (
              <Cell rightAligned>
                <FormatNumber value={props.record?.organicTraffic} />
              </Cell>
            );
          },
          onHeaderCell: () => ({
            ordering: {
              defaultOrder: TableOrder.DESC,
              orderBy: LandingTableIDs.ORGANIC_TRAFFIC,
              sortingKey: SORTING_TYPE.numerical,
            },
            reverseDirection: true,
          }),
        },
        {
          id: LandingTableIDs.ORGANIC_TRAFFIC_CHANGE,
          title: t('+/-'),
          width: 72,
          cellRenderer: createChangeCell<LandingTableItem>({
            valueKey: 'organicTraffic',
            deltaKey: 'organicTrafficCompare',
          }),
          onHeaderCell: () => ({
            ordering: {
              defaultOrder: TableOrder.ASC,
              orderBy: LandingTableIDs.ORGANIC_TRAFFIC_CHANGE,
              sortingKey: SORTING_TYPE.numerical,
            },
            reverseDirection: true,
          }),
        },

        {
          id: LandingTableIDs.DYNAMIC_CTR,
          title: t('CTR'),
          combineRow: LandingTableIDs.DYNAMIC_CTR_CHANGE,
          width: 100,
          cellRenderer: (props) => {
            if (isNil(props.record?.dynamicCtrValue)) {
              return (
                <Cell rightAligned>
                  <NoValueForDate />
                </Cell>
              );
            }
            return (
              <Cell rightAligned>
                <FormatNumber
                  value={(props.record?.dynamicCtrValue as number) / 100}
                  style={'percent'}
                  maximumFractionDigits={2}
                />
              </Cell>
            );
          },
          onHeaderCell: () => ({
            ordering: {
              defaultOrder: TableOrder.DESC,
              orderBy: LandingTableIDs.DYNAMIC_CTR,
              sortingKey: SORTING_TYPE.numerical,
            },
            reverseDirection: true,
          }),
        },
        {
          id: LandingTableIDs.DYNAMIC_CTR_CHANGE,
          title: t('+/-'),
          width: 72,
          cellRenderer: createPercentageChangeCell<LandingTableItem>({
            percentageKey: 'dynamicCtrValue',
            percentageCompareKey: 'dynamicCtrCompare',
          }),
          onHeaderCell: () => ({
            ordering: {
              defaultOrder: TableOrder.ASC,
              orderBy: LandingTableIDs.DYNAMIC_CTR_CHANGE,
              sortingKey: SORTING_TYPE.numerical,
            },
            reverseDirection: true,
          }),
        },
      ].filter((column) =>
        byDomainTypeFilter(column as ColumnType<LandingTableItem>, !!isNaverDomain),
      ) as ColumnType<LandingTableItem>[],
    [enabledRevenue],
  );

  return { columns };
};

export const useFetchLandingTableData = (skip: boolean = false, displayCurrency?: string) =>
  useFetchTableData<LandingTableQuery, LandingTableQueryVariables, LandingTableItem>({
    query: QUERY_LANDING_PAGES,
    defaultOrdering: DEFAULT_LANDING_ORDERING,
    tableId: LANDING_TABLE_ID,
    noTableSettings: true,
    displayCurrency,
    skip,
    extractData: (res) => ({
      data:
        res.tableLandingPages?.landingPages?.map(
          // eslint-disable-next-line
          // @ts-ignore
          (e) => ({ ...e, id: e?.id?.toString() } as LandingTableItem),
        ) ?? [],
      length: res.tableLandingPages?.pagination?.numResults ?? 0,
    }),
  });
