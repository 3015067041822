import { useRef } from 'react';
import { ActionIcon } from '@mantine/core';
import { IconX } from '@tabler/icons-react';
import cn from 'classnames';
import compose from 'lodash/flowRight';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import SimpleDropdownButton from 'Components/Controls/Dropdowns/SimpleDropdownButton';
import FilterEditorForm from 'Components/Filters/FilterEditorForm';
import { AccPopover, InjectedPopoverBodyProps } from 'Components/Popover/AccPopover';
import type { FilterBase } from 'Types/Filter';
import { t } from 'Utilities/i18n';
import { useMousetrap } from 'Utilities/mousetrap';
import { isMac } from 'Utilities/underdash';
import { getFilterSearchShortCutDescription } from '../AddFilter/component';
import getFilterData from '../getFilterData';
import styles from './filter-item.module.scss';

type Props = {
  filter: FilterBase;
  active?: boolean;
  onDelete: (filter: FilterBase) => void;
  onUpdate: (filter: FilterBase) => void;
  primaryFilter?: boolean;
};

const FilterItem = (props: Props) => {
  const popoverControlsRef = useRef<InjectedPopoverBodyProps | null>(null);
  const shortcut = isMac() ? 'command+f' : 'ctrl+f';
  useMousetrap({
    combination: shortcut,
    description: getFilterSearchShortCutDescription(props.filter?.attribute),
    disabled: !props.primaryFilter,
    callback: () => popoverControlsRef.current?.openPopover(),
  });

  const handleDelete = (evt) => {
    evt.stopPropagation();
    props.onDelete(props.filter);
  };
  const { filter } = props;
  const filterData = getFilterData(filter.attribute);
  const labelFunction = filterData?.labelFunc as ((filter: FilterBase) => any) | undefined;
  const label = labelFunction && labelFunction(filter);
  const title = filterData?.title;
  const isDisabled = !props.active;
  return (
    <AccPopover
      position="bottom-start"
      withPortal
      targetToggle
      target={
        <button
          className={cn(
            styles.filterItem,
            'menu-toggle simple-dropdown filter-item dropdown',
            isDisabled ? styles.disabledFilter : '',
          )}
        >
          <SimpleDropdownButton
            item={label}
            title={title}
            tooltip={isDisabled ? t('This filter is not applicable for this tab') : undefined}
            labelMaxWidth={200}
          />
          <AccTooltip tooltip={t('Clear the filter')}>
            <ActionIcon
              className={cn(styles.deleteFilter)}
              onClick={handleDelete}
              variant="transparent"
              color="gray.7"
            >
              <IconX />
            </ActionIcon>
          </AccTooltip>
        </button>
      }
      body={{ p: 0, mt: -8 }}
      withArrow={false}
      controlsRef={popoverControlsRef}
      maxWidth={600}
      trapFocus
    >
      {({ closePopover }) => {
        const withClose = (fn) => compose(closePopover, fn);
        return (
          <FilterEditorForm
            onSubmit={withClose(props.onUpdate)}
            filter={filter}
            initialValues={filter}
            onDelete={withClose(handleDelete)}
          />
        );
      }}
    </AccPopover>
  );
};

FilterItem.defaultProps = {
  active: true,
};

export default FilterItem;
