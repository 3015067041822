import {
  IconArrowNarrowDown,
  IconArrowNarrowUp,
  IconSortAscendingLetters,
  IconSortAscendingNumbers,
  IconSortDescendingLetters,
  IconSortDescendingNumbers,
} from '@tabler/icons-react';
import { ColumnOrdering } from 'Components/DataTable/types';
import { SORTING_TYPE } from 'Pages/Keywords/Table/hooks/keyword/constants';
import { t } from 'Utilities/i18n';

const MENU_ICON_SIZE = 16;

export const getSortingIcon = (isDesc: boolean, hasOrdering: boolean, ordering: ColumnOrdering) => {
  if (!hasOrdering) return null;

  if (isDesc) {
    switch (ordering.sortingKey) {
      case SORTING_TYPE.alphabetical:
        return <IconSortDescendingLetters className="sort-icon" size={MENU_ICON_SIZE} />;
      case SORTING_TYPE.numerical:
        return <IconSortDescendingNumbers className="sort-icon" size={MENU_ICON_SIZE} />;
      case SORTING_TYPE.rank:
        return <IconSortDescendingNumbers className="sort-icon" size={MENU_ICON_SIZE} />;
      case SORTING_TYPE.recurency:
        return <IconArrowNarrowDown className="sort-icon" size={MENU_ICON_SIZE} />;
      default:
        return <IconArrowNarrowUp className="sort-icon" size={MENU_ICON_SIZE} />;
    }
  }
  switch (ordering.sortingKey) {
    case SORTING_TYPE.alphabetical:
      return <IconSortAscendingLetters className="sort-icon" size={MENU_ICON_SIZE} />;
    case SORTING_TYPE.numerical:
      return <IconSortAscendingNumbers className="sort-icon" size={MENU_ICON_SIZE} />;
    case SORTING_TYPE.rank:
      return <IconSortAscendingNumbers className="sort-icon" size={MENU_ICON_SIZE} />;
    case SORTING_TYPE.recurency:
      return <IconArrowNarrowUp className="sort-icon" size={MENU_ICON_SIZE} />;
    default:
      return <IconArrowNarrowDown className="sort-icon" size={MENU_ICON_SIZE} />;
  }
};

export const getSortingText = (isASC: boolean, hasOrdering: boolean, ordering: ColumnOrdering) => {
  if (!hasOrdering) return null;

  if (isASC) {
    switch (ordering.sortingKey) {
      case SORTING_TYPE.alphabetical:
        return t('A to Z');
      case SORTING_TYPE.numerical:
        return t('Lowest to highest');
      case SORTING_TYPE.recurency:
        return t('Oldest to newest');
      case SORTING_TYPE.amount:
        return t('Fewest to most');
      case SORTING_TYPE.rank:
        return t('Highest to lowest');
      default:
        return t('Ascending');
    }
  }
  switch (ordering.sortingKey) {
    case SORTING_TYPE.alphabetical:
      return t('Z to A');
    case SORTING_TYPE.numerical:
      return t('Highest to lowest');
    case SORTING_TYPE.recurency:
      return t('Newest to oldest');
    case SORTING_TYPE.amount:
      return t('Most to fewest');
    case SORTING_TYPE.rank:
      return t('Lowest to highest');
    default:
      return t('Descending');
  }
};
