import React from 'react';
import cn from 'classnames';
import { ROW_SIZE } from '../support/constants';
import styles from './scrollShadow.module.scss';

export const TableScrollShadow = React.memo(({ isHeader }: { isHeader?: boolean }) => {
  return (
    <div
      className={cn(styles.item, 'final-table-shadow-item')}
      style={{ top: isHeader ? 0 : '-6px', height: `${ROW_SIZE + (isHeader ? 1 : 0)}px` }}
    />
  );
});
TableScrollShadow.displayName = 'TableScrollShadow';
