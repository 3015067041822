import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import every from 'lodash/every';
import {
  getAttributeMapping,
  getFilterDataValue,
  sortMap,
} from 'Components/DataTable/hooks/offline/support/helpers';
import { TableOfflineFilter } from 'Components/DataTable/hooks/offline/useOfflineFilter';
import { TablePagination } from 'Components/DataTable/types';
import { useGetFilters } from 'Hooks/data/filter/useFilters';
import * as Sort from 'Types/Sort';
import { StoreTableProps, StoreType } from 'Types/Store';
import compareWithFilter from 'Utilities/compare';
import { DataLike } from './types';

/***
 * Filter data by filters on client side
 */
export const useFilterData = (options?: TableOfflineFilter) => {
  const getFilters = useGetFilters();
  return useCallback(
    (data: Record<string, any>[]): DataLike => {
      if (!options) {
        return [];
      }
      const filterFuncs = getFilters()
        .filter((e) => {
          if (options?.skipAll) {
            return false;
          } else if (options?.skip) {
            return !options.skip?.includes(e.attribute);
          }
          return true;
        })
        .map((filter) => (input) => {
          const value = getFilterDataValue(input, filter.attribute, options);
          return compareWithFilter(value, filter as any);
        });
      return data.filter((item) => every(filterFuncs, (filter) => filter(item)));
    },
    [options, getFilters],
  );
};

/***
 * Apply sorting to the data
 */
export const useGetSort = (options?: TableOfflineFilter) => {
  const tableProps = useSelector<StoreType>((state) =>
    (options ? state.table?.[options.tableName] : null),
  );
  const getSorter = (byField: string) => {
    if (options?.sortTypes?.[byField] && sortMap[options.sortTypes[byField]]) {
      return sortMap[options.sortTypes[byField]];
    }

    return sortMap[Sort.NATURAL];
  };

  return useCallback(
    (data: DataLike, externalTableProps?: { sortField: string; sortOrder: string }): DataLike => {
      if ((!tableProps && !externalTableProps) || !options) {
        return data;
      }

      const { sortField, sortOrder } = externalTableProps || (tableProps as StoreTableProps);

      if (!sortField) {
        return data;
      }

      const sortFieldMapped = getAttributeMapping(sortField, options.mappings);
      return getSorter(sortField)(data, sortFieldMapped, sortOrder);
    },
    [options],
  );
};

/***
 * Apply pagination to the data
 */
export const useGetPagination = (withoutPagination?: boolean) => {
  return useCallback((data: DataLike, pagination?: TablePagination) => {
    if (withoutPagination) {
      return {
        data,
        length: data.length,
      };
    }

    const { startIndex, stopIndex } = pagination || {};
    return {
      data: data.slice(startIndex, stopIndex),
      length: data.length,
    };
  }, []);
};
