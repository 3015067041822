export const TableSettingVersion = {
  DEFAULT: 'default',
  GROUPS: 'groups',
} as const;

export type ColumnSettingKeyType =
  | 'defaultKeywordsColumns'
  | 'defaultCompetitorsColumns'
  | 'defaultTagCloudColumns'
  | 'defaultLandingPagesColumns'
  | 'defaultFoldersColumns'
  | 'defaultHomeColumns'
  | 'defaultKeydisColumns';

export interface GroupSettingsItem {
  id: string;
  enabled: boolean;
}

export interface TableSettingForm {
  groups?: GroupSettingsItem[];
  columns: string[];
}
