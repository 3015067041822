import { useRef } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router';
import { Flex, Group } from '@mantine/core';
import { IconX } from '@tabler/icons-react';
import moment from 'moment';
import AccActionIcon from 'Components/AccActionIcon/AccActionIcon';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import { LineChart } from 'Components/Chart/LineChart';
import { NOT_IN_TOP_100_VALUE } from 'Components/Chart/LineChart/support/constants';
import { SeriesItem, chartTooltipTypes } from 'Components/Chart/LineChart/support/types';
import ExportButton from 'Components/ExportButton';
import AccTitle from 'Components/Title/AccTitle';
import { useGetPublicKeywordHistoryQuery } from 'Ghql';
import { useFilters } from 'Hooks';
import { colorScheme } from 'Utilities/colors';
import { t, tct } from 'Utilities/i18n';
import { getFiltersWithFallback } from './GetFiltersWithFallback';

type PublicTableRankChartProps = {
  keywordId: string;
  keyword: string;
  toggleExpand?: () => void;
};

const PublicTableRankChart = ({ keywordId, keyword, toggleExpand }: PublicTableRankChartProps) => {
  const filters = useFilters();
  const filtersWithFallback = getFiltersWithFallback(filters);

  const exportContainer = useRef<HTMLDivElement>(null);

  const intl = useIntl();

  const { domainSlug = '', viewkey = '' } = useParams();

  const { data, loading } = useGetPublicKeywordHistoryQuery({
    variables: { filters: filtersWithFallback, viewkey, slug: domainSlug, keywordId },
  });

  const series: SeriesItem[] = [
    {
      name: t('Historic Keyword Rank'),
      primary: true,
      color: `${colorScheme.graphs.stats.averageBaseRank}9D`,
      data:
        data?.graphs?.publicKeywordHistory?.filter(Boolean)?.map((e) => ({
          x: e?.searchDatetime ?? 0,
          y: e?.rank || NOT_IN_TOP_100_VALUE,
        })) ?? [],
    },
  ];

  return (
    <div>
      <Flex align={'center'} justify={'space-between'} pr={16}>
        <AccTitle p={16} type="h5">
          {keyword
            ? tct('Historic Keyword Rank for "[keyword]"', {
                keyword,
              })
            : t('Historic Keyword Rank')}
        </AccTitle>
        <Group gap="sm">
          <ExportButton
            content={() => exportContainer.current}
            fileName={`AccuRanker_historic_keyword_rank_chart_${keyword?.replaceAll(
              ' ',
              '_',
            )}_${moment().format('YYYY-MM-DD')}`}
          />
          {toggleExpand ? (
            <AccTooltip tooltip={t('Collapse expanded row')}>
              <AccActionIcon size={'lg'} variant="subtle" onClick={toggleExpand}>
                <IconX />
              </AccActionIcon>
            </AccTooltip>
          ) : null}
        </Group>
      </Flex>
      <Group ref={exportContainer} ml={8} mr={16}>
        <div style={{ flexGrow: 1, minWidth: 0 }}>
          <LineChart
            isRank
            series={series}
            tooltip={{
              type: chartTooltipTypes.STOCK,
              title: t('Historic rank'),
              showPercentage: false,
              dontShowTotalKeywords: true,
              customNumFormatter: (num: number) =>
                intl?.formatNumber(num, {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 2,
                  style: 'decimal',
                }),
            }}
            chartType="area"
            isLoading={loading}
            loaderType="line"
            disableLegend
            exportButton={false}
          />
        </div>
      </Group>
    </div>
  );
};

export default PublicTableRankChart;
