import { useEffect, useState } from 'react';
import { Flex, Popover } from '@mantine/core';
import { useClickOutside } from '@mantine/hooks';
import { IconCalendar } from '@tabler/icons-react';
import AccSegmentedControl from 'Components/AccSegmentedControl/AccSegmentedControl';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import AccText from 'Components/Text/AccText';
import { PublicDomainFragment } from 'Ghql';
import { DomainInfo } from 'Query';
import { PeriodFilter } from 'Types/Filter';
import { t } from 'Utilities/i18n';
import { CompareToContent } from '../CompareToContent';
import { getNextScheduledRefresh } from '../CompareToContent/utils';
import { getSegmentedDateRange } from '../getRange';
import { getSegmentedDateRangeValue } from '../getRangeValue';
import {
  DateFilters,
  DateRange,
  clampRange,
  parsePeriodFilterValue,
  rangeToFilters,
} from '../model';
import { getTooltipDate } from './getTooltipDate';
import styles from './segmentedCompareTo.module.scss';

const DATE_RANGE_TYPES = [
  'yesterday',
  'last-week',
  'last-two-weeks',
  'last-month',
  'last-year',
  'initial',
] as const;

export type DateRangeType = typeof DATE_RANGE_TYPES[number] | 'custom';

type Props = {
  partialScrapeWarningShouldShow: boolean;
  domainInfo: DomainInfo | PublicDomainFragment | undefined;
  onSubmit: (filters: DateFilters) => void;
  min: Date;
  max: Date;
  onlyPeriodFilter: boolean;
  periodFilter: PeriodFilter;
  onChange?: (value: DateRangeType) => void;
  message?: string;
  label?: string;
  notViewingLatestData?: boolean;
};

export const SegmentedCompareToContent = (props: Props) => {
  const { min, max, periodFilter, notViewingLatestData, onChange } = props;

  const [popoverOpen, setPopoverOpen] = useState(false);
  const [previousSegmentedControlValue, setPreviousSegmentedControlValue] =
    useState<DateRangeType>('yesterday');
  const [dropdown, setDropdown] = useState<HTMLDivElement | null>(null);
  const [segmentedControl, setSegmentedControl] = useState<HTMLDivElement | null>(null);

  const periodFilterValue = parsePeriodFilterValue(periodFilter);
  const segmentedConstrolInitialValue = getSegmentedDateRangeValue(
    periodFilterValue,
    min,
    notViewingLatestData,
  );

  const [segmentedControlValue, setSegmentedControlValue] = useState<DateRangeType>(
    segmentedConstrolInitialValue,
  );

  const [useCustomLabel, setUseCustomLabel] = useState(
    (DATE_RANGE_TYPES as unknown as string[]).includes(segmentedControlValue),
  );

  useEffect(() => {
    segmentedConstrolInitialValue && setSegmentedControlValue(segmentedConstrolInitialValue);
    setUseCustomLabel(segmentedConstrolInitialValue !== 'custom');
  }, [notViewingLatestData]);

  const handleSubmit = (range: DateRange) => {
    const dateRange = clampRange(range, min, max);

    const filters = rangeToFilters(dateRange);

    props.onSubmit(filters);
  };

  const hideSegment = (value: DateRangeType): boolean => {
    const dateRange = getSegmentedDateRange(value, max);
    const range = clampRange(dateRange, min, max);
    const disabled = min >= range.from && max !== dateRange.from;
    return disabled;
  };

  const handleOnCancel = () => {
    if (previousSegmentedControlValue !== 'custom') {
      setSegmentedControlValue(previousSegmentedControlValue);
    }
  };

  const handleOnChange = (value: DateRangeType) => {
    setPreviousSegmentedControlValue(segmentedControlValue);
    setSegmentedControlValue(value);

    // Small timeout to allow segmented control to repaint before setting filter
    setTimeout(() => {
      if (value === 'custom') {
        setPopoverOpen(true);
      } else {
        const dateRange = getSegmentedDateRange(value, max);
        setPopoverOpen(false);
        setUseCustomLabel(true);
        handleSubmit(dateRange);
      }

      onChange?.(value);
    }, 25);
  };

  useClickOutside(
    () => {
      setPopoverOpen(false);
      handleOnCancel();
    },
    null,
    [dropdown, segmentedControl],
  );

  const segmentedControlData: { label: JSX.Element; value: DateRangeType }[] = [
    {
      label: (
        <AccTooltip tooltip={getTooltipDate('yesterday', min, max)}>
          <div>{t('Yesterday')}</div>
        </AccTooltip>
      ),
      value: 'yesterday',
    },

    {
      label: (
        <AccTooltip tooltip={getTooltipDate('last-week', min, max)}>
          <div>{t('1w')}</div>
        </AccTooltip>
      ),
      value: 'last-week',
    },

    {
      label: (
        <AccTooltip tooltip={getTooltipDate('last-two-weeks', min, max)}>
          <div>{t('2w')}</div>
        </AccTooltip>
      ),
      value: 'last-two-weeks',
    },

    {
      label: (
        <AccTooltip tooltip={getTooltipDate('last-month', min, max)}>
          <div>{t('1m')}</div>
        </AccTooltip>
      ),
      value: 'last-month',
    },

    {
      label: (
        <AccTooltip tooltip={getTooltipDate('last-year', min, max)}>
          <div>{t('1y')}</div>
        </AccTooltip>
      ),
      value: 'last-year',
    },

    {
      label: (
        <AccTooltip tooltip={getTooltipDate('initial', min, max)}>
          <div>{t('Initial')}</div>
        </AccTooltip>
      ),
      value: 'initial',
    },
    {
      label: (
        <AccTooltip tooltip={t('Select a custom date range')}>
          <Flex
            onClick={() => {
              setPopoverOpen(!popoverOpen);
              if (segmentedControlValue === 'custom') {
                setPreviousSegmentedControlValue('custom');
              }
            }}
            gap={4}
          >
            {useCustomLabel ? <div>{t('Custom')}</div> : <div>{props.label || t('Custom')}</div>}
            <IconCalendar size={16} />
          </Flex>
        </AccTooltip>
      ),
      value: 'custom',
    },
  ];

  const segmentedControlDataFiltered = segmentedControlData.filter((item) => {
    if (!hideSegment(item.value)) {
      return item;
    }
  });

  const hideSegmententedControl = hideSegment('yesterday');

  if (hideSegmententedControl) {
    return (
      <Flex gap="xxs" ml={32}>
        <AccText variant="label" size="sm" nowrap>
          {t('Next scheduled refresh:')}
        </AccText>
        <AccText size="sm" nowrap>
          {getNextScheduledRefresh(props.domainInfo)}
        </AccText>
      </Flex>
    );
  }

  if (!segmentedControlDataFiltered.map((item) => item.value).includes(segmentedControlValue)) {
    setSegmentedControlValue('yesterday');
  }

  return (
    <Flex align="center" gap="xs" ml="xxl">
      <AccText size="sm" nowrap className={styles.hideOnSmallScreens}>
        {useCustomLabel ? t('Comparing data to:') : t('Comparing data from:')}
      </AccText>
      <Popover
        opened={popoverOpen}
        withArrow
        arrowOffset={48}
        position="top-end"
        withinPortal
        shadow="md"
        transitionProps={{ duration: 0 }}
      >
        <Popover.Target>
          <Flex ref={setSegmentedControl}>
            <AccSegmentedControl
              size="xs"
              radius={4}
              onChange={(value) => {
                handleOnChange(value as DateRangeType);
              }}
              data={segmentedControlDataFiltered}
              value={segmentedControlValue}
            />
          </Flex>
        </Popover.Target>
        <Popover.Dropdown>
          <CompareToContent
            ref={setDropdown}
            domainInfo={props.domainInfo}
            min={min}
            max={max}
            onSubmit={(e) => {
              props.onSubmit(e);
              setUseCustomLabel(false);
            }}
            periodFilter={props.periodFilter}
            onlyPeriodFilter={props.onlyPeriodFilter}
            message={props.message}
            onCancel={handleOnCancel}
            closePopover={() => {
              setPopoverOpen(false);
            }}
            openPopover={() => {
              setPopoverOpen(true);
            }}
          />
        </Popover.Dropdown>
      </Popover>
    </Flex>
  );
};
