import { FilterAttribute, FilterComparison, FilterValueType, PeriodFilter } from 'Types/Filter';

export enum TableOrder {
  ASC = 'ASC',
  DESC = 'DESC',
}

export const fakeTableDetails = {
  fakePagination: {
    page: 1,
    results: 100,
  },
  fakeOrdering: {
    order: TableOrder.ASC,
    orderBy: 'keyword',
  },
};

export const fakePeriodFilter: PeriodFilter = {
  attribute: FilterAttribute.PERIOD,
  comparison: FilterComparison.BETWEEN,
  type: FilterValueType.DATETIME,
  value: '["1970-01-01","latest"]',
};
