import { useApolloClient } from '@apollo/client';
import { AddKeywordsMode } from 'Components/Modal/Content/AddKeywords';
import { KeydisImportConfig } from 'Components/Modal/Content/AddKeywords/support/types';
import { KeydisImportInfoDocument } from 'Ghql';
import { useModal } from 'Hooks/base/useModal';
import { useDomainId } from 'Hooks/data/domain/useQueryDomainInfo';
import type { FilterBase } from 'Types/Filter';

export const useKeydisImportModal = ({
  filters,
  keywordCount,
}: {
  filters: FilterBase[];
  keywordCount: number;
}) => {
  const domainId = useDomainId();
  const client = useApolloClient();

  const { showModal } = useModal();

  const importConfig: KeydisImportConfig = {
    excludeKeywords: undefined,
    includeKeywords: undefined,
    nKeywordsToAdd: keywordCount,
    ordering: undefined, // Ordering shouldn't matter as we're importing everything matching filters
  };

  return () => {
    showModal({
      modalType: 'AddKeywords',
      modalTheme: 'light',
      modalProps: {
        domainId,
        mode: AddKeywordsMode.IMPORT,
        getKeydisKeywords: () =>
          client.query({
            fetchPolicy: 'network-only',
            query: KeydisImportInfoDocument,
            variables: {
              filters,
            },
          }),
        keydisKeywordsCounter: keywordCount,
        overwriteFilters: filters,
        importConfig,
      },
    });
  };
};
