import UrlWithStatusCell from 'Components/AccuTable/CellRenderer/CellUrlWithStatus/CellUrlWithStatus';
import AboveTheFoldCell from 'Components/AccuTable/CellRenderer/aboveTheFold';
import CompetitionCell from 'Components/AccuTable/CellRenderer/competition';
import CPCCell from 'Components/AccuTable/CellRenderer/cpc';
import CTRCell from 'Components/AccuTable/CellRenderer/ctr';
import MaxCTRCell from 'Components/AccuTable/CellRenderer/ctrMax';
import KeywordCell from 'Components/AccuTable/CellRenderer/keyword';
import RankCell from 'Components/AccuTable/CellRenderer/rank';
import SearchIntentCell from 'Components/AccuTable/CellRenderer/searchIntent';
import SearchVolumeCell from 'Components/AccuTable/CellRenderer/searchVolume';
import SerpCell, { SerpFeatureNode } from 'Components/AccuTable/CellRenderer/serp';
import ShareOfVoiceCell from 'Components/AccuTable/CellRenderer/shareOfVoice';
import TrafficCell from 'Components/AccuTable/CellRenderer/traffic';
import { ColumnType, withCellContext, withRowContext } from 'Components/DataTable';
import linkWithFilters from 'Components/Filters/linkWithFilters';
import withProps from 'Components/HOC/withProps';
import { KeywordInfoProps, MenuItemIds } from 'Components/Modal/Content/KeywordInfo';
import { UpdateKeywordsTagsParams } from 'Hooks/useKeyword';
import { FilterAttribute, FilterComparison, FilterValueType } from 'Types/Filter';
import { KEYWORDS_FILTER_SET } from 'Types/FilterSet';
import { StoreMetaData } from 'Types/Store';
import { t } from 'Utilities/i18n';
import { keywordOrderConfig } from './hooks/keyword/constants';
import { KeywordColumnID } from './support/constants';

interface GetKeydisCellsProps {
  showKeywordModal: (params: Omit<KeywordInfoProps, 'scrollElement'>) => void;
  updateKeywordsTags: (params: UpdateKeywordsTagsParams) => Promise<any>;
  language: string | null;
  metaData: StoreMetaData;
  history: Record<string, any>;
  displayCurrency: string | undefined;
  userHasWriteAccess: boolean;
}

export const getKeydisCells = ({
  showKeywordModal,
  updateKeywordsTags,
  language,
  metaData,
  history,
  displayCurrency,
  userHasWriteAccess,
}: GetKeydisCellsProps) => {
  const checkBoxColumn: ColumnType = {
    id: KeywordColumnID.CHECKBOX,
    width: 40,
    fixed: 'left',
    onHeaderCell: () => ({
      type: 'checkbox',
    }),
    onCell: (record: any) => ({
      type: 'checkbox',
      data: record,
    }),
  };
  const keywordTableColumns: ColumnType[] = [
    {
      id: KeywordColumnID.KEYWORD,
      combineRow: KeywordColumnID.SEARCH_INTENT,
      title: t('Keyword'),
      onHeaderCell: () => ({
        tooltip: t('Sort by keyword'),
        filter: {
          filterAttributes: [FilterAttribute.KEYWORD, FilterAttribute.TAGS],
          filterTooltip: t('Filter keyword…'),
          filterId: 'keyword-filter',
        },
        ordering: keywordOrderConfig.keyword,
      }),
      width: 235,
      fixed: 'left',
      flex: true,
      cellRenderer: withCellContext<any>(KeywordCell as any),
      cellRendererParams: {
        onTagRemoveClick: (id: number, tag: any) =>
          updateKeywordsTags({
            keywords: [id],
            tags: [tag],
            remove: true,
          }),
        onTagClick: (_domainId: string, tag: any) => {
          const tagFilter = {
            attribute: FilterAttribute.TAGS,
            type: FilterValueType.ARRAY,
            comparison: tag ? FilterComparison.ANY : FilterComparison.EMPTY,
            value: [tag],
          };

          history.push(
            linkWithFilters({
              to: '/keywords/keyword_discovery',
              newFilters: [tagFilter],
              filterSet: KEYWORDS_FILTER_SET,
            }),
          );
        },
        showOptions: true,
        isLink: false,
      },
    },
    {
      id: KeywordColumnID.SEARCH_INTENT,
      title: 'SI',
      onHeaderCell: () => ({
        tooltip: t('Sort by search intent'),
        filter: {
          filterAttributes: [FilterAttribute.SEARCH_INTENT],
          filterTooltip: t('Filter search intent…'),
        },
        ordering: keywordOrderConfig.searchIntent,
      }),
      width: 75,
      cellRenderer: SearchIntentCell,
    },
    {
      id: KeywordColumnID.RANK,
      title: t('Rank'),
      onHeaderCell: () => ({
        tooltip: t('Sort by rank'),
        ordering: keywordOrderConfig.rank,
        filter: {
          filterAttributes: [FilterAttribute.RANK],
          filterTooltip: t('Filter rank…'),
          hasMediumFilter: true,
          filterLabel: t('Rank column filters'),
        },
      }),
      width: language === 'da' ? 115 : 85,
      cellRenderer: withRowContext(RankCell as any),
    },
    {
      id: KeywordColumnID.ABOVE_THE_FOLD,
      title: `${t('Above the fold')}`,
      onHeaderCell: () => ({
        tooltip: t('Sort by Above the fold'),
        ordering: keywordOrderConfig.aboveTheFold,
        // Filter don't work for tested example
        // filter: {
        //   filterAttributes: [FilterAttribute.ABOVE_THE_FOLD],
        //   filterTooltip: t('Add filter for Above the fold'),
        // },
      }),
      width: language === 'da' ? 95 : 110,
      cellRenderer: AboveTheFoldCell,
    },
    {
      id: KeywordColumnID.URL,
      flex: true,
      title: t('URL'),
      onHeaderCell: () => ({
        tooltip: t('Sort by url'),
        filter: {
          filterAttributes: [FilterAttribute.HIGHEST_RANKING_PAGE],
          filterTooltip: t('Filter url…'),
        },
        ordering: keywordOrderConfig.url,
      }),
      width: 290,
      cellRenderer: withRowContext(withProps({ hideStatus: true })(UrlWithStatusCell) as any),
    },
    {
      id: KeywordColumnID.SEARCH_VOLUME,
      title: t('Searches'),
      onHeaderCell: () => ({
        tooltip: t('Sort by average monthly search volume'),
        filter: {
          filterAttributes: [FilterAttribute.SEARCH_VOLUME],
          filterTooltip: t('Filter searches…'),
        },
        ordering: keywordOrderConfig.searchVolume,
      }),
      width: language === 'da' ? 115 : 105,
      cellRenderer: withRowContext(SearchVolumeCell as any),
    },
    {
      id: KeywordColumnID.CPC,
      title: `${t('CPC')} (${displayCurrency})`,
      onHeaderCell: () => ({
        tooltip: t('Sort by average cost per click'),
        ordering: keywordOrderConfig.cpc,
      }),
      width: 85,
      cellRenderer: withRowContext(CPCCell as any),
    },

    {
      id: KeywordColumnID.DYNAMIC_CTR,
      combineRow: KeywordColumnID.DYNAMIC_CTR_MAX,
      title: t('CTR'),
      onHeaderCell: () => ({
        tooltip: t('Sort by click-through rate'),
        ordering: keywordOrderConfig.dynamicCtr,
      }),
      width: 50,
      cellRenderer: CTRCell,
    },
    {
      id: KeywordColumnID.DYNAMIC_CTR_MAX,
      combineRow: KeywordColumnID.DYNAMIC_CTR_POTENTIAL,
      title: t('Max'),
      onHeaderCell: () => ({
        tooltip: t('Sort by max possible click-through rate'),
        ordering: keywordOrderConfig.dynamicCtrMax,
        filter: {
          filterAttributes: [FilterAttribute.DYNAMIC_CTR, FilterAttribute.DYNAMIC_CTR_MAX],
          filterTooltip: t('Filter by CTR…'),
        },
      }),
      width: 80,
      cellRenderer: MaxCTRCell,
    },
    {
      id: KeywordColumnID.ORGANIC_TRAFFIC,
      title: t('AI SoV'),
      combineRow: KeywordColumnID.ORGANIC_TRAFFIC_CHANGE,
      onHeaderCell: () => ({
        tooltip: t('Sort by AI Share of Voice'),
        ordering: keywordOrderConfig.organicTraffic,
        filter: {
          filterAttributes: [FilterAttribute.ORGANIC_TRAFFIC],
          filterTooltip: t('Filter by AI Share of Voice…'),
        },
      }),
      width: 115,
      cellRenderer: TrafficCell,
    },
    {
      id: KeywordColumnID.AD_COMPETITION,
      title: t('Comp'),
      onHeaderCell: () => ({
        tooltip: t('Google Ads competition for this keyword'),
        ordering: keywordOrderConfig.adCompetition,
      }),
      width: language === 'da' ? 85 : 80,
      cellRenderer: CompetitionCell,
    },
    {
      id: KeywordColumnID.SHARE_OF_VOICE,
      title: t('SoV'),
      onHeaderCell: () => ({
        tooltip: t('Sort by share of voice'),
        ordering: keywordOrderConfig.sov,
        filter: {
          filterAttributes: [FilterAttribute.SHARE_OF_VOICE],
          filterTooltip: t('Filter change in SoV…'),
        },
      }),
      width: language === 'da' ? 110 : 80,
      cellRenderer: ShareOfVoiceCell,
    },
    {
      id: KeywordColumnID.SERP,
      title: t('SERP features'),
      onHeaderCell: () => ({
        tooltip: t('Sort by features on the SERP'),
        filter: {
          filterAttributes: [
            FilterAttribute.PAGE_SERP_FEATURES,
            FilterAttribute.PAGE_SERP_FEATURES_RANK,
          ],
          hasLargeFilter: true,
          containerClassName: 'serp-features-modal',
          filterTooltip: t('Filter features on the SERP…'),
        },
        ordering: keywordOrderConfig.serp,
      }),
      width: language === 'da' ? 147 : 140,
      cellRenderer: SerpCell,
      cellRendererParams: {
        serpFeatures: metaData?.serpFeatures,
        onClick: (
          id: string,
          _domainId: string,
          serp: string,
          serpFeaturesData: SerpFeatureNode[],
        ) => {
          showKeywordModal({
            keywordId: id,
            domainId: _domainId,
            initialTab: MenuItemIds.TAB_SERP_FEATURES,
            selectedSerp: serp,
            serpFeaturesData,
            isKeyDis: true,
          });
        },
        isKeyDis: true,
      },
    },
  ] as ColumnType[];
  if (userHasWriteAccess) {
    return [checkBoxColumn, ...keywordTableColumns] as ColumnType[];
  }
  return keywordTableColumns as ColumnType[];
};
