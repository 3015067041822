import React, { HTMLAttributeAnchorTarget } from 'react';
import { Link } from 'react-router-dom';

type Props = {
  children: React.ReactNode;
  to?: string;
  target?: HTMLAttributeAnchorTarget;
};

const ConditionalLink = (props: Props) => {
  const { children, to, target } = props;

  return to ? (
    <Link to={to} target={target}>
      {children}
    </Link>
  ) : (
    <>{children}</>
  );
};

export default ConditionalLink;
