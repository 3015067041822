import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import AccAlert from 'Components/AccAlert/AccAlert';
import { PageIdToLink, linkToPageWithDomains } from 'Components/Filters/LinkToDomain';
import AccText from 'Components/Text/AccText';
import { KEYWORDS_FILTER_SET } from 'Types/FilterSet';
import { t, tct } from 'Utilities/i18n';
import { useQueryIsDemoClient } from '../../../../Hooks/data/client/useQueryIsDemoClient';
import { useQueryDomainInfo } from '../../../../Hooks/data/domain/useQueryDomainInfo';
import { selectDomainList } from '../../../../Selectors/DomainSelector';
import { useSmartRouterSubscription } from '../../../../Utilities/Router/components/LocationPathnameContext';

const DemoDomainBanner = () => {
  const domainListId = useSelector(selectDomainList);
  const disableDemoBanner = useSmartRouterSubscription((pathname) =>
    pathname?.includes('/welcome'),
  );
  const { domainInfo } = useQueryDomainInfo();
  const [isDemoDomain, demoDomainBannerInfo] = [
    domainInfo?.isDemoDomain,
    domainInfo?.demoDomainBannerInfo ?? {},
  ];
  const { demoClientBannerInfo, isDemoClient } = useQueryIsDemoClient(domainListId, isDemoDomain);
  const isGroup = isDemoClient ?? false;
  // The group and domains views use the same route, so we have to query both in order to check
  // if we are either on a demo client (group) or demo domain
  const {
    domainCount = 0,
    domainId,
    domainName,
  } = isDemoDomain ? demoDomainBannerInfo : demoClientBannerInfo;
  const navigate = useNavigate();

  if (!domainCount || disableDemoBanner) {
    return null;
  }
  const LinkText = ({ text, link }: { text: string; link?: string }) => (
    <AccText
      variant="link"
      inline
      onClick={() => (link ? navigate(link) : navigate('/domains'))}
      fw={600}
      size="sm"
    >
      {text}
    </AccText>
  );
  const singleDomain = tct('[domain] cannot be edited. You can edit your own domain [link].', {
    link: (
      <LinkText
        text={domainName || t('here')}
        link={
          domainId
            ? linkToPageWithDomains(PageIdToLink.overview, domainId, KEYWORDS_FILTER_SET, [])
            : ''
        }
      />
    ),
    domain: isGroup ? t('These domains') : t('This domain'),
  });

  const multipleDomains = tct('[domain] cannot be edited. [link] to see your own domains', {
    link: <LinkText text={t('Click here')} />,
    domain: isGroup ? t('These domains') : t('This domain'),
  });

  const noDomains = tct('[domain] cannot be edited. [link] to add your own domain.', {
    link: <LinkText text={t('Click here')} />,
    domain: isGroup ? t('These domains') : t('This domain'),
  });

  return (
    <AccAlert
      mt="1rem"
      severity="info"
      singleLine
      title={isGroup ? t('Demo domains.') : t('Demo domain.')}
    >
      <AccText size="sm" fw={400}>
        {domainCount === 1 ? singleDomain : (domainCount || 0) > 1 ? multipleDomains : noDomains}
      </AccText>
    </AccAlert>
  );
};

export default DemoDomainBanner;
