import { useEffect, useState } from 'react';
import cookie from 'react-cookies';
import { Box, Center, Flex } from '@mantine/core';
import {
  IconChevronRight,
  IconPlayerPause,
  IconTableOptions,
  IconWorldWww,
} from '@tabler/icons-react';
import AccButton from 'Components/AccButton/AccButton';
import AccSegmentedControl from 'Components/AccSegmentedControl/AccSegmentedControl';
import AccessControl from 'Components/AccessControl';
import { HomeButton, LastBreadcrumbChild } from 'Components/Breadcrumbs/Breadcrumbs';
import Filters from 'Components/Filters';
import PageHeaderContainer from 'Components/PageHeaderContainer';
import { ViewMode } from 'Components/ViewMode';
import { useModal } from 'Hooks/base/useModal';
import {
  useHasMultiGSCConnectAccess,
  useHasMultiGaConnectAccess,
} from 'Hooks/data/organization/useOrganizationInfo';
import { useUser } from 'Hooks/data/user/useUser';
import { useGa4Tracking } from 'Hooks/useConversionTracking';
import DomainsTable from 'Pages/Domains/DomainsTable';
import * as Actions from 'Pages/Layout/ActionsMenu/Actions';
import ActionbarContainer from 'Pages/Layout/ActionsMenu/components/ActionbarContainer';
import { PeriodFilterContainer } from 'Pages/Layout/ActionsMenu/components/PeriodFilterContainer';
import PausedDomainsTable from 'Pages/PausedDomains';
import { TableIDs } from 'Types/Table';
import { TrackingKey } from 'Utilities/Analytics/mixpanel';
import { t } from 'Utilities/i18n';

const useGa4TrackLogin = () => {
  const [ga4TrackLoginSent, setGa4TrackLoginSent] = useState(false);
  const user = useUser();
  useEffect(() => {
    if (ga4TrackLoginSent) return;
    if (!user.isAuthenticated) return;

    const storedUserId = cookie.load('ga4_login_user_id');
    if (storedUserId === user.id) {
      setGa4TrackLoginSent(true);
      return;
    }

    window.gtag('event', 'login', {
      userId: user.id,
    });
    if (user.id !== null && user.id !== undefined) {
      cookie.save('ga4_login_user_id', user.id, { path: '/' });
    }
    setGa4TrackLoginSent(true);
  }, [ga4TrackLoginSent, user.id, user.isAuthenticated]);
};

const DomainsPage = () => {
  useGa4Tracking();
  useGa4TrackLogin();

  const { showModal } = useModal();

  const [display, setDisplay] = useState<'domains' | 'paused'>('domains');

  const user = useUser();

  const userDomainsWithNoGA =
    (user.organization?.numberOfDomains || 0) - (user.organization?.numberOfDomainsWithGa || 0);

  const userDomainsWithNoGSC =
    (user.organization?.numberOfDomains || 0) - (user.organization?.numberOfDomainsWithGwt || 0);

  const isMultiConnectEnabled = useHasMultiGaConnectAccess();

  const isMultiGSCConnectEnabled = useHasMultiGSCConnectAccess();

  const showBulkGAButton = userDomainsWithNoGA > 1 && isMultiConnectEnabled;

  const showBulkGSCButton = userDomainsWithNoGSC > 1 && isMultiGSCConnectEnabled;

  const handleAddDomain = () => {
    showModal({
      modalType: 'BuildDomain',
      modalTheme: 'light',
    });
  };

  const handleConnectToAnalytics = () => {
    showModal({
      modalType: 'ConnectToGA',
      modalTheme: 'light',
      modalProps: {
        bulkConnect: true,
      },
    });
  };

  const handleConnectToGSC = () =>
    showModal({
      modalType: 'ConnectToGSC',
      modalTheme: 'light',
      modalProps: {
        bulkConnect: true,
      },
    });

  const handleOpenTableSettings = () => {
    showModal({
      modalType: 'TableSettingsModal',
      modalTheme: 'light',
      modalProps: {
        tableName: TableIDs.DOMAINS,
      },
    });
  };

  const segmentedControlData = [
    {
      label: (
        <Center>
          <IconWorldWww size={14} />
          <Box ml="xs">{t('Domains')}</Box>
        </Center>
      ),
      value: 'domains',
    },
    {
      label: (
        <Center>
          <IconPlayerPause size={14} />
          <Box ml="xs">{t('Paused Domains')}</Box>
        </Center>
      ),
      value: 'paused',
    },
  ];

  return (
    <>
      <Flex justify="space-between">
        <PageHeaderContainer breadcrumbs>
          <HomeButton />
          <IconChevronRight />
          <LastBreadcrumbChild>{t('Domains')}</LastBreadcrumbChild>
        </PageHeaderContainer>
        <PeriodFilterContainer />
      </Flex>
      <Filters disabled={display !== 'domains'} />
      <ActionbarContainer>
        <AccessControl>
          <Actions.AddAction key="add" label={t('Add domain')} onClick={handleAddDomain} />
          {showBulkGAButton && (
            <Actions.ConnectToAnalyticsAction
              label={t('Connect domains to Analytics')}
              key="addAnalytics"
              onClick={handleConnectToAnalytics}
            />
          )}
          {showBulkGSCButton && (
            <Actions.ConnectToGSCAction
              label={t('Connect domains to Search Console')}
              key="addGSC"
              onClick={handleConnectToGSC}
            />
          )}
        </AccessControl>
        {display === 'domains' ? (
          <AccButton
            leftSection={<IconTableOptions size={20} />}
            trackingKey={TrackingKey.TableSettings}
            variant="tertiary"
            onClick={handleOpenTableSettings}
            ml="auto"
          >
            {t('Configure columns')}
          </AccButton>
        ) : (
          <div style={{ marginLeft: 'auto' }} />
        )}
        <ViewMode tableName={TableIDs.DOMAINS} />

        {user.organization?.activePlan?.featureCanPause && (
          <AccSegmentedControl
            value={display}
            onChange={(value) => setDisplay(value as typeof display)}
            data={segmentedControlData}
            transitionDuration={100}
          />
        )}
      </ActionbarContainer>
      {display === 'domains' ? <DomainsTable /> : <PausedDomainsTable />}
    </>
  );
};

export default DomainsPage;
