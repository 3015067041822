import ConditionalTooltipWrapper from 'Components/ConditionalTooltipWrapper/ConditionalTooltipWrapper';
import RefreshKeywords from 'Components/RefreshKeywords';
import { ViewMode } from 'Components/ViewMode';
import { getDisabledDemoText } from 'Constants/messages';
import queryDomainInfo from 'Pages/queryDomainInfo';
import { DomainInfo } from 'Query';
import ActionMenuList from './ActionMenuList';
import { WithKeywordActionMenuDataProps } from './withKeywordActionMenuData';

interface KeywordActionMenuProps {
  tableName: string;
  domainId: string;
  domainInfo?: DomainInfo;
  columnsSettingsName: any;
}

const KeywordActionMenu = ({
  tableName,
  domainInfo,
  domainId,
  columnsSettingsName,
}: KeywordActionMenuProps & WithKeywordActionMenuDataProps) => {
  return (
    <>
      <ActionMenuList
        tableName={tableName}
        domainInfo={domainInfo}
        domainId={domainId}
        columnsSettingsName={columnsSettingsName}
      />
      <ViewMode tableName={tableName} />
      <ConditionalTooltipWrapper
        tooltip={domainInfo?.isDemoDomain ? getDisabledDemoText() : undefined}
      >
        <RefreshKeywords disabled={domainInfo?.isDemoDomain} />
      </ConditionalTooltipWrapper>
    </>
  );
};

export default queryDomainInfo()(KeywordActionMenu);
