import { useCallback } from 'react';
import { useStore } from 'react-redux';
import { useApolloClient } from '@apollo/client';
import { TableFetchDataCallBack } from 'Components/DataTable';
import { DomainsDocument, DomainsQuery, DomainsQueryVariables } from 'Ghql';
import { selectCurrentFilters } from 'Selectors/FilterSelector';

// eslint-disable-next-line import/no-unused-modules
export const useFetchDomainsData = (): TableFetchDataCallBack => {
  const client = useApolloClient();
  const store = useStore();

  return useCallback(
    async (params) => {
      const filters = selectCurrentFilters(store.getState());
      const variables: DomainsQueryVariables = {
        filters,
        pagination: params.pagination,
        ordering: params.ordering,
      };
      const e = await client.query<DomainsQuery, DomainsQueryVariables>({
        query: DomainsDocument,
        variables,
        fetchPolicy: 'network-only',
      });
      const data = e?.data?.tableDashboard?.domains || [];
      const totalLength = e?.data?.tableDashboard?.pagination?.numResults ?? 0; //e?.data?.tableDomains?.domains?.length ?? data.length;
      return { data, length: totalLength };
    },
    [client, store],
  );
};
