import React, { CSSProperties, useMemo } from 'react';
import { Box } from '@mantine/core';
import {
  IconChartArea,
  IconChartArrows,
  IconChartBar,
  IconChartBubble,
  IconChartLine,
  IconTable,
} from '@tabler/icons-react';
import ErrorBoundary from 'Components/ErrorBoundary';
import { ChartBoxContext } from 'Pages/Keywords/Overview/components/ChartBoxContext/index';
import { ChartPlaceholder } from 'Pages/Keywords/Overview/components/ChartPlaceholder/index';
import { CompetitorsBubbleChart } from 'Pages/Keywords/Overview/components/CompetitorsBubbleChart';
import { GoogleAnalyticsTraffic } from 'Pages/Keywords/Overview/components/GoogleAnalyticsTraffic/GoogleAnalyticsTraffic';
import { AiTrafficValueChart } from 'Pages/Keywords/Overview/components/HistoryCharts/AiTrafficValue/TrafficValue';
import { AverageBaseRankChart } from 'Pages/Keywords/Overview/components/HistoryCharts/AverageBaseRank/AverageBaseRankChart';
import { AverageCtrChart } from 'Pages/Keywords/Overview/components/HistoryCharts/AverageCTR/AverageCtrChart';
import { AverageRankChart } from 'Pages/Keywords/Overview/components/HistoryCharts/AverageRank/AverageRankChart';
import { BaseRankingDistribution } from 'Pages/Keywords/Overview/components/HistoryCharts/BaseRankingDistribution/BaseRankingDistributionChart';
import { EstimatedVisitsChart } from 'Pages/Keywords/Overview/components/HistoryCharts/EstimatedVisits/EstimatedVisits';
import { PixelsFromTopChart } from 'Pages/Keywords/Overview/components/HistoryCharts/PixelsFromTop/PixelsFromTop';
import { RankingDistribution } from 'Pages/Keywords/Overview/components/HistoryCharts/RankingDistribution/RankingDistributionChart';
import { SovChart } from 'Pages/Keywords/Overview/components/HistoryCharts/ShareOfVoice/SovChart';
import { TrafficValueChart } from 'Pages/Keywords/Overview/components/HistoryCharts/TrafficValue/TrafficValue';
import { OverviewNotifications } from 'Pages/Keywords/Overview/components/OverviewNotifications/OverviewNotifications';
import SearchIntent from 'Pages/Keywords/Overview/components/SearchIntent';
import { SearchTrend } from 'Pages/Keywords/Overview/components/SearchTrend/SearchTrend';
import {
  AIShareOfVoiceContainer,
  ShareOfVoiceContainer,
} from 'Pages/Keywords/Overview/components/ShareOfVoiceByType/ShareOfVoiceContainer';
import UnknownCompetitorTabs from 'Pages/Keywords/Overview/components/UnknownCompetitorTabs';
import {
  AiWinnersAndLosers,
  WinnersAndLosers,
} from 'Pages/Keywords/Overview/components/WinnersLosers';
import { t } from 'Utilities/i18n';

export const CHART_NAMES = {
  SHARE_OF_VOICE: 'Share of Voice',
  AVERAGE_RANK: 'Average Rank',
  RANKING_DISTRIBUTION: 'Ranking Distribution',
  COMPETITORS: 'Competitors',
  SEARCH_INTENT: 'Search Intent',
  SHARE_OF_VOICE_BY: 'Share of Voice, by',
  AI_SHARE_OF_VOICE_BY: 'AI Share of Voice, by',
  DYNAMIC_COMPETITORS_BY: 'Dynamic Competitors, by',
  WINNERS_AND_LOSERS: 'Top Winners/Losers',
  AI_WINNERS_AND_LOSERS: 'Top AI Winners/Losers',
  GOOGLE_ANALYTICS: 'Organic Traffic (GA/GSC)',
  SEARCH_TREND: 'Search Trend',
  AVERAGE_CTR: 'Average CTR',
  ESTIMATED_VISITS: 'Estimated Visits',
  TRAFFIC_VALUE: 'Traffic Value',
  NOTIFICATIONS: 'Notifications',
  AVERAGE_BASE_RANK: 'Average Base Rank',
  AI_TRAFFIC_VALUE: 'AI Traffic Value',
  BASE_RANKING_DISTRIBUTION: 'Base Ranking Distribution',
  PIXELS_FROM_TOP: 'Pixels From Top',
  PLACEHOLDER: 'Placeholder',
  NONE: 'None',
};

export const GET_CHART_DISPLAY_NAME = () => ({
  [CHART_NAMES.SHARE_OF_VOICE]: t('Share of Voice'),
  [CHART_NAMES.AVERAGE_RANK]: t('Average Rank'),
  [CHART_NAMES.RANKING_DISTRIBUTION]: t('Ranking Distribution'),
  [CHART_NAMES.COMPETITORS]: t('Competitors'),
  [CHART_NAMES.SEARCH_INTENT]: t('Search Intent'),
  [CHART_NAMES.SHARE_OF_VOICE_BY]: t('Share of Voice, by'),
  [CHART_NAMES.AI_SHARE_OF_VOICE_BY]: t('AI Share of Voice, by'),
  [CHART_NAMES.DYNAMIC_COMPETITORS_BY]: t('Dynamic Competitors, by'),
  [CHART_NAMES.WINNERS_AND_LOSERS]: t('Top Winners/Losers'),
  [CHART_NAMES.AI_WINNERS_AND_LOSERS]: t('Top AI Winners/Losers'),
  [CHART_NAMES.GOOGLE_ANALYTICS]: t('Organic Traffic (GA/GSC)'),
  [CHART_NAMES.SEARCH_TREND]: t('Search Trend'),
  [CHART_NAMES.AVERAGE_CTR]: t('Average CTR'),
  [CHART_NAMES.ESTIMATED_VISITS]: t('AI Share of Voice'),
  [CHART_NAMES.TRAFFIC_VALUE]: t('Traffic Value'),
  [CHART_NAMES.NOTIFICATIONS]: t('Notifications'),
  [CHART_NAMES.AVERAGE_BASE_RANK]: t('Average Base Rank'),
  [CHART_NAMES.AI_TRAFFIC_VALUE]: t('AI Traffic Value'),
  [CHART_NAMES.BASE_RANKING_DISTRIBUTION]: t('Base Ranking Distribution'),
  [CHART_NAMES.PIXELS_FROM_TOP]: t('Pixels From Top'),
  [CHART_NAMES.NONE]: t('None'),
});

const ICON_SIZE = 32;

export const CHART_MENU = {
  [CHART_NAMES.SHARE_OF_VOICE]: <IconChartLine size={ICON_SIZE} color={'#F89537'} />,
  [CHART_NAMES.AVERAGE_RANK]: <IconChartLine size={ICON_SIZE} color={'#4967BF'} />,
  [CHART_NAMES.RANKING_DISTRIBUTION]: <IconChartArea size={ICON_SIZE} color={'#06378B'} />,
  [CHART_NAMES.COMPETITORS]: <IconChartBubble size={ICON_SIZE} color={'#63AF6B'} />,
  [CHART_NAMES.SEARCH_INTENT]: <IconChartArrows size={ICON_SIZE} color={'#B14FB1'} />,
  [CHART_NAMES.SHARE_OF_VOICE_BY]: <IconChartArrows size={ICON_SIZE} color={'#4967BF'} />,
  [CHART_NAMES.AI_SHARE_OF_VOICE_BY]: <IconChartArrows size={ICON_SIZE} color={'#4967BF'} />,
  [CHART_NAMES.DYNAMIC_COMPETITORS_BY]: <IconChartArrows size={ICON_SIZE} color={'#4967BF'} />,
  [CHART_NAMES.WINNERS_AND_LOSERS]: <IconTable size={ICON_SIZE} color={'#55AB68'} />,
  [CHART_NAMES.AI_WINNERS_AND_LOSERS]: <IconTable size={ICON_SIZE} color={'#55AB68'} />,
  [CHART_NAMES.GOOGLE_ANALYTICS]: <IconChartBar size={ICON_SIZE} color={'#f89734'} />,
  [CHART_NAMES.SEARCH_TREND]: <IconChartBar size={ICON_SIZE} color={'#f89734'} />,
  [CHART_NAMES.AVERAGE_CTR]: <IconChartLine size={ICON_SIZE} color={'#49A353'} />,
  [CHART_NAMES.ESTIMATED_VISITS]: <IconChartLine size={ICON_SIZE} color={'#B14FB1'} />,
  [CHART_NAMES.TRAFFIC_VALUE]: <IconChartBar size={ICON_SIZE} color={'#937860'} />,
  [CHART_NAMES.AVERAGE_BASE_RANK]: <IconChartLine size={ICON_SIZE} color={'#D48184'} />,
  [CHART_NAMES.AI_TRAFFIC_VALUE]: <IconChartLine size={ICON_SIZE} color={'#CCB974'} />,
  [CHART_NAMES.BASE_RANKING_DISTRIBUTION]: <IconChartArea size={ICON_SIZE} color={'#06378B'} />,
  [CHART_NAMES.PIXELS_FROM_TOP]: <IconChartLine size={ICON_SIZE} color={'#64B5CD'} />,
};

export const GET_CHART_DESCRIPTION = () => ({
  [CHART_NAMES.SHARE_OF_VOICE]: t(
    'The total Share of Voice for the keywords in the current filtering over time.',
  ),
  [CHART_NAMES.AVERAGE_RANK]: t(
    'The average rank for all keywords in the current filtering and whether it\'s trending upwards or downwards.',
  ),
  [CHART_NAMES.RANKING_DISTRIBUTION]: t(
    'The distribution of how ALL your ranks have developed over time, divided into six specified rank ranges.',
  ),
  [CHART_NAMES.COMPETITORS]: t(
    'The relative size of you and your competitors in three dimensions.',
  ),
  [CHART_NAMES.SEARCH_INTENT]: t(
    'Categorizes all keywords in the current filtering by the primary purpose behind the search.',
  ),
  [CHART_NAMES.SHARE_OF_VOICE_BY]: t(
    'Share of Voice aggregated by: tags, competitors, or landing pages.',
  ),
  [CHART_NAMES.AI_SHARE_OF_VOICE_BY]: t(
    'AI Share of Voice aggregated by: tags, competitors, or landing pages.',
  ),
  [CHART_NAMES.DYNAMIC_COMPETITORS_BY]: t(
    'The biggest competitors for the keywords in the current filtering, analyzed by score, score over time, or ranks.',
  ),
  [CHART_NAMES.WINNERS_AND_LOSERS]: t(
    'The keywords with the largest rank changes, categorized as ‘winners’ or ‘losers’ based on their movement.',
  ),
  [CHART_NAMES.AI_WINNERS_AND_LOSERS]: t(
    'The keywords with the largest rank changes, categorized as ‘winners’ or ‘losers’ based on their movement.',
  ),
  [CHART_NAMES.GOOGLE_ANALYTICS]: t(
    'The historical evolution of traffic from search engines, registered by Google Analytics and Google Search Console.',
  ),
  [CHART_NAMES.SEARCH_TREND]: t(
    'The combined search volume of all your keywords on a monthly basis.',
  ),
  [CHART_NAMES.AVERAGE_CTR]: t(
    'The average Click-through Rate (CTR) for the keywords in the current filtering over time, weighted by their Search Volume.',
  ),
  [CHART_NAMES.ESTIMATED_VISITS]: t(
    'The AI Share of Voice for the keywords in the current filtering over time, estimated based on a machine learning model.',
  ),
  [CHART_NAMES.TRAFFIC_VALUE]: t(
    'The traffic value for the keywords in the current filtering over time, estimated by multiplying the average CPC by SoV.',
  ),
  [CHART_NAMES.AVERAGE_BASE_RANK]: t(
    'The average base rank over time for all keywords in the current filtering before applying any keyword settings.',
  ),
  [CHART_NAMES.AI_TRAFFIC_VALUE]: t(
    'The traffic value for the keywords in the current filtering over time, estimated by multiplying the average CPC by AI SoV.',
  ),
  [CHART_NAMES.BASE_RANKING_DISTRIBUTION]: t(
    'The distribution of how ALL your base ranks have developed over time, divided into six specified rank ranges.',
  ),
  [CHART_NAMES.PIXELS_FROM_TOP]: t(
    'The average distance in pixel from the top of the SERP to the keywords in the current filtering over time.',
  ),
});

const CHART_MAP = {
  [CHART_NAMES.SHARE_OF_VOICE]: SovChart,
  [CHART_NAMES.AVERAGE_RANK]: AverageRankChart,
  [CHART_NAMES.RANKING_DISTRIBUTION]: RankingDistribution,
  [CHART_NAMES.COMPETITORS]: CompetitorsBubbleChart,
  [CHART_NAMES.SEARCH_INTENT]: SearchIntent,
  [CHART_NAMES.SHARE_OF_VOICE_BY]: ShareOfVoiceContainer,
  [CHART_NAMES.AI_SHARE_OF_VOICE_BY]: AIShareOfVoiceContainer,
  [CHART_NAMES.DYNAMIC_COMPETITORS_BY]: UnknownCompetitorTabs,
  [CHART_NAMES.WINNERS_AND_LOSERS]: WinnersAndLosers,
  [CHART_NAMES.AI_WINNERS_AND_LOSERS]: AiWinnersAndLosers,
  [CHART_NAMES.GOOGLE_ANALYTICS]: GoogleAnalyticsTraffic,
  [CHART_NAMES.SEARCH_TREND]: SearchTrend,
  [CHART_NAMES.AVERAGE_CTR]: AverageCtrChart,
  [CHART_NAMES.ESTIMATED_VISITS]: EstimatedVisitsChart,
  [CHART_NAMES.TRAFFIC_VALUE]: TrafficValueChart,
  [CHART_NAMES.NOTIFICATIONS]: OverviewNotifications,
  [CHART_NAMES.AVERAGE_BASE_RANK]: AverageBaseRankChart,
  [CHART_NAMES.AI_TRAFFIC_VALUE]: AiTrafficValueChart,
  [CHART_NAMES.BASE_RANKING_DISTRIBUTION]: BaseRankingDistribution,
  [CHART_NAMES.PIXELS_FROM_TOP]: PixelsFromTopChart,
  [CHART_NAMES.NONE]: () => <Box mih={'380px'} />,
  [CHART_NAMES.PLACEHOLDER]: ChartPlaceholder,
};

type SharedProps = {
  idx?: number;
  chartName: string;
  column?: 'leftColumn' | 'rightColumn';
};
type ChartBoxProps = SharedProps & {
  style?: CSSProperties;
  alwaysVisible?: boolean;
  otherProps: any;
};

export const ChartBox = ({ chartName, alwaysVisible, otherProps = {} }: ChartBoxProps & any) => {
  const Component = useMemo(() => CHART_MAP[chartName], [chartName]);

  if (!CHART_MAP[chartName]) {
    return null;
  }
  return (
    <ErrorBoundary>
      <ChartBoxContext.Provider value={alwaysVisible}>
        <Component {...otherProps} />
      </ChartBoxContext.Provider>
    </ErrorBoundary>
  );
};
