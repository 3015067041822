import FormatNumber from 'Components/FormatNumber';
import { DashboardNode } from 'Pages/Domains/DomainsTable/support/types';
import styles from '../domains-table.module.scss';

type Props = {
  domainNode: DashboardNode;
};

const AiShareOfVoiceCell = (props: Props) => {
  const { countKeywords, aiShareOfVoice } = props.domainNode || {};

  if (!countKeywords) return null;

  return (
    <FormatNumber className={styles.valueCell} percentage={false} precision={0}>
      {aiShareOfVoice ?? 0}
    </FormatNumber>
  );
};

export default AiShareOfVoiceCell;
