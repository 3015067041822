import { useApolloClient } from '@apollo/client';
import { AccFastIcon } from 'Components/AccFastIcon';
import {
  DataTable,
  TableFetchDataCallBack,
  TableOrder,
  TableSize,
  useTableStore,
} from 'Components/DataTable';
import { ActionsCellItem } from 'Components/Table/TableRow/ActionsCell';
import {
  GetWorkspacesDocument,
  GetWorkspacesQuery,
  GetWorkspacesQueryVariables,
  WorkspaceFragment,
  useDeleteWorkspaceMutation,
} from 'Ghql';
import { useModal } from 'Hooks/base/useModal';
import toast from 'Hooks/useToast';
import { TableIDs } from 'Types/Table';
import { invalidateCache } from 'Utilities/Graphql/invalidateCache';
import { t } from 'Utilities/i18n';
import { notEmpty } from 'Utilities/underdash';
import styleVariables from 'css/base/variables.module.scss';
import IconEdit from 'icons/IconEdit.svg';
import { IconTrash } from 'icons/tag-cloud';
import { getColumns } from './getColumns';

const { snorlax4: iconColor, gray4: disabledIconColor } = styleVariables;

const DEFAULT_ORDERING = {
  orderBy: 'id',
  order: TableOrder.ASC,
};

const useFetchData = (): TableFetchDataCallBack => {
  const client = useApolloClient();

  return async () => {
    return client
      .query<GetWorkspacesQuery, GetWorkspacesQueryVariables>({
        query: GetWorkspacesDocument,
        fetchPolicy: 'network-only',
      })
      .then((response) => {
        const workspaces = response.data?.workspacesByOrganization?.filter(notEmpty) || [];
        return { data: workspaces, length: workspaces.length ?? 0 };
      });
  };
};

const WorkspacesTable = ({
  dataKey,
  handleUpdateTable,
  refetchQty,
}: {
  dataKey: number;
  handleUpdateTable: () => void;
  refetchQty: () => void;
}) => {
  const tableStore = useTableStore(TableIDs.WORKSPACES);
  const client = useApolloClient();
  const { showModal } = useModal();
  const [deleteWorkspace] = useDeleteWorkspaceMutation();
  const fetchData = useFetchData();

  const handleEdit = (record: WorkspaceFragment) => {
    showModal({
      modalType: 'EditWorkspace',
      modalTheme: 'light',
      modalProps: {
        refresh: handleUpdateTable,
        id: record.id,
      },
    });
  };

  const handleDelete = (record: WorkspaceFragment) => {
    showModal({
      modalType: 'Confirmation',
      modalProps: {
        cancelLabel: t('Cancel'),
        confirmLabel: t('Delete workspace'),
        primaryButtonLabel: t('Confirm'),
        lockDuration: 0,
        title: t('Delete workspace?', record.name),
        description: t(
          'Once deleted, users assigned to this role will lose access to related groups.',
        ),
        action: () => {
          deleteWorkspace({
            variables: {
              workspaceId: record.id,
            },
          })
            .then((response) => {
              const errors = response.data?.deleteWorkspace?.errors;
              if (errors && errors.length) {
                toast.error('Something went wrong. Please reload and try again.');
              } else {
                tableStore?.deleteItem(record.id.toString());
                toast.success(t('Workspace has been deleted.'));
                invalidateCache(client.cache, 'workspacesByOrganization');
                refetchQty();
              }
            })
            .catch(() => toast.error('Something went wrong. Please reload and try again.'));
        },
      },
    });
  };

  const getActions = (record: WorkspaceFragment): ActionsCellItem[] => {
    const isDeletable = record?.id && parseInt(record.id, 10) !== 1;

    return [
      {
        onSelect: () => handleEdit(record),
        label: t('Edit'),
        fastIcon: <AccFastIcon src={IconEdit} size={20} color={iconColor} />,
      },
      {
        onSelect: () => handleDelete(record),
        label: t('Delete'),
        fastIcon: (
          <AccFastIcon
            src={IconTrash}
            size={20}
            color={isDeletable ? iconColor : disabledIconColor}
          />
        ),
        disabled: !isDeletable,
      },
    ];
  };

  // eslint-disable-next-line @typescript-eslint/ban-types
  const columns = getColumns(getActions);

  return (
    <DataTable
      dataKey={`${dataKey.toString()}-table`}
      tableId={TableIDs.WORKSPACES}
      fetchData={fetchData}
      columns={columns}
      viewMode={TableSize.DEFAULT}
      defaultOrdering={DEFAULT_ORDERING}
      emptyOptions={{
        title: t('No Workspaces'),
        subTitle: t('There are currently no Workspaces.'),
      }}
      offlineFilter={{
        tableName: TableIDs.WORKSPACES,
        skipAll: true,
      }}
    />
  );
};

export default WorkspacesTable;
