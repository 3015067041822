import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useMantineTheme } from '@mantine/core';
import AccActionIcon from 'Components/AccActionIcon/AccActionIcon';
import { AccFastIcon } from 'Components/AccFastIcon';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import linkWithFilters from 'Components/Filters/linkWithFilters';
import { FilterAttribute, FilterComparison, FilterValueType } from 'Types/Filter';
import { KEYWORDS_FILTER_SET } from 'Types/FilterSet';
import KeydisIcon from 'icons/menu/compass-keydis.svg';
import CompetitorsIcon from 'icons/menu/competitors.svg';
import KeywordsIcon from 'icons/menu/keywords.svg';
import OverviewIcon from 'icons/menu/overview.svg';

export const KEYWORDS_PAGE = 'keywords';
export const OVERVIEW_PAGE = 'overview';

// eslint-disable-next-line import/no-unused-modules
export const COMPETITORS_PAGE = 'competitors';

// eslint-disable-next-line import/no-unused-modules
export const KEYWORD_DISCOVERY_PAGE = 'keyword_discovery';

type Pages = {
  path: string;
  icon: string;
};

type PageTypes =
  | typeof KEYWORDS_PAGE
  | typeof OVERVIEW_PAGE
  | typeof COMPETITORS_PAGE
  | typeof KEYWORD_DISCOVERY_PAGE;

const PAGES_WITH_PATH_AND_ICON: Record<PageTypes, Pages> = {
  [KEYWORDS_PAGE]: { path: '/keywords/list', icon: KeywordsIcon },
  [OVERVIEW_PAGE]: { path: '/keywords/overview', icon: OverviewIcon },
  [COMPETITORS_PAGE]: { path: '/keywords/competitors', icon: CompetitorsIcon },
  [KEYWORD_DISCOVERY_PAGE]: { path: '/keywords/keyword_discovery', icon: KeydisIcon },
};

const getFolderOrTagFilter = ({ isFolder, folderOrTag }) => {
  if (isFolder) {
    return {
      attribute: FilterAttribute.FOLDERS,
      type: FilterValueType.FOLDER,
      comparison: FilterComparison.FOLDER_OR_SUBFOLDER,
      value: [folderOrTag],
    };
  }
  return {
    attribute: FilterAttribute.TAGS,
    type: FilterValueType.ARRAY,
    comparison: FilterComparison.ANY,
    value: [folderOrTag],
  };
};

const linkToPathWithFolder = (folder: string, path: string) => {
  const folderFilter = getFolderOrTagFilter({ isFolder: true, folderOrTag: folder });
  return linkWithFilters({ to: path, newFilters: [folderFilter], filterSet: KEYWORDS_FILTER_SET });
};

const linkToPathWithTag = (tag: string, path: string) => {
  const tagsFilter = getFolderOrTagFilter({ isFolder: false, folderOrTag: tag });
  return linkWithFilters({ to: path, newFilters: [tagsFilter], filterSet: KEYWORDS_FILTER_SET });
};

export const useFolderOrTagFilter = ({ isFolder, folderOrTag }) => {
  return useMemo(() => getFolderOrTagFilter({ isFolder, folderOrTag }), [isFolder, folderOrTag]);
};

export const useFolderLink = ({ isFolder, path, pageToGoTo, name }) => {
  return useMemo(
    () =>
      (isFolder
        ? linkToPathWithFolder(path, PAGES_WITH_PATH_AND_ICON[pageToGoTo].path)
        : linkToPathWithTag(name, PAGES_WITH_PATH_AND_ICON[pageToGoTo].path)),
    [isFolder, path, pageToGoTo, name],
  );
};

export const LinkIconWithFolderFilter = React.memo(
  ({
    path,
    pageToGoTo,
    isFolder,
    name,
    tooltipText,
  }: {
    path: string;
    pageToGoTo: keyof typeof PAGES_WITH_PATH_AND_ICON;
    isFolder: boolean;
    name: string;
    tooltipText?: string;
  }) => {
    const link = useFolderLink({
      isFolder,
      path,
      pageToGoTo,
      name,
    });

    const theme = useMantineTheme();

    return (
      <Link to={link}>
        <AccTooltip tooltip={tooltipText}>
          <AccActionIcon variant="subtle">
            <AccFastIcon
              src={PAGES_WITH_PATH_AND_ICON[pageToGoTo].icon}
              color={theme.colors.blue[4]}
            />
          </AccActionIcon>
        </AccTooltip>
      </Link>
    );
  },
);
LinkIconWithFolderFilter.displayName = 'LinkIconWithFolderFilter';
