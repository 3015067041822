import { ReactNode, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useApolloClient } from '@apollo/client';
import {
  IconBrandGoogleDrive,
  IconFileTypeCsv,
  IconFileTypePdf,
  IconFileTypeXls,
} from '@tabler/icons-react';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import { ColumnType, TableOrder, useTableStore } from 'Components/DataTable';
import Ellipsis from 'Components/Ellipsis';
import ReportActionCell from 'Components/Table/TableRow/ReportActionCell';
import ReportScheduleCell from 'Components/Table/TableRow/ReportScheduleCell';
import {
  ReportsScheduledReportNewReportTypeChoices,
  ScheduledReportFragment,
  useDeleteScheduledReportMutation,
  useScheduledReportsLazyQuery,
} from 'Ghql';
import toast from 'Hooks/useToast';
import useUserPermission from 'Hooks/useUserPermission';
import { SORTING_TYPE } from 'Pages/Keywords/Table/hooks/keyword/constants';
import { TableIDs } from 'Types/Table';
import { invalidateCache } from 'Utilities/Graphql/invalidateCache';
import { t } from 'Utilities/i18n';
import { notEmpty } from 'Utilities/underdash';
import styleVariables from 'css/base/variables.module.scss';
import { ColumnIDs } from './ColumnIDs';
import styles from './scheduledReportTable.module.scss';

export const getReportTypeIcon = (reportType: string) => {
  const { gray5: iconColor } = styleVariables;
  switch (reportType) {
    case ReportsScheduledReportNewReportTypeChoices.A_1:
      return (
        <AccTooltip tooltip={t('PDF')}>
          <IconFileTypePdf size={24} color={iconColor} tabIndex={-1} />
        </AccTooltip>
      );

    case ReportsScheduledReportNewReportTypeChoices.A_2:
      return (
        <AccTooltip tooltip={t('Excel')}>
          <IconFileTypeXls size={24} color={iconColor} tabIndex={-1} />
        </AccTooltip>
      );

    case ReportsScheduledReportNewReportTypeChoices.A_3:
      return (
        <AccTooltip tooltip={t('CSV')}>
          <IconFileTypeCsv size={24} color={iconColor} tabIndex={-1} />
        </AccTooltip>
      );

    case ReportsScheduledReportNewReportTypeChoices.A_5:
      return (
        <AccTooltip tooltip={t('Google Sheets')}>
          <IconBrandGoogleDrive size={24} color={iconColor} tabIndex={-1} />
        </AccTooltip>
      );

    default:
      return 'unknown';
  }
};

const filterData = (list: ScheduledReportFragment[], searchTerm: string) => {
  const st = searchTerm?.toLowerCase();
  return list.filter((e) => {
    const isNameMatch = e.name?.toLowerCase().includes(st);
    const isReportForMatch = e.isGroupReport
      ? e.group?.name?.toLowerCase().includes(st)
      : e.domain?.domain?.toLowerCase().includes(st);
    const isTemplateMatch = e.reportTemplate?.name?.toLowerCase().includes(st);
    return isNameMatch || isReportForMatch || isTemplateMatch;
  });
};

export const useFetchScheduledReports = ({
  searchTerm,
  handleLoading,
}: {
  searchTerm: string;
  handleLoading: (isLoading: boolean) => void;
}) => {
  const [getScheduledReports] = useScheduledReportsLazyQuery();
  return useCallback(async () => {
    try {
      handleLoading(true);
      const response = await getScheduledReports();
      const scheduledReports = response?.data?.scheduledReports?.filter(notEmpty) || [];
      const filteredReports = filterData(scheduledReports, searchTerm);
      return { data: filteredReports, length: filteredReports.length };
    } catch (error: unknown) {
      if (error instanceof Error) {
        console.error('Error fetching scheduled reports:', error.message);
      }
      return { data: [], length: 0 };
    } finally {
      handleLoading(false);
    }
  }, [handleLoading, getScheduledReports, searchTerm]);
};

export const useScheduledReportsTableColumns = () => {
  const tableStore = useTableStore(TableIDs.SCHEDULED_REPORTS);
  const { userHasWriteAccess } = useUserPermission();
  const client = useApolloClient();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [deleteScheduledReport, _rest] = useDeleteScheduledReportMutation();
  const handleDeleteScheduledReport = (report: ScheduledReportFragment) => {
    deleteScheduledReport({
      variables: {
        input: {
          id: report.id,
        },
      },
    }).then((response) => {
      if (response.errors?.length) {
        toast.error(t('Unable to delete scheduled report'));
      } else {
        tableStore?.deleteItem(report.id?.toString());
        toast.success(t('Scheduled report deleted'));
        invalidateCache(client.cache, 'scheduledReports');
      }
    });
  };

  // eslint-disable-next-line @typescript-eslint/ban-types
  const columns: ColumnType<ScheduledReportFragment, {}>[] = [
    {
      id: ColumnIDs.TYPE,
      title: t('Type'),
      width: 60,
      cellRenderer: (props) => {
        const reportTypeIcon = getReportTypeIcon(props.record.reportType);
        return <div className={styles.centerWrapper}>{reportTypeIcon}</div>;
      },
      onHeaderCell: () => ({
        ordering: {
          defaultOrder: TableOrder.ASC,
          orderBy: 'reportType',
          sortingKey: SORTING_TYPE.alphabetical,
        },
      }),
    },
    {
      id: ColumnIDs.NAME,
      title: t('Name'),
      width: 200,
      cellRenderer: (props) => props.record.name,
      onHeaderCell: () => ({
        ordering: {
          defaultOrder: TableOrder.ASC,
          orderBy: ColumnIDs.NAME,
          sortingKey: SORTING_TYPE.alphabetical,
        },
      }),
    },
    {
      id: ColumnIDs.REPORT_FOR,
      title: t('Report For'),
      flex: true,
      width: 200,
      cellRenderer: (props) => {
        const groupOrDomainName = props.record.isGroupReport
          ? t('All domains is %s', props.record.group?.name)
          : `${props.record.domain?.domain}${
              props.record.domain?.displayName ? ` (${props.record.domain?.displayName})` : ''
            }`;
        return <Ellipsis>{groupOrDomainName}</Ellipsis>;
      },
      onHeaderCell: () => ({
        ordering: {
          defaultOrder: TableOrder.ASC,
          orderBy: 'domain.displayName',
          sortingKey: SORTING_TYPE.alphabetical,
        },
      }),
    },
    {
      id: ColumnIDs.TEMPLATE,
      title: t('Template Name'),
      width: 200,
      flex: true,
      cellRenderer: (props) => {
        const report = props.record;
        let reportTemplateName: ReactNode | undefined = (
          <Ellipsis>{report.reportTemplate?.name}</Ellipsis>
        );

        if (!report.reportTemplate?.systemTemplate) {
          reportTemplateName = (
            <Link to={`/reports/templates/builder/edit/${report.reportTemplate?.id ?? ''}`}>
              <Ellipsis>{report.reportTemplate?.name}</Ellipsis>
            </Link>
          );
        }

        return reportTemplateName;
      },
      onHeaderCell: () => ({
        ordering: {
          defaultOrder: TableOrder.ASC,
          orderBy: 'reportTemplate.name',
          sortingKey: SORTING_TYPE.alphabetical,
        },
      }),
    },
    {
      id: ColumnIDs.SCHEDULE,
      title: t('Schedule'),
      width: 110,
      flex: true,
      cellRenderer: (props) => <ReportScheduleCell report={props.record} />,
      onHeaderCell: () => ({
        ordering: {
          defaultOrder: TableOrder.ASC,
          orderBy: 'schedule',
          sortingKey: SORTING_TYPE.alphabetical,
        },
      }),
    },
  ];
  if (userHasWriteAccess) {
    columns.push({
      id: ColumnIDs.ACTIONS,
      title: t('Actions'),
      width: 78,
      cellRenderer: (props) => (
        <ReportActionCell report={props.record} handleDelete={handleDeleteScheduledReport} />
      ),
      className: 'no-border',
    });
  }
  return columns;
};
