// This file auto generated!
// Do not modify it, for more details check ./scripts/generateIcon.js
import IconIconIconLogo from './Icon/logo.svg?inline';
import IconNotFoundCurves from './NotFoundCurves.svg?inline';
import IconAbovethefold2 from './abovethefold2.svg?inline';
import IconAbovethefold from './abovethefold.svg?inline';
import IconActivecampaign from './activecampaign.svg?inline';
import IconAmpMobile from './amp-mobile.svg?inline';
import IconArrowBothSides from './arrow-both-sides.svg?inline';
import IconArrowBothUpdown from './arrow-both-updown.svg?inline';
import IconArrowDown from './arrow-down.svg?inline';
import IconArrowHeadDown from './arrow-head-down.svg?inline';
import IconArrowHeadUp from './arrow-head-up.svg?inline';
import IconArrowRight from './arrow-right.svg?inline';
import IconArrowSimple from './arrow-simple.svg?inline';
import IconArrowUp from './arrow-up.svg?inline';
import IconArrows from './arrows.svg?inline';
import IconBaidu from './baidu.svg?inline';
import IconBin from './bin.svg?inline';
import IconBing from './bing.svg?inline';
import IconBinoculars from './binoculars.svg?inline';
import IconBook from './book.svg?inline';
import IconBreadcrumbs from './breadcrumbs.svg?inline';
import IconBurger from './burger.svg?inline';
import IconButtonPlus from './button-plus.svg?inline';
import IconCalendar from './calendar.svg?inline';
import IconCarousel from './carousel.svg?inline';
import IconCart from './cart.svg?inline';
import IconChangePassword from './change-password.svg?inline';
import IconCheck1 from './check-1.svg?inline';
import IconCheckDouble from './check-double.svg?inline';
import IconCheckLocation from './check-location.svg?inline';
import IconCheckRounded2 from './check-rounded-2.svg?inline';
import IconCheckRounded from './check-rounded.svg?inline';
import IconCheck from './check.svg?inline';
import IconCheckbox from './checkbox.svg?inline';
import IconChevronBackward from './chevron-backward.svg?inline';
import IconChevronForward from './chevron-forward.svg?inline';
import IconChevronUp from './chevron-up.svg?inline';
import IconCircleDashed from './circle-dashed.svg?inline';
import IconCircle from './circle.svg?inline';
import IconClockWait from './clock-wait.svg?inline';
import IconClock from './clock.svg?inline';
import IconClone from './clone.svg?inline';
import IconClose2 from './close-2.svg?inline';
import IconCloseRounded from './close-rounded.svg?inline';
import IconClose from './close.svg?inline';
import IconComment from './comment.svg?inline';
import IconConnecting from './connecting.svg?inline';
import IconContentNewspaper2 from './content-newspaper-2.svg?inline';
import IconContentNote from './content-note.svg?inline';
import IconCopy from './copy.svg?inline';
import IconCorporateContact from './corporate-contact.svg?inline';
import IconCourse from './course.svg?inline';
import IconCriticReview from './critic-review.svg?inline';
import IconCrossRoundedThin from './cross-rounded-thin.svg?inline';
import IconCsv from './csv.svg?inline';
import IconCtr from './ctr.svg?inline';
import IconCtrChange from './ctr_change.svg?inline';
import IconCtrMax from './ctr_max.svg?inline';
import IconCtrPot from './ctr_pot.svg?inline';
import IconDataSheet from './data-sheet.svg?inline';
import IconDevices from './devices.svg?inline';
import IconDiagram from './diagram.svg?inline';
import IconDirectAnswer from './direct-answer.svg?inline';
import IconDiscoverMorePlaces from './discover-more-places.svg?inline';
import IconDollarOverline from './dollar-overline.svg?inline';
import IconDollarUnderline from './dollar-underline.svg?inline';
import IconDomainSettings from './domain-settings.svg?inline';
import IconDot from './dot.svg?inline';
import IconDownload from './download.svg?inline';
import IconEditProfile from './edit-profile.svg?inline';
import IconEditRounded from './edit-rounded.svg?inline';
import IconEdit from './edit.svg?inline';
import IconEnvelope from './envelope.svg?inline';
import IconEqual from './equal.svg?inline';
import IconEvent from './event.svg?inline';
import IconExclamation from './exclamation.svg?inline';
import IconEyeSlashed from './eye-slashed.svg?inline';
import IconEye from './eye.svg?inline';
import IconFacebook from './facebook.svg?inline';
import IconFactCheck from './fact-check.svg?inline';
import IconFaq from './faq.svg?inline';
import IconFeaturedVideo from './featured-video.svg?inline';
import IconFileStatisticInformation2 from './file-statistic-information-2.svg?inline';
import IconFileTasksQuestionMark from './file-tasks-question-mark.svg?inline';
import IconFilterActive from './filter-active.svg?inline';
import IconFilterWhite from './filter-white.svg?inline';
import IconFilter from './filter.svg?inline';
import IconFindJob from './find-job.svg?inline';
import IconFlash from './flash.svg?inline';
import IconFloppydisk from './floppydisk.svg?inline';
import IconFolder from './folder.svg?inline';
import IconG from './g.svg?inline';
import IconGlasses from './glasses.svg?inline';
import IconGlobe from './globe.svg?inline';
import IconGoogleDrive from './google-drive.svg?inline';
import IconGoogleNote from './google-note.svg?inline';
import IconGooglePosts from './google-posts.svg?inline';
import IconGoogle from './google.svg?inline';
import IconGreaterThan from './greater-than.svg?inline';
import IconGrumpGrumpChillingTiger2 from './grump/Chilling-tiger2.svg?inline';
import IconGrumpGrumpGrumpySmile from './grump/GrumpySmile.svg?inline';
import IconGrumpGrumpStandingCautiousTiger from './grump/StandingCautiousTiger.svg?inline';
import IconGrumpGrumpCautious from './grump/cautious.svg?inline';
import IconGrumpGrumpChilledWithPawsHorizontal from './grump/chilled-with-paws-horizontal.svg?inline';
import IconGrumpGrumpChilledWithPaws from './grump/chilled-with-paws.svg?inline';
import IconGrumpGrumpChilled from './grump/chilled.svg?inline';
import IconGrumpGrumpFurious from './grump/furious.svg?inline';
import IconGrumpGrumpGrumpy from './grump/grumpy.svg?inline';
import IconHeadphone from './headphone.svg?inline';
import IconHighestRankings from './highest-rankings.svg?inline';
import IconHotelBed1 from './hotel-bed-1.svg?inline';
import IconHotel from './hotel.svg?inline';
import IconHttps from './https.svg?inline';
import IconIgnore from './ignore.svg?inline';
import IconIgnoreInShareOfVoice from './ignoreInShareOfVoice.svg?inline';
import IconImagePack from './image-pack.svg?inline';
import IconInfoBubble from './info-bubble.svg?inline';
import IconInformation from './information.svg?inline';
import IconIntercomIcon from './intercom-icon.svg?inline';
import IconJobPosting from './job-posting.svg?inline';
import IconJourney from './journey.svg?inline';
import IconKey from './key.svg?inline';
import IconKeyboard from './keyboard.svg?inline';
import IconKeywordOverview from './keyword-overview.svg?inline';
import IconKeywords from './keywords.svg?inline';
import IconKnowledgeCard from './knowledge-card.svg?inline';
import IconKnowledgePanel from './knowledge-panel.svg?inline';
import IconLessThan from './less-than.svg?inline';
import IconLink from './link.svg?inline';
import IconLiveStream from './live-stream.svg?inline';
import IconLoading from './loading.svg?inline';
import IconLocalTeaserPack from './local-teaser-pack.svg?inline';
import IconLocation2 from './location-2.svg?inline';
import IconLocationGlobeView from './location-globe-view.svg?inline';
import IconLocationPath2 from './location-path-2.svg?inline';
import IconLocation from './location.svg?inline';
import IconLock from './lock.svg?inline';
import IconLogoBrand from './logo-brand.svg?inline';
import IconLogoWatermark from './logo-watermark.svg?inline';
import IconLogo from './logo.svg?inline';
import IconMainMenuMainMenuAffiliate from './main-menu/affiliate.svg?inline';
import IconMainMenuMainMenuDesktop from './main-menu/desktop.svg?inline';
import IconMainMenuMainMenuDomains from './main-menu/domains.svg?inline';
import IconMainMenuMainMenuGroups from './main-menu/groups.svg?inline';
import IconMainMenuMainMenuHeartHandshake from './main-menu/heart-handshake.svg?inline';
import IconMainMenuMainMenuLinkedAccounts from './main-menu/linked-accounts.svg?inline';
import IconMainMenuMainMenuPausedDomains from './main-menu/paused-domains.svg?inline';
import IconMainMenuMainMenuReport from './main-menu/report.svg?inline';
import IconMainMenuMainMenuSales from './main-menu/sales.svg?inline';
import IconMainMenuMainMenuSettings from './main-menu/settings.svg?inline';
import IconMap from './map.svg?inline';
import IconMenuMenuSerpFeatures from './menu/SERP-features.svg?inline';
import IconMenuMenuAccountsRequests from './menu/accounts-requests.svg?inline';
import IconMenuMenuApi from './menu/api.svg?inline';
import IconMenuMenuBilling from './menu/billing.svg?inline';
import IconMenuMenuBulkimport from './menu/bulkimport.svg?inline';
import IconMenuMenuCalendarMagnify from './menu/calendar-magnify.svg?inline';
import IconMenuMenuCompassKeydis from './menu/compass-keydis.svg?inline';
import IconMenuMenuCompetitorRankings from './menu/competitor-rankings.svg?inline';
import IconMenuMenuCompetitors from './menu/competitors.svg?inline';
import IconMenuMenuConnectedAccounts from './menu/connected-accounts.svg?inline';
import IconMenuMenuCreditCard from './menu/credit-card.svg?inline';
import IconMenuMenuCustomers from './menu/customers.svg?inline';
import IconMenuMenuDesktop from './menu/desktop.svg?inline';
import IconMenuMenuDomains from './menu/domains.svg?inline';
import IconMenuMenuEditProfile from './menu/edit-profile.svg?inline';
import IconMenuMenuGeneratedReports from './menu/generated-reports.svg?inline';
import IconMenuMenuGoogleAnalytics from './menu/google-analytics.svg?inline';
import IconMenuMenuGoogleSearchconsole from './menu/google-searchconsole.svg?inline';
import IconMenuMenuGroups from './menu/groups.svg?inline';
import IconMenuMenuImportExamples from './menu/import-examples.svg?inline';
import IconMenuMenuInvoice from './menu/invoice.svg?inline';
import IconMenuMenuKeywordOveruse from './menu/keyword-overuse.svg?inline';
import IconMenuMenuKeywords from './menu/keywords.svg?inline';
import IconMenuMenuLandingpage from './menu/landingpage.svg?inline';
import IconMenuMenuLinkedAccounts from './menu/linked-accounts.svg?inline';
import IconMenuMenuMoneyNote from './menu/money-note.svg?inline';
import IconMenuMenuNotes from './menu/notes.svg?inline';
import IconMenuMenuOrganizationHierarchy from './menu/organization-hierarchy.svg?inline';
import IconMenuMenuOverview from './menu/overview.svg?inline';
import IconMenuMenuPausedDomains from './menu/paused-domains.svg?inline';
import IconMenuMenuRanksHistory from './menu/ranks-history.svg?inline';
import IconMenuMenuReferral from './menu/referral.svg?inline';
import IconMenuMenuReportTemplates from './menu/report-templates.svg?inline';
import IconMenuMenuReports from './menu/reports.svg?inline';
import IconMenuMenuSales from './menu/sales.svg?inline';
import IconMenuMenuServerDollar2 from './menu/server-dollar-2.svg?inline';
import IconMenuMenuSettings from './menu/settings.svg?inline';
import IconMenuMenuTags from './menu/tags.svg?inline';
import IconMenuMenuTools from './menu/tools.svg?inline';
import IconMenuMenuTransferMultiAccountDomain from './menu/transfer-multi-account-domain.svg?inline';
import IconMenuMenuUsers from './menu/users.svg?inline';
import IconMenuMenuVisitors from './menu/visitors.svg?inline';
import IconMenuMenuWallet from './menu/wallet.svg?inline';
import IconMessageBubble from './message-bubble.svg?inline';
import IconMessages from './messages.svg?inline';
import IconMinus2 from './minus-2.svg?inline';
import IconMinus from './minus.svg?inline';
import IconMobile2 from './mobile-2.svg?inline';
import IconMobileDevices from './mobile-devices.svg?inline';
import IconMobile from './mobile.svg?inline';
import IconMonitor2 from './monitor-2.svg?inline';
import IconMonitor from './monitor.svg?inline';
import IconMoreVert from './more_vert.svg?inline';
import IconMostPopularCarousel from './most-popular-carousel.svg?inline';
import IconMovie from './movie.svg?inline';
import IconMultipleKeywordsFilter from './multiple_keywords_filter.svg?inline';
import IconNaver from './naver.svg?inline';
import IconNoFilter from './no-filter.svg?inline';
import IconNodeTree from './node-tree.svg?inline';
import IconNoteMultiple from './note-multiple.svg?inline';
import IconNoteSingle from './note-single.svg?inline';
import IconNotes from './notes.svg?inline';
import IconOccupation from './occupation.svg?inline';
import IconOseMinus from './ose-minus.svg?inline';
import IconOsePlus from './ose-plus.svg?inline';
import IconPdf from './pdf.svg?inline';
import IconPipedrive from './pipedrive.svg?inline';
import IconPixelsfromtop from './pixelsfromtop.svg?inline';
import IconPlay from './play.svg?inline';
import IconPlus2 from './plus-2.svg?inline';
import IconPlusRounded from './plus-rounded.svg?inline';
import IconPlus from './plus.svg?inline';
import IconProgressChecked from './progress-checked.svg?inline';
import IconQaPageMobile from './qa-page-mobile.svg?inline';
import IconQuestionRoundedFilled from './question-rounded-filled.svg?inline';
import IconRank from './rank.svg?inline';
import IconRecipe from './recipe.svg?inline';
import IconRefineCarousel from './refine-carousel.svg?inline';
import IconRefresh from './refresh.svg?inline';
import IconRelatedSearchesCarousel from './related-searches-carousel.svg?inline';
import IconRelatedSearches from './related-searches.svg?inline';
import IconRelease from './release.svg?inline';
import IconRemove from './remove.svg?inline';
import IconReport from './report.svg?inline';
import IconRewardsTrophy from './rewards-trophy.svg?inline';
import IconRichCards from './rich-cards.svg?inline';
import IconSave2 from './save2.svg?inline';
import IconSave from './save.svg?inline';
import IconSearch2 from './search-2.svg?inline';
import IconSearchByPhotos from './search-by-photos.svg?inline';
import IconSearchIntentSearchIntentCommercial from './search-intent/commercial.svg?inline';
import IconSearchIntentSearchIntentInformational from './search-intent/informational.svg?inline';
import IconSearchIntentSearchIntentMainSearchIntentIcon from './search-intent/main-search-intent-icon.svg?inline';
import IconSearchIntentSearchIntentNavigational from './search-intent/navigational.svg?inline';
import IconSearchIntentSearchIntentTransactional from './search-intent/transactional.svg?inline';
import IconSearchVolume from './search-volume.svg?inline';
import IconSearch from './search.svg?inline';
import IconSettings from './settings.svg?inline';
import IconShoppingCartFavoriteStar from './shopping-cart-favorite-star.svg?inline';
import IconShoppingCartInformation from './shopping-cart-information.svg?inline';
import IconSignOut from './sign-out.svg?inline';
import IconSiteLinksSearchbox from './site-links-searchbox.svg?inline';
import IconSnippet from './snippet.svg?inline';
import IconSocialMediaSocialMediaFacebookCircle from './social-media/facebook_circle.svg?inline';
import IconSocialMediaSocialMediaGoogleCircle from './social-media/google_circle.svg?inline';
import IconSocialMediaSocialMediaInstagramCircle from './social-media/instagram_circle.svg?inline';
import IconSocialMediaSocialMediaLinkedinCircle from './social-media/linkedin_circle.svg?inline';
import IconSocialMediaSocialMediaMicrosoft from './social-media/microsoft.svg?inline';
import IconSocialMediaSocialMediaTiktokCircle from './social-media/tiktok_circle.svg?inline';
import IconSocialMediaSocialMediaXCircle from './social-media/x_circle.svg?inline';
import IconSocialProfile from './social-profile.svg?inline';
import IconSoftTag from './soft-tag.svg?inline';
import IconSoftwareApp from './software-app.svg?inline';
import IconSov from './sov.svg?inline';
import IconSovMax from './sov_max.svg?inline';
import IconSpeechbubble from './speechbubble.svg?inline';
import IconSpeedometerGreen from './speedometer-green.svg?inline';
import IconSpeedometerOrange from './speedometer-orange.svg?inline';
import IconSpeedometerRed from './speedometer-red.svg?inline';
import IconSpellcheck from './spellcheck.svg?inline';
import IconStar from './star.svg?inline';
import IconSubdomain from './subdomain.svg?inline';
import IconSumm from './summ.svg?inline';
import IconTable from './table.svg?inline';
import IconTagCloudTagCloudChevronDown from './tag-cloud/chevron-down.svg?inline';
import IconTagCloudTagCloudChevronRight from './tag-cloud/chevron-right.svg?inline';
import IconTagCloudTagCloudEdit from './tag-cloud/edit.svg?inline';
import IconTagCloudTagCloudFolderPlus from './tag-cloud/folder-plus.svg?inline';
import IconTagCloudTagCloudFolder from './tag-cloud/folder.svg?inline';
import IconTagCloudTagCloudSerpFeatures from './tag-cloud/serp-features.svg?inline';
import IconTagCloudTagCloudTags from './tag-cloud/tags.svg?inline';
import IconTagCloudTagCloudTrash from './tag-cloud/trash.svg?inline';
import IconTagPlus from './tag-plus.svg?inline';
import IconTag from './tag.svg?inline';
import IconTags from './tags.svg?inline';
import IconThingsTodo from './things-todo.svg?inline';
import IconThumbnail from './thumbnail.svg?inline';
import IconThumbnails from './thumbnails.svg?inline';
import IconTiger from './tiger.svg?inline';
import IconTitleLengthDescriptionLength from './title-length-description-length.svg?inline';
import IconTrafficLightGreen from './traffic-light-green.svg?inline';
import IconTrafficLightYellow from './traffic-light-yellow.svg?inline';
import IconTrafficvalue from './trafficvalue.svg?inline';
import IconTrafficvalueMax from './trafficvalue_max.svg?inline';
import IconTravelDeparture from './travel-departure.svg?inline';
import IconTreeTreeCursorClickDouble from './tree/cursor-click-double.svg?inline';
import IconTreeTreeCursorClick from './tree/cursor-click.svg?inline';
import IconTreeTreeCursorMoveAllDirection from './tree/cursor-move-all-direction.svg?inline';
import IconTreeTreeZoomIn from './tree/zoom-in.svg?inline';
import IconTweet from './tweet.svg?inline';
import IconTwoUsers from './two-users.svg?inline';
import IconUnlink from './unlink.svg?inline';
import IconUploadCloud from './upload-cloud.svg?inline';
import IconUrl from './url.svg?inline';
import IconUsers from './users.svg?inline';
import IconVideoCarousel from './video-carousel.svg?inline';
import IconView from './view.svg?inline';
import IconWindowChecked from './window-checked.svg?inline';
import IconWindowSearch from './window-search.svg?inline';
import IconWindow from './window.svg?inline';
import IconWorldOff from './world-off.svg?inline';
import IconWrench from './wrench.svg?inline';
import IconX from './x.svg?inline';
import IconXls from './xls.svg?inline';
import IconYandex from './yandex.svg?inline';
import IconYoutubeAds from './youtube-ads.svg?inline';
import IconYoutubeAge from './youtube-age.svg?inline';
import IconYoutubeChannel from './youtube-channel.svg?inline';
import IconYoutubeLiveStream from './youtube-live-stream.svg?inline';
import IconYoutubeMovie from './youtube-movie.svg?inline';
import IconYoutubePlaylist from './youtube-playlist.svg?inline';
import IconYoutubePromotedVideo from './youtube-promoted-video.svg?inline';
import IconYoutubeRadio from './youtube-radio.svg?inline';
import IconYoutubeRelatedMovies from './youtube-related-movies.svg?inline';
import IconYoutubeRelatedSearches from './youtube-related-searches.svg?inline';
import IconYoutubeRelatedToYourSearch from './youtube-related-to-your-search.svg?inline';
import IconYoutubeSecondarySearchContainer from './youtube-secondary-search-container.svg?inline';
import IconYoutubeShelfRender from './youtube-shelf-render.svg?inline';
import IconYoutubeShow from './youtube-show.svg?inline';
import IconYoutubeSubscribers from './youtube-subscribers.svg?inline';
import IconYoutubeVideoCount from './youtube-video-count.svg?inline';
import IconYoutubeVideo from './youtube-video.svg?inline';
import IconYoutubeViews from './youtube-views.svg?inline';
import IconYoutubeWatching from './youtube-watching.svg?inline';
import IconYoutube from './youtube.svg?inline';

export const icons = {
  abovethefold: IconAbovethefold,
  abovethefold2: IconAbovethefold2,
  activecampaign: IconActivecampaign,
  'amp-mobile': IconAmpMobile,
  'arrow-both-sides': IconArrowBothSides,
  'arrow-both-updown': IconArrowBothUpdown,
  'arrow-down': IconArrowDown,
  'arrow-head-down': IconArrowHeadDown,
  'arrow-head-up': IconArrowHeadUp,
  'arrow-right': IconArrowRight,
  'arrow-simple': IconArrowSimple,
  'arrow-up': IconArrowUp,
  arrows: IconArrows,
  baidu: IconBaidu,
  bin: IconBin,
  bing: IconBing,
  binoculars: IconBinoculars,
  book: IconBook,
  breadcrumbs: IconBreadcrumbs,
  burger: IconBurger,
  'button-plus': IconButtonPlus,
  calendar: IconCalendar,
  carousel: IconCarousel,
  cart: IconCart,
  'change-password': IconChangePassword,
  'check-1': IconCheck1,
  'check-double': IconCheckDouble,
  'check-location': IconCheckLocation,
  'check-rounded-2': IconCheckRounded2,
  'check-rounded': IconCheckRounded,
  check: IconCheck,
  checkbox: IconCheckbox,
  'chevron-backward': IconChevronBackward,
  'chevron-forward': IconChevronForward,
  'chevron-up': IconChevronUp,
  'circle-dashed': IconCircleDashed,
  circle: IconCircle,
  'clock-wait': IconClockWait,
  clock: IconClock,
  clone: IconClone,
  'close-2': IconClose2,
  'close-rounded': IconCloseRounded,
  close: IconClose,
  comment: IconComment,
  connecting: IconConnecting,
  'content-newspaper-2': IconContentNewspaper2,
  'content-note': IconContentNote,
  copy: IconCopy,
  'corporate-contact': IconCorporateContact,
  course: IconCourse,
  'critic-review': IconCriticReview,
  'cross-rounded-thin': IconCrossRoundedThin,
  csv: IconCsv,
  ctr_change: IconCtrChange,
  ctr_max: IconCtrMax,
  ctr_pot: IconCtrPot,
  ctr: IconCtr,
  'data-sheet': IconDataSheet,
  devices: IconDevices,
  diagram: IconDiagram,
  'direct-answer': IconDirectAnswer,
  'discover-more-places': IconDiscoverMorePlaces,
  'dollar-overline': IconDollarOverline,
  'dollar-underline': IconDollarUnderline,
  'domain-settings': IconDomainSettings,
  dot: IconDot,
  download: IconDownload,
  'edit-profile': IconEditProfile,
  'edit-rounded': IconEditRounded,
  edit: IconEdit,
  envelope: IconEnvelope,
  equal: IconEqual,
  event: IconEvent,
  exclamation: IconExclamation,
  'eye-slashed': IconEyeSlashed,
  eye: IconEye,
  facebook: IconFacebook,
  'fact-check': IconFactCheck,
  faq: IconFaq,
  'featured-video': IconFeaturedVideo,
  'file-statistic-information-2': IconFileStatisticInformation2,
  'file-tasks-question-mark': IconFileTasksQuestionMark,
  'filter-active': IconFilterActive,
  'filter-white': IconFilterWhite,
  filter: IconFilter,
  'find-job': IconFindJob,
  flash: IconFlash,
  floppydisk: IconFloppydisk,
  folder: IconFolder,
  g: IconG,
  glasses: IconGlasses,
  globe: IconGlobe,
  'google-drive': IconGoogleDrive,
  'google-note': IconGoogleNote,
  'google-posts': IconGooglePosts,
  google: IconGoogle,
  'greater-than': IconGreaterThan,
  'grump-cautious': IconGrumpGrumpCautious,
  'grump-chilled-with-paws-horizontal': IconGrumpGrumpChilledWithPawsHorizontal,
  'grump-chilled-with-paws': IconGrumpGrumpChilledWithPaws,
  'grump-chilled': IconGrumpGrumpChilled,
  'grump-Chilling-tiger2': IconGrumpGrumpChillingTiger2,
  'grump-furious': IconGrumpGrumpFurious,
  'grump-grumpy': IconGrumpGrumpGrumpy,
  'grump-GrumpySmile': IconGrumpGrumpGrumpySmile,
  'grump-StandingCautiousTiger': IconGrumpGrumpStandingCautiousTiger,
  headphone: IconHeadphone,
  'highest-rankings': IconHighestRankings,
  'hotel-bed-1': IconHotelBed1,
  hotel: IconHotel,
  https: IconHttps,
  'Icon-logo': IconIconIconLogo,
  ignore: IconIgnore,
  ignoreInShareOfVoice: IconIgnoreInShareOfVoice,
  'image-pack': IconImagePack,
  'info-bubble': IconInfoBubble,
  information: IconInformation,
  'intercom-icon': IconIntercomIcon,
  'job-posting': IconJobPosting,
  journey: IconJourney,
  key: IconKey,
  keyboard: IconKeyboard,
  'keyword-overview': IconKeywordOverview,
  keywords: IconKeywords,
  'knowledge-card': IconKnowledgeCard,
  'knowledge-panel': IconKnowledgePanel,
  'less-than': IconLessThan,
  link: IconLink,
  'live-stream': IconLiveStream,
  loading: IconLoading,
  'local-teaser-pack': IconLocalTeaserPack,
  'location-2': IconLocation2,
  'location-globe-view': IconLocationGlobeView,
  'location-path-2': IconLocationPath2,
  location: IconLocation,
  lock: IconLock,
  'logo-brand': IconLogoBrand,
  'logo-watermark': IconLogoWatermark,
  logo: IconLogo,
  'main-menu-affiliate': IconMainMenuMainMenuAffiliate,
  'main-menu-desktop': IconMainMenuMainMenuDesktop,
  'main-menu-domains': IconMainMenuMainMenuDomains,
  'main-menu-groups': IconMainMenuMainMenuGroups,
  'main-menu-heart-handshake': IconMainMenuMainMenuHeartHandshake,
  'main-menu-linked-accounts': IconMainMenuMainMenuLinkedAccounts,
  'main-menu-paused-domains': IconMainMenuMainMenuPausedDomains,
  'main-menu-report': IconMainMenuMainMenuReport,
  'main-menu-sales': IconMainMenuMainMenuSales,
  'main-menu-settings': IconMainMenuMainMenuSettings,
  map: IconMap,
  'menu-accounts-requests': IconMenuMenuAccountsRequests,
  'menu-api': IconMenuMenuApi,
  'menu-billing': IconMenuMenuBilling,
  'menu-bulkimport': IconMenuMenuBulkimport,
  'menu-calendar-magnify': IconMenuMenuCalendarMagnify,
  'menu-compass-keydis': IconMenuMenuCompassKeydis,
  'menu-competitor-rankings': IconMenuMenuCompetitorRankings,
  'menu-competitors': IconMenuMenuCompetitors,
  'menu-connected-accounts': IconMenuMenuConnectedAccounts,
  'menu-credit-card': IconMenuMenuCreditCard,
  'menu-customers': IconMenuMenuCustomers,
  'menu-desktop': IconMenuMenuDesktop,
  'menu-domains': IconMenuMenuDomains,
  'menu-edit-profile': IconMenuMenuEditProfile,
  'menu-generated-reports': IconMenuMenuGeneratedReports,
  'menu-google-analytics': IconMenuMenuGoogleAnalytics,
  'menu-google-searchconsole': IconMenuMenuGoogleSearchconsole,
  'menu-groups': IconMenuMenuGroups,
  'menu-import-examples': IconMenuMenuImportExamples,
  'menu-invoice': IconMenuMenuInvoice,
  'menu-keyword-overuse': IconMenuMenuKeywordOveruse,
  'menu-keywords': IconMenuMenuKeywords,
  'menu-landingpage': IconMenuMenuLandingpage,
  'menu-linked-accounts': IconMenuMenuLinkedAccounts,
  'menu-money-note': IconMenuMenuMoneyNote,
  'menu-notes': IconMenuMenuNotes,
  'menu-organization-hierarchy': IconMenuMenuOrganizationHierarchy,
  'menu-overview': IconMenuMenuOverview,
  'menu-paused-domains': IconMenuMenuPausedDomains,
  'menu-ranks-history': IconMenuMenuRanksHistory,
  'menu-referral': IconMenuMenuReferral,
  'menu-report-templates': IconMenuMenuReportTemplates,
  'menu-reports': IconMenuMenuReports,
  'menu-sales': IconMenuMenuSales,
  'menu-SERP-features': IconMenuMenuSerpFeatures,
  'menu-server-dollar-2': IconMenuMenuServerDollar2,
  'menu-settings': IconMenuMenuSettings,
  'menu-tags': IconMenuMenuTags,
  'menu-tools': IconMenuMenuTools,
  'menu-transfer-multi-account-domain': IconMenuMenuTransferMultiAccountDomain,
  'menu-users': IconMenuMenuUsers,
  'menu-visitors': IconMenuMenuVisitors,
  'menu-wallet': IconMenuMenuWallet,
  'message-bubble': IconMessageBubble,
  messages: IconMessages,
  'minus-2': IconMinus2,
  minus: IconMinus,
  'mobile-2': IconMobile2,
  'mobile-devices': IconMobileDevices,
  mobile: IconMobile,
  'monitor-2': IconMonitor2,
  monitor: IconMonitor,
  more_vert: IconMoreVert,
  'most-popular-carousel': IconMostPopularCarousel,
  movie: IconMovie,
  multiple_keywords_filter: IconMultipleKeywordsFilter,
  naver: IconNaver,
  'no-filter': IconNoFilter,
  'node-tree': IconNodeTree,
  'note-multiple': IconNoteMultiple,
  'note-single': IconNoteSingle,
  notes: IconNotes,
  NotFoundCurves: IconNotFoundCurves,
  occupation: IconOccupation,
  'ose-minus': IconOseMinus,
  'ose-plus': IconOsePlus,
  pdf: IconPdf,
  pipedrive: IconPipedrive,
  pixelsfromtop: IconPixelsfromtop,
  play: IconPlay,
  'plus-2': IconPlus2,
  'plus-rounded': IconPlusRounded,
  plus: IconPlus,
  'progress-checked': IconProgressChecked,
  'qa-page-mobile': IconQaPageMobile,
  'question-rounded-filled': IconQuestionRoundedFilled,
  rank: IconRank,
  recipe: IconRecipe,
  'refine-carousel': IconRefineCarousel,
  refresh: IconRefresh,
  'related-searches-carousel': IconRelatedSearchesCarousel,
  'related-searches': IconRelatedSearches,
  release: IconRelease,
  remove: IconRemove,
  report: IconReport,
  'rewards-trophy': IconRewardsTrophy,
  'rich-cards': IconRichCards,
  save: IconSave,
  save2: IconSave2,
  'search-2': IconSearch2,
  'search-by-photos': IconSearchByPhotos,
  'search-intent-commercial': IconSearchIntentSearchIntentCommercial,
  'search-intent-informational': IconSearchIntentSearchIntentInformational,
  'search-intent-main-search-intent-icon': IconSearchIntentSearchIntentMainSearchIntentIcon,
  'search-intent-navigational': IconSearchIntentSearchIntentNavigational,
  'search-intent-transactional': IconSearchIntentSearchIntentTransactional,
  'search-volume': IconSearchVolume,
  search: IconSearch,
  settings: IconSettings,
  'shopping-cart-favorite-star': IconShoppingCartFavoriteStar,
  'shopping-cart-information': IconShoppingCartInformation,
  'sign-out': IconSignOut,
  'site-links-searchbox': IconSiteLinksSearchbox,
  snippet: IconSnippet,
  'social-media-facebook_circle': IconSocialMediaSocialMediaFacebookCircle,
  'social-media-google_circle': IconSocialMediaSocialMediaGoogleCircle,
  'social-media-instagram_circle': IconSocialMediaSocialMediaInstagramCircle,
  'social-media-linkedin_circle': IconSocialMediaSocialMediaLinkedinCircle,
  'social-media-microsoft': IconSocialMediaSocialMediaMicrosoft,
  'social-media-tiktok_circle': IconSocialMediaSocialMediaTiktokCircle,
  'social-media-x_circle': IconSocialMediaSocialMediaXCircle,
  'social-profile': IconSocialProfile,
  'soft-tag': IconSoftTag,
  'software-app': IconSoftwareApp,
  sov_max: IconSovMax,
  sov: IconSov,
  speechbubble: IconSpeechbubble,
  'speedometer-green': IconSpeedometerGreen,
  'speedometer-orange': IconSpeedometerOrange,
  'speedometer-red': IconSpeedometerRed,
  spellcheck: IconSpellcheck,
  star: IconStar,
  subdomain: IconSubdomain,
  summ: IconSumm,
  table: IconTable,
  'tag-cloud-chevron-down': IconTagCloudTagCloudChevronDown,
  'tag-cloud-chevron-right': IconTagCloudTagCloudChevronRight,
  'tag-cloud-edit': IconTagCloudTagCloudEdit,
  'tag-cloud-folder-plus': IconTagCloudTagCloudFolderPlus,
  'tag-cloud-folder': IconTagCloudTagCloudFolder,
  'tag-cloud-serp-features': IconTagCloudTagCloudSerpFeatures,
  'tag-cloud-tags': IconTagCloudTagCloudTags,
  'tag-cloud-trash': IconTagCloudTagCloudTrash,
  'tag-plus': IconTagPlus,
  tag: IconTag,
  tags: IconTags,
  'things-todo': IconThingsTodo,
  thumbnail: IconThumbnail,
  thumbnails: IconThumbnails,
  tiger: IconTiger,
  'title-length-description-length': IconTitleLengthDescriptionLength,
  'traffic-light-green': IconTrafficLightGreen,
  'traffic-light-yellow': IconTrafficLightYellow,
  trafficvalue_max: IconTrafficvalueMax,
  trafficvalue: IconTrafficvalue,
  'travel-departure': IconTravelDeparture,
  'tree-cursor-click-double': IconTreeTreeCursorClickDouble,
  'tree-cursor-click': IconTreeTreeCursorClick,
  'tree-cursor-move-all-direction': IconTreeTreeCursorMoveAllDirection,
  'tree-zoom-in': IconTreeTreeZoomIn,
  tweet: IconTweet,
  'two-users': IconTwoUsers,
  unlink: IconUnlink,
  'upload-cloud': IconUploadCloud,
  url: IconUrl,
  users: IconUsers,
  'video-carousel': IconVideoCarousel,
  view: IconView,
  'window-checked': IconWindowChecked,
  'window-search': IconWindowSearch,
  window: IconWindow,
  'world-off': IconWorldOff,
  wrench: IconWrench,
  x: IconX,
  xls: IconXls,
  yandex: IconYandex,
  'youtube-ads': IconYoutubeAds,
  'youtube-age': IconYoutubeAge,
  'youtube-channel': IconYoutubeChannel,
  'youtube-live-stream': IconYoutubeLiveStream,
  'youtube-movie': IconYoutubeMovie,
  'youtube-playlist': IconYoutubePlaylist,
  'youtube-promoted-video': IconYoutubePromotedVideo,
  'youtube-radio': IconYoutubeRadio,
  'youtube-related-movies': IconYoutubeRelatedMovies,
  'youtube-related-searches': IconYoutubeRelatedSearches,
  'youtube-related-to-your-search': IconYoutubeRelatedToYourSearch,
  'youtube-secondary-search-container': IconYoutubeSecondarySearchContainer,
  'youtube-shelf-render': IconYoutubeShelfRender,
  'youtube-show': IconYoutubeShow,
  'youtube-subscribers': IconYoutubeSubscribers,
  'youtube-video-count': IconYoutubeVideoCount,
  'youtube-video': IconYoutubeVideo,
  'youtube-views': IconYoutubeViews,
  'youtube-watching': IconYoutubeWatching,
  youtube: IconYoutube,
};
