import config from 'config';

export const graphqlEndpoint = (): string => {
  const hostname = window?.location?.hostname;

  if (hostname.includes('develop.dev.servers.ac')) {
    return `http://${hostname}/graphql`;
  }

  return `${config.defaultGraphqlEndpoint}`;
};

export const graphqlWebSocketEndpoint = (): string => {
  const hostname = window?.location?.hostname;

  if (hostname.includes('develop.dev.servers.ac')) {
    return `ws://${hostname}/graphql-ws`;
  }

  return `${config.defaultGraphqlWebSocketEndpoint}`;
};

export const importWebSocketEndpoint = (): string => {
  const hostname = window?.location?.hostname;

  if (hostname.includes('develop.dev.servers.ac')) {
    return `ws://${hostname}/import-ws`;
  }

  return `${config.defaultImportWebSocketEndpoint}`;
};
