import { useRef } from 'react';
import { Flex, Group, useMantineTheme } from '@mantine/core';
import { IconX } from '@tabler/icons-react';
import cn from 'classnames';
import { TooltipFormatterContextObject } from 'highcharts';
import moment from 'moment';
import AccActionIcon from 'Components/AccActionIcon/AccActionIcon';
import AccButton from 'Components/AccButton/AccButton';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import { ColumnChart } from 'Components/Chart/ColumnChart/ColumnChart';
import { toMonth } from 'Components/Chart/ColumnChart/support/hooks/useColumnChartConfig';
import ExportButton from 'Components/ExportButton';
import AccTitle from 'Components/Title/AccTitle';
import { DEFAULT_BACKEND_DATE_FORMAT } from 'Constants';
import {
  useKeywordsAiSearchVolumeQuery,
  useKeywordsSearchVolumeQuery,
  useUpdateAiSvMutation,
} from 'Ghql';
import { useFilters } from 'Hooks';
import { useHasEnabledNewFeatures } from 'Hooks/data/organization/useOrganizationInfo';
import { colorScheme } from 'Utilities/colors';
import { t, tct } from 'Utilities/i18n';
import styles from './searchVolume.module.scss';

const toYear = (date: number) => {
  return moment.unix(date / 1000).year();
};

function tooltipFormatter(item: TooltipFormatterContextObject) {
  const points = item?.points?.[0].series.points;
  if (!points) return '';
  const x = item.x as number;
  const sameMonthPoints = points?.filter((e) => toMonth(x) === toMonth(Number(e?.category)));
  const colorOrange = `color: ${colorScheme.orange}`;

  const header = sameMonthPoints
    .map(
      (e) =>
        `<div class='${styles.tooltipHeader}' style='${x === e.x ? colorOrange : ''}'>${toYear(
          e?.x,
        )}</div>`,
    )
    .join('');
  const row = sameMonthPoints
    .map((e) => `<div style='${x === e.x ? colorOrange : ''}'>${e?.y?.toLocaleString()}</div>`)
    .join('');

  //Global classnames added to prevent styling from global table styles
  return `<div class='${styles.tooltip}'>
  <div class='${styles.tooltipRow}'>
    <div class='${styles.tooltipHeader}'></div>
    ${header}
  </div>
  <div class='${styles.tooltipRow}'>
    <div class='${styles.tooltipHeader}'>${moment(new Date(x)).format('MMM')}</div>
    ${row}
  </div>
</div>`;
}

const aiTooltipFormatter = (item: TooltipFormatterContextObject) => {
  const desktop = item?.points?.find((e) => e?.series?.name === 'Desktop')?.y ?? 0;
  const mobile = item?.points?.find((e) => e?.series?.name === 'Mobile')?.y ?? 0;
  const total = desktop + mobile;
  return `
<div class="${styles.tooltip}" >
  <div class='${styles.tooltipRow}'>
    <div style='font-weight: bold'>${
      item?.x ? moment(new Date(item?.x)).format('MMM YY') : undefined
    }</div>
  </div>
  <div class='${styles.tooltipRow}'>
    <div class='${styles.tooltipHeader}'>${t('Desktop')}</div>
    <div>${desktop?.toLocaleString()}</div>
  </div>
  <div class='${styles.tooltipRow}'>
    <div class='${styles.tooltipHeader}'>${t('Mobile')}</div>
    <div>${mobile?.toLocaleString()}</div>
  </div>
  <div class='${cn(styles.tooltipRow, styles.tooltipFooter)}'>
    <div class='${styles.tooltipHeader}'>${t('Total')}</div>
    <div>${total?.toLocaleString()}</div>
  </div>
</div>`;
};

type Props = {
  keywordId: number;
  height: number;
  useAi?: boolean;
  toggleExpand?: () => void;
};

const SearchVolumeContent = (props: Props) => {
  const filters = useFilters();

  const theme = useMantineTheme();

  const exportContainer = useRef<HTMLDivElement>(null);

  const { keywordId, height, useAi } = props;

  const variables = {
    filters: [
      ...filters,
      {
        attribute: 'keywords',
        type: 'list',
        comparison: 'contains',
        value: `[${keywordId}]`,
      },
    ],
    pagination: {
      page: 1,
      results: 10,
    },
    ordering: {
      order: 'ASC',
      orderBy: 'keyword',
    },
  };
  const { data: svData, loading: svLoading } = useKeywordsSearchVolumeQuery({
    variables,
    skip: useAi,
  });
  const {
    data: aiSvData,
    loading: aiSvLoading,
    refetch,
  } = useKeywordsAiSearchVolumeQuery({
    variables,
    skip: !useAi,
  });

  const [updateAiSv] = useUpdateAiSvMutation();

  const data = useAi ? aiSvData : svData;
  const loading = useAi ? aiSvLoading : svLoading;
  const dataKey = useAi ? 'aiSearchVolume' : 'searchVolume';

  const resultKeyword = data?.keywords?.keywords?.[0]?.keyword;

  const chartData =
    data?.keywords?.keywords?.[0]?.[dataKey]?.history?.map((e) => ({
      value: e?.volume ?? 0,
      date: moment(e?.month ?? '', DEFAULT_BACKEND_DATE_FORMAT)
        .toDate()
        .getTime(),
    })) ?? [];
  let series: any[] = [];

  if (useAi) {
    const desktopData =
      data?.keywords?.keywords?.[0]?.[dataKey]?.history?.map((e) => ({
        value: e?.volumeDesktop ?? 0,
        date: moment(e?.month ?? '', DEFAULT_BACKEND_DATE_FORMAT)
          .toDate()
          .getTime(),
      })) ?? [];

    const mobileData =
      data?.keywords?.keywords?.[0]?.[dataKey]?.history?.map((e) => ({
        value: e?.volumeMobile ?? 0,
        date: moment(e?.month ?? '', DEFAULT_BACKEND_DATE_FORMAT)
          .toDate()
          .getTime(),
      })) ?? [];

    series = series.concat([
      {
        type: 'column',
        data: desktopData,
        title: t('Desktop'),
        color: theme.primaryColor,
      },
      {
        type: 'column',
        data: mobileData,
        title: t('Mobile'),
        color: theme.colors.blue[5],
      },
    ]);
  }
  const newFeaturesEnabled = useHasEnabledNewFeatures();
  const showRefreshButton = useAi && newFeaturesEnabled;

  return (
    <div>
      <Flex align={'center'} justify={'space-between'} pr={16}>
        <AccTitle p={16} type="h5">
          {resultKeyword
            ? tct('Historic [ai]Search Volume for "[keyword]"', {
                keyword: resultKeyword,
                ai: useAi ? t('AI ') : '',
              })
            : tct('Historic [ai]Search Volume', { ai: useAi ? t('AI ') : '' })}
        </AccTitle>
        <Group gap="sm">
          {showRefreshButton && (
            <>
              <span>Buttons only shown for AccuRanker users:</span>
              <AccButton
                size={'sm'}
                onClick={() => {
                  updateAiSv({ variables: { keywordId: keywordId.toString() } });
                }}
              >
                Refresh AI SV History
              </AccButton>
              <AccButton
                onClick={() => {
                  refetch();
                }}
              >
                Refetch
              </AccButton>
              <AccButton
                href={`https://trends.google.com/trends/explore?date=today%205-y&geo=GB&q=${resultKeyword}&hl=en`}
              >
                Trends
              </AccButton>
            </>
          )}
          <ExportButton
            content={() => exportContainer.current}
            fileName={`AccuRanker_historic_search_volume_chart_${resultKeyword?.replaceAll(
              ' ',
              '_',
            )}_${moment().format('YYYY-MM-DD')}`}
          />
          {props.toggleExpand ? (
            <AccTooltip tooltip={t('Collapse expanded row')}>
              <AccActionIcon size={'lg'} variant="subtle" onClick={props.toggleExpand}>
                <IconX />
              </AccActionIcon>
            </AccTooltip>
          ) : null}
        </Group>
      </Flex>
      <Group ref={exportContainer} ml={8} mr={16}>
        <div style={{ flexGrow: 1, minWidth: 0 }}>
          {/* Key used to force rendering proper chart type */}
          {useAi ? (
            <ColumnChart
              key="aiSearchVolumeChart"
              items={series}
              loading={loading}
              tooltipFormatter={aiTooltipFormatter}
              colWidth={10}
              showLegend
              height={height}
              stack={true}
            />
          ) : (
            <ColumnChart
              key="SearchVolumeChart"
              items={[
                {
                  type: 'column',
                  data: chartData,
                  title: 'Search Trend:',
                  color: `${theme.colors.orange[5]}`,
                },
              ]}
              loading={loading}
              tooltipFormatter={tooltipFormatter}
              colWidth={10}
              height={height}
            />
          )}
        </div>
      </Group>
    </div>
  );
};

export default SearchVolumeContent;
