import { FormattedNumber } from 'react-intl';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { ColumnType, DataTable, TableOrder } from 'Components/DataTable';
import Icon from 'Components/Icon';
import { InvoiceNode } from 'Ghql';
import { SORTING_TYPE } from 'Pages/Keywords/Table/hooks/keyword/constants';
import { TableIDs } from 'Types/Table';
import { t } from 'Utilities/i18n';
import CheckIcon from 'icons/check.svg?inline';
import LocaleSelector from '../../Selectors/LocaleSelector';
import * as Sort from '../../Types/Sort';
import { ColumnIDs } from './support/ColumnIDs';
import { useFetchInvoices } from './support/hooks';
import styles from './support/invoiceTable.module.scss';

const useInvoiceColumns = () => {
  const fullLocale = useSelector(LocaleSelector);
  const columns: ColumnType<Partial<InvoiceNode>>[] = [
    {
      id: ColumnIDs.DATE,
      width: 100,
      title: t('Date'),
      cellRenderer: ({ record }) => (
        <span>{moment(record.createdAt).locale(fullLocale).format('ll')}</span>
      ),
      onHeaderCell: () => ({
        ordering: {
          defaultOrder: TableOrder.DESC,
          orderBy: 'createdAt',
          sortingKey: SORTING_TYPE.recurency,
        },
      }),
    },
    {
      id: ColumnIDs.INVOICE_NUMBER,
      title: t('Invoice Number'),
      width: 100,
      cellRenderer: ({ record }) => (
        <a href={record.url} target="_blank" rel="noopener noreferrer" className="table-link">
          {record.number}
        </a>
      ),
      onHeaderCell: () => ({
        ordering: {
          defaultOrder: TableOrder.DESC,
          orderBy: ColumnIDs.INVOICE_NUMBER,
          sortingKey: SORTING_TYPE.numerical,
        },
      }),
    },
    {
      id: ColumnIDs.AMOUNT,
      title: t('Amount Excl. VAT'),
      width: 120,
      cellRenderer: ({ record }) => (
        <FormattedNumber
          style="currency"
          value={record?.amount}
          currency={record?.currency}
          currencyDisplay="code"
        />
      ),
      onHeaderCell: () => ({
        ordering: {
          defaultOrder: TableOrder.DESC,
          orderBy: ColumnIDs.AMOUNT,
          sortingKey: SORTING_TYPE.numerical,
        },
      }),
    },
    {
      id: ColumnIDs.VAT,
      title: t('VAT'),
      width: 100,
      cellRenderer: ({ record }) => (
        <FormattedNumber
          style="currency"
          value={record?.vat}
          currency={record?.currency}
          currencyDisplay="code"
        />
      ),
      onHeaderCell: () => ({
        ordering: {
          defaultOrder: TableOrder.DESC,
          orderBy: ColumnIDs.VAT,
          sortingKey: SORTING_TYPE.numerical,
        },
      }),
    },
    {
      id: ColumnIDs.TOTAL,
      width: 100,
      title: t('Total'),
      cellRenderer: ({ record }) => (
        <FormattedNumber
          style="currency"
          value={record?.total}
          currency={record?.currency}
          currencyDisplay="code"
        />
      ),
      onHeaderCell: () => ({
        ordering: {
          defaultOrder: TableOrder.DESC,
          orderBy: ColumnIDs.TOTAL,
          sortingKey: SORTING_TYPE.numerical,
        },
      }),
    },
    {
      id: ColumnIDs.PAID,
      title: t('Paid'),
      width: 70,
      cellRenderer: ({ record }) =>
        (record?.paid ? <Icon className={styles.icon} tooltip={t('Paid')} icon={CheckIcon} /> : null),
      onHeaderCell: () => ({
        ordering: {
          defaultOrder: TableOrder.DESC,
          orderBy: ColumnIDs.PAID,
          sortingKey: SORTING_TYPE.numerical,
        },
      }),
      className: 'no-border',
    },
  ];
  return columns;
};
export const InvoicesTable = ({ organizationId }: { organizationId?: string | number }) => {
  const fetchData = useFetchInvoices(organizationId);
  const columns = useInvoiceColumns();

  return (
    <DataTable
      tableId={TableIDs.INVOICES}
      fetchData={fetchData}
      columns={columns}
      defaultOrdering={{
        order: TableOrder.DESC,
        orderBy: 'createdAt',
      }}
      offlineFilter={{
        tableName: TableIDs.INVOICES,
        mappings: {
          [ColumnIDs.DATE]: 'createdAt',
          [ColumnIDs.INVOICE_NUMBER]: 'number',
          [ColumnIDs.AMOUNT]: 'amount',
          [ColumnIDs.VAT]: 'vat',
          [ColumnIDs.TOTAL]: 'total',
          [ColumnIDs.PAID]: 'paid',
        },
        sortTypes: {
          createdAt: Sort.DATE,
          number: Sort.NATURAL,
          amount: Sort.NATURAL,
          vat: Sort.NATURAL,
          total: Sort.NATURAL,
          paid: Sort.NATURAL,
        },
        skipAll: true,
      }}
      pageSize={10}
      borderless
    />
  );
};
