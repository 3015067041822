/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useEffect, useMemo, useState } from 'react';
import {
  DndContext,
  DragOverlay,
  pointerWithin,
  rectIntersection,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { arrayMove, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { useGlobalDragHandle } from 'Hooks/useGlobalDragHandle';
import SortableContainer from 'Pages/Keywords/Overview/NextChartsDashboard/DraggableChartListV2/SortableContainer';
import {
  KeyboardSensor,
  MouseSensor,
} from 'Pages/Keywords/Overview/NextChartsDashboard/DraggableChartListV2/support/sensors';
import { filterColumnCharts } from 'Pages/Keywords/Overview/NextChartsDashboard/DraggableChartListV2/utils';
import { OverviewAddChart } from 'Pages/Keywords/Overview/components/AddCharts/AddCharts';
import { CHART_NAMES } from 'Pages/Keywords/Overview/components/ChartBox';
import styles from 'Pages/Keywords/Overview/styles.module.scss';
import { EventName, useMixpanel } from 'Utilities/Analytics/mixpanel';
import { devError } from 'Utilities/log';
import SortableItem from './SortableItem';

const wrapperStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  width: 'calc(100% + 20px)',
  marginLeft: '-10px',
};

/**
 * Custom collision detection algorithm that combines pointerWithin and rectIntersection
 * So that we handle proper dnd for:
 * - empty column
 * - case when we have long short column and we drop at the from long to short at the bottom
 * https://docs.dndkit.com/api-documentation/context-provider/collision-detection-algorithms#composition-of-existing-algorithms
 * @param args
 */
function customCollisionDetectionAlgorithm(args) {
  // First, let's see if there are any collisions with the pointer
  const pointerCollisions = pointerWithin(args);

  // Collision detection algorithms return an array of collisions
  if (pointerCollisions.length > 0) {
    return pointerCollisions;
  }

  // If there are no collisions with the pointer, return rectangle intersections
  return rectIntersection(args);
}

export type DraggableItemProps = {
  addItem: (id: string, index: number, columnId: string | undefined) => void;
  removeChart: (id: any) => void;
  allProps: any;
  addColumnOptions: string[];
  disableDnd: boolean | undefined;
};

interface DndBoardProps {
  allProps: any;
  leftChartNames: string[];
  rightChartNames: string[];
  saveCharts?: (fn: (state: any) => void) => void;
  disableEdit?: boolean;
}
export default function DndBoard(props: DndBoardProps) {
  const { allProps, leftChartNames, rightChartNames } = props;
  const [initItems, setInitItems] = useState({
    leftColumn: (leftChartNames ?? []).filter(filterColumnCharts),
    rightColumn: (rightChartNames ?? []).filter(filterColumnCharts),
  });

  const [activeId, setActiveId] = useState<null | string>();

  useGlobalDragHandle(!!activeId);

  const items = useMemo(() => {
    return {
      leftColumn: initItems.leftColumn,
      rightColumn: initItems.rightColumn,
    };
  }, [initItems]);

  useEffect(() => {
    if (items.leftColumn.some((e) => items.rightColumn.some((el) => el === e))) {
      devError(
        `Duplicate chart names in left and right columns. This shouldn't happen. ${JSON.stringify(
          items,
        )}`,
      );
    }
  }, [items]);
  const trackEvent = useMixpanel();

  const trackDrag = () => {
    const extraProps = {
      Variant: 'Chart',
    };
    trackEvent(EventName.CustomizeDashboardDrag, extraProps);
  };

  const trackEditWidget = (variant: string, id: string) => {
    const extraProps = {
      Variant: variant,
      ID: id,
    };
    trackEvent(EventName.EditWidget, extraProps);
  };

  const setItems = (fn) => {
    const getNewItems = (prevItems) => {
      const nextItems = fn(prevItems);
      return {
        leftColumn: nextItems.leftColumn.filter(filterColumnCharts),
        rightColumn: nextItems.rightColumn.filter(filterColumnCharts),
      };
    };
    props.saveCharts?.(getNewItems);
    setInitItems(getNewItems);
  };

  const combinedColumns = useMemo(
    () => [...(items.leftColumn || []), ...(items.rightColumn || [])],
    [items.leftColumn, items.rightColumn],
  );
  const addColumnOptions = useMemo(
    () =>
      Object.values(CHART_NAMES)
        .filter((id) => !combinedColumns?.includes(id))
        .filter(filterColumnCharts),
    [combinedColumns],
  );

  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const itemProps: DraggableItemProps = useMemo(
    () => ({
      addItem,
      removeChart,
      allProps,
      addColumnOptions,
      disableDnd: props.disableEdit,
    }),
    [allProps, addColumnOptions, props.disableEdit],
  );

  return (
    <>
      <div style={wrapperStyle} className={props.disableEdit ? 'overview-board--disabled-dnd' : ''}>
        <DndContext
          sensors={sensors}
          collisionDetection={customCollisionDetectionAlgorithm}
          onDragStart={handleDragStart}
          onDragOver={handleDragOver}
          onDragEnd={handleDragEnd}
        >
          <SortableContainer
            id="leftColumn"
            items={items.leftColumn}
            addColumnOptions={addColumnOptions}
            itemProps={itemProps}
          />
          <SortableContainer
            id="rightColumn"
            items={items.rightColumn}
            addColumnOptions={addColumnOptions}
            itemProps={itemProps}
          />
          <DragOverlay>
            {activeId ? <SortableItem id={activeId} {...itemProps} placeholder /> : null}
          </DragOverlay>
        </DndContext>
      </div>
      {!props.disableEdit && (
        <div className={styles.container}>
          <div className={styles.fullWidthColumn}>
            <OverviewAddChart columns={combinedColumns} addColumn={addColumn} />
          </div>
        </div>
      )}
    </>
  );

  function removeChart(id) {
    trackEditWidget('Remove', id);
    setItems((state) => ({
      leftColumn: state.leftColumn.filter((e) => e !== id),
      rightColumn: state.rightColumn.filter((e) => e !== id),
    }));
    setActiveId(null);
  }
  function addItem(id, index, columnId) {
    const newState = { ...items };
    trackEditWidget('Add', id);

    // Determine the target column array
    const targetColumnArray = newState[columnId];

    if (index === -1) {
      newState[columnId] = [...targetColumnArray, id];
    } else {
      newState[columnId] = [
        ...targetColumnArray.slice(0, index),
        id,
        ...targetColumnArray.slice(index),
      ];
    }
    setItems((state) => ({ ...state, ...newState }));
    setActiveId(null);
  }

  function addColumn(id) {
    addItem(id, -1, 'leftColumn');
  }
  function findContainer(id) {
    if (id in items) {
      return id;
    }

    return Object.keys(items).find((key) => items[key].includes(id));
  }
  function handleDragStart(event) {
    const { active } = event;
    const { id } = active;

    setActiveId(id);
  }
  function handleDragOver(event) {
    const { active, over, draggingRect } = event;
    const { id } = active;
    const overId = over?.id;

    // Find the containers
    const activeContainer = findContainer(id);
    const overContainer = findContainer(overId);

    if (!activeContainer || !overContainer || activeContainer === overContainer) {
      return;
    }

    setItems((prev) => {
      const activeItems = prev[activeContainer];
      const overItems = prev[overContainer];

      // Find the indexes for the items
      const activeIndex = activeItems.indexOf(id);
      const overIndex = overItems.indexOf(overId);

      let newIndex;
      if (overId in prev) {
        // We're at the root droppable of a container
        newIndex = overItems.length + 1;
      } else {
        const isBelowLastItem =
          over &&
          overIndex === overItems.length - 1 &&
          (draggingRect?.offsetTop || 0) > over.rect.offsetTop + over.rect.height;

        const modifier = isBelowLastItem ? 1 : 0;

        newIndex = overIndex >= 0 ? overIndex + modifier : overItems.length + 1;
      }

      return {
        ...prev,
        [activeContainer]: [...prev[activeContainer].filter((item) => item !== active.id)],
        [overContainer]: [
          ...prev[overContainer].slice(0, newIndex),
          items[activeContainer][activeIndex],
          ...prev[overContainer].slice(newIndex, prev[overContainer].length),
        ],
      };
    });
  }
  function handleDragEnd(event) {
    const { active, over } = event;
    const { id } = active;
    const overId = over?.id;

    trackDrag();

    const activeContainer = findContainer(id);
    const overContainer = findContainer(overId);

    if (!activeContainer || !overContainer || activeContainer !== overContainer) {
      return;
    }

    const activeIndex = items[activeContainer].indexOf(active.id);
    const overIndex = items[overContainer].indexOf(overId);

    if (activeIndex !== overIndex) {
      setItems((list) => ({
        ...list,
        [overContainer]: arrayMove(list[overContainer], activeIndex, overIndex),
      }));
    }

    setActiveId(null);
  }
}
