import { FormEventHandler, ForwardedRef, MouseEventHandler, forwardRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { ButtonProps, FlexProps, Button as MantineButton } from '@mantine/core';
import cn from 'classnames';
import { EventName, TrackingKey, useMixpanel } from 'Utilities/Analytics/mixpanel';
import ConditionalTooltipWrapper from '../ConditionalTooltipWrapper/ConditionalTooltipWrapper';
import styles from './accButton.module.scss';

type variantType = 'primary' | 'secondary' | 'tertiary' | 'destructive' | 'success';
type variantProperties = {
  color: string;
  variant: ButtonProps['variant'];
};

const variantProps: Record<variantType, variantProperties> = {
  primary: {
    color: 'orange',
    variant: 'filled',
  },
  secondary: {
    color: 'snorlax',
    variant: 'subtle',
  },
  tertiary: {
    color: 'snorlax',
    variant: 'subtle',
  },
  destructive: {
    color: 'red',
    variant: 'filled',
  },
  success: {
    color: 'green',
    variant: 'filled',
  },
};

// eslint-disable-next-line import/no-unused-modules
export type Props = Omit<ButtonProps, 'variant' | 'color'> & {
  variant?: variantType;
  onClick?: MouseEventHandler<HTMLButtonElement> | FormEventHandler<HTMLFormElement>;
  trackingKey?: TrackingKey;
  /**
   * If set to true, disables Analytics event tracking for this button.
   *
   * @default false
   */
  noTracking?: boolean;
  tooltip?: string;
  /** Apply mantine flexProps to the container immediately surrounding the button, if a tooltip is provided  */
  tooltipWrapperProps?: FlexProps;
  /**
   * Used for external links. Opens in a new tab by default (target = '_blank').
   * Use the target prop to change this behaviour.
   */
  href?: string; // External links
  /**
   * Target of the href prop. Defaults to '_blank'.
   * To open a link in the same window, change this prop to '_self'.
   */
  target?: '_self' | '_blank';
  /**
   * Used for internal links.
   */
  link?: string; // Internal links
  /**
   * optional state sent with react-router navigate
   */
  linkState?: any;
  type?: HTMLButtonElement['type'];
  id?: string;
};

// extract text content from simple components
const textContent = (elem): string => (elem && typeof elem === 'string' ? elem : '');

const AccButton = forwardRef((props: Props, ref: ForwardedRef<any>) => {
  const {
    variant = 'primary',
    children,
    onClick,
    href,
    target = '_blank',
    link,
    linkState,
    trackingKey,
    noTracking = false,
    tooltip,
    tooltipWrapperProps,
    className,
    type,
    ...rest
  } = props;
  const trackEvent = useMixpanel();

  const navigate = useNavigate();

  const width = props.w ?? props.fullWidth ? '100%' : undefined;

  const trackClick = () => {
    const text = textContent(children);
    const extraProps = {
      Variant: variant,
      ...(text ? { Text: text } : {}),
      ...(trackingKey ? { 'Tracking Key': trackingKey } : {}),
    };
    trackEvent(EventName.ButtonClick, extraProps);
  };

  return (
    <ConditionalTooltipWrapper tooltip={tooltip} tooltipWrapperProps={tooltipWrapperProps}>
      <MantineButton
        ref={ref}
        loaderProps={{ type: 'dots' }}
        type={type}
        data-full-width={props.fullWidth || null}
        component={href ? 'a' : 'button'}
        href={href}
        target={target}
        onClick={(e) => {
          noTracking === false && trackClick();
          onClick?.(e);
          if (link) {
            navigate(link, { state: linkState });
          }
        }}
        radius="md"
        w={width}
        className={cn(
          styles.button,
          {
            [styles.primary]: variant === 'primary',
            [styles.secondary]: variant === 'secondary',
            [styles.tertiary]: variant === 'tertiary',
            [styles.destructive]: variant === 'destructive',
            [styles.success]: variant === 'success',
          },
          className,
        )}
        {...variantProps[variant]}
        {...rest}
      >
        {children}
      </MantineButton>
    </ConditionalTooltipWrapper>
  );
});
AccButton.displayName = 'AccButton';

export default AccButton;
