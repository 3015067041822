import React, { memo, useState } from 'react';
import { Group, Indicator, Popover } from '@mantine/core';
import cn from 'classnames';
import isNumber from 'lodash/isNumber';
import AccButton from 'Components/AccButton/AccButton';
import { AccFastIcon } from 'Components/AccFastIcon';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import { TABLE_ICON_SIZE } from 'Components/AccuTable/constants';
import AccText from 'Components/Text/AccText';
import { useFilters, useSerpFeatures } from 'Hooks';
import { getUpdatedArrayFilter, useSetOrOverwriteFilter } from 'Hooks/data/filter/setFilters';
import { store } from 'Store';
import { PAGE_SERP_FEATURES, PAGE_SERP_FEATURES_OWNED } from 'Types/Filter';
import { SerpFeatures } from 'Types/Store';
import { FilterTrackingKey } from 'Utilities/Analytics/mixpanel';
import { t, tct } from 'Utilities/i18n';
import {
  SEARCH_TYPE_NAVER,
  SearchEngineIds,
} from '../../../../Modal/Content/AddSearchEngine/types';
import { getSerpFeatureData } from '../../helpers/serp-features';
import { SerpFeaturesItem } from './types';
import './serp-features-popover.scss';
import './serp-icon.scss';

type SerpIconProps = {
  id: string;
  owned?: boolean | number;
  serpRank?: boolean | number;
  noTooltip?: boolean;
  serpFeatures?: SerpFeaturesItem[] | SerpFeatures;
  searchEngineId?: number;
  disablePopover?: boolean;
  disableInteraction?: boolean;
  size?: number;
  withinPortal?: boolean;
  color?: string;
  ownedColor?: string;
  serpFeatureData?: ReturnType<typeof getSerpFeatureData>;
  navigateToWebpageOnClick?: boolean;
  className?: string;
};

export const SerpFeatureInfo = ({
  children,
  serpFeatureData,
  searchEngineId,
  noTooltip,
  className,
  ...props
}: React.PropsWithChildren<SerpIconProps>) => {
  const [renderPopover, setRenderPopover] = useState(false);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const { id, owned, serpFeatures, serpRank } = props;
  if (!serpFeatureData) {
    const userSerpFeatures = useSerpFeatures();
    serpFeatureData = getSerpFeatureData(id, serpFeatures, userSerpFeatures);
  }
  const setFilter = useSetOrOverwriteFilter();
  const filters = useFilters();

  if (!renderPopover) {
    // We do not want to render whats inside the popover before its necessary.
    // So we do it only when hovering popover target. In mantine v6 this will be supported out of the box by mantine.
    return <div onMouseEnter={() => setRenderPopover(true)}>{children}</div>;
  }

  // Please note the innermost div is necessary, deleting it will ruin the popover!.
  return (
    <Popover
      width={300}
      shadow="xs"
      zIndex={1000}
      onChange={() => setPopoverOpen(!popoverOpen)}
      withinPortal={true}
    >
      <Popover.Target>
        <div className={cn('popover-trigger', className)} data-open={popoverOpen}>
          <AccTooltip
            disable={popoverOpen || !noTooltip}
            tooltip={
              serpFeatureData
                ? t(
                    '%s %s %s',
                    serpFeatureData.label,
                    owned ? t('(owned)') : '',
                    isNumber(serpRank) ? t('- positioned before rank %s', serpRank) : '',
                  )
                : ''
            }
          >
            {children}
          </AccTooltip>
        </div>
      </Popover.Target>
      <Popover.Dropdown>
        <>
          <AccText size="md" fw="bold" mb={0}>
            {tct('[serpLabel] [owned]', {
              serpLabel: serpFeatureData?.label,
              owned: owned ? t('(owned)') : '',
            })}
          </AccText>
          <AccText fs="italic" size="sm" mb={0}>
            {isNumber(serpRank)
              ? tct('Position on SERP: Before rank [serpRank]', {
                  serpRank,
                })
              : ''}
          </AccText>
          <AccText fs="italic" size="sm" mb={8}>
            {isNumber(owned)
              ? tct('Position in Feature: [featurePosition]', {
                  featurePosition: owned,
                })
              : ''}
          </AccText>
          <AccText size="sm" mb={12}>
            {searchEngineId === SearchEngineIds[SEARCH_TYPE_NAVER]
              ? serpFeatureData?.naverDescription || ''
              : serpFeatureData?.description || ''}
          </AccText>
          <Group align="center" justify="space-between">
            {searchEngineId !== 6 && ( // no link for Naver
              <AccText
                size="sm"
                variant="link"
                onClick={() =>
                  serpFeatureData?.url &&
                  window.open(serpFeatureData?.url, '_blank', 'noopener,noreferrer')
                }
              >
                {t('Click to learn more')}
              </AccText>
            )}
            <AccButton
              onClick={() => {
                if (serpFeatureData?.id) {
                  const filterType = owned ? PAGE_SERP_FEATURES_OWNED : PAGE_SERP_FEATURES;
                  setFilter(
                    getUpdatedArrayFilter(filters, serpFeatureData.id, filterType),
                    FilterTrackingKey.SerpPopover,
                  );
                  setRenderPopover(false);
                }
              }}
              variant={'secondary'}
              noTracking
            >
              {t('Add filter')}
            </AccButton>
          </Group>
        </>
      </Popover.Dropdown>
    </Popover>
  );
};

type IconComponentProps = {
  size: number | undefined;
  owned: boolean | undefined;
  ownedColor: string;
  color: string;
  IconTag: string | undefined;
};

const IconComponent = ({ size, owned, ownedColor, color, IconTag }: IconComponentProps) => {
  return (
    <AccFastIcon
      size={size || TABLE_ICON_SIZE}
      fit="contain"
      src={IconTag}
      color={owned ? ownedColor : color}
    />
  );
};

const SerpIconAreEqual = (prevProps: SerpIconProps, nextProps: SerpIconProps) => {
  return (
    prevProps.id === nextProps.id &&
    prevProps.owned === nextProps.owned &&
    prevProps.serpRank === nextProps.serpRank &&
    prevProps.noTooltip === nextProps.noTooltip &&
    prevProps.disablePopover === nextProps.disablePopover &&
    prevProps.size === nextProps.size &&
    prevProps.color === nextProps.color &&
    prevProps.ownedColor === nextProps.ownedColor
  );
};

const SerpIcon = (props: SerpIconProps) => {
  const {
    id,
    owned,
    serpRank,
    serpFeatures,
    searchEngineId,
    disablePopover,
    disableInteraction,
    size,
    color = '#6b6c6c',
    ownedColor = '#f89537',
    className,
  } = props;

  const state = store.getState();
  const userSerpFeatures = state?.metaData?.serpFeatures;
  const serpFeatureData = getSerpFeatureData(id, serpFeatures, userSerpFeatures);
  const IconTag = serpFeatureData?.icon;

  // Please note the innermost div is necessary, deleting it will ruin the popover!.
  const Wrapper = ({ children }: { children: JSX.Element }) => (
    <div className={cn('serp-icon-wrapper', className)}>{children}</div>
  );

  const Icon = () => (
    <IconComponent
      size={size}
      owned={isNumber(owned) ? true : owned}
      ownedColor={ownedColor}
      color={color}
      IconTag={IconTag}
    />
  );

  if (disableInteraction) {
    return <Icon />;
  }
  if (disablePopover) {
    return (
      <Wrapper>
        <Icon />
      </Wrapper>
    );
  }
  return (
    <Wrapper>
      <SerpFeatureInfo {...props} serpFeatureData={serpFeatureData} searchEngineId={searchEngineId}>
        <Indicator
          classNames={{ root: 'indicator-root', indicator: 'indicator-indicator' }}
          disabled={!isNumber(serpRank)}
          size={14}
          label={serpRank}
        >
          <Icon />
        </Indicator>
      </SerpFeatureInfo>
    </Wrapper>
  );
};

export default memo(SerpIcon, SerpIconAreEqual);
