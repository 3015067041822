import React, { useMemo, useState } from 'react';
import { List } from 'react-virtualized';
import { Box, Center, Divider, Flex, Popover } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { IconCaretDownFilled, IconCirclePlus } from '@tabler/icons-react';
import AccButton from 'Components/AccButton/AccButton';
import TextInput from 'Components/Controls/TextInput';
import AccText from 'Components/Text/AccText';
import AccTitle from 'Components/Title/AccTitle';
import { TrackingKey } from 'Utilities/Analytics/mixpanel';
import DropdownRow from './DropdownRow';
import styles from './search-dropdown.module.scss';

export interface GroupsDomainItem {
  displayName: string;
  domain?: string;
  id: string;
}

type Props = {
  targetPlaceholder: string;

  item: GroupsDomainItem;
  items: GroupsDomainItem[];

  dropdownHeader: React.ReactNode;
  dropdownSearchPlaceholder: string;
  dropdownAddButtonLabel: string;

  showAddButton?: boolean;

  onAdd?: (...args: Array<any>) => any;
  onDelete?: (...args: Array<any>) => any;
  onEdit?: (...args: Array<any>) => any;
  onSelect?: (...args: Array<any>) => any;

  dropdownWidth?: number;
};

const GroupsAndDomainsDropdown = (props: Props) => {
  const { targetPlaceholder } = props;
  const { item, items } = props;
  const { dropdownHeader, dropdownSearchPlaceholder, dropdownAddButtonLabel } = props;
  const { showAddButton = false } = props;
  const { onAdd, onDelete, onEdit, onSelect } = props;
  const { dropdownWidth } = props;

  const [opened, setOpened] = useState(false);

  const [searchValue, setSearchValue] = useState('');
  const [searchTerm] = useDebouncedValue(searchValue, 200);

  const filteredItems = useMemo(() => {
    return items?.length
      ? items.filter(
          (currentItem) =>
            currentItem.displayName?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
            currentItem.domain?.toLowerCase().includes(searchTerm?.toLowerCase()),
        )
      : [];
  }, [items, searchTerm]);

  const renderRow = ({ index, key, style }) => {
    const rowItem = filteredItems[index];
    return (
      <DropdownRow
        key={key}
        setOpened={setOpened}
        style={style}
        item={rowItem}
        onDelete={onDelete}
        onEdit={onEdit}
        onSelect={onSelect}
      />
    );
  };

  const createLabel = (): string | React.ReactNode => {
    if (item?.displayName && item?.domain) {
      return (
        <>
          <span className={styles.ellipsis175}>{item.displayName}</span>
          {item.displayName !== item.domain && (
            <AccText fw={300} inline ml="xs" size="sm" overflowEllipsis maw={175}>
              {item.domain}
            </AccText>
          )}
        </>
      );
    }
    return (
      <span className={styles.ellipsis250}>
        {item?.displayName || item?.domain || targetPlaceholder}
      </span>
    );
  };

  return (
    <Popover opened={opened} onChange={setOpened} withinPortal position="bottom-start">
      <Popover.Target>
        <AccButton
          className={styles.dropdownTarget}
          leftSection={
            item?.domain && (
              <img
                className="favicon"
                style={{ height: 14, width: 14 }}
                src={`https://app.accuranker.com/screenshots/favicon?url=${item.domain}&size=24`}
              />
            )
          }
          variant="tertiary"
          trackingKey={TrackingKey.OpenBreadcrumbPopover}
          onClick={() => {
            setOpened((prev) => !prev);
          }}
        >
          {createLabel()} <IconCaretDownFilled style={{ marginLeft: '2px' }} size={12} />
        </AccButton>
      </Popover.Target>

      <Popover.Dropdown w="auto" p={0}>
        <Flex direction="column">
          <Box py="sm" px="md">
            <AccTitle mb="sm" type="h4">
              {dropdownHeader}
            </AccTitle>
            <TextInput
              placeholder={dropdownSearchPlaceholder}
              autoFocus
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              showError={false}
            />
          </Box>

          <Divider color="gray.1" />

          <List
            tabIndex={-1}
            width={dropdownWidth || 400}
            height={300}
            rowCount={filteredItems.length}
            rowHeight={48}
            rowRenderer={renderRow}
            overscanRowCount={20}
          />
          {showAddButton && (
            <>
              <Divider color="gray.1" />
              <Center p="md" py="sm">
                <AccButton
                  variant="tertiary"
                  leftSection={<IconCirclePlus size={18} />}
                  onClick={onAdd}
                >
                  {dropdownAddButtonLabel}
                </AccButton>
              </Center>
            </>
          )}
        </Flex>
      </Popover.Dropdown>
    </Popover>
  );
};

export default GroupsAndDomainsDropdown;
