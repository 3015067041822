import { useApolloClient } from '@apollo/client';
import { Flex, useMantineTheme } from '@mantine/core';
import { captureException } from '@sentry/react';
import { FORM_ERROR, ValidationErrors } from 'final-form';
import { updateDomain } from 'Actions/DomainsAction';
import AccButton from 'Components/AccButton/AccButton';
import { Field, Form } from 'Components/Fields';
import { FormError } from 'Components/Fields/Form/FormError';
import AccTitle from 'Components/Title/AccTitle';
import { UnrankedWeightConstants } from 'Constants';
import { getDisabledDemoText } from 'Constants/messages';
import { SearchVolumeSettingInput, useUpdateDomainDisplaySettingsMutation } from 'Ghql';
import { useQueryDomainInfo } from 'Hooks/data/domain/useQueryDomainInfo';
import { DefaultValue, useDisplayCurrencies, useDisplayCurrency } from 'Hooks/displayCurrency';
import { useActions } from 'Hooks/redux/useActions';
import { useLocalSearch } from 'Hooks/useLocalSearch';
import toast from 'Hooks/useToast';
import { useUnrankedWeight } from 'Hooks/useUnrankedWeight';
import { CPCHelperText } from 'Pages/DomainSettings/support/helpers';
import { useOrganizationSelector } from 'Selectors/OrganizationSelector';
import { t } from 'Utilities/i18n';
import { AverageRankSettings } from './AverageRankSettings';
import { SearchSettings } from './SearchSettings';
import { getUnrankedWeightInput, validateUnrankedWeight } from './utils';

export enum FieldName {
  displayCurrency = 'displayCurrency',
  localSearch = 'localSearch',
  unrankedWeightEnabled = 'unrankedWeightEnabled',
  unrankedWeight = 'unrankedWeight',
}

export enum UnrankedWeightEnabled {
  yes = 'yes',
  no = 'no',
  accountDefault = 'account_default',
}

export function DisplaySettings() {
  const ACCOUNT_DEFAULT = t('Account Default');

  const organization = useOrganizationSelector();

  const topCurrencies = Array.from(
    new Set<string>([
      organization?.primaryCountryCurrency || '',
      'USD',
      'EUR',
      'GBP',
      ACCOUNT_DEFAULT,
    ]),
  );
  const displayCurrencies = useDisplayCurrencies(
    topCurrencies,
    ACCOUNT_DEFAULT,
    DefaultValue.domain,
  );
  const { displayCurrency, domainId } = useDisplayCurrency(false);

  const { localSearchSetting } = useLocalSearch(domainId);

  const [updateDomainSettings] = useUpdateDomainDisplaySettingsMutation();

  const { isDemoDomain } = useQueryDomainInfo();

  const { domainUnrankedWeight, organizationUnrankedWeight } = useUnrankedWeight();

  const isAccountDefault = domainUnrankedWeight === UnrankedWeightConstants.ACCOUNT_DEFAULT;
  const domainUnrankedWeightEnabled = !(
    domainUnrankedWeight === null ||
    (isAccountDefault && organizationUnrankedWeight === null)
  );

  const actions = useActions({ updateDomainDisplayCurrencyAction: updateDomain });

  const initialValues = {
    [FieldName.displayCurrency]: displayCurrency,
    [FieldName.localSearch]: localSearchSetting,
    [FieldName.unrankedWeightEnabled]: isAccountDefault
      ? UnrankedWeightEnabled.accountDefault
      : domainUnrankedWeightEnabled
      ? UnrankedWeightEnabled.yes
      : UnrankedWeightEnabled.no,
    [FieldName.unrankedWeight]: domainUnrankedWeight
      ? domainUnrankedWeight
      : UnrankedWeightConstants.DEFAULT_IF_DISABLED,
  };

  const cache = useApolloClient().cache;

  const onSubmit = async (values: Record<FieldName, any>) => {
    try {
      const newUnrankedWeight = getUnrankedWeightInput(values);
      const result = await updateDomainSettings({
        variables: {
          input: {
            domainId,
            currency: values[FieldName.displayCurrency],
            searchVolumeSetting: values[FieldName.localSearch] as SearchVolumeSettingInput,
            unrankedWeight: newUnrankedWeight,
          },
        },
      });

      const resultDisplayCurrency =
        result.data?.updateDomainSpecialSettings?.domain?.displayCurrency || DefaultValue.domain;

      actions.updateDomainDisplayCurrencyAction(domainId, {
        displayCurrency: resultDisplayCurrency,
        unrankedWeight: newUnrankedWeight,
      });

      toast.success(t('Display settings for domain updated successfully.'));
      cache.reset();
    } catch (errors) {
      captureException(errors);
      return { [FORM_ERROR]: t('Something went wrong. Please try again later.') };
    }
  };

  const theme = useMantineTheme();

  return (
    <Flex direction="column" rowGap="md">
      <AccTitle type="h3">{t('Display Settings')}</AccTitle>
      <Form
        initialValues={initialValues}
        subscription={{
          invalid: true,
          submitting: true,
          pristine: true,
          values: true,
        }}
        validate={(values) => {
          const errors: ValidationErrors = {};
          validateUnrankedWeight(values, errors);
          return errors;
        }}
        onSubmit={onSubmit}
      >
        {({ invalid, submitting, pristine, values }) => {
          return (
            <Flex direction="column" rowGap="md">
              <FormError />

              <SearchSettings />

              <AverageRankSettings
                domainUnrankedWeight={domainUnrankedWeight}
                organizationUnrankedWeight={organizationUnrankedWeight}
                values={values}
              />

              <Flex direction="column" rowGap={theme.other.gutterSm}>
                <AccTitle type="h5" helper={<CPCHelperText />}>
                  {t('CPC Currency')}
                </AccTitle>

                <Field.Select
                  h={36}
                  id="displayCurrency"
                  name="displayCurrency"
                  options={displayCurrencies}
                  searchable
                  groupHidden
                  fieldFormOnlyLabel
                />
              </Flex>

              <AccButton
                type="submit"
                disabled={invalid || submitting || pristine || isDemoDomain}
                tooltip={isDemoDomain ? getDisabledDemoText() : undefined}
                variant="primary"
                mt={'md'}
                ml="auto"
                tooltipWrapperProps={{ ml: 'auto' }}
              >
                {t('Save')}
              </AccButton>
            </Flex>
          );
        }}
      </Form>
    </Flex>
  );
}
