import { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';
import AccButton from 'Components/AccButton/AccButton';
import TopNavbar from 'Pages/Layout/DashboardTemplate/TopNavbar';
import { t } from 'Utilities/i18n';
import { redirectToExternalUrl } from 'Utilities/underdash';
import AccurankerIcon from 'icons/logo-brand.svg';
import Footer from '../FooterEmpty';
import './base-public-page.scss';

type Props = {
  user: {
    isAuthenticated: boolean;
  };
  className?: string;
  children: any;
  showSideNavbar: boolean;
  showFooter: boolean;
};

class BasePublicPage extends Component<Props> {
  renderHeader() {
    const {
      user: { isAuthenticated },
    } = this.props;
    return (
      <Fragment>
        <a href="https://app.accuranker.com/" style={{ display: 'flex' }}>
          <img className="logo" src={AccurankerIcon} alt={'Accuranker API'} />
        </a>
        <div
          className={cn('navigation', {
            'not-auth': !isAuthenticated,
          })}
        >
          {isAuthenticated ? (
            <TopNavbar />
          ) : (
            <AccButton
              variant="primary"
              mr="lg"
              onClick={() => redirectToExternalUrl(`/user/login/?next=${window.location.pathname}`)}
            >
              {t('Sign In')}
            </AccButton>
          )}
        </div>
      </Fragment>
    );
  }

  render() {
    const {
      className,
      children,
      showFooter,
      showSideNavbar,
      user: { isAuthenticated },
    } = this.props;
    const showSidebar = isAuthenticated && showSideNavbar;
    return (
      <div
        className={cn('base-public-page', className, {
          'with-side-navbar': showSidebar,
        })}
      >
        {!showSidebar && <div className="header-placeholder" />}
        <div className="header">{this.renderHeader()}</div>
        <div className="content">
          <div className="main-content">{children}</div>
          {showFooter && <Footer />}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ user }) => ({
  user,
});

export default connect(mapStateToProps)(BasePublicPage);
