import { ColumnSetting } from 'Components/DataTable';
import { useHasEnabledNewFeatures } from 'Hooks/data/organization/useOrganizationInfo';
import { useShouldUseAiSoV } from 'Hooks/useShouldUseAiSoV';
import useUserPermission from 'Hooks/useUserPermission';
import { domainsColumnSettings } from 'Pages/Domains/DomainsTable/support/types';
import { keyDisTableConfig } from 'Pages/KeywordDiscovery/components/KeywordDiscoveryTable/support/constants';
import {
  COMPETITOR_QUERY_OPTIONS,
  competitorTableSettings,
} from 'Pages/Keywords/Competitors/support/constants';
import { FOLDERS_QUERY_OPTIONS, foldersTable } from 'Pages/Keywords/Groupings/support/constants';
import { landingPageTable } from 'Pages/Keywords/LandingPage/components/KeywordLandingTable/support/constants';
import {
  KEYWORD_QUERY_OPTIONS,
  keywordTableConfig,
} from 'Pages/Keywords/Table/hooks/keyword/constants';
import { KeywordColumnID } from 'Pages/Keywords/Table/support/constants';
import { StoreType } from 'Types/Store';
import { TableID, TableIDs } from 'Types/Table';
import { keywordGroupItems } from '../NextTableSettings/support/config';
import { TableSettingGroup } from '../NextTableSettings/support/types';
import { selectFlatColumnIds } from './common/helpers';
import { selectColumns, selectGroups } from './compatibility/helpers';
import { ColumnSettingKeyType, GroupSettingsItem, TableSettingVersion } from './types';

type TableSettingConfigType = {
  selector: (a: StoreType) => string[] | null;
  selectGroups?: (a: StoreType) => GroupSettingsItem[] | null;
  columns?: ColumnSetting[];
  groups?: TableSettingGroup[];
  columnSettingsName?: ColumnSettingKeyType;
  queryOptions?: { [key: string]: boolean };
  isFlat?: boolean;
  disableTableViewType?: boolean;
  groupSettingsName?: string;
  version?: string;
};

const KEYDIS_TABLE_CONFIG: TableSettingConfigType = {
  selector: (state: StoreType) => selectFlatColumnIds(state.user?.defaultKeydisColumns || ''),
  columns: keyDisTableConfig,
  columnSettingsName: 'defaultKeydisColumns',
  isFlat: true,
  queryOptions: KEYWORD_QUERY_OPTIONS,
};

const tableSettingConfig = ({
  accessRestricted,
  shouldUseAiSoV,
  newFeaturesEnabled,
}: {
  accessRestricted?: boolean;
  shouldUseAiSoV?: boolean;
  newFeaturesEnabled?: boolean;
}): Partial<Record<TableSettingId, TableSettingConfigType>> => ({
  [TableIDs.KEYWORDS]: {
    selector: selectColumns(keywordGroupItems, 'defaultKeywordsColumns', 'keywordsGroupOrder'),
    columns: newFeaturesEnabled
      ? keywordTableConfig
      : keywordTableConfig.filter((config) => config.id !== KeywordColumnID.AI_SEARCH_VOLUME),
    columnSettingsName: 'defaultKeywordsColumns',
    isFlat: true,
    queryOptions: KEYWORD_QUERY_OPTIONS,
    selectGroups: selectGroups(keywordGroupItems, 'defaultKeywordsColumns', 'keywordsGroupOrder'),
    groups: keywordGroupItems.map((group) => {
      if (newFeaturesEnabled) {
        return group;
      }
      if (group.options.filter((option) => option.id === KeywordColumnID.AI_SEARCH_VOLUME).length) {
        return {
          ...group,
          options: group.options.filter((option) => option.id !== KeywordColumnID.AI_SEARCH_VOLUME),
        };
      }
      return group;
    }),
    groupSettingsName: 'keywordsGroupOrder',
    version: TableSettingVersion.GROUPS,
  },
  [TableIDs.KEYWORD_DISCOVERY]: KEYDIS_TABLE_CONFIG,
  [TableIDs.RESEARCH]: KEYDIS_TABLE_CONFIG,
  [TableIDs.COMPETITORS]: {
    selector: (state: StoreType) =>
      selectFlatColumnIds(state.user?.defaultCompetitorsColumns || ''),
    columns: competitorTableSettings(accessRestricted),
    columnSettingsName: 'defaultCompetitorsColumns',
    queryOptions: COMPETITOR_QUERY_OPTIONS,
    isFlat: true,
    disableTableViewType: true,
  },

  [TableIDs.LANDING_PAGES]: {
    selector: (state: StoreType) =>
      selectFlatColumnIds(state.user?.defaultLandingPagesColumns || ''),
    columns: landingPageTable,
    columnSettingsName: 'defaultLandingPagesColumns',
    queryOptions: FOLDERS_QUERY_OPTIONS,
    isFlat: true,
  },
  [TableIDs.FOLDERS]: {
    selector: (state: StoreType) =>
      selectFlatColumnIds(state.user?.defaultFoldersColumns || undefined),
    columns: foldersTable,
    columnSettingsName: 'defaultFoldersColumns',
    queryOptions: FOLDERS_QUERY_OPTIONS,
    isFlat: true,
    disableTableViewType: true,
  },
  [TableIDs.DOMAINS]: {
    selector: (state: StoreType) =>
      selectFlatColumnIds(state.user?.defaultHomeColumns || undefined),
    columns: domainsColumnSettings(accessRestricted, shouldUseAiSoV),
    columnSettingsName: 'defaultHomeColumns',
    isFlat: true,
  },
});

export const getTableSetting = ({
  key,
  accessRestricted,
  shouldUseAiSoV,
  newFeaturesEnabled,
}: {
  key: TableSettingId;
  accessRestricted?: boolean;
  shouldUseAiSoV?: boolean;
  newFeaturesEnabled?: boolean;
}): TableSettingConfigType => {
  const result = tableSettingConfig({ accessRestricted, shouldUseAiSoV, newFeaturesEnabled })[key];

  return {
    ...result,
    selector: result?.selector ?? (() => null),
    selectGroups: result?.selectGroups ?? (() => null),
  };
};

export const useTableSetting = (key: TableSettingId): TableSettingConfigType => {
  const shouldUseAiSoV = useShouldUseAiSoV();
  const { userHasWriteAccess } = useUserPermission();
  const newFeaturesEnabled = useHasEnabledNewFeatures();
  const accessRestricted = !userHasWriteAccess;
  return getTableSetting({ key, accessRestricted, shouldUseAiSoV, newFeaturesEnabled });
};

// eslint-disable-next-line @typescript-eslint/ban-types
export type TableSettingId = TableID | (string & {});
