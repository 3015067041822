import { useEffect, useState } from 'react';
import { DataTable, TableOrder } from 'Components/DataTable';
import { ModalPayloadConfig } from 'Reducers/ModalReducer';
import { TableIDs } from 'Types/Table';
import { subscribeToDomain } from 'Utilities/websocket';
import {
  useConnectedAccountsTableColumns,
  useFetchConnectedAccounts,
  useGroups,
} from './support/hooks';

type ConnectedAccountsTableProps = {
  showModal: (config: ModalPayloadConfig | undefined) => void;
  handleUpdateTable: () => void;
  dataKey: number;
};

const ConnectedAccountsTable = ({
  showModal,
  handleUpdateTable,
  dataKey,
}: ConnectedAccountsTableProps): JSX.Element => {
  const [requireRefetch, setRequireRefetch] = useState(false);

  useEffect(() => {
    const subscription = subscribeToDomain(() => {
      setRequireRefetch(true);
    });
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    requireRefetch && setRequireRefetch(false);
  }, [requireRefetch, dataKey]);

  const { adobeGroup, gaGroup, gscGroup } = useGroups(requireRefetch);

  const fetchData = useFetchConnectedAccounts();
  const columns = useConnectedAccountsTableColumns({
    showModal,
    handleUpdateTable,
    adobeGroup,
    gaGroup,
    gscGroup,
  });

  return (
    <DataTable
      tableId={TableIDs.CONNECTED_ACCOUNTS}
      dataKey={`${dataKey.toString()}${requireRefetch}`}
      fetchData={fetchData}
      columns={columns}
      defaultOrdering={{
        order: TableOrder.DESC,
        orderBy: 'description',
      }}
      offlineFilter={{
        tableName: TableIDs.CONNECTED_ACCOUNTS,
        skipAll: true,
      }}
    />
  );
};

export default ConnectedAccountsTable;
