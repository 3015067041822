import { Link } from 'react-router-dom';
import { Progress } from '@mantine/core';
import { IconTrash } from '@tabler/icons-react';
import moment from 'moment';
import AccActionIcon from 'Components/AccActionIcon/AccActionIcon';
import AccBadge from 'Components/AccBadge';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import linkWithFilters from 'Components/Filters/linkWithFilters';
import Skeleton from 'Components/Skeleton';
import {
  ImporterImportStatusChoices,
  useImportPageDeleteImportMutation,
  useImportRedirectLazyQuery,
} from 'Ghql';
import { useModal } from 'Hooks/base/useModal';
import toast from 'Hooks/useToast';
import type { ClientsFilter } from 'Types/Filter';
import { FilterAttribute, FilterComparison, FilterValueType } from 'Types/Filter';
import { DOMAINS_FILTER_SET } from 'Types/FilterSet';
import { t } from 'Utilities/i18n';
import FileName from './filename';
import { getBadgeType, statusToText } from './importer_helpers';
import styles from './table.module.scss';

interface UploadedImport {
  id: string;
  status: ImporterImportStatusChoices;
  progress: number;
  createdAt: string;
  redirectUrl?: string | null | undefined;
  file?: string | null | undefined;
  client?:
    | {
        id: string;
        name: string;
      }
    | null
    | undefined;
}

interface Props {
  uploadedImports: UploadedImport[];
  onDelete: () => void;
  loading: boolean;
}

interface RowProps {
  importUpload: UploadedImport;
  i: number;
  onDelete: () => void;
}

interface FileNameLinkProps {
  importUpload: UploadedImport;
}

const makeDomainsLink = (clientId: string) => {
  const clientsFilter: ClientsFilter = {
    attribute: FilterAttribute.CLIENTS,
    type: FilterValueType.LIST,
    comparison: FilterComparison.CONTAINS,
    value: [clientId],
  };
  return linkWithFilters({
    to: '/domains',
    overwriteFilters: [clientsFilter],
    filterSet: DOMAINS_FILTER_SET,
  });
};

const FileNameLink = ({ importUpload }: FileNameLinkProps): JSX.Element => {
  const [importRedirect] = useImportRedirectLazyQuery();

  const handleRedirectClick = (id: string) => async (e: React.MouseEvent) => {
    e.preventDefault();
    const res = await importRedirect({ variables: { id }, fetchPolicy: 'network-only' });

    if (res.error) {
      toast.error(t('Failed to download the file. Please contact support.'));
    } else {
      window.location.href = res.data?.importRedirect?.redirectUrl as string;
    }
  };

  // files are kept for 14 days - is this one still available for download
  const now = moment();
  const diff = now.diff(moment(importUpload.createdAt), 'days');
  const fileStillExist = diff < 14;

  const fn = <FileName filePath={importUpload?.file} />;

  if (fileStillExist) {
    return (
      <a href={''} onClick={handleRedirectClick(importUpload.id)}>
        {fn}
      </a>
    );
  }

  return (
    <AccTooltip tooltip={t('Files are stored for 14 days')}>
      <span>{fn}</span>
    </AccTooltip>
  );
};
const ImportTableRow = ({ importUpload, i, onDelete }: RowProps) => {
  const { showModal } = useModal();
  const [importPageDeleteImportMutation] = useImportPageDeleteImportMutation({
    variables: {
      input: { id: importUpload.id },
    },
  });

  const handleDeleteImport = () => {
    importPageDeleteImportMutation().then(() => onDelete());
  };

  const showDeleteConfirmation = () => {
    showModal({
      modalType: 'Confirmation',
      modalProps: {
        title: t('Delete Import?'),
        description: t('The import will be permanently deleted.'),
        confirmLabel: t('Delete import'),
        action: handleDeleteImport,
      },
    });
  };

  return (
    <tr key={`importUpload-row-${i}-${importUpload?.id}`}>
      <td>
        <span className={styles.nowrap}>{moment(importUpload.createdAt).format('lll')}</span>
      </td>
      <td>
        <AccBadge type={getBadgeType(importUpload?.status)}>
          {statusToText(importUpload.status, importUpload.progress)}
        </AccBadge>
      </td>
      <td>
        <Progress.Root size="xl">
          <Progress.Section color="snorlax" value={importUpload.progress / 100}>
            <Progress.Label>{`${importUpload.progress / 100}%`}</Progress.Label>
          </Progress.Section>
        </Progress.Root>
      </td>
      <td>
        <FileNameLink importUpload={importUpload} />
      </td>
      <td>
        {importUpload.client && (
          <Link to={makeDomainsLink(importUpload.client.id)}>{importUpload.client.name}</Link>
        )}
      </td>
      <td>
        <Link to={`/import/validate/${importUpload.id}`}>{t('View')}</Link>
        <AccTooltip tooltip={t('Delete')}>
          <AccActionIcon inline ml="xxs" onClick={showDeleteConfirmation}>
            <IconTrash size={18} />
          </AccActionIcon>
        </AccTooltip>
      </td>
    </tr>
  );
};

const ImportTableEmptySpace = () => (
  <tr>
    <td colSpan={6}>
      <div className="table-empty-state">
        <p className="title">{t('No Imports')}</p>
        <p className="sub-title">{t('Start an import above to see the progress here.')}</p>
      </div>
    </td>
  </tr>
);

const sortById = (a, b) => b.id - a.id;

const ImportTable = ({ uploadedImports, onDelete, loading }: Props): JSX.Element => (
  <table className={styles.importTable}>
    <thead>
      <tr>
        <th>{t('Date')}</th>
        <th className={styles.statusColumn}>{t('Status')}</th>
        <th className={styles.progressColumn}>{t('Progress')}</th>
        <th>{t('File')}</th>
        <th>{t('Group')}</th>
        <th className={styles.actionsColumn} />
      </tr>
    </thead>
    {loading ? (
      <Skeleton
        className={styles.importTableSkeleton}
        linesConfig={[
          {
            type: 'text',
            options: {
              width: '100%',
              marginBottom: '10px',
            },
          },
        ]}
      />
    ) : (
      <tbody>
        {uploadedImports.sort(sortById)?.map((ui, i) => (
          <ImportTableRow key={ui.id} importUpload={ui} i={i} onDelete={onDelete} />
        ))}
        {uploadedImports.length <= 0 && <ImportTableEmptySpace />}
      </tbody>
    )}
  </table>
);

export default ImportTable;
