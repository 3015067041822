import { Component } from 'react';
import { connect } from 'react-redux';
import { gql } from '@apollo/client';
import { graphql } from '@apollo/client/react/hoc';
import { Progress } from '@mantine/core';
import compose from 'lodash/flowRight';
import isEmpty from 'lodash/isEmpty';
import uniq from 'lodash/uniq';
import { SubmissionError } from 'redux-form';
import { v4 as uuidv4 } from 'uuid';
import { hideModal, showModal } from 'Actions/ModalAction';
import { TableStoreType, withTableStore } from 'Components/DataTable';
import { linkToKeywordsDomain } from 'Components/Filters/LinkToDomain';
import linkWithFilters from 'Components/Filters/linkWithFilters';
import HelpGuideButton from 'Components/HelpGuideButton';
import { SearchEngineTypes } from 'Components/Modal/Content/AddSearchEngine/types';
import ModalBorder from 'Components/Modal/Layout/ModalBorder';
import AccText from 'Components/Text/AccText';
import {
  KeywordsImportKeydisKeywordsDocument,
  KeywordsImportKeydisKeywordsMutation,
  KeywordsImportKeydisKeywordsMutationVariables,
} from 'Ghql';
import { withDomainInfo } from 'Hooks/data/domain/useQueryDomainInfo';
import toast, { MAX_NOTIFICATION_TIMEOUT } from 'Hooks/useToast';
import { DomainInfo, DomainTypeChoices } from 'Query';
import { filtersSelector } from 'Selectors/FiltersSelector';
import { apolloClient } from 'Store';
import type { FilterBase } from 'Types/Filter';
import { KEYWORDS_FILTER_SET } from 'Types/FilterSet';
import { TableIDs } from 'Types/Table';
import { ClientQuery } from 'Types/support/Graphql';
import { EventName, trackEventMixpanel } from 'Utilities/Analytics/mixpanel';
import { appStorage } from 'Utilities/AppStorage';
import { transformLocalesData } from 'Utilities/Common/search-engines';
import { invalidateCache } from 'Utilities/Graphql/invalidateCache';
import { WithRouter, withRouter } from 'Utilities/Router';
import { ErrorsFromServer, throwSubmitErrors } from 'Utilities/errors';
import { t, tct } from 'Utilities/i18n';
import underdash from 'Utilities/underdash';
import AddKeywordsForm from './AddKeywordsForm';
import { AddKeywordFormType } from './AddKeywordsForm/types';
import AddKeywordsSkeleton from './Skeleton';
import { transformImportKeydisKeywordsInput } from './support/helpers';
import { AddKeywordFormProps, KeydisImportConfig } from './support/types';
import './add-keywords.scss';

export const AddKeywordsMode = {
  IMPORT: 'import',
  DEFAULT: 'default',
};
export type AddKeywordsModalType = 'import' | 'default';

type Props = {
  hideModal: (...args: Array<any>) => any;
  data: any;
  domainId: string;
  addKeywords: (...args: Array<any>) => any;
  hasGSC: Record<string, any>;
  state?: any;
  gscKeywords?: {
    keyword: string;
  }[];
  showHelpOverlay?: boolean;
  refresh: (...args: Array<any>) => any;
  filters: FilterBase[];
  isAllSelected: boolean;
  numResults: number;
  keywordsData: Record<string, any>;
  maxKeywords?: any;
  importKeywords: ClientQuery<
    KeywordsImportKeydisKeywordsMutation,
    KeywordsImportKeydisKeywordsMutationVariables
  >;
  getKeywords?: any;
  featureYoutube: boolean;
  featureBaidu: boolean;
  tableStore?: TableStoreType;
  showModal: (...args: Array<any>) => any;
  mode?: any;
  placeholderKeywords?: {
    keyword: string;
  }[];
  keydisKeywordsCounter?: number;
  selectedCountry: any;
  overwriteFilters?: FilterBase[];
  getKeydisKeywords?: () => any;
  importConfig?: KeydisImportConfig;
  /**Use this prop to redirect to keywordslist after submitting */
  redirectOnSubmit?: boolean;
  domainInfo?: DomainInfo;
} & WithRouter;

class AddKeywords extends Component<Props> {
  state = {
    gscKeywords: null,
    isKeyDis: false,
    placeholderKeywords: this.props.placeholderKeywords,
    selectedCountry: this.props.selectedCountry,
  };
  addKeywordJobId: string = uuidv4();

  componentDidMount() {
    if (this.props.getKeywords) {
      this.props.getKeywords()?.then((e) => {
        this.setState({
          gscKeywords: e?.data?.keywords?.keywords ?? null,
        });
      });
    } else if (this.props.placeholderKeywords) {
      if (this.props.placeholderKeywords.length >= 100) {
        this.setState({
          gscKeywords: [
            ...this.props.placeholderKeywords,
            {
              keyword: '...',
            },
          ],
        });
      } else {
        this.setState({
          gscKeywords: this.props.placeholderKeywords,
        });
      }
    } else if (this.props.getKeydisKeywords) {
      this.props.getKeydisKeywords()?.then((e) => {
        const placeholderKeywordsImmutable = e?.data?.keydisImportInfo?.keydisImportInfo?.keywords;
        // Need to make a mutable version for it to be extensible with ...and xxx more just below.
        const placeholderKeywords = placeholderKeywordsImmutable
          ? [...placeholderKeywordsImmutable]
          : undefined;
        if (placeholderKeywords && placeholderKeywords.length === 500) {
          if (this.props.keydisKeywordsCounter) {
            placeholderKeywords.push({
              keyword: `...and ${
                this.props.keydisKeywordsCounter - placeholderKeywords.length
              } more`,
            });
          }
        }
        const selectedCountry = {
          country: e?.data?.keydisImportInfo?.keydisImportInfo?.country,
          searchSettings: [e?.data?.keydisImportInfo?.keydisImportInfo?.searchSettings],
        };
        this.setState({
          placeholderKeywords,
          selectedCountry,
          gscKeywords: placeholderKeywords,
        });
      });
    }

    if (this.props.mode === AddKeywordsMode.IMPORT) {
      this.setState({
        isKeyDis: true,
      });
    }
  }

  onClose = () => {
    this.props.hideModal();
  };
  openHelperOverlay = () => {
    this.props.hideModal();
  };

  fetchKeydisKeywords = async (data: AddKeywordFormProps, forceAdd: boolean) => {
    const { importKeywords, domainId, overwriteFilters, importConfig } = this.props;
    const filters = overwriteFilters || filtersSelector(this.props.state);
    const input = transformImportKeydisKeywordsInput({
      data,
      selectedCountry: this.state.selectedCountry,
      domainId,
      filters,
      importConfig: importConfig!,
      addKeywordJobId: this.addKeywordJobId,
    });
    const res = await importKeywords({
      variables: {
        input,
        forceAdd,
      },
    });
    const errors = res.data?.importKeydisKeywords?.errors;
    return { res, errors };
  };

  onSuccess = (keywords: AddKeywordFormProps['keywords']) => {
    appStorage.clearKeywordModalContent();
    invalidateCache(apolloClient.cache);
    this.props.hideModal();
    this.props.redirectOnSubmit &&
      this.props.history.push(linkToKeywordsDomain(this.props.domainId));
    this.props.tableStore?.getData(true);
    if (this.state.isKeyDis) {
      this.props.tableStore?.resetSelection();
    }
    trackEventMixpanel(EventName.AddKeywordsSuccess, '', {
      Count: keywords?.length,
      'Is Keydis': this.state.isKeyDis,
    });
  };

  handleSubmit = async (data: AddKeywordFormProps) => {
    const input = {
      domain: this.props.domainId,
      keywords: data.keywords,
      starred: data.keywordSettings.starred,
      ignoreInShareOfVoice: data.keywordSettings.ignoreInShareOfVoice,
      tags: data.tags,
      locales: data.engines?.map(({ searchEngines, settings, countrylocale, ...rest }) => ({
        ...rest,
        ...settings,
        countrylocale: parseInt(countrylocale),
        searchEngines: searchEngines
          .filter((item) => item.searchTypes.length)
          .map((searchEngine) => ({
            id: parseInt(SearchEngineTypes[searchEngine.name]),
            searchTypes: searchEngine.searchTypes,
          })),
      })),
      country: this.state.selectedCountry?.country,
      addKeywordJobId: this.addKeywordJobId,
    };

    try {
      let res;
      let errors;
      if (this.state.isKeyDis) {
        ({ res, errors } = await this.fetchKeydisKeywords(data, false));
      } else {
        res = await this.props.addKeywords({
          variables: {
            input,
            forceAdd: false,
          },
        });
        errors = res.data.addKeywordsNew.errors;
      }

      if (!isEmpty(errors)) {
        // Checks the error list for 'entering overuse'-error
        if (errors.find((x) => x.messages.indexOf('WARNING! Entering overuse') !== -1)) {
          errors = {};
          trackEventMixpanel(EventName.KeywordOveruseShowConfirmation, '');
          this.props.showModal({
            modalType: 'Confirmation',
            modalProps: {
              showExclamationInTitle: false,
              title: (
                <span>
                  <span>{t('Keyword usage')}</span>
                </span>
              ),
              modalFooterTextSlot: (
                <HelpGuideButton helpguideLink="https://www.accuranker.com/help/account/dynamic-keyword-usage" />
              ),
              description: tct(
                'By adding these keywords, your account will exceed its current keyword limit.[br]' +
                  'If you want to manage your keyword usage click [here:here].[br][br]',
                {
                  br: <br />,
                  here: <a href="/app/account/keyword-usage" target="_blank" rel="noreferrer" />,
                },
              ),
              confirmLabel: t('Confirm'),
              terms: (
                <AccText variant="label">
                  I allow the keyword limit to be exceeded and I agree to the
                </AccText>
              ), // "Terms and Conditions" link is added by modal footer
              lockDuration: 0,
              action: async () => {
                if (this.state.isKeyDis) {
                  ({ res, errors } = await this.fetchKeydisKeywords(data, true));
                } else {
                  res = await this.props.addKeywords({
                    variables: {
                      input,
                      forceAdd: true,
                    },
                  });
                  errors = res.data.addKeywordsNew.errors;
                }
                this.onSuccess(data.keywords);
              },
            },
            nested: true,
          });

          if (!isEmpty(errors)) {
            throwSubmitErrors(errors);
          }
        } else {
          throwSubmitErrors(errors);
        }
      } else {
        this.onSuccess(data.keywords);
      }
    } catch (error: unknown) {
      if (error instanceof SubmissionError) {
        throw error;
      }
      if (error) {
        throwSubmitErrors(error as ErrorsFromServer);
      }
    }

    if (this.props.gscKeywords) {
      const link = linkWithFilters({ to: '/keywords/list', filterSet: KEYWORDS_FILTER_SET });
      this.props.history.replace(link);
    }

    // Show status update for all but naver
    if (this.props.domainInfo?.domainType !== DomainTypeChoices.A_7) {
      toast.info(
        <div>
          {t('Keywords added to queue..')}
          <Progress.Root size="xl">
            <Progress.Section value={0}>
              <Progress.Label>0%</Progress.Label>
            </Progress.Section>
          </Progress.Root>
        </div>,
        {
          id: this.addKeywordJobId,
          autoClose: MAX_NOTIFICATION_TIMEOUT,
          loading: true,
          title: this.props.domainInfo
            ? tct('Adding keywords to [domainName]...', {
                domainName: this.props.domainInfo?.displayName || this.props.domainInfo?.domain,
              })
            : t('Adding keywords...'),
        },
      );
    }

    this.props.refresh && this.props.refresh();
  };
  getGscPredefinedKeywords = () => {
    // we get gcsKeywords from state if getKeywords provided
    return this.state.gscKeywords || this.props.gscKeywords;
  };

  render() {
    const { hasGSC, isAllSelected, keywordsData, maxKeywords, getKeywords, getKeydisKeywords } =
      this.props;
    const gscKeywords = this.getGscPredefinedKeywords();
    let content;

    if (
      underdash.graphqlLoading(this.props, ['tableStore']) ||
      (getKeywords && !gscKeywords) ||
      (getKeydisKeywords && !this.state.placeholderKeywords)
    ) {
      content = <AddKeywordsSkeleton />;
    } else {
      const locales = transformLocalesData(
        this.props.data.countrylocales,
        this.props.featureBaidu,
        this.props.featureYoutube,
        hasGSC?.domain?.domainType === 'A_3',
      );
      let keywords: string[] = gscKeywords ? gscKeywords.map(({ keyword }) => keyword) : [];

      if (isAllSelected) {
        keywords = keywordsData.keywords.keywords
          .map((item) => item.keyword)
          .filter((keyword) => !keywords.includes(keyword));
      }

      if (keywords.length === 0) {
        // read keywords from localStorage cache
        keywords = appStorage.getKeywordModalContent(this.props.domainId);
      }

      const engines = (hasGSC?.domain?.defaultSearchSettings || []).map((searchSetting) => ({
        countrylocale: searchSetting.countrylocale.id,
        locations: searchSetting.locations,
        searchEngines: searchSetting.searchEngines.map(({ searchEngine, searchTypes }) => ({
          id: parseInt(searchEngine.id),
          name: searchEngine.name.toLowerCase(),
          searchTypes,
        })),
        settings: {
          ignoreLocalResults: searchSetting.ignoreLocalResults || false,
          ignoreFeaturedSnippet: searchSetting.ignoreFeaturedSnippet || false,
          enableAutocorrect: searchSetting.enableAutocorrect || false,
        },
      }));
      const initialValues: AddKeywordFormType = {
        keywords: uniq(keywords),
        tags: [],
        engines,
        keywordSettings: {
          starred: false,
          ignoreInShareOfVoice: false,
        },
      };

      const isGSCEnabled = !!hasGSC?.domain?.googleOauthConnectionGsc;
      content = (
        <AddKeywordsForm
          locales={locales}
          initialValues={initialValues}
          onCancel={this.props.hideModal}
          onSubmit={this.handleSubmit}
          hasGSC={isGSCEnabled}
          country={
            engines.length
              ? hasGSC?.domain?.defaultSearchSettings[0].countrylocale.region
              : 'United States'
          }
          openHelperOverlay={this.openHelperOverlay}
          domain={this.props.domainId}
          youTubeDomain={hasGSC?.domain?.domainType === 'A_3'}
          refresh={this.props.refresh}
          maxKeywords={maxKeywords?.user?.organization?.activePlan?.actualMaxKeywords}
          mode={this.props.mode}
          keydisKeywordsCounter={this.props.keydisKeywordsCounter}
          selectedCountry={this.state.selectedCountry}
        />
      );
    }

    return (
      <ModalBorder className="add-keywords" title={t('Add Keywords')} onClose={this.onClose}>
        {content}
      </ModalBorder>
    );
  }
}

const keywordsQuery = gql`
  query addKeywords_keywords(
    $filters: [FilterInput]!
    $pagination: PaginationInput!
    $ordering: OrderingInput!
  ) {
    keywords(filters: $filters, pagination: $pagination, ordering: $ordering) {
      keywords {
        id
        keyword
      }
    }
  }
`;

export const ADD_KEYWORDS_COUNTRY_LOCALES_QUERY = gql`
  query addKeywords_countrylocales {
    countrylocales {
      id
      locale
      region
      countryCode
      localeShort
      googleSupport
      bingSupport
      baiduSupport
      youtubeSupport
      naverSupport
    }
  }
`;
const addKeywordsNew = gql`
  mutation addKeywordsNew($input: AddKeywordsInputNew!, $forceAdd: Boolean!) {
    addKeywordsNew(input: $input, forceAdd: $forceAdd) {
      errors {
        field
        messages
      }
    }
  }
`;

export const DOMAIN_HAS_GSC_QUERY = gql`
  query addKeywords_domain($domainId: ID!) {
    domain(id: $domainId) {
      id
      domainType
      defaultSearchSettings {
        id
        countrylocale {
          id
          region
        }
        locations
        searchEngines {
          id
          searchEngine {
            id
            name
          }
          searchTypes
        }
        ignoreLocalResults
        ignoreFeaturedSnippet
        enableAutocorrect
      }
      googleOauthConnectionGsc {
        id
      }
    }
  }
`;

export const MAX_KEYWORDS_QUERY = gql`
  query maxKeywords {
    user {
      id
      organization {
        id
        activePlan {
          id
          actualMaxKeywords
        }
      }
    }
  }
`;

const mapStateToProps = (state) => {
  return {
    state,
    featureYandex: state.user.organization.activePlan.featureYandex,
    featureBaidu: state.user.organization.activePlan.featureBaidu,
    featureYoutube: state.user.organization.activePlan.featureYoutube,
  };
};

export default compose(
  graphql(ADD_KEYWORDS_COUNTRY_LOCALES_QUERY),
  graphql(keywordsQuery, {
    name: 'keywordsData',
    options: (props: any) => {
      const { filters, numResults } = props;
      return {
        fetchPolicy: 'network-only',
        variables: {
          filters,
          pagination: {
            page: 1,
            startIndex: 0,
            stopIndex: numResults,
            results: 0,
          },
          ordering: {
            order: 'ASC',
            orderBy: 'keyword',
          },
        },
      };
    },
    skip: (props) => !props.isAllSelected,
  }),
  graphql(DOMAIN_HAS_GSC_QUERY, {
    name: 'hasGSC',
    options: () => ({
      fetchPolicy: 'network-only',
    }),
  }),
  graphql(addKeywordsNew, {
    name: 'addKeywords',
  }),
  graphql(KeywordsImportKeydisKeywordsDocument, {
    name: 'importKeywords',
  }),
  graphql(MAX_KEYWORDS_QUERY, {
    name: 'maxKeywords',
    options: () => ({
      fetchPolicy: 'network-only',
    }),
  }),
  connect(mapStateToProps, {
    hideModal,
    showModal,
  }),
  withRouter,
  withDomainInfo,
  withTableStore(TableIDs.KEYWORD_DISCOVERY),
)(AddKeywords);
export { stringToArray } from 'Components/Modal/Content/AddKeywords/support/helpers';
export { arrayToString } from 'Components/Modal/Content/AddKeywords/support/helpers';
