import { FilterAttribute } from 'Types/Filter';
import { t } from 'Utilities/i18n';
import AboveTheFoldData from './Editors/AboveTheFold/data';
import adCompetitionData from './Editors/AdCompetition/data';
import AiSearchVolumeData from './Editors/AiSearchVolume/data';
import aiTrafficValueData from './Editors/AiTrafficValue/data';
import aiTrafficValueChangeData from './Editors/AiTrafficValueChange/data';
import AlphabetData from './Editors/Alphabet/data';
import baseRankData from './Editors/BaseRank/data';
import baseRankChangeData from './Editors/BaseRankChange/data';
import cpcData from './Editors/CPC/data';
import Campaigns from './Editors/Campaigns/data';
import Clicks from './Editors/Clicks/data';
import Clients from './Editors/Clients/data';
import CompetitorDomain from './Editors/CompetitorDomain/data';
import CompetitorUrl from './Editors/CompetitorUrl/data';
import CountryLocale from './Editors/CountryLocale/data';
import CountryName from './Editors/CountryName/data';
import Date from './Editors/Date/dataFactory';
import DisplayNameData from './Editors/DisplayName/data';
import Domain from './Editors/Domain/data';
import Domains from './Editors/Domains/data';
import DynamicCtrData from './Editors/DynamicCtr/data';
import DynamicCtrChangeData from './Editors/DynamicCtrChange/data';
import DynamicCtrMaxData from './Editors/DynamicCtrMax/data';
import foldersData from './Editors/Folders/data';
import GscCtr from './Editors/GscCtr/data';
import GscPosition from './Editors/GscPosition/data';
import GoogleAnalyticsData from './Editors/HasGoogleAnalytics/data';
import GoogleSearchConsole from './Editors/HasGoogleSearchConsole/data';
import HighestRankingPageData from './Editors/HighestRankingPage/data';
import HighestRankingPageMatch from './Editors/HighestRankingPageMatch/data';
import ID from './Editors/ID/data';
import Impressions from './Editors/Impressions/data';
import KeywordOptionsData from './Editors/KeywordOptions/data';
import keywordsData from './Editors/Keywords/data';
import Keywords from './Editors/KeywordsList/data';
import KeywordsListSelectData from './Editors/KeywordsListSelect/data';
import LandingPages from './Editors/LandingPages/data';
import localRankData from './Editors/LocalRank/data';
import localRankChangeData from './Editors/LocalRankChange/data';
import locationData from './Editors/Location/data';
import maxAiSovData from './Editors/MaxAiSov/data';
import maxAiTrafficValueData from './Editors/MaxAiTrafficValue/data';
import maxRankData from './Editors/MaxRank/data';
import maxSovData from './Editors/MaxSov/data';
import maxTrafficValueData from './Editors/MaxTrafficValue/data';
import Name from './Editors/Name/data';
import Note from './Editors/Note/data';
import PageSerpFeaturesData from './Editors/PageSerpFeatures/data';
import PageSerpFeaturesOwnedData from './Editors/PageSerpFeaturesOwned/data';
import PageSerpFeaturesRankData from './Editors/PageSerpFeaturesRank/data';
import pixelsFromTopData from './Editors/PixelsFromTop/data';
import pixelsFromTopChangeData from './Editors/PixelsFromTopChange/data';
import Placements from './Editors/Placements/data';
import rankData from './Editors/Rank/data';
import RankChangeData from './Editors/RankChange/data';
import SalesManager from './Editors/SalesManager/data';
import searchEngineData from './Editors/SearchEngine/data';
import SearchIntentData from './Editors/SearchIntent/data';
import SearchTypeData from './Editors/SearchType/data';
import SearchVolumeData from './Editors/SearchVolume/data';
import ShareOfVoiceData from './Editors/ShareOfVoice/data';
import ShareOfVoiceChangeData from './Editors/ShareOfVoiceChange/data';
import SignedUp from './Editors/SignedUp/data';
import StarredData from './Editors/Starred/data';
import subdomainData from './Editors/Subdomain/data';
import tagsData from './Editors/Tags/data';
import TopCompetitorData from './Editors/TopCompetitor/data';
import TrafficData from './Editors/Traffic/data';
import TrafficChangeData from './Editors/TrafficChange/data';
import trafficValueData from './Editors/TrafficValue/data';
import trafficValueChangeData from './Editors/TrafficValueChange/data';
import UniqueIds from './Editors/UniqueIds/data';
import UserName from './Editors/UserName/data';

const filtersData = [
  pixelsFromTopData,
  pixelsFromTopChangeData,
  cpcData,
  adCompetitionData,
  DisplayNameData,
  GoogleAnalyticsData,
  GoogleSearchConsole,
  ShareOfVoiceData,
  keywordsData,
  locationData,
  rankData,
  baseRankData,
  baseRankChangeData,
  localRankData,
  localRankChangeData,
  maxAiSovData,
  maxSovData,
  maxRankData,
  aiTrafficValueData,
  aiTrafficValueChangeData,
  maxAiTrafficValueData,
  trafficValueData,
  trafficValueChangeData,
  maxTrafficValueData,
  searchEngineData,
  tagsData,
  foldersData,
  StarredData,
  SearchVolumeData,
  AiSearchVolumeData,
  ShareOfVoiceChangeData,
  SearchTypeData,
  SalesManager,
  RankChangeData,
  Domain,
  AboveTheFoldData,
  KeywordOptionsData,
  PageSerpFeaturesData,
  PageSerpFeaturesOwnedData,
  PageSerpFeaturesRankData,
  HighestRankingPageData,
  HighestRankingPageMatch,
  CountryLocale,
  LandingPages,
  CountryName,
  Impressions,
  Clicks,
  TopCompetitorData,
  UserName,
  Date(),
  Date({
    title: () => t('Date added'),
    attribute: FilterAttribute.DATE_ADDED,
    withCount: true,
  }),
  SignedUp,
  Note,
  Name,
  Keywords,
  Clients,
  Domains,
  UniqueIds,
  Placements,
  Campaigns,
  CompetitorUrl,
  CompetitorDomain,
  TrafficData,
  TrafficChangeData,
  DynamicCtrData,
  DynamicCtrChangeData,
  DynamicCtrMaxData,
  subdomainData,
  SearchIntentData,
  KeywordsListSelectData,
  ID,
  AlphabetData,
  GscCtr,
  GscPosition,
];

export type FilterData = ReturnType<typeof filtersData[number]>;
let filtersDataMap: Record<string, FilterData>;

const buildFilterDataMap = () =>
  filtersData.reduce((currentVal: Record<string, FilterData>, filterDataFunc) => {
    const data = filterDataFunc();
    currentVal[data.defaultValue.attribute] = data;
    return currentVal;
  }, {});

function getFilterData(attribute: string | undefined): FilterData | null {
  if (!attribute) return null;

  if (!filtersDataMap) {
    filtersDataMap = buildFilterDataMap();
  }

  return filtersDataMap[attribute];
}

export default getFilterData;
