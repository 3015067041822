import { Component } from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import { IconPencil, IconTrash } from '@tabler/icons-react';
import classnames from 'classnames';
import AccActionIcon from 'Components/AccActionIcon/AccActionIcon';
import { withRouter } from 'Utilities/Router';
import { t } from 'Utilities/i18n/index';

type Item = {
  disabled?: boolean;
  label?: any;
  link?: any;
  icon?: any;
  onSelect?: (...args: Array<any>) => any;
  header?: any;
  className?: string;
  isDemoClient?: boolean;
};
type DropdownItemProps = {
  ref?: (...args: Array<any>) => any;
  tag: any;
  to?: string;
  onClick: (...args: Array<any>) => any;
  onSelect?: (...args: Array<any>) => any;
  header?: any;
};
type Props = {
  items: Array<Item>;
  item?: any | null | undefined;
  valueFunc?: (...args: Array<any>) => any;
  labelFunc?: (...args: Array<any>) => any;
  iconFunc?: ((...args: Array<any>) => any) | null | undefined;
  onSelect?: (...args: Array<any>) => any;
  linkFunc?: ((...args: Array<any>) => any) | null | undefined;
  closeOnSelect?: boolean;
  onEdit?: (...args: Array<any>) => any;
  onDelete?: (...args: Array<any>) => any;
  selectedItem: number;
  onMouseOver: (...args: Array<any>) => any;
  onMouseOut?: (...args: Array<any>) => any;
  emptyText?: string;
  history: Record<string, any>;
  match: Record<string, any>;
};

class SimpleItemsList extends Component<Props> {
  static defaultProps = {
    labelFunc: (item: any) => item,
    iconFunc: null,
    item: null,
    linkFunc: null,
    closeOnSelect: true,
    onMouseOver: () => {},
    selectedItem: -1,
  };

  componentDidMount() {
    window.addEventListener('keydown', this.handleKeyDown as any);
  }

  componentDidUpdate() {
    if (this.selectedItemRef) {
      // Instead of using findDOMNode, you should just be able to use
      // the ref instead, but that doesn't seem to work
      // eslint-disable-next-line
      const container: any | null | undefined = ReactDOM.findDOMNode(this);
      // eslint-disable-next-line
      const elem: any | null | undefined = ReactDOM.findDOMNode(this.selectedItemRef);

      if (!container || !elem) return;
      // elem.scrollIntoView is not used as you cannot specify where to scroll to
      const elemTop = elem.offsetTop;
      const elemBottom = elemTop + elem.scrollHeight;
      const containerTop = container.scrollTop + container.offsetTop;
      const containerBottom = containerTop + container.clientHeight;
      const isVisible = elemTop > containerTop && elemBottom < containerBottom;
      const scrollToTop = elemTop < containerTop;

      if (isVisible) {
        return;
      }

      if (typeof container.scroll === 'function') {
        if (scrollToTop) {
          container.scroll(0, elem.offsetTop - container.offsetTop);
        } else {
          container.scroll(
            0,
            elem.offsetTop - container.offsetTop - container.clientHeight + elem.scrollHeight,
          );
        }
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown as any);
  }

  selectedItemRef = null;
  handleKeyDown = (event: React.SyntheticEvent<HTMLInputElement>) => {
    if (!this.selectedItemRef) {
      return;
    }

    const { history } = this.props;

    // Try to simulate link click behaviour without DOM access

    // TODO FixTSignore
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (event?.key === 'Enter') {
      // TODO FixTSignore
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (this.selectedItemRef?.props && this.selectedItemRef?.props.tag === Link) {
        // TODO FixTSignore
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const link = this.selectedItemRef?.props?.to;
        // TODO FixTSignore
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.selectedItemRef?.onClick?.();

        if (link !== history.location.pathname) {
          history.push(link);
        }
      } else {
        // TODO FixTSignore
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.selectedItemRef?.onClick?.();
      }
    }
  };

  renderEditButton(obj: Record<string, any>) {
    const { onEdit } = this.props;
    return (
      onEdit && (
        <AccActionIcon
          size={22}
          className="icn-button"
          onClick={(event: React.SyntheticEvent<any>) => {
            event.preventDefault();
            onEdit(event, obj);
          }}
        >
          <IconPencil style={{ height: '20px', width: '20px' }} />
        </AccActionIcon>
      )
    );
  }

  renderDeleteButton(obj: Record<string, any>) {
    const { onDelete } = this.props;
    return (
      onDelete && (
        <AccActionIcon
          size={22}
          className="icn-button"
          onClick={(event: React.SyntheticEvent<any>) => {
            event.preventDefault();
            onDelete(event, obj);
          }}
        >
          <IconTrash style={{ height: '20px', width: '20px' }} />
        </AccActionIcon>
      )
    );
  }

  renderItems() {
    const {
      items,
      labelFunc,
      linkFunc,
      valueFunc,
      iconFunc,
      onSelect,
      // closeOnSelect,
      selectedItem,
      onMouseOver,
      emptyText,
    } = this.props;

    if (!items.length) {
      return <span className="empty">{emptyText || t('No items to display')}</span>;
    }

    return items.map((item, index) => {
      const itemLabel = labelFunc ? labelFunc(item) : item.label;
      const link = linkFunc && linkFunc(item);
      const icon = iconFunc && iconFunc(item);
      const key = valueFunc ? valueFunc(item) : itemLabel;
      const disableActions = item?.isDemoClient || false;

      // Handle the case when item has it's own select handler
      // to avoid the situation when we need to identify
      // what item was passed to the common onSelect
      const handleItemSelect = () => {
        let result;

        if (item.onSelect) {
          result = item.onSelect();
        }

        if (result !== false) {
          onSelect && onSelect(item);
        }
      };

      let itemProps: DropdownItemProps;

      if (link) {
        itemProps = {
          tag: Link,
          to: link,
          onClick: handleItemSelect,
        };
      } else {
        itemProps = {
          tag: 'div',
          onClick: handleItemSelect,
        };
      }

      if (selectedItem === index) {
        itemProps.ref = (self) => (this.selectedItemRef = self);
      }
      const { tag: WrapperComponent = 'div', ...resultItemProps } = itemProps;

      // <div disabled="" tabIndex="-1" className="selected disabled dropdown-item">
      return (
        <WrapperComponent
          key={key}
          tabIndex={item.disabled ? -1 : 0}
          {...resultItemProps}
          className={classnames('dropdown-item', item.className, {
            selected: selectedItem === index,
            header: item.header,
            disabled: item.disabled,
          })}
          onMouseOver={() => onMouseOver(index)}
        >
          {icon && <div className="icon-container">{icon}</div>}
          {itemLabel}
          {!disableActions && (
            <span className="action-icon-wrapper">
              {this.renderEditButton(item)}
              {this.renderDeleteButton(item)}
            </span>
          )}
        </WrapperComponent>
      );
    });
  }

  render() {
    return <div className="items">{this.renderItems()}</div>;
  }
}

export default withRouter(SimpleItemsList as any) as any;
