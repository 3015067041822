import { ColumnSetting } from 'Components/DataTable';
import { keywordOrderConfig } from 'Pages/Keywords/Table/hooks/keyword/constants';
import { KeywordColumnID } from 'Pages/Keywords/Table/support/constants';
import { t } from 'Utilities/i18n';

export const keyDisTableConfig: ColumnSetting[] = [
  {
    id: KeywordColumnID.CHECKBOX,
    queryWithClauses: ['id'],
    tableSetting: {
      getLabel: () => t('Checkbox'),
      requiresAnalytics: false,
      requiresAdvancedMetrics: false,
      required: true,
      defaultHide: false,
    },
  },
  {
    id: KeywordColumnID.KEYWORD,
    queryWithClauses: [
      'id',
      'keyword',
      'tags',
      'rankId',
      'domainId',
      'ignoreInShareOfVoice',
      'ignoreLocalResults',
      'ignoreFeaturedSnippet',
      'enableAutocorrect',
    ],
    tableSetting: {
      getLabel: () => t('Keyword'),
      requiresAnalytics: false,
      requiresAdvancedMetrics: false,
      required: true,
      defaultHide: false,
      orderBy: keywordOrderConfig.keyword.orderBy,
    },
  },

  {
    id: KeywordColumnID.RANK,
    queryWithClauses: [
      'updatingKeyword',
      'rankValue',
      'aboveTheFold',
      'hasExtraRanks',
      'searchEngineId',
      'extraRanks',
    ],
    tableSetting: {
      getLabel: () => t('Rank'),
      requiresAnalytics: false,
      requiresAdvancedMetrics: false,
      required: false,
      defaultHide: false,
      orderBy: keywordOrderConfig.rank.orderBy,
    },
  },
  {
    id: KeywordColumnID.ABOVE_THE_FOLD,
    queryWithClauses: ['aboveTheFold'],
    tableSetting: {
      getLabel: () => t('Above the fold'),
      requiresAnalytics: false,
      requiresAdvancedMetrics: false,
      required: false,
      defaultHide: true,
      orderBy: keywordOrderConfig.aboveTheFold.orderBy,
    },
  },
  {
    id: KeywordColumnID.URL,
    queryWithClauses: [
      'id',
      'domainId',
      'title',
      'description',
      'highestRankingPage',
      'updatingKeyword',
      'preferredLandingPageId',
      'highestRankingPageStatus',
    ],
    tableSetting: {
      getLabel: () => t('URL'),
      requiresAnalytics: false,
      requiresAdvancedMetrics: false,
      required: false,
      defaultHide: false,
      orderBy: keywordOrderConfig.url.orderBy,
    },
  },
  {
    id: KeywordColumnID.SEARCH_VOLUME,
    queryWithClauses: ['id', 'searchVolumeValue', 'validForSearchVolume'],
    tableSetting: {
      getLabel: () => t('Searches'),
      requiresAnalytics: false,
      requiresAdvancedMetrics: false,
      required: false,
      defaultHide: false,
      orderBy: keywordOrderConfig.searchVolume.orderBy,
    },
  },
  {
    id: KeywordColumnID.CPC,
    queryWithClauses: ['avgCostPerClick', 'searchVolumeValue', 'validForSearchVolume'],
    tableSetting: {
      getLabel: () => t('CPC'),
      requiresAnalytics: false,
      requiresAdvancedMetrics: false,
      required: false,
      defaultHide: false,
      orderBy: keywordOrderConfig.cpc.orderBy,
    },
  },
  {
    id: KeywordColumnID.AD_COMPETITION,
    queryWithClauses: ['searchVolumeValue', 'competition', 'validForSearchVolume'],
    tableSetting: {
      getLabel: () => t('Ad Competition'),
      requiresAnalytics: false,
      requiresAdvancedMetrics: false,
      required: false,
      defaultHide: false,
      orderBy: keywordOrderConfig.competition.orderBy,
    },
  },
  {
    id: KeywordColumnID.DYNAMIC_CTR,
    queryWithClauses: ['id', 'dynamicCtrValue'],
    tableSetting: {
      getLabel: () => t('CTR'),
      requiresAnalytics: false,
      requiresAdvancedMetrics: false,
      required: false,
      defaultHide: false,
      orderBy: keywordOrderConfig.dynamicCtr.orderBy,
    },
  },
  {
    id: KeywordColumnID.DYNAMIC_CTR_MAX,
    queryWithClauses: ['id', 'dynamicCtrMax'],
    tableSetting: {
      getLabel: () => t('Max CTR'),
      requiresAnalytics: false,
      requiresAdvancedMetrics: false,
      required: false,
      defaultHide: false,
      orderBy: keywordOrderConfig.dynamicCtrMax.orderBy,
    },
  },
  {
    id: KeywordColumnID.ORGANIC_TRAFFIC,
    queryWithClauses: ['id', 'organicTraffic', 'validForSearchVolume'],
    tableSetting: {
      getLabel: () => t('AI SoV'),
      requiresAnalytics: false,
      requiresAdvancedMetrics: false,
      required: false,
      defaultHide: false,
      orderBy: keywordOrderConfig.organicTraffic.orderBy,
    },
  },
  {
    id: KeywordColumnID.SHARE_OF_VOICE,
    queryWithClauses: [
      'searchVolumeValue',
      'shareOfVoiceValue',
      'shareOfVoiceIsPercentage',
      'shareOfVoicePercentageValue',
      'updatingKeyword',
      'validForSearchVolume',
    ],
    tableSetting: {
      getLabel: () => t('SoV'),
      requiresAnalytics: false,
      requiresAdvancedMetrics: true,
      required: false,
      defaultHide: false,
      orderBy: keywordOrderConfig.sov.orderBy,
    },
  },
  {
    id: KeywordColumnID.SERP,
    queryWithClauses: ['id', 'domainId', 'rankId', 'updatingKeyword', 'serp'],
    tableSetting: {
      getLabel: () => t('SERP features'),
      requiresAnalytics: false,
      requiresAdvancedMetrics: false,
      required: false,
      defaultHide: false,
      orderBy: keywordOrderConfig.serp.orderBy,
    },
  },
];
