import isEmpty from 'lodash/isEmpty';
import { ImportKeydisKeywordsInput } from 'Ghql';
import { SearchEngineTypes } from '../../AddSearchEngine/types';
import { AddKeywordFormProps, KeydisImportConfig } from './types';

export const transformImportKeydisKeywordsInput = ({
  domainId,
  data,
  filters,
  selectedCountry,
  importConfig,
  addKeywordJobId,
}: {
  domainId: string;
  data: AddKeywordFormProps;
  importConfig: KeydisImportConfig;
  filters: any;
  selectedCountry: any;
  addKeywordJobId: string;
}): ImportKeydisKeywordsInput => {
  return {
    domain: domainId,
    ordering: importConfig.ordering,
    nKeywordsToAdd: importConfig.nKeywordsToAdd,
    excludedKeywords: importConfig.excludeKeywords,
    additionalKeywordsToAdd: importConfig.includeKeywords,
    starred: data.keywordSettings.starred,
    ignoreInShareOfVoice: data.keywordSettings.ignoreInShareOfVoice,
    tags: data.tags,
    locales: data.engines?.map(({ searchEngines, settings, countrylocale, ...rest }) => ({
      ...rest,
      ...settings,
      countrylocale: parseInt(countrylocale),
      searchEngines: searchEngines
        .filter((item) => item.searchTypes.length)
        .map((searchEngine) => ({
          id: parseInt(SearchEngineTypes[searchEngine.name]),
          searchTypes: searchEngine.searchTypes,
        })),
    })),
    country: selectedCountry?.country,
    filters,
    addKeywordJobId,
  };
};

/**
 * Converts from array of strings to newline-separated string.
 * I.e. from  the format that KeywordsField expects to what is stored in localStorage.
 */
export const arrayToString = (val?: string[]): string => val?.join?.('\n') ?? '';

/**
 * Converts from newline-separated string to array of strings.
 * I.e. from what is stored in localStorage to the format that KeywordsField expects.
 */
export const stringToArray = (val?: string): string[] =>
  val?.split('\n').filter((v) => !isEmpty(v)) ?? [];
