/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useEffect } from 'react';
import { useApolloClient } from '@apollo/client';
import { AccFastIcon } from 'Components/AccFastIcon';
import {
  DataTable,
  TableFetchDataCallBack,
  TableOrder,
  TableSize,
  useTableStore,
} from 'Components/DataTable';
import { ActionsCellItem } from 'Components/Table/TableRow/ActionsCell';
import { defaultRole } from 'Constants/workspaces';
import {
  UserFragment,
  UsersDocument,
  UsersQuery,
  UsersQueryVariables,
  useEditUserMutation,
  useGetWorkspacesQuery,
} from 'Ghql';
import { useModal } from 'Hooks/base/useModal';
import { useUser } from 'Hooks/data/user/useUser';
import toast from 'Hooks/useToast';
import useUserPermission from 'Hooks/useUserPermission';
import { TableIDs } from 'Types/Table';
import { invalidateCache } from 'Utilities/Graphql/invalidateCache';
import { t, tct } from 'Utilities/i18n';
import { notEmpty } from 'Utilities/underdash';
import { subscribeToUser } from 'Utilities/websocket';
import styleVariables from 'css/base/variables.module.scss';
import IconEdit from 'icons/IconEdit.svg';
import { IconTrash } from 'icons/tag-cloud';
import { getColumns } from './getColumns';
import styles from '../usersTable.module.scss';

const { snorlax4: iconColor, gray4: disabledIconColor } = styleVariables;

const DEFAULT_ORDERING = {
  orderBy: 'id',
  order: TableOrder.ASC,
};
const useFetchData = (): TableFetchDataCallBack => {
  const client = useApolloClient();

  return async () => {
    return client
      .query<UsersQuery, UsersQueryVariables>({
        query: UsersDocument,
        fetchPolicy: 'network-only',
      })
      .then((response) => {
        const users = response.data?.users?.filter(notEmpty) || [];
        return { data: users, length: users.length ?? 0 };
      });
  };
};

const UsersTable = ({
  dataKey,
  handleUpdateTable,
  refetchQty,
}: {
  dataKey: number;
  handleUpdateTable: () => void;
  refetchQty: () => void;
}) => {
  const tableStore = useTableStore(TableIDs.USERS);
  const client = useApolloClient();
  const user = useUser();
  const { isAdmin } = useUserPermission();
  const { loading: loadingRoles } = useGetWorkspacesQuery();

  const { showModal } = useModal();

  useEffect(() => {
    const subscription = subscribeToUser(handleUpdateTable);
    return () => {
      subscription.unsubscribe();
    };
  }, [handleUpdateTable]);

  const handleEdit = (record: UserFragment) => {
    showModal({
      modalType: 'EditUser',
      modalTheme: 'light',
      modalProps: {
        refresh: handleUpdateTable,
        id: record.id,
      },
    });
  };

  const [updateUser] = useEditUserMutation();

  const handleDelete = (record: UserFragment) => {
    showModal({
      modalType: 'Confirmation',
      modalProps: {
        cancelLabel: t('Cancel'),
        confirmLabel: t('Delete user'),
        primaryButtonLabel: t('Confirm'),
        lockDuration: 0,
        title: t('Delete User?', record.fullName),
        description: tct('Once deleted the user [user] can no longer log in.', {
          user: <b>{record.fullName}</b>,
        }),
        action: () => {
          const updateUserInput = {
            id: record.id ?? '',
            fullName: record.fullName ?? '',
            email: record.email ?? '',
            userType: record.userType ?? defaultRole,
            delete: true,
          };
          updateUser({
            variables: {
              updateUserInput,
            },
          })
            .then((response) => {
              const errors = response.data?.updateUser?.errors;
              if (errors && errors.length) {
                toast.error(t('Something went wrong. Please reload and try again.'));
              } else {
                tableStore?.deleteItem(record.id!.toString());
                toast.success(t('User deleted'));
                invalidateCache(client.cache, 'users');
                refetchQty();
              }
            })
            .catch(() => toast.error(t('Something went wrong. Please reload and try again.')));
        },
      },
    });
  };

  const getActions = (record: UserFragment): ActionsCellItem[] => {
    const isDeletable = isAdmin && user.id !== record.id;

    return [
      {
        onSelect: () => handleEdit(record),
        label: t('Edit'),
        fastIcon: <AccFastIcon src={IconEdit} size={20} color={iconColor} />,
      },
      {
        onSelect: () => handleDelete(record),
        label: t('Delete'),
        fastIcon: (
          <AccFastIcon
            src={IconTrash}
            size={20}
            color={isDeletable ? iconColor : disabledIconColor}
          />
        ),
        disabled: !isDeletable,
      },
    ];
  };

  const fetchData = useFetchData();

  const columns = getColumns(getActions);

  return (
    <DataTable
      className={styles.domainsTable}
      dataKey={`${dataKey.toString()}-table`}
      tableId={TableIDs.USERS}
      fetchData={fetchData}
      columns={columns}
      viewMode={TableSize.DEFAULT}
      defaultOrdering={DEFAULT_ORDERING}
      skipSaveOrdering={true}
      emptyOptions={{
        title: t('No Users'),
        subTitle: t('There are currently no users.'),
      }}
      offlineFilter={{
        tableName: TableIDs.USERS,
        skipAll: true,
      }}
      forceRenderSkeleton={loadingRoles}
    />
  );
};

export default UsersTable;
