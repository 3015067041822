import { useState } from 'react';
import { FormSpy, useField } from 'react-final-form';
import {
  PasswordInput as MantinePasswordInput,
  PasswordInputProps as MantinePasswordInputProps,
  Popover,
  PopoverProps,
  Progress,
} from '@mantine/core';
import cn from 'classnames';
import { FIELD_NAMES } from 'Pages/Register';
import { t } from 'Utilities/i18n';
import {
  COMPROMISED_ERROR,
  RE_HAS_ATLEAST_8_CHARACTERS,
  RE_HAS_LETTER_OR_SPECIAL,
  RE_HAS_NUMBER,
} from 'Utilities/validation';
import PasswordRequirement from './PasswordRequirement';
import styles from './password-input.module.scss';

const requirements = [
  { re: RE_HAS_NUMBER, label: 'Includes a number' },
  { re: RE_HAS_LETTER_OR_SPECIAL, label: 'Includes a letter or special symbol' },
  { re: RE_HAS_ATLEAST_8_CHARACTERS, label: 'Includes at least 8 characters' },
];

const getStrength = (password: string) => {
  let multiplier = 0;

  requirements.forEach((requirement) => {
    if (!requirement.re.test(password)) {
      multiplier += 1;
    }
  });

  return Math.max(100 - (100 / (requirements.length + 1)) * multiplier, 10);
};

export type PasswordProps = {
  popoverProps?: Omit<PopoverProps, 'children'>;
} & MantinePasswordInputProps;

const PasswordInput = (props: PasswordProps) => {
  const { name, popoverProps, ...rest } = props;

  const [compromisedPassword, setCompromisedPassword] = useState(false);

  const [popoverOpened, setPopoverOpened] = useState(false);

  const { input, meta } = useField(name || '');

  const strength = getStrength(input.value);
  const color = strength === 100 ? 'green' : strength > 50 ? 'yellow' : 'red';

  const checks = requirements.map((requirement, index) => (
    <PasswordRequirement
      key={index}
      label={requirement.label}
      meets={requirement.re.test(input.value)}
    />
  ));

  return (
    <Popover
      opened={popoverOpened}
      position="bottom"
      width="target"
      transitionProps={{ transition: 'pop' }}
      {...popoverProps}
    >
      <Popover.Target>
        <div
          onFocusCapture={() => setPopoverOpened(true)}
          onBlurCapture={() => setPopoverOpened(false)}
        >
          <MantinePasswordInput
            size="md"
            classNames={{
              innerInput: styles.passwordInnerInput,
              input: cn(styles.passwordInput, {
                [styles.hasError]: meta.error && meta.touched,
              }),
            }}
            {...rest}
            h={41}
            value={input.value}
            onChange={input.onChange}
          />
          <FormSpy
            subscription={{ errors: true, touched: true }} // Subscribe to validation errors
          >
            {({ errors }) => {
              setCompromisedPassword(errors?.[FIELD_NAMES.PASSWORD]?.includes(COMPROMISED_ERROR()));
              return null;
            }}
          </FormSpy>
        </div>
      </Popover.Target>
      <Popover.Dropdown
        className={cn({ [styles.popoverPositionTop]: popoverProps?.position === 'top' })}
      >
        {checks}
        {compromisedPassword && (
          <PasswordRequirement
            label={t(
              'This password has been found in data breaches and is not secure. Please choose another one.',
            )}
            meets={false}
            error={true}
          />
        )}
        {!compromisedPassword && <Progress mt="md" color={color} value={strength} size={8} />}
      </Popover.Dropdown>
    </Popover>
  );
};

export default PasswordInput;
