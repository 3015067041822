import { useNavigate } from 'react-router';
import cn from 'classnames';
import { linkToKeywordDiscoveryDomain } from 'Components/Filters/LinkToDomain';
import { Flag } from 'Components/Flag';
import FormatNumber from 'Components/FormatNumber';
import { DashboardNode } from 'Pages/Domains/DomainsTable/support/types';
import styles from '../domains-table.module.scss';

type Props = {
  domainNode: DashboardNode;
};

const DiscoveredKeywordsCell = (props: Props) => {
  const { untrackedKeywords, country, id } = props.domainNode || {};

  const navigate = useNavigate();

  return (
    <div className={cn(styles.domainsCellContainer, styles.numberCell)}>
      {/* Youtube domains will return untrackedKeywords as null */}
      {untrackedKeywords !== null && id ? (
        <a
          className={styles.left}
          onClick={() => navigate(linkToKeywordDiscoveryDomain(id.toString()))}
        >
          <FormatNumber>{untrackedKeywords}</FormatNumber>
        </a>
      ) : (
        <span className={styles.left}>N/A</span>
      )}

      {country && <Flag size="md" mx={0} country={country} fadedColors />}
    </div>
  );
};

export default DiscoveredKeywordsCell;
