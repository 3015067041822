import { Badge } from '@mantine/core';
import cn from 'classnames';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import AccText from 'Components/Text/AccText';
import { t } from 'Utilities/i18n';
import styles from './demo-content.module.scss';

type Props = {
  className?: string;
};

const DemoContentBadge = (props: Props) => {
  const { className } = props;

  return (
    <AccTooltip
      tooltip={t('This is a demo domain and cannot be edited.<br>The data is updated once a day.')}
    >
      <Badge
        miw="fit-content"
        color="blue"
        className={cn(styles.demoContentBadge, className)}
        variant="filled"
      >
        <AccText size="xs" fw={600} c="white">
          {t('Demo Domain')}
        </AccText>
      </Badge>
    </AccTooltip>
  );
};

export default DemoContentBadge;
