import { Flex } from '@mantine/core';
import { IconChevronRight } from '@tabler/icons-react';
import { HomeButton } from 'Components/Breadcrumbs/Breadcrumbs';
import GroupsAndDomainsDropdown, {
  GroupsDomainItem,
} from 'Components/Controls/Dropdowns/GroupsAndDomainsDropdown';
import PageHeaderContainer from 'Components/PageHeaderContainer';
import AccText from 'Components/Text/AccText';
import useUserPermission from 'Hooks/useUserPermission';
import { PeriodFilterContainer } from 'Pages/Layout/ActionsMenu/components/PeriodFilterContainer';
import { useStableNavigate } from 'Utilities/Router/components/LocationPathnameContext';
import { t } from 'Utilities/i18n';
import { notEmpty } from 'Utilities/underdash';
import { useActiveNavKey } from '../../SideNavbar/support/useActiveNavKey';
import { GroupsPlaceholder } from './components/GroupsPlaceholder';
import { getDomainLink } from './support/helpers';
import {
  useGroupsMenuData,
  useGroupsNavigationActions,
  useKeywordsPageHeader,
} from './support/hooks';

export const NextGroupsAndDomains = () => {
  const { groups, selectedGroupItem, selectedDomainItem, loading, domainIds, refetch } =
    useGroupsMenuData();
  const navigate = useStableNavigate();
  const actions = useGroupsNavigationActions(domainIds, refetch);
  const { userHasWriteAccess, isAdmin, isSuperuser } = useUserPermission();
  const pageHeader = useKeywordsPageHeader();

  const activeNavKey = useActiveNavKey();

  if (loading) {
    return <GroupsPlaceholder />;
  }

  return (
    <Flex justify="space-between">
      <PageHeaderContainer breadcrumbs>
        <HomeButton navigate={navigate} />
        <IconChevronRight />
        <GroupsAndDomainsDropdown
          item={selectedGroupItem as GroupsDomainItem}
          items={groups as GroupsDomainItem[]}
          dropdownAddButtonLabel={t('Add new group')}
          dropdownHeader={t('Groups')}
          dropdownSearchPlaceholder={t('Search groups')}
          targetPlaceholder={t('Groups')}
          showAddButton={isAdmin || isSuperuser}
          onSelect={actions.onGroupSelect}
          onAdd={actions.onAddGroup}
          onEdit={actions.onEditGroup}
          onDelete={actions.onDeleteGroup}
        />
        <IconChevronRight />
        {selectedGroupItem && (
          <>
            <GroupsAndDomainsDropdown
              item={selectedDomainItem as GroupsDomainItem}
              items={(selectedGroupItem?.domains?.filter(notEmpty) as GroupsDomainItem[]) ?? []}
              dropdownAddButtonLabel={t('Add new domain')}
              dropdownHeader={t('Domains')}
              dropdownSearchPlaceholder={t('Search for any domain')}
              targetPlaceholder={t('All domains')}
              showAddButton={userHasWriteAccess}
              onAdd={actions.onDomainsAdd(selectedGroupItem?.id)}
              onEdit={actions.onDomainsEdit}
              onDelete={actions.onDomainsDelete}
              onSelect={(domain) => {
                navigate(getDomainLink(domain, activeNavKey));
              }}
            />
            <IconChevronRight />
            <AccText noSelect size="sm" fw={600} c="gray.6" pl="sm">
              {pageHeader}
            </AccText>
          </>
        )}
      </PageHeaderContainer>

      <PeriodFilterContainer />
    </Flex>
  );
};
