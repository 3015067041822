import { nodeHasChildren } from 'Components/Table/TreeTable';
import {
  HIDE_CONNECTOR_PREFIX,
  LAST_CHILD_CLASS_NAME,
  NODE_EXPANDED_CLASS_NAME,
} from './constants';

/**
 * Enrich isLastChild, isFirstChild, hideConnectors, id - into nodes.
 * - hideConnectors - list of lines that should be not visible - if folder is last child.
 */
export function updateTreeInfo(
  tree,
  expandedNodeIds: string[] | undefined,
  expandDataKey: string,
  overwriteExpandedState?: boolean,
) {
  function updateNodeInfo(node, depth, parentIds, index, childLength) {
    const isLastChild = index === childLength - 1;
    const isFirstChild = index === 0;
    const hideConnectors = [
      ...(parentIds || []),
      ...(isLastChild ? [`${HIDE_CONNECTOR_PREFIX}${depth + 1}`] : []),
    ];

    const nodeId = node?.[expandDataKey];
    const updatedNode = {
      ...node,
      children: node.children ?? [],
      isLastChild,
      isFirstChild,
      hideConnectors: parentIds,
      state: expandedNodeIds?.includes(nodeId)
        ? { expanded: true }
        : overwriteExpandedState
        ? { expanded: false }
        : node.state,
      id: nodeId,
    };

    if (updatedNode.children?.length) {
      updatedNode.children = updatedNode.children.map((child, i) => {
        return updateNodeInfo(child, depth + 1, hideConnectors, i, updatedNode.children?.length);
      });
    }

    return updatedNode;
  }

  return tree?.map?.((node, index) => updateNodeInfo(node, 0, [], index, tree.length)) ?? [];
}

/**
 * Get classNames for node based on position in the list of siblings
 */
export const getIndentClassNames = (node: NodeLikeType, isExpanded?: boolean): string => {
  return [node?.isLastChild && LAST_CHILD_CLASS_NAME, isExpanded && NODE_EXPANDED_CLASS_NAME]
    .filter(Boolean)
    .join(' ');
};

const iterateTreeNodes = (tree, condition, getValue): string[] => {
  const items = [...tree];

  const result: string[] = [];
  let next = items.pop();

  while (next) {
    if (condition(next)) {
      result.push(getValue(next));
    }

    items.push(...next.children);

    next = items.pop();
  }
  return result;
};

/**
 * Extract node id of expanded nodes
 */
export const getExpandedNodeIds = <T extends { children: T[]; id: string }>(tree): string[] => {
  return iterateTreeNodes(
    tree,
    (node) => node.state?.expanded && node?.id,
    (node) => node?.id,
  );
};

export const getAllNodes = <T extends { children: T[]; id: string }>(tree): string[] => {
  return iterateTreeNodes(
    tree,
    (node) => !!node?.id,
    (node) => node?.id,
  );
};

const getIsFolder = (node: unknown) =>
  (node as NodeLikeType)?.isFolder || (node as NodeLikeType)?.is_folder;

/**
 * Extract node id of expanded nodes
 */
export const getAllFolderNodeIds = <T extends { children: T[]; id: string }>(tree): string[] => {
  return iterateTreeNodes(
    tree,
    (node) => getIsFolder(node) && node?.id,
    (node) => node?.id,
  );
};

type NodeLikeType<T = any> = {
  id: string | number;
  children?: NodeLikeType<T>[];
  [key: string]: unknown;
} & T;

const updateNode = (rootNodes: NodeLikeType[] | undefined, id: string, patch: object) => {
  const copiedRootNodes = [...(rootNodes || [])];

  const queue = [...copiedRootNodes];

  while (queue.length > 0) {
    const node = queue.shift();

    if (node?.id === id) {
      Object.assign(node, patch);
      break;
    }

    if (node?.children) {
      queue.push(...node.children);
    }
  }

  return copiedRootNodes;
};

const markAsCollapsed = (node: NodeLikeType) => {
  Object.assign(node, {
    state: { expanded: false },
  });
};
const updateTreeToCollapseAllChildren = (
  rootNodes: NodeLikeType<{ isFolder?: boolean }>[] | undefined,
  id: string,
) => {
  const copiedRootNodes = [...(rootNodes || [])];

  let queue = [...copiedRootNodes];

  let markAsClosed = false;
  while (queue.length > 0) {
    const node = queue.shift()!;

    if (markAsClosed) {
      markAsCollapsed(node);
    }

    if (node?.id === id) {
      markAsCollapsed(node);
      queue = [...(node.children || []).filter(nodeHasChildren)];
      markAsClosed = true;
    } else if (node?.children) {
      queue.push(...(node.children || []).filter(nodeHasChildren));
    }
  }

  return copiedRootNodes;
};

export const updateTreeToToggleExpanded = (
  nestedData: NodeLikeType[] | undefined,
  nodeId: string,
  nextExpanded?: boolean,
) => {
  if (nextExpanded) {
    return updateNode(nestedData, nodeId, {
      state: { expanded: true },
    });
  }

  return updateTreeToCollapseAllChildren(nestedData, nodeId);
};

const sortGroupChildren = (a, b) =>
  (getIsFolder(a) ? (getIsFolder(b) ? 0 : -1) : !getIsFolder(b) ? 0 : 1);

export const normalizeTree = (tree) => {
  const stack = [...tree];
  const result: typeof tree = [];
  let item = stack.shift();

  while (item) {
    result.push(item);
    if (item.children?.length) {
      stack.push(
        ...(item?.children?.map((e) => ({ ...e, parent: item?.id })).sort(sortGroupChildren) ?? []),
      );
    }
    item = stack.shift();
  }
  return result;
};
