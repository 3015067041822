import React from 'react';
import { ChartLoader } from 'Components/Chart/ChartLoader/ChartLoader';
import { ReactHighchartsChart } from 'Components/Chart/ReactHighcarts';
import { DEFAULT_CHART_CONFIG, TOOLTIP_CHART_FORMATTING } from 'Components/Chart/support/constants';
import { labelsFormatter } from 'Components/Chart/support/helpers';
import { useUnknownCompetitorRankDistributionQuery } from 'Ghql';
import { useFilters } from 'Hooks';
import { useQueryDomainInfo } from 'Hooks/data/domain/useQueryDomainInfo';
import colorScheme, { getCompetitorAndDomainColors } from 'Utilities/colors';
import { getFaviconByDomain } from 'Utilities/favicon';

function getRankDistributionYMax(
  mappedData: { data: (number | null | undefined)[]; name: string | null | undefined }[],
) {
  // yMax should be the rank which there are most values of.
  function addVector(a, b) {
    return a.map((e, i) => e + b[i]);
  }

  const sum = mappedData.reduce((a, b) => addVector(a, b.data), Array(10).fill(0));
  const yMax = Math.max(...sum);
  return yMax;
}

export const UnknownCompetitorRankDistributionChart = ({ competitors }) => {
  const filters = useFilters();
  const { domainInfo, colorMap, displayNameMap } = useQueryDomainInfo();
  const isYoutubeDomain = Boolean(domainInfo?.youtubeChannelName);

  const chartHeight = 380;

  const { data: rankDistributionData, loading: rankDistributionLoading } =
    useUnknownCompetitorRankDistributionQuery({
      skip: !competitors,
      variables: {
        filters,
        competitors,
        name: 'unknownCompetitorRankDistribution',
      },
    });

  const distributionChartData = rankDistributionData?.graphs?.unknownCompetitorRankDistribution;

  if (rankDistributionLoading) {
    return (
      <ChartLoader
        loaderType="column"
        loading={rankDistributionLoading}
        containerBoxProps={{
          h: chartHeight,
          mb: 10,
        }}
      />
    );
  }
  if (!competitors || !distributionChartData) {
    return null;
  }

  const dynamicCompetitors = distributionChartData.map((x) => x?.domain || '');
  const colors = getCompetitorAndDomainColors(colorMap, dynamicCompetitors);

  const getTooltipPosition = (
    labelWidth: number,
    labelHeight: number,
    point: Highcharts.TooltipPositionerPointObject,
    data: Highcharts.Tooltip,
  ) => {
    const result = data?.getPosition(labelWidth, labelHeight, point);
    if (point.plotX < 250) {
      result.x = point.plotX + 70;
      result.y = 0;
      return result;
    }
    result.x = point.plotX - 240;
    result.y = 0;
    return result;
  };

  const mappedData = distributionChartData.map((x, i) => ({
    name: (displayNameMap && x?.domain && displayNameMap[x?.domain]) || x?.domain,
    domain: x?.domain,
    isOwnDomain: x?.domain === domainInfo?.domain,
    color: colors[i],
    data: [
      x?.rank1,
      x?.rank2,
      x?.rank3,
      x?.rank4,
      x?.rank5,
      x?.rank6,
      x?.rank7,
      x?.rank8,
      x?.rank9,
      x?.rank10,
    ],
  }));
  const yMax = getRankDistributionYMax(mappedData);

  const series = [
    {
      name: '-',
      color: '#f3f4f7',
      animation: false,
      marker: {
        symbol: 'circle',
        radius: 3,
      },
      stack: 0,
      borderColor: 'transparent',
      type: 'column',
      data: Array(10).fill(yMax),
      showInLegend: false,
      enableMouseTracking: false, // Do not show tooltips for background bars,
      states: { inactive: { opacity: 1 } },
    },
    ...(mappedData as any),
  ];

  const distributionChartConfig = {
    chart: {
      type: 'column',
      height: chartHeight,
      marginTop: 5,
      marginBottom: 30,
      marginLeft: 50,
      colorCount: 6,
      zooming: { type: 'x' },
      animation: false,
    },
    plotOptions: {
      column: {
        stacking: 'normal',
        grouping: false,
        pointWidth: 11,
        borderRadius: 2,
        groupPadding: 0.1,
        states: { inactive: { opacity: 0.1 } },
      },
      series: { animation: false },
    },
    legend: {
      enabled: true,
      layout: 'vertical',
      y: -100,
      itemMarginBottom: 6,
      align: 'right',
      useHTML: true,
      padding: 0,
      labelFormatter() {
        const faviconSrc = getFaviconByDomain(
          isYoutubeDomain ? 'youtube.com' : (this as any)?.userOptions?.domain,
          16,
          true,
        );
        const fontColor = (this as any)?.userOptions?.isOwnDomain ? colorScheme.orange : '#337ab7';
        const idx = (this as any)?._i;
        return `<div style='${
          (this as any)?.visible ? '' : 'opacity: 0.5;'
        }; color: ${fontColor};  padding-bottom: 2px; padding-top: 0; margin-top:0'>
          <span style='width:20px; display: inline-block'>#${idx}</span>
<img width="16" height="16" src="${faviconSrc}" alt="" style="border-radius: 100%; margin-right: 4px; "/>${
          (this as any)?.userOptions?.displayName || (this as any)?.name.slice(0, 25)
        }</div>`;
      },
    },
    yAxis: {
      title: {
        text: null,
      },
      max: yMax,
      tickInterval: mappedData ? getRankDistributionYMax(mappedData) : undefined,
      top: '0%',
      labels: {
        style: {
          whiteSpace: 'nowrap',
          textOverflow: 'none',
        },
        formatter() {
          return labelsFormatter(this, true);
        },
      },
    },
    xAxis: {
      labels: {
        align: 'center',
        step: 1,
        y: 15,
      },
      categories: [
        'Rank 1',
        'Rank 2',
        'Rank 3',
        'Rank 4',
        'Rank 5',
        'Rank 6',
        'Rank 7',
        'Rank 8',
        'Rank 9',
        'Rank 10',
      ],
    },
    tooltip: {
      ...TOOLTIP_CHART_FORMATTING,
      shared: true,
      padding: 10,
      style: {
        lineHeight: '1.25',
      },
      positioner(labelWidth, labelHeight, point) {
        return getTooltipPosition(labelWidth, labelHeight, point, this as any);
      },
      headerFormat:
        '<div class="chart-tooltip-table"><div class="chart-tooltip-table-tr"><div class="chart-tooltip-table-th">Number of keywords at {point.key}</div></div>',

      pointFormatter() {
        return `<div class="chart-tooltip-table-tr">
                    <div class="chart-tooltip-table-td">
                        <span class="chart-tooltip-bullet" style="color: ${
                          (this as any).color
                        };">●</span> ${(this as any).series.name}
                    </div>
                    <div class="chart-tooltip-table-td chart-tooltip-table-right" style="text-align: right;">
                        ${(this as any).y}
                    </div>
                  </div>`;
      },
    },
    ...DEFAULT_CHART_CONFIG,

    series,
  };

  return <ReactHighchartsChart config={distributionChartConfig} />;
};
