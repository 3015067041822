import { Checkbox, Group } from '@mantine/core';
import { SelectItem } from 'Components/AccSelect';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import { Flag } from 'Components/Flag';
import RankOptions from 'Components/Table/TableRow/RankOptions';
import { KeywordNode } from 'Ghql';

type Props = SelectItem & Partial<KeywordNode> & { isSelected?: boolean };

const OptionComponent = (props: Props) => {
  const { value, countrylocale, location, isSelected } = props;

  return (
    <Group wrap="nowrap" gap={4}>
      {isSelected ? (
        <Checkbox mr={4} checked color={'snorlax.3'} />
      ) : (
        <Checkbox mr={4} checked={false} color={'snorlax.3'} />
      )}
      <span>
        <RankOptions keywordData={props} />
      </span>
      {countrylocale?.countryCode && (
        <AccTooltip tooltip={`${location?.concat(', ')}${countrylocale?.region}`}>
          <Flag size="sm" id={`country-${value}`} mr={2} country={countrylocale?.countryCode} />
        </AccTooltip>
      )}
      {props.label}
    </Group>
  );
};

export default OptionComponent;
