import { memo, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { selectors } from 'react-virtualized-tree';
import { ActionIcon, Checkbox, Flex } from '@mantine/core';
import { useHover } from '@mantine/hooks';
import { IconEdit, IconFolderPlus, IconFolderSymlink, IconTrash } from '@tabler/icons-react';
import cn from 'classnames';
import last from 'lodash/last';
import noop from 'lodash/noop';
import { observer } from 'mobx-react';
import AccActionIcon from 'Components/AccActionIcon/AccActionIcon';
import { RowIndentations } from 'Components/Table/TreeTable';
import AccText from 'Components/Text/AccText';
import AccTooltip from '../../../../../../Components/AccTooltip/AccTooltip';
import { getIndentClassNames } from '../../../../../../Utilities/Table/Tree/treeUtils';
import { t } from '../../../../../../Utilities/i18n';
import { useDragNode, useDropNode, useTreeContext } from '../../react-dnd-treeview/hooks';
import { PlaceholderContext } from '../../react-dnd-treeview/providers';
import { DropDirection } from '../../react-dnd-treeview/types';
import { ROOT_ID, UNCATEGORIZED_FOLDER_ID } from '../../support/constants';
import { useSelectedNodes } from '../../support/state';
import { DeleteFolderModal } from '../DeleteFolderModal';
import { EditNodeInput } from './EditNodeInput';
import { NodeIcon } from './NodeIcon';
import editGroupStyles from '../../editGroup.module.scss';
import styles from './node-item.module.scss';

type Props = {
  node: any;
  classNames?: string;
  isEdit: boolean | '' | 0;
  setIsEdit: (value: boolean) => void;
  onCancelEdit: () => void;
  onDelete: (nodeId: string) => void;
  onAdd: (folderName: string) => void;
  onUpdateNode: (nodeId: string, patch: any) => void;
  onUpdate: (nodeId: string, patch: any) => void;
  onSelect: (e: any, node: any) => void;
  onStartAdd: (e: any) => void;
  onDrop: (tree: any, dropTarget: any, placeholderContext: any) => void;

  onToggle: (node: any, config?: any) => void;
};

const NodeItem = observer((props: Props) => {
  const { node, isEdit, setIsEdit, onCancelEdit, onDelete, onAdd, onUpdate, onSelect, onDrop } =
    props;

  const { selectedNodes } = useSelectedNodes();
  const isSelected = !!selectedNodes?.some((e) => e.id === node.id);
  const isUncategorized = node.id === UNCATEGORIZED_FOLDER_ID;

  const { hovered, ref } = useHover();
  const placeholderContext = useContext(PlaceholderContext);
  const [deleteModalOpened, setDeleteModalOpened] = useState(false);
  const { hasChildren } = selectors.getNodeRenderOptions(node);
  const isExpanded = node.state?.expanded;
  const classNames = getIndentClassNames(node, isExpanded);

  const toggleExpand = (config?: any) => {
    if (node.isFolder) {
      props.onToggle?.(node, config);
    }
  };

  const containerRef = useRef<any>(null);
  const [isDraggingItem, drag, preview] = useDragNode(node, containerRef);
  const [, , drop] = useDropNode(node, containerRef);
  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, [preview]);

  const isDropTarget =
    placeholderContext.dropTargetId === node.id &&
    ![DropDirection.UP, DropDirection.DOWN].includes(placeholderContext.direction!);

  const parentHovered = node.parents?.includes(placeholderContext.dropTargetId);

  const treeContext = useTreeContext();

  const onMoveSelectedToFolder = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onDrop(treeContext.tree, { dropTargetId: node.id, index: 0 }, placeholderContext);
  };

  const onStartEdit = (e) => {
    e.stopPropagation();
    setIsEdit(true);
  };
  const handleDelete = (event) => {
    event.stopPropagation();
    if (!isUncategorized) {
      // Delete folder without modal if it's empty
      if (node.isFolder && !hasChildren) {
        onDelete(node.id);
        return;
      }
      setDeleteModalOpened(true);
    }
  };
  const handleCancelEdit = () => {
    if (isEdit) {
      setIsEdit(false);
    } else {
      onCancelEdit();
    }
  };
  const handleSave = (val) => {
    if (isEdit) {
      onUpdate(node.id, { name: val, text: val });
    } else {
      onAdd(val);
    }
  };
  const isDragging = isDraggingItem || isSelected;

  const countChildren = (treeNode): number => {
    if (!treeNode.children) {
      return 0;
    }

    let count = treeNode.children.length;

    treeNode.children.forEach((child) => {
      count += countChildren(child);
    });

    return count;
  };

  const childrenCount = useMemo(() => {
    return countChildren(node);
  }, [node]);

  const [animateAdd, setAnimateAdd] = useState(false);

  useEffect(() => {
    if (node.addTime) {
      const timeAdded = new Date().getTime() - node.addTime;

      if (timeAdded < 1000) {
        setAnimateAdd(true);
      }
    }
  }, [node.addTime]);

  return (
    <>
      <div
        ref={(containerElement) => {
          containerRef.current = containerElement;
          if (containerElement) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            ref.current = containerElement;
          }
          drag(drop(containerElement));
        }}
        data-drop-target-child={parentHovered}
        data-drop-parent={last(node.parents) ?? ROOT_ID}
        data-drop-folder={!!node?.isFolder}
        data-deepness={node?.deepness}
        data-drop-id={node.id}
        style={{ paddingLeft: '10px' }}
        className={cn(editGroupStyles.row, {
          [editGroupStyles.dropTarget]: isDropTarget,
          [editGroupStyles.dragging]: isDragging,
          [editGroupStyles.animateAdd]: animateAdd,
        })}
        role="listitem"
        onClick={(e) => {
          if (node.isFolder) {
            e.stopPropagation();
            toggleExpand();
          }
        }}
      >
        <div
          className={editGroupStyles.checkboxContainer}
          onClick={(e) => {
            if (!isUncategorized) {
              e.stopPropagation();
              onSelect({ ctrlKey: true }, node);
            }
          }}
          style={isUncategorized ? { opacity: 0.2 } : {}}
        >
          <Checkbox
            className={editGroupStyles.checkbox}
            checked={isSelected}
            onChange={noop}
            disabled={isUncategorized}
          />
        </div>
        <RowIndentations
          depth={node.deepness}
          expandIconWidth="28px"
          className={classNames}
          hideConnectors={node.hiddenConnectors}
        />
        <NodeIcon
          droppable={node?.isFolder}
          isOpen={isExpanded}
          isDynamic={node?.isDynamic}
          onClick={() => toggleExpand()}
          label={node?.name}
        />

        {node.isAdd || isEdit ? (
          <EditNodeInput
            onCancel={handleCancelEdit}
            onSave={handleSave}
            initValue={isEdit ? node.name : ''}
            isFolder={node.isFolder}
            node={node}
          />
        ) : (
          <AccText
            size="sm"
            fw={600}
            className={editGroupStyles.title}
            title={node?.name?.length > 50 ? node?.name : undefined}
          >
            {node?.isFolder ? node?.name : ''}{' '}
            {node?.isFolder ? (
              <AccText c="gray" inline fw={400}>
                ({childrenCount})
              </AccText>
            ) : null}
          </AccText>
        )}
        {node.isFolder && selectedNodes?.length ? (
          <AccTooltip tooltip={t('Move (%s) here', selectedNodes.length)}>
            <AccActionIcon ml={12} onClick={onMoveSelectedToFolder}>
              <IconFolderSymlink size={18} />
            </AccActionIcon>
          </AccTooltip>
        ) : null}
        {hovered && !isEdit && !node.isAdd && !!node.isFolder && (
          <Flex ml="md">
            <ActionIcon
              variant="transparent"
              color="gray"
              onClick={(event) => {
                event.stopPropagation();
                props.onStartAdd(node.id);
              }}
            >
              <IconFolderPlus size={18} />
            </ActionIcon>
            {!isUncategorized && (
              <ActionIcon variant="transparent" color="gray" onClick={onStartEdit}>
                <IconEdit size={18} />
              </ActionIcon>
            )}
            <AccTooltip
              tooltip={t('Uncategorized folder cannot be deleted')}
              disable={!isUncategorized}
            >
              <ActionIcon
                variant="transparent"
                color="gray"
                onClick={handleDelete}
                className={cn(styles.deleteIcon, { [styles.isUncategorized]: isUncategorized })}
                data-testid={`action-delete-${node.name}`}
              >
                <IconTrash size={18} />
              </ActionIcon>
            </AccTooltip>
          </Flex>
        )}
      </div>
      <DeleteFolderModal
        nodeId={node.id}
        nodeName={node.name}
        isFolder={node.isFolder}
        onDelete={onDelete}
        opened={deleteModalOpened}
        setOpened={setDeleteModalOpened}
      />
    </>
  );
});

export default memo(NodeItem);
