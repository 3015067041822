import * as React from 'react';
import CheckboxGroup, { ValueLabel } from 'Components/Controls/CheckboxGroup';
import toFormField from 'Components/Forms/toFormField';
import { t } from 'Utilities/i18n';
import type { KeywordSettings } from '../types';

type Props = {
  value: KeywordSettings;
  onChange: (...args: Array<any>) => any;
  showError?: boolean;
  disabled?: boolean;
};
const defaultSettings = {
  starred: false,
  ignoreInShareOfVoice: false,
};

class SettingsField extends React.Component<Props> {
  static defaultProps = {
    disabled: false,
  };
  handleChange = (properties: string[]) => {
    const newValue = properties.reduce(
      (accumulator, property) => {
        accumulator[property] = true;
        return accumulator;
      },
      { ...defaultSettings },
    );
    this.props.onChange(newValue);
  };

  items: ValueLabel[] = [
    {
      value: 'starred',
      label: t('Star Keywords'),
    },
    {
      value: 'ignoreInShareOfVoice',
      label: t('Ignore in Share of Voice'),
      hint: t('Ignore in share of voice, this is usually used for branded keywords.'),
    },
  ];

  render() {
    const { value } = this.props;
    const selectedProperties = Object.keys(value).filter((field) => value[field]);
    return (
      <CheckboxGroup
        disabled={this.props.disabled}
        className="add-keywords-settings"
        value={selectedProperties}
        onChange={this.handleChange}
        items={this.items}
      />
    );
  }
}

export default toFormField(SettingsField);
