import { memo } from 'react';
import { Box, useMantineTheme } from '@mantine/core';
import AccLazyPopover from 'Components/AccLazyPopover/AccLazyPopover';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import FormatNumber from 'Components/FormatNumber/new';
import Loader from 'Components/Loader';
import { useAvgTopTenCtrByPathLazyQuery } from 'Ghql';
import { useFilters } from 'Hooks';
import { FolderColumnID } from 'Pages/Keywords/Groupings/support/constants';
import { BarChart } from 'Pages/Keywords/Overview/components/BarChart/BarChart';
import { propsIsEqualComparison } from 'Utilities/compare';
import { t } from 'Utilities/i18n';

type Props = {
  maxCtr: number;
  path: string;
};
const FoldersMaxCtrCell = (props: Props) => {
  const { maxCtr, path } = props;

  const filters = useFilters();
  const [fetchData, { data, loading }] = useAvgTopTenCtrByPathLazyQuery();

  let items = [];

  const theme = useMantineTheme();

  if (data?.avgTopTenCtrByPath) {
    items = data.avgTopTenCtrByPath?.avgTopTenCtrByPath?.[path]?.map((item) => {
      return {
        value: item.avg_ctr,
        label: t('Rank ') + item.rank,
        id: item.rank,
        background: theme.colors.snorlax[theme.primaryShade as number],
      };
    });
  }

  return (
    <AccLazyPopover
      dropdownTitle={t('Average CTR by rank')}
      width={350}
      outerContainerProps={{ justify: 'end' }}
      target={
        <Box
          className={'table-link-like'}
          key={FolderColumnID.DYNAMIC_CTR_MAX}
          onClick={() => {
            fetchData({ variables: { filters } });
          }}
        >
          <AccTooltip tooltip={t('Show average CTR by rank')}>
            <span>
              <FormatNumber
                value={maxCtr}
                style="percent"
                maximumFractionDigits={1}
                minimumFractionDigits={1}
              />
            </span>
          </AccTooltip>
        </Box>
      }
    >
      {loading ? (
        <Loader
          style={{
            height: 350,
          }}
        />
      ) : (
        <BarChart normalNumberOfItems={10} items={items} formatNumberStyle={'percent'} />
      )}
    </AccLazyPopover>
  );
};

export default memo(FoldersMaxCtrCell, propsIsEqualComparison);
