import { AllSerpTypes } from 'Components/AccuTable/CellRenderer/helpers/serp-features';

export const useSerpFakeData = () => {
  const fakeData: [AllSerpTypes, number][] = [
    ['reviews', 1081150],
    ['related_searches_carousel', 956377],
    ['thumbnails', 776404],
    ['video', 704268],
    ['image_pack', 689802],
    ['faq', 668357],
    ['related_questions', 433265],
    ['shopping', 228446],
    ['maps_local_teaser', 196030],
    ['video_carousel_position', 176259],
    ['feature_snippet', 138790],
    ['ads_top', 112895],
    ['ads_in_between', 82648],
    ['ads_bottom', 33455],
    ['results_about', 67097],
    ['knowledge_panel', 56878],
    ['recipes_position', 44841],
    ['maps_local', 34352],
    ['shop_styles_owned', 25536],
    ['site_links', 12912],
    ['knowledge_cards', 7036],
    ['popular_stores_owned', 4133],
    ['carousel', 3853],
    ['rich_product_info_owned', 3740],
    ['podcasts', 3703],
    ['ai_overview', 332],
    ['feature_snippet', 2461],
    ['direct_answer', 1176],
    ['related_questions', 726],
    ['image_product_pack_position_owned', 557],
    ['site_links_searchbox', 553],
    ['job_posting', 455],
    ['discover_more_places', 323],
    ['knowledge_panel', 266],
    ['hotels_position', 149],
    ['news_position', 132],
    ['site_links', 122],
  ];

  return { fakeData };
};
