import { PropsWithChildren } from 'react';
import {
  IconAdjustmentsHorizontal,
  IconChartLine,
  IconChevronsUp,
  IconCirclePlus,
  IconClipboardCopy,
  IconFileDownload,
  IconPlugConnected,
  IconRefresh,
  IconSettings,
  IconTableOptions,
} from '@tabler/icons-react';
import cn from 'classnames';
import AccButton from 'Components/AccButton/AccButton';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import { TrackingKey } from 'Utilities/Analytics/mixpanel';
import { t } from 'Utilities/i18n';
import { getStickyTop } from 'Utilities/sticky';
import EditIcon from 'icons/edit.svg?inline';
import UpgradeAction from './UpgradeAction';

export interface ActionProps {
  title?: string;
  className?: string;
  label?: string;
  icon?: any;
  onClick?: (...args: Array<any>) => any;
  brand?: string;
  disabled?: boolean;
  newBadge?: boolean;
  showTooltip?: boolean;
  tooltip?: string;
  href?: string;
  link?: string;
  m?: string;
}
export const EditAction = (props: ActionProps) => (
  <AccButton
    {...props}
    variant="secondary"
    leftSection={props.icon || <EditIcon />}
    trackingKey={TrackingKey.EditAction}
  >
    {props.label || t('Edit')}
  </AccButton>
);
export const UpdateAction = (props: ActionProps) => (
  <AccButton
    {...props}
    variant="tertiary"
    leftSection={props.icon || <IconAdjustmentsHorizontal />}
  >
    {props.label || t('Update')}
  </AccButton>
);
export const RefreshAction = (props: ActionProps) => (
  <AccButton
    {...props}
    variant="primary"
    leftSection={props.icon || <IconRefresh size={18} />}
    trackingKey={TrackingKey.RefreshAction}
  >
    {props.label || t('Refresh')}
  </AccButton>
);
export const AddAction = (props: ActionProps) => (
  <AccButton
    {...props}
    variant="primary"
    leftSection={props.icon || <IconCirclePlus size={18} />}
    trackingKey={TrackingKey.AddAction}
  >
    {props.label || t('Add')}
  </AccButton>
);
export const ConnectToAnalyticsAction = (props: ActionProps) => (
  <AccButton
    {...props}
    variant="secondary"
    leftSection={props.icon || <IconPlugConnected size={18} />}
  >
    {props.label || t('Connect to Analytics')}
  </AccButton>
);
export const UpgadeUAToGA4AnalyticsAction = (props: ActionProps) => (
  <AccTooltip
    tooltip={t(
      'You currently have a UA (Universal Analytics) connection. We recommend upgrading to GA4 (Google Analytics 4).',
    )}
  >
    <AccButton variant="secondary" {...props} leftSection={<IconChevronsUp />}>
      {props.label || t('Upgrade to GA4')}
    </AccButton>
  </AccTooltip>
);
export const ConnectToGSCAction = (props: ActionProps) => (
  <AccButton
    variant={'secondary'}
    {...props}
    leftSection={props.icon || <IconPlugConnected size={18} />}
  >
    {props.label || t('Connect to Search Console')}{' '}
  </AccButton>
);
export const EditDomainAction = (props: ActionProps) => (
  <EditAction
    {...props}
    label={props.label || t('Edit domain')}
    icon={props.icon || <IconSettings size={18} />}
  />
);
export const ReportAction = (props: ActionProps) => {
  return (
    <div style={{ position: 'relative' }}>
      <AccButton
        variant="tertiary"
        {...props}
        leftSection={<IconFileDownload size={20} />}
        trackingKey={TrackingKey.DownloadReport}
      >
        {props.label || t('Download report')}
      </AccButton>
    </div>
  );
};
export const CopyAction = (props: ActionProps) => (
  <AccButton
    variant="tertiary"
    {...props}
    leftSection={props.icon || <IconClipboardCopy size={20} />}
    trackingKey={TrackingKey.CopyAction}
  >
    {props.label || t('Copy to clipboard')}
  </AccButton>
);
export const SettingsAction = (props: ActionProps) => (
  <AccButton
    variant="tertiary"
    onClick={props.onClick}
    {...props}
    leftSection={props.icon || <IconTableOptions size={20} />}
    trackingKey={TrackingKey.TableSettings}
  >
    {props.label || t('Configure columns')}
  </AccButton>
);
export const NormalAction = (props: ActionProps) => (
  <AccButton
    variant="secondary"
    leftSection={props.icon || <IconAdjustmentsHorizontal size={18} />}
    {...props}
  >
    {props.label}
  </AccButton>
);
export const CompareAction = (props: ActionProps) => {
  return (
    <AccButton variant="tertiary" leftSection={<IconChartLine />} {...props}>
      {props.label}
    </AccButton>
  );
};
export const ActionsContainer = (
  props: PropsWithChildren<{
    className?: string;
    containerClassName?: string;
    sticky?: boolean;
    hiddenFilterbar?: boolean;
  }>,
) => {
  const style = props.sticky
    ? { top: getStickyTop({ filtersOnly: true, hiddenFilterbar: props.hiddenFilterbar }) }
    : {};
  return (
    <div
      className={cn(
        'tabs-container',
        { 'tabs-container--sticky': props.sticky },
        props.containerClassName,
      )}
      id="actionsMenuContainer"
      data-sticky={props.sticky}
      style={style}
    >
      <div className={cn('actions', props.className)}>{props.children}</div>
    </div>
  );
};

export { UpgradeAction };
