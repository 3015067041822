import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { DEFAULT_PAGE_SIZE, TableLabels } from 'Components/DataTable';
import { useContextTableStore } from 'Components/DataTable/store/components/TableStoreContext';
import PaginationButton from './components/PaginationButton';
import { PageType } from './constants';
import { getPageNumbers, getPaginationDisplayLabel } from './helpers';
import './pagination.scss';

type PaginationProps = {
  onPagination: any;
  onPageSizeChange: any;
  paginationPageSize: number;
  totalDataLength: number;
  loading: boolean;
  loadingPaginationText?: string;
  currentPage: number;
  setCurrentPage: (a: number) => void;
  tableLabels: TableLabels;
};

const AccuPagination = ({
  onPagination,
  totalDataLength,
  loading,
  currentPage,
  setCurrentPage,
  tableLabels,
}: PaginationProps) => {
  const [pageNeighbours] = useState(1);
  const tableStore = useContextTableStore();
  const pageSize = tableStore?.paginationSize || DEFAULT_PAGE_SIZE;
  useEffect(() => {
    onPagination(currentPage);
  }, [currentPage]);

  const gotoPage = (page: number) => {
    !loading && setCurrentPage(page);
  };

  if (totalDataLength <= 0) return null;

  const pages = getPageNumbers({ totalDataLength, pageSize, pageNeighbours, currentPage });
  // pages.toString() used to generate unique key for pagination variant. It prevents spotted issue of having incorrect pagination on change. i.e:
  // 1 ... ... 2 3 4 5 ... NEXT
  return (
    <div className="accu-pagination" key={pages.toString()}>
      <span className="accu-pagination__row-count">
        {getPaginationDisplayLabel({
          totalDataLength,
          loading,
          pageSize,
          currentPage,
          tableLabels,
        })}
      </span>
      <ul className="accu-pagination__list pagination">
        {pages.length > 1 &&
          pages.map((page, idx) => {
            const key = page + idx;
            if (page === PageType.NEXT) {
              return (
                <PaginationButton
                  key={key}
                  type={PageType.NEXT}
                  onClick={() => gotoPage(currentPage + 1)}
                  loading={loading}
                />
              );
            }

            if (page === PageType.PREVIOUS) {
              return (
                <PaginationButton
                  key={key}
                  type={PageType.PREVIOUS}
                  onClick={() => gotoPage(currentPage - 1)}
                  loading={loading}
                />
              );
            }

            if (page === PageType.SPILL) {
              return <PaginationButton key={key} type={PageType.SPILL} loading={loading} />;
            }

            return (
              <PaginationButton
                key={key}
                type={PageType.RIGHT_PAGE}
                active={currentPage === page}
                onClick={() => gotoPage(page as number)}
                page={page as number}
                loading={loading}
              />
            );
          })}
      </ul>
    </div>
  );
};

export default observer(AccuPagination);
