import moment from 'moment';
import {
  CompareToFilter,
  FilterAttribute,
  FilterBase,
  FilterComparison,
  FilterValueType,
  PeriodFilter,
} from 'Types/Filter';

/**
 * Get filters from the URL and insert fallback period- and compareTO filters if they are missing
 */
export const getFiltersWithFallback = (filters: FilterBase[]): FilterBase[] => {
  const yesterday = moment(new Date()).subtract(1, 'days').format('YYYY-MM-DD');

  const periodFilter = filters.find((filter) => filter.attribute === FilterAttribute.PERIOD) as
    | PeriodFilter
    | undefined;

  const compareToFilter = filters.find(
    (filter) => filter.attribute === FilterAttribute.COMPARE_TO,
  ) as CompareToFilter | undefined;

  const restFilters = filters.filter(
    (filter) =>
      filter.attribute !== FilterAttribute.PERIOD &&
      filter.attribute !== FilterAttribute.COMPARE_TO,
  );

  const fallbackPeriodFilter: PeriodFilter = {
    attribute: FilterAttribute.PERIOD,
    comparison: FilterComparison.BETWEEN,
    type: FilterValueType.DATETIME,
    value: JSON.stringify([yesterday, 'latest']),
  };

  const fallbackCompareToFilter: CompareToFilter = {
    attribute: FilterAttribute.COMPARE_TO,
    comparison: FilterComparison.EQ,
    type: FilterValueType.DATETIME,
    value: yesterday,
  };

  return [
    ...restFilters,
    periodFilter || fallbackPeriodFilter,
    compareToFilter || fallbackCompareToFilter,
  ];
};
