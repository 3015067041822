import React from 'react';
import { Box, Flex, useMantineTheme } from '@mantine/core';
import { IconCaretDown, IconCaretDownFilled, IconCaretUp } from '@tabler/icons-react';
import cn from 'classnames';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import { TableOrder } from 'Components/DataTable';
import { TableScrollShadow } from 'Components/Table/VirtualizedTable';
import AccTitle from 'Components/Title/AccTitle';
import { useDisplayCurrency } from 'Hooks/displayCurrency';
import { getFavicon } from 'Pages/SiteMapping/components/CompetitorTable';
import { pathLink } from 'Pages/SiteMapping/support/helpers';
import { SitemapNode } from 'Pages/SiteMapping/support/types';
import { t } from 'Utilities/i18n';
import { TableSearchInput } from './components/TableSearchInput';
import styles from './tree-table.module.scss';

export const TreeTableHeaderColumnWithSearch = ({
  name,
  submitSearch,
  activeSearch,
  currentOrder,
  currentOrderBy,
  orderBy,
  setOrderBy,
  sticky,
}: {
  name: string;
  submitSearch: (value: string) => void;
  activeSearch: string;
  currentOrder?: TableOrder;
  currentOrderBy?: string;
  orderBy?: string;
  sticky?: boolean;
  setOrderBy?: (orderBy: string) => void;
}) => {
  const theme = useMantineTheme();
  return (
    <Flex
      className={cn(styles.headerSearchColumn, {
        [styles.sticky]: sticky,
        [styles.clickable]: orderBy,
      })}
      align="center"
      h="100%"
    >
      {orderBy && currentOrderBy === orderBy ? (
        currentOrder === TableOrder.DESC ? (
          <IconCaretDown size={18} color={theme.colors.gray[7]} />
        ) : (
          <IconCaretUp size={18} color={theme.colors.gray[7]} />
        )
      ) : null}
      <AccTitle type="h5" onClick={() => orderBy && setOrderBy && setOrderBy(orderBy)}>
        {name}
      </AccTitle>
      <TableSearchInput submitSearch={submitSearch} activeSearch={activeSearch} />
      <TableScrollShadow isHeader />
    </Flex>
  );
};

//Favicon URL           Icon Searches           Est.visits        Traf. val         CTR              T keyw             Disc
export const siteMapGridTemplateColumns =
  '36px minmax(250px, 5fr) 2px minmax(100px, 1fr) minmax(80px, 1fr) minmax(90px, 1fr) minmax(45px, 1fr) minmax(70px, 1fr) minmax(80px, 1fr)';

export const useSitemapHeader = (rootNode: SitemapNode, setOrderBy: (orderBy: string) => void) => {
  const { displayCurrency } = useDisplayCurrency(true);
  const domainUrl = pathLink(rootNode) ? new URL(rootNode.url) : undefined;

  const headerConfig = [
    {
      title: t('Searches'),
      tooltip: t('Search volume'),
      orderBy: 'search_volume',
    },
    {
      title: t('AI SoV'),
      tooltip: t('AI Share of Voice'),
      orderBy: 'traffic',
    },
    {
      title: t('AI Traf.\xa0val'), // \xa0 == nbsp
      tooltip: `${t('Estimated traffic value')} (${displayCurrency})`,
      orderBy: 'traffic_value',
    },
    {
      title: t('CTR'),
      tooltip: t('Click-through rate'),
      orderBy: 'ctr',
    },
    {
      title: t('Tracked'),
      tooltip: t('Number of tracked keywords'),
      orderBy: 'tracked_keywords',
    },
    {
      title: t('Disc.'),
      tooltip: t('Number of discovered keywords'),
      orderBy: 'untracked_keywords',
    },
  ];

  const HeaderRows = ({ submitSearch, className, activeSearch, orderBy }) => {
    return (
      <Box
        className={cn(styles.siteMapHeader, className)}
        style={{
          gridTemplateColumns: siteMapGridTemplateColumns,
        }}
      >
        <Flex className={styles.iconCell} align="center">
          {domainUrl && (
            <AccTooltip placement="left" tooltip={rootNode.name}>
              <Box
                component={'img'}
                className={styles.icon}
                src={getFavicon(domainUrl.host)}
                alt={`${rootNode.name} icon`}
              />
            </AccTooltip>
          )}
        </Flex>
        <TreeTableHeaderColumnWithSearch
          name={t('URL')}
          submitSearch={submitSearch}
          activeSearch={activeSearch}
        />
        {/* Empty column for icons */}
        <div />
        {headerConfig.map((column) => {
          return (
            <AccTooltip placement="left" tooltip={column.tooltip} key={column.title}>
              <Flex className={styles.cell} align="center" justify="end">
                <AccTitle type="h5" onClick={() => setOrderBy(column.orderBy)}>
                  {column.title}
                </AccTitle>
                {column.orderBy && (
                  <div
                    className={cn(styles.sortChevron, {
                      [styles.selected]: orderBy === column.orderBy,
                    })}
                  >
                    <IconCaretDownFilled size={12} />
                  </div>
                )}
              </Flex>
            </AccTooltip>
          );
        })}
      </Box>
    );
  };

  return HeaderRows;
};
