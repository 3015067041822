import React from 'react';
import isNil from 'lodash/isNil';
import AccActionIcon from 'Components/AccActionIcon/AccActionIcon';
import AccButton from 'Components/AccButton/AccButton';
import { AccFastIcon } from 'Components/AccFastIcon';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import { WithRowContext } from 'Components/DataTable';
import FormatNumber from 'Components/FormatNumber/new';
import { useUpdateAiSvMutation } from 'Ghql';
import { useHasEnabledNewFeatures } from 'Hooks/data/organization/useOrganizationInfo';
import SearchVolumeContent from 'Pages/Keywords/Table/Chart/SearchVolume';
import { t } from 'Utilities/i18n';
import ChevronDown from 'icons/chevronDown.svg';
import ChevronUp from 'icons/chevronUp.svg';
import Cell from './HelperComponents/AccuCell';
import NaCell from './helpers/NaCell';
import './styles.scss';

export type SearchVolumeCellProps = {
  value: {
    data: {
      id: number;
      searchVolumeValue: number;
      validForSearchVolume: boolean;
      noSearchVolumeForLocation: boolean;
      location: string;
      aiSearchVolume: number;
    };
    loadingData?: boolean;
  };
  /** Make it possible to expand the table row to show additional SearchVolume data. Defaults to true */
  expandable?: boolean;
  // auto from
  rowState: any;
  setRowState: (...args: Array<any>) => any | undefined;
  updatingKeyword?: any;
  useAi: boolean;
} & WithRowContext;

const SearchVolumeCell = (props: SearchVolumeCellProps): JSX.Element | null => {
  const {
    value: { data },
    rowState,
    useAi,
    setRowState,
    rowContext,
    expandable = true,
  } = props;
  const {
    searchVolumeValue: nonAiSearchVolume,
    validForSearchVolume,
    id,
    noSearchVolumeForLocation,
    location,
    aiSearchVolume,
  } = data;

  const searchVolumeValue = useAi ? aiSearchVolume : nonAiSearchVolume;
  const newFeaturesEnabled = useHasEnabledNewFeatures();
  const [updateAiSv] = useUpdateAiSvMutation();

  if (noSearchVolumeForLocation === true && location) {
    return (
      <NaCell
        tooltip={t('Local search volume is not available for this location.')}
        align="right"
      />
    );
  }

  if (validForSearchVolume === false) {
    return <NaCell tooltip={t('Search volume not available.')} align="right" />;
  }

  const openRow = (e: React.MouseEvent<HTMLButtonElement>) => {
    e?.preventDefault();

    if (rowContext?.toggleCustomExpand) {
      rowContext.toggleCustomExpand({ useAi });
    } else {
      setRowState((currentState) => {
        if (currentState.detailRenderer) {
          return { ...currentState, detailRenderer: null };
        }

        return {
          ...currentState,
          detailRenderer: (height: number) => (
            <SearchVolumeContent keywordId={id} height={height} useAi={useAi} />
          ),
        };
      });
    }
  };

  const isRelevantExpandedCell =
    'useAi' in rowContext.expandComponentProps && rowContext.expandComponentProps.useAi === useAi;

  const getIsActive = () =>
    (!!rowState?.detailRenderer || rowContext?.customExpand) && isRelevantExpandedCell;
  const active = getIsActive();
  const showCellData = !isNil(searchVolumeValue);

  if (useAi && newFeaturesEnabled && isNil(searchVolumeValue)) {
    return (
      <AccButton
        size={'sm'}
        ml={12}
        mr={12}
        onClick={() => {
          updateAiSv({ variables: { keywordId: id.toString() } });
        }}
      >
        Refresh
      </AccButton>
    );
  }

  return (
    <Cell rightAligned>
      {showCellData &&
        (searchVolumeValue && validForSearchVolume && expandable ? (
          <div className="search-volume-container">
            <AccTooltip
              tooltip={t('View historic search volume for this keyword')}
              disable={active}
            >
              <AccActionIcon
                size={'sm'}
                onClick={openRow}
                className="search-volume"
                data-active={active}
              >
                {active ? (
                  <AccFastIcon src={ChevronUp} color={'#444444'} />
                ) : (
                  <AccFastIcon src={ChevronDown} color={'#6b6c6c'} />
                )}
              </AccActionIcon>
            </AccTooltip>

            <div className="row-detail-btn">
              <FormatNumber value={searchVolumeValue} />
            </div>
          </div>
        ) : (
          <FormatNumber value={searchVolumeValue} />
        ))}
    </Cell>
  );
};

export default SearchVolumeCell;
