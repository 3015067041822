import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import { useHover } from '@mantine/hooks';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import {
  KEYWORD_DISCOVERY_PAGE,
  useFolderLink,
} from 'Components/Filters/LinkWithFolders/linkWithFolders';
import FormatNumber from 'Components/FormatNumber/new';
import Icon from 'Components/Icon/new';
import { FolderCellProps } from 'Pages/Keywords/Groupings/ViewMode/utils/useFoldersTableConfig';
import { FolderColumnID } from 'Pages/Keywords/Groupings/support/constants';
import { propsIsEqualComparison } from 'Utilities/compare';
import { t } from 'Utilities/i18n';
import KeyDisIcon from 'icons/menu/compass-keydis.svg?inline';

export const DiscoveredKeywordsCell = memo(({ props }: { props: FolderCellProps }) => {
  const link = useFolderLink({
    name: props.rootNode.name,
    isFolder: props.rootNode.is_folder,
    path: props.rootNode.path,
    pageToGoTo: KEYWORD_DISCOVERY_PAGE,
  });

  const { hovered, ref } = useHover();

  if (!props.rootNode.is_dynamic && !props.rootNode.is_folder) {
    return (
      <AccTooltip tooltip={t('Only available for dynamic tags')}>
        <div className={props.classes.rightTextAlign} key={FolderColumnID.DISCOVERED_KEYWORDS}>
          {t('N/A')}
        </div>
      </AccTooltip>
    );
  }

  return (
    <div
      className={props.classes.rightTextAlign}
      key={FolderColumnID.DISCOVERED_KEYWORDS}
      ref={ref}
    >
      <AccTooltip tooltip={t('Go to discovery')}>
        <Link to={link} className="table-link">
          {hovered && (
            <Icon width={22} height={14}>
              <KeyDisIcon />
            </Icon>
          )}
          <FormatNumber value={props.rootNode.keydis_count} />
        </Link>
      </AccTooltip>
    </div>
  );
}, propsIsEqualComparison);
DiscoveredKeywordsCell.displayName = 'DiscoveredKeywordsCell';
