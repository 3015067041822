import { IconDeviceDesktopAnalytics, IconListSearch } from '@tabler/icons-react';
import cn from 'classnames';
import AccActionIcon from 'Components/AccActionIcon/AccActionIcon';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import LinkToDomain, { PageIdToLink } from 'Components/Filters/LinkToDomain';
import { t } from 'Utilities/i18n';
import DemoContentBadge from '../DemoContent/DemoContentBadge';
import domainCellStyles from '../../Pages/Domains/DomainsTable/domains-table.module.scss';
import './domain-details.scss';

type Props = {
  domainId: string;
  title: string;
  domain: string;
  logo: string;
  isDemoDomain: boolean;
  shouldLink?: boolean;
  reset: boolean;
  small: boolean;
  showNavigateIcons?: boolean;
};

const Domain = ({
  title,
  domain,
  logo,
  domainId,
  isDemoDomain,
  reset = false,
  small,
  shouldLink = true,
  showNavigateIcons = true,
}: Props) => (
  <div
    className={cn('domain-details', {
      'domain-details--small': small,
    })}
  >
    <img className="logo" src={logo} />
    <div className={cn('text-container', domainCellStyles.domainCellText)} title={domain}>
      {shouldLink ? (
        <LinkToDomain domainId={domainId} reset={reset} className="title">
          {title}
        </LinkToDomain>
      ) : (
        <span className="title">{title}</span>
      )}
      {!title ? (
        <LinkToDomain domainId={domainId} reset={reset} className="title">
          {domain}
        </LinkToDomain>
      ) : (
        <div className="domain">{domain}</div>
      )}
    </div>
    {showNavigateIcons &&
      (isDemoDomain ? (
        <DemoContentBadge className="demo-badge" />
      ) : (
        <div className="domain-details__icon-container">
          <LinkToDomain
            domainId={domainId}
            reset={false}
            navigateTo={PageIdToLink.overview}
            className="domain-details__navigation-icon"
          >
            <AccTooltip tooltip={t('Go to dashboard')}>
              <AccActionIcon variant="navigation" tabIndex={-1}>
                <IconDeviceDesktopAnalytics />
              </AccActionIcon>
            </AccTooltip>
          </LinkToDomain>
          <LinkToDomain
            domainId={domainId}
            reset={false}
            navigateTo={PageIdToLink.keywords}
            className="domain-details__navigation-icon"
          >
            <AccTooltip tooltip={t('Go to keywords list')}>
              <AccActionIcon variant="navigation" tabIndex={-1}>
                <IconListSearch />
              </AccActionIcon>
            </AccTooltip>
          </LinkToDomain>
        </div>
      ))}
  </div>
);

export default Domain;
