import { useEffect, useMemo, useState } from 'react';
import { AccFastIcon } from 'Components/AccFastIcon';
import { ColumnType, TableLabels, TableOrder } from 'Components/DataTable';
import ActionsCell from 'Components/Table/TableRow/ActionsCell';
import DomainDetailsCell from 'Components/Table/TableRow/DomainDetailsCell';
import RankingDistributionCell from 'Components/Table/TableRow/RankingDistributionCell';
import { useDeleteDomainsMutation } from 'Ghql';
import { useModal } from 'Hooks/base/useModal';
import { useLanguage } from 'Hooks/data/domain/useQueryDomainInfo';
import toast from 'Hooks/useToast';
import AiShareOfVoiceCell from 'Pages/Domains/DomainsTable/cells/AIShareOfVoiceCell';
import { SORTING_TYPE } from 'Pages/Keywords/Table/hooks/keyword/constants';
import { FilterAttribute } from 'Types/Filter';
import { EventName, useMixpanel } from 'Utilities/Analytics/mixpanel';
import { t } from 'Utilities/i18n';
import { devError } from 'Utilities/log';
import { subscribeToDomain } from 'Utilities/websocket';
import styleVariables from 'css/base/variables.module.scss';
import IconEdit from 'icons/IconEdit.svg';
import { IconTrash } from 'icons/tag-cloud';
import AiShareOfVoiceChangeCell from '../cells/AIShareOfVoiceChangeCell';
import AverageRankCell from '../cells/AverageRankCell';
import AverageRankChangeCell from '../cells/AverageRankChangeCell';
import CreatedOnCell from '../cells/CreatedOnCell';
import DiscoveredKeywordsCell from '../cells/DiscoveredKeywordsCell';
import GroupCell from '../cells/GroupCell';
import KeywordsCell from '../cells/KeywordsCell';
import ShareOfVoiceCell from '../cells/ShareOfVoiceCell';
import ShareOfVoiceChangeCell from '../cells/ShareOfVoiceChangeCell';
import { DashboardNode, DomainColumnIDs } from './types';

interface ReturnType {
  columns: ColumnType<DashboardNode>[];
  tableLabels: TableLabels;
  dataKey: number;
}

export const useDomainsTableInfo = (): ReturnType => {
  const { showModal } = useModal();
  const trackEvent = useMixpanel();
  const language = useLanguage();

  //used to force refetch of tabledata
  const [dataKey, setDataKey] = useState(1);
  const updateTable = () => setDataKey((key) => key + 1);

  //Update table when domain is added/paused, etc.
  useEffect(() => {
    const subscription = subscribeToDomain(() => {
      //The backend is really slow to respond when mutating the DomainNode. We have to wait for this to complete
      //before we can refetch the TableDomainNodes by refreshing the tablestore.
      //https://accuranker.myjetbrains.com/youtrack/issue/ARR-2550
      setTimeout(() => {
        updateTable();
      }, 3000);
    });
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const handleEditDomain = (domain: DashboardNode) => {
    showModal({
      modalType: 'BuildDomain',
      modalTheme: 'light',
      modalProps: {
        domainId: domain.id,
      },
    });
  };

  const [deleteDomain] = useDeleteDomainsMutation({
    onCompleted(data, clientOptions) {
      toast.success(t('Domain is being deleted. This might take a few seconds.'));
      trackEvent(EventName.DeleteDomainSuccess, {
        'Deleted Domain ID': clientOptions?.variables?.input?.id ?? '',
        Source: 'Domains table',
      });
      setTimeout(() => {
        updateTable();
      }, 3000);
    },
    onError(error, clientOptions) {
      toast.error(t('Could not delete domain'));
      trackEvent(EventName.DeleteDomainFailed, {
        'Deleted Domain ID': clientOptions?.variables?.input?.id ?? '',
        Source: 'Domains table',
        Error: `${error.name}: ${error.message}`,
      });
    },
  });

  const handleDeleteDomain = (domain: DashboardNode) => {
    const { id } = domain;

    if (!id) {
      devError('Failed to delete');
    }

    deleteDomain({
      variables: {
        input: {
          id: `${id}`,
        },
      },
    });
  };

  const showDeleteConfirmation = (domain: DashboardNode) => {
    showModal({
      modalType: 'Confirmation',
      modalProps: {
        title: t('Delete Domain?'),
        description: t('The domain %s will be permanently deleted.', domain.domain),
        confirmLabel: t('Delete domain'),
        action: () => handleDeleteDomain(domain),
      },
    });
  };

  const columns: ColumnType<DashboardNode, {}>[] = useMemo(
    () => [
      {
        id: DomainColumnIDs.DOMAIN_DISPLAY_NAME,
        title: t('Domain Name'),
        width: 325,
        fixed: 'left',
        flex: true,
        cellRenderer: ({ record }) => {
          return <DomainDetailsCell domainData={record} reset />;
        },
        onHeaderCell: () => ({
          ordering: {
            defaultOrder: TableOrder.ASC,
            orderBy: DomainColumnIDs.DOMAIN_DISPLAY_NAME,
            sortingKey: SORTING_TYPE.alphabetical,
          },
          filter: {
            filterAttributes: [FilterAttribute.DOMAINS],
            filterTooltip: t('Add filter for Domain'),
            filterId: 'domain-filter',
          },
        }),
      },
      {
        id: DomainColumnIDs.CLIENT_NAME,
        title: t('Group'),
        width: 210,
        cellRenderer: ({ record }) => <GroupCell domainNode={record} />,
        onHeaderCell: () => ({
          ordering: {
            defaultOrder: TableOrder.ASC,
            orderBy: DomainColumnIDs.CLIENT_NAME,
            sortingKey: SORTING_TYPE.alphabetical,
          },
          filter: {
            filterAttributes: [FilterAttribute.CLIENTS],
            filterTooltip: t('Add filter for Group'),
            filterId: 'domain-filter',
          },
        }),
      },
      {
        id: DomainColumnIDs.KEYWORDS,
        title: t('Keywords'),
        width: 140,
        cellRenderer: ({ record }) => <KeywordsCell domainNode={record} />,
        onHeaderCell: () => ({
          reverseDirection: true,
          ordering: {
            defaultOrder: TableOrder.ASC,
            orderBy: DomainColumnIDs.KEYWORDS,
            sortingKey: SORTING_TYPE.numerical,
          },
        }),
      },
      {
        id: DomainColumnIDs.DISCOVERED_KEYWORDS,
        title: t('Discovered Keywords'),
        width: 165,
        cellRenderer: ({ record }) => <DiscoveredKeywordsCell domainNode={record} />,
        onHeaderCell: () => ({
          reverseDirection: true,
          ordering: {
            defaultOrder: TableOrder.ASC,
            orderBy: DomainColumnIDs.DISCOVERED_KEYWORDS,
            sortingKey: SORTING_TYPE.numerical,
          },
        }),
      },
      {
        id: DomainColumnIDs.AVERAGE_RANK,
        combineRow: DomainColumnIDs.AVERAGE_RANK_CHANGE,
        title: t('Average Rank'),
        width: 110,
        cellRenderer: ({ record }) => <AverageRankCell domainNode={record} />,
        onHeaderCell: () => ({
          reverseDirection: true,
          ordering: {
            defaultOrder: TableOrder.ASC,
            orderBy: DomainColumnIDs.AVERAGE_RANK,
            sortingKey: SORTING_TYPE.rank,
          },
        }),
      },
      {
        id: DomainColumnIDs.AVERAGE_RANK_CHANGE,
        title: t('+/-'),
        width: 145,
        onHeaderCell: () => ({
          reverseDirection: true,
          tooltip: t('Change in rank between the two compared dates'),
          ordering: {
            defaultOrder: TableOrder.DESC,
            orderBy: DomainColumnIDs.AVERAGE_RANK_CHANGE,
            sortingKey: SORTING_TYPE.numerical,
          },
          menuTitle: t('Change in Rank'),
        }),
        cellRenderer: ({ record }) => <AverageRankChangeCell domainNode={record} />,
        cellRendererParams: {
          emptyLoader: true,
        },
      },
      {
        id: DomainColumnIDs.SHARE_OF_VOICE,
        combineRow: DomainColumnIDs.SHARE_OF_VOICE_CHANGE,
        title: t('Share of Voice'),
        width: 120,
        cellRenderer: ({ record }) => <ShareOfVoiceCell domainNode={record} />,
        onHeaderCell: () => ({
          reverseDirection: true,
          ordering: {
            defaultOrder: TableOrder.DESC,
            orderBy: DomainColumnIDs.SHARE_OF_VOICE,
            sortingKey: SORTING_TYPE.numerical,
          },
        }),
      },
      {
        id: DomainColumnIDs.SHARE_OF_VOICE_CHANGE,
        title: t('+/-'),
        width: 165,
        onHeaderCell: () => ({
          reverseDirection: true,
          tooltip: t('Change in SoV between the two compared dates'),
          ordering: {
            defaultOrder: TableOrder.DESC,
            orderBy: DomainColumnIDs.SHARE_OF_VOICE_CHANGE,
            sortingKey: SORTING_TYPE.numerical,
          },
          menuTitle: t('Change in SoV'),
        }),
        cellRenderer: ({ record }) => <ShareOfVoiceChangeCell domainNode={record} />,
        cellRendererParams: {
          emptyLoader: true,
        },
      },
      {
        id: DomainColumnIDs.AI_SHARE_OF_VOICE,
        title: t('AI Share of Voice'),
        combineRow: DomainColumnIDs.AI_SHARE_OF_VOICE_CHANGE,
        width: 130,
        cellRenderer: ({ record }) => <AiShareOfVoiceCell domainNode={record} />,
        onHeaderCell: () => ({
          reverseDirection: true,
          ordering: {
            defaultOrder: TableOrder.DESC,
            orderBy: DomainColumnIDs.AI_SHARE_OF_VOICE,
            sortingKey: SORTING_TYPE.numerical,
          },
        }),
      },
      {
        id: DomainColumnIDs.AI_SHARE_OF_VOICE_CHANGE,
        title: t('+/-'),
        width: 165,
        onHeaderCell: () => ({
          reverseDirection: true,
          tooltip: t('Change in AI SoV between the two compared dates'),
          ordering: {
            defaultOrder: TableOrder.DESC,
            orderBy: DomainColumnIDs.AI_SHARE_OF_VOICE_CHANGE,
            sortingKey: SORTING_TYPE.numerical,
          },
          menuTitle: t('Change in AI SoV'),
        }),
        cellRenderer: ({ record }) => <AiShareOfVoiceChangeCell domainNode={record} />,
        cellRendererParams: {
          emptyLoader: true,
        },
      },
      {
        id: DomainColumnIDs.RANKING_DISTRIBUTION,
        title: t('Ranking Distribution'),
        width: 160,
        cellRenderer: ({ record }) => (
          <RankingDistributionCell
            width={140}
            rankingDistributionData={{
              keywords0To3: record.count1To3,
              keywords4To10: record.count4To10,
              keywords11To20: record.count11To20,
              keywords21To50: record.count21To50,
              keywordsAbove50: record.count51To100,
              keywordsUnranked: record.countUnranked,
            }}
            domainId={record.id?.toString() || ''}
          />
        ),
      },
      {
        id: DomainColumnIDs.DATE_ADDED,
        title: t('Created On'),
        width: language === 'da' ? 125 : 100,
        cellRenderer: ({ record }) => <CreatedOnCell domainNode={record} />,
        onHeaderCell: () => ({
          ordering: {
            defaultOrder: TableOrder.ASC,
            orderBy: DomainColumnIDs.DATE_ADDED,
            sortingKey: SORTING_TYPE.recurency,
          },
        }),
      },
      {
        id: DomainColumnIDs.ACTIONS,
        title: language === 'da' ? '' : 'Actions',
        width: 76,
        fixed: 'right',
        cellRenderer: ({ record }) => {
          const { snorlax4: iconColor, gray4: disabledIconColor } = styleVariables;

          return (
            <ActionsCell
              shouldUpdateIndicator={record}
              actions={[
                {
                  onSelect: () => handleEditDomain(record),
                  label: t('Edit domain'),
                  fastIcon: (
                    <AccFastIcon
                      src={IconEdit}
                      size={20}
                      color={record.isDemoDomain ? disabledIconColor : iconColor}
                    />
                  ),
                  disabled: record.isDemoDomain && t('Can\'t edit a demo domain'),
                },
                {
                  onSelect: () => showDeleteConfirmation(record),
                  label: t('Delete domain'),
                  fastIcon: <AccFastIcon src={IconTrash} size={20} color={iconColor} />,
                },
              ]}
            />
          );
        },
        className: 'no-border',
      },
    ],
    [],
  );

  const tableLabels: TableLabels = useMemo(
    () => ({
      cellLoadingTooltip: 'Refreshing domains...',
      paginationLoading: 'Loading domains ...',
    }),
    [],
  );

  return { columns, tableLabels, dataKey };
};
