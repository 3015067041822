import { useMemo } from 'react';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import { AccFastIcon } from 'Components/AccFastIcon';
import Cell from 'Components/AccuTable/CellRenderer/HelperComponents/AccuCell';
import NaCell from 'Components/AccuTable/CellRenderer/helpers/NaCell';
import { ColumnType, TableLabels, TableOrder } from 'Components/DataTable';
import { FixedType } from 'Components/DataTable/table-core/interface';
import FormatNumber from 'Components/FormatNumber';
import ActionsCell from 'Components/Table/TableRow/ActionsCell';
import FormattedDomainCell from 'Components/Table/TableRow/FormattedDomainCell';
import ValueDelta from 'Components/Table/TableRow/ValueDelta';
import { CompetitorRowNode as Competitor } from 'Ghql';
import { SORTING_TYPE } from 'Pages/Keywords/Table/hooks/keyword/constants';
import { DomainInfo, DomainTypeChoices } from 'Query';
import { t, tct } from 'Utilities/i18n';
import styleVariables from 'css/base/variables.module.scss';
import IconEdit from 'icons/IconEdit.svg';
import { IconTrash } from 'icons/tag-cloud';
import { useCompetitorActions } from '../support/actions';
import { CompetitorColumnID } from '../support/constants';

interface UseCompetitorsTableInfo {
  domainInfo?: DomainInfo;
  isPercentage: boolean;
  domainId?: number;
  isNaverDomain?: boolean;
}

const valueComponent = (valueKey: keyof Competitor, precision: number = 0) => {
  const result = (props: any) => {
    const value = get(props.record, valueKey);
    return (
      <Cell rightAligned>
        <FormatNumber precision={precision}>{value || 0}</FormatNumber>
      </Cell>
    );
  };
  result.displayName = 'valueComponent';
  return result;
};

const valueDeltaComponent = (
  valueKey: keyof Competitor,
  valueChangeKey: keyof Competitor,
  percentage: boolean = false,
  precision: number = 0,
  reverseColor: boolean = false,
) => {
  const resultComponent = (props: any) => {
    const value = get(props.record, valueKey);
    const valueChange = get(props.record, valueChangeKey);

    if (isNil(value) || isNil(valueChange)) {
      return <NaCell tooltip={t('No value for this date.')} align="right" />;
    }

    const result = value - valueChange;
    const percentageProps = percentage ? { percentage: true, precision: precision || 2 } : {};
    return (
      <Cell rightAligned>
        <ValueDelta
          precision={precision}
          {...percentageProps}
          reverseColor={reverseColor}
          currentValue={value}
          delta={result}
        />
      </Cell>
    );
  };
  resultComponent.displayName = 'valueDeltaComponent';

  return resultComponent;
};

export const NaverBlacklist: string[] = [
  CompetitorColumnID.ORGANIC_TRAFFIC,
  CompetitorColumnID.ORGANIC_TRAFFIC_CHANGE,
  CompetitorColumnID.DYNAMIC_CTR,
  CompetitorColumnID.DYNAMIC_CTR_CHANGE,
];

export const useCompetitorsTableInfo = ({
  domainInfo,
  isPercentage,
  domainId,
}: UseCompetitorsTableInfo) => {
  const { handleEditCompetitor, handleDeleteCompetitor } = useCompetitorActions();
  const { isDemoDomain } = domainInfo || {};
  const isNaverDomain = domainInfo?.domainType === DomainTypeChoices.A_7;

  const byDomainTypeFilter = (col: ColumnType<Competitor>): boolean => {
    // if (domainInfo?.domainType === DomainTypeChoices.A_7) {
    if (isNaverDomain) {
      return !col.id || !NaverBlacklist.includes(col.id);
    }
    return true;
  };

  const columns: ColumnType<Competitor>[] = useMemo(
    () =>
      [
        {
          id: CompetitorColumnID.CHECKBOX,
          width: 35,
          fixed: 'left',
          onCell: (record: any) => ({
            type: 'checkbox',
            data: record,
            hideCondition: (data?: Competitor) => data?.ownDomain,
          }),
        },
        {
          id: CompetitorColumnID.COMPETITOR_INFO,
          title: t('Competitor'),
          width: 220,
          fixed: 'left',
          flex: true,
          onHeaderCell: () => ({
            tooltip: t('Competitor Info'),
            ordering: {
              defaultOrder: TableOrder.ASC,
              orderBy: CompetitorColumnID.COMPETITOR_INFO,
              sortingKey: SORTING_TYPE.alphabetical,
            },
          }),
          cellRenderer: (props: { record: Competitor }) => {
            const rowData = props?.record;

            return (
              <FormattedDomainCell
                domain={rowData?.domain ?? ''}
                displayName={rowData?.displayName ?? ''}
                faviconUrl={rowData?.faviconUrl ?? ''}
              />
            );
          },
        },

        {
          id: CompetitorColumnID.ORGANIC_TRAFFIC,
          combineRow: CompetitorColumnID.ORGANIC_TRAFFIC_CHANGE,
          title: t('AI SoV'),
          width: 100,
          sortField: 'organic_traffic',
          onHeaderCell: () => ({
            reverseDirection: true,
            tooltip: t('AI Share of Voice for this competitor'),
            ordering: {
              defaultOrder: TableOrder.ASC,
              orderBy: CompetitorColumnID.ORGANIC_TRAFFIC,
              sortingKey: SORTING_TYPE.numerical,
            },
          }),
          cellRenderer: (props: { record: Competitor }) => {
            const { organicTraffic } = props.record;
            if (isNil(organicTraffic)) {
              return <NaCell tooltip={t('No AI SoV for this date.')} align="right" />;
            }
            return (
              <Cell rightAligned={true}>
                <FormatNumber>{organicTraffic ?? 0}</FormatNumber>
              </Cell>
            );
          },
        },
        {
          id: CompetitorColumnID.ORGANIC_TRAFFIC_CHANGE,
          title: t('+/-'),
          onHeaderCell: () => ({
            reverseDirection: true,
            tooltip: t('Change in AI Share of Voice between the two compared dates'),
            ordering: {
              defaultOrder: TableOrder.ASC,
              orderBy: CompetitorColumnID.ORGANIC_TRAFFIC_CHANGE,
              sortingKey: SORTING_TYPE.numerical,
            },
          }),
          width: 80,
          cellRenderer: valueDeltaComponent('organicTraffic', 'organicTrafficCompare', false),
        },

        {
          id: CompetitorColumnID.DYNAMIC_CTR,
          combineRow: CompetitorColumnID.DYNAMIC_CTR_CHANGE,
          title: t('Average CTR'),
          width: 100,
          sortField: 'dynamic_ctr',
          onHeaderCell: () => ({
            reverseDirection: true,
            tooltip: t('Average CTR for this competitor'),
            ordering: {
              defaultOrder: TableOrder.ASC,
              orderBy: CompetitorColumnID.DYNAMIC_CTR,
              sortingKey: SORTING_TYPE.numerical,
            },
          }),
          cellRenderer: (props: { record: Competitor }) => {
            const { dynamicCtrValue } = props.record;
            if (isNil(dynamicCtrValue)) {
              return <NaCell tooltip={t('No CTR for this date.')} align="right" />;
            }
            return (
              <Cell rightAligned={true}>
                <FormatNumber percentage precision={2}>
                  {dynamicCtrValue ?? 0}
                </FormatNumber>
              </Cell>
            );
          },
        },
        {
          id: CompetitorColumnID.DYNAMIC_CTR_CHANGE,
          title: t('+/-'),
          onHeaderCell: () => ({
            reverseDirection: true,
            tooltip: t('Change in CTR between the two compared dates'),
            ordering: {
              defaultOrder: TableOrder.ASC,
              orderBy: CompetitorColumnID.DYNAMIC_CTR_CHANGE,
              sortingKey: SORTING_TYPE.numerical,
            },
          }),
          width: 80,
          cellRenderer: valueDeltaComponent('dynamicCtrValue', 'dynamicCtrCompare', true),
        },

        {
          id: CompetitorColumnID.SHARE_OF_VOICE,
          combineRow: CompetitorColumnID.SHARE_OF_VOICE_CHANGE,
          title: t('SoV'),
          width: 100,
          sortField: 'share_of_voice',
          onHeaderCell: () => ({
            reverseDirection: true,
            tooltip: t('Share of Voice for this competitor'),
            ordering: {
              defaultOrder: TableOrder.ASC,
              orderBy: CompetitorColumnID.SHARE_OF_VOICE,
              sortingKey: SORTING_TYPE.numerical,
            },
          }),
          cellRenderer: (props: { record: Competitor }) => {
            if (isPercentage) {
              const { shareOfVoicePercentageValue } = props.record;
              return (
                <Cell rightAligned={true}>
                  {shareOfVoicePercentageValue ? (
                    <FormatNumber percentage precision={2}>
                      {shareOfVoicePercentageValue}
                    </FormatNumber>
                  ) : (
                    shareOfVoicePercentageValue ?? 0
                  )}
                </Cell>
              );
            }
            const { shareOfVoiceValue } = props.record;
            return (
              <Cell rightAligned={true}>
                <FormatNumber>{shareOfVoiceValue ?? 0}</FormatNumber>
              </Cell>
            );
          },
        },
        {
          id: CompetitorColumnID.SHARE_OF_VOICE_CHANGE,
          title: t('+/-'),
          onHeaderCell: () => ({
            reverseDirection: true,
            tooltip: t('Change in SoV between the two compared dates'),
            ordering: {
              defaultOrder: TableOrder.ASC,
              orderBy: CompetitorColumnID.SHARE_OF_VOICE_CHANGE,
              sortingKey: SORTING_TYPE.numerical,
            },
          }),
          width: 80,
          cellRenderer: isPercentage
            ? valueDeltaComponent(
                'shareOfVoicePercentageValue',
                'shareOfVoicePercentageValueCompare',
                true,
              )
            : valueDeltaComponent('shareOfVoiceValue', 'shareOfVoiceCompare', false),
        },

        {
          id: CompetitorColumnID.KEYWORDS_AVG_RANK,
          title: t('Avg. Rank'),
          width: 100,
          combineRow: CompetitorColumnID.KEYWORDS_AVG_RANK_CHANGE,
          onHeaderCell: () => ({
            reverseDirection: true,
            tooltip: t('Average rank of keywords for this competitor'),
            ordering: {
              defaultOrder: TableOrder.ASC,
              orderBy: CompetitorColumnID.KEYWORDS_AVG_RANK,
              sortingKey: SORTING_TYPE.rank,
            },
          }),
          cellRenderer: valueComponent('avgRankValue', 2),
        },
        {
          id: CompetitorColumnID.KEYWORDS_AVG_RANK_CHANGE,
          title: t('+/-'),
          width: 80,
          onHeaderCell: () => ({
            reverseDirection: true,
            tooltip: t('Change in average rank of keywords between the two compared dates'),
            ordering: {
              defaultOrder: TableOrder.ASC,
              orderBy: CompetitorColumnID.KEYWORDS_AVG_RANK_CHANGE,
              sortingKey: SORTING_TYPE.numerical,
            },
          }),
          cellRenderer: valueDeltaComponent('avgRankValue', 'avgRankCompare', false, 2, true),
        },

        {
          id: CompetitorColumnID.KEYWORDS_0_TO_3,
          combineRow: CompetitorColumnID.KEYWORDS_0_TO_3_CHANGE,
          title: t('1-3'),
          onHeaderCell: () => ({
            reverseDirection: true,
            ordering: {
              defaultOrder: TableOrder.ASC,
              orderBy: CompetitorColumnID.KEYWORDS_0_TO_3,
              sortingKey: SORTING_TYPE.rank,
            },
          }),
          width: 70,
          cellRenderer: valueComponent('count1To3'),
        },
        {
          id: CompetitorColumnID.KEYWORDS_0_TO_3_CHANGE,
          title: t('+/-'),
          onHeaderCell: () => ({
            reverseDirection: true,
            ordering: {
              defaultOrder: TableOrder.ASC,
              orderBy: CompetitorColumnID.KEYWORDS_0_TO_3_CHANGE,
              sortingKey: SORTING_TYPE.numerical,
            },
          }),
          width: 66,
          cellRenderer: valueDeltaComponent('count1To3', 'count1To3Compare'),
        },
        {
          id: CompetitorColumnID.KEYWORDS_4_TO_10,
          combineRow: CompetitorColumnID.KEYWORDS_4_TO_10_CHANGE,
          title: t('4-10'),
          onHeaderCell: () => ({
            reverseDirection: true,
            ordering: {
              defaultOrder: TableOrder.ASC,
              orderBy: CompetitorColumnID.KEYWORDS_4_TO_10,
              sortingKey: SORTING_TYPE.rank,
            },
          }),
          width: 70,
          cellRenderer: valueComponent('count4To10'),
        },
        {
          id: CompetitorColumnID.KEYWORDS_4_TO_10_CHANGE,
          title: t('+/-'),
          onHeaderCell: () => ({
            reverseDirection: true,
            ordering: {
              defaultOrder: TableOrder.ASC,
              orderBy: CompetitorColumnID.KEYWORDS_4_TO_10_CHANGE,
              sortigKey: SORTING_TYPE.numerical,
            },
          }),
          width: 66,
          cellRenderer: valueDeltaComponent('count4To10', 'count4To10Compare'),
        },
        {
          id: CompetitorColumnID.KEYWORDS_11_TO_20,
          combineRow: CompetitorColumnID.KEYWORDS_11_TO_20_CHANGE,
          title: t('11-20'),
          onHeaderCell: () => ({
            reverseDirection: true,
            ordering: {
              defaultOrder: TableOrder.ASC,
              orderBy: CompetitorColumnID.KEYWORDS_11_TO_20,
              sortingKey: SORTING_TYPE.rank,
            },
          }),
          width: 70,
          cellRenderer: valueComponent('count11To20'),
        },
        {
          id: CompetitorColumnID.KEYWORDS_11_TO_20_CHANGE,
          title: t('+/-'),
          onHeaderCell: () => ({
            reverseDirection: true,
            ordering: {
              defaultOrder: TableOrder.ASC,
              orderBy: CompetitorColumnID.KEYWORDS_11_TO_20_CHANGE,
              sortingKey: SORTING_TYPE.numerical,
            },
          }),
          width: 66,
          cellRenderer: valueDeltaComponent('count11To20', 'count11To20Compare'),
        },
        {
          id: CompetitorColumnID.KEYWORDS_21_TO_50,
          combineRow: CompetitorColumnID.KEYWORDS_21_TO_50_CHANGE,
          title: t('21-50'),
          onHeaderCell: () => ({
            reverseDirection: true,
            ordering: {
              defaultOrder: TableOrder.ASC,
              orderBy: CompetitorColumnID.KEYWORDS_21_TO_50,
              sortingKey: SORTING_TYPE.rank,
            },
          }),
          width: 70,
          cellRenderer: valueComponent('count21To50'),
        },
        {
          id: CompetitorColumnID.KEYWORDS_21_TO_50_CHANGE,
          title: t('+/-'),
          onHeaderCell: () => ({
            reverseDirection: true,
            ordering: {
              defaultOrder: TableOrder.ASC,
              orderBy: CompetitorColumnID.KEYWORDS_21_TO_50_CHANGE,
              sortingKey: SORTING_TYPE.numerical,
            },
          }),
          width: 66,
          cellRenderer: valueDeltaComponent('count21To50', 'count21To50Compare'),
        },

        {
          id: CompetitorColumnID.KEYWORDS_ABOVE_50,
          combineRow: CompetitorColumnID.KEYWORDS_ABOVE_50_CHANGE,
          title: t('51-100'),
          onHeaderCell: () => ({
            reverseDirection: true,
            ordering: {
              defaultOrder: TableOrder.ASC,
              orderBy: CompetitorColumnID.KEYWORDS_ABOVE_50,
              sortingKey: SORTING_TYPE.rank,
            },
          }),
          width: 70,
          cellRenderer: valueComponent('count51To100'),
        },
        {
          id: CompetitorColumnID.KEYWORDS_ABOVE_50_CHANGE,
          title: t('+/-'),
          onHeaderCell: () => ({
            reverseDirection: true,
            ordering: {
              defaultOrder: TableOrder.ASC,
              orderBy: CompetitorColumnID.KEYWORDS_ABOVE_50_CHANGE,
              sortingType: SORTING_TYPE.numerical,
            },
          }),
          width: 66,
          cellRenderer: valueDeltaComponent('count51To100', 'count51To100Compare'),
        },

        {
          id: CompetitorColumnID.KEYWORDS_UNRANKED,
          combineRow: CompetitorColumnID.KEYWORDS_UNRANKED_CHANGE,
          title: t('Unranked'),
          onHeaderCell: () => ({
            reverseDirection: true,
            ordering: {
              defaultOrder: TableOrder.ASC,
              orderBy: CompetitorColumnID.KEYWORDS_UNRANKED,
              sortingKey: SORTING_TYPE.numerical,
            },
          }),
          width: 86,
          cellRenderer: (props: { record: Competitor }) => {
            const value = props.record.countUnranked ?? 0;
            return (
              <Cell rightAligned>
                <FormatNumber>{value}</FormatNumber>
              </Cell>
            );
          },
        },
        {
          id: CompetitorColumnID.KEYWORDS_UNRANKED_CHANGE,
          title: t('+/-'),
          onHeaderCell: () => ({
            reverseDirection: true,
            ordering: {
              defaultOrder: TableOrder.ASC,
              orderBy: CompetitorColumnID.KEYWORDS_UNRANKED_CHANGE,
              sortingKey: SORTING_TYPE.numerical,
            },
          }),
          width: 66,
          cellRenderer: valueDeltaComponent('countUnranked', 'countUnrankedCompare'),
        },

        {
          id: CompetitorColumnID.ACTIONS,
          width: 76,
          fixed: 'right' as FixedType,
          cellRenderer: ({ record }: { record: Competitor }) => {
            const { snorlax4: iconColor, gray4: disabledIconColor } = styleVariables;
            const isDisabled = record.ownDomain || isDemoDomain;
            const actions = [
              {
                onSelect: () => handleEditCompetitor(record?.id, domainId),
                label: t('Edit'),
                fastIcon: (
                  <AccFastIcon
                    src={IconEdit}
                    size={20}
                    color={isDisabled ? disabledIconColor : iconColor}
                  />
                ),
                disabled: isDisabled,
              },
              {
                onSelect: () => handleDeleteCompetitor(record),
                label: t('Delete'),
                fastIcon: (
                  <AccFastIcon
                    src={IconTrash}
                    size={20}
                    color={isDisabled ? disabledIconColor : iconColor}
                  />
                ),
                disabled: isDisabled,
              },
            ];

            return <ActionsCell shouldUpdateIndicator={record} actions={actions} />;
          },
          onCell: () => ({
            hideCondition: (data?: Competitor) => data?.ownDomain,
          }),
        },
      ].filter((col) =>
        byDomainTypeFilter(col as ColumnType<Competitor>),
      ) as ColumnType<Competitor>[],
    [domainInfo?.domain, isPercentage, handleEditCompetitor, handleDeleteCompetitor, domainId],
  );

  const tableLabels: TableLabels = useMemo(
    () => ({
      cellLoadingTooltip: t('Refreshing competitors…'),
      paginationLoading: t('Loading competitors…'),
      paginationLoadingWithDetails: ({ from, to, total }) =>
        tct('Loading competitor [from] to [to] of [total] competitors', {
          total,
          from,
          to,
        }),
      paginationDetails: ({ from, to, total }) =>
        tct('Competitor [from] to [to] of [total] competitors', {
          total,
          from,
          to,
        }),
    }),
    [],
  );

  const defaultOrdering = useMemo(
    () => ({
      order: TableOrder.ASC,
      orderBy: CompetitorColumnID.COMPETITOR_INFO,
    }),
    [],
  );
  return { columns, tableLabels, defaultOrdering };
};
