import isNil from 'lodash/isNil';
import ErrorBoundary from 'Components/ErrorBoundary';
import { useIsPercentage } from 'Hooks/data/domain/useQueryDomainInfo';
import KpiBox, { KpiBoxSizeProps } from 'Pages/Keywords/Overview/components/KpiBar/KpiBox';
import { KPI_NAMES } from 'Pages/Keywords/Overview/components/KpiBar/index';
import { t } from 'Utilities/i18n';
import { KpiBarQueryProps } from './useKpiBarQueryWithDisplayCurrency';

const ShareOfVoiceKpiBox = ({
  size,
  kpiBarQueryProps,
  draggable,
}: KpiBoxSizeProps & { kpiBarQueryProps: KpiBarQueryProps }) => {
  const { displayCurrency, data, loading } = kpiBarQueryProps;

  const showPercentage = useIsPercentage();

  const sovValue = !isNil(data?.kpis?.allKpis?.shareOfVoiceSum)
    ? showPercentage
      ? (data?.kpis?.allKpis?.shareOfVoiceSum as number) / 100
      : data?.kpis?.allKpis?.shareOfVoiceSum
    : undefined;
  const sovBeforeValue = !isNil(data?.kpis?.allKpis?.shareOfVoiceSumBefore)
    ? showPercentage
      ? (data?.kpis?.allKpis?.shareOfVoiceSumBefore as number) / 100
      : data?.kpis?.allKpis?.shareOfVoiceSumBefore
    : undefined;
  return (
    <ErrorBoundary>
      <KpiBox
        id={KPI_NAMES.SHARE_OF_VOICE}
        size={size}
        title={t('Share of Voice')}
        loading={loading || !displayCurrency}
        value={sovValue}
        beforeValue={sovBeforeValue}
        showPercentage={showPercentage}
        precision={showPercentage ? 2 : 0}
        maxValue={data?.kpis?.allKpis?.maxShareOfVoiceSum}
        draggable={draggable}
      />
    </ErrorBoundary>
  );
};

export default ShareOfVoiceKpiBox;
