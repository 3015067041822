import cn from 'classnames';
import noop from 'lodash/noop';
import toFormField from 'Components/Forms/toFormField';
import {
  AddKeywordsModalType,
  AddKeywordsMode,
  arrayToString,
  stringToArray,
} from 'Components/Modal/Content/AddKeywords';
import { withAceEditor } from './WithAceEditor';
import './keywordsfield.scss';

type KeywordsFieldProps = {
  value: string[];
  onChange: (val: string[] | undefined) => void;
  placeholder: string;
  disabled?: boolean;
  mode?: AddKeywordsModalType;
  aceComponent: React.ElementType;
};

const AceEditorStaticProps = {
  // we should editorProps as an empty object to avoid crashing react-ace
  editorProps: {},
};

const KeywordsField = (props: KeywordsFieldProps) => {
  const { placeholder, disabled } = props;

  const isImportMode = props.mode === AddKeywordsMode.IMPORT;
  const height = isImportMode ? '338px' : '200px';
  const value = arrayToString(props.value);
  const AceEditor = props.aceComponent;

  const handleChange = (val: string) => {
    props.onChange(stringToArray(val));
  };

  return (
    <AceEditor
      {...AceEditorStaticProps}
      className={cn({
        disabled_keywords: isImportMode,
      })}
      placeholder={placeholder}
      onChange={handleChange}
      onBlur={noop}
      theme={'chrome'}
      mode={'text'}
      fontSize={'0.8rem'}
      showGutter={true}
      highlightActiveLine={true}
      value={value}
      defaultValue={value}
      width={'100%'}
      height={height}
      focus={true}
      disabled={isImportMode || disabled}
      readOnly={isImportMode}
      setOptions={{
        showLineNumbers: true,
        tabSize: 1,
        fontFamily: '\'Roboto Mono\', monospace',
        showPrintMargin: false,
        dragEnabled: false,
        newLineMode: 'unix',
        scrollSpeed: isImportMode ? 0 : undefined,
      }}
    />
  );
};

export default toFormField(withAceEditor(KeywordsField));
