import React from 'react';
import AccAlert from 'Components/AccAlert/AccAlert';
import AccText from 'Components/Text/AccText';
import AccTitle from 'Components/Title/AccTitle';
import { TableSearchInput } from 'Pages/SiteMapping/components/TreeTable/components/TableSearchInput';
import { t } from 'Utilities/i18n';
import styles from './table-wrapper-group-edit.module.scss';

export const TableWrapperGroupEdit = ({ submitSearch, activeSearch, searchKey, isDirty }: any) => {
  return (
    <div className={styles.tableHeader}>
      <AccTitle type="h5">{t('Edit mode')}</AccTitle>
      <TableSearchInput submitSearch={submitSearch} activeSearch={activeSearch} key={searchKey} />
      {!!isDirty && (
        <AccAlert px="sm" py="xs" ml="md" severity="info">
          {t('You have unsaved changes')}
        </AccAlert>
      )}
      <AccText ml="auto">
        {t('Drag and drop or select multiple folders/tags and "Move (x) selected"')}
      </AccText>
    </div>
  );
};
