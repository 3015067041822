import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import cn from 'classnames';
import { TableSettingGroup, TableSettingOptionProps } from '../support/types';
import { TableSettingOptionGroup, TableSettingOptionGroupProps } from './TableSettingGroup';
import styles from './tableSettings.module.scss';

const SortableItem = SortableElement<TableSettingOptionGroupProps>(TableSettingOptionGroup);

type Props = {
  items: TableSettingGroup[];
  grabbingCursor?: boolean;
  itemProps: TableSettingOptionProps;
};

export const SortableTableSettingsList = SortableContainer<Props>(
  ({ items, itemProps, grabbingCursor }: Props) => {
    return (
      <div className={cn(styles.listContainer, { [styles.useGrabbing]: grabbingCursor })}>
        {items?.filter(Boolean).map((value, index) => {
          const Element = !value.fixed ? SortableItem : TableSettingOptionGroup;
          return <Element key={`item-${value?.id}`} index={index} item={value} {...itemProps} />;
        })}
      </div>
    );
  },
);
