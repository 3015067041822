import cookie from 'react-cookies';
import { useSelector } from 'react-redux';
import { useUser } from 'Hooks/data/user/useUser';
import { ENABLE_NEW_FEATURES_COOKIE } from 'Pages/Profile/components/EditProfile';
import { selectHasKeydisAccess } from 'Selectors/DomainSelector';
import { selectOrganizationId } from 'Selectors/OrganizationSelector';

function isAccurankerOrgId(organizationId: string | null) {
  if (process.env.NODE_ENV === 'development') {
    return true;
  }
  return organizationId === '28571';
}

// eslint-disable-next-line import/no-unused-modules
export const useIsAccurankerUser = () => {
  const organizationId = useSelector(selectOrganizationId);
  return isAccurankerOrgId(organizationId);
};

/** Does the current user have access to the 2FA user interface */
export const useIs2faEligibleUser = () => {
  const organizationId = useSelector(selectOrganizationId);
  return organizationId === '1' || isAccurankerOrgId(organizationId);
};

export const hasNewFeaturesEnabled = () => {
  return !!cookie.load(ENABLE_NEW_FEATURES_COOKIE);
};

export const useHasEnabledNewFeatures = () => {
  const user = useUser();
  const isAccuRankerUser = user?.isImpersonating || user?.salesManager;
  return Boolean(isAccuRankerUser && !!cookie.load(ENABLE_NEW_FEATURES_COOKIE));
};

export const useHasWordCloudAccess = () => {
  const organizationId = useSelector(selectOrganizationId);

  if (process.env.NODE_ENV === 'development') {
    return true;
  }
  return organizationId === '28571' || organizationId === '42453' || organizationId === '39462'; // 28571 = accuranker subaccount, '42453' and '39462' = TEMP SHOULD BE DELETED LATER WHEN GROUPINGS + RESEARCH RELEASED. Some potential customer
};

/** "Connect domains to analytics" button.*/
export const useHasMultiGaConnectAccess = () => {
  const organizationId = useSelector(selectOrganizationId);

  if (process.env.NODE_ENV === 'development') {
    return true;
  }

  // enable to allow leadventure organizationId
  return organizationId === '28571' || organizationId === '44668';
};

/** "Connect domains to Google Search console" button. */
export const useHasMultiGSCConnectAccess = () => {
  const organizationId = useSelector(selectOrganizationId);

  if (process.env.NODE_ENV === 'development') {
    return true;
  }

  // enable to allow leadventure organizationId
  return organizationId === '28571' || organizationId === '44668';
};

/**
 * Only users whose plan has OrganizationPlanNode.hasKeydisAccess should see the Keyword Discovery page
 */
export const useHasKeydisAccess = () => useSelector(selectHasKeydisAccess);

// eslint-disable-next-line import/no-unused-modules
export const withIsAccurankerUser = (Component) => {
  const result = (props) => {
    const isAccurankerUser = useIsAccurankerUser();
    return <Component {...props} isAccurankerUser={isAccurankerUser} />;
  };
  result.displayName = 'withIsAccurankerUser';

  return result;
};
