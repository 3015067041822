import ValueIndicator from 'Components/ValueIndicator/value-indicator';
import { t } from 'Utilities/i18n';
import Cell from './HelperComponents/AccuCell';

export type RankChangeCellProps = {
  value: {
    data: {
      rankValue: number | null;
      rankCompare: number | null;
      baseRank: number | null;
      baseRankCompare: number | null;
      localPackRank: number | null;
      localPackRankCompare: number | null;
      updatingKeyword: boolean;
    };
    loadingData?: boolean;
  };
  isBaseRank?: boolean;
  isLocalPackRank?: boolean;
};

const RankChangeCell = (props: RankChangeCellProps) => {
  const {
    value: { data },
    isBaseRank,
    isLocalPackRank,
  } = props;
  const { rankValue, rankCompare, baseRank, baseRankCompare, localPackRank, localPackRankCompare } =
    data;

  return (
    <Cell className="u-flex u-items-center" rightAligned={true}>
      <ValueIndicator
        negate={true}
        beforeValue={
          isBaseRank ? baseRankCompare : isLocalPackRank ? localPackRankCompare : rankCompare
        }
        afterValue={isBaseRank ? baseRank : isLocalPackRank ? localPackRank : rankValue}
        noCompareString={t('Not in top 100')}
      />
    </Cell>
  );
};

export default RankChangeCell;
