import { useApolloClient } from '@apollo/client';
import { ColumnType, DataTable, TableFetchDataCallBack, TableOrder } from 'Components/DataTable';
import {
  MultiAccountOwnerFragment,
  MultiAccountOwnersDocument,
  MultiAccountOwnersQuery,
  MultiAccountOwnersQueryVariables,
  useRemoveMultiAccountMutation,
} from 'Ghql';
import { useModal } from 'Hooks/base/useModal';
import toast from 'Hooks/useToast';
import { SORTING_TYPE } from 'Pages/Keywords/Table/hooks/keyword/constants';
import { TableIDs } from 'Types/Table';
import { t } from 'Utilities/i18n';
import { notEmpty } from 'Utilities/underdash';
import { ExternalAccessTablesProps } from '../components/ExternalAccessTables';
import { ColumnIDs } from './ColumnIDs';
import tableStyles from '../ownersTable.module.scss';

const useFetchData = (): TableFetchDataCallBack => {
  const client = useApolloClient();

  return async () => {
    return client
      .query<MultiAccountOwnersQuery, MultiAccountOwnersQueryVariables>({
        query: MultiAccountOwnersDocument,
        fetchPolicy: 'network-only',
      })
      .then((response) => {
        const owners =
          response.data?.user?.organization?.multiAccountOwners?.filter(notEmpty) || [];

        return { data: owners, length: owners?.length ?? 0 };
      });
  };
};

const OwnersTable = ({ dataKey, updateTables }: ExternalAccessTablesProps) => {
  const fetchData = useFetchData();

  const { showModal } = useModal();

  const [removeMultiAccount] = useRemoveMultiAccountMutation();

  const handleDelete = (name: string, id: string) => {
    const input = {
      id,
      isOwner: true,
    };

    showModal({
      modalType: 'Confirmation',
      modalProps: {
        cancelLabel: t('Cancel'),
        confirmLabel: t('Remove access'),
        lockDuration: 0,
        title: t('Remove organization access?'),
        description: t(
          'Once removing access, the organization %s can no longer access your account.',
          name,
        ),
        action: () =>
          removeMultiAccount({
            variables: {
              input,
            },
          }).then((response) => {
            const errors = response.data?.removeMultiAccount?.errors ?? [];
            if (!errors.length) {
              toast.success(t('Organization removed'));
              updateTables();
            } else {
              const allErrors = errors
                .filter((error) => error?.field === '__all__')
                .reduce((error, acc) => acc + error, '');
              toast.error(allErrors);
              toast.error(t('Unable to remove organization'));
            }
          }),
      },
    });
  };

  const columns: ColumnType<MultiAccountOwnerFragment>[] = [
    {
      id: ColumnIDs.ORGANIZATION_NAME,
      title: t('Organization Name'),
      flex: true,
      width: 100,
      cellRenderer: (props) => <span>{props.record.fromOrganization?.name}</span>,
      onHeaderCell: () => ({
        ordering: {
          defaultOrder: TableOrder.DESC,
          orderBy: 'fromOrganization.name',
          sortingKey: SORTING_TYPE.alphabetical,
        },
      }),
    },
    {
      id: ColumnIDs.USER_TYPE,
      title: t('Account access as'),
      flex: true,
      width: 50,
      cellRenderer: (props) => (
        <span>{props.record.isOrgAdmin ? t('Admin user') : t('Normal user')}</span>
      ),
      onHeaderCell: () => ({
        ordering: {
          defaultOrder: TableOrder.DESC,
          orderBy: 'isOrgAdmin',
          sortingKey: SORTING_TYPE.alphabetical,
        },
      }),
    },
    {
      id: ColumnIDs.ACTIONS,
      title: t('Actions'),
      width: 150,
      cellRenderer: (props) =>
        !props.record.fromOrganizationPays && (
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              props.record.fromOrganization?.name &&
                handleDelete(props.record.fromOrganization?.name, props.record.id);
            }}
          >
            {t('Remove')}
          </a>
        ),
    },
  ];

  return (
    <DataTable
      tableId={TableIDs.EXTERNAL_ACCESS_OWNERS}
      dataKey={dataKey.toString()}
      fetchData={fetchData}
      columns={columns}
      emptyOptions={{
        title: t('No Data'),
        subTitle: t('There are currently no organizations with external access.'),
      }}
      offlineFilter={{
        tableName: TableIDs.EXTERNAL_ACCESS_OWNERS,
        mappings: {
          [ColumnIDs.ORGANIZATION_NAME]: 'fromOrganization.name',
          [ColumnIDs.USER_TYPE]: 'isOrgAdmin',
        },
      }}
      className={tableStyles.pendingOwnersTable}
    />
  );
};

export default OwnersTable;
