import { DataTable, SelectRowType, TableOrder } from 'Components/DataTable';
import { TableIDs } from 'Types/Table';
import { t } from 'Utilities/i18n';
import { ColumnIDs } from './helpers/ColumnIDs';
import { useFetchGSCKeywords, useGSCKeywordsColumns } from './helpers/hooks';

type GSCKeywordsTableProps = {
  dataKey: number;
  domainId: string | undefined;
};

const GSCKeywordsTable = ({ domainId, dataKey }: GSCKeywordsTableProps) => {
  const fetchData = useFetchGSCKeywords({ domainId });
  const columns = useGSCKeywordsColumns();

  return (
    <DataTable
      tableId={TableIDs.IMPORT_GSC}
      selectConfig={{ selectRowType: SelectRowType.MULTI_PAGE_SELECT }}
      dataKey={dataKey?.toString()}
      fetchData={fetchData}
      columns={columns}
      emptyOptions={{
        title: t('No Keywords'),
        subTitle: t('There are currently no keywords to import.'),
      }}
      defaultOrdering={{
        order: TableOrder.DESC,
        orderBy: ColumnIDs.IMPRESSIONS,
      }}
      offlineFilter={{
        skip: ['domains'],
        tableName: TableIDs.IMPORT_GSC,
        skipAll: false,
        mappings: {
          gscExists: 'exists',
          gscPosition: 'position',
          gscCtr: 'ctr',
        },
      }}
    />
  );
};

export default GSCKeywordsTable;
