import { useApolloClient } from '@apollo/client';
import { ColumnType, DataTable, TableFetchDataCallBack, TableOrder } from 'Components/DataTable';
import {
  ApiFilterFragment,
  ApiFiltersDocument,
  ApiFiltersQuery,
  ApiFiltersQueryVariables,
} from 'Ghql';
import { TableIDs } from 'Types/Table';
import { t } from 'Utilities/i18n';
import { notEmpty } from 'Utilities/underdash';
import { ColumnIDs } from './ColumnIDs';

const useFetchData = (): TableFetchDataCallBack => {
  const client = useApolloClient();

  return async () => {
    return client
      .query<ApiFiltersQuery, ApiFiltersQueryVariables>({
        query: ApiFiltersDocument,
        fetchPolicy: 'network-only',
      })
      .then((response) => {
        const filters = response.data?.user?.organization?.apiFilters?.filter(notEmpty) || [];

        return { data: filters, length: filters?.length ?? 0 };
      });
  };
};

const IntegrationAPIsTable = () => {
  const fetchData = useFetchData();

  const columns: ColumnType<ApiFilterFragment, {}>[] = [
    {
      id: ColumnIDs.ID,
      title: t('ID'),
      flex: true,
      width: 100,
      cellRenderer: (props) => <span>{props.record.id}</span>,
      onHeaderCell: () => ({
        ordering: {
          defaultOrder: TableOrder.DESC,
          orderBy: 'id',
        },
      }),
    },
    {
      id: ColumnIDs.NAME,
      title: t('Name'),
      flex: true,
      width: 100,
      cellRenderer: (props) => <span>{props.record.name}</span>,
      onHeaderCell: () => ({
        ordering: {
          defaultOrder: TableOrder.DESC,
          orderBy: 'name',
        },
      }),
    },
  ];

  return (
    <DataTable
      tableId={TableIDs.INTEGRATION_APIS}
      fetchData={fetchData}
      columns={columns}
      pagination={true}
      pageSize={100000}
      emptyOptions={{
        title: t('No Filters'),
        subTitle: t('There are currently no API filters to show.'),
      }}
      offlineFilter={{
        tableName: TableIDs.INTEGRATION_APIS,
        skipAll: true,
        mappings: {
          [ColumnIDs.ID]: 'id',
          [ColumnIDs.NAME]: 'name',
        },
      }}
    />
  );
};

export default IntegrationAPIsTable;
