import { Box } from '@mantine/core';
import { IconCheck, IconX } from '@tabler/icons-react';
import AccText from 'Components/Text/AccText';
import styles from './password-input.module.scss';

type Props = {
  meets?: boolean;
  label?: string;
  error?: boolean;
};
const PasswordRequirement = (props: Props) => {
  const { meets, label, error } = props;

  return (
    <AccText
      c={error ? 'red' : meets ? 'green' : 'black'}
      className={styles.passwordRequirement}
      fw={400}
      size="sm"
    >
      {meets ? (
        <IconCheck size="0.9rem" />
      ) : (
        <IconX style={{ minWidth: 'fit-content' }} size="0.9rem" />
      )}{' '}
      <Box ml={10} className={styles.labelWrapper}>
        {label}
      </Box>
    </AccText>
  );
};

export default PasswordRequirement;
