import { useNavigate } from 'react-router';
import cn from 'classnames';
import AccButton from 'Components/AccButton/AccButton';
import { linkToKeywordsDomain } from 'Components/Filters/LinkToDomain';
import FormatNumber from 'Components/FormatNumber/new';
import AccText from 'Components/Text/AccText';
import useUserPermission from 'Hooks/useUserPermission';
import { t } from 'Utilities/i18n';
import { DashboardNode } from '../support/types';
import styles from '../domains-table.module.scss';

type Props = {
  domainNode: DashboardNode;
};

const KeywordsCell = (props: Props) => {
  const { countKeywords, id } = props.domainNode;
  const { userHasWriteAccess } = useUserPermission();
  const navigate = useNavigate();

  const handleAddKeywords = () => {
    const domainKeywordsLink = linkToKeywordsDomain(id?.toString() || '');
    navigate(domainKeywordsLink, { state: { addKeywordsModal: true } });
  };

  return (
    <div className={cn(styles.domainsCellContainer, styles.numberCell)}>
      {countKeywords ? (
        <FormatNumber className={styles.left} value={countKeywords} />
      ) : id && userHasWriteAccess ? (
        <AccButton w="100%" variant="secondary" onClick={handleAddKeywords}>
          {t('Add keywords')}
        </AccButton>
      ) : (
        <AccText ta="right" w="100%" size="xl">
          -
        </AccText>
      )}
    </div>
  );
};

export default KeywordsCell;
