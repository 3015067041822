import isNil from 'lodash/isNil';
import { DashboardNode } from 'Pages/Domains/DomainsTable/support/types';
import styles from '../domains-table.module.scss';

type Props = {
  domainNode: DashboardNode;
};

const AverageRankCell = (props: Props) => {
  const { countKeywords, avgRank } = props.domainNode || {};
  if (!countKeywords || isNil(avgRank)) return null;
  return <span className={styles.valueCell}>{avgRank.toFixed(1)}</span>;
};

export default AverageRankCell;
