import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { useRowIndex } from '../support/hooks/useRowIndex';

export type RowRenderType<T> = (props: { node?: T; onToggle?: (item: T) => void }) => JSX.Element;

type TableRowProps<T> = {
  data: T[];
  staticIndex: number;
  rowRender: RowRenderType<T>;
  onToggle?: (item: T) => void;
  className?: string;
  propsIndex?: number;
  storeKey?: string;
};

export const TableRow = <T extends object>({
  staticIndex,
  data,
  rowRender: RowRender,
  onToggle,
  className,
  propsIndex,
  storeKey,
}: TableRowProps<T>) => {
  const index = useRowIndex(staticIndex, propsIndex, storeKey);

  const rootNode = data[index] || {};

  return (
    <div
      className={className}
      style={{ display: !isEmpty(rootNode) ? 'flex' : 'none' }}
      data-testid={`table-row-${(rootNode as Record<string, string>)?.name}`}
    >
      <RowRender node={rootNode} onToggle={onToggle} />
    </div>
  );
};
