import { useEffect, useMemo, useState } from 'react';
import { useIntercom } from 'react-use-intercom';
import { useQuery } from '@apollo/client';
import { Box, Center, Grid } from '@mantine/core';
import { IconShoppingCart } from '@tabler/icons-react';
import cn from 'classnames';
import AccButton from 'Components/AccButton/AccButton';
import AccSegmentedControl from 'Components/AccSegmentedControl/AccSegmentedControl';
import Breadcrumbs from 'Components/Breadcrumbs/Breadcrumbs';
import FormatNumber from 'Components/FormatNumber';
import NewFormatNumber from 'Components/FormatNumber/new';
import Segment, { Images } from 'Components/Segment';
import Skeleton from 'Components/Skeleton';
import AccText from 'Components/Text/AccText';
import { OrganizationActivePlanFragment } from 'Ghql';
import { useUser } from 'Hooks/data/user/useUser';
import { useGa4Tracking } from 'Hooks/useConversionTracking';
import { withOnlyOrgPermission } from 'Pages/SelectPlan/support/withOnlyOrgPermission';
import type {
  selectPlanForm_pricingPlansChoices_pricingPlansChoices_choices as IPlanChoices,
  pricingPlansChoicesResponse,
} from 'Query';
import { WithRouter, withRouter } from 'Utilities/Router';
import { t, tct } from 'Utilities/i18n';
import reusableStyles from 'css/reusable-styles.module.scss';
import PlanFeatures from './plan-features';
import { pricingPlansQuery } from './pricingPlans.query';
import './select-plan.scss';

interface Props extends WithRouter {
  errorMessage: string;
}

const CYCLE = {
  MONTHLY: 1,
  ANNUAL: 2,
};

const PlansSkeleton = (): JSX.Element => (
  <Grid.Col span={{ xs: 12, md: 12 }} className={'plan'}>
    <Skeleton
      linesConfig={[
        { type: 'subtitle', options: { width: '40%', alignment: 'center' } },
        { type: 'title', options: { width: '60%', alignment: 'center' } },
        { type: 'input', options: { width: '100%', alignment: 'center' } },
        { type: 'spacer-underline', options: { width: '100%' } },
        { type: 'text', options: { width: '40%' } },
        { type: 'spacer-underline', options: { width: '100%' } },
        { type: 'text', options: { width: '45%' } },
        { type: 'spacer-underline', options: { width: '100%' } },
        { type: 'text', options: { width: '70%' } },
        { type: 'spacer-underline', options: { width: '100%' } },
        { type: 'text', options: { width: '85%' } },
        { type: 'spacer-underline', options: { width: '100%' } },
        { type: 'text', options: { width: '60%' } },
        { type: 'spacer-underline', options: { width: '100%' } },
        { type: 'text', options: { width: '56%' } },
        { type: 'spacer-underline', options: { width: '100%' } },
        { type: 'text', options: { width: '55%' } },
        { type: 'spacer-underline', options: { width: '100%' } },
        { type: 'text', options: { width: '40%' } },
        { type: 'spacer-underline', options: { width: '100%' } },
        { type: 'text', options: { width: '43%' } },
        { type: 'spacer-underline', options: { width: '100%' } },
        { type: 'text', options: { width: '35%' } },
        { type: 'spacer-underline', options: { width: '100%' } },
        { type: 'text', options: { width: '30%' } },
        { type: 'spacer-underline', options: { width: '100%' } },
        { type: 'text', options: { width: '10%' } },
        { type: 'button', options: { width: '40%', alignment: 'center' } },
      ]}
    />
  </Grid.Col>
);

const ErrorMessage = ({ error }): JSX.Element | null => {
  if (!error) return null;
  return (
    <Box mb="lg" className="alert alert-danger" role="alert">
      {error}
    </Box>
  );
};

const FeatureSegment = () => (
  <Segment small info className={'select-plan-container__plan-description__disclaimer'}>
    <div className={'select-plan-container__plan-description__disclaimer-content'}>
      {tct('[b1:All features] are available in [b2:all] plans at [b3:no extra cost]', {
        b1: <b />,
        b2: <b />,
        b3: <b />,
      })}
    </div>
  </Segment>
);

const InfoSegment = (): JSX.Element => {
  const { show: openSupportChat, isOpen } = useIntercom();

  return (
    <Segment primary image={Images.GrumpySmile}>
      <div>
        <b>{t('Are you looking for more keywords?')}</b>
      </div>
      <br />
      <div>
        {t('Don\'t worry, we\'ve got you covered.')}
        <br />
        {t(
          'Click here to contact one of our customer success representatives, and they\'ll get back to you.',
        )}
      </div>
      <br />
      <AccButton variant="primary" onClick={isOpen ? () => {} : openSupportChat}>
        {t('Contact support')}
      </AccButton>
    </Segment>
  );
};

const isSamePlanWithSameBillingCycle = ({
  planChoice,
  billingCycle,
  activePlan,
  active,
}: {
  planChoice: IPlanChoices;
  billingCycle: number;
  activePlan?: OrganizationActivePlanFragment | null;
  active?: boolean;
}): boolean =>
  !!(
    activePlan &&
    !activePlan.isTrial &&
    planChoice.maxKeywords === activePlan.maxKeywords &&
    ((activePlan.billingCycleInMonths === 12 && billingCycle === CYCLE.ANNUAL) ||
      (activePlan.billingCycleInMonths === 1 && billingCycle === CYCLE.MONTHLY)) &&
    active
  );

const useGA4TrackViewItem = (planOptions?: (IPlanChoices | null)[]) => {
  const [viewItemSent, setViewItemSent] = useState(false);

  useEffect(() => {
    if (viewItemSent) return;
    if (!planOptions) return;

    const [currency] = new Set(planOptions.map((o) => o?.currency));

    const items = planOptions
      .filter((o) => !o?.unavailable)
      .map((o) => ({
        item_id: o?.id,
        item_name: o?.name,
      }));

    window.gtag?.('event', 'view_item', {
      currency,
      items,
    });

    setViewItemSent(true);
  }, [planOptions, viewItemSent]);
};

const SelectPlanPage = ({ errorMessage, history }: Props & WithRouter) => {
  const user = useUser();
  const { data, loading, error } = useQuery<pricingPlansChoicesResponse>(pricingPlansQuery);
  const [planOptions, setPlanOptions] = useState<Array<IPlanChoices | null>>();
  const hasRecommended = false; // TODO ARR-2117. Delete this and the corresponding css when we are sure this feature will not be re-introduced

  useGa4Tracking();
  useGA4TrackViewItem(planOptions);

  useEffect(() => {
    if (data?.pricingPlansChoices) {
      const emptyOptions: (IPlanChoices | null)[] = [];
      const options = data.pricingPlansChoices.reduce((arr, o) => {
        if (o && o.choices) {
          return [...arr, ...o.choices];
        }
        return arr;
      }, emptyOptions);
      setPlanOptions(options);
      // if (options.some((opt) => opt?.suggested)) {
      //   setHasRecommended(true);
      // }
    }
  }, [data]);

  const userbillingCycleInMonths: number =
    user?.organization?.activePlan?.billingCycleInMonths || 1;

  const [billingCycle, setBillingCycle] = useState<number>(
    userbillingCycleInMonths < 12 ? CYCLE.MONTHLY : CYCLE.ANNUAL,
  );

  const handleSetBillingCycle = (cycle: string) => {
    setBillingCycle(parseInt(cycle));
  };

  const handleSelectPlan = (planId?: string) => {
    if (planId) {
      history.push(`/checkout/${billingCycle}/${planId}`);
    }
  };

  const getMonthPriceForAnnual = (priceYearly: number) => Math.round(priceYearly / 12);

  const paymentCycles = useMemo(
    () => [
      {
        value: '1',
        label: (
          <Center>
            <AccText fw={600} c={billingCycle === CYCLE.ANNUAL ? 'inherit' : 'white'}>
              {t('monthly pricing')}
            </AccText>
          </Center>
        ),
      },
      {
        value: '2',
        label: (
          <Center>
            <AccText
              pos="relative"
              fw={600}
              c={billingCycle === CYCLE.ANNUAL ? 'white' : 'inherit'}
              mr="md"
            >
              {t('annual pricing')}
              <AccText
                fw={400}
                size="sm"
                c={billingCycle === CYCLE.ANNUAL ? 'orange' : 'white'}
                className={cn('bestValueElement', { active: billingCycle === CYCLE.ANNUAL })}
              >
                {t('best value')}
              </AccText>
            </AccText>
          </Center>
        ),
      },
    ],
    [billingCycle],
  );

  const renderBillingCycleToggle = () => {
    if (!planOptions || planOptions.length === 0) {
      return null;
    }
    return (
      <div className="button-group-wrapper">
        <AccSegmentedControl
          value={billingCycle.toString()}
          onChange={handleSetBillingCycle}
          color="orange"
          data={paymentCycles}
          size="md"
          transitionDuration={150}
          classNames={{
            root: 'segmentedControlPrice',
          }}
        />
      </div>
    );
  };

  const renderAnnualDiscount = ({ priceMonthly, priceYearly, currency }: IPlanChoices) => {
    if (billingCycle === CYCLE.ANNUAL && priceMonthly && priceYearly && currency) {
      const discount = Math.ceil(priceMonthly - getMonthPriceForAnnual(priceYearly));
      return discount > 0 ? (
        <span className="yearly-discount">
          {t('You save')} <FormatNumber currency={currency}>{discount}</FormatNumber>
        </span>
      ) : null;
    }
  };

  const renderPrice = ({ priceMonthly, priceYearly, currency }: IPlanChoices) => {
    if (priceMonthly && priceYearly && currency) {
      const price =
        billingCycle === CYCLE.MONTHLY ? priceMonthly : getMonthPriceForAnnual(priceYearly);
      return (
        <p className="plan-price">
          <FormatNumber currency={currency}>{price}</FormatNumber>
          <span className="suffix">/{t('mo')}</span>
        </p>
      );
    }
  };

  const renderSelectButton = (clickHandle: () => void, selectedPlanChoice: IPlanChoices) => {
    const activePlan = user.organization?.activePlan;
    const active = user.organization?.active;

    const currPlan = isSamePlanWithSameBillingCycle({
      planChoice: selectedPlanChoice,
      active,
      activePlan: activePlan as OrganizationActivePlanFragment | null,
      billingCycle,
    });

    if (currPlan) {
      return <p className="your-plan">{t('Your Plan')}</p>;
    }
    return (
      <AccButton
        variant="primary"
        onClick={clickHandle}
        leftSection={<IconShoppingCart size={16} />}
      >
        {t('Go to checkout')}
      </AccButton>
    );
  };

  const renderPlanRow = (option: IPlanChoices | null) => {
    if (!option) return null;

    const activePlan = user.organization?.activePlan;
    const active = user.organization?.active;

    const currPlan = isSamePlanWithSameBillingCycle({
      planChoice: option,
      active,
      activePlan: activePlan as OrganizationActivePlanFragment | null,
      billingCycle,
    });
    if (option.unavailable) {
      return null;
    }

    return (
      <div
        className={cn('select-plan-container__table__row', {
          'current-plan': currPlan,
          'recommended-plan': option.suggested,
          'has-recommended': hasRecommended,
        })}
        key={option.id}
      >
        <span className="select-plan-container__table__row__cell">
          <NewFormatNumber
            value={option.maxKeywords}
            formatSettings={{
              spaceBetweenNumberAndLabel: false,
              thousandsSettings: {
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
              },
              millionsSettings: {
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
              },
              billionsSettings: {
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
              },
            }}
          />
        </span>
        <span className="select-plan-container__table__row__cell">
          {renderPrice(option)} {renderAnnualDiscount(option)}
        </span>
        {hasRecommended && (
          <span
            className={cn('select-plan-container__table__row__cell', {
              label: option.suggested,
            })}
            data-label={option.suggested && t('Recommended')}
          />
        )}
        <span className="select-plan-container__table__row__cell">
          {renderSelectButton(() => handleSelectPlan(option.id), option)}
        </span>
      </div>
    );
  };

  const renderPlan = () => {
    if (!planOptions || planOptions.length === 0) {
      return null;
    }
    return (
      <div
        className={cn('select-plan-container__table', {
          'has-recommended': hasRecommended,
        })}
      >
        <></>
        <div className={'select-plan-container__keywordCount'}>
          {t('Current active keywords: ')}
          <FormatNumber>{user.organization?.numberOfKeywords}</FormatNumber>
        </div>
        <div className="select-plan-container__table__header">
          <span className="select-plan-container__table__header__cell">{t('Keywords')}</span>
          <span className="select-plan-container__table__header__cell">{t('Pricing')}</span>
          {hasRecommended && <span className="select-plan-container__table__header__cell" />}
          <span className="select-plan-container__table__header__cell" />
        </div>
        <div className="select-plan-container__table__body">{planOptions.map(renderPlanRow)}</div>
      </div>
    );
  };

  const renderPlans = () => {
    if (loading || error) {
      return <PlansSkeleton />;
    }
    return renderPlan();
  };

  return (
    <>
      <Breadcrumbs />
      <div className={cn('select-plan-container', reusableStyles.paperContainer)}>
        <ErrorMessage error={errorMessage} />
        <Grid gutter="xl">
          <Grid.Col span={{ xs: 4 }}>
            <h4>{t('Upgrade Plan')}</h4>
            <PlanFeatures />
          </Grid.Col>
          <Grid.Col span={{ xs: 8 }}>
            {renderBillingCycleToggle()}
            <FeatureSegment />
            {renderPlans()}
            <br />
            <br />
            <InfoSegment />
          </Grid.Col>
        </Grid>
      </div>
    </>
  );
};

export default withOnlyOrgPermission(withRouter(SelectPlanPage));
