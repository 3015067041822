import ValueIndicator from 'Components/ValueIndicator/value-indicator';
import Cell from './HelperComponents/AccuCell';

type Props = {
  value: {
    data: {
      aiTrafficValue: number | null;
      aiTrafficValueCompare: number | null;
      updatingKeyword: boolean;
    };
    loadingData: boolean;
  };
};

const AiTrafficValueChangeCell = (props: Props) => {
  const {
    value: { data },
  } = props;
  const { aiTrafficValue, aiTrafficValueCompare } = data;

  return (
    <Cell className="u-flex u-items-center" rightAligned={true}>
      <ValueIndicator
        negate={false}
        beforeValue={aiTrafficValueCompare}
        afterValue={aiTrafficValue}
      />
    </Cell>
  );
};

export default AiTrafficValueChangeCell;
