import { useField } from 'react-final-form';
import { Field } from 'Components/Fields';
import { getCountryCodeFromTLD } from 'Utilities/getCountryCodeFromTld';
import { t } from 'Utilities/i18n';
import Validator from 'Utilities/validation';
import { CountryOption, FIELD_NAMES } from '..';

type Props = {
  countryOptions: CountryOption[];
  isSocialSignup?: boolean;
};

const CompanyInfo = ({ countryOptions, isSocialSignup }: Props) => {
  const { input: organizationCountry } = useField(FIELD_NAMES.ORG_COUNTRY);
  const { input: organizationURL } = useField(FIELD_NAMES.ORG_URL);
  // use debounced value to prevent unnecessary re-render
  const handleOnURLBlur = () => {
    if (!organizationCountry?.value) {
      const cc = getCountryCodeFromTLD(countryOptions, organizationURL.value);
      organizationCountry.onChange(cc);
    }
  };

  const validateIsNotSuspectDomain = (value) => {
    if (
      value?.includes('example.com') ||
      value?.includes('accuranker.com') ||
      value?.includes('google.com') ||
      value?.includes('facebook.com')
    ) {
      return t('Are you sure this is your domain?');
    }
  };

  return (
    <>
      <Field.TextInput
        positionErrorBelow
        label={t('Company domain')}
        labelClassname="required"
        name={FIELD_NAMES.ORG_URL}
        id={FIELD_NAMES.ORG_URL}
        elementType="input"
        type="text"
        placeholder={t('Enter your company domain (e.g. example.com)')}
        autoComplete="url"
        required
        hideRequiredStar
        validate={[Validator.isUrl, validateIsNotSuspectDomain]}
        onBlur={handleOnURLBlur}
      />
      <Field.CountrySelect
        label={t('Country')}
        name={FIELD_NAMES.ORG_COUNTRY}
        id={FIELD_NAMES.ORG_COUNTRY}
        type="text"
        placeholder={t('Where would you like to track your keywords?')}
        disableLabel
        countries={countryOptions}
        autoComplete="off"
        required
        hideRequiredStar
        validate={Validator.required}
      />
      {isSocialSignup || (
        <Field.Password
          positionErrorBelow
          label={t('Password')}
          name={FIELD_NAMES.PASSWORD}
          id={FIELD_NAMES.PASSWORD}
          placeholder={t('Enter your password')}
          autoComplete="off"
          required
          hideRequiredStar
          popoverProps={{ position: 'top' }}
          validate={[
            Validator.required,
            Validator.passwordNumber,
            Validator.passwordLetter,
            Validator.passwordMinLen,
            Validator.passwordMaxLen,
            Validator.passwordPwned,
          ]}
        />
      )}
    </>
  );
};

export default CompanyInfo;
