import { useMemo } from 'react';
import { IconFilterSearch, IconSitemap } from '@tabler/icons-react';
import { AccFastIcon } from 'Components/AccFastIcon';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import { ColumnType, TableOrder } from 'Components/DataTable';
import ActionsCell from 'Components/Table/TableRow/ActionsCell';
import AccText from 'Components/Text/AccText';
import { TableClientNode } from 'Ghql';
import useUserPermission from 'Hooks/useUserPermission';
import { SORTING_TYPE } from 'Pages/Keywords/Table/hooks/keyword/constants';
import { t } from 'Utilities/i18n';
import styleVariables from 'css/base/variables.module.scss';
import IconEdit from 'icons/IconEdit.svg';
import { IconTrash } from 'icons/tag-cloud';
import { GroupColumnIDs } from './types';
import { useGroupHandlers } from './useGroupHandlers';
import styles from './group-table.module.scss';

type ReturnType = {
  // eslint-disable-next-line @typescript-eslint/ban-types
  columns: ColumnType<TableClientNode, {}>[];
};

export const useGroupsTableConfig = (updateTable: () => void): ReturnType => {
  const { makeDomainsLink, makeOverviewLink, handleEditGroup, handleDelete } = useGroupHandlers({
    updateTable,
  });
  const { isAdmin, isSuperuser } = useUserPermission();

  // eslint-disable-next-line @typescript-eslint/ban-types
  const columns: ColumnType<TableClientNode, {}>[] = useMemo(() => {
    const cols: ColumnType<TableClientNode, {}>[] = [
      {
        id: GroupColumnIDs.GROUP_DISPLAY_NAME,
        title: t('Group Name'),
        width: 325,
        fixed: 'left',
        flex: true,
        cellRenderer: ({ record }) => (
          <div className={styles.groupCell}>
            <IconSitemap size={17} />
            <AccTooltip tooltip={t('Add group as filter and go to domains')}>
              <AccText
                href={makeDomainsLink(record?.id?.toString() || '')}
                variant="link"
                size="md"
              >
                {record.name} <IconFilterSearch size={14} className={styles.filterIcon} />
              </AccText>
            </AccTooltip>
          </div>
        ),
        onHeaderCell: () => ({
          ordering: {
            defaultOrder: TableOrder.ASC,
            orderBy: GroupColumnIDs.GROUP_DISPLAY_NAME,
            sortingKey: SORTING_TYPE.alphabetical,
          },
        }),
      },
      {
        id: GroupColumnIDs.NUMBER_OF_DOMAINS,
        title: t('Number of Domains'),
        width: 200,
        cellRenderer: ({ record }) => (
          <AccText ta="right" size="xl">
            {record.numberOfDomains}
          </AccText>
        ),
        onHeaderCell: () => ({
          ordering: {
            defaultOrder: TableOrder.ASC,
            orderBy: GroupColumnIDs.NUMBER_OF_DOMAINS,
            sortingKey: SORTING_TYPE.numerical,
          },
        }),
      },
      {
        id: GroupColumnIDs.NUMBER_OF_KEYWORDS,
        title: t('Number of Keywords'),
        width: 200,
        cellRenderer: ({ record }) => {
          const link = makeOverviewLink(record);
          return (
            <AccText
              ta="right"
              variant={link ? 'link' : 'text'}
              size="xl"
              target={link ? '_self' : undefined}
              href={link}
            >
              {record.numberOfKeywords}
            </AccText>
          );
        },
        onHeaderCell: () => ({
          ordering: {
            defaultOrder: TableOrder.ASC,
            orderBy: GroupColumnIDs.NUMBER_OF_KEYWORDS,
            sortingKey: SORTING_TYPE.numerical,
          },
        }),
      },
      {
        id: GroupColumnIDs.DATE_ADDED,
        title: t('Created At'),
        width: 175,
        cellRenderer: ({ record }) => <AccText size="md">{record.createdAt}</AccText>,
        onHeaderCell: () => ({
          ordering: {
            defaultOrder: TableOrder.ASC,
            orderBy: GroupColumnIDs.DATE_ADDED,
            sortingKey: SORTING_TYPE.recurency,
          },
        }),
      },
    ];
    if (isAdmin || isSuperuser) {
      cols.push({
        id: GroupColumnIDs.ACTIONS,
        title: t('Actions'),
        width: 76,
        fixed: 'right',
        cellRenderer: ({ record }) => {
          const { snorlax4: iconColor } = styleVariables;
          return (
            <ActionsCell
              shouldUpdateIndicator={false}
              actions={[
                {
                  onSelect: () => handleEditGroup(record),
                  label: t('Edit Group'),
                  fastIcon: <AccFastIcon src={IconEdit} size={20} color={iconColor} />,
                },
                {
                  onSelect: () => handleDelete(record),
                  label: t('Delete Group'),
                  fastIcon: <AccFastIcon src={IconTrash} size={20} color={iconColor} />,
                },
              ]}
            />
          );
        },
      });
    }
    return cols;
  }, [handleDelete, handleEditGroup, makeDomainsLink, makeOverviewLink]);

  return { columns };
};
