import { DataTable, TableOrder } from 'Components/DataTable';
import { TableIDs } from 'Types/Table';
import { GroupColumnIDs } from './support/types';
import { useFetchGroupsData } from './support/useFetchGroupsData';
import { useGroupsTableConfig } from './support/useGroupsTableConfig';

const GROUPS_TABLE = TableIDs.GROUPS;

const GroupsTable = ({ dataKey, updateTable }: { dataKey: number; updateTable: () => void }) => {
  const fetchGroups = useFetchGroupsData();
  const { columns } = useGroupsTableConfig(updateTable);

  const defaultOrdering = {
    order: TableOrder.ASC,
    orderBy: GroupColumnIDs.GROUP_DISPLAY_NAME,
  };

  return (
    <DataTable
      dataKey={dataKey?.toString()}
      fetchData={fetchGroups}
      columns={columns}
      tableId={GROUPS_TABLE}
      defaultOrdering={defaultOrdering}
      pagination={true}
      pageSize={100}
    />
  );
};

export default GroupsTable;
