/* eslint-disable no-case-declarations */
import { ModalTypes } from 'Components/Modal/Content';
import { HIDE_MODAL, SHOW_MODAL } from '../Actions/ModalAction';

export type ModalPayloadConfig = {
  modalType: ModalTypes;
  modalTheme?: 'dark' | 'light';
  modalProps?: { [key: string]: any };
  nested?: any;
};

interface ModalAction {
  type: 'show_modal' | 'hide_modal';
  payload?: ModalPayloadConfig;
}

type ModalsState = {
  list: ModalPayloadConfig[];
};
const initialState: ModalsState = {
  list: [],
};
export default function (state: ModalsState = initialState, action: ModalAction) {
  switch (action.type) {
    case SHOW_MODAL:
      const { nested, ...payload } = action.payload || {};
      return { ...state, list: nested ? state.list.concat(action.payload || []) : [payload] };

    case HIDE_MODAL:
      const list = [...state.list];
      list.pop();
      return { ...state, list };

    default:
      return state;
  }
}
