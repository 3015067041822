import { Flex, FlexProps } from '@mantine/core';
import styles from './actionbar-container.module.scss';

type Props = {
  children: React.ReactNode;
} & FlexProps;
const ActionbarContainer = (props: Props) => {
  const { children, ...rest } = props;

  return (
    <Flex mt="lg" mb="lg" className={styles.container} {...rest} data-testid="actionsMenuContainer">
      {children}
    </Flex>
  );
};

export default ActionbarContainer;
