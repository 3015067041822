import ValueIndicator from 'Components/ValueIndicator/value-indicator';
import Cell from './HelperComponents/AccuCell';

type Props = {
  value: {
    data: {
      searchVolumeValue: number;
      organicTraffic: number;
      organicTrafficCompare: number;
      validForSearchVolume: boolean;
      updatingKeyword: boolean;
    };
    loadingData: boolean;
  };
};

const TrafficChangeCell = (props: Props) => {
  const {
    value: { data },
  } = props;
  const { organicTraffic, validForSearchVolume } = data;
  let { organicTrafficCompare } = data;

  if (!validForSearchVolume) {
    return null;
  }

  if (organicTraffic === null) {
    return null;
  }

  if (organicTrafficCompare === null) {
    organicTrafficCompare = 0;
  }

  return (
    <Cell className={'accu-table-cell__share-of-voice-change'} rightAligned={true}>
      <ValueIndicator
        beforeValue={organicTrafficCompare}
        afterValue={organicTraffic}
        style={'decimal'}
      />
    </Cell>
  );
};

export default TrafficChangeCell;
