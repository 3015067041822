import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { useModal } from 'Hooks/base/useModal';
import ActionbarContainer from 'Pages/Layout/ActionsMenu/components/ActionbarContainer';
import { selectDomainId } from 'Selectors/DomainSelector';
import { TableIDs } from 'Types/Table';
import KeywordActionMenu from './Actions/KeywordActionMenu';
import KeywordsTable from './KeywordsTable';
import './keywords-table.scss';

const columnsSettingsName = 'defaultKeywordsColumns';

const KeywordsPage = (): JSX.Element => {
  const domainId = useSelector(selectDomainId);
  const { state: locationState } = useLocation();
  const { showModal } = useModal();
  // show Add keywords modal when location state addKeywords is true.
  // i.e. set on DomainsTable on Add Keywords button click
  useEffect(() => {
    if (locationState?.addKeywordsModal) {
      showModal({
        modalType: 'AddKeywords',
        modalTheme: 'light',
        modalProps: {
          domainId,
        },
      });
    }
  }, [locationState?.addKeywordsModal, domainId, showModal]);

  return (
    <>
      <ActionbarContainer>
        <KeywordActionMenu
          domainId={domainId}
          tableName={TableIDs.KEYWORDS}
          columnsSettingsName={columnsSettingsName}
        />
      </ActionbarContainer>
      <div className="keywords-table">
        <KeywordsTable />
      </div>
    </>
  );
};

export default React.memo(KeywordsPage);
