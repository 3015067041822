import { ROOT_ID } from '../constants';
import { getIsTreeHasChildren } from '../helpers';
import { useSelectedNodes } from '../state';

export const useHandleGroupCanDrop = () => {
  const { getSelectedNodes } = useSelectedNodes();
  return (tree, options) => {
    const selectedNodeIds = getSelectedNodes().map((e) => e.id);
    const items = tree.filter((e) => selectedNodeIds.includes(e.id));

    if (items.some((e) => !e.isFolder) && options.dropTargetId === ROOT_ID) {
      // https://accuranker.myjetbrains.com/youtrack/issue/ARR-3123/Make-it-impossible-to-drag-tags-to-root-level
      return false;
    }

    return items?.every((item) => !getIsTreeHasChildren(item, options.dropTargetId));
  };
};
