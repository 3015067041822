import { useState } from 'react';
import AccessControl from 'Components/AccessControl';
import { getDisabledDemoText } from 'Constants/messages';
import { useFilters } from 'Hooks';
import { useModal } from 'Hooks/base/useModal';
import { useDomainId, useQueryDomainInfo } from 'Hooks/data/domain/useQueryDomainInfo';
import * as Actions from 'Pages/Layout/ActionsMenu/Actions';
import ActionbarContainer from 'Pages/Layout/ActionsMenu/components/ActionbarContainer';
import NotesTable from 'Pages/NotesList/NotesTable';
import { t } from 'Utilities/i18n';
import styles from './keywords-notes.module.scss';

const NotesList = () => {
  const [dataKey, setDataKey] = useState(0);
  const domainId = useDomainId();
  const { showModal } = useModal();
  const filters = useFilters();
  const resetTable = () => setDataKey((v) => v + 1);

  const { isDemoDomain } = useQueryDomainInfo();

  const handleAddAction = () => {
    showModal({
      modalType: 'AddNote',
      modalTheme: 'light',
      modalProps: {
        keywords: [],
        domainId,
        onClose: resetTable,
        enableAddingKeywords: true,
        filters,
      },
    });
  };

  return (
    <>
      <ActionbarContainer>
        <AccessControl>
          <Actions.AddAction
            disabled={isDemoDomain}
            tooltip={isDemoDomain ? getDisabledDemoText() : undefined}
            key="add-note-action"
            label={t('Add note')}
            onClick={handleAddAction}
          />
          <Actions.UpgradeAction key="upgrade-plan-action" alignRight={true} />
        </AccessControl>
      </ActionbarContainer>
      <div className={styles.removeMinHeight}>
        <NotesTable dataKey={dataKey} resetTable={resetTable} />
      </div>
    </>
  );
};

export default NotesList;
