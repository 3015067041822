import React, { memo } from 'react';
import { Flex, useMantineTheme } from '@mantine/core';
import isNil from 'lodash/isNil';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import { TABLE_ICON_SIZE } from 'Components/AccuTable/constants';
import FormatNumber from 'Components/FormatNumber/new';
import Icon from 'Components/Icon/new';
import { FolderCellProps } from 'Pages/Keywords/Groupings/ViewMode/utils/useFoldersTableConfig';
import { propsIsEqualComparison } from 'Utilities/compare';
import { t } from 'Utilities/i18n';
import IconMobile from 'icons/mobile.svg?inline';
import IconDesktop from 'icons/monitor.svg?inline';

// eslint-disable-next-line import/no-unused-modules
export const DeviceCell = memo((props: FolderCellProps) => {
  const theme = useMantineTheme();
  const gray = theme.colors.gray[theme.primaryShade as number];
  return (
    <Flex gap={4} justify="end">
      <Icon width={TABLE_ICON_SIZE} height={TABLE_ICON_SIZE} color={gray}>
        <AccTooltip tooltip={t('Mobile')}>
          <IconMobile />
        </AccTooltip>
      </Icon>
      <FormatNumber value={props.rootNode.mobile_percentage} style="percent" />
      <div />
      <Icon width={TABLE_ICON_SIZE} height={TABLE_ICON_SIZE} color={gray}>
        <AccTooltip tooltip={t('Desktop')}>
          <IconDesktop />
        </AccTooltip>
      </Icon>
      <FormatNumber
        value={
          !isNil(props.rootNode.mobile_percentage)
            ? 1 - props.rootNode.mobile_percentage
            : undefined
        }
        style="percent"
      />
    </Flex>
  );
}, propsIsEqualComparison);
DeviceCell.displayName = 'DeviceCell';
