import { useCallback } from 'react';
import { useStore } from 'react-redux';
import { useApolloClient } from '@apollo/client';
import { TableFetchDataCallBack } from 'Components/DataTable';
import {
  ChartNotesDocument,
  NotesTableDomainDocument,
  NotesTableDomainQuery,
  NotesTableDomainQueryVariables,
} from 'Ghql';
import { useDomainId } from 'Hooks/data/domain/useQueryDomainInfo';

export const useFetchNotes = (): TableFetchDataCallBack => {
  const client = useApolloClient();
  const store = useStore();
  const domainId = useDomainId();

  return useCallback(async () => {
    const variables: NotesTableDomainQueryVariables = {
      id: domainId!,
    };
    // refetch ChartNotes every time the FetchNotes will be triggered in NoteList page.
    client.refetchQueries({
      include: [ChartNotesDocument],
    });
    const e = await client.query<NotesTableDomainQuery, NotesTableDomainQueryVariables>({
      query: NotesTableDomainDocument,
      variables,
      fetchPolicy: 'network-only',
    });
    const data = e.data?.domain?.notes ?? [];
    return {
      data,
      length: data?.length ?? 0,
    };
  }, [client, store]);
};
