import { MouseEvent } from 'react';
import { useSelector } from 'react-redux';
import { IconEdit, IconTrash } from '@tabler/icons-react';
import cn from 'classnames';
import AccActionIcon from 'Components/AccActionIcon/AccActionIcon';
import generateCreateUserFilterInput from 'Components/Filters/generateCreateUserFilterInput';
import Icon from 'Components/Icon/new';
import { useDeleteUserFilterMutation, useUpdateUserFilterMutation } from 'Ghql';
import { useModal } from 'Hooks/base/useModal';
import toast from 'Hooks/useToast';
import SpecificFilterSelector from 'Selectors/SpecificFilterSelector';
import type { FilterGroup } from 'Types/Filter';
import { FilterAttribute } from 'Types/Filter';
import { DOMAINS_FILTER_SET, KEYWORDS_FILTER_SET, isRequiredFilter } from 'Types/FilterSet';
import type { FilterSet } from 'Types/FilterSet';
import { StoreType } from 'Types/Store';
import { t } from 'Utilities/i18n/index';
import StarIcon from 'icons/star.svg?inline';
import styles from '../savedFilterGroup.module.scss';

const domainsFilterSelector = SpecificFilterSelector(FilterAttribute.DOMAINS);

const mapStateToProps = (state, ownProps: Props) => {
  let canBeDefault = false;
  let isDefault = false;
  let domainId: any = null;
  // Quite tricky logic to determine is current filter group is default
  // or can be set to be default
  if (state.filter.filterSet === DOMAINS_FILTER_SET) {
    canBeDefault = true;
    isDefault = !!ownProps.filterGroup.defaultForDomains;
  } else if (state.filter.filterSet === KEYWORDS_FILTER_SET) {
    const domainsFilter = domainsFilterSelector(state);

    if (domainsFilter?.value?.length === 1) {
      canBeDefault = true;
      domainId = domainsFilter.value[0];
      isDefault = !!(
        ownProps.filterGroup.defaultForKeywords &&
        // TODO FixTSignore
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        ownProps.filterGroup.defaultForKeywords.indexOf(domainId) !== -1
      );
    }
  }

  return {
    isDefault,
    canBeDefault,
    domainId,
  };
};

type Props = {
  filterGroup: FilterGroup;
  filterSet: FilterSet;
  onSelect: (...args: Array<any>) => any;
  onEdit: (...args: Array<any>) => any;
  onDelete: (...args: Array<any>) => any;
  onToggleDefault: (...args: Array<any>) => any;
  index: number;
  closeDropdown: (...args: Array<any>) => any;
  className?: string;
};

const SavedFilterGroup = (props: Props) => {
  const { showModal } = useModal();
  const { isDefault, canBeDefault, domainId } = useSelector((state: StoreType) =>
    mapStateToProps(state, props),
  );
  const { onSelect, onDelete, onToggleDefault, closeDropdown, filterSet, onEdit, index } = props;

  //className will be injected by from Mantine Menu.Item
  const { filterGroup, className, ...spreadProps } = props;

  const [updateUserFilter] = useUpdateUserFilterMutation();
  const [deleteUserFilter] = useDeleteUserFilterMutation();
  const handleSelect = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();
    onSelect(filterGroup);
  };
  const handleDelete = () => {
    const { id } = filterGroup;
    deleteUserFilter({
      variables: {
        input: {
          id,
        },
      },
    }).then(
      (res) => {
        const { errors } = res?.data?.deleteFilter || {};
        if (errors && errors.length) {
          toast.error(t('Unable to delete segment'));
          return;
        }

        toast.success(t('Segment deleted'));
        onDelete(filterGroup.id);
      },
      () => {
        toast.error(t('Unable to delete segment'));
      },
    );
  };

  const showModalOnDelete = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();
    closeDropdown();
    showModal({
      modalType: 'Confirmation',
      modalProps: {
        title: t('Delete Segment?'),
        lockDuration: 0,
        description: t('The segment will be permanently deleted.'),
        cancelLabel: t('Cancel'),
        confirmLabel: t('Delete segment'),
        action: handleDelete,
      },
    });
  };

  const handleEdit = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();
    closeDropdown();
    showModal({
      modalType: 'EditFilterGroup',
      modalProps: {
        filterGroup,
        filterSet,
        onEdit,
      },
    });
  };
  const toggleDefault = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();
    const availableFilters = filterGroup.filters.filter(
      (filter) => !isRequiredFilter(filter.attribute, filterSet),
    );
    // Quite tricky logic to toggle default depending on the current filter set and domain
    let defaultForDomains = filterGroup.defaultForDomains || false;
    let defaultForKeywords: any = filterGroup.defaultForKeywords || [];

    if (filterSet === DOMAINS_FILTER_SET) {
      defaultForDomains = !isDefault;
    } else if (filterSet === KEYWORDS_FILTER_SET && domainId) {
      defaultForKeywords = defaultForKeywords.filter(
        (defaultDomainId) => defaultDomainId !== domainId,
      );

      if (!isDefault) {
        defaultForKeywords.push(domainId);
      }
    }

    const updateUserFilterInput = generateCreateUserFilterInput(
      filterGroup.name,
      availableFilters,
      filterSet,
      {
        defaultForDomains,
        defaultForKeywords,
      },
    );
    updateUserFilter({
      variables: {
        input: {
          id: filterGroup.id,
          ...updateUserFilterInput,
        },
      },
    }).then(
      (res) => {
        const { errors } = res?.data?.updateFilter || {};
        if (errors && errors.length) {
          toast.error(t('Unable to set default segment'));
          return;
        }
        onToggleDefault(filterGroup.id, !isDefault);
      },
      () => {
        toast.error(t('Unable to set default segment'));
      },
    );
  };

  return (
    <button
      type="button"
      {...spreadProps}
      className={cn(className, styles.savedFilterGroup)}
      onClick={handleSelect}
    >
      <div className={styles.filterGroupLabel}>{filterGroup.name}</div>
      <div>
        {index !== 0 && (
          <AccActionIcon onClick={handleEdit} className={styles.iconButton} variant="subtle">
            <Icon tooltip={t('Edit segment')} nofill>
              <IconEdit size={18} />
            </Icon>
          </AccActionIcon>
        )}
        {index !== 0 && canBeDefault && (
          <AccActionIcon className={styles.iconButton} onClick={toggleDefault} variant="subtle">
            <Icon
              color={isDefault && '#f89537'}
              tooltip={isDefault ? t('Disable default') : t('Enable default')}
            >
              <StarIcon />
            </Icon>
          </AccActionIcon>
        )}
        {index !== 0 && (
          <AccActionIcon onClick={showModalOnDelete} variant="subtle" className={styles.iconButton}>
            <Icon tooltip={t('Delete segment')} nofill>
              <IconTrash size={18} />
            </Icon>
          </AccActionIcon>
        )}
      </div>
    </button>
  );
};

export default SavedFilterGroup;
