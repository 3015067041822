import { useCallback, useEffect, useMemo, useState } from 'react';

const recursiveSearch = (data: any, search: string, expandDataKey: string) => {
  if (search === '') return data;
  const searchResults = data?.filter((item) => {
    const isMatch = item[expandDataKey].toLowerCase().includes(search.toLowerCase());
    return (
      isMatch ||
      (item.children?.length && recursiveSearch(item.children, search, expandDataKey).length > 0)
    );
  });
  return searchResults?.map((item) => {
    const children = item.children?.length
      ? recursiveSearch(item.children, search, expandDataKey)
      : [];
    return { ...item, children };
  });
};

export const useClientSideSearch = <T>(
  rootNodes: T[],
  expandDataKey: string,
  initData: T[],
): {
  nestedData: T[];
  submitSearch: (query?: string) => void;
  activeSearch?: string;
} => {
  const [nestedData, setNestedData] = useState<T[]>(rootNodes);
  const [searchPhrase, setSearchPhrase] = useState<string | undefined>(undefined);

  const resultData = useMemo(() => {
    return searchPhrase ? recursiveSearch(nestedData, searchPhrase, expandDataKey) : nestedData;
  }, [nestedData, searchPhrase, expandDataKey]);

  useEffect(() => {
    setNestedData(rootNodes);
  }, [rootNodes]);

  const submitSearch = useCallback(
    (newSearchPhrase?: string) => {
      if (rootNodes) {
        setSearchPhrase(newSearchPhrase);
        setNestedData(newSearchPhrase ? rootNodes : initData);
      }
    },
    [rootNodes, initData],
  );

  return { nestedData: resultData, submitSearch, activeSearch: searchPhrase };
};
