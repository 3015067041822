import React, { useEffect, useState } from 'react';
import {
  CloseButton,
  Combobox,
  Flex,
  FloatingSide,
  InputBase,
  Loader,
  ScrollArea,
  SelectProps,
  useCombobox,
} from '@mantine/core';
import { defaultSelectCreatePrompt, filterData } from 'Components/AccSelect/support/helpers';
import { t } from 'Utilities/i18n';
import { notEmpty, toArray } from 'Utilities/underdash';
import styleVariables from 'css/base/variables.module.scss';
import { DelayedSpinner } from './components/DelayedSpinner';
import { MAX_SELECT_DROPDOWN_HEIGHT } from './support/constants';
import {
  SelectNotFoundConfig,
  useCustomItemComponent,
  useOptions,
  useSelectNotFound,
  useSetDefaultSelectValue,
} from './support/hooks';
import { SelectItem, SelectItemValueType, SelectStylesVariations } from './support/types';
import styles from './select.module.scss';

const groupOptionsByGroup = <Item extends SelectItem = SelectItem>(
  options: Item[],
): { [key: string]: Item[] } => {
  const groupedOptions = options
    .filter((option) => !!option.value)
    .reduce((result, option) => {
      const groupKey = option.group || '$default'; // Use '$default' if no group is specified
      (result[groupKey] = result[groupKey] || []).push(option);
      return result;
    }, {}) as { [key: string]: Item[] };

  // Sort groups alphabetically, placing '$default' last
  const sortedGroups = Object.entries(groupedOptions)
    .filter(notEmpty)
    .sort(([a], [b]) => {
      if (a === '$default') return 1; // '$default' should be last
      if (b === '$default') return -1;
      return 0;
    })
    .reduce((acc, [key, value]) => {
      acc[key] = value;
      return acc;
    }, {} as { [key: string]: Item[] });

  return sortedGroups;
};

export const RightSectionElement = <T extends SelectItemValueType = string>({
  clearable,
  value,
  onChange,
  rightSectionSlot,
  isLoading,
}: {
  clearable: boolean | undefined;
  value: T | null | undefined;
  onChange: (value: T | null) => void;
  rightSectionSlot?: JSX.Element;
  isLoading: boolean | undefined;
}) => {
  if (isLoading) {
    return <Loader size={15} />;
  }
  if (rightSectionSlot) {
    return <>{rightSectionSlot}</>;
  }
  if (clearable && value) {
    return (
      <CloseButton
        size="sm"
        onMouseDown={(event) => {
          //make sure the bubbling phase is stopped to not conflict with the input close dropdown event
          event.preventDefault();
          event.stopPropagation();
        }}
        onClick={() => {
          onChange(null);
        }}
        aria-label={t('Clear value')}
        classNames={{ root: styles.closeButtonRoot }}
      />
    );
  }
  return <Combobox.Chevron />;
};

export const ComboboxOptions = <Item extends SelectItem = SelectItem>({
  value,
  options,
  optionComponent,
}: {
  value: Item['value'] | null;
  options: Item[];
  optionComponent?: (props: Item) => JSX.Element;
}) => {
  const groupedOptions = groupOptionsByGroup<Item>(options);

  return (
    <>
      {Object.entries(groupedOptions).map(([groupName, groupItems]) => (
        <Combobox.Group
          classNames={{ group: styles.comboboxGroup, groupLabel: styles.groupLabel }}
          //add empty string otherwise the devider will not be rendered
          label={groupName === '$default' ? ' ' : groupName}
          key={groupName}
          data-hidden-label={groupName === '$default' || null}
        >
          {groupItems.map((option, index) => (
            <Combobox.Option
              //Combobox.Option expects value to be string
              {...option}
              value={option.value as string}
              key={typeof option.value !== 'boolean' ? option.value : index}
              selected={!!value && option.value === value}
              active={option.active}
              disabled={option.disabled}
            >
              {optionComponent ? optionComponent(option) : <span>{option.label}</span>}
            </Combobox.Option>
          ))}
        </Combobox.Group>
      ))}
    </>
  );
};

/** Infer the SelectItemValueType for `value` from the type of `options` */
export type InferSelectItemValueType<Item extends SelectItem> = Item['value'];

interface BaseSelectProps<Item extends SelectItem = SelectItem>
  extends Omit<
    SelectProps,
    | 'data'
    | 'filter'
    | 'size'
    | 'withinPortal'
    | 'onSearchChange'
    | 'radius'
    | 'onChange'
    | 'onSubmit'
    | 'itemComponent'
    | 'rightSection'
    | 'nothingFound'
    | 'value'
    | 'defaultValue'
  > {
  name?: string;
  value?: InferSelectItemValueType<Item> | null;
  onChange: (value: InferSelectItemValueType<Item> | null, option: Item) => void;
  customOnChange?: (value: InferSelectItemValueType<Item> | null, option: Item) => void;
  options?: Item[];
  loadOptions?: (search?: string) => Promise<Item[]>;
  /** Set first option if no value is selected, make input non-clearable */
  useFirstOptionAsDefault?: boolean;
  disabled?: boolean;
  showError?: boolean;
  /**Determines whether the select should be searchable, true by default*/
  searchable?: boolean;
  clearable?: boolean;
  /**
   * Keys of option where which we should filter based on search query.
   * @default ['value']
   * @example
   * ```tsx
   * <AccSelect
   *    options=[{value: 1, label: 'Alphabet', domain: 'google.com'}]
   *    filterBy={["label", "domain"]}
   * />
   * ```
   * if user type: "google", we should see option 1.
   */
  placeholder?: string;
  clearSearchOnChange?: boolean;
  /** Hide the first group separator in the dropdown */
  groupHidden?: boolean;
  autoFocus?: boolean;
  disableLabel?: boolean;
  creatable?: boolean;
  error?: string | null | boolean;
  isLoading?: boolean;
  searchPromptText?: string;
  /**
   * Option's Label allowed to be only string (since it used for search)
   * To have possibility display react element - use `displayLabel`
   * @example
   * ```tsx
   * const options = [{label: 'hello', value: 'hello', displayLabel: <div><img src="hello.png"/>example</div>}]}'}]
   * <AccSelect options={options} ...
   * ```
   */
  displayLabel?: boolean;
  dropdownStyle?: React.CSSProperties;
  rightSectionComponent?: (p: { search?: string; valueOption?: Item }) => JSX.Element;
  /** min characters before start fetching options */
  minQueryLength?: number;
  /** Alternative item component used in dropdown */
  itemComponent?: (props: Item) => JSX.Element;
  /** Lets you override the filter function to determine how the search query is matched against the options */
  filter?(value: string, item: Item): boolean;
  onSubmit?(value: string): void;
  withPortal?: boolean;
  onSearchChange?: (string: string) => void;
  selectRef?: React.RefObject<HTMLInputElement>;
  clearOptionsOnBlur?: boolean;
  getCreateLabel?: (search: string) => string;
  dropdownHeight?: number;
  dropdownPosition?: Extract<FloatingSide, 'top' | 'bottom'>;
  /** Limit amount of items displayed at a time for searchable select */
  limit?: number;
  /** Should selectable options in the dropdown be filtered when search value exactly matches selected item (when an item is selected) */
  filterDataOnExactSearchMatch?: boolean;
  /** Don't try to auto-complete value based on options. E.g. "Change Preferred URL" modal  */
  skipAutoCompleteOnChange?: boolean;
  withinPortal?: boolean;
  dropdownWidth?: number;
}

export type AccSelectProps<Item extends SelectItem = SelectItem> = BaseSelectProps<Item> &
  SelectStylesVariations &
  SelectNotFoundConfig;
/**
 * Custom Select component with support for async options loading and custom option rendering.
 * If you need multi select support, please use `AccMultiSelect` component.
 *
 * @documentation https://mantine.dev/core/select/
 * @example
 * ```tsx
 * const [value, setValue] = useState<string | null>(null);
 * return <AccSelect value={value} onChange={setValue} options={[{label: "Option 1", value: 1}, {label: "Option 2", value: 2},]} />;
 * ```
 */
const AccSelect = <Item extends SelectItem>({
  options: selectOptions,
  clearable = false,
  searchable = true,
  onSubmit,
  creatable = false,
  groupHidden = true,
  itemComponent,
  clearSearchOnChange,
  isLoading: passedIsLoading,
  searchPromptText,
  nothingFoundText,
  rightSectionComponent,
  getCreateLabel,
  bg,
  size = 'default',
  dropdownPosition = 'bottom',
  useFirstOptionAsDefault,
  loadOptions,
  minQueryLength,
  selectRef,
  filter,
  inputMaxWidth,
  borderRadius = 'sm',
  displayLabel,
  onSearchChange: externalOnSearchChange,
  customOnChange,
  clearOptionsOnBlur = true,
  dropdownHeight = MAX_SELECT_DROPDOWN_HEIGHT,
  value,
  onKeyDown,
  onChange: externalOnChange,
  disabled,
  style,
  placeholder,
  error,
  showError,
  onBlur,
  filterDataOnExactSearchMatch = false,
  skipAutoCompleteOnChange = false,
  limit = Infinity,
  withinPortal = false,
  dropdownWidth,
  ...props
}: AccSelectProps<Item>) => {
  /** Shorter syntax to refer to SelectItemValueType */
  type T = InferSelectItemValueType<Item>;

  const resultClearable = useFirstOptionAsDefault ? false : clearable;
  const [search, searchChange] = useState('');
  const [hasBeenSearched, setHasBeenSearched] = useState(false);

  const onSearchChange = (query: string) => {
    searchChange(query);
    externalOnSearchChange?.(query);
    setHasBeenSearched(true);
  };

  const { options, isLoading, clearFetchedOptions } = useOptions<Item>({
    options: selectOptions,
    search,
    loadOptions,
    searchable,
    minQueryLength,
    externalIsLoading: passedIsLoading,
    values: toArray(value)?.filter(notEmpty) || [],
  });

  const filteredItems = filterData<SelectItem<T>>({
    data: options,
    searchable,
    limit,
    searchValue: search,
    filter: filter as
      | ((value: string, item: SelectItem<InferSelectItemValueType<Item>>) => boolean)
      | undefined,

    value,
    filterDataOnExactSearchMatch,
  });

  const simpleOptions = JSON.stringify(
    options.map((item) => ({ label: item.label, value: item.value } as any)),
  );

  // Set initial search value from external value or option
  useEffect(() => {
    if (hasBeenSearched) {
      return;
    }
    if (!options || !value) return;
    const newSelectedValue = options?.find(
      (item) =>
        item.value === value || item.label?.toLowerCase() === value.toString().toLowerCase(),
    );

    const valueIsNew =
      newSelectedValue && newSelectedValue.value !== search && newSelectedValue.label !== search;

    if (newSelectedValue && valueIsNew) {
      onSearchChange(newSelectedValue.label || newSelectedValue.value.toString());
    }
  }, [value, creatable, simpleOptions, onSearchChange]);

  const combobox = useCombobox({
    onDropdownOpen: () => {
      combobox.selectActiveOption();
      combobox.focusTarget();
    },
  });

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    onBlur?.(event);
    clearOptionsOnBlur && clearFetchedOptions();
  };

  const onChange = (val: T | null) => {
    const isCreate = val === '$create';

    if (clearSearchOnChange || !val) {
      onSearchChange('');
    }
    const option: SelectItem<T> | undefined = isCreate
      ? { label: search, value: search }
      : options?.find((o) => o?.value === val);
    option && onSearchChange(option.label || option.value.toString());

    const optionValue = option?.value || val;

    optionValue && option && customOnChange?.(optionValue, option as Item);
    optionValue && option && externalOnChange(optionValue, option as Item);
    if (resultClearable && !val) {
      customOnChange?.(null, {} as Item);
      externalOnChange(null, {} as Item);
    }
  };

  // If options change, this sync the displayed value with the options.
  // This is useful when the options change and the original value was not in the options
  // For instance in the edit static tag modal for the folders select - or in general if options comes from the backend
  // But initial value is already known in the frontend
  useEffect(() => {
    if (value && !skipAutoCompleteOnChange) {
      onChange(value);
    }
  }, [simpleOptions]);

  const handleKeydown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    onKeyDown?.(event);
    onSubmit?.(event?.currentTarget?.value);
  };

  const customItemComponent = useCustomItemComponent<Item>(itemComponent, displayLabel);

  useSetDefaultSelectValue({
    options,
    value,
    useFirstOptionAsDefault,
    onChange,
    clearable,
  });

  const nothingFound = useSelectNotFound({
    minQueryLength,
    search,
    searchPromptText,
    isLoading,
    nothingFoundText,
  });

  const ComboboxOptionsList = () => {
    if (isLoading) {
      return (
        <Flex justify={'center'}>
          <DelayedSpinner />
        </Flex>
      );
    }

    //Check if searchValue is identical to one of the elements
    const exactOptionMatch = options.some(
      (item) =>
        item.label?.toLowerCase() === search?.toLowerCase() ||
        item.value?.toString().toLowerCase() === search?.toLowerCase(),
    );

    return (
      <>
        <ComboboxOptions<Item>
          value={value ?? null}
          optionComponent={customItemComponent}
          options={filteredItems as Item[]}
        />
        {creatable && !exactOptionMatch && search.trim().length > 0 && (
          <Combobox.Option value="$create">
            {getCreateLabel ? getCreateLabel(search || '') : defaultSelectCreatePrompt(search)}
          </Combobox.Option>
        )}
        {!filteredItems?.length && !creatable && <Combobox.Empty>{nothingFound}</Combobox.Empty>}
      </>
    );
  };

  return (
    <Combobox
      shadow={'md'}
      store={combobox}
      onOptionSubmit={(selectedValue) => {
        return onChange(selectedValue);
      }}
      width={dropdownWidth}
      withinPortal={withinPortal}
      classNames={{
        dropdown: styles.comboboxDropdown,
        option: styles.comboboxOption,
        options: styles.comboboxOptions,
        empty: styles.comboboxEmpty,
      }}
      data-background-gray={bg === 'gray' || undefined}
      data-background-snorlax={bg === 'snorlax' || undefined}
      position={dropdownPosition}
    >
      <Combobox.Target ref={selectRef}>
        <InputBase
          {...props}
          readOnly={!searchable}
          value={search}
          placeholder={placeholder}
          onBlur={handleBlur}
          pointer
          role="combobox"
          rightSectionPointerEvents={resultClearable && !isLoading ? 'auto' : 'none'}
          onChange={(event) => {
            !combobox.dropdownOpened && combobox.openDropdown();
            combobox.updateSelectedOptionIndex();
            onSearchChange(event.currentTarget.value);
          }}
          onClick={() => {
            !combobox.dropdownOpened && combobox.openDropdown();
            combobox.dropdownOpened && !searchable ? combobox.closeDropdown() : null;
          }}
          onKeyDown={handleKeydown}
          rightSection={
            <RightSectionElement<T>
              clearable={resultClearable}
              value={value}
              isLoading={isLoading}
              onChange={onChange}
              rightSectionSlot={
                rightSectionComponent &&
                rightSectionComponent({
                  search,
                  valueOption: (options as Item[])?.find((item) => item.value === value),
                })
              }
            />
          }
          wrapperProps={{
            'data-background-gray': bg === 'gray' || undefined,
            'data-background-snorlax': bg === 'snorlax' || undefined,
          }}
          error={showError && error}
          withErrorStyles={showError}
          radius={borderRadius === 'none' ? 0 : borderRadius}
          multiline={false}
          disabled={disabled}
          style={{
            ...(inputMaxWidth ? { maxWidth: `${inputMaxWidth}px` } : {}),
            ...style,
          }}
          size={size === 'default' ? styleVariables.inputFieldHeight : size}
          classNames={{
            section: styles.inputSection,
            input: styles.input,
            root: styles.inputRoot,
            error: styles.error,
          }}
        />
      </Combobox.Target>
      <Combobox.Dropdown
        classNames={{ dropdown: styles.comboboxDropdown }}
        data-group-hidden={groupHidden || null}
        onClick={() => combobox.closeDropdown()}
      >
        <Combobox.Options>
          <ScrollArea.Autosize
            mah={dropdownHeight}
            type="auto"
            scrollbars="y"
            classNames={{ viewport: styles.scrollAreaViewPort }}
          >
            <ComboboxOptionsList />
          </ScrollArea.Autosize>
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
};

export default AccSelect;
