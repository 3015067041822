import { Component } from 'react';
import { gql } from '@apollo/client';
import { graphql } from '@apollo/client/react/hoc';
import { Flex, Progress } from '@mantine/core';
import compose from 'lodash/flowRight';
import { t } from 'Utilities/i18n';
import underdash from 'Utilities/underdash';
import './account-usage.scss';

type Props = {
  accountUsage: Record<string, any>;
};

class AccountUsage extends Component<Props> {
  render() {
    if (underdash.graphqlLoading({ ...this.props }) || underdash.graphqlError({ ...this.props })) {
      return null;
    }

    const {
      accountUsage: {
        user: {
          organization: {
            numberOfKeywords,
            numberOfUsers,
            numberOfDomains,
            activePlan: { maxUsers, maxKeywords, maxDomains, maxNumbersOfWorkspaces },
          },
        },
        workspacesByOrganization: workspaces,
      },
    } = this.props;

    return (
      <div className="account-usage">
        <Flex justify="space-between">
          <small>{t('Keywords')}</small>
          <small>{t('%s of %s', numberOfKeywords, maxKeywords)}</small>
        </Flex>
        <Progress
          mb={6}
          size="xl"
          color="blue"
          value={(Math.min(numberOfKeywords, maxKeywords) / maxKeywords) * 100}
        />

        <Flex justify="space-between">
          <small>{t('Domains')}</small>
          <small>
            {t('%s of %s', numberOfDomains, maxDomains === -1 ? t('Unlimited') : maxDomains)}
          </small>
        </Flex>
        <Progress
          mb={6}
          size="xl"
          color="blue"
          value={(numberOfDomains / (maxDomains === -1 ? 9999 : maxDomains)) * 100}
        />

        <Flex justify="space-between">
          <small>{t('Users')}</small>
          <small>{t('%s of %s', numberOfUsers, maxUsers === -1 ? t('Unlimited') : maxUsers)}</small>
        </Flex>
        <Progress
          mb={6}
          size="xl"
          color="blue"
          value={(numberOfUsers / (maxUsers === -1 ? 9999 : maxUsers)) * 100}
        />

        <Flex justify="space-between">
          <small>{t('Max number of Workspaces')}</small>
          <small>
            {t(
              '%s of %s',
              workspaces?.length ?? 0,
              maxNumbersOfWorkspaces === -1 ? t('Unlimited') : maxNumbersOfWorkspaces,
            )}
          </small>
        </Flex>
        <Progress
          mb={0}
          size="xl"
          color="blue"
          value={
            ((workspaces?.length ?? 0) /
              (maxNumbersOfWorkspaces === -1 ? 9999 : maxNumbersOfWorkspaces)) *
            100
          }
        />
      </div>
    );
  }
}

const accountUsageQuery = gql`
  query accountUsage_user {
    user {
      id
      organization {
        id
        activePlan {
          id
          maxUsers
          maxKeywords
          maxDomains
          maxNumbersOfWorkspaces
        }
        numberOfKeywords
        numberOfDomains
        numberOfUsers
      }
    }
    workspacesByOrganization {
      id
    }
  }
`;
export default compose(
  graphql(accountUsageQuery, {
    name: 'accountUsage',
  }),
)(AccountUsage);
