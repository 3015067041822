const edgeCases = {
  gb: 'uk',
};
type CountryItem = {
  countryCode: string;
  [key: string]: any;
};
const createTLDCountryList = (
  countries: CountryItem[],
): {
  tld: string;
  countryCode: string;
}[] => {
  return countries
    .map((country) => {
      const cc = country.countryCode?.toLowerCase();
      return {
        tld: edgeCases[cc] || cc,
        countryCode: country.countryCode,
      };
    })
    .filter((item) => item.countryCode);
};

export const getCountryCodeFromTLD = (countries: CountryItem[], domainOrTld: string) => {
  const countryList = createTLDCountryList(countries);
  const tld = domainOrTld.split('.').pop()?.toLowerCase() || '';
  const country = countryList.find((c) => c?.tld === tld);
  return country?.countryCode;
};
