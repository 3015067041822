import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useApolloClient } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { FormApi } from 'final-form';
import { TableStoreType } from 'Components/DataTable';
import { linkToKeywordsDomain } from 'Components/Filters/LinkToDomain';
import { SoMePlatform } from 'Components/SoMeFormFields/SocialMediaIcons';
import {
  AddDomainForm_CountrylocalesQuery,
  CountByCountryNode,
  KeydisImportInfoDocument,
  useAddDomainForm_AddDomainMutation,
} from 'Ghql';
import { useFilters } from 'Hooks';
import { extractFormErrors } from 'Hooks/Graphql/helpers';
import { useModal } from 'Hooks/base/useModal';
import { useRemoveFilter, useSetOrOverwriteFilter } from 'Hooks/data/filter/setFilters';
import toast from 'Hooks/useToast';
import { getPlaceholderKeywords } from 'Pages/KeywordDiscovery/support/helpers';
import { DomainsFilter, FilterAttribute, FilterComparison, FilterValueType } from 'Types/Filter';
import { StoreUserType } from 'Types/Store';
import { EventName, useMixpanel } from 'Utilities/Analytics/mixpanel';
import { LocaleDataSearchEngine, transformLocalesData } from 'Utilities/Common/search-engines';
import { useHistory } from 'Utilities/Router/withRouter';
import { t } from 'Utilities/i18n';
import { notEmpty } from 'Utilities/underdash';
import Validator from 'Utilities/validation';
import { AddKeywordsMode } from '../../../AddKeywords';
import { KeydisImportConfig } from '../../../AddKeywords/support/types';
import { DOMAIN_MODE } from '../BuildDomainForm';

type Locale = {
  countrylocale: string;
  locations: string[];
  searchEngines: LocaleDataSearchEngine[];
  settings: {
    enableAutocorrect: boolean;
    ignoreFeaturedSnippet: boolean;
    ignoreLocalResults: boolean;
  };
};

type FormStateValues = {
  domain: string;
  displayName: string;
  defaultSearchLocales: Locale[];
  belongsToGroup: string;
  googleBusinessNameList: string[];
  includeSubdomains: boolean | undefined;
  exactMatch: boolean | undefined;
  shareOfVoicePercentage: boolean | undefined;
  paused?: boolean;
  [SoMePlatform.X]: string | undefined;
  [SoMePlatform.FACEBOOK]?: string;
  [SoMePlatform.TIKTOK]?: string;
  [SoMePlatform.INSTAGRAM]?: string;
  [SoMePlatform.LINKEDIN]?: string;
};

type SubmitDomainProps = {
  primarySearchEngine: string | undefined;
  tableStore: TableStoreType | null | undefined;
  selectedCountry?: CountByCountryNode | undefined;
};

/**
 * Submit- and changeLocale functions for `<AddDomainForm>`
*
* @example
* const { handleSubmit, handleChangeLocale } = useAddDomainForm({
  *  primarySearchEngine,
  mode,
  countrylocalesData,
  user,
  tableStore,
  * });
  */
// eslint-disable-next-line import/no-unused-modules
export const useSubmitAddDomainForm = ({
  primarySearchEngine,
  tableStore,
  selectedCountry,
}: SubmitDomainProps) => {
  const { showModal } = useModal();
  const client = useApolloClient();
  const filters = useFilters();
  const removeFilter = useRemoveFilter();
  const setFilter = useSetOrOverwriteFilter();
  const location = useLocation();
  const history = useHistory();
  const navigate = useNavigate();
  const trackEvent = useMixpanel();

  const [addDomain] = useAddDomainForm_AddDomainMutation({
    onCompleted: (data, clientOptions) => {
      const domainObj = data?.addDomain?.domain;
      const defaultSearchLocales: Locale[] =
        clientOptions?.variables?.addDomainInput?.defaultSearchsettings;

      const domainId = domainObj?.id;

      if (data?.addDomain?.errors && data?.addDomain?.errors.length > 0) {
        toast.error(data?.addDomain?.errors[0]?.messages[0]);
        trackEvent(EventName.AddDomainFailed, {
          Message: data?.addDomain?.errors[0]?.messages[0] ?? '',
        });
        return;
      }

      if (!domainId) {
        toast.error(
          t(
            'An error occurred while trying to add the domain, please try again or contact support.',
          ),
        );
        Sentry.captureException('No domain id found in add domain form');
        trackEvent(EventName.AddDomainFailed, { Message: 'No domainId' });
        return;
      }

      //Swap the fre text domain filter for a domain filter
      removeFilter(FilterAttribute.FREE_TEXT_DOMAIN);
      const domainsFilter: DomainsFilter = {
        attribute: FilterAttribute.DOMAINS,
        type: FilterValueType.LIST,
        comparison: FilterComparison.CONTAINS,
        value: [domainId],
      };
      setFilter(domainsFilter, 'no tracking');
      const updatedFilters = filters.filter(
        (x) => x.attribute !== FilterAttribute.FREE_TEXT_DOMAIN,
      );
      updatedFilters.push(domainsFilter);

      toast.success(t('Domain added'));
      trackEvent(EventName.AddDomainSuccess);

      const nKeywordsToAdd =
        (tableStore?.isAllSelected
          ? Math.min(
              tableStore?.totalDataLength,
              tableStore?.maxSelectCount ?? tableStore?.totalDataLength,
            )
          : tableStore?.selectedRows?.length) ?? 0;

      if (tableStore && nKeywordsToAdd) {
        navigate(location.pathname, { state: { modalHide: false }, replace: true });
      } else {
        //Redirect to domains if no keywords were preselected.
        history.push(linkToKeywordsDomain(domainId, true), {
          state: { modalHide: false },
        });
      }

      //Add additional props if keywords needs to be included
      const importKeywordsProps = nKeywordsToAdd
        ? {
            mode: AddKeywordsMode.IMPORT,
            getKeydisKeywords: () =>
              client.query({
                fetchPolicy: 'network-only',
                query: KeydisImportInfoDocument,
                variables: {
                  filters: updatedFilters,
                },
              }),
            keydisKeywordsCounter: nKeywordsToAdd,
            overwriteFilters: filters,
            locales: defaultSearchLocales,
            placeholderKeywords: tableStore ? getPlaceholderKeywords(tableStore) : undefined,
            selectedCountry,
            importConfig: {
              excludeKeywords: undefined,
              includeKeywords: undefined,
              nKeywordsToAdd,
              ordering: undefined, // Ordering shouldn't matter as we're importing everything matching filters
            } as KeydisImportConfig,
          }
        : { locales: defaultSearchLocales };

      showModal({
        modalType: 'AddKeywords',
        modalTheme: 'light',
        modalProps: {
          domainId,
          redirectOnSubmit: true,
          ...importKeywordsProps,
        },
      });
    },
    onError: (error) => {
      toast.error(error?.message);
      Validator.setResponseErrors(Validator.throwSubmissionError(error));
    },
  });

  const handleSubmit = useCallback(
    ({
      domain,
      displayName,
      defaultSearchLocales,
      belongsToGroup,
      googleBusinessNameList,
      includeSubdomains,
      exactMatch,
      shareOfVoicePercentage,
      x_handle,
      facebook_profile,
      tiktok_profile,
      instagram_profile,
      linkedin_profile,
    }: FormStateValues) => {
      const addDomainInput = {
        domain,
        displayName: displayName || '',
        client: belongsToGroup,
        googleBusinessNameList: googleBusinessNameList || [],
        twitterHandle: x_handle || '',
        facebookProfile: facebook_profile || '',
        tiktokProfile: tiktok_profile || '',
        instagramProfile: instagram_profile || '',
        linkedinProfile: linkedin_profile || '',
        includeSubdomains: includeSubdomains ?? true,
        exactMatch: exactMatch ?? false,
        shareOfVoicePercentage: shareOfVoicePercentage ?? false,
        defaultSearchsettings: defaultSearchLocales.map((locale) => ({
          countrylocale: locale.countrylocale,
          locations: locale.locations.filter((loc) => !!loc?.length),
          searchEngines: locale.searchEngines
            .filter(({ searchTypes }) => !!searchTypes.length)
            .map(({ id, searchTypes }) => ({
              searchEngine: id,
              searchTypes: searchTypes.map((searchType) => searchType.toString()),
            })),
          primary: locale.countrylocale === primarySearchEngine,
          ...locale.settings,
        })),
      };

      return addDomain({
        variables: {
          addDomainInput,
        },
      }).then((res) => {
        const { errors } = res?.data?.addDomain || {};

        if (errors && errors.length) {
          toast.error(errors[0]?.messages[0] || t('Something went wrong'));
          return extractFormErrors(res);
        }
      });
    },
    [addDomain, primarySearchEngine],
  );

  return handleSubmit;
};

type HandleChangeLocaleProps = {
  mode: DOMAIN_MODE;
  countrylocalesData: AddDomainForm_CountrylocalesQuery | undefined;
  user: StoreUserType;
};
export const useHandleChangeLocale = ({
  mode,
  countrylocalesData,
  user,
}: HandleChangeLocaleProps) => {
  const { showModal } = useModal();
  return (localeId: string, form: FormApi): void => {
    const locales = countrylocalesData?.countrylocales?.length
      ? transformLocalesData(
          countrylocalesData?.countrylocales?.filter(notEmpty),
          !!user?.organization?.activePlan?.featureBaidu,
          user?.organization?.activePlan?.featureYoutube ?? false,
          mode === DOMAIN_MODE.youtube,
        )
      : [];

    const locale = locales.find((localeObj) => localeObj.id === localeId);

    // you cannot add the same locale more than once
    if (locales) {
      const alreadyExists = !!form
        .getFieldState('defaultSearchLocales')
        ?.value?.find((searchLocale: Locale) => searchLocale.countrylocale === locale?.id);

      if (alreadyExists) {
        toast.error(t('You cannot add the same locale multiple times.'));
        return;
      }
    }

    showModal({
      modalType: 'AddSearchEngine',
      modalTheme: 'light',
      modalProps: {
        locale,
        youTubeDomain: mode === DOMAIN_MODE.youtube,
        initialValues: {
          searchEngines: locale?.searchEngines,
          settings: {
            ignoreLocalResults: false,
            ignoreFeaturedSnippet: false,
            enableAutocorrect: false,
          },
          locations: [''],
        },
        onSubmit: (value: Locale) => {
          form.mutators.push('defaultSearchLocales', value);
        },
      },
      nested: true,
    });
  };
};
