import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import CountryLocaleCell from 'Components/AccuTable/CellRenderer/countryLocale';
import RankCell from 'Components/AccuTable/CellRenderer/rank';
import RankChangeCell from 'Components/AccuTable/CellRenderer/rankChange';
import SearchTypeEngineCell from 'Components/AccuTable/CellRenderer/searchTypeEngine';
import { ColumnType, TableOrder, withRowContext } from 'Components/DataTable';
import { useLanguage } from 'Hooks/data/domain/useQueryDomainInfo';
import { useFetchTableData } from 'Hooks/table';
import { SORTING_TYPE } from 'Pages/Keywords/Table/hooks/keyword/constants';
import { IconContainer } from 'Pages/Keywords/Table/support/IconContainer/IconContainer';
import { FilterAttribute } from 'Types/Filter';
import { StoreType } from 'Types/Store';
import { t } from 'Utilities/i18n';
import DevicesIcon from 'icons/devices.svg?inline';
import {
  CompetitorsRanksQueryDocument,
  CompetitorsRanksQueryQuery,
  CompetitorsRanksQueryQueryVariables,
  KeywordRankWithCompetitorRankNode,
} from '../../../../../../Ghql';
import { CompetitorFavicon } from '../components/CompetitorFavicon';
import { createRankCell } from '../components/CompetitorRankCell';
import { createRankChangeCell } from '../components/CompetitorsRankChangeCell';
import { KeywordCell } from '../components/KeywordCell/KeywordCell';
import { SearchVolumeCell } from '../components/SearchVolumeCell';
import {
  COMPETITORS_RANKS_TABLE_ID,
  CompetitorRanksOrderConfig,
  CompetitorsRanksTableIDs,
  DEFAULT_COMPETITORS_RANKS_ORDERING,
} from './constants';
import { getCompetitorRanksExtraColumns } from './helpers';
import { CompetitorIndexInfo } from './types';

export const useCompetitorsRanksTableInfo = (competitors?: CompetitorIndexInfo[]) => {
  const metaData = useSelector((state: StoreType) => state.metaData);
  const language = useLanguage();
  const columns: ColumnType<any>[] = useMemo(() => {
    const resultColumns: ColumnType<any>[] = [
      {
        id: CompetitorsRanksTableIDs.KEYWORD,
        title: t('Keyword'),
        combineRow: CompetitorsRanksTableIDs.SEARCH_ENGINE,
        flex: true,
        width: 235,
        fixed: 'left',
        cellRenderer: KeywordCell,
        onHeaderCell: () => ({
          tooltip: t('Sort by keyword'),
          ordering: CompetitorRanksOrderConfig.KEYWORD,
          filter: {
            filterAttributes: [FilterAttribute.KEYWORD, FilterAttribute.TAGS],
            filterTooltip: t('Filter keyword…'),
            filterId: 'keyword-filter',
          },
        }),
      },
      {
        id: CompetitorsRanksTableIDs.SEARCH_ENGINE,
        title: (
          <IconContainer size="md">
            <DevicesIcon />
          </IconContainer>
        ),
        width: 75,
        fixed: 'left',
        cellRenderer: SearchTypeEngineCell as any,
        cellRendererParams: {
          searchTypes: metaData?.searchTypes,
          searchEngines: metaData?.searchEngines,
          cellClassName: 'vertical-alignment-top',
        },
        onHeaderCell: () => ({
          tooltip: t('Sort by search type'),
          ordering: CompetitorRanksOrderConfig.SEARCH_ENGINE,
          filter: {
            filterAttributes: [FilterAttribute.SEARCH_ENGINE, FilterAttribute.SEARCH_TYPE],
            filterTooltip: t('Filter search type…'),
          },
        }),
      },
      {
        id: CompetitorsRanksTableIDs.LOCATION,
        title: t('Location'),
        width: 160,
        cellRenderer: CountryLocaleCell as any,
        cellRendererParams: {
          countryLocales: metaData?.countrylocales,
        },
      },
      {
        id: CompetitorsRanksTableIDs.SEARCH_VOLUME,
        title: t('Searches'),
        width: 115,
        cellRenderer: SearchVolumeCell,
        onHeaderCell: () => ({
          tooltip: t('Sort by monthly searches'),
          ordering: CompetitorRanksOrderConfig.SEARCH_VOLUME,
          reverseDirection: true,
          filter: {
            filterAttributes: [FilterAttribute.SEARCH_VOLUME],
            filterTooltip: t('Filter searches…'),
          },
        }),
      },
      {
        id: CompetitorsRanksTableIDs.RANK,
        combineRow: CompetitorsRanksTableIDs.RANK_CHANGE,
        title: t('Rank'),
        width: language === 'da' ? 105 : 85,
        cellRenderer: withRowContext(RankCell as any) as any,
        onHeaderCell: () => ({
          tooltip: t('Sort by rank'),
          ordering: CompetitorRanksOrderConfig.RANK,
          reverseDirection: true,
          filter: {
            filterAttributes: [FilterAttribute.RANK, FilterAttribute.RANK_CHANGE],
            filterTooltip: t('Filter rank…'),
            hasMediumFilter: true,
            filterLabel: t('Rank column filters'),
          },
        }),
      },
      {
        id: CompetitorsRanksTableIDs.RANK_CHANGE,
        title: t('+/-'),
        width: 60,
        cellRenderer: RankChangeCell as any,
        cellRendererParams: {
          hasLoader: true,
          emptyLoader: true,
        },
        onHeaderCell: () => ({
          tooltip: t('Change in rank between the two compared dates'),
          ordering: CompetitorRanksOrderConfig.RANK_CHANGE,
          reverseDirection: true,
        }),
      },
    ];

    competitors?.map(({ name, index, domain }) => {
      const competitorNum = index?.toString();
      resultColumns.push(
        ...[
          {
            id: CompetitorsRanksTableIDs.COMPETITOR_RANK.concat(competitorNum),
            combineRow: CompetitorsRanksTableIDs.COMPETITOR_RANK_CHANGE.concat(competitorNum),
            title: <CompetitorFavicon domain={domain} />,
            width: 70,
            cellRenderer: createRankCell(`competitor${competitorNum}Rank`) as any,
            onHeaderCell: () => ({
              tooltip: name ?? domain,
              ordering: {
                defaultOrder: TableOrder.ASC,
                orderBy: `competitor_${competitorNum}_rank`,
                sortingKey: SORTING_TYPE.rank,
              },
              reverseDirection: true,
            }),
          },
          {
            id: CompetitorsRanksTableIDs.COMPETITOR_RANK_CHANGE.concat(competitorNum),
            title: t('+/-'),
            width: 60,
            cellRenderer: createRankChangeCell({
              rankKey: `competitor${competitorNum}Rank`,
              rankCompareKey: `competitor${competitorNum}RankCompare`,
            }) as any,
            onHeaderCell: () => ({
              ordering: {
                defaultOrder: TableOrder.ASC,
                orderBy: `competitor_${competitorNum}_rank_change`,
                sortingKey: SORTING_TYPE.numerical,
              },
              reverseDirection: true,
            }),
          },
        ],
      );
    });
    return resultColumns;
  }, [metaData, competitors]);

  return { columns };
};

export const useCompetitorsRanks = () => {
  const [competitors, setCompetitors] = useState<CompetitorIndexInfo[]>();
  const fetchData = useFetchTableData<
    CompetitorsRanksQueryQuery,
    CompetitorsRanksQueryQueryVariables,
    KeywordRankWithCompetitorRankNode
  >({
    query: CompetitorsRanksQueryDocument,
    defaultOrdering: DEFAULT_COMPETITORS_RANKS_ORDERING,
    tableId: COMPETITORS_RANKS_TABLE_ID,
    noTableSettings: true,
    onSuccess: (data) => {
      setCompetitors(
        getCompetitorRanksExtraColumns(
          data.competitorRankList?.keywords?.[0],
          data.competitorRankList?.competitors,
        ),
      );
    },
    extractData: (res) => {
      return {
        data: (res?.competitorRankList?.keywords ?? [])?.map((e) => ({
          ...e,
        })) as KeywordRankWithCompetitorRankNode[],
        length: res?.competitorRankList?.pagination?.numResults ?? 0,
      };
    },
    getVariables: ({ ordering, pagination, filters }) => {
      return { ordering, pagination, filters };
    },
  });

  return { fetchData, competitors };
};
