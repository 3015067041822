import { Component } from 'react';
import { gql } from '@apollo/client';
import { graphql } from '@apollo/client/react/hoc';
import Skeleton from 'Components/Skeleton';
import { t, tct } from 'Utilities/i18n';
import { graphqlOK } from 'Utilities/underdash';

type Props<T = any> = {
  data?: T;
  isOrgAdmin: boolean;
};

class PaymentMethod extends Component<Props> {
  renderSkeleton() {
    return (
      <Skeleton
        linesConfig={
          [
            {
              type: 'chart',
              options: {
                flex: '1',
                height: '250px',
              },
            },
          ] as any
        }
      />
    );
  }

  render() {
    if (!graphqlOK(this.props)) {
      return this.renderSkeleton();
    }

    const {
      isOrgAdmin,
      data: {
        user: {
          organization: { paymentcontact: contact },
        },
      },
    } = this.props;
    let content;

    if (!isOrgAdmin) {
      content = t('You need to be organization admin to see payment method information.');
    } else if (contact && contact.paymentMethod === 'A_1') {
      content = (
        <div>
          <p>{t('Your account is paid via invoice.')}</p>
          <p>{t('Your payment terms are %s days.', contact.paymentTerms)}</p>
          <p>
            {t(
              'The invoice is emailed to your payment contact. If none is set, the invoices are sent to all account admins.',
            )}
          </p>
        </div>
      );
    } else if (
      !contact ||
      !contact.braintreeCustomer ||
      (!contact.braintreeCustomer.card && !contact.braintreeCustomer.paypal)
    ) {
      content = t('No payment method selected');
    } else {
      const { card, paypal } = contact.braintreeCustomer;
      content = [
        card && [
          <span key="card-image" className="info-row">
            <strong className="info-label-cell" />
            <span className="info-value-cell">
              <img src={card.imageUrl} />
            </span>
          </span>,
          <span key="number" className="info-row">
            <strong className="info-label-cell">{t('Card Number')}</strong>
            <span className="info-value-cell">{card.maskedNumber}</span>
          </span>,
          <span key="expiration" className="info-row last-card-row">
            <strong className="info-label-cell">{t('Expiration Date')}</strong>
            <span className="info-value-cell">{card.expirationDate}</span>
          </span>,
          <small key="help">
            {tct(
              'We do not store credit card information on our servers. [link:Our payment processor] is a validated Level 1 PCI DSS Compliant Service Provider.',
              {
                link: (
                  <a
                    href="https://www.braintreepayments.com/features/data-security"
                    target="_blank"
                    rel="noreferrer"
                  />
                ),
              },
            )}
          </small>,
        ],
        paypal && [
          <span key="paypal-image" className="info-row">
            <strong className="info-label-cell" />
            <span className="info-value-cell">
              <img src={paypal.imageUrl} />
            </span>
          </span>,
          <span key="paypal" className="info-row">
            <strong className="info-label-cell">{t('PayPal Account')}</strong>
            <span className="info-value-cell">{paypal.email}</span>
          </span>,
        ],
      ];
    }

    return <div className="billing-box">{content}</div>;
  }
}

const dataQuery = gql`
  query paymentMethod_braintreeCustomer {
    user {
      id
      organization {
        id
        paymentcontact {
          id
          paymentMethod
          paymentTerms
          braintreeCustomer {
            card {
              cardType
              maskedNumber
              expirationDate
              imageUrl
            }
            paypal {
              email
              imageUrl
            }
          }
        }
      }
    }
  }
`;
export default graphql<Props>(dataQuery, {
  options: () => ({
    fetchPolicy: 'network-only',
  }),
})(PaymentMethod);
