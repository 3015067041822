import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReactResizeDetector from 'react-resize-detector';
import Tree from 'react-virtualized-tree';
import { Box } from '@mantine/core';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import { useClientSideSearch } from 'Components/Table/TreeTable';
import type { NodeLike, TreeNode } from 'Components/Table/TreeTable';
import TableEmptyState from 'Components/TableEmptyState';
import { getDisabledDemoText } from 'Constants/messages';
import { useQueryDomainInfo } from 'Hooks/data/domain/useQueryDomainInfo';
import { TableRowChild } from 'Pages/SiteMapping/components/TreeTable/TableRowChild';
import { getAllNodes, getExpandedNodeIds, updateTreeInfo } from 'Utilities/Table/Tree/treeUtils';
import { t } from 'Utilities/i18n';
import { useClearSelectedOnUnmount } from '../../../Keywords/Groupings/EditMode/support/state';
import { TableComponent } from './TableComponent';
import { StickyScrollContainer } from './components/StickyScrollContainer';
import { FlattenedTreeNode } from './types';
import styles from './tree-table.module.scss';

// eslint-disable-next-line import/no-unused-modules
export interface TreeTableProps {
  rootNodes: NodeLike[];
  setSelectedNodes: (
    node: TreeNode & any, // In reality, this is a SitemapNode or FolderType, but gives trouble with typescript :(
  ) => void;
  saveExpandedNodes?: (nodeIds: string[]) => void;
  expandedNodes?: string[];
  expandRootNodesInitially?: boolean;
  expandDataKey: string;
  loading: boolean;
  RowContentComponent: (props) => JSX.Element;
  Header: (props) => JSX.Element;
  gridTableRows: string;
  disableRowSelect?: boolean;
  loadingElement?: JSX.Element;
  headerProps?: object;
  onDragEnd?: (event: React.DragEvent<HTMLDivElement>) => void;
  // force component re-render
  tableRowKey?: string;
}

/**Main component for the TreeTable in Organic Site Explorer */
export const TreeTable = ({
  rootNodes,
  saveExpandedNodes,
  expandedNodes: initExpandedNodeIds,
  expandDataKey,
  setSelectedNodes,
  loading,
  RowContentComponent,
  Header,
  gridTableRows,
  expandRootNodesInitially,
  disableRowSelect,
  loadingElement,
  headerProps,
  onDragEnd,
  tableRowKey,
}: TreeTableProps) => {
  const tableRef = useRef<HTMLTableElement>(null);
  useClearSelectedOnUnmount();

  const { isDemoDomain } = useQueryDomainInfo();

  const initData = useMemo(
    () => updateTreeInfo(rootNodes, initExpandedNodeIds, expandDataKey),
    [rootNodes, initExpandedNodeIds],
  );

  const [treeData, setData] = useState(initData);
  const { nestedData, submitSearch, activeSearch } = useClientSideSearch<any>(
    treeData,
    expandDataKey,
    initData,
  );

  // when sorting the table, we get new rootNodes data, and need to rerender the tree
  useEffect(() => {
    if (rootNodes) {
      setData(updateTreeInfo(rootNodes, initExpandedNodeIds, expandDataKey));
    }
  }, [JSON.stringify(rootNodes)]);

  const handleTreeChange = useCallback(
    (data) => {
      setData(updateTreeInfo(data, [], expandDataKey));
      saveExpandedNodes?.(getExpandedNodeIds(data));
    },
    [setData, saveExpandedNodes, getExpandedNodeIds],
  );

  const currentExpandedNodeIds = getExpandedNodeIds(treeData);

  const expandOrCollapseAllNodes = useCallback(() => {
    if (currentExpandedNodeIds.length > 0) {
      saveExpandedNodes?.([]);
      setData(updateTreeInfo(treeData, [], expandDataKey, true));
    } else {
      setData(updateTreeInfo(treeData, getAllNodes(treeData), expandDataKey));
      saveExpandedNodes?.(rootNodes?.map((node) => node[expandDataKey] as string) ?? []);
    }
  }, [currentExpandedNodeIds]);

  const TreeRow = useMemo(() => {
    const result = ({ node: rootNode, style, onChange }: any) => {
      return (
        <Box
          style={omit(style, ['marginLeft', 'cursor'])}
          ml="0"
          key={`${rootNode?.[expandDataKey]}-tableRowChild`}
        >
          <TableRowChild
            disableRowSelect={disableRowSelect}
            rootNode={rootNode as FlattenedTreeNode}
            className="rootNode"
            expandDataKey={expandDataKey}
            updateSelectedNode={setSelectedNodes}
            RowContentComponent={RowContentComponent}
            forceInitiallyExpanded={expandRootNodesInitially ?? Boolean(rootNodes?.length)}
            gridTableRows={gridTableRows}
            onChange={onChange}
            saveExpandedNodes={saveExpandedNodes}
            onDragEnd={onDragEnd}
          />
        </Box>
      );
    };
    result.displayName = 'TreeRow';

    return result;
  }, [setSelectedNodes, tableRowKey]);

  const [resetHeaderKey, setResetHeaderKey] = useState(0);
  const resetSearch = () => {
    setResetHeaderKey(resetHeaderKey + 1);
    submitSearch('');
  };
  // required for proper display with scrollable table
  const [tableWidth, setTableWidth] = useState(tableRef.current?.scrollWidth);
  const tableRows = useMemo(
    () => (
      <Box w="100%" display="flex" mih="700px" data-testid="tree-table-body">
        <ReactResizeDetector
          handleWidth
          onResize={() => {
            // getting width of table based on header width
            setTableWidth(tableRef.current?.children?.[0]?.scrollWidth);
          }}
        />
        {!isEmpty(nestedData) || loading ? (
          <Tree nodes={nestedData} onChange={handleTreeChange} width={tableWidth}>
            {TreeRow}
          </Tree>
        ) : (
          <TableEmptyState
            list={[]}
            title={t('No pages found')}
            subTitle={
              activeSearch
                ? t('We found no pages to show for the specified search.')
                : t('We found no pages to show for this domain')
            }
            filtersPossible={true}
            noDataWithFiltersTitle={t('No  pages found')}
            noDataWithFiltersSubTitle={t(
              'We found no pages to show, this could be due to your active filters. Clear your filters and try again.',
            )}
            onBtnClick={activeSearch ? resetSearch : null}
            btnTitle={activeSearch ? t('Clear Search') : null}
            fullWidth
            btnDisable={!activeSearch && isDemoDomain}
            btnTooltip={!activeSearch && isDemoDomain && getDisabledDemoText()}
          />
        )}
      </Box>
    ),
    [nestedData, tableWidth, tableRowKey],
  );

  const headerRow = useMemo(
    () => (
      <Header
        submitSearch={submitSearch}
        className={styles.treeTableHeader}
        activeSearch={activeSearch}
        {...headerProps}
        key={resetHeaderKey?.toString()}
        handleExpandOrCollapseAllNodes={expandOrCollapseAllNodes}
        expandNodes={currentExpandedNodeIds && currentExpandedNodeIds.length === 0}
      />
    ),
    [headerProps, activeSearch, resetHeaderKey, expandOrCollapseAllNodes],
  );
  return (
    <StickyScrollContainer width={tableWidth}>
      <TableComponent
        ref={tableRef}
        className={styles.treeTable}
        loading={loading}
        loadingElement={loadingElement}
        headerRow={headerRow}
        tableRows={tableRows}
      />
    </StickyScrollContainer>
  );
};
