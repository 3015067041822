import { formatNumberHelper } from 'Components/FormatNumber/formatNumberHelper';
import ValueDelta from 'Components/Table/TableRow/ValueDelta';
import { DashboardNode } from 'Pages/Domains/DomainsTable/support/types';
import { percentageChange } from 'Utilities/format';
import KPIChangeBadge from '../components/KpiChangeBadge';
import styles from '../domains-table.module.scss';

type Props = {
  domainNode: DashboardNode;
};

const ShareOfVoiceChangeCell = (props: Props) => {
  const { domainNode } = props;

  const countKeywords = domainNode?.countKeywords ?? 0;
  const shareOfVoice = domainNode?.shareOfVoice ?? 0;
  const shareOfVoiceCompare = domainNode?.shareOfVoiceCompare ?? 0;
  const shareOfVoicePercentageValue = domainNode?.shareOfVoicePercentageValue ?? 0;
  const shareOfVoicePercentageValueCompare = domainNode?.shareOfVoicePercentageValueCompare ?? 0;
  const isPercentage = domainNode?.shareOfVoiceIsPercentage ?? false;

  if (!countKeywords) return null;

  // logic copied from old dashboard table
  const sovChangePercentage = isPercentage
    ? shareOfVoicePercentageValue - shareOfVoicePercentageValueCompare
    : percentageChange(shareOfVoiceCompare, shareOfVoice);
  const sovChange = isPercentage
    ? shareOfVoicePercentageValue - shareOfVoicePercentageValueCompare
    : shareOfVoice - shareOfVoiceCompare;
  const sovChangeFormatted = formatNumberHelper({
    value: sovChange,
    precision: 1,
  });

  const sovChangePercentageFormatted = parseInt((sovChangePercentage * 100).toFixed(0), 10);
  const sov = isPercentage ? shareOfVoicePercentageValue : shareOfVoice;
  const showChange = isPercentage || shareOfVoice;
  const showBadge =
    !isPercentage && shareOfVoice && shareOfVoiceCompare && sovChangePercentageFormatted !== 0;

  if (!sovChangeFormatted || sovChangeFormatted === '0' || !showChange) return null;

  return (
    <div className={styles.changeCell}>
      <ValueDelta
        currentValue={sov}
        delta={isPercentage ? sovChangePercentage : sovChange}
        precision={0}
        percentage={isPercentage}
      />
      <KPIChangeBadge
        color={
          sovChangePercentageFormatted < 0
            ? 'red'
            : sovChangePercentageFormatted > 0
            ? 'green'
            : 'grey'
        }
        value={
          showBadge
            ? `${sovChangePercentage > 0 ? '+' : ''}${sovChangePercentageFormatted}%`
            : `${sovChangePercentageFormatted}%`
        }
      />
    </div>
  );
};

export default ShareOfVoiceChangeCell;
