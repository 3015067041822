import { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { ObservableQuery, useApolloClient } from '@apollo/client';
import { Center, CheckIcon } from '@mantine/core';
import { AccFastIcon } from 'Components/AccFastIcon';
import { ColumnType, TableOrder, useTableStore } from 'Components/DataTable';
import type { TableStoreType } from 'Components/DataTable/store/TableStore';
import ActionsCell, { ActionsCellItem } from 'Components/Table/TableRow/ActionsCell';
import {
  DefaultReportTemplateQuery,
  DefaultReportTemplateQueryVariables,
  ReportTemplateFragment,
  ReportTemplatesDocument,
  ReportTemplatesQuery,
  ReportTemplatesQueryVariables,
  useDefaultReportTemplateQuery,
  useUpdateReportTemplateMutation,
} from 'Ghql';
import { useModal } from 'Hooks/base/useModal';
import toast from 'Hooks/useToast';
import useUserPermission from 'Hooks/useUserPermission';
import { SORTING_TYPE } from 'Pages/Keywords/Table/hooks/keyword/constants';
import { ModalPayloadConfig } from 'Reducers/ModalReducer';
import { apolloClient } from 'Store';
import { ReportElement } from 'Types/ReportElement';
import { TableIDs } from 'Types/Table';
import { invalidateCache } from 'Utilities/Graphql/invalidateCache';
import { useHistory } from 'Utilities/Router/withRouter';
import { t } from 'Utilities/i18n';
import { devError } from 'Utilities/log';
import { notEmpty } from 'Utilities/underdash';
import styleVariables from 'css/base/variables.module.scss';
import IconCircleCheck from 'icons/IconCircleCheck.svg';
import IconEdit from 'icons/IconEdit.svg';
import IconFilePlus from 'icons/IconFilePlus.svg';
import { IconTrash } from 'icons/tag-cloud';
import { ReportWidgets } from '../ReportTemplatesTable';
import { ColumnIDs } from './ColumnIDs';

type RefetchDefaultTemplate = ObservableQuery<
  DefaultReportTemplateQuery,
  DefaultReportTemplateQueryVariables
>['refetch'];

type UpdateReportTemplate = ReturnType<typeof useUpdateReportTemplateMutation>[0];

const handleDelete = ({
  template,
  updateTemplate,
  showModal,
  handleUpdateTable,
  tableStore,
}: {
  template: ReportTemplateFragment;
  updateTemplate: UpdateReportTemplate;
  showModal: (config: ModalPayloadConfig | undefined) => void;
  handleUpdateTable: () => void;
  tableStore: TableStoreType | null;
}) => {
  const { id, name } = template;
  showModal({
    modalType: 'Confirmation',
    modalProps: {
      cancelLabel: t('Cancel'),
      confirmLabel: t('Delete'),
      lockDuration: 0,
      title: t('Delete Template \'%s\'?', name),
      description: t(
        'Please note that all scheduled reports using this template will be removed as well',
      ),
      action: () => {
        const input = {
          id,
          brandColor: '000000',
          name: '~~DELETE~~',
          template: '[{}]',
          delete: true,
          default: false,
        };
        updateTemplate({
          variables: {
            input,
          },
        }).then((res) => {
          const errors = res.data?.updateReportTemplate?.errors;
          if (errors && errors.length) {
            toast.error(t('Unable to remove template'));
          } else {
            toast.success(t('Template removed'));
            tableStore?.deleteItem(id);
            handleUpdateTable();
            invalidateCache(apolloClient.cache, 'reportTemplates');
          }
        });
      },
    },
  });
};

const handleSetAsDefault = (
  { id, brandColor, name, template }: ReportTemplateFragment,
  updateTemplate: UpdateReportTemplate,
  refetchDefaultTemplate: RefetchDefaultTemplate,
  handleUpdateTable: () => void,
) => {
  const input = {
    id,
    brandColor: brandColor || '',
    name,
    template,
    delete: false,
    default: true,
  };
  updateTemplate({
    variables: {
      input,
    },
  }).then((res) => {
    const errors = res.data?.updateReportTemplate?.errors;
    if (errors && errors.length) {
      toast.error(t('Unable to set template as default'));
    } else {
      handleUpdateTable();
      invalidateCache(apolloClient.cache, 'reportTemplates');
      refetchDefaultTemplate();
    }
  });
};

const getFilteredReportTemplates = (
  templates: ReportTemplateFragment[],
  search: string,
  reportWidgets: ReportWidgets,
) => {
  return templates.filter((template) => {
    const isNameMatch: boolean = template.name.toLowerCase().includes(search);
    try {
      const parsed: ReportElement[] = JSON.parse(template.template);
      const isElementMatch: boolean = parsed
        .map(({ type }) => reportWidgets[type]?.label)
        .join(', ')
        .toLowerCase()
        .includes(search);
      return isNameMatch || isElementMatch;
    } catch (err) {
      devError(err);
      return false;
    }
  });
};

type FetchReportTemplatesProps = {
  searchTerm: string;
  handleLoading: (isLoading: boolean) => void;
  reportWidgets: ReportWidgets;
};

export const useFetchReportTemplates = ({
  searchTerm,
  handleLoading,
  reportWidgets,
}: FetchReportTemplatesProps) => {
  const client = useApolloClient();

  return useCallback(async () => {
    try {
      handleLoading(true);
      const response = await client.query<ReportTemplatesQuery, ReportTemplatesQueryVariables>({
        query: ReportTemplatesDocument,
      });
      const reportTemplates = response?.data?.reportTemplates?.filter(notEmpty) || [];

      const search = searchTerm?.toLowerCase();

      const filteredTemplates = getFilteredReportTemplates(reportTemplates, search, reportWidgets);

      return { data: filteredTemplates, length: filteredTemplates.length };
    } catch (error: unknown) {
      if (error instanceof Error) {
        console.error('Error fetching generated reports:', error.message);
      }
      return { data: [], length: 0 };
    } finally {
      handleLoading(false);
    }
  }, [client, searchTerm, handleLoading, reportWidgets]);
};

type ReportTemplateColumnsProps = {
  reportWidgets: ReportWidgets;
  handleUpdateTable: () => void;
};

export const useReportTemplateColumns = ({
  reportWidgets,
  handleUpdateTable,
}: ReportTemplateColumnsProps) => {
  const { data: defaultReportTemplate, refetch: refetchDefaultTemplate } =
    useDefaultReportTemplateQuery();
  const [updateTemplate] = useUpdateReportTemplateMutation();
  const { userHasWriteAccess } = useUserPermission();
  const history = useHistory();
  const tableStore = useTableStore(TableIDs.REPORT_TEMPLATES);
  const { showModal } = useModal();

  const columns: ColumnType<ReportTemplateFragment, {}>[] = useMemo(() => {
    const cols: ColumnType<ReportTemplateFragment>[] = [
      {
        id: ColumnIDs.NAME,
        title: t('Name'),
        flex: true,
        width: 250,
        cellRenderer: (props) => {
          return props.record.systemTemplate || !userHasWriteAccess ? (
            props.record.name
          ) : (
            <Link to={`/reports/templates/builder/edit/${props.record.id}`}>
              {props.record.name}
            </Link>
          );
        },
        onHeaderCell: () => ({
          ordering: {
            defaultOrder: TableOrder.DESC,
            orderBy: ColumnIDs.NAME,
            sortingKey: SORTING_TYPE.alphabetical,
          },
        }),
      },
      {
        id: ColumnIDs.ELEMENTS,
        title: t('Elements'),
        flex: true,
        width: 420,
        cellRenderer: (props) => {
          const template = props.record.template;
          const parsed: ReportElement[] = JSON.parse(template);
          return <span>{parsed.map(({ type }) => reportWidgets[type]?.label).join(', ')}</span>;
        },
        onHeaderCell: () => ({
          ordering: {
            defaultOrder: TableOrder.DESC,
            orderBy: 'template',
            sortingKey: SORTING_TYPE.alphabetical,
          },
        }),
      },
      {
        id: ColumnIDs.SYSTEM_TEMPLATE,
        title: t('System Template'),
        width: 120,
        cellRenderer: (props) => {
          const { gray7 } = styleVariables;
          return (
            props.record.systemTemplate && (
              <Center>
                <CheckIcon color={gray7} size={16} />
              </Center>
            )
          );
        },
        onHeaderCell: () => ({
          ordering: {
            defaultOrder: TableOrder.DESC,
            orderBy: 'systemTemplate',
          },
        }),
        className: 'table-icon',
      },
      {
        id: ColumnIDs.DEFAULT_TEMPLATE,
        title: t('Default Template'),
        width: 124,
        cellRenderer: (props) => {
          const { gray7 } = styleVariables;
          return (
            props.record.isOrganizationDefaultTemplate && (
              <Center>
                <CheckIcon color={gray7} size={16} />
              </Center>
            )
          );
        },
        onHeaderCell: () => ({
          ordering: {
            defaultOrder: TableOrder.DESC,
            orderBy: 'isOrganizationDefaultTemplate',
          },
        }),
        className: 'table-icon',
      },
    ];
    if (userHasWriteAccess) {
      cols.push({
        id: ColumnIDs.ACTIONS,
        title: t('Actions'),
        width: 140,
        cellRenderer: (props) => {
          const template = props.record;

          if (template && defaultReportTemplate && defaultReportTemplate.user) {
            const orgDefaultReportTemplate =
              defaultReportTemplate?.user.organization?.defaultReportTemplate;

            const hasDefault = !!orgDefaultReportTemplate;
            const defaultId = orgDefaultReportTemplate && orgDefaultReportTemplate.id;

            const canSetAsDefault =
              (!hasDefault && !template.defaultSystemTemplate) || template.id !== defaultId;

            const { snorlax4: iconColor, gray4: disabledIconColor } = styleVariables;

            const actions: ActionsCellItem[] = [
              {
                label: t('Set as default'),
                onSelect: () =>
                  handleSetAsDefault(
                    template,
                    updateTemplate,
                    refetchDefaultTemplate,
                    handleUpdateTable,
                  ),
                fastIcon: (
                  <AccFastIcon
                    src={IconCircleCheck}
                    size={20}
                    color={canSetAsDefault ? iconColor : disabledIconColor}
                  />
                ),
                disabled: !canSetAsDefault,
              },
              {
                label: t('Edit'),
                fastIcon: (
                  <AccFastIcon
                    src={IconEdit}
                    size={20}
                    color={template.systemTemplate ? disabledIconColor : iconColor}
                  />
                ),
                onSelect: () => history.push(`/reports/templates/builder/edit/${template.id}`),
                disabled: template.systemTemplate,
              },
              {
                label: t('Clone'),
                fastIcon: <AccFastIcon src={IconFilePlus} size={20} color={iconColor} />,
                onSelect: () => history.push(`/reports/templates/builder/clone/${template.id}`),
              },
              {
                label: t('Delete'),
                disabled: template.systemTemplate,
                onSelect: () =>
                  handleDelete({
                    template,
                    updateTemplate,
                    showModal,
                    handleUpdateTable,
                    tableStore,
                  }),
                fastIcon: (
                  <AccFastIcon
                    src={IconTrash}
                    size={20}
                    color={template.systemTemplate ? disabledIconColor : iconColor}
                    tooltip={
                      template.systemTemplate ? t('Can\'t delete system templates') : undefined
                    }
                  />
                ),
              },
            ];
            return <ActionsCell shouldUpdateIndicator={props.record} actions={actions} />;
          }
        },
      });
    }

    return cols;
  }, [
    defaultReportTemplate,
    history,
    reportWidgets,
    refetchDefaultTemplate,
    updateTemplate,
    showModal,
    handleUpdateTable,
    tableStore,
    userHasWriteAccess,
  ]);

  return columns;
};
