import { DataTable, TableOrder, TableSize } from 'Components/DataTable';
import { TableStyleMode } from 'Components/DataTable/types';
import { KeywordColumnID } from 'Pages/Keywords/Table/support/constants';
import { TableIDs } from 'Types/Table';
import { t } from 'Utilities/i18n';
import { useFetchOverviewNotifications } from './support/hooks/data';
import { useNotificationsTableColumns } from './support/hooks/table';

const DEFAULT_NOTIFICATIONS_ORDERING = {
  order: TableOrder.ASC,
  orderBy: KeywordColumnID.KEYWORD,
};

export const OverviewNotificationsTable = () => {
  const fetchData = useFetchOverviewNotifications();
  const columns = useNotificationsTableColumns();
  return (
    <DataTable
      tableId={TableIDs.NOTIFICATIONS}
      columns={columns}
      fetchData={fetchData}
      styleMode={TableStyleMode.ROUNDED}
      viewMode={TableSize.COMFORTABLE}
      defaultOrdering={DEFAULT_NOTIFICATIONS_ORDERING}
      tableMinHeight={200}
      scrollOnPageChange={false}
      pageSize={50}
      emptyOptions={{
        title: t('No notifications'),
        subTitle: (
          <>
            {t('Learn more about notifications')}{' '}
            <a
              href={'https://www.accuranker.com/help/overview/notifications/'}
              target={'_blank'}
              rel="noreferrer"
            >
              here
            </a>
          </>
        ),
      }}
    />
  );
};
