import { HIDE_CONNECTOR_PREFIX } from '../../../../Utilities/Table/Tree/constants';
import type { NodeLike } from './types';
import { WithTreeState } from './types';

export const isNodeExpanded = (node) => node.state?.expanded;
export const nodeHasChildren = (node) => node.children?.length;

export const flattenTreeAndAddMetaInfo = (
  nodes: NodeLike[] | undefined,
  parents: (string | number)[] = [],
  hiddenConnectors: string[] = [],
) => {
  return (nodes || []).reduce((acc, node, i) => {
    const isLastChild = i === (nodes?.length || 0) - 1 && !!parents.length;

    const deepness = parents.length;
    const nodeWithHelpers = { ...node, deepness, parents, hiddenConnectors, isLastChild };
    if (!nodeHasChildren(node) || !isNodeExpanded(node)) {
      return [...acc, nodeWithHelpers];
    }

    const nextHiddenConnectors =
      isLastChild && (node.is_folder || node.isFolder)
        ? [...hiddenConnectors, `${HIDE_CONNECTOR_PREFIX}${deepness - 1}`]
        : hiddenConnectors;
    return [
      ...acc,
      nodeWithHelpers,
      ...flattenTreeAndAddMetaInfo(
        node.children || [],
        [...parents, node.id],
        nextHiddenConnectors,
      ),
    ];
  }, [] as NodeLike[]);
};

export function applyExpandedToTree(
  tree: NodeLike[] | null | undefined,
  expandedNodeIds: (string | number)[] | undefined,
  expandDataKey: string,
  overwriteExpandedState?: boolean,
): NodeLike<WithTreeState>[] {
  function applyExpandedToItem(node: NodeLike) {
    const nodeId = node?.[expandDataKey];
    const updatedNode = {
      ...node,
      children: node.children ?? [],
      state: expandedNodeIds?.includes(nodeId)
        ? { expanded: true }
        : overwriteExpandedState
        ? { expanded: false }
        : node.state,
      id: nodeId,
    };

    if (updatedNode.children?.length) {
      updatedNode.children = updatedNode.children.map(applyExpandedToItem);
    }

    return updatedNode;
  }

  return tree?.map?.(applyExpandedToItem) ?? [];
}
