import { IconTrash } from '@tabler/icons-react';
import AccButton from 'Components/AccButton/AccButton';
import { AccDropdownMenu, DropdownItem } from 'Components/AccDropdownMenu';
import { t } from 'Utilities/i18n';

// import SimpleDropdownList from 'Components/Controls/Dropdowns/SimpleDropdownList';
type Props = {
  selectedKeywords: any[];
  isAllSelected: boolean;
  totalResults: number;
  // functions for actions
  showRemoveTagsModal: (...args: Array<any>) => any;
  showDeleteKeywordsModal: (...args: Array<any>) => any;
  updateKeywordsStarred: (...args: Array<any>) => any;
};

const MoreRemoveActions = (props: Props) => {
  const {
    updateKeywordsStarred,
    showRemoveTagsModal,
    showDeleteKeywordsModal,
    isAllSelected,
    totalResults,
    selectedKeywords,
  } = props;
  const items: DropdownItem[] = [
    {
      label: t('Delete tags'),
      onSelect: showRemoveTagsModal,
    },
    {
      label: t('Delete stars'),
      onSelect: updateKeywordsStarred,
    },
    {
      label: t('Delete keywords'),
      onSelect: showDeleteKeywordsModal,
    },
  ];
  return (
    <AccDropdownMenu items={items}>
      <AccButton variant="tertiary" leftSection={<IconTrash />}>
        {t('Delete')}
        {` (${isAllSelected ? totalResults : selectedKeywords?.length})`}
      </AccButton>
    </AccDropdownMenu>
  );
};

export default MoreRemoveActions;
