import { gql } from '@apollo/client';

export const UPDATE_TABLE_SETTING_MUTATION = gql`
  mutation UpdateTableSettingMutation($input: UpdateUserSettingsInput!) {
    updateUserSettings(input: $input) {
      user {
        id
        defaultKeywordsColumns
        defaultCompetitorsColumns
        defaultLandingPagesColumns
        defaultTagCloudColumns
        defaultNotesColumns
        defaultKeywordsNotificationsColumns
        defaultFoldersColumns
        keywordsGroupOrder
      }
    }
  }
`;
