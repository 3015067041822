import { useMemo } from 'react';
import { Field } from 'Components/Fields';
import SelectOption from 'Components/SelectOption';
import { UserRole, getAllUserRoleOptions } from 'Constants/workspaces';
import styles from './field.module.scss';

type Props = {
  defaultValue: UserRole;
  name: string;
  disabled?: boolean;
  withinPortal?: boolean;
  size?: 'default' | 'sm' | 'md' | 'lg';
};

const FieldUserRole = (props: Props) => {
  const userRoleOptions = useMemo(getAllUserRoleOptions, []);

  return (
    <Field.SelectValue
      itemComponent={SelectOption}
      name={props.name}
      searchable={false}
      clearable={false}
      classNames={{ options: styles.selectOptions }}
      options={userRoleOptions}
      size={props.size || 'default'}
      value={props.defaultValue}
      disabled={props.disabled}
      withinPortal={props.withinPortal}
      dropdownWidth={400}
      dropdownPosition="bottom-start"
    />
  );
};

export default FieldUserRole;
