import { useDisplayCurrency } from 'Hooks/displayCurrency';
import { CHART_NAMES } from 'Pages/Keywords/Overview/components/ChartBox';
import AboveTheFoldKpiBox from 'Pages/Keywords/Overview/components/KpiBar/Boxes/AboveTheFold';
import AiSearchVolumeKpiBox from 'Pages/Keywords/Overview/components/KpiBar/Boxes/AiSearchVolume';
import AiShareOfVoiceKpiBox from 'Pages/Keywords/Overview/components/KpiBar/Boxes/AiShareOfVoiceBox';
import AiTrafficValueKpiBox from 'Pages/Keywords/Overview/components/KpiBar/Boxes/AiTrafficValue';
import AverageBaseRankKpiBox from 'Pages/Keywords/Overview/components/KpiBar/Boxes/AverageBaseRank';
import AverageRankKpiBox from 'Pages/Keywords/Overview/components/KpiBar/Boxes/AverageRank';
import DynamicCtrKpiBox from 'Pages/Keywords/Overview/components/KpiBar/Boxes/DynamicCtrKpiBox';
import KeywordsCountKpiBox from 'Pages/Keywords/Overview/components/KpiBar/Boxes/KeywordsCount';
import PixelsFromTopKpiBox from 'Pages/Keywords/Overview/components/KpiBar/Boxes/PixelsFromTop';
import SearchVolumeKpiBox from 'Pages/Keywords/Overview/components/KpiBar/Boxes/SearchVolume';
import ShareOfVoiceKpiBox from 'Pages/Keywords/Overview/components/KpiBar/Boxes/ShareOfVoiceKpiBox';
import TrafficValueKpiBox from 'Pages/Keywords/Overview/components/KpiBar/Boxes/TrafficValue';
import { DraggableKpiBar } from 'Pages/Keywords/Overview/components/KpiBar/DraggableKpi';
import { PUBLIC_KPI_NAMES } from 'Pages/PublicReport/publicKpiBar/Boxes';
import { t } from 'Utilities/i18n';
import { useKpiBarQueryWithCurrency } from './Boxes/useKpiBarQueryWithDisplayCurrency';

interface KpiBarProps {
  activeKpis?: string[];
  saveKpis?: any;
  disableEdit?: boolean;
}

export const KPI_NAMES = {
  KEYWORDS: 'KPI Keywords',
  AVERAGE_RANK: 'KPI Average Rank',
  TRAFFIC_VALUE: 'KPI Traffic Value',
  SHARE_OF_VOICE: 'KPI Share of Voice',
  ABOVE_THE_FOLD: 'KPI Above the Fold',
  DYNAMIC_CTR: 'KPI Dynamic CTR',
  AI_SHARE_OF_VOICE: 'KPI AI Share of Voice',
  BASE_RANK: 'KPI Base Rank',
  AI_TRAFFIC_VALUE: 'KP AI Traffic Value',
  PIXELS_FROM_TOP: 'KPI Pixels from Top',
  SEARCH_VOLUME: 'KPI Search Volume',
  AI_SEARCH_VOLUME: 'KPI AI Search Volume',
} as const;

export const KpiMap = {
  [KPI_NAMES.KEYWORDS]: KeywordsCountKpiBox,
  [KPI_NAMES.AVERAGE_RANK]: AverageRankKpiBox,
  [KPI_NAMES.TRAFFIC_VALUE]: TrafficValueKpiBox,
  [KPI_NAMES.SHARE_OF_VOICE]: ShareOfVoiceKpiBox,
  [KPI_NAMES.AI_SHARE_OF_VOICE]: AiShareOfVoiceKpiBox,
  [KPI_NAMES.ABOVE_THE_FOLD]: AboveTheFoldKpiBox,
  [KPI_NAMES.DYNAMIC_CTR]: DynamicCtrKpiBox,
  [KPI_NAMES.BASE_RANK]: AverageBaseRankKpiBox,
  [KPI_NAMES.AI_TRAFFIC_VALUE]: AiTrafficValueKpiBox,
  [KPI_NAMES.PIXELS_FROM_TOP]: PixelsFromTopKpiBox,
  [KPI_NAMES.SEARCH_VOLUME]: SearchVolumeKpiBox,
  [KPI_NAMES.AI_SEARCH_VOLUME]: AiSearchVolumeKpiBox,
};

export const GET_KPI_DISPLAY_NAME = () => ({
  [KPI_NAMES.KEYWORDS]: t('Keywords'),
  [KPI_NAMES.AVERAGE_RANK]: t('Average Rank'),
  [KPI_NAMES.TRAFFIC_VALUE]: t('Traffic Value'),
  [KPI_NAMES.AI_TRAFFIC_VALUE]: t('AI Traffic Value'),
  [KPI_NAMES.SHARE_OF_VOICE]: t('Share of Voice'),
  [KPI_NAMES.ABOVE_THE_FOLD]: t('Above the Fold'),
  [KPI_NAMES.DYNAMIC_CTR]: t('Average CTR'),
  [KPI_NAMES.AI_SHARE_OF_VOICE]: t('AI Share of Voice'),
  [KPI_NAMES.BASE_RANK]: t('Base Rank'),
  [KPI_NAMES.PIXELS_FROM_TOP]: t('Pixels from Top'),
  [KPI_NAMES.SEARCH_VOLUME]: t('Search Volume'),
  [KPI_NAMES.AI_SEARCH_VOLUME]: t('AI Search Volume'),
});

export const getKpiTooltipText = () => ({
  [KPI_NAMES.KEYWORDS]: t(
    'The amount of tracked keywords in the current filtering and whether it\'s trending upwards or downwards.',
  ),
  [KPI_NAMES.AVERAGE_RANK]: t(
    'The average rank for all keywords in the current filtering and whether it\'s trending upwards or downwards.',
  ),
  [KPI_NAMES.TRAFFIC_VALUE]: t(
    'The Traffic Value for the keywords in the current filtering and whether it\'s trending upwards or downwards. Estimated by multiplying the average CPC by SoV.',
  ),
  [KPI_NAMES.SHARE_OF_VOICE]: t(
    'The total Share of Voice for the keywords in the current filtering and whether it\'s trending upwards or downwards.',
  ),
  [KPI_NAMES.AI_SHARE_OF_VOICE]: t(
    'The total AI Share of Voice for the keywords in the current filtering it\'s trend based on a machine learning model. Estimated based on a machine learning model.',
  ),
  [KPI_NAMES.ABOVE_THE_FOLD]: t(
    'The total number of keywords in the current filtering that is visible in the search results without scrolling.',
  ),
  [KPI_NAMES.DYNAMIC_CTR]: t(
    'The average Click-through Rate for the keywords in the current filtering and it\'s trend, weighted by their Search Volume.',
  ),
  [KPI_NAMES.BASE_RANK]: t(
    'The base rank for all keywords in the current filtering before applying any keyword settings and it\'s trend.',
  ),
  [KPI_NAMES.AI_TRAFFIC_VALUE]: t(
    'The traffic value for the keywords in the current filtering and whether it\'s trending upwards or downwards. Estimated by multiplying the average CPC by AI SoV',
  ),
  [KPI_NAMES.PIXELS_FROM_TOP]: t(
    'The average distance in pixel from the top of the SERP to the keywords in the current filtering and it\'s trend.',
  ),
  [KPI_NAMES.SEARCH_VOLUME]: t(
    'The search volume for the keywords in the current filtering and whether it is trending upwards or downwards.',
  ),
  [KPI_NAMES.AI_SEARCH_VOLUME]: t(
    'The AI search volume for the keywords in the current filtering and whether it is trending upwards or downwards.',
  ),
  [PUBLIC_KPI_NAMES.TOP_ONE_KEYWORDS]: t(
    'The number of keywords that are ranked number one on the first page.\n\nFirst page is defined as top 10 results.',
  ),
  [PUBLIC_KPI_NAMES.TOP_THREE_KEYWORDS]: t(
    'The number of keywords that are ranked in the top three on the first page.\n\nFirst page is defined as top 10 results.',
  ),
  [PUBLIC_KPI_NAMES.ON_PAGE_ONE]: t(
    'The number of keywords that are ranked on the first page.\n\nFirst page is defined as top 10 results.',
  ),
  [PUBLIC_KPI_NAMES.KEYWORDS_MOVED_UP]: t(
    'The number of keywords that have moved up in rankings since the comparison date.',
  ),
  [PUBLIC_KPI_NAMES.KEYWORDS_MOVED_DOWN]: t(
    'The number of keywords that have moved down in rankings since the comparison date.',
  ),
});

export const GET_KPI_DESCRIPTION = () => ({
  [KPI_NAMES.KEYWORDS]: t(
    'The amount of tracked keywords in the current filtering and whether it\'s trending upwards or downwards.',
  ),
  [KPI_NAMES.AVERAGE_RANK]: t(
    'The average rank for all keywords in the current filtering and whether it\'s trending upwards or downwards.',
  ),
  [KPI_NAMES.TRAFFIC_VALUE]: t(
    'The Traffic Value for the keywords in the current filtering andwhether it\'s trending upwards or downwards.',
  ),
  [KPI_NAMES.SHARE_OF_VOICE]: t(
    'The total Share of Voice for the keywords in the current filtering and whether it\'s trending upwards or downwards.',
  ),
  [KPI_NAMES.AI_SHARE_OF_VOICE]: t(
    'The total AI Share of Voice for the keywords in the current filtering it\'s trend based on a machine learning model.',
  ),
  [KPI_NAMES.ABOVE_THE_FOLD]: t(
    'The total number of keywords in the current filtering that is visible in the search results without scrolling.',
  ),
  [KPI_NAMES.DYNAMIC_CTR]: t(
    'The average Click-through Rate for the keywords in the current filtering and it\'s trend, weighted by their Search Volume.',
  ),
  [KPI_NAMES.BASE_RANK]: t(
    'The base rank for all keywords in the current filtering before applying any keyword settings and it\'s trend.',
  ),
  [KPI_NAMES.AI_TRAFFIC_VALUE]: t(
    'The traffic value for the keywords in the current filtering and whether it\'s trending upwards or downwards.',
  ),
  [KPI_NAMES.PIXELS_FROM_TOP]: t(
    'The average distance in pixel from the top of the SERP to the keywords in the current filtering and it\'s trend.',
  ),
  [KPI_NAMES.SEARCH_VOLUME]: t(
    'The search volume for the keywords in the current filtering and whether it is trending upwards or downwards.',
  ),
  [KPI_NAMES.AI_SEARCH_VOLUME]: t(
    'The AI search volume for the keywords in the current filtering and whether it is trending upwards or downwards.',
  ),
});

const KpiBar = ({ activeKpis, saveKpis, disableEdit }: KpiBarProps) => {
  const { displayCurrency } = useDisplayCurrency(true);
  const { data, loading } = useKpiBarQueryWithCurrency(displayCurrency);

  const kpiBarQueryProps = { displayCurrency, data, loading };
  const items = activeKpis?.filter((e) => e !== CHART_NAMES.NONE) ?? [];

  return (
    <DraggableKpiBar
      kpis={items || []}
      kpiBarQueryProps={kpiBarQueryProps}
      saveKpis={saveKpis}
      disableEdit={disableEdit}
    />
  );
};

export default KpiBar;
