import { formatNumberHelper } from 'Components/FormatNumber/formatNumberHelper';
import ValueDelta from 'Components/Table/TableRow/ValueDelta';
import { DashboardNode } from 'Pages/Domains/DomainsTable/support/types';
import { percentageChange } from 'Utilities/format';
import KPIChangeBadge from '../components/KpiChangeBadge';
import styles from '../domains-table.module.scss';

type Props = {
  domainNode: DashboardNode;
};

const AiShareOfVoiceChangeCell = (props: Props) => {
  const { domainNode } = props;
  const aiShareOfVoice = domainNode?.aiShareOfVoice ?? 0;
  const aiShareOfVoiceCompare = domainNode?.aiShareOfVoiceCompare ?? 0;

  if (!domainNode?.countKeywords) return null;

  // logic copied from old dashboard table
  const sovChangePercentage = percentageChange(aiShareOfVoiceCompare, aiShareOfVoice);
  const sovChange = aiShareOfVoice - aiShareOfVoiceCompare;
  const sovChangeFormatted = formatNumberHelper({
    value: sovChange,
    precision: 1,
  });
  const sovChangePercentageFormatted = parseInt((sovChangePercentage * 100).toFixed(0), 10);
  const showPercentageChange = !!aiShareOfVoice && !!aiShareOfVoiceCompare;

  // If aiShareOfVoice is 0 or sovChangeFormatted is not defined or '0' return null
  if (!aiShareOfVoice || !sovChangeFormatted || sovChangeFormatted === '0') return null;

  return (
    <div className={styles.changeCell}>
      <ValueDelta
        currentValue={aiShareOfVoice}
        delta={sovChange}
        precision={0}
        percentage={false}
      />
      <KPIChangeBadge
        color={sovChangePercentage < 0 ? 'red' : sovChangePercentage > 0 ? 'green' : undefined}
        value={
          sovChangePercentageFormatted !== 0 && showPercentageChange
            ? `${sovChangePercentage > 0 ? '+' : ''}${sovChangePercentageFormatted}%`
            : undefined
        }
      />
    </div>
  );
};

export default AiShareOfVoiceChangeCell;
