import { IntlProvider } from 'react-intl';
import { Outlet } from 'react-router-dom';
import { IntercomProvider } from 'react-use-intercom';
import * as Sentry from '@sentry/react';
import config from 'config';
import { useGoogleAdsTracking } from 'Hooks/useConversionTracking';
import Subscription from '../../Hooks/Subscription';
import { useIsLoadingInitialState, useUser } from '../../Hooks/data/user/useUser';
import User from '../../Queries/user';
import { AdvancedRouterWrapper } from '../../Utilities/Router/components/LocationPathnameContext';
import { ModalCloseListener } from '../../Utilities/Router/components/ModalCloseListener';
import { onSubscriptionData } from '../../Utilities/websocket';
import HighchartsLang from '../HighchartsLang';
import Loader from '../Loader';
import Modal from '../Modal';
import { UserRunningTasksSubscription } from '../RunningTasksSubscription';
import AppContainer from './AppMetaContainer';
import { usePreventScrollWhenModalOpen } from './helpers';
import {
  useAppInitSubscriptions,
  useInitModals,
  useLanguageListener,
  useSentryChangeListener,
} from './support/hooks';

const BaseContainer = () => {
  const user = useUser();
  const { isAuthenticated } = user;
  const isLoadingInitialState = useIsLoadingInitialState();

  useAppInitSubscriptions(isLoadingInitialState);
  const [langKey, updatePage] = useLanguageListener();
  useSentryChangeListener(user);
  useInitModals();

  // Google Ads tracking for all authenticated users to aid in creating a more comprehensive audience profile, allowing for their exclusion from Ads campaigns.
  const skipGoogleAdsTracking = !isAuthenticated;
  useGoogleAdsTracking({ isTrialConversionFlow: false }, skipGoogleAdsTracking);

  usePreventScrollWhenModalOpen();

  if (isLoadingInitialState) {
    return null;
  }

  return (
    <AdvancedRouterWrapper>
      <IntlProvider locale={user?.language || ''} key={langKey}>
        <IntercomProvider appId={config.intercomAppId} autoBoot={false}>
          <AppContainer>
            {isAuthenticated && (
              <Subscription
                query={User.subscriptions.initSubscription}
                onSubscriptionData={onSubscriptionData}
              />
            )}
            <HighchartsLang />
            <Modal updatePage={updatePage} />
            <Loader isGlobal />
            <Outlet />
            <ModalCloseListener />
            {/*<NewsDialog />*/}
            <UserRunningTasksSubscription />
          </AppContainer>
        </IntercomProvider>
      </IntlProvider>
    </AdvancedRouterWrapper>
  );
};

const ResultBaseContainer = Sentry.withProfiler(BaseContainer);
ResultBaseContainer.displayName = 'BaseContainerWithSentryProfiler';

export default ResultBaseContainer;
