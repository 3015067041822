import * as Sentry from '@sentry/react';
import { ChartsFragment } from 'Ghql';
import {
  AdjustmentsHorizontal,
  AiOverview,
  Basket,
  Breadcrumbs,
  Carousel,
  Cart,
  Comment,
  Conversation,
  DirectAnswer,
  DiscoverMorePlaces,
  DollarOverUnderLine,
  Dress,
  Events,
  Faq,
  FeaturedVideo,
  Flights,
  HotelPack,
  Https,
  ImagePack,
  ImagePackBasket,
  JobPosting,
  KnowledgeCard,
  KnowledgePanel,
  Link,
  LocalTeaserPack,
  Map,
  MostPopularCarousel,
  Movie,
  Overline,
  Podcasts,
  Recipes,
  RefineCarousel,
  RelatedSearchesCarousel,
  Report,
  ResearchProduct,
  ResultsAbout,
  SearchByPhotos,
  SiteLinksSearchbox,
  Snippet,
  SoftwareApp,
  Speechbubble,
  StarShop,
  TagStarred,
  ThingsTodo,
  Thumbnails,
  TripsPopular,
  Tweet,
  Underline,
  VideoCarousel,
  YoutubeAds,
  YoutubeChannel,
  YoutubeLiveStream,
  YoutubeMovie,
  YoutubePlaylist,
  YoutubePromotedVideo,
  YoutubeRadio,
  YoutubeRelatedMovies,
  YoutubeRelatedSearches,
  YoutubeRelatedToYourSearch,
  YoutubeSecondarySearchContainer,
  YoutubeShelfRender,
  YoutubeShow,
  YoutubeVideo,
} from 'Pages/Keywords/Table/Icon/serpIcons';
import { PrettifyUnion } from 'Types/Prettify';
import { SerpFeatures } from 'Types/Store';
import { SerpFeaturesItem } from '../HelperComponents/SerpPopOver/types';

/** Convert camelCase string literal types to snake_case  */
export type SnakeCase<S extends string> = S extends `${infer First}${infer Rest}`
  ? First extends Uppercase<First>
    ? `_${Lowercase<First>}${SnakeCase<Rest>}`
    : `${First}${SnakeCase<Rest>}`
  : '';

type OwnedSerp<T> = {
  [K in keyof T as K extends `${string}Owned` ? K : never]: T[K];
};

// TODO: Remove when backend endpoint is updated with the correct types and add them to the SerpsData query
type missingOwned =
  | 'youtubeAgeOwned'
  | 'youtubeViewsOwned'
  | 'youtubeVideoCountOwned'
  | 'youtubeSubscribersOwned'
  | 'youtubeWatchingOwned';

// TODO: Remove when backend endpoint is updated with the correct types and add them to the SerpsData query
type missingSerps = 'hotelsPack';

type SerpFeatureOverviewFragment = NonNullable<
  NonNullable<ChartsFragment['serpFeatureOverview']>[number]
>;

type OwnedSerpFeatureTypes =
  | keyof OwnedSerp<Omit<SerpFeatureOverviewFragment, '__typename' | 'keywordCount'>>
  | missingOwned;

type OwnedSerpTypes = SnakeCase<OwnedSerpFeatureTypes>;

type SerpFeatureTypes =
  | Exclude<
      keyof Omit<SerpFeatureOverviewFragment, '__typename' | 'keywordCount'>,
      OwnedSerpFeatureTypes
    >
  | missingSerps;
type SerpTypes = SnakeCase<SerpFeatureTypes>;

export type AllSerpTypes = PrettifyUnion<SerpTypes | OwnedSerpTypes>;

/** All SERP types that can be owned*/
export const ownedSerpTypes: Record<OwnedSerpTypes, OwnedSerpTypes> = {
  maps_local_teaser_owned: 'maps_local_teaser_owned',
  maps_local_owned: 'maps_local_owned',
  related_questions_owned: 'related_questions_owned',
  reviews_owned: 'reviews_owned',
  tweets_owned: 'tweets_owned',
  site_links_owned: 'site_links_owned',
  feature_snippet_owned: 'feature_snippet_owned',
  knowledge_panel_owned: 'knowledge_panel_owned',
  video_owned: 'video_owned',
  thumbnails_owned: 'thumbnails_owned',
  breadcrumbs_owned: 'breadcrumbs_owned',
  https_owned: 'https_owned',
  site_links_searchbox_owned: 'site_links_searchbox_owned',
  faq_owned: 'faq_owned',
  featured_video_owned: 'featured_video_owned',
  recipes_owned: 'recipes_owned',
  organic_stores_owned: 'organic_stores_owned',
  popular_stores_owned: 'popular_stores_owned',
  rich_product_info_owned: 'rich_product_info_owned',
  news_position_owned: 'news_position_owned',
  video_carousel_position_owned: 'video_carousel_position_owned',
  recipes_position_owned: 'recipes_position_owned',
  research_product_position_owned: 'research_product_position_owned',
  hotels_position_owned: 'hotels_position_owned',
  forum_post_position_owned: 'forum_post_position_owned',
  image_product_pack_position_owned: 'image_product_pack_position_owned',
  shop_styles_owned: 'shop_styles_owned',
  youtube_promoted_video_owned: 'youtube_promoted_video_owned',
  youtube_channel_owned: 'youtube_channel_owned',
  youtube_video_owned: 'youtube_video_owned',
  youtube_show_owned: 'youtube_show_owned',
  youtube_playlist_owned: 'youtube_playlist_owned',
  youtube_movie_owned: 'youtube_movie_owned',
  youtube_live_stream_owned: 'youtube_live_stream_owned',
  youtube_radio_owned: 'youtube_radio_owned',
  youtube_age_owned: 'youtube_age_owned',
  youtube_views_owned: 'youtube_views_owned',
  youtube_video_count_owned: 'youtube_video_count_owned',
  youtube_subscribers_owned: 'youtube_subscribers_owned',
  youtube_watching_owned: 'youtube_watching_owned',
};

/** SERP types that cannot be owned */
export const serpTypes: Record<SerpTypes, SerpTypes> = {
  ads_top: 'ads_top',
  ads_in_between: 'ads_in_between',
  ads_bottom: 'ads_bottom',
  shopping: 'shopping',
  maps_local_teaser: 'maps_local_teaser',
  maps_local: 'maps_local',
  related_questions: 'related_questions',
  carousel: 'carousel',
  image_pack: 'image_pack',
  reviews: 'reviews',
  tweets: 'tweets',
  site_links: 'site_links',
  feature_snippet: 'feature_snippet',
  knowledge_panel: 'knowledge_panel',
  knowledge_cards: 'knowledge_cards',
  video: 'video',
  thumbnails: 'thumbnails',
  breadcrumbs: 'breadcrumbs',
  https: 'https',
  direct_answer: 'direct_answer',
  job_posting: 'job_posting',
  site_links_searchbox: 'site_links_searchbox',
  things_todo: 'things_todo',
  featured_video: 'featured_video',
  faq: 'faq',
  trips_popular: 'trips_popular',
  podcasts: 'podcasts',
  ai_overview: 'ai_overview',
  related_searches_carousel: 'related_searches_carousel',
  most_popular_carousel: 'most_popular_carousel',
  refine_carousel: 'refine_carousel',
  results_about: 'results_about',
  apps: 'apps',
  search_by_photos: 'search_by_photos',
  discover_more_places: 'discover_more_places',
  hotels_pack: 'hotels_pack',
  flights: 'flights',
  events: 'events',
  organic_stores: 'organic_stores',
  popular_stores: 'popular_stores',
  rich_product_info: 'rich_product_info',
  filter_menu: 'filter_menu',
  news_position: 'news_position',
  video_carousel_position: 'video_carousel_position',
  recipes_position: 'recipes_position',
  research_product_position: 'research_product_position',
  hotels_position: 'hotels_position',
  forum_post_position: 'forum_post_position',
  image_product_pack_position: 'image_product_pack_position',
  shop_styles: 'shop_styles',
  youtube_ads: 'youtube_ads',
  youtube_promoted_video: 'youtube_promoted_video',
  youtube_related_searches: 'youtube_related_searches',
  youtube_related_to_your_search: 'youtube_related_to_your_search',
  youtube_shelf_render: 'youtube_shelf_render',
  youtube_related_movies: 'youtube_related_movies',
  youtube_secondary_search_container: 'youtube_secondary_search_container',
  youtube_channel: 'youtube_channel',
  youtube_video: 'youtube_video',
  youtube_show: 'youtube_show',
  youtube_playlist: 'youtube_playlist',
  youtube_movie: 'youtube_movie',
  youtube_live_stream: 'youtube_live_stream',
  youtube_radio: 'youtube_radio',
};

const iconMap: Record<SerpTypes, JSX.Element> = {
  ads_top: Overline,
  ads_in_between: DollarOverUnderLine,
  ads_bottom: Underline,
  shopping: Cart,
  maps_local_teaser: LocalTeaserPack,
  maps_local: Map,
  related_questions: Speechbubble,
  carousel: Carousel,
  image_pack: ImagePack,
  reviews: Comment,
  tweets: Tweet,
  site_links: Link,
  feature_snippet: Snippet,
  knowledge_panel: KnowledgePanel,
  knowledge_cards: KnowledgeCard,
  video: Movie,
  thumbnails: Thumbnails,
  breadcrumbs: Breadcrumbs,
  https: Https,
  direct_answer: DirectAnswer,
  job_posting: JobPosting,
  site_links_searchbox: SiteLinksSearchbox,
  things_todo: ThingsTodo,
  featured_video: FeaturedVideo,
  faq: Faq,
  trips_popular: TripsPopular,
  podcasts: Podcasts,
  ai_overview: AiOverview,
  related_searches_carousel: RelatedSearchesCarousel,
  most_popular_carousel: MostPopularCarousel,
  refine_carousel: RefineCarousel,
  results_about: ResultsAbout,
  apps: SoftwareApp,
  hotels_pack: HotelPack,
  flights: Flights,
  events: Events,
  research_product_position: ResearchProduct,
  search_by_photos: SearchByPhotos,
  discover_more_places: DiscoverMorePlaces,
  news_position: Report,
  video_carousel_position: VideoCarousel,
  recipes_position: Recipes,
  youtube_ads: YoutubeAds,
  youtube_promoted_video: YoutubePromotedVideo,
  youtube_related_searches: YoutubeRelatedSearches,
  youtube_related_to_your_search: YoutubeRelatedToYourSearch,
  youtube_shelf_render: YoutubeShelfRender,
  youtube_related_movies: YoutubeRelatedMovies,
  youtube_secondary_search_container: YoutubeSecondarySearchContainer,
  youtube_channel: YoutubeChannel,
  youtube_video: YoutubeVideo,
  youtube_show: YoutubeShow,
  youtube_playlist: YoutubePlaylist,
  youtube_movie: YoutubeMovie,
  youtube_live_stream: YoutubeLiveStream,
  youtube_radio: YoutubeRadio,
  filter_menu: AdjustmentsHorizontal,
  rich_product_info: TagStarred,
  organic_stores: Basket,
  popular_stores: StarShop,
  hotels_position: HotelPack,
  forum_post_position: Conversation,
  image_product_pack_position: ImagePackBasket,
  shop_styles: Dress,
};

export const getSERPimageUrl = (iconId: string): string => {
  if (iconId in iconMap) {
    return iconMap[iconId];
  }
  return Breadcrumbs;
};

export const getSerpFeatureData = (
  id: string,
  serpFeatureList?: SerpFeaturesItem[] | SerpFeatures,
  allSerpFeatures?: SerpFeatures | null,
) => {
  const serpFeatures = (serpFeatureList || allSerpFeatures) as SerpFeatures;
  const serp = serpFeatures?.find(
    (feature) => (feature?.id && feature.id === id) || (feature?.owned && feature.owned === id),
  );

  if (!serp || !serp?.id) {
    const errorMsg = `no serp found for id: ${id}`;
    Sentry.captureException(errorMsg);
    return null;
  }

  const icon = getSERPimageUrl(serp.id || '');

  return { ...serp, icon };
};
