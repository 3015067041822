import React, { useMemo, useState } from 'react';
import { selectors } from 'react-virtualized-tree';
import { Group } from '@mantine/core';
import { useHover } from '@mantine/hooks';
import cn from 'classnames';
import { useIsSelected } from 'Pages/Keywords/Groupings/EditMode/support/state';
import { getIndentClassNames } from 'Utilities/Table/Tree/treeUtils';
import { t } from 'Utilities/i18n';
import { customPropsIsComparison } from '../../../../Utilities/compare';
import { TableRowProps } from '../support/types';
import styles from '../treetable.module.scss';

/**Recursive component for rows in the TreeTable
 * If the node has children, it will render a TableRowChild component for each child
 * */
export const TableRowContainer = React.memo<TableRowProps>(
  (props: TableRowProps): JSX.Element => {
    const {
      rootNode,
      expandDataKey,
      onNodeSelect,
      rowComponent: RowContentComponent,
      gridTableRows,
      disableRowSelect,
      onDragEnd,
      onToggle,
      cellProps,
    } = props;
    const [dragStartY, setDragStartY] = useState(0);
    const { ref, hovered } = useHover();
    const isSelected = useIsSelected(rootNode[expandDataKey], expandDataKey);

    const { isExpanded } = selectors.getNodeRenderOptions(rootNode as any);

    const resultClassName = getIndentClassNames(rootNode, isExpanded);

    const handleSelect = disableRowSelect ? undefined : (event) => onNodeSelect?.(rootNode, event);

    const dragProps = useMemo(
      () => ({
        draggable: !!onDragEnd,
        onDragStart: (e) => {
          setDragStartY(e.clientY);
          e.dataTransfer.setDragImage(document.createElement('span'), 0, 0);
        },
        onDragEnd: (e) => {
          if (Math.abs(e.clientY - dragStartY) > 30) {
            onDragEnd?.(
              e,
              t(
                'It looks like you\'re trying to organize your tags. Do you want to enter the folder editing mode?',
              ),
            );
          }
        },
      }),
      [],
    );
    return (
      <Group
        ref={ref}
        data-testid="TableRowChild"
        data-scroll={rootNode?.name}
        tabIndex={0}
        aria-selected={isSelected}
        onClick={handleSelect}
        onKeyPress={handleSelect}
        className={cn(styles.fullRow, resultClassName, { [styles.flex]: !gridTableRows })}
        style={{ gridTemplateColumns: gridTableRows }}
        ml="0"
        miw="100%"
        {...dragProps}
        wrap="nowrap"
      >
        <RowContentComponent
          rootNode={rootNode}
          isOpen={isExpanded}
          isSelected={isSelected}
          isHovered={hovered}
          onToggle={onToggle}
          updateSelectedNode={onNodeSelect}
          className={resultClassName}
          hideConnectors={(rootNode as any)?.hideConnectors}
          expandDataKey={props.expandDataKey}
          onDragEnd={onDragEnd}
          {...(cellProps || {})}
        />
      </Group>
    );
  },
  /**
   * To display actual data - we need only re-render once rootNode changed, skipping children to avoid expensive comparison on long list.
   */
  customPropsIsComparison<TableRowProps>([
    // To display actual data - we need only re-render once rootNode changed, skipping children to avoid expensive comparison on long list.
    (e) => ({ ...e, children: null }),
    'expandDataKey',
    'gridTableRows',
    'disableRowSelect',
  ]),
);
TableRowContainer.displayName = 'TableRowContainer';
