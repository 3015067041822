import { useEffect, useState } from 'react';
import { useApolloClient } from '@apollo/client';
import { IconHelp } from '@tabler/icons-react';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import { AccFastIcon } from 'Components/AccFastIcon';
import {
  ColumnType,
  DataTable,
  TableFetchDataCallBack,
  TableOrder,
  TableSize,
} from 'Components/DataTable';
import DomainDetails from 'Components/DomainDetails';
import ActionsCell from 'Components/Table/TableRow/ActionsCell';
import {
  PausedDomainFragment,
  PausedDomainsListDocument,
  PausedDomainsListQuery,
  PausedDomainsListQueryVariables,
  usePausedDomainsDeleteDomainMutation,
  usePausedDomainsUpdateDomainMutation,
} from 'Ghql';
import { useModal } from 'Hooks/base/useModal';
import toast from 'Hooks/useToast';
import styles from 'Pages/Domains/DomainsTable/domains-table.module.scss';
import { TableIDs } from 'Types/Table';
import { ErrorsFromServer, throwSubmitErrors } from 'Utilities/errors';
import { t, tct } from 'Utilities/i18n';
import { notEmpty } from 'Utilities/underdash';
import { subscribeToDomain } from 'Utilities/websocket';
import styleVariables from 'css/base/variables.module.scss';
import IconPlayerPlay from 'icons/IconPlayerPlay.svg';
import { IconTrash } from 'icons/tag-cloud';
import { EventName, trackEventMixpanel } from '../../Utilities/Analytics/mixpanel';
import { ColumnIDs } from './ColumnIDs';
import DateCell from './cells/DateCell';
import GroupCell from './cells/GroupCell';
import KeywordsCell from './cells/KeywordsCell';

const useFetchData = (): TableFetchDataCallBack => {
  const client = useApolloClient();

  return async () => {
    return client
      .query<PausedDomainsListQuery, PausedDomainsListQueryVariables>({
        query: PausedDomainsListDocument,
        fetchPolicy: 'network-only',
      })
      .then((response) => {
        const PausedDomains = response.data?.pausedDomainsList?.filter(notEmpty) || [];

        return { data: PausedDomains, length: PausedDomains.length ?? 0 };
      });
  };
};

const PausedDomainsTable = () => {
  const [dataKey, setDataKey] = useState<number>(0);

  const handleUpdateTable = () => {
    setDataKey((prev) => prev + 1);
  };

  //Update table when domain is paused/unpaused.
  useEffect(() => {
    const subscription = subscribeToDomain(() => {
      handleUpdateTable();
    });
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const { showModal } = useModal();
  const [updateDomain] = usePausedDomainsUpdateDomainMutation();

  const [deleteDomainMutation] = usePausedDomainsDeleteDomainMutation();

  const handleUnpauseDomain = async (domain: PausedDomainFragment) => {
    try {
      let res = await updateDomain({
        variables: {
          input: {
            id: domain.id,
            domain: domain.domain,
            displayName: domain.displayName || '',
            includeSubdomains: domain.includeSubdomains,
            exactMatch: domain.exactMatch,
            shareOfVoicePercentage: domain.shareOfVoicePercentage,
            googleBusinessNameList: domain.googleBusinessNameList || [],
            twitterHandle: domain.twitterHandle || '',
            instagramProfile: domain?.instagramProfile || '',
            facebookProfile: domain?.facebookProfile || '',
            tiktokProfile: domain?.tiktokProfile || '',
            linkedinProfile: domain?.linkedinProfile || '',
            client: domain.client.id,
            paused: false,
          },
          forceUnpause: false,
        },
      });
      let errors = res.data?.updateDomain?.errors;

      if (!isEmpty(errors)) {
        // Checks the error list for 'entering overuse'-error
        if (errors?.find((error) => error?.messages.indexOf('WARNING! Entering overuse') !== -1)) {
          errors = [];
          // If overuse is confirmed send request with "forceUnpause: true"
          showModal({
            modalType: 'Confirmation',
            modalProps: {
              showExclamationInTitle: false,
              title: (
                <span>
                  <span>{t('Keyword usage')}</span>
                  <a
                    className={'help-icon-overuse-modal'}
                    href="https://www.accuranker.com/help/account/dynamic-keyword-usage"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <IconHelp size={20} />
                  </a>
                </span>
              ),
              description: tct(
                'By adding these keywords, your account will exceed its current keyword limit. [br]If you want to manage your keyword usage click [here:here].',
                {
                  br: <br />,
                  here: (
                    <a
                      href="https://app.accuranker.com/app/account/keyword-usage"
                      target="_blank"
                      rel="noreferrer"
                    />
                  ),
                },
              ),
              confirmLabel: t('Continue'),
              lockDuration: 0,
              action: async () => {
                res = await updateDomain({
                  variables: {
                    input: {
                      id: domain.id,
                      domain: domain.domain,
                      displayName: domain.displayName || '',
                      includeSubdomains: domain.includeSubdomains,
                      exactMatch: domain.exactMatch,
                      shareOfVoicePercentage: domain.shareOfVoicePercentage,
                      googleBusinessNameList: domain.googleBusinessNameList || [],
                      twitterHandle: domain.twitterHandle || '',
                      client: domain.client.id,
                      facebookProfile: domain.facebookProfile || '',
                      instagramProfile: domain.instagramProfile || '',
                      linkedinProfile: domain.linkedinProfile || '',
                      tiktokProfile: domain.tiktokProfile || '',
                      paused: false,
                    },
                    forceUnpause: true,
                  },
                });
                errors = res.data?.updateDomain?.errors;

                if (!isEmpty(errors)) {
                  throwSubmitErrors(errors as ErrorsFromServer);
                } else {
                  // If no errors so far, the domain has successfully been unpaused.
                  toast.success(t('Domain unpaused'));
                }
              },
            },
          });
        } else if (!isEmpty(errors)) {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          throwSubmitErrors(errors as ErrorsFromServer);
        }
      } else {
        // If no errors so far, the domain has successfully been unpaused.
        toast.success(t('Domain unpaused'));
      }
    } catch (error: any) {
      if (error.errors && error.errors.paused) {
        toast.error(error.errors.paused);
      } else {
        toast.error(t('Something went wrong'));
      }

      throw error;
    }
  };

  const handleDeleteDomain = (domainObj) => {
    const { id } = domainObj;
    deleteDomainMutation({
      variables: {
        input: {
          id,
        },
      },
    }).then((response) => {
      const errors = response.data?.deleteDomain?.errors;

      if (errors && errors.length) {
        toast.error(t('Could not delete domain'));
        trackEventMixpanel(EventName.DeleteDomainFailed, '', {
          'Deleted Domain ID': id,
          Source: 'GroupsAndDomains',
          Error: `${errors?.[0]?.field}: ${errors?.[0]?.messages?.[0]}`,
        });
        return;
      }

      toast.success(t('Domain deleted'));
      trackEventMixpanel(EventName.DeleteDomainSuccess, '', {
        'Deleted Domain ID': id,
        Source: 'GroupsAndDomains',
      });
      handleUpdateTable();
    });
  };

  const showDeleteConfirmation = (domainObj: PausedDomainFragment) => {
    const { domain, displayName } = domainObj;
    showModal({
      modalType: 'Confirmation',
      modalProps: {
        title: t('Delete Domain?'),
        description: t(
          'The domain "%s" and all its keywords will be permanently deleted.',
          displayName || domain,
        ),
        confirmLabel: t('Delete domain'),
        action: () => handleDeleteDomain(domainObj),
      },
    });
  };

  const fetchData = useFetchData();

  const getActions = (record: PausedDomainFragment) => {
    const { snorlax4: iconColor } = styleVariables;
    return [
      {
        onSelect: () => handleUnpauseDomain(record),
        label: t('Unpause'),
        fastIcon: <AccFastIcon src={IconPlayerPlay} size={20} color={iconColor} />,
      },
      {
        onSelect: () => showDeleteConfirmation(record),
        label: t('Delete'),
        fastIcon: <AccFastIcon src={IconTrash} size={20} color={iconColor} />,
      },
    ];
  };

  // eslint-disable-next-line @typescript-eslint/ban-types
  const columns: ColumnType<PausedDomainFragment, {}>[] = [
    {
      id: ColumnIDs.DOMAIN_NAME,
      title: t('Domain Name'),
      flex: true,
      width: 325,
      cellRenderer: ({ record }) => (
        <DomainDetails
          domainId={record.id}
          title={record.displayName || ''}
          domain={record.domain}
          logo={record.faviconUrl || ''}
          isDemoDomain={false}
          reset={true}
          small={false}
          shouldLink={false}
          showNavigateIcons={false}
        />
      ),
      onHeaderCell: () => ({
        ordering: {
          orderBy: 'domain',
          defaultOrder: TableOrder.ASC,
        },
      }),
    },
    {
      id: ColumnIDs.BELONGS_TO_GROUP,
      title: t('Belongs to Group'),
      flex: true,
      width: 250,
      cellRenderer: ({ record }) => <GroupCell record={record} />,
      onHeaderCell: () => ({
        ordering: {
          orderBy: 'client.name',
          defaultOrder: TableOrder.ASC,
        },
      }),
    },
    {
      id: ColumnIDs.KEYWORDS,
      title: t('Keywords'),
      width: 150,
      cellRenderer: ({ record }) => <KeywordsCell record={record} />,
      onHeaderCell: () => ({
        ordering: {
          orderBy: 'totalKeywords',
          defaultOrder: TableOrder.ASC,
        },
      }),
    },
    {
      id: ColumnIDs.CREATED_AT,
      title: t('Created At'),
      width: 125,
      cellRenderer: ({ record }) => <DateCell date={record.dateAdded} />,
      onHeaderCell: () => ({
        ordering: {
          orderBy: 'dateAdded',
          defaultOrder: TableOrder.ASC,
        },
      }),
    },
    {
      id: ColumnIDs.PAUSED_AT,
      title: t('Paused At'),
      width: 125,
      cellRenderer: ({ record }) => (
        <DateCell
          date={record.pausedChangeDate ? moment(record.pausedChangeDate).format('YYYY-MM-DD') : ''}
        />
      ),
      onHeaderCell: () => ({
        ordering: {
          orderBy: 'pausedChangeDate',
          defaultOrder: TableOrder.DESC,
        },
      }),
    },
    {
      id: ColumnIDs.ACTIONS,
      title: t('Actions'),
      width: 76,
      cellRenderer: ({ record }) => (
        <ActionsCell shouldUpdateIndicator={record} actions={getActions(record)} />
      ),
      onHeaderCell: () => ({
        ordering: {
          orderBy: ColumnIDs.ACTIONS,
          defaultOrder: TableOrder.DESC,
        },
      }),
    },
  ];

  return (
    <DataTable
      className={styles.domainsTable}
      dataKey={dataKey.toString()}
      tableId={TableIDs.PAUSED_DOMAINS}
      fetchData={fetchData}
      pagination={true}
      columns={columns}
      viewMode={TableSize.DEFAULT}
      emptyOptions={{
        title: t('No Paused Domains'),
        subTitle: t('There are currently no paused domains.'),
      }}
      offlineFilter={{
        tableName: TableIDs.PAUSED_DOMAINS,
        skipAll: true,
      }}
    />
  );
};

export default PausedDomainsTable;
