import { ScrollSyncPane } from 'react-scroll-sync';
import styles from './stickyVerticalScroll.module.scss';

export const StickyVerticalScroll = ({ width }: { width?: number }) => {
  const hasWidth = !!width;
  return (
    <ScrollSyncPane>
      <div className={styles.container} data-has-width={hasWidth || null}>
        <div className={styles.innerBlock}>
          <div className={styles.extendBlock} style={{ width: width ? `${width}px` : '100%' }} />
        </div>
      </div>
    </ScrollSyncPane>
  );
};
