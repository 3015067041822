import { Component } from 'react';
import { gql } from '@apollo/client';
import { graphql } from '@apollo/client/react/hoc';
import compose from 'lodash/flowRight';
import { Field, reduxForm } from 'redux-form';
import AccButton from 'Components/AccButton/AccButton';
import { Checkbox, Select, TextAreaField, TextField } from 'Components/Forms/Fields';
import { ModalFooter } from 'Components/Modal/Layout/ModalFooter';
import Skeleton from 'Components/Skeleton';
import toast from 'Hooks/useToast';
import { t } from 'Utilities/i18n/index';
import underdash from 'Utilities/underdash';
import Validator from 'Utilities/validation';
import formStyle from 'css/layout/form-layout.module.scss';

type Props = {
  organizations: Record<string, any>;
  handleSubmit: (...args: Array<any>) => any;
  requestAccess: (...args: Array<any>) => any;
  invalid: boolean;
  reset: (...args: Array<any>) => any;
  submitting: boolean;
  refresh: (...args: Array<any>) => any;
  onClose: (...args: Array<any>) => any;
};
type State = {};

class RequestAccessForm extends Component<Props, State> {
  handleSubmit = ({
    fromOrganization: { value: id },
    toEmail,
    subject,
    message = '',
    isOrgAdmin,
    sendACopy,
  }) => {
    const requestAccessInput = {
      fromOrganization: id,
      toEmail,
      subject,
      message,
      isOrgAdmin: !!isOrgAdmin,
      sendACopy: !!sendACopy,
    };
    return this.props
      .requestAccess({
        variables: {
          requestAccessInput,
        },
      })
      .then(
        ({
          data: {
            addMultiAccountRequest: { multiAccount, errors },
          },
        }) => {
          if (!multiAccount) {
            toast.error('Something went wrong');
            Validator.setResponseErrors(Validator.throwSubmissionError, errors);
          } else {
            this.props.onClose();
            this.props.refresh();
            toast.success(t('Request sent'));
          }
        },
      );
  };

  handleSelectOnBlur(field) {
    const {
      input,
      input: { value },
    } = field;
    input.onBlur(value);
  }

  renderSkeleton() {
    return (
      <div>
        <Skeleton
          className="indented-form-group form-group"
          linesConfig={[
            {
              type: 'text',
              options: {
                width: '30%',
              },
            },
            {
              type: 'input',
            },
            {
              type: 'text',
              options: {
                width: '30%',
              },
            },
            {
              type: 'input',
            },
            {
              type: 'text',
              options: {
                width: '30%',
              },
            },
            {
              type: 'input',
            },
            {
              type: 'text',
              options: {
                width: '30%',
              },
            },
            {
              type: 'input',
            },
            {
              type: 'input',
            },
            {
              type: 'text',
              options: {
                width: '35%',
              },
            },
            {
              type: 'text',
              options: {
                width: '25%',
              },
            },
            {
              type: 'subtitle',
              options: {
                width: '20%',
              },
            },
            {
              type: 'button',
              options: {
                width: '45%',
                alignment: 'center',
              },
            },
          ]}
        />
      </div>
    );
  }

  render() {
    const {
      organizations: { user },
      invalid,
      submitting,
      handleSubmit,
    } = this.props;

    if (underdash.graphqlError({ ...this.props }) || underdash.graphqlLoading({ ...this.props })) {
      return this.renderSkeleton();
    }

    const options = user.organizations.map((org) => ({
      label: org.name,
      value: org.id,
    }));
    return (
      <form onSubmit={handleSubmit(this.handleSubmit)}>
        <div className={formStyle.formRow}>
          <div className="form-label required">{t('From organization')}</div>
          <Field
            defaultBehaviour
            name="fromOrganization"
            type="text"
            placeholder={t('Select your organization')}
            component={Select}
            validate={Validator.required}
            options={options}
            autoFocus={true}
          />
        </div>
        <div className={formStyle.formRow}>
          <div className="form-label required">{t('To email')}</div>
          <Field
            name="toEmail"
            type="email"
            placeholder={t('To email')}
            component={TextField}
            validate={[Validator.required, Validator.email]}
          />
        </div>
        <div className={formStyle.formRow}>
          <div className="form-label required">{t('Subject')}</div>
          <Field
            name="subject"
            placeholder={t('Subject')}
            component={TextField}
            validate={[Validator.required]}
          />
        </div>
        <div className={formStyle.formRow}>
          <div className="form-label">{t('Message')}</div>
          <Field
            name="message"
            placeholder={t('Message')}
            component={TextAreaField}
            validate={[]}
          />
        </div>
        <div className={formStyle.formRow}>
          <Field name="isOrgAdmin" component={Checkbox}>
            {t('Access the account as an admin user')}
          </Field>
        </div>
        <div className={formStyle.formRow}>
          <Field name="sendACopy" component={Checkbox} defaultChecked>
            {t('Send a copy to myself')}
          </Field>
        </div>
        <ModalFooter
          primaryButtonSlot={
            <AccButton disabled={invalid || submitting} type="submit" variant="primary">
              {t('Send request')}
            </AccButton>
          }
          secondaryButtonSlot={
            <AccButton onClick={this.props.onClose} variant="tertiary">
              {t('Cancel')}
            </AccButton>
          }
        />
      </form>
    );
  }
}

const requestAccessQuery = gql`
  mutation requestAccessForm_requestAccess($requestAccessInput: AddMultiAccountRequestInput!) {
    addMultiAccountRequest(input: $requestAccessInput) {
      multiAccount {
        id
      }
      errors {
        field
        messages
      }
    }
  }
`;
const getOrganizationsQuery = gql`
  query requestAccessForm_organizations {
    user {
      id
      organizations {
        id
        name
      }
    }
  }
`;
export default compose(
  graphql(getOrganizationsQuery, {
    name: 'organizations',
  }),
  graphql(requestAccessQuery, {
    name: 'requestAccess',
  }),
)(
  reduxForm({
    form: 'RequestAccessForm',
  })(RequestAccessForm),
) as any;
