import { Component } from 'react';
import { connect } from 'react-redux';
import { gql } from '@apollo/client';
import { graphql } from '@apollo/client/react/hoc';
import cn from 'classnames';
import compose from 'lodash/flowRight';
import { bindActionCreators } from 'redux';
import { submit } from 'redux-form';
import { showModal } from 'Actions/ModalAction';
import AccButton from 'Components/AccButton/AccButton';
import LoadingSpinner from 'Components/LoadingSpinner';
import AccTitle from 'Components/Title/AccTitle';
import { withRouter } from 'Utilities/Router';
import { t } from 'Utilities/i18n';
import reusableStyles from 'css/reusable-styles.module.scss';
import CompanyInfoWidget from '../CompanyInfoWidget/index';
import styles from './update-payment-info.module.scss';

type ContextRouter = any;
type Props = {
  dispatch: (...args: Array<any>) => any;
  updateCompanyInfo: (...args: Array<any>) => any;
  showModal: (...args: Array<any>) => any;
  backLink?: string;
} & ContextRouter;
type State = {
  showVatFields: boolean;
  formSubmitting: boolean;
  formValid: boolean;
};

class UpdatePaymentInfo extends Component<Props, State> {
  static defaultProps = {
    backLink: '/account/billing',
  };

  constructor(props) {
    super(props);
    this.state = {
      showVatFields: true,
      formSubmitting: false,
      formValid: true,
    };
  }

  getFormValidStatus = (valid) => {
    this.setState({
      formValid: valid,
    });
  };
  handleSubmit = ({
    companyName,
    street,
    zipcode,
    city,
    state,
    country: { countryCode },
    vatPrefix,
    vatNumber,
    emailInvoiceTo,
    poNumber,
  }) => {
    const { history } = this.props;
    this.setState({
      formSubmitting: true,
    });
    this.props
      .updateCompanyInfo({
        variables: {
          companyName,
          street,
          zipcode,
          city,
          state,
          countryIso: countryCode,
          vatPrefix: vatPrefix ? vatPrefix.vatCode : '',
          vatNumber: vatNumber ? vatNumber.trim() : '',
          emailInvoiceTo,
          poNumber,
        },
      })
      .then(
        ({
          data: {
            setPaymentContact: { error, success },
          },
        }) => {
          if (!success) {
            this.props.showModal({
              modalType: 'GeneralError',
              modalProps: {
                title: t('We could not update your company information'),
                errorType: error,
                link: '/billing/',
              },
            });
            return;
          }

          history.push('/account/billing');
        },
      )
      .finally(() => {
        this.setState({
          formSubmitting: false,
        });
      });
  };
  handleBack = () => {
    const { history, backLink } = this.props;
    history.push(backLink);
  };

  render() {
    const { dispatch } = this.props;
    const { formSubmitting, formValid } = this.state;
    const loadingSpinner = formSubmitting ? <LoadingSpinner /> : '';
    return (
      <div className={cn(styles.container, reusableStyles.paperContainer)}>
        <AccTitle type="h4" mb={18}>
          {t('Company Details')}
        </AccTitle>
        <form>
          <CompanyInfoWidget
            onSubmit={this.handleSubmit}
            setFormValidStatus={this.getFormValidStatus}
          />
          <div className="text-right confirmation-button-wrapper">
            {loadingSpinner}
            <AccButton variant="tertiary" onClick={this.handleBack}>
              {t('Back')}
            </AccButton>
            <AccButton
              disabled={formSubmitting || !formValid}
              variant="primary"
              onClick={() => dispatch(submit('CompanyInfoForm'))}
            >
              {t('Update')}
            </AccButton>
          </div>
        </form>
      </div>
    );
  }
}

const updateCompanyInfoMutation = gql`
  mutation updatePaymentInfo_updateCompanyInfo(
    $companyName: String!
    $street: String!
    $city: String!
    $zipcode: String!
    $countryIso: String!
    $state: String
    $vatPrefix: String
    $vatNumber: String
    $emailInvoiceTo: String
    $poNumber: String
  ) {
    setPaymentContact(
      companyName: $companyName
      street: $street
      city: $city
      zipcode: $zipcode
      countryIso: $countryIso
      state: $state
      vatPrefix: $vatPrefix
      vatNumber: $vatNumber
      emailInvoiceTo: $emailInvoiceTo
      poNumber: $poNumber
    ) {
      success
      error
    }
  }
`;
export default compose(
  graphql(updateCompanyInfoMutation, {
    name: 'updateCompanyInfo',
  }),
  connect(null, (dispatch) => ({
    ...bindActionCreators(
      {
        showModal,
      },
      dispatch,
    ),
    dispatch,
  })),
)(withRouter(UpdatePaymentInfo));
