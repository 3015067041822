import { PropsWithChildren, useEffect, useState } from 'react';
import { Modal, ModalProps } from '@mantine/core';
import AccTitle from 'Components/Title/AccTitle';
import styles from './simpleModal.module.scss';

type Props = PropsWithChildren<{
  title: string;
  opened?: boolean;
  setOpened?: (opened: boolean) => void;
  modalProps?: Omit<ModalProps, 'opened' | 'onClose' | 'title' | 'children'>;
  onClose?: () => void;
}>;

const SimpleModal = ({
  title,
  children,
  opened = false,
  setOpened,
  modalProps = {},
  onClose,
}: Props) => {
  const [localOpened, setLocalOpened] = useState(opened);

  const handleClose = () => {
    setLocalOpened(false);
    setOpened?.(false);
    onClose?.();
  };

  useEffect(() => {
    setLocalOpened(opened);
  }, [opened]);

  return (
    <Modal
      opened={localOpened}
      onClose={() => handleClose()}
      title={
        <AccTitle type="h4" c="white" fw={600}>
          {title}
        </AccTitle>
      }
      overlayProps={{ color: 'black', opacity: 0.4 }}
      padding={0}
      classNames={{
        root: styles.modal,
        inner: styles.inner,
        content: styles.content,
        body: styles.body,
        header: styles.header,
        close: styles.close,
      }}
      {...modalProps}
    >
      {children}
    </Modal>
  );
};

// eslint-disable-next-line import/no-unused-modules
export default SimpleModal;
