import { memo, useRef } from 'react';
import { useIntl } from 'react-intl';
import { Chart as ChartType } from 'highcharts';
import isEqual from 'lodash/isEqual';
import { ChartContainer } from 'Components/Chart/ChartContainer/ChartContainer';
import { ChartLoader, ChartLoaderType } from 'Components/Chart/ChartLoader/ChartLoader';
import { useNotesModifiers } from 'Components/Chart/Notes';
import { ReactHighchartsChart, ReactStockedChart } from 'Components/Chart/ReactHighcarts';
import { useChartConfig } from './support/hooks/useChartConfig';
import { LineChartType, UseChartConfigProps } from './support/types';

export interface LineChartProps extends Omit<UseChartConfigProps, 'intl'> {
  stoked?: boolean;
  metric?: string;
  yAxisLabel?: string;
  disableLegend?: boolean;
  placeLegendBelowChart?: boolean;
  isRank?: boolean;
  visibility?: Record<string, boolean>;
  loaderType?: ChartLoaderType;
  exportButton?: boolean;
  showHoursOnXAxis?: boolean;
  hideNotes?: boolean;
  fileName?: string;
}

export const LineChart = memo(
  ({
    exportButton = true,
    stoked = true,
    metric,
    yAxisLabel,
    chartType = LineChartType.LINE,
    showHoursOnXAxis = false,
    hideNotes,
    fileName,
    ...props
  }: LineChartProps) => {
    const intl = useIntl();
    const chartRef = useRef<ChartType>(null);

    const notes = !props.showFake ? props.notes || [] : [];
    const { withNotes, handleNotesClick, tooltipElement } = useNotesModifiers(
      () => chartRef.current,
      notes,
    );
    const chartConfig = useChartConfig(
      { chartType: chartType || LineChartType.LINE, ...props, intl, showHoursOnXAxis },
      chartRef.current,
      metric,
      yAxisLabel,
    );
    const Chart = stoked ? ReactStockedChart : ReactHighchartsChart;
    return (
      <ChartLoader loading={props.isLoading} loaderType={props.loaderType}>
        <ChartContainer
          onClick={handleNotesClick}
          noPadding={true}
          exportButton={exportButton}
          fileName={fileName}
        >
          <Chart ref={chartRef} config={hideNotes ? chartConfig : withNotes(chartConfig)} />
          {tooltipElement}
        </ChartContainer>
      </ChartLoader>
    );
  },
  isEqual,
);
LineChart.displayName = 'LineChart';
