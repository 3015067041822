import { useEffect } from 'react';
import { DataTable, TableOrder } from 'Components/DataTable';
import { GeneratedReportFragment } from 'Ghql';
import { TableIDs } from 'Types/Table';
import { t } from 'Utilities/i18n';
import { subscribeToGeneratedReport } from 'Utilities/websocket';
import { useFetchGeneratedReports, useGeneratedReportsTableColumns } from './helpers/hooks';

type GeneratedReportsTableProps = {
  searchTerm: string;
  handleUpdateTable: () => void;
  dataKey: number;
  handleLoading: (isLoading: boolean) => void;
  handleDelete: (report: GeneratedReportFragment) => void;
};

const GeneratedReportsTable = ({
  handleUpdateTable,
  dataKey,
  searchTerm,
  handleLoading,
  handleDelete,
}: GeneratedReportsTableProps): JSX.Element => {
  useEffect(() => {
    const subscription = subscribeToGeneratedReport(() => {
      handleUpdateTable();
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [handleUpdateTable]);

  const fetchData = useFetchGeneratedReports({ searchTerm, handleLoading });
  const columns = useGeneratedReportsTableColumns({ handleUpdateTable, handleDelete });

  return (
    <DataTable
      tableId={TableIDs.GENERATED_REPORTS}
      dataKey={`${dataKey?.toString()}-${searchTerm}`}
      fetchData={fetchData}
      columns={columns}
      emptyOptions={{
        title: t('No Generated Reports'),
        subTitle: t('There are currently no generated reports.'),
      }}
      defaultOrdering={{
        order: TableOrder.DESC,
        orderBy: 'createdAt',
      }}
      offlineFilter={{
        tableName: TableIDs.GENERATED_REPORTS,
        skipAll: true,
      }}
    />
  );
};

export default GeneratedReportsTable;
