import {
  IconArrowsDoubleNeSw,
  IconBrandSafari,
  IconCloudUpload,
  IconFileAnalytics,
  IconHeartHandshake,
  IconHome,
  IconMoneybag,
  IconTool,
} from '@tabler/icons-react';
import { RoutePath } from 'Constants/Routes';
import { SALES_FILTER_SET } from 'Types/FilterSet';
import { t } from 'Utilities/i18n';
import { NavItem, NavItemKeys } from './types';

export enum VisibilityState {
  HasKeydisAccess = 'HasKeydisAccess',
  IsOrgAdmin = 'IsOrgAdmin',
  IsOrgAdminShowKeywordUsage = 'isOrgAdminShowKeywordUsage',
  IsOrgAdminNotTrial = 'isOrgAdminNotTrial',
  IsSalesManager = 'IsSalesManager',
  IsOnMultiAccount = 'IsOnMultiAccount',
  SalesManagerNotMultiAccount = 'SalesManagerNotMultiAccount',
  IsTrial = 'IsTrial',
  IsPartner = 'isPartner',
  HasAccess = 'HasAccess',
  HasAccuApiAccess = 'HasAccuApiAccess',
  NoAccuApiAccess = 'NoAccuApiAccess',
  HasWriteAccess = 'HasWriteAccess',
  IsOrgAdminOrSuperuser = 'IsOrgAdminOrSuperuser',
}

export const getAllNavigationItems = (): NavItem[] => [
  {
    key: NavItemKeys.HOME,
    label: t('Home'),
    link: '/domains',
    icon: () => <IconHome width={18} height={18} color="white" />,
    visibleFor: [VisibilityState.HasAccess, VisibilityState.NoAccuApiAccess],
    subPages: [
      {
        label: t('Domains'),
        link: '/domains',
        extraActiveRoutes: ['/'],
      },
      {
        label: t('Groups'),
        link: '/groups',
        visibleFor: VisibilityState.IsOrgAdminOrSuperuser,
      },
    ],
  },
  {
    key: NavItemKeys.RESEARCH,
    label: t('Discovery'),
    link: '/research',
    icon: () => <IconBrandSafari width={18} height={18} color="white" />,
    visibleFor: [
      VisibilityState.HasAccess,
      VisibilityState.HasKeydisAccess,
      VisibilityState.NoAccuApiAccess,
    ],
  },
  {
    key: NavItemKeys.REPORTING,
    label: t('Reporting'),
    link: '/reports/scheduled',
    icon: () => <IconFileAnalytics width={18} height={18} color="white" />,
    visibleFor: [VisibilityState.HasAccess, VisibilityState.NoAccuApiAccess],
    subPages: [
      {
        label: t('Scheduled Reports'),
        link: '/reports/scheduled',
      },
      {
        label: t('Generated Reports'),
        link: '/reports/generated',
      },
      {
        label: t('Report Templates'),
        link: '/reports/templates',
      },
    ],
  },
  {
    key: NavItemKeys.ACCOUNT,
    label: t('Account'),
    link: '/account',
    visibleFor: [VisibilityState.HasAccess, VisibilityState.HasWriteAccess],
    icon: () => <IconTool width={18} height={18} color="white" />,
    subPages: [
      {
        label: t('Account Settings'),
        link: '/account',
        visibleFor: VisibilityState.IsOrgAdmin,
      },
      {
        label: t('Users'),
        link: '/account/users',
        visibleFor: [VisibilityState.IsOrgAdmin, VisibilityState.NoAccuApiAccess],
      },
      {
        label: t('Workspaces'),
        link: '/account/workspaces',
        visibleFor: [VisibilityState.IsOrgAdminOrSuperuser],
      },
      {
        label: t('Sub-Accounts'),
        link: '/accounts',
        visibleFor: VisibilityState.NoAccuApiAccess,
      },
      {
        label: t('Accounts Requests'),
        link: '/accounts/requests',
        visibleFor: VisibilityState.IsPartner,
      },
      {
        label: t('Transfer & Copy Domains'),
        link: '/accounts/domains',
        visibleFor: VisibilityState.IsPartner,
      },
      {
        label: t('External Access'),
        link: RoutePath.ACCOUNT_EXTERNAL_ACCESS,
        visibleFor: [VisibilityState.IsOrgAdmin, VisibilityState.NoAccuApiAccess],
      },
      {
        label: t('Keyword Usage'),
        link: RoutePath.ACCOUNT_KEYWORD_OVERUSE,
        visibleFor: VisibilityState.IsOrgAdminShowKeywordUsage,
      },
      {
        label: t('Subscription'),
        link: '/account/billing',
        visibleFor: VisibilityState.IsOrgAdminNotTrial,
      },
      {
        label: t('Change Plan'),
        link: '/billing/package/select',
        visibleFor: [VisibilityState.IsOrgAdmin, VisibilityState.NoAccuApiAccess],
      },
      {
        label: t('Connected Integrations'),
        link: '/account/connected',
        visibleFor: [VisibilityState.IsOrgAdmin, VisibilityState.NoAccuApiAccess],
      },
      {
        label: t('Wallet'),
        link: '/account/wallet',
        visibleFor: [VisibilityState.IsOrgAdmin, VisibilityState.NoAccuApiAccess],
      },
      {
        label: t('AccuAPI'),
        link: '/account/accu_api',
        visibleFor: VisibilityState.HasAccuApiAccess,
      },
    ],
  },
  {
    key: NavItemKeys.INTEGRATIONS,
    label: t('Integrations'),
    link: '/integrations',
    icon: () => <IconArrowsDoubleNeSw width={18} height={18} color="white" />,
    visibleFor: [
      VisibilityState.HasAccess,
      VisibilityState.NoAccuApiAccess,
      VisibilityState.HasWriteAccess,
    ],
    subPages: [
      {
        label: t('Third-Party Integrations'),
        link: '/integrations',
      },
      {
        label: t('API Filters'),
        link: '/integrations/api',
      },
      {
        label: t('API Docs'),
        link: '/api',
        external: true,
      },
    ],
  },
  {
    key: NavItemKeys.IMPORTER,
    label: t('Import'),
    link: '/import',
    icon: () => <IconCloudUpload width={18} height={18} color="white" />,
    visibleFor: [
      VisibilityState.HasAccess,
      VisibilityState.NoAccuApiAccess,
      VisibilityState.HasWriteAccess,
    ],
    subPages: [
      {
        label: t('File Import'),
        link: '/import',
      },
      {
        label: t('File Formatting'),
        link: '/import/examples',
      },
    ],
  },
  {
    key: NavItemKeys.SALES,
    label: t('Sales'),
    link: '/sales/search',
    icon: () => <IconMoneybag width={18} height={18} color="white" />,
    visibleFor: VisibilityState.SalesManagerNotMultiAccount,
    subPages: [
      {
        label: t('Search'),
        link: '/sales/search',
        filterSet: SALES_FILTER_SET,
      },
      {
        label: t('Plans'),
        link: '/sales/plans',
      },
      {
        label: t('Customers'),
        link: '/sales/customers',
      },
      {
        label: t('Mails'),
        link: '/sales/mails',
      },
      {
        label: t('Trials'),
        link: '/sales/dashboard',
      },
      {
        label: t('Tools'),
        link: '/sales/tools',
      },
      {
        label: t('Invoice Customers'),
        link: '/sales/invoice/customers',
      },
      {
        label: t('Reconcile Payments'),
        link: '/sales/reconcile_payments',
      },
      {
        label: t('Importer'),
        link: '/sales/importer',
      },
    ],
  },
  {
    key: NavItemKeys.WELCOME,
    label: t('Welcome'),
    link: '/welcome',
    visibleFor: [VisibilityState.HasAccess, VisibilityState.IsTrial],
    icon: () => <IconHeartHandshake width={18} height={18} color="white" />,
  },
];
