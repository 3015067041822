import { memo } from 'react';
import { IconPencil, IconTrash } from '@tabler/icons-react';
import AccActionIcon from 'Components/AccActionIcon/AccActionIcon';
import AccessControl from 'Components/AccessControl';
import AccText from 'Components/Text/AccText';
import { GroupsDomainItem } from '.';
import styles from './search-dropdown.module.scss';

type Props = {
  setOpened: (val: boolean) => void;
  onDelete?: (...args: Array<any>) => any;
  onEdit?: (...args: Array<any>) => any;
  onSelect?: (...args: Array<any>) => any;
  style?: React.CSSProperties;
  item?: GroupsDomainItem;
};

const DropdownRow = (props: Props) => {
  const { item, setOpened, onDelete, onEdit, onSelect, style } = props;

  return (
    <div
      tabIndex={0}
      className={styles.row}
      style={style}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          onSelect?.(item);
          setOpened(false);
        }
      }}
      onClick={() => {
        onSelect?.(item);
        setOpened(false);
      }}
    >
      {item?.domain && (
        <img
          className="favicon"
          style={{ height: 16, width: 16 }}
          src={`https://app.accuranker.com/screenshots/favicon?url=${item.domain}&size=24`}
        />
      )}
      <AccText overflowEllipsis nowrap size="sm" fw={600}>
        {item?.displayName || item?.domain}
      </AccText>
      <AccText overflowEllipsis nowrap size="sm" fw={300}>
        {item?.domain}
      </AccText>
      <AccessControl>
        <div className={styles.actionIcons}>
          <AccActionIcon
            size={22}
            onClick={(event: React.SyntheticEvent<any>) => {
              event.preventDefault();
              onEdit?.(event, item);
            }}
          >
            <IconPencil size={20} />
          </AccActionIcon>
          <AccActionIcon
            size={22}
            onClick={(event: React.SyntheticEvent<any>) => {
              event.preventDefault();
              onDelete?.(event, item);
            }}
          >
            <IconTrash size={20} />
          </AccActionIcon>
        </div>
      </AccessControl>
    </div>
  );
};

export default memo(DropdownRow);
