import React from 'react';
import Cell from 'Components/AccuTable/CellRenderer/HelperComponents/AccuCell';
import FormatNumber from 'Components/FormatNumber/new';

interface Props {
  value: number;
  currency?: boolean;
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
}

export const NumberCell: React.FC<React.PropsWithChildren<Props>> = ({
  value,
  currency,
  minimumFractionDigits,
  maximumFractionDigits,
}) => {
  return (
    <Cell rightAligned>
      <FormatNumber
        value={value ?? 0}
        currency={currency ? 'USD' : undefined}
        minimumFractionDigits={minimumFractionDigits}
        maximumFractionDigits={maximumFractionDigits}
      />
    </Cell>
  );
};
