import { Field } from 'Components/Fields';
import { t } from 'Utilities/i18n';
import Validator from 'Utilities/validation';
import { FIELD_NAMES } from '..';

// type JobTitle = {
//   label: string;
//   value: string;
// };

const ContactInfo = () => {
  // const jobTitleOptions: JobTitle[] = useMemo(() => {
  //   return [
  //     {
  //       label: t('SEO specialist'),
  //       value: 'seoSpecialist',
  //     },
  //     {
  //       label: t('Marketing specialist'),
  //       value: 'marketingSpecialist',
  //     },
  //     {
  //       label: t('Manager'),
  //       value: 'manager',
  //     },
  //     {
  //       label: t('C-level'),
  //       value: 'cLevel',
  //     },
  //     {
  //       label: t('Other'),
  //       value: 'other',
  //     },
  //   ];
  // }, []);

  return (
    <>
      {/*<Grid.Col span={12}>*/}
      {/*  <AccTitle type="h3" align="center" className={styles.sectionTitle}>*/}
      {/*    <span>{t('Contact Information')}</span>*/}
      {/*  </AccTitle>*/}
      {/*</Grid.Col>*/}
      <Field.TextInput
        positionErrorBelow
        label={t('Full name')}
        labelClassname="required"
        name={FIELD_NAMES.FULL_NAME}
        id={FIELD_NAMES.FULL_NAME}
        elementType="input"
        type="text"
        placeholder={t('Enter your full name')}
        autoComplete="name"
        required
        hideRequiredStar
        validate={[Validator.required, Validator.string, Validator.fullName]}
      />
      {/*<Grid.Col sm={12}>*/}
      {/*  <Field.Select*/}
      {/*    label={t('Job Title')}*/}
      {/*    name={FIELD_NAMES.JOB_TITLE}*/}
      {/*    id={FIELD_NAMES.JOB_TITLE}*/}
      {/*    searchable={false}*/}
      {/*    placeholder={t('Select your job title')}*/}
      {/*    options={jobTitleOptions}*/}
      {/*    required*/}
      {/*    validate={Validator.required}*/}
      {/*  />*/}
      {/*</Grid.Col>*/}
      {/*<Grid.Col sm={12}>*/}
      {/*  <Field.PhoneInput*/}
      {/*    label={t('Phone')}*/}
      {/*    labelClassname="required"*/}
      {/*    helpText={t('Required for verification')}*/}
      {/*    name={FIELD_NAMES.PHONE}*/}
      {/*    id={FIELD_NAMES.PHONE}*/}
      {/*    elementType="input"*/}
      {/*    type="tel-local"*/}
      {/*    placeholder={t('Enter your phone number')}*/}
      {/*    searchPlaceholder={t('Search')}*/}
      {/*    defaultCountry={createdByCountry}*/}
      {/*    inputClassName={styles.phoneInput}*/}
      {/*    autoComplete="tel"*/}
      {/*    required*/}
      {/*    validate={[Validator.required]}*/}
      {/*  />*/}
      {/*</Grid.Col>*/}
      <Field.TextInput
        label={t('Work email')}
        labelClassname="required"
        name={FIELD_NAMES.EMAIL}
        id={FIELD_NAMES.EMAIL}
        elementType="input"
        readOnly
        disabled
        type="email"
        autoComplete="off"
        required
        hideRequiredStar
        validate={[Validator.required, Validator.emailHtml]}
      />
    </>
  );
};

export default ContactInfo;
