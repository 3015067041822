import { memo } from 'react';
import { useFolderOrTagFilter } from 'Components/Filters/LinkWithFolders/linkWithFolders';
import LinkToKeywordListCell from 'Pages/Keywords/Groupings/ViewMode/components/cells/LinkToKeywordListCell';
import { propsIsEqualComparison } from 'Utilities/compare';
import { t } from 'Utilities/i18n';
import { FolderCellProps } from '../../utils/useFoldersTableConfig';

const KeywordsCell = (props: FolderCellProps) => {
  const foldersOrTagFilter = useFolderOrTagFilter({
    isFolder: props.rootNode.is_folder,
    folderOrTag: props.rootNode.is_folder ? props.rootNode.path : props.rootNode.name,
  });

  return (
    <LinkToKeywordListCell
      props={props}
      filters={[foldersOrTagFilter]}
      value={props.rootNode.keywords}
      tooltip={t('Go to keywords list and filter by folder')}
    />
  );
};

export default memo(KeywordsCell, propsIsEqualComparison);
