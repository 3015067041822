import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Text, TextProps, useMantineTheme } from '@mantine/core';
// eslint-disable-next-line import/no-unresolved
import { PolymorphicComponentProps } from '@mantine/core/lib/core/factory/create-polymorphic-component';
import cn from 'classnames';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import { withProps } from 'Components/HOC';
import styles from './accText.module.scss';

export type AccTextProps = Omit<
  PolymorphicComponentProps<'p', Omit<TextProps, 'variant' | 'color'>>,
  'color'
> & {
  nowrap?: boolean;
  variant?: TextProps['variant'] | 'label' | 'link';
  disabled?: boolean;
  inactive?: boolean;
  overflowEllipsis?: boolean;
  noOverflowTooltip?: boolean; // Sometimes we want to disable the tooltip for overflow ellipsis
  /**Supplying an href will wrap the component in a react-router <Link> */
  href?: string;
  /**Used if an href is supplied, and passed to react-router <Link> */
  target?: '_self' | '_blank' | '_parent' | '_top';
  noSelect?: boolean;
  /** Wrap with `<AccTooltipSimple tooltip={tooltip}>` */
  tooltip?: string;
};

/**
 * Text component to uniform text styling in the app
 * @example
 * <AccText c="gray" styles={{ root: { display: 'block' } }} inline={false}>
      <b>{t("Search intent")}</b> {t("in SEO refers to the purpose or goal behind a user's search query.")}
   </AccText>
 */
const AccText = ({
  children,
  nowrap = false,
  variant = 'text',
  disabled = false,
  inactive = false,
  overflowEllipsis = false,
  noOverflowTooltip = false,
  noSelect = false,
  inline: inlineProp,
  size: sizeProp,
  fw: weightProp,
  display: displayProp,
  c: cProp,
  href,
  target,
  tooltip,
  className,
  ...rest
}: AccTextProps) => {
  const {
    other: { textInactive, textDisabled, textPrimary },
  } = useMantineTheme();

  const OverflowEllipsisTooltipWrapper =
    overflowEllipsis && !noOverflowTooltip
      ? withProps({ tooltip: children, old: true })(AccTooltip)
      : withProps({ tooltip })(AccTooltip);

  const LinkWrapper = href
    ? withProps({
        to: href,
        target,
        className: overflowEllipsis ? styles.overflowEllipsis : '',
      })(Link)
    : Fragment;

  const color = inactive ? textInactive : disabled ? textDisabled : cProp ?? textPrimary;

  const defaultSize = variant === 'label' ? 'sm' : 'md';
  const size = sizeProp ?? defaultSize;

  const defaultWeight = 400;
  const weight = variant === 'label' ? 600 : weightProp ?? defaultWeight;

  const display = inlineProp ? 'inline' : displayProp;

  return (
    <LinkWrapper>
      <OverflowEllipsisTooltipWrapper>
        <Text
          className={cn(
            {
              [styles.link]: variant === 'link',
              [styles.overflowEllipsis]: overflowEllipsis,
              [styles.noSelect]: noSelect,
              [styles.noWrap]: nowrap,
            },
            className,
          )}
          c={color}
          size={size}
          fw={weight}
          display={display}
          data-disabled={disabled}
          {...rest}
        >
          {children}
        </Text>
      </OverflowEllipsisTooltipWrapper>
    </LinkWrapper>
  );
};

export default AccText;
