import { Box, Flex } from '@mantine/core';
import cn from 'classnames';
import FormatNumber from 'Components/FormatNumber/new';
import styles from './tree-table.module.scss';

export const StackedValues = (props) => {
  const {
    topValue,
    bottomValue,
    hoverTop,
    hoverBottom,
    includeTop,
    includeBottom,
    ...formatNumberProps
  } = props;

  return (
    <Flex direction="column" align="end">
      {includeTop && (
        <Box
          className={cn(styles.stackedValueTop, {
            [styles.active]: hoverTop,
          })}
        >
          {typeof topValue === 'string' || topValue instanceof String ? (
            topValue
          ) : (
            <FormatNumber value={topValue} {...formatNumberProps} />
          )}
        </Box>
      )}
      {includeBottom && (
        <Box
          className={cn(includeTop ? styles.stackedValueBottom : styles.staackedValueBottom, {
            [styles.active]: hoverBottom,
          })}
        >
          {typeof bottomValue === 'string' || bottomValue instanceof String ? (
            bottomValue
          ) : (
            <FormatNumber value={bottomValue} {...formatNumberProps} />
          )}
        </Box>
      )}
    </Flex>
  );
};
