import { KeyboardEvent, KeyboardEventHandler, useRef } from 'react';
import { Box, BoxProps, CloseButton } from '@mantine/core';
import cn from 'classnames';
import merge from 'lodash/merge';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import FilterCount from 'Components/Filters/Common/FilterCount';
import { useAutoFocus } from 'Hooks/useAutoFocus';
import { t } from 'Utilities/i18n';
import styles from './text-input-control.module.scss';

type FieldWrapperProps = {
  value: any;
  onChange?: (...args: Array<any>) => any;
};

export type TextInputProps = {
  autoFocus?: boolean;
  onFocus?: (...args: Array<any>) => any;
  onBlur?: (...args: Array<any>) => any;
  customOnChange?: (event) => void;
  placeholder?: string;
  isNumber?: boolean;
  showError?: boolean;
  disabled?: boolean;
  style?: any;
  isPositive?: boolean;
  onReset?: (...args: Array<any>) => any;
  showReset?: boolean;
  resetTooltip?: string;
  readOnly?: boolean;
  id?: string;
  className?: string;
  boxProps?: BoxProps;
  inheritWidthHeight?: boolean;
  min?: number;
  buttonSlot?: JSX.Element;
  leftSection?: JSX.Element;
  count?: number | null;
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
  [x: string]: any;
};

const TextInput = (props: TextInputProps & FieldWrapperProps) => {
  const {
    value = null,
    onChange = () => {},
    customOnChange,
    placeholder = '',
    isNumber = false,
    showError = false,
    disabled = false,
    readOnly = false,
    inheritWidthHeight = false,
    onReset,
    autoFocus,
    isPositive,
    showReset,
    resetTooltip,
    boxProps,
    style,
    buttonSlot,
    count,
    onKeyDown,
    leftSection,
    ...rest
  } = props;

  const inputRef = useRef<HTMLInputElement>(null);
  useAutoFocus(inputRef, autoFocus);

  const toPositiveValue = (_value: any) => {
    let newValue: string | number = Number(_value);
    const absValue = Math.abs(newValue);

    if (absValue === 0) {
      newValue = newValue !== 0 ? '' : newValue;
    } else {
      newValue = absValue;
    }

    return newValue;
  };

  const handleChange = (evt: React.SyntheticEvent<any>) => {
    if (isNumber && evt.target) {
      // TODO FixTSignore
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      evt.target.value = toPositiveValue(evt.target.value);
    }

    customOnChange?.(evt);
    onChange?.(evt);
  };

  const handleReset = () => {
    onReset && onReset();
  };

  const handleKeydown = (evt: KeyboardEvent<HTMLInputElement>) => {
    // TODO FixTSignore
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (onReset && !evt.target.value && evt.key === 'Backspace') {
      evt.preventDefault();
      handleReset();
    }
    onKeyDown?.(evt);
  };

  return (
    <Box
      {...boxProps}
      className={styles.textInputContainer}
      style={merge(inheritWidthHeight && { width: 'inherit', height: 'inherit' })}
    >
      <input
        ref={inputRef}
        style={merge(inheritWidthHeight && { width: 'inherit', height: 'inherit' }, style)}
        data-autofocus={autoFocus}
        min={isNumber && isPositive ? 0 : null}
        className={cn(styles.textInputControl, {
          error: showError,
        })}
        data-with-left-section={!!leftSection || null}
        value={value}
        onKeyDown={handleKeydown}
        placeholder={placeholder}
        disabled={disabled}
        readOnly={readOnly}
        type={isNumber ? 'number' : 'text'}
        {...(rest as any)}
        onChange={handleChange as any}
      />
      {leftSection && <span className={styles.leftSection}>{leftSection}</span>}
      <FilterCount count={count} />
      {buttonSlot && <span className={styles.textInputButton}>{buttonSlot}</span>}
      {showReset && (
        <AccTooltip tooltip={resetTooltip || t('Reset')}>
          <CloseButton
            size="sm"
            disabled={!value}
            onClick={handleReset}
            aria-label={t('Clear input')}
            classNames={{ root: styles.textInputReset }}
          />
        </AccTooltip>
      )}
    </Box>
  );
};

export default TextInput;
